import { useSelector } from 'react-redux'
import { Translator, Button } from '@itarget/shared'
import { Box, Grid, Hidden, Typography, makeStyles } from '@material-ui/core'
import { useState } from 'react'

const useStyles = makeStyles(() => ({
  sliderProprietiesStyle: {
    height: 'auto',
    backgroundColor: (props) => (props.theme == 'light' ? '#f6f6f6' : '#232323'),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sliderItemStyle: { height: 'auto', justifyContent: 'center', display: 'flex', overflowY: 'hidden' },
  sliderItemContainerStyle: { maxWidth: '1240px' },
  itemTitlesStyle: { lineHeight: 1, fontWeight: 'bold' },
  itemVideoStyle: { objectFit: 'cover', width: '100%', height: '100%' },
  sliderItemInfoStye: { maxWidth: 300 }
}))

const swiffySliderClass = {
  sliderProprieties:
    'swiffy-slider slider-item-ratio slider-nav-autohide slider-nav-autoplay slider-nav-autopause slider-indicators-round slider-nav-animation slider-nav-animation-fadein slider-nav-animation-slow slider-item-ratio-32x9 slider-nav-caret slider-nav-outside',
  sliderContainer: 'slider-container',
  sliderItem: 'slide',
  sliderCurrent: '-visible',
  sliderNav: 'slider-nav',
  sliderNext: '-next',
}

export const BannerCarrousel = ({ bannersData }) => {
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0)
  const systemTheme = useSelector((state) => state.theme.systemTheme)
  const styles = useStyles({ theme: systemTheme })

  const translation = {
    seeMore: Translator({ transRef: 'translation.Veja mais' }),
  }

  const handleChangeCurrentBannerIndex = (index) => {
    setCurrentBannerIndex(index)
  }
  
  if (!bannersData || bannersData?.length == 0) return null

  return (
    <div className={`${swiffySliderClass.sliderProprieties} ${styles.sliderProprietiesStyle}`}>
      <Box>
        <ul className={`${swiffySliderClass.sliderContainer}`}>
          {bannersData.map((row, index) => {
            return (
              <Box
                id={index}
                key={index}
                className={`${swiffySliderClass.sliderItem}${currentBannerIndex == index ? swiffySliderClass.sliderCurrent : ''}`}>
                <li className={` ${styles.sliderItemStyle}`}>
                  <Grid
                    className={`${styles.sliderItemContainerStyle}`}
                    container
                    xs={12}>
                    <Hidden smDown>
                      <Grid
                        item
                        xs={4}
                        container
                        direction='column'
                        justifyContent='center'>
                        <Box p={1}>
                          <Grid
                            item
                            container
                            spacing={2}
                            className={styles.sliderItemInfoStyle}
                            direction='column'>
                            {row.type && <Grid item>
                              <Typography variant='body1'>{row.type}</Typography>
                            </Grid>}
                            <Grid item>
                              <Typography
                                className={`${styles.itemTitlesStyle}`}
                                variant='h3'>
                                {row.title}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant='subtitle1'>{row.subtitle}</Typography>
                            </Grid>
                            {row.action && <Grid item>
                              <Button
                                variant='contained'
                                color='primary'>
                                {translation.seeMore}
                              </Button>
                            </Grid>}
                          </Grid>
                        </Box>
                      </Grid>
                    </Hidden>
                    <Grid
                      item
                      xs={12}
                      md={8}>
                      <video
                        className={`${styles.itemVideoStyle}`}
                        loop={row.video.loop}
                        muted={row.video.muted}
                        autoPlay={row.video.autoPlay}
                        controls={row.video.controls}>
                        <source
                          src={row.video.url}
                          type={row.video.type}
                        />
                      </video>
                    </Grid>
                  </Grid>
                </li>
              </Box>
            )
          })}
        </ul>
      </Box>
      {bannersData.length > 1 && (
        <>
          <button
            type='button'
            className={`${swiffySliderClass.sliderNav}`}
          />
          <button
            type='button'
            className={`${swiffySliderClass.sliderNav} ${swiffySliderClass.sliderNav}${swiffySliderClass.sliderNext}`}
          />
        </>
      )}
    </div>
  )
}
