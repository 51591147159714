import { createContext, useState, Context } from 'react'

export const ProgrammingContext = createContext({ displayVideo: false, handleDisplayVideo: null, currentPresentation: null })

export const ProgrammingContextProvider = ({ children }) => {
  const [displayVideo, setDisplayVideo] = useState(false)
  const [currentPresentation, setCurrentPresentation] = useState(null)
  const handleDisplayVideo = (presentation = null) => {
    if (!!currentPresentation) {
      setDisplayVideo(false)
      setCurrentPresentation(null)
      return
    }
    setCurrentPresentation(presentation)
    setDisplayVideo(true)
  }
  const value = { displayVideo, handleDisplayVideo, currentPresentation }

  return <ProgrammingContext.Provider value={value}>{children}</ProgrammingContext.Provider>
}
