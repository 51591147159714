import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useFetchApi, Config, Loading, useRedirect, Translator } from "@itarget/shared";
import StoreView from "./views/StoreView";

const StoreContainer = () => {
  const { goTo } = useRedirect();
  const { id, cc } = useParams();
  const selectedLang = useSelector((state) => state.language.lang)
  const user = useSelector((state) => state.auth.user);
  const [groupByOffers, setGroupByOffers] = useState("");
  const [offers, setOffers] = useState([]);
  const {
    data: listOffers,
    isLoading: isLoadingListOffers,
    fetchData: fetchListOffers,
  } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/activity-schedule/offer?centro_custo_id=${cc}` +
      (user?.data?.id ? `&pessoa_id=${user.data.id}` : "")
  );

  const selectProduct = async (productId) => {
    goTo(`offers/store/${productId.aga_id}`);
  };

  useEffect(() => {
    if (listOffers) {
      setOffers(listOffers.data);
      setGroupByOffers(listOffers.groupBy);
    }
  }, [listOffers]);

  useEffect(() => {
    fetchListOffers();
  }, [user, fetchListOffers]);

  return (
    <>
      {isLoadingListOffers ? (
        <Loading text={Translator({transRef: "translation.Carregando..."})} />
      ) : (
        <StoreView
          selectProduct={selectProduct}
          offers={offers}
          groupByOffers={groupByOffers}
          center_cost_id={cc}
          id={id}
        />
      )}
    </>
  );
};

export default StoreContainer;
