import {
  Drawer,
  Grid,
  IconButton,
  Typography,
  TextField,
  Divider,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import MenuRoundedIcon from '@material-ui/icons/MenuRounded'
import CloseIcon from '@material-ui/icons/Close'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import LinkStore from '../LinkStore'
import { useParams } from 'react-router-dom'
import { useFetchApi, Config, useRedirect, RTag, Translator } from '@itarget/shared'
import { makeStyles } from '@material-ui/core'
import './style.scss'
import {
  MenuStoreContext,
} from '../../context/MenuStoreContext'

const useStyles = makeStyles((theme) => ({
  container: {
    // menu-principal
    background: '#0086ff',
    padding: '4px 0',
    margin: '0px 15px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  link: {
    // a
    cursor: 'pointer',
  },
  disorder: {
    // ul
    margin: '0 auto',
    '& a': {
      color: '#fff',
    },
  },
  item: {
    // li
    float: 'left',
    listStyle: 'none',
    marginRight: '1.5rem',
  },
  itemDescription: {
    paddingRight: '20px',
    userSelect: 'none',
    color: '#0086ff',
    background: '#dfdfdf',
    textDecoration: 'none',
    flexGrow: '1',
    fontWeight: 'bold',
  },
  itemList: {
    // li a
    display: 'block',
    padding: '6px 0.5rem 5px 0.5rem',
    textDecoration: 'none',
    color: '#fff',
    fontSize: '0.82rem',
    fontWeight: 300,
  },
  submenuBoxLinks: {
    margin: '0.7rem 0',
    padding: '0.7rem 0',
    backgroundColor: '#f3f3f3',
  },
  search: {
    // menu-search
    backgroundColor: '#fff',
    marginRight: '50px',
    padding: '0 20px 0 10px',
    borderRadius: '40px',
    border: 'none',
  },
  options: {
    // search-options
    zIndex: 1300,
    position: 'absolute',
    backgroundColor: 'white',
    marginLeft: '15px',
    cursor: 'pointer',
  },
  optionsContainer: {
    // option-container
    display: 'block',
    fontSize: '0.7em',
    padding: '15px',
  },
  thumb: {
    // option-thumb
    width: '50px',
    float: 'left',
    paddingTop: '5px',
  },
  description: {
    // option-description
    marginLeft: '15px',
  },
  adjust: {
    // option-adjust
    display: 'block',
    width: '100%',
    height: '1px',
    clear: 'both',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1.5rem',
  },
  helperText: {
    cursor: 'pointer',
    color: '#fff',

  },
  '@media screen and (max-width: 500px)': {
    container: {
      // menu-principal
      padding: '0 5%',
      flexGrow: 1,
      justifyContent: 'center',
      height: 'fit-content',
      padding: '10px 0',
    },
    disorder: {
      // ul
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingLeft: 0,
    },
    itemList: {
      // li a
      fontSize: '1rem',
      padding: '1rem 20px',
    },
  },
}))

const MenuSingleStore = ({ cc }) => {
  const styles = useStyles()
  const { goTo } = useRedirect()
  const [offer, setOffer] = useState()
  const routeParams = useParams()
  const user = useSelector((state) => state.auth.user)
  const selectedLang = useSelector((state) => state.language.lang)
  const { id } = useParams()
  const { data: detailRequest, fetchData: fetchDetailRequest } = useFetchApi(
    Config.baseURL +
    `api/${selectedLang}/activity-payment${id ? '/' + id : ''}/detailing${user && user.data ? '?pessoa_id=' + user.data.id : ''
    }`
  )
  const { data: listMenuAll, fetchData: fetchListMenu } = useFetchApi(
    Config.baseURL +
    `api/${selectedLang}/ecommerce/list-menu?centerCostId=${cc}${user && user.data ? '&pessoa_id=' + user.data.id : ''
    }`
  )

  useEffect(() => {
    if (id) {
      fetchDetailRequest()
    }
  }, [id, fetchDetailRequest])

  useEffect(() => {
    fetchListMenu()
  }, [fetchListMenu])

  useEffect(() => {
    if (detailRequest) {
      setOffer(detailRequest.data.detailing[0])
    }
  }, [detailRequest])

  const { setProducts } = useContext(MenuStoreContext)
  const [selectedFilter, setSelectedFilter] = useState({})
  const { data: listProducts, fetchData: fetchListProducts } = useFetchApi(
    Config.baseURL +
    `api/${selectedLang}/activity-schedule/offer?centro_custo_id=${cc}` +
    (routeParams.activity_id ? `&atividade_id=${routeParams.activity_id}` : '') +
    (selectedFilter?.desc_atv ? `&desc_atv=${selectedFilter.desc_atv}` : '')
  )

  useEffect(() => {
    fetchListProducts()
  }, [user?.data, routeParams.activity_id, fetchListProducts, selectedFilter])

  useEffect(() => {
    if (listProducts) {
      setProducts(listProducts?.data)
    }
  }, [listProducts, setProducts])

  const onSubmit = (values) => {
    setSelectedFilter({ ...selectedFilter, ...values })
  }

  const formik = useFormik({
    initialValues: selectedFilter,
    enableReinitialize: true,
    onSubmit,
  })

  const [drawer, setDrawer] = useState(false)
  const selectOffer = (id) => {
    goTo(`offers/store/${id}`)
  }

  return (
    <Grid className={styles.container} item xs={12}>
      <nav>
        <RTag show='desktop'>
          <ul
            className={styles.disorder}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {listMenuAll?.data.map((menu) => (
              <li className={styles.item} key={menu.id}>
                <LinkStore
                  selectOffer={selectOffer}
                  id={id}
                  center_cost_id={cc}
                  offer={offer}
                  menu={menu}
                />
              </li>
            ))}
          </ul>
        </RTag>
        <RTag show='mobile'>
          <ul className={styles.disorder}>
            <IconButton
              size='large'
              style={{
                color: 'white',
                transition: '0.3s all',
                transform: drawer && 'rotate(-90deg)',
              }}
              onClick={() => setDrawer(true)}
            >
              <MenuRoundedIcon />
            </IconButton>
            <Drawer open={drawer} anchor='left' onClose={() => setDrawer(false)}>
              <div style={{ padding: '20px', paddingRight: '0' }}>
                <IconButton onClick={() => setDrawer(false)}>
                  <CloseIcon />
                </IconButton>
                <ul className={styles.disorder} style={{ listStyleType: 'none' }}>
                  {listMenuAll?.data?.map((menu) => (
                    <li
                      className={styles.item}
                      style={{ margin: '20px 0' }}
                      key={menu.id}
                    >
                      <Typography className={styles.itemDescription}>
                        {menu?.description}
                      </Typography>
                      {menu?.subMenu?.map((atividade) => {
                        return (
                          <Grid onClick={() => goTo(`offers-unique/${cc}/${atividade?.id}`)} className={styles.submenuBoxLinks} item xs={20}>
                            <Typography className="submenuBox-product" variant="body2">
                              {atividade.description}
                            </Typography>
                          </Grid>
                        )
                      })}
                    </li>
                  ))}
                </ul>
              </div>
            </Drawer>
          </ul>
        </RTag>
      </nav>
      <Divider flexItem light variant='middle' orientation='vertical' />
      <div className={styles.rightSide}>
        <Grid>
          <Typography onClick={() => goTo(`offers-unique/${cc}`)} className={styles.helperText}>
            <Translator transRef={'translation.Home'} />
          </Typography>
        </Grid>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            id='desc_atv'
            name='desc_atv'
            variant='outlined'
            size='small'
            className='store-input-search'
            color='secondary'
            placeholder='Pesquisar'
            value={formik.values.desc_atv || ''}
            onChange={formik.handleChange}
            InputProps={{
              endAdornment: (
                <IconButton type='submit'>
                  <SearchIcon style={{ color: '#153978' }} />
                </IconButton>
              ),
            }}
            autoFocus
          />
        </form>
      </div>
    </Grid>
  )
}
export default MenuSingleStore
