import React from 'react'
import { Translator, Button } from '@itarget/shared'

export function BtnSeeMore(props) {
  return (
    <Button variant='midnight' icon='read_more' color='midnight' {...props}>
      <Translator transRef='translation.Veja mais' />
    </Button>
  )
}

BtnAssistir.defaultProps = {
  onClick: null,
}

export function BtnAssistir(props) {
  return (
    <Button variant='midnight' icon='video_library' color='midnight' {...props}>
      <Translator transRef='translation.On Demand' />
    </Button>
  )
}

BtnAssistir.defaultProps = {
  onClick: null,
}

export function BtnAssistirAoVivo(props) {
  return (
    <Button variant='midnight' icon='cast_for_education' color='midnight' {...props}>
      <Translator transRef='translation.Acessar ao vivo' />
    </Button>
  )
}

BtnAssistirAoVivo.defaultProps = {
  onClick: null,
}

export function BtnPendente(props) {
  return (
    <Button variant='midnight' icon='production_quantity_limits' color='midnight' {...props}>
      <Translator transRef='translation.Minhas compras' />
    </Button>
  )
}

BtnPendente.defaultProps = {
  onClick: null,
}

export function BtnInscrito(props) {
  return (
    <Button variant='midnight' icon='check' color='midnight' {...props}>
      <Translator transRef='translation.Inscrito' />
    </Button>
  )
}

BtnInscrito.defaultProps = {
  onClick: null,
}

export function BtnMindsRadiology(props) {
  return (
    <Button variant='midnight' icon='link' color='midnight' {...props}>
      <Translator transRef='translation.Minds Radiology' />
    </Button>
  )
}

BtnMindsRadiology.defaultProps = {
  onClick: null,
}

export function BtnAttendSeeClasses(props) {
  return (
    <Button variant='midnight' icon='video_library' color='midnight' {...props}>
      <Translator transRef='translation.Ver aulas' />
    </Button>
  )
}
