import MODULE_NAME from './constants';
import { buildActions } from './utils';

export const FETCH = buildActions(MODULE_NAME, 'FETCH');

export const SET_TOKEN = `${MODULE_NAME}/SET_TOKEN`;

export const setToken = (token = '') => ({
  type: SET_TOKEN,
  payload: { token },
});

export const fetchStarted = ({ meta = {}, payload = {}, callback = null }) => ({
  type: 'FETCH_STARTED',
  meta,
  payload,
  callback,
});

export const fetchFailed = () => ({
  type: FETCH.FAILED,
});

export const fetchFulfilled = () => ({
  type: FETCH.FULFILLED,
});
