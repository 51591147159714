import { formatDate, useQueryParams } from '@itarget/shared'
import { Box, Typography } from '@material-ui/core'
import { useItemStyles } from './styles'

const Item = ({ onClick, date, enabled = true }) => {
  const { get } = useQueryParams()
  let selectedDate = get('date')
  const styles = useItemStyles()

  let day = formatDate.getDay(date)
  let month = formatDate.monthInFull(date).slice(0, 3)

  const onItemClick = () => {
    onClick(date)
  }

  return (
    <Box
      onClick={onItemClick}
      className={styles.container}
      data-enabled={enabled}
      data-selected={selectedDate == date}>
      <Typography className={styles.day}>{day}</Typography>
      <Typography className={styles.month}>{month}</Typography>
    </Box>
  )
}

export default Item
