import React from 'react';
import { Styles } from './styles';

export default function TypographyValue({align, size, value, children, style = {}, className}) {
    return (
        <Styles className={className} style={style} size={size} align={align}>
            {children} <span>R$ </span> <span className="value">{value}</span>
        </Styles>
    );
}

TypographyValue.defaultProps = {
    size: 'sm',
    align: 'left',
}