import {
  Typography,
  Divider,
  Grid,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  AppBar,
  Toolbar,
  Breadcrumbs,
  Avatar,
  Hidden,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core'
import React from 'react'
import { toast } from '../../../redux'
import { ModalQuestions } from '../../../components/ModalQuestionsCourse'
import RecoveryPassword from '../../../components/RecoveryPassword/RecoveryPassword'
import { MeContainer, Config, Translator, formatString, Form, Button } from '@itarget/shared'
import Layout from '../../../components/Layout'
import './style.scss'
import { makeStyles } from '@material-ui/styles'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  titleStyle: {
    ...theme.typography.body1,
    lineHeight: 1,
    fontSize: '24px',
    textTransform: 'capitalize',
  },
  subInformationStyle: {
    ...theme.typography.caption,
    opacity: 0.6,
    lineHeight: 1.2,
    fontSize: '12px',
  },
  categoryInformationStyle: {
    fontWeight: 'bold',
  },
  profileGridContainerStyle: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    gap: theme.spacing(3.5),
    height: 'fit-content',

    '& .profileGridStyle': {
      justifyContent: 'center',
    },
  },
  formContainerStyle: {
    padding: theme.spacing(2),
    height: 'fit-content',
  },
  informationFormStyle: {
    marginTop: theme.spacing(2),
    display: (props) => (props.formState == 'profile' ? 'flex' : 'none'),
    '& form': {
      width: '100%',
      '& div[role=tabpanel] .MuiGrid-item': {
        justifyContent: 'flex-end',
        display: 'flex',
        flexDirection: 'row',
      },
      '& .MuiButton-root': {
        width: 'fit-content',
      },
      '& .buttonGrid': {
        justifyContent: 'flex-start !important',
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
        gap: theme.spacing(1),

        '& .MuiButton-root': {
          flex: 0,
        },
      },
    },
  },
  changePasswordFormStyle: {
    marginTop: theme.spacing(2),
    display: (props) => (props.formState == 'changePassword' ? 'flex' : 'none'),
  },

  avatarStyle: {
    aspectRatio: 1,
  },
  labelEditAvatarStyle: {
    display: 'none',
  },
  avatarGridContainerStyle: {
    flexBasis: 'fit-content',

    '& .avatarGridStyle': {
      position: 'relative',

      [theme.breakpoints.up(theme.breakpoints.values.xs)]: {
        width: '100px',
        height: '100px',
      },
      [theme.breakpoints.up(theme.breakpoints.values.md)]: {
        width: '150px',
        height: '150px',
      },

      '& .labelEditAvatarStyle': {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: '100%',
        backgroundColor: theme.palette.primary.main,
        position: 'absolute',

        padding: theme.spacing(0.3, 0, 0, 0.3),

        [theme.breakpoints.up(theme.breakpoints.values.xs)]: {
          width: '32px',
          height: '32px',
          bottom: `-${theme.spacing(0.5)}px`,
          right: `-${theme.spacing(0.5)}px`,
        },
        [theme.breakpoints.up(theme.breakpoints.values.md)]: {
          width: '42px',
          height: '42px',
          bottom: `-${theme.spacing(0.5)}px`,
          right: `-${theme.spacing(0.5)}px`,
        },

        '& .overlay-icon': {
          color: '#fff',
          [theme.breakpoints.up(theme.breakpoints.values.xs)]: {
            fontSize: '16px',
          },
          [theme.breakpoints.up(theme.breakpoints.values.md)]: {
            fontSize: '20px',
          },
        },
      },

      '& .labelEditAvatarStyle:hover': {
        backgroundColor: (props) => theme.palette.primary[props.theme],
      },

      '& .labelEditAvatarStyle:hover + .avatarStyle': {
        borderColor: (props) => theme.palette.primary[props.theme],
      },

      '& .changePhotoLoading': {
        borderRadius: '100%',
        backgroundColor: theme.palette.transparent.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.up(theme.breakpoints.values.xs)]: {
          width: '93px',
          height: '93px',
        },
        [theme.breakpoints.up(theme.breakpoints.values.md)]: {
          width: '143px',
          height: '143px',
        },

        '& .MuiCircularProgress-svg': {
          color: (props) => (props.theme == 'light' ? theme.palette.primary.main : '#fff'),
        },
      },

      '& .avatarStyle': {
        border: `4px solid ${theme.palette.primary.main}`,
        aspectRatio: 1,
        width: '100%',
        height: '100%',
      },
    },
  },
  buttonGridContainerStyle: {
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      flexDirection: 'column',
    },

    '& .buttonGridStyle': {
      flex: 'auto',
      width: '100%',

      '& .MuiButton-root': {
        width: '100%',
        justifyContent: 'flex-end',
        padding: theme.spacing(0.5, 2.5),

        '& .MuiButton-label': {
          display: 'flex',
          flexDirection: 'row-reverse',
        },
      },
    },
  },
  extraGridContainerStyle: {
    display: (props) => (props.displayExtra ? 'flex' : 'none'),
    borderRadius: theme.shape.borderRadius,
    gap: theme.spacing(3.5),
    height: 'fit-content',

    '& .coursesContainer': {
      gap: theme.spacing(2),

      '& .course': {
        cursor: 'pointer',

        '& .MuiLinearProgress-bar': {
          transition: 'background-color 300ms linear',
        },

        '& .imageCourse': {
          borderRadius: '100%',
          width: '100%',
          aspectRatio: 1,
        },
        '& .courseInformation': {
          gap: theme.spacing(1),
          display: 'flex',
          flexDirection: 'column',

          '& .descriptionCourse,': {
            ...theme.typography.caption,
            textTransform: 'uppercase',
            fontSize: '12px',
            lineHeight: 1,
          },
          '& .typeCourse': {
            ...theme.typography.caption,
            fontWeight: 'bold',
            fontSize: '10px',
            lineHeight: 1,
          },
        },
      },

      '& .course:hover': {
        '& .MuiLinearProgress-bar': {
          backgroundColor: (props) => theme.palette.primary[props.theme],
        },
      },
    },
  },
}))

function Modal(props) {
  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.handleClose}
        TransitionComponent={props.Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={props.handleClose}
              aria-label='close'>
              <Icon className='overlay-icon'>close</Icon>
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant='h6'
              component='div'>
              {props.title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Typography style={{ marginTop: '5%' }}>{props?.iframe && props.iframe}</Typography>
      </Dialog>
    </div>
  )
}

const NewPanelView = ({
  centro_custo_id,
  user,
  goTo,
  auth,
  passwords,
  openMyData,
  handleSubmit,
  handlePasswordChange,
  handleCloseMyData,
  stateProfile,
  onCloseModal,
  onOpenModal,
  openModal,
  sendPhoto,
  listService,
  showQuiz,
  setShowQuiz,
  reRegister,
  handleLogout,
  financialStatus,
  handleFormInformation,
  handleFormChangePassword,
  dataPersonCourse,
  isLoadingPersonCourse,
  isLoadingPersonPut,
  displayInitializedCourses,
}) => {
  const systemTheme = useSelector((state) => state.theme.systemTheme)
  const styles = useStyles({
    formState: stateProfile,
    theme: systemTheme,
    hasAssociation: user.data?.associado,
    displayExtra: displayInitializedCourses,
  })
  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const breadRedirection = () => {
    goTo('')
  }

  const translation = {
    certificated: Translator({ transRef: 'translation.Certificado!' }),
    profile: Translator({ transRef: 'translation.Perfil' }),
    userAvatar: Translator({ transRef: 'translation.avatar do usuário' }),
    currentPassword: Translator({ transRef: 'translation.Senha atual' }),
    newPassword: Translator({ transRef: 'translation.Nova senha' }),
    confirmNewPassword: Translator({ transRef: 'translation.Confirmar nova senha' }),
    myHistory: Translator({ transRef: 'translation.Meus Históricos' }),
    myInformation: Translator({ transRef: 'translation.Meus dados' }),
    changePassword: Translator({ transRef: 'translation.Alterar senha' }),
    initializedCourse: Translator({ transRef: 'translation.Cursos iniciados' }),
    emptyInitializedCourse: Translator({ transRef: 'translation.Sem cursos iniciados' }),
    seeAll: Translator({ transRef: 'translation.Ver todos' }),
    logout: Translator({ transRef: 'translation.Sair' }),
  }

  return (
    <Layout>
      <Modal
        open={open}
        handleClose={handleClose}
        title={translation?.certificated}
        iframe={
          <embed
            style={{
              width: '100%',
              position: 'absolute',
              display: 'block',
              height: '100%',
            }}
            width={'100%'}
            height={'100%'}
            alt={translation?.certificated}
            src={`${Config.urlIcase}/estacao/index/autenticar-hash/lang/pt-br/evento/${centro_custo_id}/hash/${user.data.hash_link_senha}/link/L2VzdGFjYW8vaW5kZXgvbGlzdGEtY2VydGlmaWNhZG9zL2xheW91dC9tb2RhbA==`}></embed>
        }
      />
      {showQuiz && (
        <ModalQuestions
          setShowQuiz={() => {
            setShowQuiz()
          }}
          open={true}
          questionDestination={17}
          centerCostId={centro_custo_id}
        />
      )}
      <Dialog
        open={openMyData}
        onClose={handleCloseMyData}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle className='modalMydata-header'>
          <Translator transRef='translation.Meus Dados' />
          <IconButton
            onClick={handleCloseMyData}
            className='modalMydata-close'>
            <Icon>cancel</Icon>
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <MeContainer
            toast={toast}
            user={user}
            centro_custo_id={centro_custo_id}
            tipo_interface_id={124}
          />
        </DialogContent>
      </Dialog>

      <Grid
        item
        spacing={3}
        container>
        <Grid
          item
          xs={12}
          className='grid-welcome'>
          {
            <Breadcrumbs
              style={{ marginTop: 15, padding: 24 }}
              separator='›'>
              {[
                <div
                  key={0}
                  onClick={() => breadRedirection()}
                  style={{ cursor: 'pointer', fontWeight: 'bold' }}
                  role='link'>
                  <Translator transRef='translation.Ofertas' />
                </div>,
              ]}
            </Breadcrumbs>
          }
        </Grid>
        <Grid
          item
          container
          className={styles.contentGridContainerStyle}
          spacing={10}>
          <Grid
            item
            container
            alignItems='center'
            justifyContent='center'
            className={styles.profileGridContainerStyle}
            xs={12}
            md={3}>
            <Grid
              item
              spacing={3}
              container
              className='profileGridStyle'
              xs={12}>
              <Grid
                item
                container
                alignItems='center'
                justifyContent='center'
                xs={3}
                sm={2}
                md={12}
                className={styles.avatarGridContainerStyle}>
                <Grid
                  item
                  className='avatarGridStyle'>
                  <label
                    className='labelEditAvatarStyle'
                    htmlFor='btn-upload'>
                    <input
                      id='btn-upload'
                      name='btn-upload'
                      style={{ display: 'none' }}
                      type='file'
                      accept='image/*'
                      onChange={(e) => sendPhoto(e)}
                    />

                    <Icon className='overlay-icon'>edit</Icon>
                  </label>
                  {isLoadingPersonPut && (
                    <Grid className='changePhotoLoading'>
                      <CircularProgress />
                    </Grid>
                  )}
                  <Avatar
                    src={user?.data?.foto ? user?.data?.foto : 'assets/images/avatar.png'}
                    className='avatarStyle'
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                spacing={1}
                direction='column'
                justifyContent='center'
                alignItems='center'
                xs={9}
                md={12}>
                <Grid
                  item
                  container>
                  <Typography className={styles.titleStyle}>{user.data.nome.toLowerCase()}</Typography>
                </Grid>
                <Grid
                  item
                  direction='column'
                  container>
                  <Typography className={`${styles.subInformationStyle} ${styles.categoryInformationStyle}`}>
                    {user.data.categoria_centro_custo_descricao}
                  </Typography>
                  {financialStatus && <Typography className={`${styles.subInformationStyle}`}>{financialStatus}</Typography>}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              spacing={1}
              className={styles.buttonGridContainerStyle}>
              <Hidden smDown>
                <Grid
                  className='buttonGridStyle'
                  item>
                  <Button
                    variant='midnight'
                    color='twilight'
                    onClick={handleFormInformation}
                    icon='account_box'>
                    {translation.myInformation}
                  </Button>
                </Grid>
                <Grid
                  className='buttonGridStyle'
                  item>
                  <Button
                    variant='midnight'
                    color='twilight'
                    onClick={handleFormChangePassword}
                    icon='password'>
                    {translation.changePassword}
                  </Button>
                </Grid>
              </Hidden>
              {listService.length &&
                listService.map((row) => {
                  return (
                    <Grid
                      key={row.key}
                      className='buttonGridStyle'
                      item>
                      <Button
                        onClick={row.onClick}
                        icon={row.icon}
                        variant='contained'
                        color='primary'>
                        {row.label}
                      </Button>
                    </Grid>
                  )
                })}
              {user?.data?.id && (
                <Grid
                  className='buttonGridStyle'
                  item>
                  <Button
                    icon={'logout'}
                    variant='contained'
                    onClick={handleLogout}
                    color='primary'>
                    {translation.logout}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={displayInitializedCourses ? 6 : 9}
            justifyContent='center'
            className={styles.formContainerStyle}>
            <Hidden mdUp>
              <Grid
                item
                container
                spacing={1}>
                <Grid
                  container
                  xs={6}
                  item>
                  <Button
                    variant='midnight'
                    color='twilight'
                    onClick={handleFormInformation}
                    icon='account_box'>
                    {translation.myInformation}
                  </Button>
                </Grid>
                <Grid
                  container
                  xs={6}
                  item>
                  <Button
                    variant='midnight'
                    color='twilight'
                    onClick={handleFormChangePassword}
                    icon='password'>
                    {translation.changePassword}
                  </Button>
                </Grid>
              </Grid>
            </Hidden>
            <Hidden smDown>
              <Grid
                item
                container>
                <Typography variant='h6'>
                  {stateProfile == 'profile' ? translation.myInformation : translation.changePassword}
                </Typography>
              </Grid>
            </Hidden>
            <Grid
              item
              container
              className={styles.informationFormStyle}>
              <Form
                toast={toast}
                user={user}
                auth={auth}
                centro_custo_id={centro_custo_id}
                reRegister={reRegister}
                tipo_interface_id={124}
                description={false}
              />
            </Grid>
            <Grid
              item
              container
              className={styles.changePasswordFormStyle}>
              <RecoveryPassword
                openModal={openModal}
                passwords={passwords}
                onOpenModal={onOpenModal}
                handleSubmit={handleSubmit}
                onCloseModal={onCloseModal}
                handlePasswordChange={handlePasswordChange}
              />
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid
              item
              container
              alignItems='center'
              justifyContent='center'
              className={styles.extraGridContainerStyle}
              xs={12}
              md={3}>
              <Grid
                item
                container>
                <Typography variant='h6'>{translation.initializedCourse}</Typography>
              </Grid>
              <Grid
                justifyContent='center'
                item
                className='coursesContainer'
                container>
                {isLoadingPersonCourse && <CircularProgress color='inherit' />}
                {!isLoadingPersonCourse && dataPersonCourse.length == 0 && (
                  <Typography variant='caption'>{translation.emptyInitializedCourse}</Typography>
                )}
                {!isLoadingPersonCourse &&
                  dataPersonCourse.length != 0 &&
                  dataPersonCourse.map((row) => {
                    return (
                      <Grid
                        key={row.agenda_atividade_id}
                        onClick={() => goTo(`my-course/${row.agenda_atividade_id}`)}
                        item
                        className='course'
                        direction='row'
                        container
                        alignItems='center'
                        justifyContent='center'
                        spacing={1}
                        xs={12}>
                        <Grid
                          xs={3}
                          item>
                          {row?.thumb && (
                            <img
                              className='imageCourse'
                              src={row.thumb}
                            />
                          )}
                        </Grid>
                        <Grid
                          xs={9}
                          className='courseInformation'
                          item>
                          <Grid item>
                            <Typography className='typeCourse'>{row.tipo_atividade_descricao}</Typography>
                          </Grid>
                          <Grid item>
                            <Typography className='descriptionCourse'>{row.descricao}</Typography>
                          </Grid>
                          <Grid item>
                            <LinearProgress
                              variant='determinate'
                              value={row.total_assistido}
                              valueBuffer={100}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  })}
              </Grid>
              <Grid
                item
                container>
                <Button
                  onClick={() => goTo('my-course')}
                  variant='contained'
                  color='primary'>
                  {translation.seeAll}
                </Button>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default NewPanelView
