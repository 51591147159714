import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Loading } from '@itarget/shared'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import LinkComponent from '../LinkComponent'

export const Styles = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex a {
    cursor: pointer;
  }
  background: ;

  nav ul {
    flex: 1;
    color: #fff;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 12px;
  }

  nav ul li {
    float: left;
    list-style: none;
  }

  nav ul li a {
    display: block;
    padding: 7px 0px;
    text-decoration: none;
    color: #fff;
  }

  nav {
    display: flex;
    flex: 1;
    max-width: 1240px;
    justify-content: flex-start;
  }

  .menu-principal {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @media (max-width: 1280px) {
    nav ul {
      padding: 0 12px;
    }
  }
`

export default function MenuSingle({ disableCustomMenu = false }) {
  const settingCourse = useSelector((state) => state.setting.settingCommerce)
  const centerCost = useSelector((state) => state.setting.centerCost)
  const [menuState, setMenuState] = useState({ menu: [], colors: { backgroundColor: null, itemColor: null } })

  useEffect(() => {
    try {
      if (settingCourse?.json_cores_barra_menu) {
        let colors = JSON.parse(settingCourse?.json_cores_barra_menu)
        let { itemColor, backgroundColor } = colors
        setMenuState((prevState) => {
          return { ...prevState, colors: { itemColor, backgroundColor } }
        })
      }
      if (!disableCustomMenu && settingCourse?.customMenu) {
        let menu = settingCourse?.customMenu
        setMenuState((prevState) => {
          return { ...prevState, menu }
        })
        return
      }
      if (settingCourse?.json_menu) {
        let menu = [...JSON.parse(settingCourse?.json_menu)]
        menu = menu.map((m) => {
          if (m.url == '/' && centerCost?.tipo_centro_custo === 7) {
            m.url = '/ead'
          }
          return m
        })
        setMenuState((prevState) => {
          return { ...prevState, menu }
        })
      }
    } catch (e) {}
  }, [centerCost, settingCourse.json_menu])
  return (
    <Styles style={{ backgroundColor: menuState.colors?.backgroundColor || '#184081' }}>
      <Grid
        className='menu-principal'
        lg={12}
        xs={12}>
        <nav>
          <ul>
            {menuState?.menu && menuState.menu.length > 0 ? (
              menuState.menu.map((menuItem) => (
                <li key={menuItem?.title || menuItem?.description}>
                  <LinkComponent
                    url={menuItem.url}
                    description={menuItem?.title || menuItem?.description}
                    color={menuState.colors?.itemColor || '#fff'}
                  />
                </li>
              ))
            ) : (
              <Loading />
            )}
          </ul>
        </nav>
      </Grid>
    </Styles>
  )
}
