import { Box, Grid, Hidden, Typography, makeStyles } from '@material-ui/core'

import MenuC from '../../MenuC'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Trailing } from './Trailing'
import { useLocation, useParams } from 'react-router-dom'
import { Button } from '@itarget/shared'
import { BannerCarrousel } from '../../../BannerCarrousel'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '12vh',
  },
  iconcart: {
    width: '32px',
    height: '32px',
  },
  container: {
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    '@media (max-width: 425px)': {
      borderBottom: '0',
      position: 'unset',
    },
  },
  videoContainer: {
    zIndex: 0,
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    '&:after': {
      content: "''",
      top: 0,
      left: 0,
      width: '100%',
      height: '101%',
      position: 'absolute',
      zIndex: 1,
      backgroundImage: (props) =>
        `linear-gradient(${props.backgroundImageColors.top} 90%, ${props.backgroundImageColors.bottom} 100%)`,
    },
  },
  video: {
    width: 'inherit',
    height: 'inherit',
    objectFit: 'fill',
  },
  header: {
    marginRight: '1%',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '@media (min-width: 1240px)': {
      width: '1240px',
    },
    '@media (max-width: 740px)': {
      marginTop: '1%',
    },
  },
  logo: {
    zIndex: 1,
    maxHeight: '80px',
    '@media (max-width: 740px)': {
      display: 'none',
    },
  },
  showMenuLogo: {
    '@media (max-width: 740px)': {
      zIndex: 4,
      top: '10px',
      left: '0',
      display: 'block',
      position: 'fixed',
      maxHeight: '60px',
    },
  },
  trailingLogged: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: theme.spacing(1.5, 0),
    '& .user-information-block': {
      color: 'inherit',
      '& .MuiListItemAvatar-root': {},
    },
    '& .shoppingCartIcon': {
      color: (props) => (props.theme == 'light' ? '#000' : '#fff'),
    },
  },
  trailing: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  trailingMobile: {
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 740px)': {
      padding: '0',
      display: 'flex',
    },
  },
  leading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '@media (max-width: 740px)': {
      padding: '0',
    },
  },
  menuItem: {
    textTransform: 'uppercase',
  },
  menuButton: {
    zIndex: 4,
    '& .material-icons': {
      color: (props) => (props.theme == 'light' ? '#000' : '#fff'),
    },
    '@media (min-width: 1140px)': {
      display: 'none',
    },
  },
}))

let mock = [
  {
    title: 'Home',
    url: '/',
    menu_order: 1,
  },
  {
    title: 'Cursos',
    url: '#',
    menu_order: 2,
    submenu: [
      {
        id: 1,
        title: 'SAVA',
        url: '#',
        menu_order: 3,
      },
      {
        id: 2,
        title: 'ETTI',
        url: '#',
        menu_order: 4,
      },
      {
        id: 3,
        title: 'Controle de Via Aérea',
        url: '#',
        menu_order: 5,
      },
      {
        id: 4,
        title: 'Curso de Extensão em Gestão, Finanças Pessoais e Contabilidade',
        url: '#',
        menu_order: 6,
      },
      {
        id: 5,
        title: 'Gestão do Trabalho do Anestesista',
        url: '#',
        menu_order: 7,
      },
      {
        id: 6,
        title: 'Salve uma Vida',
        url: '#',
        menu_order: 8,
      },
      {
        id: 7,
        title: 'Fundamentos Jurídicos p/ o Anestesiologista',
        url: '#',
        menu_order: 9,
      },
      {
        id: 8,
        title: 'Formação de Líderes',
        url: '#',
        menu_order: 10,
      },
    ],
  },
  {
    title: 'Titulações',
    url: '#',
    menu_order: 11,
    submenu: [
      {
        id: 9,
        title: 'TEA',
        url: '#',
        menu_order: 12,
      },
      {
        id: 10,
        title: 'TSA',
        url: '#',
        menu_order: 13,
      },
    ],
  },
  {
    title: 'Congressos',
    url: '#',
    menu_order: 14,
    submenu: [
      {
        id: 11,
        title: '69ª CBA',
        url: '#',
        menu_order: 15,
      },
      {
        id: 12,
        title: '5° CBIGS',
        url: '#',
        menu_order: 16,
      },
    ],
  },
  {
    title: 'Anuidades',
    url: '#',
    menu_order: 17,
  },
]

const Header = ({
  showMenu,
  toggleMenu,
  logo,
  client,
  shoppingCarts,
  menuItemsC = [],
  isLoadingMenuData,
  user,
  goTo,
  onLogout,
  statusAssociated,
  category,
}) => {
  const [menu, setMenu] = useState([{ description: 'Home', url: '/' }])
  const [imageBackground, setImageBackground] = useState(null)
  const systemTheme = useSelector((state) => state.theme.systemTheme)
  const settingCourse = useSelector((state) => state.setting.settingCommerce)
  const location = useLocation()
  const { cc } = useParams()
  const shouldRenderBanner = location.pathname === '/offers' || location.pathname === `/offers-unique/${cc}`

  let backgroundImageColors = {
    top: systemTheme == 'light' ? '#fafafa00' : '#1C1C1C00',
    bottom: systemTheme == 'light' ? '#fafafafd' : '#1C1C1Cff',
  }

  useEffect(() => {
    try {
      if (settingCourse?.json_menu) setMenu(JSON.parse(settingCourse.json_menu))
      if (settingCourse.url_image_topo_background) setImageBackground(settingCourse.url_image_topo_background)
    } catch (e) {}
  }, [settingCourse])
  const styles = useStyles({
    backgroundImageColors,
    imageBackground,
    shouldRenderBanner,
    showMenu: showMenu != 'hidden',
    theme: systemTheme,
  })
  return (
    <>
      <header className={`${styles.root}`}>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.leading}>
              <img
                className={`${styles.logo} ${showMenu != 'hide' ? styles.showMenuLogo : ''}`}
                alt={client}
                src={logo}
              />
            </div>
            <MenuC
              items={mock}
              isLoadingMenuData={isLoadingMenuData}
              classes={{ root: styles.menu, itemRoot: styles.menuItemRoot }}
              showMenu={showMenu}
            />
            <div className={styles.trailing}>
              <Trailing
                showMenu={showMenu}
                category={category}
                goTo={goTo}
                onLogout={onLogout}
                shoppingCarts={shoppingCarts}
                statusAssociated={statusAssociated}
                styles={styles}
                toggleMenu={toggleMenu}
                user={user}
              />
            </div>
          </div>
        </div>
      </header>
      {shouldRenderBanner && <BannerCarrousel />}
    </>
  )
}

export default Header
