import React from 'react'
import { Grid } from '@material-ui/core'
import { Loading } from '@itarget/shared'
import Layout from '../../../components/Layout'
import ModalVideoCourse from '../../../components/ModalVideoCourse'
import './style.scss'
import TabNavEad from '../../../components/TabNavEad/TabNavEad'
import SearchTopBar from '../../../components/SearchTopBar'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  navContainer: {
    overflow: 'hidden',
  },
}))

const DetailEadView = ({
  tabs,
  date,
  formik,
  scheduling,
  refPlayer,
  lesson,
  showVideo,
  speakers,
  roomTabs,
  dateActive,
  roomActive,
  selectStep,
  subscriptions,
  onPause,
  onPlay,
  user,
  onProgress,
  onEnded,
  handleChange,
  participant,
  loadingProgram,
  scheduleType,
  roomTabHandleChange,
  dateTabHandleChange,
  filterOnlyVideoActivities,
  handleInputChangeDebounced,
  showActivitiesWithVideo,
  closeShowVideo,
  datePrograming,
  filterByPeriod,
  selectedFilter,
  activityTypes,
  axis,
}) => {
  const styles = useStyles()

  return (
    <>
      <Layout>
        {showVideo && (
          <ModalVideoCourse
            refPlayer={refPlayer}
            onProgress={onProgress}
            onPause={onPause}
            participant={participant}
            lesson={lesson}
            speakers={speakers}
            closeShowVideo={closeShowVideo}
            onPlay={onPlay}
            onEnded={onEnded}
          />
        )}
        <Grid
          spacing={4}
          container>
          <Grid
            item
            container
            xs={12}>
            <SearchTopBar
              formik={formik}
              activityTypes={activityTypes}
              axis={axis}
              filterByPeriod={filterByPeriod}
              handleChange={handleChange}
              handleInputChangeDebounced={handleInputChangeDebounced}
            />
          </Grid>
          <Grid
            item
            xs={12}
            className={styles.navContainer}>
            {loadingProgram ? (
              <Loading />
            ) : (
              <TabNavEad
                user={user}
                tabs={tabs}
                date={date}
                roomTabs={roomTabs}
                dateActive={dateActive}
                roomActive={roomActive}
                scheduling={scheduling}
                selectStep={selectStep}
                participant={participant}
                scheduleType={scheduleType}
                selectedFilter={selectedFilter}
                subscriptions={subscriptions}
                datePrograming={datePrograming}
                roomTabHandleChange={roomTabHandleChange}
                dateTabHandleChange={dateTabHandleChange}
                showActivitiesWithVideo={showActivitiesWithVideo}
                filterOnlyVideoActivities={filterOnlyVideoActivities}
              />
            )}
          </Grid>
        </Grid>
      </Layout>
    </>
  )
}

export default DetailEadView
