import {
  Container,
  Typography,
  Divider,
  Grid,
  Button,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
} from "@material-ui/core";
import React from "react";
import { formatString } from "@itarget/shared";
import { toast } from "../../../redux";
import { MeContainer, Translator } from "@itarget/shared";
import Layout from "../../../components/Layout";
import CardPanel from "../../../components/CardPanel";
import "./style.scss";

const PanelView = ({
  centro_custo_id,
  user,
  goTo,
  openMyData,
  handleOpenMyData,
  handleCloseMyData,
  setStateProfile,
  stateProfile,
  formik,
  sendPhoto,
  gamefication,
}) => {

  const translation = {
    profile: Translator({ transRef: "translation.Perfil" }),
    userPic: Translator({ transRef: "translation.Foto do usuário"}),
    service: Translator({ transRef: "translation.Atendimento" }),
    myServices: Translator({ transRef: "translation.MEUS ATENDIMENTOS" }),
    newService: Translator({ transRef: "translation.NOVO ATENDIMENTO" }),
    name: Translator({ transRef: "translation.nome" }),
    message: Translator({ transRef: "translation.Mensagem" }),
    myAnnuities: Translator({ transRef: "translation.Minhas Anuidades" }),
    myActivities: Translator({ transRef: "translation.Minhas Atividades" }),
    MYANNUITIES: Translator({ transRef: "translation.MINHAS ANUIDADES" }),
    receipt: Translator({ transRef: "translation.RECIBO" }),
    certificate: Translator({ transRef: "translation.CERTIFICADO" }),
    declaration: Translator({ transRef: "translation.DECLARAÇÃO" }),
    myCart: Translator({ transRef: "translation.MEU CARRINHO" }),
    myShopping: Translator({ transRef: "translation.ACOMPANHAR MINHAS COMPRAS" }),
    receipts: Translator({ transRef: "translation.RECIBOS" }),
    courses: Translator({ transRef: "translation.CURSOS" }),
    events: Translator({ transRef: "translation.EVENTOS" }),
    certificates: Translator({ transRef: "translation.CERTIFICADOS" }),
    annals: Translator({ transRef: "translation.ANAIS" }),
  }

  return (
    <Layout>
      <Dialog
        open={openMyData}
        onClose={handleCloseMyData}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="modalMydata-header">
          <Translator transRef="translation.Meus Dados" />
          <IconButton onClick={handleCloseMyData} className="modalMydata-close">
            <Icon>cancel</Icon>
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <MeContainer
            toast={toast}
            user={user}
            centro_custo_id={centro_custo_id}
            tipo_interface_id={103}
          />
        </DialogContent>
      </Dialog>

      <Container className="offers-header title-page">
        <Typography variant="h4"><Translator transRef="translation.PAINEL DE CONTROLE" /></Typography>
      </Container>
      <Divider />
      <Container className="offers-body">
        <Grid container spacing={3}>
          <Grid item xs={12} className="grid-welcome">
            <Typography className="welcome-h1">
              <Translator transRef="translation.Olá" /> {formatString.toLowerCase(user?.data.nome)},
            </Typography>
            <Typography className="welcome-subtitle">
              <Translator transRef="translation.Aqui você tem todos os recursos da plataforma de cursos e
              eventos à sua disposição, de modo fácil e prático." />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <CardPanel
              title={translation?.profile}
              btns={[
                {
                  label: "MEU PERFIL",
                  icon: "person",
                  onClick: () => setStateProfile("profile"),
                },
                {
                  label: "MEUS DADOS",
                  icon: "info",
                  onClick: () => handleOpenMyData(),
                },
                {
                  label: "ALTERAR SENHA",
                  icon: "password",
                  onClick: () => setStateProfile("changePassword"),
                },
                {
                  label: "GAMIFICAÇÃO",
                  icon: "sports_esports",
                  onClick: () => setStateProfile("gamification"),
                },
              ]}
            >
              {stateProfile === "profile" && (
                <>
                  <Grid item xs={3} className="grid-person-img">
                    <label htmlFor="btn-upload">
                      <input
                        id="btn-upload"
                        name="btn-upload"
                        style={{ display: "none" }}
                        type="file"
                        accept="image/*"
                        onChange={(e) => sendPhoto(e)}
                      />

                      <div class="overlay">
                        <Icon className="overlay-icon">edit</Icon>
                      </div>
                    </label>
                    <img
                      alt={translation?.userPic}
                      src={
                        user.data.foto
                          ? user.data.foto
                          : "assets/images/avatar.png"
                      }
                      className="img-person"
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="h4">
                      {formatString.toLowerCase(user?.data.nome)}
                    </Typography>
                    <Grid className="grid-infoUser">
                      <Grid>
                        <Typography
                          variant="caption"
                          style={{ fontWeight: 100 }}
                        >
                          {user?.data.email}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          variant="caption"
                          style={{ fontWeight: 100 }}
                        >
                           <Translator transRef="translation.Status:" /> Ativo
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          variant="caption"
                          style={{ fontWeight: 100 }}
                        >
                          <Translator transRef="translation.Acesso:" /> 04/06/2021
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid className="grid-btn-right">
                      <Button className="btn-black">
                        <Translator transRef="translation.Adicionar ao Apple Wallet" />
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
              {stateProfile === "changePassword" && (
                <Grid className="grid-changePassword">
                  <form onSubmit={formik.handleSubmit} className="form">
                    <Grid item xs={12} className="grid-form">
                      <TextField
                        type={"password"}
                        name="senha"
                        onChange={formik.handleChange}
                        required={true}
                        className="text-field"
                        label="Nova senha"
                      />
                    </Grid>
                    <Grid item xs={12} className="grid-form">
                      <TextField
                        type={"password"}
                        name="confirma_senha"
                        onChange={formik.handleChange}
                        required={true}
                        className="text-field"
                        label="Confirme a senha"
                      />
                    </Grid>
                    <Grid item xs={12} className="grid-item">
                      <Button className="btn-submit" type="submit">
                        <Translator transRef="translation.Enviar" />
                      </Button>
                    </Grid>
                  </form>
                </Grid>
              )}
              {stateProfile === "gamification" &&
                gamefication?.data.map((row, i) => (
                  <Grid key={i} className="grid-gamification">
                    <Grid className="gamification-description">
                      {row.descricao}:
                    </Grid>
                    <Grid className="gamification-score">{row.pontuacao}</Grid>
                  </Grid>
                ))}
              <Grid className="grid-gamification-total">
                <Grid className="gamification-description"><Translator transRef="translation.Total:" /></Grid>
                <Grid className="gamification-score">
                  {gamefication?.data
                    .map((r) => r.pontuacao)
                    .reduce((total, numero) => {
                      return total + numero;
                    }, 0)}
                </Grid>
              </Grid>
            </CardPanel>

            <CardPanel
              title={translation?.service}
              btns={[
                {
                  label: translation?.myServices,
                  icon: "chat",
                  onClick: () => console.log("MEUS ATENDIMENTOS"),
                },
                {
                  label: translation?.newService,
                  icon: "chat_bubble_outline",
                  onClick: () => console.log("NOVO ATENDIMENTO"),
                },
              ]}
            >
              <form className="form-atendimento">
                <Grid item xs={12} className="grid-form">
                  <TextField
                    name="name"
                    // onChange={formik.handleChange}
                    required={true}
                    className="text-field"
                    label={translation?.name}
                  />
                </Grid>
                <Grid item xs={12} className="grid-form">
                  <TextField
                    multiline
                    rows={6}
                    name="confirma_senha"
                    // onChange={formik.handleChange}
                    required={true}
                    className="text-field"
                    label={translation?.message}
                  />
                </Grid>
              </form>
            </CardPanel>
          </Grid>
          <Grid item xs={6}>
            <CardPanel
              title={translation?.myAnnuities}
              btns={[
                {
                  label: translation?.MYANNUITIES,
                  icon: "event_available",
                  onClick: () => goTo("my-activity"),
                },
                {
                  label: translation?.receipt,
                  icon: "insert_drive_file",
                  onClick: () => console.log("RECIBO"),
                },
                {
                  label: translation?.certificate,
                  icon: "card_membership",
                  onClick: () => console.log("CERTIFICADO"),
                },
                {
                  label: translation?.declaration,
                  icon: "insert_drive_file",
                  onClick: () => console.log("DECLARAÇÃO"),
                },
              ]}
            >
              <Grid className="grid-btn-right">
                <Button
                  className="btn-red"
                  endIcon={<Icon>event_available</Icon>}
                >
                  <Translator transRef="translation.PAGAR MINHAS ANUIDADES" />
                </Button>
              </Grid>
            </CardPanel>
            <CardPanel
              title={translation?.myActivities}
              btns={[
                {
                  label: translation?.myCart,
                  icon: "shopping_cart",
                  onClick: () => goTo("shopping-cart"),
                },
                {
                  label: translation?.myShopping,
                  icon: "timeline",
                  onClick: () => goTo("my-activity"),
                },
                {
                  label: translation?.receipts,
                  icon: "insert_drive_file",
                  onClick: () => console.log("CERTIFICADO"),
                },
                {
                  label: translation?.courses,
                  icon: "table_chart",
                  onClick: () => goTo("my-activity"),
                },
                {
                  label: translation?.events,
                  icon: "groups",
                  onClick: () => console.log("DECLARAÇÃO"),
                },
                {
                  label: translation?.certificates,
                  icon: "card_membership",
                  onClick: () => console.log("DECLARAÇÃO"),
                },
                {
                  label: translation?.annals,
                  icon: "article",
                  onClick: () => console.log("DECLARAÇÃO"),
                },
              ]}
            >
              <Grid className="grid-btn-right">
                <Button
                  onClick={() => {
                    goTo("offer");
                  }}
                  className="btn-green"
                  endIcon={<Icon>table_chart</Icon>}
                >
                  <Translator transRef="translation.INSCREVA-SE NOS EVENTOS ATIVOS" />
                </Button>
              </Grid>
            </CardPanel>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default PanelView;
