import { Box, CircularProgress } from '@material-ui/core'
import { useSectionStyles } from './styles'
import { useEffect, useRef, useState } from 'react'

const Section = ({ children }) => {
  const styles = useSectionStyles()
  const [isVisible, setIsVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const ref = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true)
          observer.unobserve(ref.current)
        }
      },
      { threshold: 0.5 }
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [ref.current, setIsVisible])

  useEffect(() => {
    if (isVisible && isLoading) {
      const timeout = setTimeout(() => {
        setIsLoading(false)
      }, 2000)
      return () => clearTimeout(timeout)
    }
  }, [isVisible, isLoading])

  return (
    <>
      {isLoading && isVisible && (
        <Box className={styles.progressContainer}>
          <CircularProgress />
        </Box>
      )}
      <Box
        ref={ref}
        className={styles.container}>
        {isVisible && !isLoading && children}
      </Box>
    </>
  )
}

export default Section
