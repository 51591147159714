import React from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Grid,
  IconButton,
  Typography,
  Icon,
  Tooltip,
  Box,
} from '@material-ui/core'
import { Translator } from '@itarget/shared'
import { useRedirect } from '@itarget/shared'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import './style.scss'
import { makeStyles } from '@material-ui/styles'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(() => ({
  accordionStyle: {
    width: '100%',
    backgroundColor: (props) => (props.theme == 'light' ? '#fff' : '#121212'),
  },
  accordionHeaderStyle: {
    backgroundColor: (props) => (props.theme == 'light' ? '#00000017' : '#ffffff17'),
  },
}))

const TimelineAccordionEad = ({ activities, participant, selectStep, user, subscriptions }) => {
  const systemTheme = useSelector((state) => state.theme.systemTheme)
  const styles = useStyles({ theme: systemTheme })
  const translation = {
    subscribedTooltip: Translator({ transRef: 'translation.Inscrito' }),
    pendingSubscribe: Translator({ transRef: 'translation.Pendente' }),
    onDemand: Translator({ transRef: 'translation.on Demand' }),
    buyActivity: Translator({ transRef: 'translation.Inscreva-se!' }),
  }
  const { goTo } = useRedirect()
  const subscriptionStatus = subscriptions.find((sub) => sub.agenda_atividade_id === activities.agenda_atividade_id)?.status_inscricao
  return (
    <Grid
      container
      alignItems='center'
      justifyContent='center'
      direction='column'>
      <Accordion className={styles.accordionStyle}>
        <AccordionSummary className={styles.accordionHeaderStyle}>
          <Box
            width='100%'
            display='flex'
            alignItems='center'>
            <Box flex={1}>
              <Typography>{activities.titulo}</Typography>
            </Box>
            <Grid item>
              <ArrowDropDown />
            </Grid>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid
              className='lecturer-container'
              alignItems='center'>
              {activities.sub_tema.map((subtopic) => {
                return subtopic.participante.map((lecturer) => {
                  const lecturerInfo = participant[lecturer.participante]
                  let lecturerName = lecturerInfo?.nome_cracha ? lecturerInfo?.nome_cracha : lecturerInfo?.nome
                  return (
                    <>
                      <Grid
                        className='lecturer-details'
                        container>
                        <Grid>
                          <Grid
                            container
                            spacing={1}>
                            {subtopic.titulo && (
                              <Grid
                                container
                                item>
                                <Typography variant='subtitle1'>{subtopic.titulo}</Typography>
                              </Grid>
                            )}
                            <Grid item>
                              <Box
                                display='flex'
                                alignItems='center'
                                flex={1}>
                                <Avatar
                                  className='lecturer-avatar'
                                  alt={lecturerName}
                                  src={lecturerInfo.foto}
                                />
                                <Box
                                  paddingLeft={1}
                                  display='flex'
                                  flexDirection='column'>
                                  <Box display='flex'>
                                    <Typography variant='subtitle2'>
                                      {lecturerName.length > 20 ? `${lecturerName.slice(0, 16)}...` : lecturerName}
                                    </Typography>
                                    {lecturerInfo?.estado && (
                                      <Typography className='lecturer-state'>{`- (${lecturerInfo.estado})`}</Typography>
                                    )}
                                  </Box>
                                  {lecturer?.funcao_descricao && (
                                    <Typography variant='caption'>{lecturer.funcao_descricao}</Typography>
                                  )}
                                </Box>
                              </Box>
                            </Grid>
                            {user.data ? (
                              <Grid>
                                {activities &&
                                  (activities.gera_inscricao_trabalho == 1 || activities.gera_inscricao_trabalho == 3) && (
                                    <>
                                      {subscriptionStatus && subscriptionStatus == 2 && (
                                        <>
                                          <Tooltip title={translation.subscribedTooltip}>
                                            <IconButton
                                              onClick={() => selectStep(subtopic, lecturerInfo.nome)}
                                              edge='end'
                                              size='large'
                                              className='btn-play'>
                                              <Icon
                                                className='play-icon'
                                                fontSize='large'>
                                                verified
                                              </Icon>
                                            </IconButton>
                                          </Tooltip>
                                          {subtopic.exibe_video_palestra !== 'N' && (
                                            <Tooltip title={translation.onDemand}>
                                              <IconButton
                                                onClick={() => selectStep(subtopic, lecturerInfo.nome)}
                                                edge='end'
                                                size='large'
                                                className='btn-play'>
                                                <Icon
                                                  className='play-icon'
                                                  fontSize='large'>
                                                  ondemand_video
                                                </Icon>
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                        </>
                                      )}
                                      {subscriptionStatus && subscriptionStatus == 1 && (
                                        <Tooltip title={translation.pendingSubscribe}>
                                          <IconButton
                                            onClick={() => goTo(`shopping-cart`)}
                                            edge='end'
                                            size='large'
                                            className='btn-play'>
                                            <Icon
                                              className='play-icon'
                                              fontSize='large'>
                                              pending
                                            </Icon>
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                      {!subscriptionStatus && (
                                        <Tooltip title={translation.buyActivity}>
                                          <IconButton
                                            onClick={() => goTo(`offers/${activities.agenda_atividade_id}`)}
                                            edge='end'
                                            size='large'
                                            className='btn-play'>
                                            <Icon
                                              className='play-icon'
                                              fontSize='large'>
                                              add_shopping_cart
                                            </Icon>
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </>
                                  )}
                              </Grid>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )
                })
              })}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}

export default TimelineAccordionEad
