import SocialIcon from '../SocialIcon'

const SocialIcons = ({ socialMedias, client }) => {

  if (!socialMedias?.[client]) {
    return <></>
  }

  return (
    <div className='redes-sociais'>
      {socialMedias[client].map((media) => {
        return <SocialIcon key={media.id} icon={media.icon} size={media?.size} color={media?.color} url={media.url} />
      })}
    </div>
  )
}

export default SocialIcons
