import { Translator } from "@itarget/shared";
import { Grid, Typography, Icon } from "@material-ui/core";

const TotalAssisted = (props) => {
    const { total_assistido } = props;
    return (
        <Grid item className="grid-resume">
            <Grid className="info-resume">
                <Grid item xs={1}>
                    <Icon>slideshow</Icon>
                </Grid>
                <Grid item xs={10}>
                    <Typography>
                        <Translator transRef={'translation.Total assistido'} />
                    </Typography>
                </Grid>
            </Grid>
            <Grid>
                <Typography className="percent-resume percent-watched">
                {`${parseFloat(total_assistido)}%`}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default TotalAssisted