import { Avatar, Badge, Box, Icon, Typography, makeStyles } from '@material-ui/core'
import { Button, Translator, useWindowDimensions, useQueryParams, useDraggable } from '@itarget/shared'

import UserMenu from './UserMenu'
import { useEffect, useState } from 'react'
import MobileHeader from '../MobileHeader'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'column',
    boxShadow: theme.shadows[2],
    backgroundColor: theme.palette.header.menu.backgroundColor,
  },
  backgroundHeaderContainer: {
    zIndex: 1100,
    width: '100%',
    position: 'absolute',
    height: theme.typography.pxToRem(160),
  },
  backgroundHeader: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  container: {
    zIndex: 1200,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(1, 3),
    justifyContent: ({hasLogo}) => (hasLogo? 'space-between' : 'flex-end'),
    height: theme.typography.pxToRem(160),
    maxWidth: theme.breakpoints.values.xl,
  },
  userContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    padding: theme.spacing(0, 1.5),
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.header.userContainer.backgroundColor,
  },
  userInformationContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  subContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1, 3),
    borderTop: `1px solid ${theme.palette.header.sub.border}`,
  },
  subContainerLimit: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: theme.breakpoints.values.xl,
  },
}))

const HeaderDefault = ({
  logo,
  shoppingCarts,
  user,
  goTo,
  onLogout,
  backgroundHeader,
  statusAssociated,
  category,
  onClickLogin,
  onClickShoppingCart,
  onClickRegister,
}) => {
  const { get, set, remove } = useQueryParams()
  const { width: windowDimensions } = useWindowDimensions()
  const settingCourse = useSelector((state) => state.setting.settingCommerce)
  const centerCost = useSelector((state) => state.setting.centerCost)
  const [menuState, setMenuState] = useState({ menu: [], colors: { backgroundColor: null, itemColor: null } })
  const styles = useStyles({ hasLogo: !!logo })
  const [mobile, setMobile] = useState(false)
  let menuActive = get('menuActive')
  let currentMenu = menuState.menu.find((item) => item['menu_order'] == menuActive)

  const onClickItem = (item) => {
    if (item.hasOwnProperty('submenu') && item.submenu.length > 0) {
      let newMenuActive = item['menu_order']
      if (menuActive == newMenuActive) return remove('menuActive')
      set({ param: 'menuActive', value: newMenuActive })
      return
    }

    window.open(item['url'], '_self')
  }

  useEffect(() => {
    const MAX_MENU_SIZE_DESKTOP = 960
    let mobile = windowDimensions <= MAX_MENU_SIZE_DESKTOP
    mobile ? setMobile(true) : setMobile(false)
  }, [setMobile, windowDimensions])

  useEffect(() => {
    try {
      if (settingCourse?.json_cores_barra_menu) {
        let colors = JSON.parse(settingCourse?.json_cores_barra_menu)
        let { itemColor, backgroundColor } = colors
        setMenuState((prevState) => {
          return { ...prevState, colors: { itemColor, backgroundColor } }
        })
      }
      if (settingCourse?.customMenu) {
        let menu = settingCourse?.customMenu
        setMenuState((prevState) => {
          return { ...prevState, menu }
        })
        return
      }
      if (settingCourse?.json_menu) {
        let menu = [...JSON.parse(settingCourse?.json_menu)]
        menu = menu.map((m) => {
          m.title = m.description
          delete m['description']
          if (m.url == '/' && centerCost?.tipo_centro_custo === 7) {
            m.url = '/ead'
          }
          return m
        })
        setMenuState((prevState) => {
          return { ...prevState, menu }
        })
      }
    } catch (e) {}
  }, [centerCost?.tipo_centro_custo, settingCourse?.json_menu, settingCourse?.customMenu, settingCourse?.json_cores_barra_menu])

  if (mobile) {
    return <MobileHeader statusAssociated={statusAssociated} />
  }

  return (
    <header className={styles.header}>
      <Box className={styles.backgroundHeaderContainer}>
        <img
          src={backgroundHeader}
          className={styles.backgroundHeader}
        />
      </Box>
      <Box className={styles.container}>
        <EntityLogo src={logo} />
        <Box className={styles.userContainer}>
          <UserShoppingCart
            items={shoppingCarts?.length}
            onClickShoppingCart={user?.data?.id ? onClickShoppingCart : onClickLogin}
          />
          {!user?.data?.id && (
            <Box className={styles.userInformationContainer}>
              <UserAvatar onClickAvatar={onClickLogin} />
              <ParticipantAccess
                onClickLogin={onClickLogin}
                onClickRegister={onClickRegister}
              />
            </Box>
          )}
          {user?.data?.id && (
            <UserMenu
              user={user}
              onLogout={onLogout}
              goTo={goTo}
              statusAssociated={statusAssociated}
              category={category}
            />
          )}
        </Box>
      </Box>
      <Box className={styles.subContainer}>
        <Box className={styles.subContainerLimit}>
          <MenuArray
            items={menuState.menu}
            onClickItem={onClickItem}
          />
          {currentMenu && currentMenu.hasOwnProperty('submenu') && currentMenu.submenu.length > 0 && (
            <SubMenuArray
              onClickItem={onClickItem}
              father={currentMenu.title}
              items={currentMenu.submenu}
            />
          )}
        </Box>
      </Box>
    </header>
  )
}

// ParticipantAccess
const ParticipantAccess = ({
  onClickLogin = () => {
    alert('DEVELOPMENT\n\nLogin action not defined.')
  },
  onClickRegister = () => {
    alert('DEVELOPMENT\n\nRegistration action not defined.')
  },
}) => {
  const styles = useParticipantAccessStyles()
  const translation = useParticipantAccessTranslation()

  return (
    <Box className={styles.container}>
      <Typography className={styles.title}>{translation.title}</Typography>
      <Button
        onClick={onClickLogin}
        className={styles.loginButton}>
        {translation.login}
      </Button>
      <Typography
        onClick={onClickRegister}
        className={styles.registerLink}>
        {translation.register}
      </Typography>
    </Box>
  )
}

const useParticipantAccessTranslation = () => ({
  register: Translator({ transRef: 'translation.Não sou cadastrado' }).props.children,
  login: Translator({ transRef: 'translation.Login' }).props.children,
  title: Translator({ transRef: 'translation.Área do participante' }).props.children,
})

const useParticipantAccessStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    flexDirection: 'column',
  },
  title: {
    lineHeight: 1,
    fontWeight: '700',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.header.participantAccess.color,
  },
  loginButton: {
    width: '100%',
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.header.participantAccess.buttonBackgroundColor,
    '&:hover': {
      backgroundColor: theme.palette.header.participantAccess.buttonBackgroundColorHover,
    },
    '& .MuiButton-label': {
      lineHeight: 1,
      fontWeight: '500',
      textTransform: 'uppercase',
      fontSize: theme.typography.pxToRem(12),
      backgroundColor: theme.palette.header.participantAccess.buttonBackgroundColorHover,
    },
  },
  registerLink: {
    lineHeight: 1,
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.header.participantAccess.color,
  },
}))
// ParticipantAccess

// UserAvatar
const UserAvatar = ({ alt = '', src = '', className, onClickAvatar }) => {
  let hasAction = !!onClickAvatar
  const styles = useUserAvatarStyles({ hasAction })
  const translation = useUserAvatarTranslation()

  return (
    <Avatar
      src={src}
      size='large'
      alt={alt || translation.notDefined}
      onClick={hasAction && onClickAvatar}
      className={`${styles.avatarDefaultStyle} ${className}`}
    />
  )
}

const useUserAvatarTranslation = () => ({
  notDefined: Translator({ transRef: 'translation.Usuário não definido' }).props.children,
})

const useUserAvatarStyles = makeStyles((theme) => ({
  avatarDefaultStyle: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    color: theme.palette.header.avatar.color,
    background: theme.palette.header.avatar.backgroundColor,
    fontSize: `${theme.typography.pxToRem(28)} !important`,
    cursor: ({ hasAction }) => (hasAction ? 'pointer' : 'default'),
  },
}))
// UserAvatar

// UserShoppingCart
const UserShoppingCart = ({ className, items = 0, onClickShoppingCart = () => {} }) => {
  const styles = useUserShoppingCartStyles()
  const translation = useUserShoppingCartTranslation()

  return (
    <Badge
      overlap='circular'
      badgeContent={items}
      className={styles.badge}>
      <Icon
        onClick={onClickShoppingCart}
        arial-label={translation.arialLabel}
        className={`${styles.shoppingCartDefaultStyle} ${className}`}>
        shopping_cart
      </Icon>
    </Badge>
  )
}

const useUserShoppingCartTranslation = () => ({
  arialLabel: Translator({ transRef: 'translation.Seu carrinho de compras' }).props.children,
})

const useUserShoppingCartStyles = makeStyles((theme) => ({
  badge: {
    '& .MuiBadge-badge': {
      backgroundColor: theme.palette.header.shoppingCart.badgeColor,
    },
  },
  shoppingCartDefaultStyle: {
    cursor: 'pointer',
    fontWeight: 'bold',
    color: theme.palette.header.shoppingCart.color,
    fontSize: `${theme.typography.pxToRem(32)} !important`,
  },
}))
// UserShoppingCart

// EntityLogo
const EntityLogo = ({ src, className, onClickLogo }) => {
  let hasAction = !!onClickLogo
  const translation = useEntityLogoTranslation()
  const styles = useEntityLogoStyles({ hasAction })

  if (!src) return <></>
  return (
    <img
      src={src}
      alt={translation.arialLabel}
      onClick={hasAction ? onClickLogo : undefined}
      className={`${styles.logoDefaultStyle} ${className}`}
    />
  )
}

const useEntityLogoTranslation = () => ({
  arialLabel: Translator({ transRef: 'translation.Logotipo da entidade' }).props.children,
})

const useEntityLogoStyles = makeStyles((theme) => ({
  logoDefaultStyle: {
    maxHeight: theme.typography.pxToRem(100),
    aspectRatio: 2,
    objectFit: 'contain',
    cursor: ({ hasAction }) => (hasAction ? 'pointer' : 'default'),
  },
}))
// EntityLogo

// MenuArray
const MenuArray = ({ items = [], onClickItem = ({}) => {} }) => {
  const styles = useMenuArrayStyles()
  return (
    <Box className={styles.container}>
      {items.map((item, index) => (
        <MenuArrayItem
          item={item}
          key={`MenuArray-${index}`}
          onClick={onClickItem.bind(this, item)}
        />
      ))}
    </Box>
  )
}

const useMenuArrayStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}))

const MenuArrayItem = ({ item, onClick = ({}) => {} }) => {
  const { get } = useQueryParams()
  const styles = useMenuArrayItemStyles()
  let currentActive = get('menuActive')
  let isActive = item.hasOwnProperty('menu_order') ? currentActive == item['menu_order'] : false
  return (
    <Box
      onClick={onClick}
      data-active={isActive}
      className={styles.container}>
      <Typography className={styles.label}>{item.title}</Typography>
    </Box>
  )
}

const useMenuArrayItemStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 300ms',
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: theme.palette.header.menuItem.backgroundHover,
    },
    '&[data-active="true"]': {
      backgroundColor: theme.palette.header.menuItem.backgroundActive,
    },
    '&[data-active="true"]:hover': {
      boxShadow: theme.shadows[1],
    },
  },
  label: {
    lineHeight: 1,
    fontWeight: '500',
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.header.menuItem.color,
  },
}))
// MenuArray

// SubMenuArray
const SubMenuArray = ({ father, items = [], onClickItem = ({}) => {} }) => {
  const styles = useSubMenuArrayStyles()
  const { elementRef } = useDraggable()

  return (
    <Box className={styles.container}>
      <Typography className={styles.father}>{father}</Typography>
      <Box
        ref={elementRef}
        className={styles.menuContainer}>
        {items.map((item, index) => (
          <SubMenuArrayItem
            item={item}
            key={`SubMenuArray-${index}`}
            onClick={onClickItem.bind(this, item)}
          />
        ))}
      </Box>
    </Box>
  )
}

const useSubMenuArrayStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(5),
    padding: theme.spacing(1, 3),
    maxWidth: theme.breakpoints.values.xl,
  },
  father: {
    lineHeight: 1,
    fontWeight: '500',
    textWrap: 'nowrap',
    position: 'relative',
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.header.sub.color,
    '&:before, &:after': {
      right: -38,
      width: 31,
      height: 31,
      top: '50%',
      transform: `translateY(-50%) rotate(45deg)`,
      content: "''",
      position: 'absolute',
    },
    '&:before': {
      backgroundColor: theme.palette.header.sub.border,
    },
    '&:after': {
      right: -37,
      backgroundColor: theme.palette.header.menu.backgroundColor,
    },
  },
  menuContainer: {
    flex: 1,
    display: 'flex',
    overflowX: 'scroll',
    alignItems: 'center',
    gap: theme.spacing(1),
    scrollbarWidth: 'none',
    marginLeft: theme.spacing(2),
    '-ms-overflow-style': 'none',
    '&-webkit-scrollbar': {
      display: 'none',
    },
  },
}))

const SubMenuArrayItem = ({ item, onClick = ({}) => {} }) => {
  const styles = useSubMenuArrayItemStyles()
  return (
    <Box
      onClick={onClick}
      className={styles.container}>
      <Typography className={styles.label}>{item.title}</Typography>
    </Box>
  )
}

const useSubMenuArrayItemStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    transition: 'all 300ms',
    '&:hover': {
      backgroundColor: theme.palette.header.menuItem.backgroundHover,
    },
    '&[data-active="true"]': {
      backgroundColor: theme.palette.header.menuItem.backgroundActive,
    },
    '&[data-active="true"]:hover': {
      boxShadow: theme.shadows[1],
    },
  },
  label: {
    lineHeight: 1,
    textWrap: 'nowrap',
    lineBreak: 'loose',
    textTransform: 'uppercase',
    color: theme.palette.header.sub.color,
    fontSize: theme.typography.pxToRem(12),
  },
}))
// SubMenuArray

export default HeaderDefault
