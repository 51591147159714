import { Box, Typography } from '@material-ui/core'
import Amount from './Amount'
import { useTotalStyles } from './styles'
import { useTotalTranslation } from './translation'

const Total = ({ amount = 0 }) => {
  const styles = useTotalStyles()
  const translation = useTotalTranslation()
  return (
    <Box className={styles.container}>
      <Typography className={styles.label}>{translation.label}</Typography>
      <Amount amount={amount} className={styles.amount} />
    </Box>
  )
}

export default Total