import { Box, Chip, Icon, Typography } from '@material-ui/core'
import { useShoppingCartStyles } from './styles'
import { useShoppingCartTranslation } from './translation'

const ShoppingCart = ({ onRedirect = () => {} }) => {
  const styles = useShoppingCartStyles()
  const translation = useShoppingCartTranslation()

  return (
    <Box className={styles.container}>
      <Box className={styles.actionsContainer}>
        <Chip
          className={styles.chip}
          label={translation.added}
        />
        <Box
          onClick={onRedirect}
          className={styles.linkContainer}>
          <Icon className={styles.linkIcon}>shopping_cart_checkout</Icon>
          <Typography className={styles.link}>{translation.redirectToShoppingCart}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default ShoppingCart
