import * as actions from "./actions";
import produce from "immer";

const initialState = {
  inPayment: false,
  type: "",
  total: 0,
  voucher: {},
  statusReceipt: null,
};

export const reducer = produce((state, action) => {
  switch (action.type) {
    case actions.SET_PAYMENT_TYPE:
      state.type = action.payload.paymentType;
      break;
    case actions.SET_TOTAL:
      state.total = action.payload.total;
      break;
    case actions.SET_IN_PAYMENT:
      state.inPayment = false;
      break;
    case actions.SET_VOUCHER:
      state.voucher = action.payload.voucher;
      break;
    case actions.SET_STATUSRECEIPT:
      state.statusReceipt = action.payload.data;
      break;
    case actions.RESET_INITIALSTATE:
      state.inPayment = false;
      state.type = "";
      state.total = 0;
      state.voucher = "";
      state.codVoucher = "";
      state.statusReceipt = null;
      break;
    default:
      break;
  }
}, initialState);
