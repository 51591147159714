import { makeStyles } from '@material-ui/styles'

export const useLoadingStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    justifyContent: 'center',
    padding: theme.spacing(1.5, 1),
    backgroundColor: theme.palette.loading.backgroundColor,
  },
  loadingIcon: {
    animation: '$spin 1s linear infinite',
    fontSize: `${theme.typography.pxToRem(16)} !important`,
    color: theme.palette.loading.iconColor,
  },
  loadingMessage: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.loading.color,
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(360deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
  },
}))
