import { Grid, Typography, Icon, IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import "./style.scss";

const CardAlertNecessary = ({ card, message, setMessage, errorSubscriptionData }) => {
  const preActivity = errorSubscriptionData?.preActivitySchedule ? errorSubscriptionData?.preActivitySchedule[0] : null;
  return (
    <Grid container className="cardShopping">
      <Grid item xs={10} className="grid-infos">
        <Typography className="title">{card && card.desc_atv}</Typography>
        <Grid className="more-infos">
          <Grid className="infos-item">
            <Icon className="infos-icon">room</Icon>
            <Typography className="infos-text">{card && card.desc_tatv}</Typography>
          </Grid>
          {preActivity && (
            <Grid className="infos-button">
              <Link to={`/offers/${preActivity.agenda_atividade_pre_id}`} onClick={() => setMessage()} className="infos-text">
                {preActivity?.descricao}
              </Link>
            </Grid>
          )}
        </Grid>
        <Typography className="message-fail">
          {message && message}
        </Typography>
      </Grid>
      <IconButton onClick={() => setMessage()} className="close-icon">
        <Icon>close</Icon>
      </IconButton>
    </Grid>
  );
};

export default CardAlertNecessary;
