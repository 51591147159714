import { all, put, takeEvery } from "redux-saga/effects";

import * as actions from "./actions";
import { actions as toastActions } from "../toast";

function* voucherFulfilled(action) {
  const selectedCourse = action.meta.selectedCourse;
  const user = action.meta.user;
  const currDiscount = action.payload.data.agenda_atividade_id
    ? selectedCourse.filter(
        (r) => r.aga_id === action.payload.data.agenda_atividade_id
      )
    : selectedCourse[0];
}

function* voucherFailed(action) {
  yield put(
    toastActions.enqueue({
      content: action.payload.message,
      severity: "error",
    })
  );
}

function* watchForVoucherFulfilled() {
  yield takeEvery(actions.VOUCHER.FULFILLED, voucherFulfilled);
}

function* watchForVoucherFailed() {
  yield takeEvery(actions.VOUCHER.FAILED, voucherFailed);
}

function* rootSaga() {
  yield all([watchForVoucherFulfilled(), watchForVoucherFailed()]);
}

export default rootSaga;
