import { makeStyles } from '@material-ui/styles'

export const useItemStyles = makeStyles((theme) => ({
  container: {
    minWidth: 50,
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    width: 'fit-content',
    gap: theme.spacing(1),
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(1, 1.5),
    borderRadius: theme.spacing(1.5),
    border: `1px solid ${theme.palette.dateFilterPicker.container.border}`,
    backgroundColor: theme.palette.dateFilterPicker.container.backgroundColor,
    '&[data-selected="true"]': {
      backgroundColor: theme.palette.primary.main,
      '& $day, & $month': {
        color: `${theme.palette.dateFilterPicker.text.colorSelected} !important`,
      },
    },
    '&[data-enabled="false"]': {
      opacity: 0.5,
      pointerEvents: 'none',
    },
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      flexDirection: 'row',
      padding: theme.spacing(0.75, 1),
      borderRadius: theme.spacing(1),
    },
  },
  day: {
    ...theme.typography.h6,
    lineHeight: 1,
    fontWeight: '700',
    color: theme.palette.dateFilterPicker.text.color,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      ...theme.typography.body2,
      fontWeight: '700',
    },
  },
  month: {
    ...theme.typography.subtitle2,
    lineHeight: 1,
    color: theme.palette.dateFilterPicker.text.color,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      ...theme.typography.caption,
    },
  },
}))

export const useRootStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'no-wrap',
    gap: ({ spacing }) => theme.spacing(spacing),
    flexDirection: ({ direction }) => direction,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      flexWrap: 'wrap',
      flexDirection: 'row !important',
    },
  },
}))
