import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { useFetchApi, Config, Translator, useRedirect, ReplaceAllInstances } from '@itarget/shared'
import { toast } from '../../redux'
import DetailMyCourseGrouped from './views/DetailMyCourseGrouped'
import { parseFloat } from 'globalthis/implementation'

const DetailMyCourseGroupContainer = () => {
  const dispatch = useDispatch()
  const { goTo } = useRedirect()
  const refPlayer = useRef()
  const { id } = useParams()
  const [showVideo, setShowVideo] = useState(false)
  const [showQuiz, setShowQuiz] = useState(false)
  const [endVideo, setEndVideo] = useState(false)
  const [progress, setProgress] = useState({
    playedSeconds: 0,
    played: 0,
    loadedSeconds: 0,
    loaded: 0,
  })
  const [totalWatch, setTotalWatch] = useState(0)
  const [percentNextVideo, setPercentNextVideo] = useState(1)
  const [lesson, setLesson] = useState('')
  const [speakers, setSpeakers] = useState('')
  const [questions, setQuestions] = useState({})
  const selectedLang = useSelector((state) => state.language.lang)
  const user = useSelector((state) => state.auth.user)
  const settingCommerce = useSelector((state) => state.setting.settingCommerce)
  const [secondsLeft, setSecondsLeft] = useState()
  const [assistedTimeout, setAssistedTimeout] = useState()
  const [secondsLeftQuiz, setSecondsLeftQuiz] = useState()
  const [quizTimeout, setQuizTimeout] = useState()
  const [intervalId, setIntervalId] = useState()

  const translation = {
    exceededResponses: Translator({
      transRef:
        'translation.Atenção: Todas as tentativas disponibilizadas para o participante já foram utilizadas. Não é possível acessar este questionário.',
    }),
    alreadyAnswered: Translator({ transRef: 'translation.Você já respondeu o questionário!' }),
    attempts: Translator({ transRef: 'translation.Atenção: Você possui %s tentativas restantes.' }).props.children,
    attempt: Translator({ transRef: 'translation.Atenção: Você possui %s tentativa restante.' }).props.children,
  }

  useEffect(() => {
    if (settingCommerce?.meta_proximovideo) {
      setPercentNextVideo(parseFloat(settingCommerce?.meta_proximovideo) / 100)
    }
  }, [settingCommerce])

  const { fetchData: fetchSendVideo } = useFetchApi(Config.baseURL + `api/${selectedLang}/video-frequency`, 'POST')

  const {
    data: getVideo,
    error: errorGetVideo,
    fetchData: fetchGetVideo,
  } = useFetchApi(
    Config.baseURL +
    `api/${selectedLang}/video-frequency/${lesson && lesson.agenda_atividade_detalhe_id}/resume?pessoa_id=${user.data && user.data.id
    }`
  )

  const {
    data: dataDetail,
    error: errorDetail,
    fetchData: fetchDetail,
    isLoading: isLoadingDetail,
  } = useFetchApi(Config.baseURL + `api/${selectedLang}/activity-schedule/${id}/detail?pessoa_id=${user?.data?.id}&group_by=1`)

  const { fetchData: fetchTrack } = useFetchApi(Config.baseURL + `api/${selectedLang}/log/track`, 'POST')

  useEffect(() => {
    fetchDetail()
    return () => {
      resetState()
    }
  }, [id, fetchDetail])

  useEffect(() => {
    if (errorGetVideo !== null) {
      dispatch(
        toast.actions.enqueue({
          content: errorGetVideo,
          severity: 'error',
        })
      )
    }
  }, [errorGetVideo, dispatch])

  useEffect(() => {
    if (errorDetail !== null) {
      dispatch(
        toast.actions.enqueue({
          content: errorDetail,
          severity: 'error',
        })
      )
    }
  }, [errorDetail, dispatch])

  useEffect(() => {
    if (showVideo && lesson) {
      fetchGetVideo()
    }
  }, [showVideo, lesson, fetchGetVideo])

  useEffect(() => {
    if (getVideo && getVideo.data.posicao && refPlayer.current) {
      const posicao = getVideo.data.posicao
      const time = posicao.seconds ? posicao.seconds : posicao.playedSeconds
      setTotalWatch(posicao.playedSeconds)
      if (posicao.played !== 1 && getVideo.data.tipo !== 'F') {
        refPlayer.current.seekTo(time)
        setProgress(posicao)
      }
    }
  }, [getVideo])

  useEffect(() => {
    if (lesson && lesson?.formulario?.length > 0) {
      setQuestions(lesson.formulario[0])
      return
    }
    setQuestions({})
  }, [lesson])

  const selectStep = (lesson, speakers, type) => {
    setLesson(lesson)
    setSpeakers(speakers)
    if (type == 'question') {
      let quiz = lesson.formulario?.[0]
      let canYouAnswer = !quiz?.respondido || quiz?.libera_tentativa
      let attemptsLeft = quiz.meta_num_tentativas - quiz.tentativas
      if (!lesson?.formulario[0]?.pode_responder) {
        dispatch(
          toast.actions.enqueue({
            content: quiz.mensagem_limite_tempo,
            severity: 'info',
          })
        )
        return
      }
      if (!canYouAnswer) {
        dispatch(
          toast.actions.enqueue({
            content: translation.exceededResponses,
            severity: 'warning',
          })
        )
        return
      }
      if (attemptsLeft != 0 && quiz.meta_num_tentativas) {
        dispatch(
          toast.actions.enqueue({
            content: ReplaceAllInstances(translation[Number(attemptsLeft) == 1 ? 'attempt' : 'attempts'], [attemptsLeft]),
            severity: 'warning',
          })
        )
      }
      if (!lesson?.formulario[0]?.data_inicio_questionario && lesson?.formulario[0]?.disponivel_por > 0) {
        fetchTrack(
          JSON.stringify({
            trackable_id: quiz.formulario_pergunta_id,
            trackable_type: 'formularios_perguntas',
            pessoa_id: user.data.id,
            action: 'QUIZ_STARTED',
          })
        )
      }
      setShowQuiz(true)
      return
    }
    if (!lesson.pode_assistir) {
      dispatch(
        toast.actions.enqueue({
          content: lesson.mensagem_limite_tempo,
          severity: 'info',
        })
      )
      return
    }
    setShowVideo(true)
  }

  const resetState = () => {
    setProgress({ playedSeconds: 0, played: 0, loadedSeconds: 0, loaded: 0 })
    setTotalWatch(0)
    setLesson('')
    setEndVideo(false)
    setSecondsLeft()
    setAssistedTimeout()
    setSecondsLeftQuiz()
    setQuizTimeout()
    setIntervalId()
    fetchDetail()
    return () => {
      resetState()
    }
  }

  const sendVideo = (tipo, posicao) => {
    const seconds = posicao !== undefined ? posicao.playedSeconds : progress.playedSeconds
    const position = posicao !== undefined ? posicao : progress

    if (position.loaded >= percentNextVideo) {
      tipo = 'F'
    }

    fetchSendVideo(
      JSON.stringify({
        agenda_atividade_detalhe_id: lesson.agenda_atividade_detalhe_id,
        pessoa_id: user.data.id,
        tipo: tipo,
        posicao: posicao ? posicao : progress,
        replay: totalWatch > seconds || endVideo ? 'S' : 'N',
      })
    )
  }

  const closeShowVideo = async () => {
    if (progress.loadedSeconds !== progress.playedSeconds) {
      await sendVideo('P')
    }
    fetchDetail()
    resetState()
    setShowVideo(false)
  }

  const onPause = (e) => {
    if (e.percent < 1) {
      sendVideo('P')
    }
  }

  const onPlay = () => {
    sendVideo('I')
    if (!lesson.data_inicio_video && lesson.disponivel_por > 0) {
      fetchTrack(
        JSON.stringify({
          trackable_id: lesson.agenda_atividade_detalhe_id,
          trackable_type: 'agenda_atividades_detalhes',
          pessoa_id: user.data.id,
          action: 'VIDEO_STARTED',
        })
      )
    }
    limitTimeVideo()
  }

  const onProgress = (e) => {
    if (totalWatch < e.playedSeconds) {
      setTotalWatch(e.playedSeconds)
    }
    if (e.played !== 1) {
      setProgress(e)
      sendVideo('P', e)
    }
  }

  const onEnded = () => {
    const position = {
      playedSeconds: progress.loadedSeconds,
      played: 1,
      loadedSeconds: progress.loadedSeconds,
      loaded: progress.loaded,
    }
    setTotalWatch(progress.loadedSeconds)
    sendVideo('F', position)
    setEndVideo(true)
    setProgress({ playedSeconds: 0, played: 0, loadedSeconds: 0, loaded: 0 })
  }

  const closeShowQuiz = () => {
    fetchDetail()
    if (intervalId) {
      clearInterval(intervalId)
    }
    setShowQuiz(false)
  }

  const setOpenQuestionnaire = () => {
    if (dataDetail?.data?.perguntas?.respondido) {
      dispatch(
        toast.actions.enqueue({
          content: translation.alreadyAnswered,
          severity: 'info',
        })
      )
    } else {
      setQuestions(dataDetail.data.perguntas)
      setShowQuiz(true)
    }
  }

  const setOpenCertificate = (url) => {
    window.open(url)
  }

  const limitTimeVideo = () => {
    if (intervalId) {
      clearInterval(intervalId)
    }
    let iid = setInterval(() => {
      if (assistedTimeout) {
        setAssistedTimeout((assisted) => {
          assisted -= 1
          return assisted
        })
      }
      if (secondsLeft) {
        setSecondsLeft((seconds) => {
          if (seconds < 1) {
            goTo(`my-course/${lesson.agenda_atividade_id}`)
            return
          }
          seconds -= 1
          return seconds
        })
      }
    }, 1000)

    setIntervalId(iid)
  }

  const limitTimeQuiz = () => {
    if (intervalId) {
      clearInterval(intervalId)
    }
    let iid = setInterval(() => {
      if (quizTimeout) {
        setQuizTimeout((quizView) => {
          quizView -= 1
          return quizView
        })
      }
      if (secondsLeftQuiz < 1) {
        goTo(`my-course/${lesson.agenda_atividade_id}`)
      }
      if (secondsLeftQuiz) {
        setSecondsLeftQuiz((seconds) => {
          seconds -= 1
          return seconds
        })
      }
    }, 1000)

    setIntervalId(iid)
  }

  useEffect(() => {
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  useEffect(() => {
    if (lesson) {
      if (lesson.disponivel_por) {
        if (lesson.data_inicio_video) {
          let date1 = new Date(lesson.data_inicio_video.replace(/\.\d+$/, '').replace(' ', 'T'))
          let date2 = new Date(lesson.data_hora)
          let differenceInSeconds = parseInt(Math.abs((date1.getTime() - date2.getTime()) / 1000))
          setSecondsLeft(lesson.disponivel_por * 60 - differenceInSeconds)
        } else {
          setSecondsLeft(lesson.disponivel_por * 60)
        }
      }

      if (lesson?.formulario[0]?.disponivel_por) {
        if (lesson?.formulario[0]?.data_inicio_questionario) {
          let date1 = new Date(lesson?.formulario[0]?.data_inicio_questionario.replace(/\.\d+$/, '').replace(' ', 'T'))
          let date2 = new Date(lesson?.formulario[0]?.data_hora)
          let differenceInSeconds = parseInt(Math.abs((date1.getTime() - date2.getTime()) / 1000))
          setSecondsLeftQuiz(lesson?.formulario[0]?.disponivel_por * 60 - differenceInSeconds)
        } else {
          setSecondsLeftQuiz(lesson?.formulario[0]?.disponivel_por * 60)
        }
      }
    }
  }, [lesson])

  return (
    <DetailMyCourseGrouped
      isLoadingDetail={isLoadingDetail}
      id={id}
      refPlayer={refPlayer}
      showVideo={showVideo}
      detail={dataDetail}
      lesson={lesson}
      speakers={speakers}
      showQuiz={showQuiz}
      questions={questions}
      closeShowVideo={closeShowVideo}
      selectStep={selectStep}
      onPause={onPause}
      onPlay={onPlay}
      onProgress={onProgress}
      onEnded={onEnded}
      closeShowQuiz={closeShowQuiz}
      setOpenQuestionnaire={setOpenQuestionnaire}
      setOpenCertificate={setOpenCertificate}
      percentNextVideo={percentNextVideo}
      secondsLeft={secondsLeft}
      secondsLeftQuiz={secondsLeftQuiz}
      limitTimeQuiz={limitTimeQuiz}
    />
  )
}

export default DetailMyCourseGroupContainer
