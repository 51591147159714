import { Box, Icon, MenuItem, Select, Typography } from '@material-ui/core'
import { Button } from '@itarget/shared'
import { useCategoryStyles } from './styles'
import { useCategoryTranslation } from './translation'
import { useFormik } from 'formik'

const Category = ({ categories = [], onConfirmCategory = ({ value = 0 }) => {} }) => {
  const initialValues = { 'DisplayValuesOffers-category-select': 0 }
  const onSubmit = (values) => {
    let selectedCategory = values['DisplayValuesOffers-category-select']
    if (!selectedCategory) return
    onConfirmCategory({ value: selectedCategory })
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
  })

  const styles = useCategoryStyles()
  const translation = useCategoryTranslation()

  let category = categories.find((category) => category.id == formik.values['DisplayValuesOffers-category-select'])

  if (categories.length == 0) return <></>

  return (
    <Box className={styles.container}>
      <Box className={styles.selectContainer}>
        <Typography className={styles.selectLabel}>{translation.selectLabel}</Typography>
        <Select
          variant='outlined'
          className={styles.select}
          onChange={formik.handleChange}
          id='DisplayValuesOffers-category-select'
          name='DisplayValuesOffers-category-select'
          value={formik.values['DisplayValuesOffers-category-select']}>
          <MenuItem
            value={0}
            className={styles.selectItem}
            key={`DisplayValuesOffers-category-select-item-default`}>
            {translation.defaultValue}
          </MenuItem>
          {categories.map((category, index) => {
            return (
              <MenuItem
                value={category.id}
                className={styles.selectItem}
                key={`DisplayValuesOffers-category-select-item-${index}`}>
                {category.descricao}
              </MenuItem>
            )
          })}
        </Select>
      </Box>
      <Button
        icon='done'
        disabled={!category}
        onClick={formik.handleSubmit}
        className={`${styles.button} ${styles.buttonOnConfirmCategory}`}>
        {translation.confirmCategory}
      </Button>
      <Box className={styles.alertContainer}>
        <Box className={styles.messageContainer}>
          <Icon className={styles.warningIcon}>warning</Icon>
          <Typography className={`${styles.message} ${styles.noCategoryMessage}`}>{translation.noCategoryMessage}</Typography>
        </Box>
        {category && (category.exige_comp_cat_evento == 'S' || category.exige_comp_cat_evento_insc == 'S') && (
          <Box className={styles.messageContainer}>
            <Icon className={styles.taskIcon}>task</Icon>
            <Typography className={`${styles.message} ${styles.organizingCommitteeMessage}`}>
              {translation.organizingCommitteeMessage}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default Category
