import styled from 'styled-components';

export const Styles = styled.div`
    font-weight: bold;
    
    text-align: ${props => props.align};

    ${props => props.size === 'sm' && `
        span {
            font-size: 20px;
        }
        span.value {
            font-size: 20px;
        }
    `}

    ${props => props.size === 'lg' && `
        span {
            font-size: 40px;
        }
        span.value {
            font-size: 40px;
        }
    `}
    
`;