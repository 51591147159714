import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { Config, useFetchApi } from "@itarget/shared"
import { useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core"
import PageView from "./views/PageView"

const PageContainer = () => {
  const selectedLang = useSelector((state) => state.language.lang)
  const routeParams = useParams()

  const { data, fetchData, isLoading } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/document-template/page?id=${routeParams?.id}`,
    "GET"
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return <>{isLoading ? <CircularProgress /> : <PageView page={data} />}</>
}

export default PageContainer
