import React from "react";
import { Switch, Route } from "react-router-dom";

import OffersContainer from "./OffersContainer";
import DetailOfferContainer from "./DetailOfferContainer";
import DetailStoreContainer from "./DetailStoreContainer";

const RouterOffers = () => {
  return (
    <Switch>
      <Route path="/offers/store/:id/:cc?">
        <DetailStoreContainer />
      </Route>
      <Route path="/offers/:id/:cc?">
        <DetailOfferContainer />
      </Route>
      <Route path="/offers">
        <OffersContainer />
      </Route>
    </Switch>
  );
};

export default RouterOffers;
