import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import { useEffect, useState } from 'react'
import './style.js'
import useStyles from './style.js'

const ButtonArrowToTop = () => {
  const [scrollPosition, setScrollPosition] = useState(0)
  let hidden = scrollPosition < 300
  const styles = useStyles()

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  const handleClickGoToTheTopOfThePage = () => {
    document.documentElement.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div
      className={`${styles.buttonArrowToTop} ${hidden ? styles.hidden : ''}`}
      onClick={() => handleClickGoToTheTopOfThePage()}>
      <ArrowUpwardIcon />
    </div>
  )
}

export default ButtonArrowToTop
