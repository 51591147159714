import "./style.scss";
import { useStyles } from "./styles";
import { CardMedia, Grid, Typography } from "@material-ui/core";
import ButtonArrowToTop from "../../../components/ButtonArrowToTop/ButtonArrowToTop";
import CardDetailStore from "../../../components/CardDetailStore";
import CarouselCardOffers from "../../../components/CarouselCardOffers";
import Layout from "../../../components/Layout";
import ProductSubtitle from "../../../components/ProductSubtitle";
import { Translator } from "@itarget/shared";

const StoreView = ({ center_cost_id, id, selectProduct, offers, groupByOffers }) => {
  const classes = useStyles();

  const translation = {
    imageTitle: Translator({ transRef: "translation.titulo imagem teste" }),
  }

  return (
    <Layout>
      <Grid item xs={12}>
        <div className={classes.container}>
          <CardMedia
            className={classes.img}
            title={translation.imageTitle}
            image="https://telemedicinamorsch.com.br/wp-content/uploads/2016/08/TELEMEDICINA-MORSCH_header_19_8_2016.png"
          />
        </div>
      </Grid>
      <Grid sx={12} item className={classes.promotion}>
        <Grid item sm={4}>
          <Typography variant="h3" className={classes.info}>
            <Translator transRef="translation.Destaques e promoções" />
          </Typography>
        </Grid>
        <Grid item sm={8} className={classes.carousel}>
          <CarouselCardOffers selectProduct={selectProduct} cc={center_cost_id} id={id} />
        </Grid>
      </Grid>
      <Grid container item sx={12} style={{ padding: "0 10%" }} className={classes.offers}>
        <ProductSubtitle text="Produtos relacionados:" width="20%" />
        {groupByOffers &&
          Object.entries(offers).map(([_, offer], i) => {
            return offer.map((row, i) => (
              <Grid className="offersModal-responsive" key={i} item xs={6} sm={6} md={4} lg={3} xl={2}>
                <CardDetailStore direction="row" row={row} selectOffer={selectProduct} />
              </Grid>
            ));
          })}
      </Grid>
      <ButtonArrowToTop />
    </Layout>
  );
};

export default StoreView;
