import { Box } from '@material-ui/core'
import { useAllSpeakersStyles } from './styles'
import SpeakerItem from './SpeakerItem'
import { useState } from 'react'

const AllSpeakers = ({ max = 3, data, handleClickSpeaker = null }) => {
  const [currentList, setCurrentList] = useState(0)
  const styles = useAllSpeakersStyles()
  const handleOnClick = () => {
    handleClickSpeaker && handleClickSpeaker(data)
  }

  const handleCurrentList = (index) => {
    setCurrentList(index)
  }

  let list = data
  let limitList = list.length > max
  if (limitList) {
    list = Array.from({ length: Math.ceil(list.length / max) }, (_, index) => list.slice(index * max, (index + 1) * max))
  }

  return (
    <Box className={styles.container}>
      {!limitList &&
        list.map((speaker, index) => {
          return (
            <SpeakerItem
              data={speaker}
              enableRounded={false}
              onClick={handleOnClick}
              key={`${index}_${speaker.id}_${speaker.name}`}
            />
          )
        })}
      {limitList && (
        <>
          {list[currentList].map((speaker, index) => {
            return (
              <SpeakerItem
                data={speaker}
                enableRounded={false}
                onClick={handleOnClick}
                key={`${index}_${speaker.id}_${speaker.name}`}
              />
            )
          })}
          <Box className={styles.footer}>
            {list.map((_, index) => {
              return (
                <Box
                  data-page={index}
                  className={styles.dot}
                  data-selected={currentList == index}
                  onClick={() => handleCurrentList(index)}
                />
              )
            })}
          </Box>
        </>
      )}
    </Box>
  )
}

export default AllSpeakers
