import { Box, Icon, IconButton, Typography } from '@material-ui/core'
import { ModalFullScreen } from '../ModalFullScreen'
import { useStyles } from './styles'
import ReactPlayer from 'react-player'
import useAction from './action'
import LinearProgressWithLabel from '../LinearProgressWithLabel'

export const ProgrammingVideo = () => {
  const styles = useStyles()
  const {
    video,
    title,
    speakers,
    percentWatched,
    handleClose,
    enabled,
    handlePosition,
    handlePlay,
    handlePause,
    handleEnded,
    videoPlayerRef,
  } = useAction()
  if (!enabled) return <></>
  return (
    <ModalFullScreen>
      <Box className={styles.container}>
        <Box className={styles.videoContainer}>
          <Box className={styles.header}>
            <Typography className={styles.title}>{title}</Typography>
            <IconButton
              onClick={handleClose}
              className={styles.closeButton}>
              <Icon>cancel</Icon>
            </IconButton>
          </Box>
          <ReactPlayer
            progressInterval={5000}
            ref={videoPlayerRef}
            url={video}
            playing
            controls
            onPlay={handlePlay}
            onPause={handlePause}
            onEnded={handleEnded}
            onProgress={handlePosition}
          />
          {speakers && <Typography className={styles.speakers}>{speakers}</Typography>}
          <LinearProgressWithLabel value={percentWatched} />
        </Box>
      </Box>
    </ModalFullScreen>
  )
}
