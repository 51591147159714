import { useRegisteredTranslation } from './translation'
import { useRegisteredStyles } from './styles'
import { Box, Icon, Typography } from '@material-ui/core'

const Registered = ({ onRedirect = () => {} }) => {
  const styles = useRegisteredStyles()
  const translation = useRegisteredTranslation()
  return (
    <Box className={styles.container}>
      <Box className={styles.registeredAlert}>
        <Box className={styles.alertIconContainer}>
          <Icon className={styles.alertIcon}>done</Icon>
        </Box>
        <Typography className={styles.alertMessage}>{translation.registered}</Typography>
      </Box>
      <Typography className={styles.message}>{translation.messageRegistered}</Typography>
      <Typography
        onClick={onRedirect}
        className={styles.link}>
        {translation.redirectToRegistered}
      </Typography>
    </Box>
  )
}

export default Registered
