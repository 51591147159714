import { Container, Grid, Typography, Icon, IconButton } from "@material-ui/core"
import "./style.scss"
import ReactPlayer from "react-player"
import { Alert } from "@material-ui/lab"
import { ModalFullScreen } from '../ModalFullScreen'

const ModalVideoCourse = ({ closeShowVideo, onPause, onPlay, onProgress, onEnded, refPlayer, lesson, speakers, secondsLeft }) => {
  const secondsToHour = (seconds) => {
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds % 3600) / 60);
    var secondsRemaining = seconds % 60;

    var hoursFormatted = hours.toString().padStart(2, '0');
    var minutesFormatted = minutes.toString().padStart(2, '0');
    var secondsFormatted = secondsRemaining.toString().padStart(2, '0');

    return hoursFormatted + ':' + minutesFormatted + ':' + secondsFormatted;
  }
  return (
    <ModalFullScreen>
      <Container>
        <Grid container className="grid-header">
          <Grid item md={11} xs={9}>
            <Typography variant="h6">
              {lesson.descricao}
            </Typography>
          </Grid>
          <Grid item md={1} xs={3} style={{ textAlign: "center" }}>
            <IconButton onClick={() => closeShowVideo()} className="icon-header">
              <Icon>highlight_off</Icon>
            </IconButton>
          </Grid>
        </Grid>
        {lesson?.disponivel_por != null && secondsLeft > 0 &&
          <Grid>
            <Alert severity="info">
              Você possui {secondsToHour(secondsLeft)} para visualizar o vídeo, podendo assistir quantas vezes quiser dentro deste tempo,
              após o encerramento deste período não será possível visualizá-lo novamente.
            </Alert>
          </Grid>
        }
        <Grid className="grid-video">
          {lesson.link_video_palestrante ? (
            <ReactPlayer
              playing
              progressInterval={5000}
              controls
              ref={refPlayer}
              url={lesson.link_video_palestrante}
              onProgress={(e) => onProgress(e)}
              onPause={(e) => onPause(e)}
              onPlay={() => onPlay()}
              onEnded={() => onEnded()}
            />
          ) : (
            "Video não cadastrado"
          )}
          <Grid className="grid-description">
          <Typography className="text-description">
            {lesson.titulo_palestra || lesson.titulo}
          </Typography>
          <Typography className="text-speaker">
            {lesson.palestrantes_nome && `Palestrante: ${speakers}`}
          </Typography>
        </Grid>
        </Grid>
      </Container>
    </ModalFullScreen>
  )
}

export default ModalVideoCourse
