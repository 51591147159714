import "./style.scss";
import { Translator } from "@itarget/shared";
import { Container, Divider, Typography, Grid, TextField, MenuItem, makeStyles } from "@material-ui/core";
import { ModalQuestions } from "../../../components/ModalQuestionsCourse";
import Layout from "../../../components/Layout";
import Model1 from "../../../components/CardOffers/Model1";
import Model2 from "../../../components/CardOffers/Model2";
import Model3 from "../../../components/CardOffers/Model3";
import Model4 from "../../../components/CardOffers/Model4";
import Model5 from "../../../components/CardOffers/Model5";

const useStyles = makeStyles((theme) => ({
  modelsContainer: {
    '& > .MuiGrid-item': {
      [theme.breakpoints.up(theme.breakpoints.values.xs)]: {
        padding: theme.spacing(2, 0),
      },
      [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        padding: theme.spacing(2, 2),
      },
      [theme.breakpoints.up(theme.breakpoints.values.md)]: {
        padding: theme.spacing(2, 1),
      },
      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        padding: theme.spacing(1, 1),
      },
      [theme.breakpoints.up(theme.breakpoints.values.xl)]: {
        padding: theme.spacing(1, 1),
      },
    },
  },
}))

const OffersView = ({
  offers,
  selectOffer,
  categorySelected,
  category,
  groupByOffers,
  showQuiz,
  setShowQuiz,
  center_cost_id
}) => {
  const styles = useStyles()
  const conditionModel = 4;//pedidno pelo wlisses avulso pelo skype
  function OffersModal(props) {
    const { offers, conditionModel, selectOffer } = props;
    return offers.map((row, i) => (
      <Grid key={i} container item xs={12} sm={6} md={4} lg={3}>
        {conditionModel === 1 && <Model1 card={row} onClick={selectOffer} />}
        {conditionModel === 2 && <Model2 card={row} onClick={selectOffer} />}
        {conditionModel === 3 && <Model3 card={row} onClick={selectOffer} />}
        {conditionModel === 4 && <Model4 card={row} onClick={selectOffer} />}
        {conditionModel === 5 && <Model5 card={row} onClick={selectOffer} />}
      </Grid>
    ));
  }

  return (
    <Layout>
      <Container maxWidth='xl' className="offers-header title-page">
        {showQuiz &&
          <ModalQuestions setShowQuiz={() => { setShowQuiz() }} questionDestination={17} centerCostId={center_cost_id} />
        }
        <Typography variant="h4">
          <Translator transRef="translation.VITRINE" />
        </Typography>
      </Container>
      <Divider />
      <Container maxWidth='xl' className="offers-body">
        <Typography style={{ display: offers.length !== 0 && "none" }} variant="h6">
          {categorySelected && category?.data?.length > 1 ? (
            <Translator transRef="translation.Selecione sua categoria para exibir os cursos" />
          ) : offers.length === 0 ? (
            <Translator transRef="translation.Sem itens cadastrados" />
          ) : (
            ""
          )}
        </Typography>

        <Grid item xs={12} className={styles.modelsContainer} container>
          {groupByOffers ? (
            Object.entries(offers).map(([title, offer], i) => {
              return (
                <>
                  <Grid key={i} item xs={12}>
                    <Typography>{title}</Typography>
                  </Grid>
                  <OffersModal offers={offer} conditionModel={conditionModel} selectOffer={selectOffer} onClickRegistered={selectOffer} />
                </>
              );
            })
          ) : (
            <OffersModal offers={offers} conditionModel={conditionModel} selectOffer={selectOffer} onClickRegistered={selectOffer} />
          )}
        </Grid>
      </Container>
    </Layout>
  );
};

export default OffersView;
