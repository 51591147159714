import {
  Tabs,
  Tab,
  Box,
  Typography,
  Icon,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from '@material-ui/core'
import { formatDate, formatMoney, Translator, useRedirect } from '@itarget/shared'
import { convertPalestraJson } from '../../utils/palestraHelper'
import { useSelector } from 'react-redux'
import { dateFormatTranslation } from '@itarget/shared/src/utils/format/date'
import { ExpandMore, AccessTime } from '@material-ui/icons'
import './style.scss'
import Programming from '../Programming/Programming'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

const getColorClass = (index) => {
  return index % 2 === 0 ? 'even-color' : 'odd-color'
}

const TabsDetailOffer = ({ value, handleChange, tabs }) => {
  const { goTo } = useRedirect()
  const detailing = tabs.detailing && tabs.detailing[0]
  const amount = tabs?.amount
  const programing = convertPalestraJson(tabs?.programing)
  const selectedLang = useSelector((state) => state.language.lang)
  const systemTheme = useSelector((state) => state.theme.systemTheme)
  let programDateIncrementor = 0

  return (
    tabs && (
      <div>
        <Tabs
          value={value}
          onChange={handleChange}
          variant='scrollable'
          className='detailOffer-tabs-container-group'
          aria-label='full width tabs example'
          scrollButtons='auto'>
          <Tab
            icon={<Icon>cast_for_education</Icon>}
            value={0}
            label={<Translator transRef='translation.Informações' />}
          />
          {programing?.length > 0 && (
            <Tab
              icon={<Icon>table_view</Icon>}
              value={1}
              label={<Translator transRef='translation.Grade Curricular' />}
            />
          )}
          {((amount?.value && Object.values(amount.value).length > 0) || detailing?.commerce_text_valores) && (
            <Tab
              icon={<Icon>attach_money</Icon>}
              value={2}
              label={<Translator transRef='translation.Valores' />}
            />
          )}
          {detailing && detailing.commerce_formato_exibicao == 2 && (
            <Tab
              icon={<Icon>cast_for_education</Icon>}
              value={3}
              label={<Translator transRef='translation.Ver programação' />}
            />
          )}
          {detailing && detailing.commerce_formato_exibicao == 3 && (
            <Tab
              icon={<Icon>cast_for_education</Icon>}
              value={3}
              onClick={() => goTo(`detail-ead/${detailing.centro_custo_id_out}/${detailing.aga_id}`)}
              label={<Translator transRef='translation.Ver aulas' />}
            />
          )}
        </Tabs>

        <TabPanel
          value={value}
          index={0}>
          <Typography className='detailing-description'>
            {detailing && detailing?.descritivo && <div dangerouslySetInnerHTML={{ __html: detailing?.descritivo }} />}
          </Typography>
          {detailing && detailing.data_venc_matricula && (
            <Typography className='detailing-date'>
              <Translator transRef='translation.Período de inscrição:' />
              <span>{dateFormatTranslation(selectedLang, detailing.data_venc_matricula)}</span>
            </Typography>
          )}
        </TabPanel>

        <TabPanel
          value={value}
          index={1}>
          {programing &&
            programing.map((row, index) => {
              let hasNotSchedule = row.hora_inicio == '00:00:00' || !row.hora_inicio

              if (row.data_atividade) programDateIncrementor++

              return (
                <Accordion
                  className='detailOffer-accordion-container'
                  key={index}>
                  <AccordionSummary
                    className='accordion-summary'
                    expandIcon={<ExpandMore className='accordion-summary-expand-more-icon' />}
                    aria-controls={`accordion-content-${row.titulo}`}
                    id={`accordion-header-${row.titulo}`}>
                    <Grid className='accordion-content'>
                      {![null, undefined].includes(row.data_atividade) && (
                        <>
                          <Grid className='accordion-content-date'>
                            <div className={`day-month ${getColorClass(programDateIncrementor)}`}>
                              <Typography className='day'>{formatDate.getDay(row.data_atividade)}</Typography>
                              <Typography className='month'>{formatDate.monthInFull(row.data_atividade).slice(0, 3)}</Typography>
                            </div>
                          </Grid>
                          <Divider className='accordion-divider' />
                        </>
                      )}

                      {!hasNotSchedule && (
                        <>
                          <Grid className='accordion-content-schedule'>
                            <AccessTime />
                            <Typography className='accordion-schedule'>
                              {formatDate.hour(row.hora_inicio)} - {formatDate.hour(row.hora_fim)}
                            </Typography>
                          </Grid>
                          <Divider className='accordion-divider' />
                        </>
                      )}
                      <Typography className='accordion-title'>{row.titulo}</Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails className='accordion-details'>
                    <Grid className='accordion-details-occupations'>
                      {row.custom.map((customAttribute, occupationsIndex) => (
                        <div key={occupationsIndex}>
                          {customAttribute.funcao && customAttribute.nome_palestrante && (
                            <Typography
                              className='profiles'
                              style={{ fontSize: '11px' }}>
                              {<strong>{`${customAttribute.funcao}: `}</strong>}
                              {`${customAttribute.nome_palestrante}.`}
                            </Typography>
                          )}
                        </div>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )
            })}
        </TabPanel>

        <TabPanel
          value={value}
          index={2}>
          <TableContainer
            className='table-valores'
            component={Paper}>
            <Table
              size='small'
              aria-label='a dense table'>
              {amount?.value && Object.values(amount.value).length > 0 && (
                <TableHead>
                  <TableRow className='table-row'>
                    {amount?.description &&
                      Object.keys(amount?.description).map((key) => {
                        return (
                          <TableCell
                            className='table-cell'
                            align='center'
                            key={key}>
                            {amount?.description[key]}
                          </TableCell>
                        )
                      })}
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {amount?.description &&
                  amount?.value.map((row, index) => (
                    <TableRow
                      className='table-row'
                      key={index + row.categoria}>
                      {Object.keys(amount?.description).map((key) => {
                        return (
                          <TableCell
                            className='table-cell'
                            align={['valor', 'valor_nsocio'].includes(key) ? 'right' : 'left'}
                            component='th'
                            scope='row'>
                            {['valor', 'valor_nsocio'].includes(key)
                              ? row[key] === '0.000'
                                ? 'Cortesia'
                                : 'R$ ' + formatMoney.toReal(row[key])
                              : row[key]}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {detailing && detailing.commerce_text_valores && (
            <Typography
              component='legend'
              dangerouslySetInnerHTML={{
                __html: detailing.commerce_text_valores,
              }}></Typography>
          )}
        </TabPanel>

        <TabPanel
          value={value}
          index={3}>
            <Programming detailing={detailing} />
        </TabPanel>
      </div>
    )
  )
}
export default TabsDetailOffer
