import { Tooltip, Typography, TypographyProps } from '@material-ui/core'
import { useStyles } from './styles'

const TypographyTooltip = (props) => {
  let defaultNumberOfLines = 0
  const styles = useStyles({ numberOfLines: props.numberOfLines || defaultNumberOfLines })

  return (
    <Tooltip title={props.children}>
      <Typography
        {...props}
        className={`${props.className} ${styles.typography}`}>
        {props.children}
      </Typography>
    </Tooltip>
  )
}

export default TypographyTooltip
