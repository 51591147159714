import { Container, Typography, Divider } from "@material-ui/core";
import "./style.scss";

import Layout from "../../../components/Layout";

const RedirectView = () => {
  return (
    <Layout>
      <Container>
        <Container maxWidth='xl' className="myActivity-header">
          <Typography variant="h4">REDIRECT</Typography>
        </Container>
        <Divider />
        <Container maxWidth='xl' className="myActivity-body"></Container>
      </Container>
    </Layout>
  );
};

export default RedirectView;
