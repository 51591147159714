import { makeStyles } from "@material-ui/core";

import { icons } from "./icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    verticalAlign: "middle",
    boxSizing: "border-box",
    fill: (props) => props.color || theme.palette.primary.main,
    width: (props) => props.size,
    height: (props) => props.size,
    margin: "5px",
  },
}));

const SocialIcon = ({ icon, size = 24, color, onClick, url = "#" }) => {
  const styles = useStyles({ size, color });
  return (
    <a href={url} rel="noreferrer" target="_blank">
      <svg
        viewBox="0 0 24 24"
        fillRule="evenodd"
        clipRule="evenodd"
        size={size}
        strokeLinejoin="round"
        color={color}
        className={styles.root}
        onClick={onClick}
        width="100%"
        height="100%"
      >
        <title>{icon}</title>
        <path d={icons[icon]} />
      </svg>
    </a>
  );
};

export default SocialIcon;
