import { Container, Divider, Grid, Dialog, Typography, Breadcrumbs, Hidden, Box } from '@material-ui/core'
import { Translator, enumsPayment, ModalCategoryReceipt, formatDate, Button } from '@itarget/shared'

import Layout from '../../../components/Layout'
import CardOffers from '../../../components/CardOffers'
import TabsDetailOffer from '../../../components/TabsDetailOffer'
import CarouselCardOffers from '../../../components/CarouselCardOffers'
import LoginContainer from '../../login/LoginContainer'

import CardWrongCreditCard from '../../../components/CardWrongCreditCard'
import { useState } from 'react'
import CardAlertNecessary from '../../../components/CardAlertNecessary'
import { ModalQuestions } from '../../../components/ModalQuestionsCourse'
import DisplayValuesOffers from '../../../components/DisplayValues​Offers'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  verticalGroup: {
    display: 'flex',
    gap: theme.spacing(1),
    flexDirection: 'column',
  },
}))

const DetailOfferView = ({
  annuities,
  centerCost,
  isLoadingScreen,
  status_comprovante_categoria,
  comp_recusa,
  paymentRedux,
  toast,
  user,
  offer,
  tabs,
  value,
  handleChange,
  addShoppingCart,
  openLogin,
  handleCloseLogin,
  cc,
  center_cost_id,
  showWrongCard,
  changePayment,
  setShowWrongCard,
  redirectSubscribe,
  openModalReceipt,
  setOpenModalReceipt,
  id,
  selectOffer,
  selectProduct,
  goTo,
  detailErrorMessage,
  necessaryErrorMessage,
  setNecessaryErrorMessage,
  errorSubscriptionData,
  isLoading,
  isLoadingSubscribe,
  showQuiz,
  setShowQuiz,
  category,
  onChangeCategory,
  addWaitingList,
  activityAmount,
  totalAnnuity,
  actionStream,
  onClickMindsRadiology,
  onChangeComboAnnuity,
  handlePurchaseQuantity,
  total,
}) => {
  const styles = useStyles()
  const { STATUS_REGISTRATION } = enumsPayment

  let detailing = tabs?.detailing?.[0]
  let mindsRadiologyUrl = detailing?.ins_metadata ? JSON.parse(detailing?.ins_metadata)?.mindsRadiologyUrl : ''
  let startDate = detailing?.data_inicio_aga ? formatDate.ptBr(detailing?.data_inicio_aga) : ''
  let endDate = detailing?.data_fim_aga ? formatDate.ptBr(detailing?.data_fim_aga) : ''
  let soldOut = detailing?.vagas_limite <= detailing?.vagas_ocupadas
  let canBuyMore = detailing?.qtd_inscricoes < detailing?.vagas_limite_insc_mesma_pessoa && detailing?.mais_insc_mesma_pessoa == 'S'
  let vacancies = detailing?.vagas_limite - detailing?.vagas_ocupadas
  let buyLimit = detailing?.vagas_limite_insc_mesma_pessoa - detailing?.qtd_inscricoes
  let freeAccess = !detailing?.plp_id && detailing?.status_inscricao == STATUS_REGISTRATION.REGISTERED
  if (vacancies <= buyLimit) buyLimit = vacancies
  let hasPendingAnnuities = annuities.length > 0

  const rules = {
    requestCategory:
      !isLoadingScreen &&
      detailing &&
      user?.data?.id &&
      !detailing?.plp_id &&
      category?.data?.length >= 1 &&
      !detailing?.status_inscricao,
    allowsItemsQuantity: !soldOut && canBuyMore && detailing?.cortesia !== 'S' && !!detailing?.plp_id,
    allowsDisplayOfferValues: (!user?.data?.id || (detailing && detailing?.plp_id) || detailing?.status_inscricao) && !isLoadingScreen,
    allowsDisplayOfTheAmount:
      !isLoadingScreen &&
      user?.data?.id &&
      ![STATUS_REGISTRATION.REGISTERED].includes(detailing?.status_inscricao) &&
      detailing?.cortesia !== 'S',
    allowsDisplayComboAnnuity:
      (!detailing?.status_inscricao || canBuyMore) &&
      detailing?.qtde_anuidade_pendente > 0 &&
      detailing?.cortesia !== 'S' &&
      !soldOut &&
      hasPendingAnnuities,
    allowsDisplayDiscountedComboAnnuity:
      (!detailing?.status_inscricao || canBuyMore) && !!totalAnnuity && detailing?.cortesia !== 'S' && !soldOut,
    allowsDisplayTotalAmount:
      ((!detailing?.status_inscricao && detailing?.qtde_anuidade_pendente > 0 && hasPendingAnnuities) || canBuyMore) &&
      detailing?.cortesia !== 'S' &&
      !soldOut &&
      !!detailing?.plp_id,
    allowsDisplayReference:
      user?.data?.id && ![STATUS_REGISTRATION.REGISTERED].includes(detailing?.status_inscricao) && detailing?.cortesia !== 'S',
    allowsDisplayWaitingList: detailing?.exibe_lista_espera === 'S',
    allowsDisplayCourtesy: !detailing?.status_inscricao && detailing?.cortesia === 'S' && !soldOut,
    availableForPurchase:
      !isLoadingScreen &&
      (!detailing?.status_inscricao || canBuyMore) &&
      detailing?.cortesia !== 'S' &&
      !soldOut &&
      (!user?.data?.id || !!detailing?.plp_id),
    soldOut: !isLoadingScreen && soldOut && !detailing?.status_inscricao,
    pendingPayment: detailing?.status_inscricao == STATUS_REGISTRATION.PENDING && detailing?.gerou_cobranca,
    shoppingCartPayment: detailing?.status_inscricao == STATUS_REGISTRATION.PENDING && !detailing?.gerou_cobranca,
    generatedCharge: detailing?.gerou_cobranca !== null,
    paymentMade: detailing?.status_inscricao == STATUS_REGISTRATION.REGISTERED && detailing?.cortesia !== 'S' && detailing?.plp_id,
    enableWatchConditions: detailing?.status_inscricao == STATUS_REGISTRATION.REGISTERED,
    courtesy: detailing?.cortesia === 'S',
    releasedContent: detailing?.qtde_aulas_video > 0 || detailing?.disponivel_transmissao === 'S',
    unavailable:
      !isLoadingScreen && (!detailing || (user?.data?.id && !detailing?.plp_id && category?.data?.length == 0)) && !freeAccess,
    configuredForDistanceLearning: centerCost?.tipo_centro_custo == 7,
    transmissionAvailable: detailing?.disponivel_transmissao === 'S',
    classesAvailable: detailing?.qtde_aulas_video > 0,
    enableMindsRadiologyUrl: detailing?.status_inscricao == STATUS_REGISTRATION.REGISTERED && !!mindsRadiologyUrl,
  }

  let title = detailing?.desc_atv || detailing?.desc_tatv

  return (
    <Layout isLoading={isLoadingScreen}>
      {showQuiz && (
        <ModalQuestions
          setShowQuiz={() => {
            setShowQuiz()
          }}
          questionDestination={17}
          centerCostId={center_cost_id}
        />
      )}
      <Dialog
        maxWidth='lg'
        open={openLogin}
        onClose={handleCloseLogin}
        className='dialog-login'>
        <LoginContainer />
      </Dialog>
      {showWrongCard && (
        <CardWrongCreditCard
          changePayment={changePayment}
          setShow={setShowWrongCard}
        />
      )}
      {necessaryErrorMessage && errorSubscriptionData && (
        <CardAlertNecessary
          card={offer}
          message={necessaryErrorMessage}
          setMessage={setNecessaryErrorMessage}
          errorSubscriptionData={errorSubscriptionData}
        />
      )}
      <Grid className='detailOffer-background'>
        <Container maxWidth='xl'>
          {detailing ? (
            <Breadcrumbs
              style={{ marginTop: 15, padding: 8 }}
              separator='›'>
              {[
                <div
                  onClick={() => goTo('')}
                  style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                  <Translator transRef='translation.Ofertas' />
                </div>,
                <div style={{ fontSize: 12 }}>{detailing.desc_atv || detailing.desc_tatv}</div>,
              ]}
            </Breadcrumbs>
          ) : null}
          <Hidden lgUp>
            {detailing && (
              <DisplayValuesOffers.Header
                title={title}
                axios={detailing?.desc_eixo}
              />
            )}
            <DisplayValuesOffers.Return onReturn={() => goTo(rules.configuredForDistanceLearning ? 'ead/' : `offers-unique/${cc}`)} />
          </Hidden>
          {detailErrorMessage && (
            <Grid className='detailOffer-cardOffers'>
              <Typography className='infos-error'>{detailErrorMessage}</Typography>
              <Grid className='infos-button'>
                <div
                  onClick={() => goTo('')}
                  className='infos-text'
                  role='button'>
                  <Translator transRef={`translation.Retornar.`} />
                </div>
              </Grid>
            </Grid>
          )}

          <Grid
            container
            spacing={3}
            className='detailing-offer-container'>
            <Grid
              className='grid-item'
              item
              lg={9}
              xs={12}>
              <Hidden mdDown>
                <Grid className='detailOffer-cardOffers'>
                  <CardOffers card={offer} />
                </Grid>
              </Hidden>
              <TabsDetailOffer
                tabs={tabs}
                value={value}
                handleChange={handleChange}
              />
            </Grid>
            <Grid
              className='grid-item grid-cardPrice'
              item
              lg={3}
              xs={12}>
              {user && openModalReceipt && (
                <ModalCategoryReceipt
                  centerCostId={cc}
                  status_comprovante_categoria={status_comprovante_categoria}
                  comp_recusa={comp_recusa}
                  open={openModalReceipt}
                  setOpen={setOpenModalReceipt}
                  toast={toast}
                  payment={paymentRedux}
                />
              )}
              <Hidden mdDown>
                {detailing && (
                  <DisplayValuesOffers.Header
                    title={title}
                    activityType={detailing?.desc_tatv}
                  />
                )}
                <DisplayValuesOffers.Return
                  onReturn={() => goTo(rules.configuredForDistanceLearning ? 'ead/' : `offers-unique/${cc}`)}
                />
              </Hidden>
              <Hidden lgUp>
                <Grid className='detailOffer-cardOffers'>
                  <CardOffers card={offer} />
                </Grid>
              </Hidden>
              {rules.requestCategory && (
                <DisplayValuesOffers.Root>
                  <DisplayValuesOffers.Category
                    categories={category?.data || []}
                    onConfirmCategory={onChangeCategory}
                  />
                </DisplayValuesOffers.Root>
              )}
              {rules.allowsDisplayOfferValues && (
                <DisplayValuesOffers.Root>
                  {(rules.allowsDisplayOfTheAmount || rules.allowsDisplayReference) && (
                    <Box className={styles.verticalGroup}>
                      {rules.allowsDisplayOfTheAmount && (
                        <DisplayValuesOffers.Amount
                          amount={activityAmount}
                          discount={{
                            discountedAmount: rules.allowsDisplayDiscountedComboAnnuity && detailing?.valor_socio_quite,
                            type: 'percentage',
                          }}
                        />
                      )}
                      {rules.allowsDisplayReference && (
                        <DisplayValuesOffers.Reference
                          category={user?.data?.categoria_centro_custo_descricao}
                          situation={user?.data?.financialStatus}
                        />
                      )}
                    </Box>
                  )}
                  {rules.allowsItemsQuantity && (
                    <DisplayValuesOffers.Counter
                      maxLimit={buyLimit}
                      onChange={handlePurchaseQuantity}
                    />
                  )}
                  {rules.allowsDisplayComboAnnuity && (
                    <DisplayValuesOffers.ComboAnnuity
                      onChange={onChangeComboAnnuity}
                      annuities={annuities}
                    />
                  )}
                  {rules.allowsDisplayTotalAmount && <DisplayValuesOffers.Total amount={totalAnnuity || total} />}
                  {rules.availableForPurchase && (
                    <DisplayValuesOffers.Available
                      onAddCart={() => addShoppingCart({ includeAnnuitiesIds: !!totalAnnuity })}
                      isLoading={isLoading || isLoadingSubscribe}
                      onSubscribe={() => (totalAnnuity ? redirectSubscribe('inPaymentWithAnnuity') : redirectSubscribe('toPayment'))}
                    />
                  )}
                  {rules.pendingPayment && <DisplayValuesOffers.Pending onRedirect={() => goTo('my-activity#3')} />}
                  {rules.shoppingCartPayment && <DisplayValuesOffers.ShoppingCart onRedirect={() => goTo('shopping-cart')} />}
                  {rules.paymentMade && <DisplayValuesOffers.Registered onRedirect={() => goTo('my-activity#3')} />}
                  {rules.courtesy && (
                    <DisplayValuesOffers.Courtesy
                      isLoading={isLoading || isLoadingSubscribe}
                      onConfirm={() => redirectSubscribe('toCourtesy')}
                      enableOnConfirm={rules.allowsDisplayCourtesy}
                    />
                  )}
                  {rules.soldOut && (
                    <DisplayValuesOffers.SoldOut
                      onWaitingList={addWaitingList}
                      isLoading={isLoading || isLoadingSubscribe}
                      enableWaitingList={rules.allowsDisplayWaitingList}
                    />
                  )}
                  {rules.enableWatchConditions && (
                    <DisplayValuesOffers.Watch
                      enableRecorded={rules.classesAvailable}
                      enableStream={rules.transmissionAvailable}
                      onWatchStream={() => actionStream(detailing)}
                      onWatchRecorded={() => goTo(`my-course/${detailing?.aga_id}`)}
                    />
                  )}
                </DisplayValuesOffers.Root>
              )}
              {rules.unavailable && !isLoadingScreen && <DisplayValuesOffers.Unavailable />}
              {rules.enableMindsRadiologyUrl && (
                <Grid
                  item
                  container>
                  <Button
                    icon='link'
                    variant='midnight'
                    color='twilight'
                    onClick={() => onClickMindsRadiology()}
                    disabled={isLoading}>
                    <Translator transRef='translation.Minds Radiology' />
                  </Button>
                </Grid>
              )}
              {isLoadingScreen && <DisplayValuesOffers.Loading />}
              {detailing && (
                <DisplayValuesOffers.Detail
                  endDate={endDate}
                  startDate={startDate}
                  room={detailing?.sala_atividade}
                  location={detailing?.local_atividade}
                  hourlyLoad={detailing?.carga_horaria}
                />
              )}
            </Grid>
          </Grid>
          <Divider className='detailOffer-divider' />
          <Grid className='carouselCard'>
            <CarouselCardOffers
              selectOffer={selectOffer}
              selectProduct={selectProduct}
              cc={offer?.centro_custo_id_out || center_cost_id}
              id={id}
            />
          </Grid>
        </Container>
      </Grid>
    </Layout>
  )
}

export default DetailOfferView
