import { Box, Typography } from '@material-ui/core'
import { formatMoney, ReplaceAllInstances } from '@itarget/shared'
import { useAmountStyles } from './styles'
import { useAmountTranslation } from './translation'

const Amount = ({ amount = 0, discount = { amount: 0, discountedAmount: 0, type: '' }, className = '' }) => {
  const styles = useAmountStyles()
  const translation = useAmountTranslation()
  if (!amount) {
    return (
      <Box className={styles.unavailableContainer}>
        <Typography className={styles.unavailable}>{translation.unavailable}</Typography>
      </Box>
    )
  }
  if (discount.discountedAmount) {
    let discountedAmountType = {
      ['percentage']: ReplaceAllInstances('%s%', [(discount.amount || 0).toFixed(2)]),
      ['value']: ReplaceAllInstances('R$ %s', [formatMoney.toReal(discount.amount || 0)]),
    }
    return (
      <Box className={styles.container}>
        <Box className={styles.discountContainer}>
          <Box className={styles.chip}>
            <Typography className={styles.discountText}>{translation.discount}</Typography>{' '}
            {discount.amount && <Typography className={styles.discountText}>{discountedAmountType[discount.type]}</Typography>}
          </Box>
          <Typography className={styles.amountDiscounted}>R$ {formatMoney.toReal(amount)}</Typography>
        </Box>
        <Typography className={`${styles.amount} ${className}`}>
          <span className={styles.currencySymbol}>R$</span> {formatMoney.toReal(discount.discountedAmount)}
        </Typography>
      </Box>
    )
  }
  return (
    <Box className={styles.container}>
      <Typography className={`${styles.amount} ${className}`}>
        <span className={styles.currencySymbol}>R$</span> {formatMoney.toReal(amount)}
      </Typography>
    </Box>
  )
}

export default Amount
