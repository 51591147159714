import MODULE_NAME from "./constants";

export const SET_SHOPPINGCARTS = `${MODULE_NAME}/SET_SHOPPINGCARTS`;
export const RESET_SHOPPINGCARTS = `${MODULE_NAME}/RESET_SHOPPINGCARTS`;

export const RESET = `${MODULE_NAME}/RESET`;

export const setShoppingCarts = (data) => ({
  type: SET_SHOPPINGCARTS,
  payload: { data },
});

export const resetShoppingCarts = () => ({
  type: RESET_SHOPPINGCARTS,
});

export const reset = () => ({
  type: RESET,
});
