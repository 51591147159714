import { makeStyles } from "@material-ui/core";

const defaultStyle = makeStyles((theme) => ({
  button: {
    minWidth: "11.445%",
    minHeight: "52px",
    padding: "0 5px !important",
    margin: theme.spacing(2),
    boxShadow: "none",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    "&:hover": {
      backgroundColor: "#fff",
      boxShadow: "none",
    },
  },
  icon: {
    flex: "0 0 auto",
    marginRight: "5px",
    width: "38px",
    height: "38px",
    fill: "#D3D3D3",
    color: "#D3D3D3",
    borderColor: "#D3D3D3",
    fontSize: "38px !important",
  },
  text: {
    flex: "1 0 auto",
    height: "52px",
    color: "#529DD7 !important",
    justifyContent: "center",
    textAlign: "start",
    display: "flex",
    flexDirection: "column",
    fontWeight: "750",
    fontSize: "0.9rem",
    lineHeight: "15px",
  },
  spanText: {
    ...theme.typography.body1,
    fontSize: "0.865em",
    fontWeight: "900",
    textTransform: "uppercase",
    color: '#030303',
  },
  popover: {
    padding: theme.spacing(1),
    minWidth: "250px",
  },
  "@media screen and (max-width: )": {},
}));

export { defaultStyle };
