import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { subscription, offers, auth as authRedux, toast, payment as paymentRedux } from "../../redux";
import { useFetchApi, Config, configurator, useRedirect, Translator, ReplaceAllInstances } from "@itarget/shared";
import ShoppingCartView from "./views/ShoppingCartView";

const ShoppingCartContainer = () => {
  const dispatch = useDispatch();
  const cloneDeep = require("clone-deep");
  const { goTo } = useRedirect();
  const [selected, setSelected] = useState([]);
  const [total, setTotal] = useState(0);
  const [state, setState] = useState("cards");
  const [openConfirmUnsubscribe, setOpenConfirmUnsubscribe] = useState(false);
  const [currentRow, setCurrentRow] = useState();
  const [incList, setIncList] = useState();
  const [incIds, setIncIds] = useState("");
  const [vlTotal, setVlTotal] = useState();
  const [showWrongCard, setShowWrongCard] = useState(false);
  const [resetStatePayment, setResetStatePayment] = useState(false);
  const [subscriptionPayment, setSubscriptionPayment] = useState([]);
  const [activityChecked, setActivityChecked] = useState([]);
  const [amountProduct, setAmountProduct] = useState(1);
  //const shoppingCarts = useSelector((state) => state.offers.shoppingCarts);
  const user = useSelector((state) => state.auth.user);
  const center_cost_id = useSelector((state) => state.auth.cc);
  const settingCommerce = useSelector((state) => state.setting.settingCommerce);
  const selectedLang = useSelector((state) => state.language.lang)
  const paymentType = useSelector((state) => state.payment.type);
  const totalState = useSelector((state) => state.payment.total);
  const [selectActivity, setSelectActivity] = useState("");
  const isAuthenticated = useSelector((state) => state.auth.isAutenticated)
  const [showQuiz, setShowQuiz] = useState();

  const { data: inscricoesApi, fetchData: fetchInscricoes, isLoading: isLoadingSubscriptionPending } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/subscription/pending?pessoa_id=${user.data && user.data.id}&origem_inscricao=5`
  );

  const { data: reRegister, fetchData: fetchReRegister } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/person/re/register?pessoa_id=${user?.data?.id}&centro_custo_id=${center_cost_id}&aplicacoes=4`
  )

  const { data: accountReceive, fetchData: fetchAccountReceive } = useFetchApi(Config.baseURL + `api/${selectedLang}/finance/account-receive/` + incIds + "/receipt");

  const { data: setting, fetchData: fetchSetting } = useFetchApi(Config.baseURL + `api/${selectedLang}/setting?centro_custo_id=${center_cost_id}&${configurator.MY_ACTIVITY}&${configurator.DISCOUNT}&${configurator.ACTIVE_VOUCHER}`);

  const translation = {
    selectActivity: Translator({ transRef: "translation.Selecione a atividade %s primeiro." }),
  }

  const {
    data: questions,
    fetchData: fetchQuestions,
  } = useFetchApi(Config.baseURL + `api/${selectedLang}/question?centro_custo_id=${center_cost_id}&pessoa_id=${user?.data?.id}&destino_pergunta=17`)

  useEffect(() => {
    if(!reRegister?.data?.[0]) return
    const timeout = setTimeout(() => goTo("me"), 2000);
    return () => clearTimeout(timeout);
  }, [reRegister]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchReRegister()
    }
  }, [isAuthenticated]);
  
  useEffect(() => {
    fetchSetting();
  }, []);

  useEffect(() => {
    if (resetStatePayment) {
      setResetStatePayment(false);
    }
  }, [resetStatePayment]);

  useEffect(() => {
      if (selected.length > 0) {
      let ids = selected.map((res, i) => {
        return res?.conta_receber_id;
      });
      setIncIds(ids.toString());
    } else {
      setTotal(0);
    }
    }, [selected]);

  useEffect(() => {
    if (accountReceive) {
      setIncList(accountReceive.data);
    }
  }, [accountReceive]);

  useEffect(() => {
    if (incList && incList.length > 0) {
      let vl = 0;
      incList.map((e) => {
        if (e.r_a_importancia_de) {
          vl = vl + parseFloat(e.r_a_importancia_de);
        }
        return vl;
      });
      setVlTotal(vl);
    }
  }, [incList]);

  useEffect(() => {
    fetchInscricoes();
    fetchQuestions();
  }, []);

  useEffect(() => {
    if (inscricoesApi?.data) {
      async function subscriptionPending() {
        let subscriptionPending = cloneDeep(inscricoesApi.data.filter((res) => res.controle_boleto_cartao_id === null));
        dispatch(offers.actions.setShoppingCarts(subscriptionPending));
        setSelected(subscriptionPending.filter((s) => s.esta_vencido !== "S"));
        setSubscriptionPayment(subscriptionPending);
        setActivityChecked(subscriptionPending.filter((s) => s.esta_vencido !== "S").map((sub) => sub.conta_receber_id));
      }
      subscriptionPending();
    }
  }, [inscricoesApi]);
  
  useEffect(()=>{
    if(selectActivity){
      dispatch(
        toast.actions.enqueue({
          content: ReplaceAllInstances(translation.selectActivity.props.children, [selectActivity]),
          severity: "error",
        })
      );
    }
    setSelectActivity("")
  },[selectActivity])

  const onChangeSelected = (e, card = {}) => {
    if(card.pre_agenda_atividades?.length &&
       !card.pre_agenda_atividades.every(pre_agenda=>
      activityChecked.includes(subscriptionPayment.find(course=>course.agenda_atividade_id === pre_agenda.agenda_atividade_pre_id)?.conta_receber_id) 
      || !card.pre_agenda_atividades.every(pre_atividade=>subscriptionPayment.some(course=>course.agenda_atividade_id === pre_atividade.agenda_atividade_pre_id)))){
        setSelectActivity(card.pre_agenda_atividades.map(pre_atividade=>pre_atividade.descricao))
        return
    }
    if (setting?.data?.filter((s) => s.codigo === 404).filter((f) => f.ativo === "S").length > 0) {
      if (e.target.checked) {
        if (card.categoria_tipo_atividade === 1) {
          setSelected([...selected, ...subscriptionPayment.filter((f) => f.categoria_tipo_atividade === 1)]);
          setActivityChecked([...activityChecked, card.conta_receber_id]);

          if (subscriptionPayment.filter((f) => f.categoria_tipo_atividade === 1).length > 1) {
            dispatch(
              toast.actions.enqueue({
                content: setting.data.filter((s) => s.codigo === 404)[0].valor_referencia,
              })
            );
          }
        } else {
          setSelected([...selected, card]);
          setActivityChecked([...activityChecked, card.conta_receber_id]);
        }
      } else {
        if (card.categoria_tipo_atividade === 1) {
          setSelected((selected) =>
            selected.filter(
              (res) =>
                !subscriptionPayment
                  .filter((f) => f.categoria_tipo_atividade === 1)
                  .map((m) => m.agenda_atividade_id)
                  .includes(res.agenda_atividade_id)
            )
          );

          setActivityChecked((activityChecked) => {
            return activityChecked.filter(
              (d) =>
                !subscriptionPayment
                  .filter((f) => f.categoria_tipo_atividade === 1)
                  .map((c) => c.conta_receber_id)
                  .includes(d)
            );
          });
        } else {
          const dependences = subscriptionPayment.filter(scription=> 
            scription.pre_agenda_atividades?.some(pre_agenda=>pre_agenda.agenda_atividade_pre_id === card.agenda_atividade_id)).map(dep=>dep?.conta_receber_id)
          const newCheckeds = activityChecked.filter(activity=> !dependences.some(dep=>dep===activity) &&  activity !== card?.conta_receber_id)
          setActivityChecked(newCheckeds)
          setSelected((selected) => selected.filter((res) => res.agenda_atividade_id !== card.agenda_atividade_id));
        }
      }
    } else {
      if (e.target.checked) {
        setSelected([...selected, card]);
        setActivityChecked([...activityChecked, card.conta_receber_id]);
      } else {
        const dependences = subscriptionPayment.filter(scription=> 
          scription.pre_agenda_atividades?.some(pre_agenda=>pre_agenda.agenda_atividade_pre_id === card.agenda_atividade_id)).map(dep=>dep.conta_receber_id)
        const newCheckeds = activityChecked.filter(activity=> !dependences.some(dep=>dep===activity) &&  activity !== card.conta_receber_id)
        setActivityChecked(newCheckeds)
        setSelected((selected) => selected.filter((res) => !dependences.some(dep=>dep===res.conta_receber_id) && res.agenda_atividade_id !== card.agenda_atividade_id));
      }
    }
  };

  const onClickUnsubscribe = () => {
    dispatch({
      type: "FETCH_STARTED",
      meta: {
        actions: subscription.actions.SUBSCRIPTION,
        body: JSON.stringify({
          id: currentRow.inscricao_uuid,
        }),
        url: Config.baseURL + `api/${selectedLang}/subscription/unsubscribe`,
        method: "PUT",
      },
    });

    dispatch(offers.actions.setShoppingCarts(subscriptionPayment.filter((row) => row.inscricao_uuid !== currentRow.inscricao_uuid)));
    setSubscriptionPayment(subscriptionPayment.filter((row) => row.inscricao_uuid !== currentRow.inscricao_uuid));
    setSelected((selected) => selected.filter((res) => res.inscricao_uuid !== currentRow.inscricao_uuid));
    handleCloseUnsubscribe();
  };

  const goFinish = () => {
    setState("finish");

    if (incIds && incIds.length > 0) {
      fetchAccountReceive();
    }
  };

  const handleCloseUnsubscribe = () => {
    setOpenConfirmUnsubscribe(false);
  };

  const openModal = (row) => {
    setOpenConfirmUnsubscribe(true);
    setCurrentRow(row);
  };

  const onInvalidCardPayment = () => {
    setShowWrongCard(true);
  };

  const changePayment = () => {
    setResetStatePayment(true);
    setShowWrongCard(false);
  };

  const amountProductChange = (value) => {
    setAmountProduct(value);
  };

  useEffect(() => {
    if (questions && questions?.data?.length > 0 && !questions?.data[0]?.respondido) {
      setShowQuiz(true);
    }
  }, [questions])

  let isLoading = isLoadingSubscriptionPending

  return (
    <ShoppingCartView
      isLoading={isLoading}
      activityChecked={activityChecked}
      vlTotal={vlTotal}
      incList={incList}
      goFinish={goFinish}
      openModal={openModal}
      openConfirmUnsubscribe={openConfirmUnsubscribe}
      handleCloseUnsubscribe={handleCloseUnsubscribe}
      onClickUnsubscribe={onClickUnsubscribe}
      center_cost_id={center_cost_id}
      toast={toast}
      paymentRedux={paymentRedux}
      paymentType={paymentType}
      totalState={totalState}
      fetchInscricoes={fetchInscricoes}
      subscriptionState={selected}
      state={state}
      setState={setState}
      courses={subscriptionPayment}
      selected={selected}
      setSelected={setSelected}
      total={total}
      subscription={subscription}
      user={user}
      showWrongCard={showWrongCard}
      resetStatePayment={resetStatePayment}
      onChangeSelected={onChangeSelected}
      onInvalidCardPayment={onInvalidCardPayment}
      setShowWrongCard={setShowWrongCard}
      changePayment={changePayment}
      setting={setting}
      settingCommerce={settingCommerce}
      dispatch={dispatch}
      amountProduct={amountProduct}
      amountProductChange={amountProductChange}
      goTo={goTo}
      showQuiz={showQuiz}
      setShowQuiz={setShowQuiz}
      authRedux={authRedux}
    />
  );
};

export default ShoppingCartContainer