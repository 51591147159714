import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { toast } from "../../redux";
import {
  Config,
  useFetchApi,
  useRedirect,
  convertBase64,
  Translator
} from "@itarget/shared";

import PanelView from "./views/PanelView";

const PanelContainer = () => {
  const { goTo } = useRedirect();
  const dispatch = useDispatch();
  const [openMyData, setOpenMyData] = useState(false);
  const [stateProfile, setStateProfile] = useState("profile");

  const user = useSelector((state) => state.auth.user);
  const selectedLang = useSelector((state) => state.language.lang)
  const centro_custo_id = useSelector((state) => state.auth.cc);

  const handleOpenMyData = () => {
    setOpenMyData(true);
  };

  const handleCloseMyData = () => {
    setOpenMyData(false);
  };

  const {
    data: changePassword,
    error: erroChangePassword,
    fetchData: fetchChangePassword,
  } = useFetchApi(Config.baseURL + `api/${selectedLang}/person/${user?.data?.id}`, "PUT");

  const { data: gamefication, fetchData: fetchGamefication } = useFetchApi(
    Config.baseURL +
    `api/${selectedLang}/person/${user?.data?.id}/gamification?centro_custo_id=${centro_custo_id}`
  );

  useEffect(() => {
    fetchGamefication();
  }, [fetchGamefication]);

  useEffect(() => {
    if (erroChangePassword !== null) {
      dispatch(
        toast.actions.enqueue({
          content: erroChangePassword,
          severity: "error",
        })
      );
    }
    if (changePassword !== null) {
      dispatch(
        toast.actions.enqueue({
          content: changePassword.message,
          severity: "success",
        })
      );
    }
  }, [changePassword, erroChangePassword, dispatch]);

  const onSubmit = (values) => {
    if (values.confirma_senha !== values.senha) {
      dispatch(
        toast.actions.enqueue({
          content: Translator({ transRef: "translation.As senhas não são compatíveis" }),
          severity: "error",
        })
      );
      return;
    }
    fetchChangePassword(JSON.stringify(values));
  };
  const formik = useFormik({
    initialValues: {
      id: user?.data?.id,
      senha: "",
      confirma_senha: "",
    },
    enableReinitialize: true,
    onSubmit,
  });

  const sendPhoto = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    fetchChangePassword(
      JSON.stringify({
        id: user.data.id,
        foto: base64,
      })
    );
  };

  return (
    <PanelView
      gamefication={gamefication}
      stateProfile={stateProfile}
      centro_custo_id={centro_custo_id}
      user={user}
      goTo={goTo}
      openMyData={openMyData}
      formik={formik}
      setStateProfile={setStateProfile}
      handleOpenMyData={handleOpenMyData}
      handleCloseMyData={handleCloseMyData}
      sendPhoto={sendPhoto}
    />
  );
};

export default PanelContainer;
