import styled from 'styled-components'

export const StylesIapo = styled.div`
@font-face {
  font-family: "Gotham";
  src: url("fonts/Gotham-Bold.woff2") format("woff2"), url("fonts/Gotham-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

#menu-desktop {
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.menu,
.menu li,
.menu a {
  margin: 0;
  list-style: none;
  text-decoration: none;
  font-size: 0.9em;
  font-weight: 300;
  text-transform: uppercase;
  word-spacing: 0px;
}
.toggle,
[id^="drop"] {
  display: none;
}

.current-menu-item .nav-link {
  background-color: #f06363;
  font-weight: bold;
}
ul.menu ul {
  display: none;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  border-bottom: 3px solid rgba(0, 0, 0, 0.15);
}

ul.menu li:hover ul.submenu-1 li:hover ul.submenu-2 {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 30;
  top: 0px !important;
  left: 100%;
  padding: 0 !important;
}

/* Configurações Nivel 1 */
.nav-menu-desktop {
  display: flex;
  flex-direction: row;
}

.nav-menu {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.menu {
  flex-wrap: wrap;
  flex: 1 0 auto;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  flexBasis: '83.333333%',
  maxWidth: '83.333333%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}

.menu li {
  float: left;
  width: auto;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu li a {
  border: 0;
  padding: 2.5px 10px;
  display: block;
  line-height: 17px;
  white-space: normal;
  font-variant: all-small-caps;
  font-weight: 300;
  font-size: 1.2rem;
  float: left;
  clear: both;
  gap: 1rem;
  transition: all 0.2s linear;
  text-transform: uppercase;
}

.show .menu a.parent.li-link {
  transition: border 0.2s linear;
}

.show .menu a.parent.li-link::after {
  content: '▼';
  font-size: .7rem;
  float: right;
  margin-left: 2px;
}

.menu li:hover > .li-link {
  transition: border 0.2s linear;
}

/* Configurações Nivel 2*/
.menu li:hover> ul.submenu-1 {
  z-index: 2;
  display: flex;
  flex-direction: column;
  position: absolute;
  gap: 0.3rem;
  top: 2.3rem;
  left: 0;
  padding: 0.3rem 0;
}

.menu ul.submenu-1 a {
  border-radius: 0;
  min-width: 8.5rem;
  border: 0;
  color: #333;
  background-color: #fff;
  padding: 0.25rem 1rem;
  width: 100%;
}
.menu ul.submenu-1 a:hover {
  background: #e9ecef;
  color: #1e2125;
}

/* Configurações nivel 2*/

.menu ul.submenu-2 a {
  overflow-x: hidden;
  max-width: 200px;
  width: 100%;
  border: 0;
}
.menu ul.submenu-2 a:hover {
  color: #222;
  background: #f7f7f7;
}

/* Configurações nivel 3*/

.menu ul.submenu-3 a {
  padding: 0 20px;
  border-radius: 5px;
}

.menu li a {
  color: #1c6ab9;
}

.menu li a: hover, .menu li a: hover::after {
  color: #1162b3;
}

.menu li a: hover, .menu li a: hover::after {
  color: #1162b3;
}

.menu a {
  color: #fff;
  border-right: 1px solid #fff;
}

/*nivel 2*/
.submenu-1 li a {
  color: #1c6ab9 !important;
}

.submenu-1 li:hover > a {
  background: #fff;
  color: #1162b3 !important;
}

/*nivel 3*/
.submenu-2 {
  background: rgb(42, 116, 153);
}
.submenu-2 a {
  color: #fff;
}
.submenu-2 li:hover > a {
  background: #fff;
}

/*nivel 3*/
.submenu-3 {
  background: rgb(42, 116, 153);
}
.submenu-3 a {
  color: #fff;
}
.submenu-3 li:hover > a {
  background: #000;
}

.MuiSvgIcon-root {
  font-size: 17px !important;
}

@media screen and (max-width: 1510px) {
  .menu li a {
    padding: 2.5px 5px;
  }
} 

@media screen and (max-width: 1410px) {
  .menu li a {
    padding: 2.5px 2.5px;
  }
} 

@media screen and (max-width: 1360px) {
  .menu li a {
    font-size: 1rem;
  }
} 

@media screen and (max-width: 1045px) {
  .menu li a {
    font-size: 1rem;
  }
} 

@media screen and (max-width: 950px) {
  #menu-desktop {
    flex: 1;
    bottom: -3rem;
    left: 0;
    position: absolute;
  }

  .menu li:last-child {
    -webkit-box-shadow: 0px 20px 32px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 20px 32px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 20px 32px -10px rgba(0,0,0,0.75);
  }

  .show .menu li.show > ul.submenu-1 {
    display: block;
    position: absolute;
    z-index: 30;
    top: 2.8rem !important;
    left: 0 !important;
    border-radius: inherit;
    border: none;
    width: 100vw;
    flex: 0;
    padding: 0;
  }

  .nav-menu {
    z-index: 1;
    max-width: 100vw;
    width: 100vw;
    padding: 0;
    margin: 0;
    flexDirection: column-reverse;
  }
  .nav-search {
    display: none;
  }

  .nav-menu .menu {
    flex: 0;
    padding: 0;
    width: 100vw;
    gap: 0;
  }

  .nav-menu .menu ul {
    width: 100vw;
  }

  ul.menu li {
    width: 100vw;
    background-color: white;
    border-bottom: 1px solid #529dd7;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
  }

  ul.menu li:hover ul.submenu-1 li:hover ul.submenu-2 {
    display: block;
    position: absolute;
    z-index: 30;
    top: 2.8rem !important;
    left: 0 !important;
    border-radius: inherit;
    border: none;
    width: 100%;
  }

  ul.menu li {
    width: 100vw;
    min-height: 30px;
    position: relative;
  }

  ul.menu li a {
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.5rem;
  }

  ul.menu li:hover ul li a::after {
    content: inherit;
  }

  .menu li:hover> ul.submenu-1 {
    width: 100%;
    z-index: 2;
    position: absolute;
    top: 35px;
    left: -0;
    min-width: 8.5rem;
  }
  
  .menu li:hover> ul.submenu-1 {
    width: 100%;
    position: absolute;
    z-index: 30;
    left: 0;
    border-radius: inherit;
    border: none;
    width: 100%;
  }

  .menu li:hover> ul.submenu-1 > li > ul.submenu-2 {
    display: none;
    position: absolute;
    z-index: 30;
    left: 0;
    padding: 0 !important;
    border-radius: inherit;
    border: none;
    width: 100%;
  }

  .menu li:hover> ul.submenu-1 > li:hover > ul.submenu-2 {
    display: block;
  }

  .menu li:hover> ul.submenu-1 > li {
    background: #fff;
    display: inline-block !important;
    width: 100%;
  }

  .menu li:hover> ul.submenu-1 > li > ul.submenu-2 > li {
    background: #dfdfdf;
    display: inline-block !important;
    width: 100%;
  }
}
`
