import React, { useContext } from 'react'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FilterListIcon from '@material-ui/icons/FilterList'
import SchoolIcon from '@material-ui/icons/School'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { SidebarContext } from '../../context/SidebarContext'
import './style.scss'
import { Grid, TextField, FormControlLabel, Checkbox, FormGroup, Chip, Button, FormLabel } from '@material-ui/core'
import { formatDate, Loading, Translator } from '@itarget/shared'
import { makeStyles } from '@material-ui/styles'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  accordionStyle: {
    width: '100%',
    '& input[type="time"]::-webkit-calendar-picker-indicator': {
      colorScheme: (props) => props.theme,
    },
    '& .MuiFormControlLabel-root': {
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
  },
}))

const DetailAccordion = ({
  programing,
  datePrograming,
  formik,
  selectedFilter,
  cleanDateFilter,
  checked,
  handleChange,
  loadingProgram,
  showActivitiesWithVideo,
  handleShowActivitiesWithVideo,
}) => {
  const systemTheme = useSelector((state) => state.theme.systemTheme)
  const styles = useStyles({ theme: systemTheme })
  const translation = {
    activitiesWithVideoLabel: Translator({ transRef: 'translation.Filtrar atividades com vídeo' }),
  }
  const hasSelectedFilter = Object.keys(selectedFilter).length > 0
  return (
    <Grid
      item
      spacing={2}
      direction='column'
      container>
      <Grid
        container
        item>
        <Accordion className={styles.accordionStyle}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'>
            <Grid
              alignItems='center'
              container
              spacing={1}>
              <Grid item>
                <SchoolIcon className='school-list-icon' />
              </Grid>
              <Grid item>
                <Typography>
                  <Translator transRef='translation.Agenda do curso' />
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              {loadingProgram ? (
                <Grid
                  item
                  container
                  alignItems='center'
                  justifyContent='center'>
                  <Loading />
                </Grid>
              ) : (
                <Grid
                  item
                  direction='column'
                  spacing='1'
                  container>
                  <Grid item>
                    <Typography>
                      <Translator transRef='translation.Selecione uma data' />
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    spacing={1}
                    direction='column'>
                    {datePrograming?.map((date, i) => {
                      return (
                        <Grid item>
                          {!checked[date] && (
                            <FormControlLabel
                              key={i}
                              value={date}
                              control={
                                <Checkbox
                                  checked={checked[date]}
                                  onChange={handleChange}
                                  name='data_inicio'
                                />
                              }
                              label={formatDate.ptBr(date)}
                              labelPlacement='end'
                            />
                          )}
                          {checked[date] && (
                            <Chip
                              label={formatDate.ptBr(date)}
                              onDelete={cleanDateFilter}
                            />
                          )}
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid
        container
        item>
        <Accordion className={styles.accordionStyle}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'>
            <Grid
              container
              spacing={1}>
              <Grid item>
                <FilterListIcon className='filter-list-icon' />
              </Grid>
              <Grid item>
                <Typography>
                  <Translator transRef='translation.Filtrar por' />
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              spacing={2}
              direction='column'
              container>
              <Grid
                container
                item
                direction='column'
                spacing={1}>
                <Grid item>
                  <Typography color='textPrimary'>
                    <Translator transRef='translation.Atividades com vídeo' />
                  </Typography>
                </Grid>
                <Grid
                  item
                  alignItems='center'
                  spacing={1}
                  container>
                  <Grid item>
                    <Checkbox
                      defaultChecked={selectedFilter?.agenda_atividades}
                      checked={showActivitiesWithVideo}
                      onChange={handleShowActivitiesWithVideo}
                      onClick={handleChange}
                      name='exibe_video_palestra'
                    />
                  </Grid>
                  <Grid item>
                    <Typography>{translation.activitiesWithVideoLabel}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                spacing={1}
                direction='column'
                container>
                <Grid item>
                  <Typography>
                    <Translator transRef='translation.Horário' />
                  </Typography>
                </Grid>
                <Grid
                  item
                  spacing={1}
                  container>
                  <Grid
                    item
                    container
                    xs={6}>
                    <TextField
                      defaultValue='00:00'
                      className='dateHour-filter'
                      type='time'
                      id='agenda_atividades-hora_inicio'
                      name='agenda_atividades[hora_inicio]'
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    xs={6}>
                    <TextField
                      defaultValue='00:00'
                      className='dateHour-filter'
                      type='time'
                      id='agenda_atividades-hora_fim'
                      name='agenda_atividades[hora_fim]'
                      onChange={formik.handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                spacing={1}
                direction='column'
                container>
                <Grid item>
                  <Typography>
                    <Translator transRef='translation.Tipo de atividade' />
                  </Typography>
                </Grid>
                <Grid item>
                  <FormControl
                    size='small'
                    variant='outlined'
                    className='select-control'>
                    <InputLabel id='activity'>
                      <Translator transRef='translation.Tipo de Atividade' />
                    </InputLabel>
                    <Select
                      id='tipo_atividade'
                      name='tipos_atividades_idiomas[id]'
                      onChange={formik.handleChange}>
                      <MenuItem value={''}>
                        <Translator transRef='translation.Todas' />
                      </MenuItem>
                      {programing?.tipo_atividade?.map((activity, index) => (
                        <MenuItem
                          key={index}
                          value={activity.uuid}>
                          {activity.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                item
                direction='column'
                container
                spacing={1}>
                <Grid item>
                  <Typography>
                    <Translator transRef='translation.Eixo' />
                  </Typography>
                </Grid>
                <Grid item>
                  <FormControl
                    size='small'
                    variant='outlined'
                    className='select-control'>
                    <InputLabel id='area'>
                      <Translator transRef='translation.Eixo' />
                    </InputLabel>
                    <Select
                      id='area'
                      name='eixos_idiomas[id]'
                      onChange={formik.handleChange}>
                      <MenuItem value={''}>
                        <Translator transRef='translation.Todas' />
                      </MenuItem>
                      {programing?.area?.map((area, index) => (
                        <MenuItem
                          key={index}
                          value={area.uuid}>
                          {area.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                item
                container
                justifyContent={hasSelectedFilter ? 'space-between' : 'flex-end'}>
                {hasSelectedFilter && (
                  <Button
                    onClick={() => cleanDateFilter()}
                    variant='contained'>
                    <Translator transRef='translation.Limpar filtros' />
                  </Button>
                )}
                <IconButton
                  size='medium'
                  type='submit'>
                  <SearchIcon />
                </IconButton>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  )
}

export default DetailAccordion
