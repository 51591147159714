import { Box, CircularProgress } from '@material-ui/core'
import { Button } from '@itarget/shared'
import { useAvailableStyles } from './styles'
import { useAvailableTranslation } from './translation'

const Available = ({ onSubscribe = () => {}, onAddCart = () => {}, isLoading = false, enableShoppingCart = true }) => {
  const styles = useAvailableStyles()
  const translation = useAvailableTranslation()

  return (
    <Box className={styles.container}>
      <Box className={styles.actionsContainer}>
        {enableShoppingCart && (
          <Button
            onClick={onAddCart}
            disabled={isLoading}
            icon={isLoading ? '' : 'add_shopping_cart'}
            className={`${styles.button} ${styles.buttonOnAddCart}`}>
            {isLoading ? (
              <CircularProgress
                size={20}
                color='inherit'
              />
            ) : (
              translation.addCart
            )}
          </Button>
        )}
        <Button
          disabled={isLoading}
          onClick={onSubscribe}
          className={`${styles.button} ${styles.buttonOnSubscribe}`}
          icon={isLoading ? '' : 'done'}>
          {isLoading ? (
            <CircularProgress
              size={20}
              color='inherit'
            />
          ) : (
            translation.subscribe
          )}
        </Button>
      </Box>
    </Box>
  )
}

export default Available
