import { Box, Icon, Typography } from '@material-ui/core'
import { useUnavailableStyles } from './styles'
import { useUnavailableTranslation } from './translation'

const Unavailable = () => {
  const styles = useUnavailableStyles()
  const translation = useUnavailableTranslation()

  return (
    <Box className={styles.container}>
      <Icon className={styles.unavailableIcon}>warning</Icon>
      <Typography className={styles.unavailableMessage}>{translation.unavailable}</Typography>
    </Box>
  )
}

export default Unavailable
