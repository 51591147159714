import './style.scss'
import { Container, Typography, Grid, Breadcrumbs, Icon, Divider } from '@material-ui/core'
import { deburr } from 'lodash'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Styles } from './styles'
import { useRef, useState, useEffect, useContext } from 'react'
import { RTag, Translator, formatDate, Loading } from '@itarget/shared'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import Layout from '../../../components/Layout'
import Model1 from '../../../components/CardOffers/Model1'
import Model2 from '../../../components/CardOffers/Model2'
import Model3 from '../../../components/CardOffers/Model3'
import Model4 from '../../../components/CardOffers/Model4'
import Model5 from '../../../components/CardOffers/Model5'
import Model7 from '../../../components/CardOffers/Model7'
import CardDetailStore from '../../../components/CardDetailStore'
import LinkComponentStore from '../../../components/LinkComponent/LinkComponentStore'
import ButtonArrowToTop from '../../../components/ButtonArrowToTop'
import SortButton from '../../../components/SortButton/SortButton'
import { MenuStoreContext } from '../../../context/MenuStoreContext'
import { ModalQuestions } from '../../../components/ModalQuestionsCourse'
import { makeStyles } from '@material-ui/styles'
import SearchTopBar from '../../../components/SearchTopBar'

const useStyles = makeStyles((theme) => ({
  modelsContainer: {
    marginBottom: theme.spacing(3.75),
    '& > .MuiGrid-item': {
      [theme.breakpoints.up(theme.breakpoints.values.xs)]: {
        padding: theme.spacing(2, 0),
      },
      [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        padding: theme.spacing(2, 2),
      },
      [theme.breakpoints.up(theme.breakpoints.values.md)]: {
        padding: theme.spacing(2, 1),
      },
      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        padding: theme.spacing(1, 1),
      },
      [theme.breakpoints.up(theme.breakpoints.values.xl)]: {
        padding: theme.spacing(1, 1),
      },
    },
  },
  listContainer: {
    marginBottom: theme.spacing(3.75),
    '& > .MuiGrid-item': {
      [theme.breakpoints.up(theme.breakpoints.values.xs)]: {
        padding: theme.spacing(1, 0),
      },
      [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        padding: theme.spacing(1, 0),
      },
      [theme.breakpoints.up(theme.breakpoints.values.md)]: {
        padding: theme.spacing(2, 0),
      },
      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        padding: theme.spacing(2, 0),
      },
      [theme.breakpoints.up(theme.breakpoints.values.xl)]: {
        padding: theme.spacing(2, 0),
      },
    },
  },
}))

const EadView = ({
  offers,
  axis,
  tags,
  formik,
  groupByOffers,
  activityTypes,
  filterHandler,
  handleChange,
  selectOffer,
  selectProduct,
  loading,
  handleInputChangeDebounced,
  centerCostId,
  onClickRegistered,
  selectedFilter,
  showQuiz,
  setShowQuiz,
}) => {
  const myRef = useRef({})
  const user = useSelector((state) => state.auth.user)
  const conditionModel = 7
  const [gridView, setGridView] = useState(true)
  const styles = useStyles()

  useEffect(() => {
    const { hash } = window.location
    if (hash) {
      const element = myRef?.current?.querySelector(hash)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [myRef])

  const { products } = useContext(MenuStoreContext)
  const filteredOffers = Object.values(products).flat()

  function OffersModal(props) {
    const { offers, conditionModel, selectOffer, onClickRegistered } = props

    if (conditionModel === 6) {
      if (filteredOffers.length == 0 && offers?.length >= 1) {
        return (
          <Grid
            container
            justify='center'>
            <Typography variant='h6'>
              <Translator transRef='translation.Sem itens correspondentes a sua pesquisa' />
            </Typography>
          </Grid>
        )
      }
      return filteredOffers.map((row, i) => {
        return (
          <Grid
            className={'offersModal-responsive'}
            key={i}
            xs={6}
            sm={6}
            md={4}
            lg={4}
            xl={2}
            item>
            <CardDetailStore
              direction='row'
              row={row}
              selectOffer={selectProduct}
            />
          </Grid>
        )
      })
    }

    if (!gridView) {
      return offers.map((row, i) => (
        <Grid
          key={i}
          xs={12}
          spacing={4}
          container
          item>
          <Model7
            card={row}
            onClick={selectOffer}
          />
        </Grid>
      ))
    } else {
      return offers.map((row, i) => (
        <Grid
          className='grid-model-container'
          item
          key={i}
          xs={12}
          sm={6}
          md={4}
          lg={3}>
          {conditionModel === 1 && (
            <Model1
              card={row}
              onClick={selectOffer}
            />
          )}
          {conditionModel === 2 && (
            <Model2
              card={row}
              onClick={selectOffer}
            />
          )}
          {conditionModel === 3 && (
            <Model3
              card={row}
              onClick={selectOffer}
            />
          )}
          {(conditionModel === 4 || 7) && (
            <Model4
              card={row}
              onClick={selectOffer}
              onClickRegistered={onClickRegistered}
            />
          )}
          {conditionModel === 5 && (
            <Model5
              card={row}
              onClick={selectOffer}
            />
          )}
        </Grid>
      ))
    }
  }

  const GridLayoutHandler = () => {
    setGridView(true)
  }

  const ListLayoutHandler = () => {
    setGridView(false)
  }

  return (
    <Styles isLogged={user?.data?.id ? true : false}>
      <Layout>
        {showQuiz && (
          <ModalQuestions
            setShowQuiz={() => {
              setShowQuiz()
            }}
            questionDestination={17}
            centerCostId={centerCostId}
          />
        )}
        <Container
          maxWidth='100%'
          style={{ width: '100%' }}
          className=''>
          {conditionModel !== 6 || gridView ? null : (
            <>
              <RTag show='desktop'>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <Link to={`/store/19`}>
                    <Icon
                      style={{
                        marginRight: '16px',
                        color: '#0086ff',
                      }}>
                      home
                    </Icon>
                  </Link>
                  <Breadcrumbs
                    separator={
                      <NavigateNextIcon
                        style={{ color: '#6d6d6d60' }}
                        fontSize='small'
                      />
                    }
                    aria-label='breadcrumb'
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <LinkComponentStore offers={offers} />
                  </Breadcrumbs>
                </div>
              </RTag>
              <RTag show='mobile'>
                <Grid
                  container
                  spacing={2}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px 20px',
                  }}>
                  <Grid
                    item
                    xs={6}>
                    <Breadcrumbs
                      separator={
                        <NavigateNextIcon
                          style={{
                            color: '#6d6d6d60',
                          }}
                          fontSize='small'
                        />
                      }
                      aria-label='breadcrumb'
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <LinkComponentStore offers={offers} />
                    </Breadcrumbs>
                  </Grid>
                </Grid>
              </RTag>
            </>
          )}
        </Container>
        {[4, 7].includes(conditionModel) && (
          <SearchTopBar
            formik={formik}
            filterHandler={filterHandler}
            activityTypes={activityTypes}
            axis={axis}
            tags={tags}
            handleChange={handleChange}
            handleInputChangeDebounced={handleInputChangeDebounced}
          />
        )}
        <Grid
          container
          xs={12}>
          {loading ? (
            <Grid
              container
              justify='center'>
              <Loading />
            </Grid>
          ) : (
            <Grid
              item
              container
              xs={12}>
              <Grid
                container
                justifyContent='center'>
                {offers.length === 0 && (
                  <Typography
                    className='no-display'
                    variant='h6'>
                    <Translator transRef='translation.Sem itens cadastrados' />
                  </Typography>
                )}

                <Grid
                  container
                  ref={myRef}>
                  {conditionModel === 6 ? (
                    groupByOffers ? (
                      <Grid
                        item
                        xs={12}
                        className={styles.modelsContainer}
                        container>
                        <OffersModal
                          conditionModel={conditionModel}
                          selectOffer={selectOffer}
                          onClickRegistered={onClickRegistered}
                        />
                      </Grid>
                    ) : (
                      <Grid
                        item
                        xs={12}
                        className={styles.modelsContainer}
                        container>
                        <OffersModal
                          offers={offers}
                          conditionModel={conditionModel}
                          selectOffer={selectOffer}
                          onClickRegistered={onClickRegistered}
                        />
                      </Grid>
                    )
                  ) : groupByOffers ? (
                    Object.entries(offers).map(([title, offer], i) => {
                      const id = deburr(title).replace(/\s/g, '-').toLowerCase()
                      return (
                        <Grid
                          key={i}
                          spacing={3}
                          container>
                          {conditionModel !== 6 && (
                            <Grid
                              item
                              xs={12}
                              direction='column'
                              spacing={2}
                              container>
                              <Grid
                                item
                                container
                                alignItems='flex-end'
                                justifyContent='space-between'>
                                <Grid item>
                                  <Typography
                                    variant='h6'
                                    id={id}>
                                    {selectedFilter?.grouping_field === 'data_inicio_aga'
                                      ? formatDate.ptBr(title)
                                      : title.length > 50
                                      ? `${title.slice(0, 45)}...`
                                      : title}
                                  </Typography>
                                </Grid>
                                {conditionModel === 7 && i < 1 ? (
                                  <Grid item>
                                    <SortButton
                                      gridLayoutHandler={GridLayoutHandler}
                                      listLayoutHandler={ListLayoutHandler}
                                    />
                                  </Grid>
                                ) : null}
                              </Grid>
                              <Grid item>
                                <Divider />
                              </Grid>
                            </Grid>
                          )}
                          <Grid
                            item
                            xs={12}
                            className={gridView ? styles.modelsContainer : styles.listContainer}
                            container>
                            <OffersModal
                              offers={offer}
                              conditionModel={conditionModel}
                              selectOffer={selectOffer}
                              onClickRegistered={onClickRegistered}
                            />
                          </Grid>
                        </Grid>
                      )
                    })
                  ) : (
                    <Grid
                      item
                      xs={12}
                      className={gridView ? styles.modelsContainer : styles.listContainer}
                      container>
                      <OffersModal
                        offers={offers}
                        conditionModel={conditionModel}
                        selectOffer={selectOffer}
                        onClickRegistered={onClickRegistered}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <ButtonArrowToTop />
      </Layout>
    </Styles>
  )
}
export default EadView
