import * as actions from './actions'
import produce from 'immer'

const initialState = {
  settingCommerce: [],
  settingLogin: [],
  isLoading: false
};

export const reducer = produce((state, action) => {
  switch (action.type) {
    case actions.SETSETTINGCOMMERCE:
      state.settingCommerce = {...state.settingCommerce, ...action.payload.setting}
      break
    case actions.SETENTITY:
      state.entity = action.payload.entity
      break
    case actions.SETCENTERCOST:
      state.centerCost = action.payload.centerCost
      break
    case actions.SETSETTINGLOGIN:
      state.settingLogin = action.payload.settingLogin;
      break;
    case actions.SETISLOADINGSETTINGLOGIN:
      state.isLoadingSettingLogin = action.payload.isLoadingSettingLogin;
      break;
    default:
      break
  }
}, initialState)
