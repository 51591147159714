import { all, takeLatest, put, delay, call } from "redux-saga/effects";
import { Config } from "@itarget/shared";

import * as actions from "./actions";
import { actions as fetchActions } from "../fetch";
import { actions as toastActions } from "../toast";
import { actions as offersActions } from "../offers";

function* rootSaga() {
  function* loginFulfilled(action) {
    yield put(actions.setIsLoadingFetch(false))

    yield call(action.callback && action.callback())
    // yield put(
    //   toastActions.enqueue({
    //     content: action.payload.message,
    //     severity: "success",
    //   })
    // );
  }
  function* loginFailed(action) {
    yield put(actions.setIsLoadingFetch(false))
    yield put(
      toastActions.enqueue({
        content: action.payload.message,
        severity: "error",
      })
    );
  }

  function* registerFulfilled(action) {
    yield put(actions.setIsLoadingFetch(false))
    const body = JSON.parse(action.meta.body);
    yield put(
      fetchActions.fetchStarted({
        type: "FETCH_STARTED",
        meta: {
          actions: actions.LOGIN,
          body: JSON.stringify({
            login: body.pessoa.email,
            senha: body.pessoa.senha,
            centro_custo_id: body.centro_custo_id,
            autorizou_acesso: [body.politica_privacidade],
          }),
          url: Config.baseURL + "api/pt-br/login",
          method: "POST",
        },
      })
    );
    // yield put(
    //   toastActions.enqueue({
    //     content: action.payload.message,
    //     severity: "success",
    //   })
    // );
  }

  function* registerFailed(action) {
    yield put(actions.setIsLoadingFetch(false))
    yield put(
      toastActions.enqueue({
        content: action.payload.message,
        severity: "error",
      })
    );
  }

  function* logout() {
    yield put(offersActions.reset());
  }

  function* setToken(action) {
    yield put(fetchActions.setToken(action.payload.token));
    while (true) {
      yield delay(20 * 60 * 1000); //20 minutos
      yield put({
        type: "FETCH_STARTED",
        meta: {
          actions: actions.AUTHENTICATE_REQUEST,
          body: JSON.stringify({
            login: "icongressolive",
            senha: "FRB:y}|Y",
          }),
          url: Config.baseURL + "api/pt-br/authenticate",
          method: "POST",
        },
      });
    }
    // yield put({
    //   type: "FETCH_STARTED",
    //   meta: {
    //     actions: actions.AUTHENTICATE_REQUEST,
    //     body: JSON.stringify({
    //       login: "icongressolive",
    //       senha: "FRB:y}|Y",
    //     }),
    //     url: Config.baseURL + "api/pt-br/authenticate",
    //     method: "POST",
    //   },
    // });
  }

  function* fetchAction(action) {
    yield put(actions.setIsLoadingFetch(true))
    yield put(
      fetchActions.fetchStarted({
        type: 'FETCH_STARTED',
        meta: action.meta,
      })
    );
  }

  function* setIsLoadingFetch(action) {
    yield put({
      type: "SET_IS_LOADING_FETCH",
      payload: {
        isLoadingFetch: action.payload.status
      },
    })
  }

  function* watchForLoginFulfilled() {
    yield takeLatest(actions.LOGIN.FULFILLED, loginFulfilled);
  }
  function* watchForLoginFailed() {
    yield takeLatest(actions.LOGIN.FAILED, loginFailed);
  }

  function* watchForAuthenticateRequestFulfilled() {
    yield takeLatest(actions.AUTHENTICATE_REQUEST.FULFILLED, setToken);
  }

  function* watchForAuthenticateRequestFailed() {
    yield takeLatest(actions.AUTHENTICATE_REQUEST.FAILED, logout);
  }

  function* watchForLogout() {
    yield takeLatest(actions.LOGOUT, logout);
  }

  function* watchForRegisterFulfilled() {
    yield takeLatest(actions.REGISTER.FULFILLED, registerFulfilled);
  }
  function* watchForRegisterFailed() {
    yield takeLatest(actions.REGISTER.FAILED, registerFailed);
  }

  function* watchForSetLoadingFetch() {
    yield takeLatest(actions.FETCH_ACTION, fetchAction);
  }

  function* watchForSetIsLoadingFetch() {
    yield takeLatest(actions.SET_IS_LOADING_FETCH, setIsLoadingFetch);
  }

  yield all([
    watchForLoginFulfilled(),
    watchForLoginFailed(),
    watchForRegisterFulfilled(),
    watchForRegisterFailed(),
    watchForLogout(),
    watchForAuthenticateRequestFulfilled(),
    watchForAuthenticateRequestFailed(),
    watchForSetLoadingFetch(),
    watchForSetIsLoadingFetch(),
  ]);
}

export default rootSaga;
