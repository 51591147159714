import { IconButton } from '@material-ui/core'
import { theme as themeRedux } from '../../redux'
import { useDispatch, useSelector } from 'react-redux'
import { BrightnessHigh, Brightness2 } from '@material-ui/icons'
import './style.scss'

export const ButtonTheme = () => {
  const dispatch = useDispatch()
  const systemTheme = useSelector((state) => state.theme.systemTheme)

  const onChangeTheme = () => {
    dispatch(themeRedux.actions.setHasUserPreferenceTheme(true))
    dispatch(themeRedux.actions.setSystemTheme(systemTheme != 'light' ? 'light' : 'dark'))
  }

  const RenderIcon = () => {
    return systemTheme != 'light' ? <BrightnessHigh className='theme-icon' /> : <Brightness2 className='theme-icon' />
  }

  return (
    <IconButton
      className='theme-button'
      onClick={onChangeTheme}>
      <RenderIcon />
    </IconButton>
  )
}
