import { makeStyles } from '@material-ui/styles'

export const useCounterStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  textContainer: {
    flex: 2,
    display: 'flex',
    gap: theme.spacing(1),
    flexDirection: 'column',
  },
  title: {
    lineHeight: 1,
    fontWeight: '500',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.displayValuesOffers.counter.color,
  },
  limit: {
    lineHeight: 1,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.displayValuesOffers.counter.limitColor,
  },
  counterContainer: {
    flex: 1,
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: theme.spacing(0.5),
    border: `1px solid ${theme.palette.displayValuesOffers.counter.borderColor}`
  },
  iconButton: {
    display: 'flex',
    borderRadius: '0',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.typography.pxToRem(30),
    height: theme.typography.pxToRem(30),
    color: theme.palette.displayValuesOffers.counter.iconColor,
    '&.Mui-disabled': {
      color: theme.palette.displayValuesOffers.counter.iconDisabledColor,
    }
  },
  counter: {
    border: 0,
    padding: 0,
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.displayValuesOffers.counter.color,
  },
}))

export const useRootStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    borderRadius: 8,
    flexDirection: 'column',
    gap: theme.spacing(2.5),
    padding: theme.spacing(3.75, 2.5),
    backgroundColor: theme.palette.displayValuesOffers.root.backgroundColor,
  },
}))

export const useRegisteredStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    borderRadius: 8,
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    justifyContent: 'center',
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.displayValuesOffers.registered.backgroundColor,
  },
  registeredAlert: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    justifyContent: 'center',
  },
  alertIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  alertIcon: {
    fontWeight: 'bold',
    fontSize: `${theme.typography.pxToRem(24)} !important`,
    color: theme.palette.displayValuesOffers.registered.successColor,
  },
  alertMessage: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.displayValuesOffers.registered.successColor,
  },
  message: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.displayValuesOffers.registered.color,
  },
  link: {
    cursor: 'pointer',
    fontWeight: 'bold',
    textDecoration: 'underline',
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.displayValuesOffers.registered.color,
  },
}))

export const useCourtesyStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(2.5),
    flexDirection: 'column',
  },
  button: {
    width: '100%',
    borderRadius: 20,
    padding: theme.spacing(1, 3.75),
    '& .MuiButton-label': {
      display: 'flex',
      textTransform: 'uppercase',
      justifyContent: 'flex-end',
      flexDirection: 'row-reverse',
      fontSize: theme.typography.pxToRem(12),
      '& .MuiIcon-root': {
        fontSize: theme.typography.pxToRem(14),
      },
    },
  },
  buttonOnConfirm: {
    color: theme.palette.displayValuesOffers.actionsButtons.onConfirm.color,
    backgroundColor: theme.palette.displayValuesOffers.actionsButtons.onConfirm.backgroundColor,
    '&:hover': {
      backgroundColor: theme.palette.displayValuesOffers.actionsButtons.onConfirm.backgroundColorHover,
    },
  },
  messageContainer: {
    display: 'flex',
    borderRadius: 8,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(2.5),
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.displayValuesOffers.courtesy.backgroundColor,
  },
  courtesyAlert: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    justifyContent: 'center',
  },
  alertIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  alertIcon: {
    fontWeight: 'bold',
    fontSize: `${theme.typography.pxToRem(24)} !important`,
    color: theme.palette.displayValuesOffers.courtesy.successColor,
  },
  alertMessage: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.displayValuesOffers.courtesy.successColor,
  },
  message: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.displayValuesOffers.courtesy.color,
  },
}))

export const useSoldOutStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(2.5),
    flexDirection: 'column',
  },
  button: {
    width: '100%',
    borderRadius: 20,
    padding: theme.spacing(1, 3.75),
    '& .MuiButton-label': {
      display: 'flex',
      textTransform: 'uppercase',
      justifyContent: 'flex-end',
      flexDirection: 'row-reverse',
      fontSize: theme.typography.pxToRem(12),
      '& .MuiIcon-root': {
        fontSize: theme.typography.pxToRem(14),
      },
    },
  },
  buttonOnWaitingList: {
    color: theme.palette.displayValuesOffers.actionsButtons.onWaitingList.color,
    backgroundColor: theme.palette.displayValuesOffers.actionsButtons.onWaitingList.backgroundColor,
    '&:hover': {
      backgroundColor: theme.palette.displayValuesOffers.actionsButtons.onWaitingList.backgroundColorHover,
    },
  },
  messageContainer: {
    display: 'flex',
    borderRadius: 8,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.displayValuesOffers.soldOut.backgroundColor,
  },
  soldOutAlert: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    justifyContent: 'center',
    marginBottom: theme.spacing(2.5),
  },
  alertIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  alertIcon: {
    fontWeight: 'bold',
    fontSize: `${theme.typography.pxToRem(17)} !important`,
    color: theme.palette.displayValuesOffers.soldOut.messageColor,
  },
  alertMessage: {
    lineHeight: 1,
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(17),
    color: theme.palette.displayValuesOffers.soldOut.messageColor,
  },
  message: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.displayValuesOffers.soldOut.color,
  },
}))

export const usePendingStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(1),
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  amount: {
    lineHeight: 1,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(32),
    color: theme.palette.displayValuesOffers.amount.color,
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },
  chip: {
    padding: theme.spacing(1.5, 0.5),
    height: theme.typography.pxToRem(22),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.displayValuesOffers.pending.backgroundColor,
    '& .MuiChip-icon, & .MuiChip-label': {
      fontWeight: 'bold',
      fontSize: theme.typography.pxToRem(12),
      color: theme.palette.displayValuesOffers.pending.color,
    },
    '& .MuiChip-label': {
      lineHeight: 1,
      textTransform: 'uppercase',
    },
  },
  linkContainer: {
    gap: 4,
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
  },
  linkIcon: {
    color: theme.palette.displayValuesOffers.pending.redirectColor,
  },
  link: {
    textDecoration: 'underline',
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.displayValuesOffers.pending.redirectColor,
  },
}))

export const useShoppingCartStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(1),
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  amount: {
    lineHeight: 1,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(32),
    color: theme.palette.displayValuesOffers.amount.color,
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },
  chip: {
    padding: theme.spacing(1.5, 0.5),
    height: theme.typography.pxToRem(22),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.displayValuesOffers.shoppingCart.backgroundColor,
    '& .MuiChip-icon, & .MuiChip-label': {
      fontWeight: 'bold',
      fontSize: theme.typography.pxToRem(12),
      color: theme.palette.displayValuesOffers.shoppingCart.color,
    },
    '& .MuiChip-label': {
      lineHeight: 1,
      textTransform: 'uppercase',
    },
  },
  linkContainer: {
    gap: 4,
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
  },
  linkIcon: {
    color: theme.palette.displayValuesOffers.shoppingCart.redirectColor,
  },
  link: {
    textDecoration: 'underline',
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.displayValuesOffers.shoppingCart.redirectColor,
  },
}))

export const useAvailableStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    gap: theme.spacing(4.5),
  },
  amount: {
    lineHeight: 1,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(32),
    color: theme.palette.displayValuesOffers.amount.color,
  },
  actionsContainer: {
    width: '100%',
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  button: {
    width: '100%',
    borderRadius: 20,
    padding: theme.spacing(1, 3.75),
    '& .MuiButton-label': {
      display: 'flex',
      textTransform: 'uppercase',
      justifyContent: 'flex-end',
      flexDirection: 'row-reverse',
      fontSize: theme.typography.pxToRem(12),
      '& .MuiIcon-root': {
        fontSize: theme.typography.pxToRem(14),
      },
    },
  },
  buttonOnSubscribe: {
    color: theme.palette.displayValuesOffers.actionsButtons.onSubscribe.color,
    backgroundColor: theme.palette.displayValuesOffers.actionsButtons.onSubscribe.backgroundColor,
    '&:hover': {
      backgroundColor: theme.palette.displayValuesOffers.actionsButtons.onSubscribe.backgroundColorHover,
    },
  },
  buttonOnAddCart: {
    color: theme.palette.displayValuesOffers.actionsButtons.onAddCart.color,
    backgroundColor: theme.palette.displayValuesOffers.actionsButtons.onAddCart.backgroundColor,
    '&:hover': {
      backgroundColor: theme.palette.displayValuesOffers.actionsButtons.onAddCart.backgroundColorHover,
    },
  },
}))

export const useWatchStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  button: {
    width: '100%',
    padding: theme.spacing(1, 3.75),
    '& .MuiButton-label': {
      display: 'flex',
      textTransform: 'uppercase',
      justifyContent: 'flex-end',
      flexDirection: 'row-reverse',
      fontSize: theme.typography.pxToRem(12),
      '& .MuiIcon-root': {
        fontSize: theme.typography.pxToRem(20),
      },
    },
  },
  buttonOnWatchStream: {
    color: theme.palette.displayValuesOffers.actionsButtons.onWatchStream.color,
    backgroundColor: theme.palette.displayValuesOffers.actionsButtons.onWatchStream.backgroundColor,
    '&:hover': {
      backgroundColor: theme.palette.displayValuesOffers.actionsButtons.onWatchStream.backgroundColorHover,
    },
  },
  buttonOnWatchRecorded: {
    color: theme.palette.displayValuesOffers.actionsButtons.onWatchRecorded.color,
    backgroundColor: theme.palette.displayValuesOffers.actionsButtons.onWatchRecorded.backgroundColor,
    '&:hover': {
      backgroundColor: theme.palette.displayValuesOffers.actionsButtons.onWatchRecorded.backgroundColorHover,
    },
  },
}))

export const useAmountStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  discountContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  chip: {
    display: 'flex',
    alignItems: 'center',
    height: 'fit-content',
    gap: theme.spacing(0.5),
    justifyContent: 'center',
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.displayValuesOffers.discount.backgroundColor,
  },
  discountText: {
    lineHeight: 1,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(10),
    color: theme.palette.displayValuesOffers.discount.color,
  },
  amountDiscounted: {
    lineHeight: 1,
    textDecoration: 'line-through',
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.displayValuesOffers.amount.discountedAmount,
  },
  amount: {
    lineHeight: 1,
    fontSize: theme.typography.pxToRem(32),
    color: theme.palette.displayValuesOffers.amount.color,
  },
  currencySymbol: {
    float: 'left',
    lineHeight: 1.785,
    marginRight: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
  },

  unavailableContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  unavailable: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(20),
  },
}))

export const useReturnStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginBottom: theme.spacing(2.5),
  },
  button: {
    width: 'min-content',
    justifyContent: 'flex-end',
    boxShadow: theme.shadows[0],
    color: theme.palette.displayValuesOffers.actionsButtons.onReturn.color,
    backgroundColor: theme.palette.displayValuesOffers.actionsButtons.onReturn.backgroundColor,
    '&:hover': {
      boxShadow: theme.shadows[0],
      backgroundColor: theme.palette.displayValuesOffers.actionsButtons.onReturn.backgroundColorHover,
    },
    '&:focus': {
      outline: 0,
    },
    '& .MuiButton-label': {
      display: 'flex',
      width: 'min-content',
      justifyContent: 'flex-end',
      textDecoration: 'underline',
      flexDirection: 'row-reverse',
      fontSize: theme.typography.pxToRem(12),
      '& .MuiIcon-root': {
        fontSize: theme.typography.pxToRem(16),
      },
    },
  },
}))

export const useUnavailableStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 4,
    display: 'flex',
    gap: theme.spacing(0.5),
    justifyContent: 'center',
    padding: theme.spacing(1.5, 1),
    backgroundColor: theme.palette.displayValuesOffers.unavailable.backgroundColor,
  },
  unavailableIcon: {
    fontSize: `${theme.typography.pxToRem(16)} !important`,
    color: theme.palette.displayValuesOffers.unavailable.iconColor,
  },
  unavailableMessage: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.displayValuesOffers.unavailable.color,
  },
}))

export const useLoadingStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    justifyContent: 'center',
    padding: theme.spacing(1.5, 1),
    backgroundColor: theme.palette.displayValuesOffers.loading.backgroundColor,
  },
  loadingIcon: {
    animation: '$spin 1s linear infinite',
    fontSize: `${theme.typography.pxToRem(16)} !important`,
    color: theme.palette.displayValuesOffers.loading.iconColor,
  },
  loadingMessage: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.displayValuesOffers.loading.color,
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(360deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
  },
}))

export const useCategoryStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.25),
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  selectLabel: {
    fontSize: theme.typography.pxToRem(12),
  },
  select: {
    width: '100%',
    height: theme.spacing(4.25),
    '& .MuiSelect-select': {
      lineHeight: 1.3,
      fontSize: theme.typography.pxToRem(14),
    },
  },
  selectItem: {
    fontSize: theme.typography.pxToRem(14),
  },
  button: {
    width: '100%',
    padding: theme.spacing(1, 3.75),
    '& .MuiButton-label': {
      display: 'flex',
      textTransform: 'uppercase',
      justifyContent: 'flex-end',
      flexDirection: 'row-reverse',
      fontSize: theme.typography.pxToRem(12),
      '& .MuiIcon-root': {
        fontSize: theme.typography.pxToRem(20),
      },
    },
  },
  buttonOnConfirmCategory: {},
  alertContainer: {
    display: 'flex',
    gap: theme.spacing(0.5),
    flexDirection: 'column',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.displayValuesOffers.category.backgroundColor,
    border: `1px solid ${theme.palette.displayValuesOffers.category.borderColor}`,
  },
  taskIcon: {
    fontSize: `${theme.typography.pxToRem(16)} !important`,
    color: theme.palette.displayValuesOffers.category.taskColor,
  },
  warningIcon: {
    fontSize: `${theme.typography.pxToRem(16)} !important`,
    color: theme.palette.displayValuesOffers.category.warningColor,
  },
  messageContainer: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  message: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.displayValuesOffers.category.color,
  },
  noCategoryMessage: {},
  organizingCommitteeMessage: {},
}))

export const useDetailStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    justifyContent: 'center',
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(1.5),
    padding: theme.spacing(2.5),
    backgroundColor: theme.palette.displayValuesOffers.detail.backgroundColor,
  },
  horizontalContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(10),
    color: theme.palette.displayValuesOffers.detail.color,
  },
  fieldValueContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  icon: {
    fontWeight: 500,
    color: theme.palette.displayValuesOffers.detail.color,
    fontSize: `${theme.typography.pxToRem(14)} !important`,
  },
  value: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.displayValuesOffers.detail.color,
  },
}))

export const useComboAnnuityStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    justifyContent: 'center',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.displayValuesOffers.comboAnnuity.backgroundColor,
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
  },
  checkbox: {
    padding: 0,
    fontSize: theme.spacing(2.75),
    color: theme.palette.displayValuesOffers.comboAnnuity.checkboxColor,
    backgroundColor: theme.palette.displayValuesOffers.comboAnnuity.checkboxBackgroundColor,
  },
  checkboxLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  checkboxLabel: {
    lineHeight: 1,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.displayValuesOffers.comboAnnuity.color,
  },
  annuityDetail: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(1),
    borderBottom: `1px dashed ${theme.palette.displayValuesOffers.comboAnnuity.dashedBorderColor}`,
    '& p, & span': {
      lineHeight: `1.1 !important`,
      fontSize: `${theme.typography.pxToRem(14)} !important`,
    },
  },
  annuityTitle: {
    fontSize: theme.typography.pxToRem(14),
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.displayValuesOffers.comboAnnuity.messageColor,
  },
}))

export const useTotalStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: theme.spacing(0.5),
    flexDirection: 'column',
  },
  label: {
    lineHeight: 1,
    fontSize: theme.typography.pxToRem(12),
  },
  amount: {
    fontSize: theme.typography.pxToRem(20),
    '& span': {
      fontSize: theme.typography.pxToRem(12),
    },
  },
}))

export const useReferenceStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  referenceContainer: {
    display: 'flex',
    gap: theme.spacing(0.5),
  },
  referenceLabel: {
    lineHeight: 1,
    fontSize: theme.typography.pxToRem(12),
  },
  reference: {
    lineHeight: 1,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(12),
  },
}))

export const useHeaderStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(1),
    flexDirection: 'column',
    marginBottom: theme.spacing(1),
  },
  title: {
    lineHeight: 1.1,
    fontSize: theme.typography.pxToRem(22),
    fontWeight: 700,
    color: theme.palette.displayValuesOffers.header.titleColor,
  },
  activityType: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.displayValuesOffers.header.axiosColor,
  },
}))
