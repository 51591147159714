import {
  all,
  put,
  call,
  select,
  takeEvery,
} from 'redux-saga/effects';

import * as actions from './actions';
import * as selectors from './selectors';

function* fetchData(action = {}) {
  try {
    const token = yield select(selectors.getToken);

    const params = {
      method: action.meta.method ? action.meta.method : 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: action.meta.method !== 'GET' ? action.meta.body : null,
    };
    const response = yield call(fetch, action.meta.url, params);
    const result = yield response.json();
    if (!response.ok){
      throw result
    }
    yield put(actions.fetchFulfilled());

    yield put({
      type: action.meta.actions.FULFILLED,
      payload: result,
      meta: action.meta ? action.meta : null ,
      callback: action.callback || null
    });
  } catch (e) {
    yield put(actions.fetchFailed());
    yield put({
      type: action.meta.actions.FAILED,
      payload: e,
    });
  }
}
// modulo fetch
function* watchFetchStarted() {
  yield takeEvery('FETCH_STARTED', fetchData);
}

function* rootSaga() {
  yield all([watchFetchStarted()]);
}

export default rootSaga;
