import { Box, LinearProgress, Typography, LinearProgressProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  linearProgressContainer: {
    flex: 1,
  },
  percentContainer: {
    minWidth: 35,
  },
}))

const LinearProgressWithLabel = (props) => {
  const styles = useStyles()
  return (
    <Box className={styles.container}>
      <Box className={styles.linearProgressContainer}>
        <LinearProgress
          variant='determinate'
          {...props}
        />
      </Box>
      <Box className={styles.percentContainer}>
        <Typography variant='body2'>{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}

export default LinearProgressWithLabel
