import React, { useContext } from 'react'
import { AppBar, Tabs, Tab, Divider, Typography, Grid, Box } from '@material-ui/core'
import { RTag, Translator, formatDate } from '@itarget/shared'
import { dateFormatToWeekday } from '@itarget/shared/src/utils/format/date'
import SchedulingTimeline from '../SchedulingTimeline/SchedulingTimeline'
import TimelineAccordion from '../TimelineAccordion/TimelineAccordion'
import { SidebarContext } from '../../context/SidebarContext'
import './style.scss'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  fitContentStyle: {
    height: 'fit-content',
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const TabNav = ({
  tabs,
  date,
  user,
  scheduling,
  subscriptions,
  participant,
  scheduleType,
  selectStep,
  datePrograming,
  roomTabs,
  dateActive,
  roomActive,
  roomTabHandleChange,
  dateTabHandleChange,
  showActivitiesWithVideo,
  filterOnlyVideoActivities,
}) => {
  const styles = useStyles()
  const { displayDate } = useContext(SidebarContext)
  return (
    <Grid
      className={styles.fitContentStyle}
      container>
      {datePrograming.length > 0 && roomTabs.length > 0 ? (
        <Grid
          item
          container>
          {displayDate && (
            <Grid
              container
              justifyContent='flex-end'>
              <Typography variant='h6'>{formatDate.ptBr(displayDate)}</Typography>
              <Divider
                orientation='vertical'
                variant='middle'
              />
              <Typography variant='h6'>{dateFormatToWeekday(displayDate)}</Typography>
            </Grid>
          )}
          <AppBar
            className={styles.fitContentStyle}
            color='default'
            position='static'>
            <Tabs
              variant='scrollable'
              scrollButtons='on'
              value={dateActive}>
              {datePrograming.map((date, index) => {
                return (
                  <Tab
                    key={index}
                    label={formatDate.ptBr(date)}
                    onClick={() => dateTabHandleChange(date, index)}
                  />
                )
              })}
            </Tabs>

            <Tabs
              variant='scrollable'
              scrollButtons='on'
              value={roomActive}>
              {roomTabs.map((rooms, index) => {
                return (
                  <Tab
                    key={index}
                    label={rooms.nome}
                    onClick={() => roomTabHandleChange(index)}
                  />
                )
              })}
            </Tabs>
          </AppBar>
        </Grid>
      ) : (
        <Grid
          className='noActivities-grid'
          container
          justify='center'>
          <Typography variant='h6'>
            <Translator transRef='translation.Não há atividades agendadas' />
          </Typography>
        </Grid>
      )}

      {roomTabs.map((roomTab, index) => (
        <TabPanel
          value={roomActive}
          index={index}
          key={index}>
          <RTag show='desktop'>
            <SchedulingTimeline
              user={user}
              date={date}
              tabs={tabs}
              room={roomTab}
              selectStep={selectStep}
              scheduling={scheduling}
              participant={participant}
              scheduleType={scheduleType}
              subscriptions={subscriptions}
              showActivitiesWithVideo={showActivitiesWithVideo}
              filterOnlyVideoActivities={filterOnlyVideoActivities}
            />
          </RTag>
          <RTag show='mobile'>
            <TimelineAccordion
              user={user}
              date={date}
              tabs={tabs}
              room={roomTab}
              selectStep={selectStep}
              scheduling={scheduling}
              participant={participant}
              scheduleType={scheduleType}
              subscriptions={subscriptions}
              showActivitiesWithVideo={showActivitiesWithVideo}
              filterOnlyVideoActivities={filterOnlyVideoActivities}
            />
          </RTag>
        </TabPanel>
      ))}
    </Grid>
  )
}

export default TabNav
