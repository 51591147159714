import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useFetchApi, Config, configurator } from "@itarget/shared"
import { subscription } from "../../redux"
import MyActivityView from "./views/MyActivityView"
import { payment, toast } from "../../redux"

const MyActivityContainer = () => {
  const dispatch = useDispatch()
  const [allSubscriptions, setAllSubscriptions] = useState({})
  const [tabs, setTabs] = useState({})
  const [openConfirmUnsubscribe, setOpenConfirmUnsubscribe] = useState(false)
  const [currentRow, setCurrentRow] = useState()
  const [currentId, setCurrentId] = useState()
  const [openChangePayment, setOpenChangePayment] = useState(false)
  const [showWrongCard, setShowWrongCard] = useState(false)
  const [subscriptionState, setSubscriptionState] = useState({})
  const [incIds, setIncIds] = useState("")
  const [setIncList] = useState()
  const [resetStatePayment, setResetStatePayment] = useState(false)
  const [selected, setSelected] = useState([])
  const [total, setTotal] = useState(0)
  const [state, setState] = useState("cards")
  const user = useSelector((state) => state.auth.user)
  const center_cost_id = useSelector((state) => state.auth.cc)
  const selectedLang = useSelector((state) => state.language.lang)
  const paymentType = useSelector((state) => state.payment.type)
  const totalState = useSelector((state) => state.payment.total)
  const settingCommerce = useSelector((state) => state.setting.settingCommerce)

  const {
    data: inscricoesApi,
    isLoading: isLoadingInscricoes,
    fetchData: fetchInscricoes,
  } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/subscription/${user.data && user.data.id}/group?centro_custo_id=${center_cost_id}`
  )

  const { data: setting, fetchData: fetchSetting } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/setting?centro_custo_id=${center_cost_id}&${configurator.MY_ACTIVITY}`
  )
  const { data: dataPix, isLoading: loadingPix, fetchData: fetchPix, errorResponse: errorPix } = useFetchApi()

  useEffect(() => {
    fetchSetting()
    fetchInscricoes()
  }, [])

  useEffect(() => {
    if (inscricoesApi && inscricoesApi.data) {
      setAllSubscriptions(inscricoesApi.data.all)
      setTabs(inscricoesApi.data.tabs)
    }
  }, [inscricoesApi])

  const handleClickOpen = () => {
    setOpenConfirmUnsubscribe(true)
  }

  const handleClose = () => {
    setOpenConfirmUnsubscribe(false)
    setOpenChangePayment(false)
  }

  const openModal = (row, id) => {
    handleClickOpen()
    setCurrentRow(row)
    setCurrentId(id)
  }

  const onChangeSelected = (e, card = {}) => {
    if (!["", null].includes(card.lote)) {
      if (e.target.checked) {
        Object.keys(inscricoesApi.data.tabs.data).forEach(function (key) {
          setSelected((selected) => {
            return [...selected, ...inscricoesApi.data.tabs.data[key].filter((p) => p.lote === card.lote)]
          })
        })
      } else {
        Object.keys(inscricoesApi.data.tabs.data).forEach(function (key) {
          setSelected((selected) => selected.filter((res) => res.lote !== card.lote))
        })
      }
      return
    }

    if (setting?.data?.filter((s) => s.codigo === 404).filter((f) => f.ativo === "S").length > 0) {
      if (e.target.checked) {
        if (card.categoria_tipo_atividade === 1) {
          setSelected([...selected, ...inscricoesApi.data.tabs.data[1].filter((d) => d.status_conta_receber === 1)])
          inscricoesApi.data.tabs.data[1] = inscricoesApi.data.tabs.data[1].map((sp) => ({
            ...sp,
            checked: true,
          }))

          setTabs(inscricoesApi.data.tabs)
          setAllSubscriptions(inscricoesApi.data.all)
          if (inscricoesApi.data.tabs.data[1].length > 1) {
            dispatch(
              toast.actions.enqueue({
                content: setting.data.filter((s) => s.codigo === 404)[0].valor_referencia,
              })
            )
          }
        } else {
          setSelected([...selected, card])
        }
      } else {
        if (card.categoria_tipo_atividade === 1) {
          setSelected((selected) =>
            selected.filter(
              (res) => !inscricoesApi.data.tabs.data[1].map((m) => m.agenda_atividade_id).includes(res.agenda_atividade_id)
            )
          )
          inscricoesApi.data.tabs.data[1] = inscricoesApi.data.tabs.data[1].map((sp) => ({
            ...sp,
            checked: false,
          }))
          setTabs(inscricoesApi.data.tabs)
          setAllSubscriptions(inscricoesApi.data.all)
        } else {
          setSelected((selected) => selected.filter((res) => res.agenda_atividade_id !== card.agenda_atividade_id))
        }
      }
    } else {
      if (e.target.checked) {
        setSelected([...selected, card])
      } else {
        setSelected((selected) => selected.filter((res) => res.agenda_atividade_id !== card.agenda_atividade_id))
      }
    }
  }

  const sum = (numbers) => {
    var total = numbers.reduce(function (total, numero) {
      if (numero) {
        return total + numero
      }
      return total
    }, 0)
    setTotal(total)
  }

  useEffect(() => {
    if (selected?.length > 0) {
      sum(selected.map((row) => parseFloat(row.cr_valor)))
      let ids = selected.map((res, i) => {
        return res?.conta_receber_id
      })
      setIncIds(ids.toString())
    } else {
      setTotal(0)
    }
  }, [selected])

  useEffect(() => {
    dispatch(payment.actions.setTotal(total))
  }, [total, dispatch])

  const { data: accountReceive } = useFetchApi(Config.baseURL + `api/${selectedLang}/finance/account-receive/` + incIds + "/receipt")

  useEffect(() => {
    if (resetStatePayment) {
      setResetStatePayment(false)
    }
  }, [resetStatePayment])

  const goFinish = () => {
    setState("finish")
  }
  useEffect(() => {
    if (accountReceive) {
      setIncList(accountReceive.data)
    }
  }, [accountReceive, setIncList])


  const onClickUnsubscribe = async () => {
    dispatch({
      type: "FETCH_STARTED",
      meta: {
        actions: subscription.actions.SUBSCRIPTION,
        body: JSON.stringify({
          id: currentRow.map((m) => m.inscricao_uuid),
        }),
        url: Config.baseURL + `api/${selectedLang}/subscription/unsubscribe`,
        method: "PUT",
      },
    })

    if (
      (setting?.data?.filter((s) => s.codigo === 404).filter((f) => f.ativo === "S").length > 0) &
      (currentRow[0].categoria_tipo_atividade === 1)
    ) {
      if (inscricoesApi.data.tabs.data[1].filter((f) => f.status_inscricao === 1).length > 0) {
        dispatch({
          type: "FETCH_STARTED",
          meta: {
            actions: subscription.actions.SUBSCRIPTION,
            body: JSON.stringify({
              id: inscricoesApi.data.tabs.data[1].filter((f) => f.status_inscricao === 1).map((m) => m.inscricao_uuid),
            }),
            url: Config.baseURL + `api/${selectedLang}/subscription/unsubscribe`,
            method: "PUT",
          },
        })

        if (!currentId) {
          setTabs((inscricoes) => {
            inscricoes.data[currentRow[0].categoria_tipo_atividade] = inscricoes.data[currentRow[0].categoria_tipo_atividade].filter(
              (i) => i.inscricao_uuid !== currentRow[0].inscricao_uuid
            )
            return inscricoes
          })
        } else {
          setTabs((inscricoes) => {
            Object.keys(inscricoes.data)
              .map(function (type) {
                return {
                  type,
                  inscricoes: inscricoes.data[type].filter((f) => !currentRow.map((m) => m.inscricao_uuid).includes(f.inscricao_uuid)),
                }
              })
              .forEach(function (key) {
                inscricoes.data[key.type] = key.inscricoes
              })
            return inscricoes
          })
        }

        setAllSubscriptions((inscricoes) => {
          const asArray = Object.entries(inscricoes)
          const atLeast9Wins = asArray.filter(
            ([key, value]) =>
              !inscricoesApi.data.tabs.data[1]
                .filter((f) => f.status_inscricao === 1)
                .map((m) => m.num_nosso_numero)
                .includes(key)
          )
          const atLeast9WinsObject = Object.fromEntries(atLeast9Wins)
          return atLeast9WinsObject
        })
      }
    } else {
      if (!currentId) {
        setTabs((inscricoes) => {
          inscricoes.data[currentRow[0].categoria_tipo_atividade] = inscricoes.data[currentRow[0].categoria_tipo_atividade].filter(
            (i) => i.inscricao_uuid !== currentRow[0].inscricao_uuid
          )
          return inscricoes
        })
      } else {
        setTabs((inscricoes) => {
          Object.keys(inscricoes.data)
            .map(function (type) {
              return {
                type,
                inscricoes: inscricoes.data[type].filter((f) => !currentRow.map((m) => m.inscricao_uuid).includes(f.inscricao_uuid)),
              }
            })
            .forEach(function (key) {
              inscricoes.data[key.type] = key.inscricoes
            })
          return inscricoes
        })
      }
      setAllSubscriptions((inscricoes) => {
        const asArray = Object.entries(inscricoes)
        const atLeast9Wins = asArray.filter(([key, value]) => key !== currentId)
        const atLeast9WinsObject = Object.fromEntries(atLeast9Wins)
        return atLeast9WinsObject
      })
    }

    handleClose()
  }

  const reprintBillet = async (row) => {
    if (row?.hash_transacao.indexOf("http") !== -1) {
      var my_form = document.createElement("FORM")
      my_form.name = "myForm"
      my_form.method = "GET"
      my_form.action = row?.hash_transacao
      my_form.target = "_blank"
      document.body.appendChild(my_form)
      my_form.submit()
      return
    }
    var my_form = document.createElement("FORM")
    my_form.name = "myForm"
    my_form.method = "POST"
    my_form.action = "https://shopline.itau.com.br/shopline/shopline.aspx"
    my_form.target = "_blank"

    var my_tb = document.createElement("INPUT")
    my_tb.type = "HIDDEN"
    my_tb.name = "dc"
    my_tb.value = row?.hash_transacao
    my_form.appendChild(my_tb)

    document.body.appendChild(my_form)
    my_form.submit()
  }

  const onChangePayment = (data) => {
    dispatch(payment.actions.setTotal(data.map((res) => parseFloat(res.valor)).reduce((acc, curr) => acc + curr)))
    setSubscriptionState(data)
    setOpenChangePayment(true)
  }

  const onInvalidCardPayment = () => {
    setShowWrongCard(true)
  }

  const checkPayment = async (subscription) => {
    await fetchPix(
      null,
      Config.baseURL +
      `api/${selectedLang}/finance/bank/low-pix?controle_layout_id=${subscription?.controle_layout_id}` +
      `&centro_custo_id=${subscription?.centro_custo_id}&conta_receber_id=${subscription?.conta_receber_id}` +
      `&tid=${subscription?.num_tid}&environment=production&ambiente=production&payment_type=5`
    )
  }

  useEffect(() => {
    if (errorPix?.message) {
      dispatch(
        toast.actions.enqueue({
          content: errorPix?.message,
          severity: "error",
        })
      )
    }
  }, [errorPix])

  return (
    <MyActivityView
      subscriptionState={subscriptionState}
      paymentRedux={payment}
      toast={toast}
      user={user}
      totalState={totalState}
      center_cost_id={center_cost_id}
      paymentType={paymentType}
      openConfirmUnsubscribe={openConfirmUnsubscribe}
      isLoadingInscricoes={isLoadingInscricoes}
      allSubscriptions={allSubscriptions}
      tabs={tabs}
      openChangePayment={openChangePayment}
      handleClose={handleClose}
      onClickUnsubscribe={onClickUnsubscribe}
      openModal={openModal}
      reprintBillet={reprintBillet}
      onChangePayment={onChangePayment}
      onInvalidCardPayment={onInvalidCardPayment}
      onChangeSelected={onChangeSelected}
      selected={selected}
      setSelected={setSelected}
      total={total}
      state={state}
      setState={setState}
      goFinish={goFinish}
      resetStatePayment={resetStatePayment}
      setting={setting}
      settingCommerce={settingCommerce}
      dispatch={dispatch}
      checkPayment={checkPayment}
      loadingPix={loadingPix}
    />
  )
}

export default MyActivityContainer
