import './style.scss'
import { Container, Typography, Grid, TextField, MenuItem, Breadcrumbs, Icon, Divider, Box, makeStyles } from '@material-ui/core'
import { deburr } from 'lodash'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Styles } from './styles'
import { useRef, useState, useEffect, useContext } from 'react'
import { ModalCategoryReceipt, RTag, Translator, formatDate, Loading } from '@itarget/shared'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import ListLayout from '../../../components/ListLayout'
import Layout from '../../../components/Layout'
import Model1 from '../../../components/CardOffers/Model1'
import Model2 from '../../../components/CardOffers/Model2'
import Model3 from '../../../components/CardOffers/Model3'
import Model4 from '../../../components/CardOffers/Model4'
import Model5 from '../../../components/CardOffers/Model5'
import Model7 from '../../../components/CardOffers/Model7'
import CardDetailStore from '../../../components/CardDetailStore'
import LinkComponentStore from '../../../components/LinkComponent/LinkComponentStore'
import ButtonArrowToTop from '../../../components/ButtonArrowToTop'
import SortButton from '../../../components/SortButton/SortButton'
import { SidebarContext } from '../../../context/SidebarContext'
import { MenuStoreContext } from '../../../context/MenuStoreContext'
import { ModalQuestions } from '../../../components/ModalQuestionsCourse'
import SearchTopBar from '../../../components/SearchTopBar'

const useStyles = makeStyles((theme) => ({
  modelsGridContainer: {
    '& .gridChipStatusStyle': {
      flexBasis: '0',
      justifyContent: 'flex-end',
    },
    '& > .MuiGrid-item': {
      [theme.breakpoints.up(theme.breakpoints.values.xs)]: {
        padding: theme.spacing(2, 0),
      },
      [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        padding: theme.spacing(2, 2),
      },
      [theme.breakpoints.up(theme.breakpoints.values.md)]: {
        padding: theme.spacing(2, 1),
      },
      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        padding: theme.spacing(1, 1),
      },
      [theme.breakpoints.up(theme.breakpoints.values.xl)]: {
        padding: theme.spacing(1, 1),
      },
    },
  },
  modelsListContainer: {
    '& .gridChipStatusStyle': {
      width: '100%',
      flexBasis: '100%',
      padding: '0',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(0.5),
      justifyContent: 'flex-start',
    },
    '& > .MuiGrid-item': {
      padding: theme.spacing(2, 0),
    },
  },
}))

const OffersView = ({
  offers,
  axis,
  formik,
  groupByOffers,
  activityTypes,
  filterHandler,
  handleChange,
  selectOffer,
  selectProduct,
  categorySelected,
  loading,
  handleInputChangeDebounced,
  category,
  openModalReceipt,
  setOpenModalReceipt,
  payment,
  toast,
  dataPersonCenterCostApi,
  idReceipt,
  centerCostId,
  onClickRegistered,
  settingCourse,
  selectedFilter,
  showQuiz,
  setShowQuiz,
}) => {
  const myRef = useRef({})
  const user = useSelector((state) => state.auth.user)
  const conditionModel = settingCourse?.tipo_layout
  const [gridView, setGridView] = useState(true)
  const styles = useStyles()

  const { open } = useContext(SidebarContext)
  const { hash } = window.location
  let elementFromHashSelected = hash && myRef?.current?.querySelector && myRef?.current?.querySelector(hash)

  useEffect(() => {
    if (elementFromHashSelected) {
      elementFromHashSelected.scrollIntoView({ behavior: 'smooth' })
    }
  }, [elementFromHashSelected])

  const { products } = useContext(MenuStoreContext)
  const filteredOffers = Object.values(products).flat()

  function OffersModal(props) {
    const { offers, conditionModel, selectOffer, onClickRegistered } = props

    if (conditionModel === 6) {
      if (filteredOffers.length == 0 && offers?.length >= 1) {
        return (
          <Grid
            container
            justify='center'>
            <Typography variant='h6'>
              <Translator transRef='translation.Sem itens correspondentes a sua pesquisa' />
            </Typography>
          </Grid>
        )
      }
      return filteredOffers.map((row, i) => {
        return (
          <Grid
            className={'offersModal-responsive'}
            key={i}
            xs={6}
            sm={6}
            md={4}
            lg={4}
            xl={2}
            item>
            <CardDetailStore
              direction='row'
              row={row}
              selectOffer={selectProduct}
            />
          </Grid>
        )
      })
    }

    if (!gridView) {
      return offers.map((row, i) => (
        <Grid
          key={i}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          item>
          <Model7
            card={row}
            onClick={selectOffer}
          />
        </Grid>
      ))
    } else {
      return offers.map((row, i) => (
        <Grid
          key={i}
          xs={12}
          sm={6}
          md={4}
          lg={3}
          className='grid-model-container'
          item>
          {conditionModel === 1 && (
            <Model1
              card={row}
              onClick={selectOffer}
            />
          )}
          {conditionModel === 2 && (
            <Model2
              card={row}
              onClick={selectOffer}
            />
          )}
          {conditionModel === 3 && (
            <Model3
              card={row}
              onClick={selectOffer}
            />
          )}
          {[4, 7].includes(conditionModel) && (
            <Model4
              card={row}
              onClick={selectOffer}
              onClickRegistered={onClickRegistered}
            />
          )}
          {conditionModel === 5 && (
            <Model5
              card={row}
              onClick={selectOffer}
            />
          )}
        </Grid>
      ))
    }
  }

  const GridLayoutHandler = () => {
    setGridView(true)
  }

  const ListLayoutHandler = () => {
    setGridView(false)
  }

  const LayoutReturn = gridView ? Layout : ListLayout

  return (
    <Styles isLogged={user?.data?.id ? true : false}>
      <LayoutReturn>
        {showQuiz && (
          <ModalQuestions
            setShowQuiz={() => {
              setShowQuiz()
            }}
            questionDestination={17}
            centerCostId={centerCostId}
          />
        )}
        {dataPersonCenterCostApi?.id && idReceipt?.data?.length === 0 && (
          <ModalCategoryReceipt
            centerCostId={centerCostId}
            status_comprovante_categoria={dataPersonCenterCostApi?.status_comprovante_categoria}
            open={openModalReceipt}
            setOpen={setOpenModalReceipt}
            toast={toast}
            payment={payment}
          />
        )}
        <Container
          maxWidth='xl'
          style={{ width: '100%' }}>
          {conditionModel !== 6 || gridView ? (
            ''
          ) : (
            <>
              <RTag show='desktop'>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <Link to={`/store/19`}>
                    <Icon
                      style={{
                        marginRight: '16px',
                        color: '#0086ff',
                      }}>
                      home
                    </Icon>
                  </Link>
                  <Breadcrumbs
                    separator={
                      <NavigateNextIcon
                        style={{ color: '#6d6d6d60' }}
                        fontSize='small'
                      />
                    }
                    aria-label='breadcrumb'
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <LinkComponentStore offers={offers} />
                  </Breadcrumbs>
                </div>
              </RTag>
              <RTag show='mobile'>
                <Container
                  maxWidth='xl'
                  spacing={2}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px 20px',
                  }}>
                  <Grid
                    item
                    xs={6}>
                    <Breadcrumbs
                      separator={
                        <NavigateNextIcon
                          style={{
                            color: '#6d6d6d60',
                          }}
                          fontSize='small'
                        />
                      }
                      aria-label='breadcrumb'
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <LinkComponentStore offers={offers} />
                    </Breadcrumbs>
                  </Grid>
                </Container>
              </RTag>
            </>
          )}
        </Container>
        {[4, 7].includes(conditionModel) && (
          <SearchTopBar
            formik={formik}
            filterHandler={filterHandler}
            activityTypes={activityTypes}
            axis={axis}
            handleChange={handleChange}
            handleInputChangeDebounced={handleInputChangeDebounced}
          />
        )}
        <Grid container>
          {loading ? (
            <Grid
              container
              justifyContent='center'>
              <Loading />
            </Grid>
          ) : (
            <Box
              width='100%'
              xs={12}>
              <Typography
                className='no-display'
                style={{
                  display: offers.length !== 0 && 'none',
                }}
                variant='h6'>
                {categorySelected && category?.data?.length > 1 ? (
                  <Translator transRef='translation.Selecione sua categoria para exibir os cursos' />
                ) : offers.length === 0 ? (
                  <Translator transRef='translation.Sem itens cadastrados' />
                ) : (
                  ''
                )}
              </Typography>

              <Container
                maxWidth='xl'
                spacing={4}
                className='gird-card-container'
                ref={myRef}>
                {conditionModel === 6 ? (
                  groupByOffers ? (
                    <OffersModal
                      conditionModel={conditionModel}
                      selectOffer={selectOffer}
                      onClickRegistered={onClickRegistered}
                    />
                  ) : (
                    <OffersModal
                      offers={offers}
                      conditionModel={conditionModel}
                      selectOffer={selectOffer}
                      onClickRegistered={onClickRegistered}
                    />
                  )
                ) : groupByOffers ? (
                  Object.entries(offers).map(([title, offer], i) => {
                    const id = deburr(title).replace(/\s/g, '-').toLowerCase()
                    return (
                      <Grid
                        spacing={2}
                        key={i}
                        xs={12}
                        container
                        item>
                        {conditionModel !== 6 && (
                          <Grid
                            xs={12}
                            spacing={1}
                            direction='column'
                            container
                            item
                            className='course-title'>
                            <Grid
                              item
                              container
                              justifyContent='space-between'
                              alignItems='center'>
                              <Typography id={id}>
                                {selectedFilter?.grouping_field === 'data_inicio_aga'
                                  ? formatDate.ptBr(title)
                                  : title.length > 60
                                  ? `${title.slice(0, 45)}...`
                                  : title}
                              </Typography>
                              {conditionModel === 7 && i < 1 ? (
                                <SortButton
                                  gridLayoutHandler={GridLayoutHandler}
                                  listLayoutHandler={ListLayoutHandler}
                                />
                              ) : null}
                            </Grid>
                            <Grid item>
                              <Divider />
                            </Grid>
                          </Grid>
                        )}
                        <Grid
                          item
                          xs={12}
                          className={gridView ? styles.modelsGridContainer : styles.modelsListContainer}
                          container>
                          <OffersModal
                            offers={offer}
                            conditionModel={conditionModel}
                            selectOffer={selectOffer}
                            onClickRegistered={onClickRegistered}
                          />
                        </Grid>
                      </Grid>
                    )
                  })
                ) : (
                  <OffersModal
                    offers={offers}
                    conditionModel={conditionModel}
                    selectOffer={selectOffer}
                    onClickRegistered={onClickRegistered}
                  />
                )}
              </Container>
            </Box>
          )}
        </Grid>
        <ButtonArrowToTop />
      </LayoutReturn>
    </Styles>
  )
}
export default OffersView
