import { Grid, CardMedia, Typography, Box, makeStyles } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import { dateFormatTranslation } from '@itarget/shared/src/utils/format/date'
import { useSelector } from 'react-redux'
import ActionsPlay from './ActionsPlay'
import ChipStatus from './ChipStatus'

import './style.scss'

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.typography.offersTitle,
  },
  eventIcon: {
    ...theme.typography.caption,
  },
}))

const Model4 = ({ card, onClick }) => {
  const styles = useStyles()
  const systemTheme = useSelector((state) => state.theme.systemTheme)
  const selectedLang = useSelector((state) => state.language.lang)
  return (
    <Grid className={`model4 model4-${systemTheme}`}>
      <CardMedia
        style={!onClick ? { cursor: 'default' } : {}}
        className='cardOffers-container'
        image={card?.imagem_lista || 'assets/images/no-image.png'}
        src='img'
        onClick={() => onClick && onClick(card)}></CardMedia>
      <Box
        paddingY={2}
        paddingX={1}
        paddingBottom={0.5}>
        <Grid
          container
          spacing={1}>
          <Grid item>
            <Typography className={styles.title}>{card && card.desc_atv}</Typography>
          </Grid>
          {card?.exibir_programacao_horario === 'S' && card?.data_fim_aga && (
            <Grid
              item
              container
              spacing={1}>
              <Grid
                xs={1}
                container
                alignItems='center'
                justifyContent='center'
                item>
                <Icon className={styles.eventIcon}>event</Icon>
              </Grid>
              <Grid
                xs={11}
                item>
                <Typography variant='caption'>
                  {dateFormatTranslation(selectedLang, card?.data_inicio_aga) !==
                    dateFormatTranslation(selectedLang, card?.data_fim_aga) &&
                    `${dateFormatTranslation(selectedLang, card?.data_inicio_aga)} - `}
                  {dateFormatTranslation(selectedLang, card?.data_fim_aga)}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
      <ChipStatus
        plp_id={card.plp_id}
        cortesia={card.cortesia}
        vagas_limite={card.vagas_limite}
        gerou_cobranca={card.gerou_cobranca}
        vagas_ocupadas={card.vagas_ocupadas}
        status_inscricao={card.status_inscricao}
        transmissao_gratuito={card.transmissao_gratuito}
        disponivel_transmissao={card.disponivel_transmissao}
      />
      <Grid className='cardOffers-infos-2'>
        <Grid
          item={true}
          xs={12}
          md={12}>
          <ActionsPlay card={card} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Model4
