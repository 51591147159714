export const convertPalestraJson = (obj, steps = []) => {
  if (!obj) {
    return null
  }

  let palestras = Object.entries(obj)

  let formated = palestras.map((palestra, i) => {
    let finalSteps =
      steps.length > 0 &&
      steps.filter(
        (st) => st.titulo_palestra === palestra[0] && st.link_video_palestrante !== null
      )

    let fields = Object.entries(palestra[1]).map((customAttribute) => {
      return {
        data_atividade: customAttribute[1][0].data_atividade,
        hora_inicio: customAttribute[1][0].hora_inicio,
        hora_fim: customAttribute[1][0].hora_fim,
        tipo_acesso_conteudo: customAttribute[1][0].tipo_acesso_conteudo,
      }
    })

    return {
      id: i,
      titulo: palestra[0].split('|')[0],
      data_atividade: fields[0].data_atividade,
      hora_inicio: fields[0].hora_inicio,
      hora_fim: fields[0].hora_fim,
      tipo_acesso_conteudo: fields[0].tipo_acesso_conteudo,
      custom: Object.entries(palestra[1]).map((customAttribute) => {
        return {
          funcao: customAttribute[0],
          nome_palestrante: customAttribute[1]
            .map((x) => x.nome_palestrante)
            .reduce((a, b) => a + ', ' + b),
        }
      }),
      steps: finalSteps,
      liberado: false,
    }
  })

  return formated
}
