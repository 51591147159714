import { Grid, Icon } from "@material-ui/core";
import "./style.scss";

const QuestionnaireNote = (props) => {
    const { exibir_certificado, resposta_correta } = props;
    const colorGrid = exibir_certificado ? "green" : "red";

    return (
        <>
            <Grid className={`grid-course ${colorGrid}`}>
                <Icon>confirmation_number</Icon> {`NOTA: ${resposta_correta}`}
            </Grid>
        </>
    )
}

export default QuestionnaireNote