import { Box, Checkbox, Typography } from '@material-ui/core'
import Amount from './Amount'
import { useComboAnnuityStyles } from './styles'
import { useComboAnnuityTranslation } from './translation'

const ComboAnnuity = ({ onChange = (checked) => {}, annuities = [] }) => {
  const styles = useComboAnnuityStyles()
  const translation = useComboAnnuityTranslation()

  const handleChange = (event) => {
    let checked = event.target.checked
    onChange(checked)
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.checkboxContainer}>
        <Checkbox
          disableRipple
          onChange={handleChange}
          className={styles.checkbox}
        />
        <Box className={styles.checkboxLabelContainer}>
          <Typography className={styles.checkboxLabel}>{translation.discount}</Typography>
        </Box>
      </Box>
      <Typography className={styles.title}>{translation.discountTitle}</Typography>
      {annuities.map(({ agenda_atividades_descricao: title, conta_receber_id: receiveCountId, inscricao_id: subscribeId, conta_receber_valor: amount }) => {
        let numberedAmount = parseFloat(amount).toFixed(2)
        return (
          <Box key={`annuity-crid_#${receiveCountId}-iid_#${subscribeId}`} className={styles.annuityDetail}>
            <Typography className={styles.annuityTitle}>{title}</Typography>
            <Amount amount={numberedAmount} />
          </Box>
        )
      })}
    </Box>
  )
}

export default ComboAnnuity
