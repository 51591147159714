import { useState, useEffect } from "react";
import { Button, Popover, Icon, Typography } from "@material-ui/core";
import { Translator } from "@itarget/shared";
import { defaultStyle } from "./styles";
import LoginForm from "../../../LoginForm";

const MenuButtonDinamic = ({ goTo, pathInternal, pathExternal, showForm = false, login = false, icon, topText, bottomText }) => {
  const [open, setOpen] = useState(showForm);
  const [anchorEl, setAnchorEl] = useState(null);
  const style = defaultStyle();
  const onClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setOpen(!!anchorEl);
  }, [anchorEl]);

  return (
    <>
      <Button variant="contained" color="secondary" className={style?.button} onClick={(e) => (login ? onClick(e) : pathInternal ? goTo(pathInternal) : window.open(pathExternal))}>
        <Icon className={style?.icon}>{icon}</Icon>
        <Typography className={style?.text}>
          <Translator transRef={`translation.${topText}`} />
          <span className={style?.spanText}>
            <Translator transRef={`translation.${bottomText}`} />
          </span>
        </Typography>
      </Button>
      {login && (
        <Popover
          id="login-button"
          open={open}
          anchorEl={anchorEl}
          keepMounted
          onClose={onClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          classes={{
            paper: style?.popover,
          }}
        >
          <LoginForm />
        </Popover>
      )}
    </>
  );
};

export default MenuButtonDinamic;
