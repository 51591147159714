import React from 'react';
import { List, Typography, makeStyles } from "@material-ui/core";
import clsx from "clsx";

import MenuItem from "./MenuItem";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    display: "inline-flex",
    backgroundColor: (props) =>
      (props.level === 0 && "#2a7499") || "transparent",
    justifyContent: "center",
  },
  loadingText: {
    color: "#fff",
  },
}));

const Menu = ({
  items = [],
  level = 0,
  isLoadingMenuData = false,
  classes = {},
}) => {
  const styles = useStyles({ level });
  return (
    <List className={clsx(styles.root, classes.root)}>
      {(isLoadingMenuData && (
        <Typography className={styles.loadingText}>Loading</Typography>
      )) ||
        items.map((item) => (
          <MenuItem
            key={item.ID}
            {...item}
            level={level}
            classes={{ itemButtonRoot: classes.itemButtonRoot }}
          />
        ))}
    </List>
  );
};
export default React.memo(Menu);
