import './style.scss'
import { Grid, Container, Breadcrumbs, Typography, Box } from '@material-ui/core'
import { Translator } from '@itarget/shared'
import CardDetailCourse from '../../../components/CardDetailCourse'
import Layout from '../../../components/Layout'
import ModalVideoCourse from '../../../components/ModalVideoCourse'
import ModalQuestionsCourse from '../../../components/ModalQuestionsCourse'
import ProgramingOnDemand from '../../../components/ProgramingOnDemand'
import TotalAssisted from '../../../components/ButtonsOnDemand/TotalAssisted'
import AboutCourse from '../../../components/ButtonsOnDemand/AboutCourse'
import Quiz from '../../../components/ButtonsOnDemand/Quiz'
import QuestionnaireNote from '../../../components/ButtonsOnDemand/QuestionnaireNote'
import ViewCertificate from '../../../components/ButtonsOnDemand/ViewCertificate'
import ComplementaryFile from '../../../components/ButtonsOnDemand/ComplementaryFile'
import { Alert } from '@material-ui/lab'

const DetailMyCourseGrouped = ({
  id,
  isLoadingDetail,
  selectStep,
  showVideo,
  showQuiz,
  closeShowVideo,
  onPause,
  onPlay,
  onProgress,
  onEnded,
  refPlayer,
  detail,
  lesson,
  speakers,
  questions,
  closeShowQuiz,
  setOpenQuestionnaire,
  setOpenCertificate,
  percentNextVideo,
  secondsLeft,
  secondsLeftQuiz,
  limitTimeQuiz,
}) => {
  const translation = {
    OnDemand: Translator({
      transRef: 'OnDemand',
    }),
    timeWarning: Translator({
      transRef:
        'translation.Atenção: a resolução dos questionários e a visualização das videoaulas têm tempo limitado. Tenha certeza de que, uma vez iniciada a visualização das aulas, as mesmas sejam concluídas dentro do prazo estipulado.',
    }),
  }

  return (
    <Layout isLoading={isLoadingDetail}>
      {showVideo && (
        <ModalVideoCourse
          refPlayer={refPlayer}
          onProgress={onProgress}
          onPause={onPause}
          lesson={lesson}
          speakers={speakers}
          closeShowVideo={closeShowVideo}
          onPlay={onPlay}
          onEnded={onEnded}
          secondsLeft={secondsLeft}
        />
      )}
      {showQuiz && !secondsLeftQuiz && (
        <ModalQuestionsCourse
          questions={questions}
          secondsLeftQuiz={secondsLeftQuiz}
          limitTimeQuiz={limitTimeQuiz}
          closeShow={closeShowQuiz}
        />
      )}
      {showQuiz && secondsLeftQuiz && (
        <ModalQuestionsCourse
          questions={questions}
          secondsLeftQuiz={secondsLeftQuiz}
          limitTimeQuiz={limitTimeQuiz}
          closeShow={closeShowQuiz}
        />
      )}
      <Grid className='detailOffer-background'>
        <Container>
          <Grid className='detailOffer-cardOffers'>
            <CardDetailCourse detail={detail} />
          </Grid>
        </Container>
      </Grid>

      <Grid container>
        {detail ? (
          <Breadcrumbs
            style={{ marginTop: 15, padding: 24 }}
            separator='›'>
            {[
              <div
                onClick={() => window.history.back()}
                style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                <Translator transRef='translation.Ofertas' />
              </div>,
              <div style={{ fontSize: 12 }}>{translation.OnDemand}</div>,
            ]}
          </Breadcrumbs>
        ) : null}
        {detail?.data?.controle_tempo && (
          <Grid>
            <Alert severity='error'>
              <div
                dangerouslySetInnerHTML={{
                  __html: translation.timeWarning.props.children,
                }}></div>
            </Alert>
          </Grid>
        )}
        {!detail && !isLoadingDetail ? (
          <Grid
            container
            alignItems='center'
            justifyContent='center'>
            <Typography>
              <Translator transRef={`translation.Sem permissão`} />
            </Typography>
          </Grid>
        ) : (
          <Grid
            container
            className='grid-containerDetail'
            spacing={2}
            xs={12}>
            <Grid
              className='grid-datas'
              item
              sm={12}
              md={9}>
              <ProgramingOnDemand
                programingGrouped={detail}
                value={0}
                selectStep={selectStep}
                steps={detail && detail.data ? detail.data.list : []}
                percentNextVideo={percentNextVideo}
              />
            </Grid>
            <Grid
              className='grid-datas'
              item
              sm={12}
              md={3}>
              <TotalAssisted total_assistido={detail && detail.data ? detail.data.total_assistido : 0} />
              <Box marginTop={2}>
                <Grid
                  container
                  spacing={2}>
                  <AboutCourse agenda_atividade_id={id} />
                  {detail?.data?.arquivo && detail?.data?.arquivo.length > 0 ? (
                    <ComplementaryFile files={detail?.data?.arquivo} />
                  ) : (
                    <></>
                  )}
                  {detail?.data?.perguntas?.exibir_questionario ? <Quiz setOpenQuestionnaire={setOpenQuestionnaire} /> : <></>}
                  {detail?.data?.resposta_correta > 0 ? (
                    <QuestionnaireNote
                      exibir_certificado={detail?.data?.exibir_certificado}
                      resposta_correta={detail.data.resposta_correta}
                    />
                  ) : (
                    <></>
                  )}
                  {detail?.data?.exibir_certificado ? (
                    <ViewCertificate
                      setOpenCertificate={setOpenCertificate}
                      url_certificado={detail?.data?.url_certificado}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Layout>
  )
}

export default DetailMyCourseGrouped
