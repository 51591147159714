import { useLectureStyles } from './styles'
import { Box } from '@material-ui/core'
import Scrollable from '../Scrollable'
import { light } from '@material-ui/core/styles/createPalette'

const Lecture = ({ children, display = false }) => {
  const styles = useLectureStyles({ display })
  const LECTURE_WIDTH = 271

  return (
    <>
      <Scrollable
        backgroundColor={{ light: '#F9F9FF', dark: '#232323' }}
        itemWidth={LECTURE_WIDTH}
        className={styles.container}>
        {children}
      </Scrollable>
      <Box className={styles.containerMobile}>{children}</Box>
    </>
  )
}

export default Lecture
