import { Grid, Icon, Typography, Divider } from "@material-ui/core";
import { Translator, Button } from "@itarget/shared";

import "./style.scss";

const CardPanel = ({ children, btns = [], title }) => {
  return (
    <Grid container spacing={3} alignItems='center' className="cardPanel-container">
      <Grid item xs={12}>
        <Typography >
          {title}
        </Typography>
        <Divider />
      </Grid>
      <Grid container spacing={1}>
        {btns.map((row, i) => (
          <Grid item key={i}>
            <Button
              variant='contained'
              color='primary'
              onClick={() => row.onClick()}
              icon={row.icon}
            >
              <Translator transRef={"translation." + row.label} />
            </Button>
          </Grid>
        ))}
      </Grid>
      {children}
    </Grid>
  );
};

export default CardPanel;
