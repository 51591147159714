import { Box } from '@material-ui/core'
import { useRootStyles } from './styles'
import { useQueryParams } from '@itarget/shared'

const Root = ({ children = <></>, direction = 'column', spacing = 1, onChange }) => {
  const styles = useRootStyles({ spacing, direction })
  const { get, set } = useQueryParams()
  let selectedDate = get('date')

  const handleChangeDate = (date) => {
    set({ param: 'date', value: date })
  }

  const onClick = (date) => {
    handleChangeDate(date)
    onChange && onChange(date)
  }

  children = children.map((row) => ({ ...row, props: { ...row.props, onClick } }))

  if (!selectedDate) handleChangeDate(children[0].props.date)

  return <Box className={styles.container}>{children}</Box>
}

export default Root
