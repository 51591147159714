import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { auth, toast } from '../../redux'
import LoginView from './views/LoginView'
import { useRedirect, configurator, useFetchApi, Config } from '@itarget/shared'

const LoginContainer = () => {
  const { cc } = useParams()
  const { goTo } = useRedirect()
  const [value, setValue] = useState(0)
  const [setting, setSetting] = useState([])
  const isAuthenticated = useSelector((state) => state.auth.isAutenticated)
  const selectedLang = useSelector((state) => state.language.lang)
  const user = useSelector((state) => state.auth.user)
  const center_cost_id = useSelector((state) => state.auth.cc)
  const settingCommerce = useSelector((state) => state.setting.settingCommerce)
  const [newSettingCommerce, setNewSettingCommerce] = useState({})
  let selectedCenterCostId = cc || center_cost_id

  let { current: search } = useRef(new URLSearchParams(document.location.search))
  let state = useMemo(() => search.get('state'), [search])

  const { data: reRegister, fetchData: fetchReRegister } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/person/re/register?pessoa_id=${user?.data?.id}&centro_custo_id=${selectedCenterCostId}&aplicacoes=4`
  )

  const {
    data: settingRequest,
    fetchData: fetchSetting,
    isLoading: isLoadingSettingLogin,
  } = useFetchApi(Config.baseURL + `api/${selectedLang}/setting?centro_custo_id=${selectedCenterCostId}&${configurator.LOGIN}`)

  const {
    data: settingCourse,
    fetchData: fetchSettingCourse,
    isLoading,
  } = useFetchApi(Config.baseURL + `api/${selectedLang}/setting-course-commerce/setting?centro_custo_id=${selectedCenterCostId}`)

  const redirectUser = () => {
    if (!!reRegister?.data?.[0]) {
      goTo(`me`)
      return
    }
    goTo(`offers-unique/${selectedCenterCostId}`)
  }

  let configurationToEnableRegister =
    setting?.data && setting.data.filter((res) => res.codigo === 316).length > 0 && setting.data.filter((res) => res.codigo === 316)[0]
  let bottomRegister =
  ['N', undefined, false].includes(configurationToEnableRegister?.ativo) ? undefined : configurationToEnableRegister?.valor_referencia

  useEffect(() => {
    !!reRegister && redirectUser()
  }, [reRegister])

  useEffect(() => {
    if (isAuthenticated) {
      fetchReRegister()
    }
  }, [isAuthenticated])

  useEffect(() => {
    fetchSetting()
  }, [fetchSetting])

  useEffect(() => {
    if (settingRequest) {
      setSetting(settingRequest)
    }
  }, [settingRequest])

  const handleChange = (_, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    fetchSettingCourse()
  }, [cc])

  useEffect(() => {
    setNewSettingCommerce(settingCourse?.data || settingCommerce)

    if (settingCourse?.data?.texto_aba_navegador) {
      document.title = settingCourse?.data?.texto_aba_navegador
    }

    if (settingCourse?.data?.icone_aba_navegador) {
      document.getElementById('favicon').href = settingCourse?.data?.icone_aba_navegador
    }
  }, [settingCourse, settingCommerce])

  useEffect(() => {
    if (state == 'register') handleChange(null, 1)
    if (!['S', undefined, false].includes(bottomRegister)) handleChange(null, 0)
  }, [bottomRegister, state])

  return (
    <LoginView
      settingCommerce={newSettingCommerce}
      isLoadingService={isLoading}
      isLoadingSettingLogin={isLoadingSettingLogin}
      center_cost_id={selectedCenterCostId}
      isAutenticated={isAuthenticated}
      setting={setting}
      bottomRegister={bottomRegister}
      value={value}
      auth={auth}
      toast={toast}
      handleChange={handleChange}
    />
  )
}

export default LoginContainer
