import React, { useCallback, useEffect } from 'react'
import './style.scss'
import { Grid, Typography, Icon, Box } from '@material-ui/core'
import { useSelector } from 'react-redux'

const Toast = ({ id, content, duration = 6000, severity = 'info', onClose }) => {
  const systemTheme = useSelector((state) => state.theme.systemTheme)
  const closeToastAutomatic = useCallback(
    (duration) => {
      return setTimeout(() => onClose(id), duration)
    },
    [id, onClose]
  )

  useEffect(() => {
    const timeout = closeToastAutomatic(duration)
    return () => clearTimeout(timeout)
  }, [closeToastAutomatic, duration, id, onClose])

  const nameIcon = () => {
    if (severity === 'success') {
      return 'check'
    } else {
      return severity
    }
  }

  return (
    <Grid className={`toast toast-${systemTheme} ${severity}`}>
      <Box className='icon-box'>
        <Icon className='icon'>{nameIcon()}</Icon>
      </Box>
      <Typography className='text'>{content}</Typography>
      <Icon
        className='icon-close'
        onClick={() => {
          onClose(id)
        }}>
        cancel
      </Icon>
    </Grid>
  )
}

export default Toast
