import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "34px 10%",
    height: "270px",
  },
  text: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "50%",
    height: "270px",
    paddingLeft: "40px",
    backgroundColor: "#074C81",
    color: "#fff",
    borderRadius: "15px 0 0 15px",
  },
  img: {
    width: "100%",
    height: "270px",
    borderRadius: "20px",
    backgroundSize: "cover",
  },
  promotion: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderTop: "1px solid #0086ff",
    borderBottom: "1px solid #0086ff",
    padding: "30px 5px",
  },
  info: {
    textAlign: "center",
    color: "#00486f",
    fontWeight: "bold",
    flex: "0 0 auto",
  },
  carousel: {
    flex: "1 0 auto",
  },
  offers: {
    marginTop: "40px",
    display: "flex",
  },
  title: {
    width: "100%",
    paddingLeft: "10px",
    color: "#0086ff",
    fontWeight: "bold",
  },
  ["@media screen and (max-width: 800px)"]: {
    container: {
      padding: "0",
      flexDirection: "column-reverse",
    },
    text: {
      width: "100%",
      padding: "0.3rem 0 0.3rem 10%",
      borderRadius: "0",
      maxHeight: "30%",
    },
    textTitle: {
      fontSize: "2rem",
    },
    textSubtitle: {
      fontSize: "0.875rem",
    },
    textInfo: {
      fontSize: "0.635rem",
    },
    img: {
      width: "100%",
      backgroundSize: "cover",
    },
    promotion: {
      flexDirection: "column",
    },
    carousel: {
      maxWidth: "90vw",
    },
    offers: {
      justifyContent: "center",
    },
  },
});
