import { Icon, IconButton, makeStyles } from '@material-ui/core'
import { RTag, Translator } from '@itarget/shared'

import MenuC from '../../MenuC'
import CartButton from '../../CartButton'
import UserMenu from '../../UserMenu'
import MenuButtonDinamic from './MenuButtonDinamic'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    height: '128px',
  },
  iconcart: {
    width: '32px',
    height: '32px',
  },
  container: {
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'space-between',
    height: '74px',
    padding: '5px 10px 7px 10px',
  },
  logo: {
    display: 'flex',
    maxHeight: '80px',
    '@media (max-width:800px)': {
      maxHeight: '40px',
    },
    '@media (max-width: 500px)': {
      maxHeight: '20px',
    }
  },
  trailing: {
    flex: '1 0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '9px',

    '& .shoppingCartIcon': {
      color: '#D3D3D3',
    },
  },
  leading: {
    flex: '0 0 auto',
    width: '9.991%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '@media (max-width: 950px)': {
      width: 'auto',
    },
  },
  menu: {
    height: '30px',
  },
  menuItem: {
    textTransform: 'uppercase',
  },
  menuButton: {
    '@media (min-width: 950px)': {
      display: 'none',
    },
  },
}))

const NotLogedButtons = ({ goTo, styles, toggleMenu, showMenu }) => {
  return (
    <>
      <RTag show='desktop'>
        <MenuButtonDinamic
          goTo={goTo}
          pathExternal='https://otorrinoevoce.com.br/'
          icon='groups'
          topText='Sou'
          bottomText='Paciente'
        />
        <MenuButtonDinamic
          goTo={goTo}
          pathExternal='http://aborlccf.org.br/localize-seu-medico/'
          icon='masks'
          topText='Encontre um'
          bottomText='Otorrino'
        />
      </RTag>
      <MenuButtonDinamic
        goTo={goTo}
        login
        icon='login'
        topText='Login de'
        bottomText='Associado'
      />
      <div>
        <IconButton
          onClick={toggleMenu}
          className={`view-list-red ${styles.menuButton}`}>
          {showMenu === 'hide' ? <Icon>view_list</Icon> : <Icon>close</Icon>}
        </IconButton>
      </div>
    </>
  )
}

const LogedButtons = ({ goTo, shoppingCarts, toggleMenu, styles, user, onLogout, statusAssociated, category, showMenu }) => {
  return (
    <>
      <RTag show='desktop'>
        <MenuButtonDinamic
          goTo={goTo}
          pathExternal='https://otorrinoevoce.com.br/'
          icon='groups'
          topText='Encontre um'
          bottomText='Paciente'
        />
        <MenuButtonDinamic
          goTo={goTo}
          pathExternal='http://aborlccf.org.br/localize-seu-medico/'
          icon='masks'
          topText='Encontre um'
          bottomText='Otorrino'
        />
      </RTag>
      <>
        <CartButton
          goTo={goTo}
          shoppingCarts={shoppingCarts}
        />
        <div>
          <IconButton
            onClick={toggleMenu}
            className={`view-list-red ${styles.menuButton}`}>
            {showMenu === 'hide' ? <Icon>view_list</Icon> : <Icon>close</Icon>}
          </IconButton>
        </div>

        <UserMenu
          user={user}
          onLogout={onLogout}
          goTo={goTo}
          statusAssociated={statusAssociated}
          category={category}
        />
      </>
    </>
  )
}

const Header = ({
  showMenu,
  toggleMenu,
  logo,
  backgroundHeader,
  client,
  menuItemsC = [],
  shoppingCarts,
  isLoadingMenuData,
  user,
  goTo,
  onLogout,
  statusAssociated,
  category,
}) => {
  const styles = useStyles()

  return (
    <header className={styles.root}>
      <div
        className={styles.container}
        style={{ backgroundImage: `url(${backgroundHeader})` }}>
        <div className={styles.leading}>
          <img
            className={styles.logo}
            alt={client}
            src={logo}
          />
        </div>
        <div className={styles.trailing}>
          {user?.data ? (
            <LogedButtons
              goTo={goTo}
              shoppingCarts={shoppingCarts}
              toggleMenu={toggleMenu}
              styles={styles}
              user={user}
              onLogout={onLogout}
              statusAssociated={statusAssociated}
              category={category}
              showMenu={showMenu}
            />
          ) : (
            <NotLogedButtons
              goTo={goTo}
              toggleMenu={toggleMenu}
              styles={styles}
              showMenu={showMenu}
            />
          )}
        </div>
      </div>

      <MenuC
        items={menuItemsC}
        isLoadingMenuData={isLoadingMenuData}
        classes={{ root: styles.menu, itemRoot: styles.menuItemRoot }}
        showMenu={showMenu}
      />
    </header>
  )
}

export default Header
