import React from 'react'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import RoomIcon from '@material-ui/icons/Room'
import Typography from '@material-ui/core/Typography'
import { useParams } from 'react-router-dom'
import { Avatar, Grid, IconButton, Icon, Tooltip } from '@material-ui/core'
import { format, hour } from '@itarget/shared/src/utils/format/date'
import { useRedirect } from '@itarget/shared'
import { Translator } from '@itarget/shared'
import './style.scss'

const SchedulingTimeline = ({ scheduling, user, room, participant, scheduleType, selectStep, date, subscriptions }) => {
  const { goTo } = useRedirect()
  const { activity_schedule_id } = useParams()
  let mainActivityID = Number(activity_schedule_id)
  const translation = {
    subscribedTooltip: Translator({ transRef: 'translation.Inscrito' }),
    pendingSubscribe: Translator({ transRef: 'translation.Pendente' }),
    onDemand: Translator({ transRef: 'translation.on Demand' }),
    buyActivity: Translator({ transRef: 'translation.Inscreva-se!' }),
  }
  return (
    <Grid
      container
      direction='column'>
      {scheduling.map((activities) => {
        const formatedDateTab = activities.data_hora_inicio.split(' ')[0]
        if (room.uuid !== activities.sala || date !== formatedDateTab) return
        const activityType = scheduleType[activities.tipo_atividade]
        const subscription = subscriptions.find((sub) => sub.agenda_atividade_id === activities.agenda_atividade_id)?.status_inscricao
        const isMainActivity = scheduling.some((activity) => activity.agenda_atividade_id === mainActivityID)
        const mainSubscription = subscriptions.find((sub) => sub.agenda_atividade_id === mainActivityID)?.status_inscricao
        const rules = {
          onlyProgram: activities.gera_inscricao_trabalho == 2,
          hasMainSubscription: mainSubscription == 2,
          isSubscribed: subscription == 2,
          isPending: subscription == 1,
        }
        return (
          <Timeline
            key={activities.uuid}
            className='timeline'
            align='left'>
            <Grid
              spacing={1}
              container>
              <Grid item>
                <RoomIcon />
              </Grid>
              <Grid item>
                <Typography>{room.nome}</Typography>
              </Grid>
            </Grid>
            <TimelineItem className='timeline-item'>
              <TimelineOppositeContent className='timeline-opposite'>
                <Typography
                  className='schedule-date-timeline'
                  align='left'
                  variant='h6'>
                  {`${format(activities.data_hora_inicio, 'HH:mm')} - ${format(activities.data_hora_fim, 'HH:mm')}`}
                </Typography>
                <Typography align='left'>
                  {activityType.nome.length > 10 ? `${activityType.nome.slice(0, 10)}...` : activityType.nome}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant='body1'>{activities.titulo}</Typography>
                <Grid
                  className='lecturer-container'
                  alignItems='center'>
                  {activities.sub_tema.map((subtopic) => {
                    return subtopic.participante.map((lecturer) => {
                      const hasVideo = subtopic.exibe_video_palestra !== 'N'
                      const lecturerInfo = participant[lecturer.participante]
                      return (
                        <>
                          <Grid
                            className='lecturer-details'
                            container>
                            <Grid className='hour-detail'>
                              <Typography>{`${hour(subtopic.data_hora_inicio)} - ${hour(subtopic.data_hora_fim)}`}</Typography>
                            </Grid>
                            <Grid>
                              <Grid container>
                                {subtopic.titulo && (
                                  <Grid
                                    container
                                    className='schedule-subtheme'>
                                    <Typography>
                                      {subtopic && subtopic.titulo.length > 55
                                        ? `${subtopic.titulo.slice(0, 55)}...`
                                        : subtopic.titulo}
                                    </Typography>
                                  </Grid>
                                )}
                                <Avatar
                                  className='lecturer-avatar'
                                  alt={lecturerInfo?.nome_cracha}
                                  src={lecturerInfo?.foto}
                                />
                                <Grid>
                                  <Typography className='lecturer'>
                                    {lecturerInfo?.nome_cracha !== null ? lecturerInfo?.nome_cracha : lecturerInfo?.nome}
                                  </Typography>
                                  <Typography className='lecturer-description'>{lecturer.funcao_descricao}</Typography>
                                </Grid>
                                <Grid>
                                  <Typography className='lecturer-state'>
                                    {lecturerInfo?.estado && `- (${lecturerInfo?.estado})`}
                                  </Typography>
                                </Grid>
                                {user.data ? (
                                  <Grid>
                                    {activities && (
                                      <>
                                        {(rules.isSubscribed || (rules.onlyProgram && rules.hasMainSubscription)) && (
                                          <>
                                            {hasVideo && (
                                              <Tooltip title={translation.subscribedTooltip}>
                                                <IconButton
                                                  edge='end'
                                                  size='large'
                                                  className='btn-play'>
                                                  <Icon
                                                    className='play-icon'
                                                    fontSize='large'>
                                                    verified
                                                  </Icon>
                                                </IconButton>
                                              </Tooltip>
                                            )}

                                            {hasVideo && (
                                              <Tooltip title={translation.onDemand}>
                                                <IconButton
                                                  onClick={() => selectStep(subtopic, lecturerInfo.nome)}
                                                  edge='end'
                                                  size='large'
                                                  className='btn-play'>
                                                  <Icon
                                                    className='play-icon'
                                                    fontSize='large'>
                                                    ondemand_video
                                                  </Icon>
                                                </IconButton>
                                              </Tooltip>
                                            )}
                                          </>
                                        )}
                                        {rules.isPending && (
                                          <Tooltip title={translation.pendingSubscribe}>
                                            <IconButton
                                              onClick={() => goTo(`shopping-cart`)}
                                              edge='end'
                                              size='large'
                                              className='btn-play'>
                                              <Icon
                                                className='play-icon'
                                                fontSize='large'>
                                                pending
                                              </Icon>
                                            </IconButton>
                                          </Tooltip>
                                        )}
                                        {!rules.onlyProgram && !rules.isSubscribed && hasVideo && !isMainActivity && (
                                          <Tooltip title={translation.buyActivity}>
                                            <IconButton
                                              onClick={() => goTo(`offers/${activities.agenda_atividade_id}`)}
                                              edge='end'
                                              size='large'
                                              className='btn-play'>
                                              <Icon
                                                className='play-icon'
                                                fontSize='large'>
                                                add_shopping_cart
                                              </Icon>
                                            </IconButton>
                                          </Tooltip>
                                        )}
                                      </>
                                    )}
                                  </Grid>
                                ) : null}
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      )
                    })
                  })}
                </Grid>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        )
      })}
    </Grid>
  )
}

export default SchedulingTimeline
