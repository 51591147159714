import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100vw ',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(0, 2.5),
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  videoContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    flexDirection: 'column',
    maxWidth: theme.typography.pxToRem(640),
    '& iframe': {
      width: '100%',
      height: '100%',
    },
    '@media (max-width: 800px)': {
      '& > div:has(div[data-vimeo-initialized="true"])': {
        width: '80vw !important',
        height: '45vw !important',
      },
    },
    '@media (orientation: landscape)': {
      '& > div:has(div[data-vimeo-initialized="true"])': {
        width: '80vh !important',
        height: '45vh !important',
      },
    },
  },
  closeButton: {
    height: 'min-content',
  },
  title: {
    ...theme.typography.h6,
  },
  speakers: {
    ...theme.typography.caption,
  },
}))
