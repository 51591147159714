import { useEffect } from 'react'
import Layout from '../../components/Layout/Layout'
import { useParams } from 'react-router'
import { useFetchApi, Config, useRedirect } from '@itarget/shared'
import { useSelector } from 'react-redux'
import Loading from '../../components/Loading'

const ScientificWorkContainer = () => {
  const { cc: centerCostId } = useParams()
  const { goTo } = useRedirect()
  const isAuthenticated = useSelector((state) => state.auth.isAutenticated)
  const isAuthenticating = useSelector((state) => state.auth.isAutenticating)
  const user = useSelector((state) => state.auth.user)
  const selectedLang = useSelector((state) => state.language.lang)
  let userId = user?.data?.id

  const {
    data: dataPerson,
    fetchData: fetchPerson,
    isLoading: isLoadingPerson,
  } = useFetchApi(Config.baseURL + `api/${selectedLang}/person/${userId}`)

  function handleRedirectUserToStation() {
    let hash = dataPerson?.hash_link_senha
    if (hash) {
      return window.location.replace(
        `${Config.urlIcongresso}estacao/index/autenticar-hash/lang/pt-br/evento/${centerCostId}/layout/N/hash/${hash}/link/L2VzdGFjYW8vdHJhYmFsaG9z`
      )
    }

    if (isAuthenticated || isAuthenticating) return

    goTo(`login/${centerCostId}?goToCallback=scientific-work/${centerCostId}`)
  }

  useEffect(() => {
    fetchPerson()
  }, [fetchPerson])

  useEffect(() => {
    handleRedirectUserToStation()
  }, [handleRedirectUserToStation])

  return (
    <Layout isLoading={isLoadingPerson}>
      <Loading />
    </Layout>
  )
}

export default ScientificWorkContainer
