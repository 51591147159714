import { makeStyles } from '@material-ui/styles'

export const useProgramStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5),
  },
  programmingContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2.5),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      flexDirection: 'column',
    },
  },
  lectureContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyContainer: {
    display: 'flex',
  },
  emptyText: {
    ...theme.typography.caption,
  },
}))
