import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFetchApi, Config, subscriptionRequest, useRedirect } from '@itarget/shared'
import { offers, toast, payment as paymentRedux, subscription as subscriptionRedux } from '../../redux'
import { payment, auth } from '../../redux'
import DetailOfferView from './views/DetailOfferView'

const DetailOfferContainer = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { goTo } = useRedirect()
  const [value, setValue] = useState(0)
  const [tabs, setTabs] = useState([])
  const [openLogin, setOpenLogin] = useState(false)
  const [state, setState] = useState('detail')
  const [offer, setOffer] = useState()
  const [showWrongCard, setShowWrongCard] = useState(false)
  const [openModalReceipt, setOpenModalReceipt] = useState(false)
  const [dataPersonCenterCostApi, setDataPersonCenterCostApi] = useState({})
  const [detailErrorMessage, setDetailtErrorMessage] = useState()
  const [necessaryErrorMessage, setNecessaryErrorMessage] = useState()
  const [errorSubscriptionData, setErrorSubscriptionData] = useState()
  const [selectedCourse, setSelectedCourse] = useState()
  const [showQuiz, setShowQuiz] = useState()
  const [purchaseQuantity, setPurchaseQuantity] = useState(1)

  const user = useSelector((state) => state.auth.user)
  const center_cost_id = useSelector((state) => state.auth.cc)
  const centerCost = useSelector((state) => state.setting.centerCost)
  const isAutenticated = useSelector((state) => state.auth.isAutenticated)
  const selectedLang = useSelector((state) => state.language.lang)
  const shoppingCarts = useSelector((state) => state.offers.shoppingCarts)
  const isAuthenticated = useSelector((state) => state.auth.isAutenticated)
  const [totalAnnuity, setTotalAnnuity] = useState(0)
  const [total, setTotal] = useState(0)

  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingSubscribe, setIsLoadingSubscribe] = useState(false)

  const {
    data: dataPreRegistration,
    error: errorPreRegistration,
    fetchData: fetchPreRegistration,
  } = useFetchApi(Config.baseURL + `api/${selectedLang}/pre-registration`, 'POST')

  const { data: annuities = { data: [] }, fetchData: fetchAnnuities } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/subscription/${user?.data?.id}/unified-registration`
  )

  const {
    data: detailRequest,
    error: erroDetailRequest,
    fetchData: fetchDetailRequest,
    isLoading: isLoadingDetail,
  } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/activity-payment/${id}/detailing?centro_custo_id=${center_cost_id}${
        user?.data ? '&pessoa_id=' + user.data.id : ''
      }`
  )

  const { data: reRegister, fetchData: fetchReRegister } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/person/re/register?pessoa_id=${user?.data?.id}&centro_custo_id=${center_cost_id}&aplicacoes=4`
  )

  const { data: inscricoesApi, fetchData: fetchInscricoes } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/subscription/pending?pessoa_id=${user?.data?.id}&origem_inscricao=5`
  )

  const { fetchData: fetchPersonCenterCost } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/center-cost-person?pessoa_id=${user?.data?.id}&centro_custo_id=${center_cost_id}`,
    'POST'
  )

  const { data: category, fetchData: fetchListCategory } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/center-cost/${center_cost_id}/category?pessoa_id=${user?.data?.id}&visivel_mod_congresso=S`
  )

  useEffect(() => {
    setDetailtErrorMessage(erroDetailRequest)
  }, [erroDetailRequest])

  const { data: categoryReceiptRequest, fetchData: fetchCategoryReceiptRequest } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/center-cost-category/${user?.data?.categoria_centro_custo_id}`
  )

  const { data: dataPersonCenterCost, fetchData: fetchDataPersonCenterCost } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/center-cost-person?pessoa_id=${user?.data?.id}&centro_custo_id=${center_cost_id}`
  )

  const { data: idReceipt, fetchData: fetchIdReceipt } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/attachments?table=pessoas_centros_custos&tabela_id=${dataPersonCenterCostApi?.id}&tipo_documento_id[]=200`
  )

  const { data: questions, fetchData: fetchQuestions } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/question?centro_custo_id=${center_cost_id}&pessoa_id=${user?.data?.id}&destino_pergunta=17`
  )

  let pendingSubscriptions = inscricoesApi && inscricoesApi?.data.length > 0 ? inscricoesApi.data : []
  let activitySubscription =
    pendingSubscriptions && offer ? pendingSubscriptions.find((activity) => activity?.agenda_atividade_id == offer?.aga_id) : null
  let pendingSubscriptionsIdsArray = pendingSubscriptions.map((subscription) => subscription?.inscricao_uuid)

  const {
    data: subscriptionComboApi,
    fetchData: fetchComboSubscription,
    isLoading: isLoadingComboSubscription,
  } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/subscription/combo?pessoa_id=${
        user.data && user.data.id
      }&inscricao_id[]=${pendingSubscriptionsIdsArray.join(',')}`
  )

  const getFilteredAnnuities = () => {
    let filteredAnnuities = annuities?.data || []
    filteredAnnuities = filteredAnnuities.filter((annuity) => !annuity?.controle_boleto_cartao_id)
    let pending = inscricoesApi?.data
    if (pending) {
      filteredAnnuities = filteredAnnuities.filter((annuity) =>
        !pending.some((pendingSubscription) => pendingSubscription.inscricao_uuid == annuity.inscricao_id)
      )
    }

    return filteredAnnuities
  }

  let filteredAnnuities = getFilteredAnnuities()

  const getActivityAmount = () => {
    let subscriptionComboApiData = subscriptionComboApi?.data
      ? subscriptionComboApi.data.find((subscription) => subscription.inscricao_id == activitySubscription?.inscricao_uuid)
      : null
    let subscriptionComboDataAmount = subscriptionComboApiData
      ? subscriptionComboApiData.valor - subscriptionComboApiData.valor_desconto
      : 0
    let amount = subscriptionComboDataAmount
      ? subscriptionComboDataAmount
      : activitySubscription?.cr_valor
      ? parseFloat(activitySubscription?.cr_valor)
      : detailRequest?.data?.detailing[0]?.valor

    return amount
  }

  let activityAmount = getActivityAmount()

  useEffect(() => {
    if (user?.data) {
      fetchDataPersonCenterCost()
      fetchCategoryReceiptRequest()
      fetchQuestions()
      fetchInscricoes()
      filteredAnnuities.length > 0 && fetchComboSubscription()
    }
  }, [
    user?.data,
    fetchDataPersonCenterCost,
    fetchCategoryReceiptRequest,
    fetchInscricoes,
    fetchComboSubscription,
    JSON.stringify(filteredAnnuities),
  ])
  useEffect(() => {
    if (dataPersonCenterCost) {
      setDataPersonCenterCostApi(dataPersonCenterCost.data[0])
    }
  }, [dataPersonCenterCost])

  useEffect(() => {
    if (dataPersonCenterCostApi?.id) {
      fetchIdReceipt()
    }
  }, [dataPersonCenterCostApi, openModalReceipt, fetchIdReceipt])

  const onInvalidSubscription = (errorMessage) => {
    let messageId = 'invalid-subscription-message-detail-offers'
    setNecessaryErrorMessage(errorMessage)
    dispatch(toast.actions.unqueue(messageId))
    dispatch(
      toast.actions.enqueue({
        content: errorMessage,
        severity: 'error',
        duration: '9000',
        id: messageId,
      })
    )
    setTotalAnnuity(0)
    if (!shoppingCarts) return
    let returnToLastValue = shoppingCarts.length - 1
    dispatch(offers.actions.setShoppingCarts(!returnToLastValue ? null : Array.from({ length: returnToLastValue })))
  }

  const redirectSubscribe = async (state) => {
    if (!user.data) {
      setOpenLogin(true)
      return
    }
    if (questions?.data && questions.data.length > 0 && !questions.data[0].respondido) {
      setShowQuiz(true)
      return
    }

    if (!(await validCategoryReceipt())) {
      return
    }

    let cloneSelectedCourse = structuredClone(selectedCourse)
    if (state !== 'inPaymentWithAnnuity') {
      cloneSelectedCourse['ids_anuidades'] = ''
      cloneSelectedCourse['plp_pacote_id'] = ''
      cloneSelectedCourse['pacote_id'] = ''
    }
    cloneSelectedCourse['qtds_inscricoes'] = purchaseQuantity

    setIsLoadingSubscribe(true)

    try {
      await onSubscribe(cloneSelectedCourse)

      setState(state)
      if (state !== 'toCourtesy') setState('redirectToShoppingCart')
    } catch (error) {
    } finally {
      setIsLoadingSubscribe(false)
      fetchDetailRequest()
    }
  }

  const addWaitingList = async () => {
    const activity = detailRequest?.data?.detailing[0]
    if (!activity) return
    if (!user.data) {
      setOpenLogin(true)
      return
    }
    const payload = JSON.stringify({ pessoa_id: user?.data?.id, agenda_atividade_id: activity?.aga_id, status: '1' })
    await fetchPreRegistration(payload)
  }

  useEffect(() => {
    const displayToast = (content, severity) => {
      dispatch(
        toast.actions.enqueue({
          content,
          severity,
        })
      )
    }

    if (dataPreRegistration && !errorPreRegistration) {
      displayToast(dataPreRegistration.message, 'success')
    }

    if (errorPreRegistration) {
      displayToast(errorPreRegistration, 'error')
    }
  }, [dataPreRegistration, errorPreRegistration])

  const { onSubscribe, subscription, errorResponseRequest } = subscriptionRequest({
    user,
    selectedCourse: selectedCourse,
    onInvalid: onInvalidSubscription,
    subscriptionRedux: subscriptionRedux,
    toast: toast,
  })

  useEffect(() => {
    setErrorSubscriptionData(errorResponseRequest?.data || errorResponseRequest?.message)
  }, [errorResponseRequest])

  useEffect(() => {
    if (isAutenticated) {
      handleCloseLogin()
    }
  }, [isAutenticated])

  useEffect(() => {
    fetchDetailRequest()
  }, [id, fetchDetailRequest])

  useEffect(() => {
    if (detailRequest) {
      setTabs(detailRequest.data)
      setOffer(detailRequest.data.detailing[0])
      setSelectedCourse(detailRequest.data.detailing[0])
    }
  }, [detailRequest])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    if (user?.data?.id) {
      fetchAnnuities()
    }
  }, [user?.data?.id, fetchAnnuities])

  useEffect(() => {
    if (subscription && state === 'redirectToShoppingCart') {
      goTo('shopping-cart')
    }
  }, [subscription, state, goTo])

  const addShoppingCart = async ({ includeAnnuitiesIds = false }) => {
    let totalItems = 0
    let cloneSelectedCourse = structuredClone(selectedCourse)
    if (!includeAnnuitiesIds && cloneSelectedCourse.hasOwnProperty('ids_anuidades')) cloneSelectedCourse['ids_anuidades'] = null
    let totalAnnuities = Object.is(cloneSelectedCourse['ids_anuidades'], null)
      ? 0
      : cloneSelectedCourse['ids_anuidades'].split(',').length
    cloneSelectedCourse['qtds_inscricoes'] = purchaseQuantity
    totalItems += cloneSelectedCourse['qtds_inscricoes']
    totalItems += totalAnnuities
    if (!user.data) {
      setOpenLogin(true)
      return
    }

    if (questions.data.length > 0 && !questions.data[0].respondido) {
      setShowQuiz(true)
      return
    }

    if (!(await validCategoryReceipt())) {
      return
    }
    try {
      setIsLoading(true)
      dispatch(offers.actions.setShoppingCarts(Array.from({ length: shoppingCarts.length + totalItems })))
      await onSubscribe(cloneSelectedCourse, true)
      setState(state)
      setTotalAnnuity(0)
      fetchDetailRequest()
    } finally {
      if (totalItems > 1) {
        fetchInscricoes()
      }
      setIsLoading(false)
    }
  }

  const handleCloseLogin = () => {
    setOpenLogin(false)
  }

  const changePayment = () => {
    setShowWrongCard(false)
  }

  const validCategoryReceipt = async () => {
    if (categoryReceiptRequest?.exige_comp_cat_evento === 'S') {
      if (dataPersonCenterCostApi?.id && idReceipt?.data?.length === 0) {
        setOpenModalReceipt(true)
        return false
      }
    }
    if (categoryReceiptRequest?.exige_comp_cat_evento_insc === 'S') {
      if (
        dataPersonCenterCostApi?.id &&
        (idReceipt?.data?.length === 0 || [0, 2].includes(dataPersonCenterCostApi?.status_comprovante_categoria))
      ) {
        setOpenModalReceipt(true)
        return false
      }
    }
    return true
  }

  const selectOffer = async (activity) => {
    goTo(`offers/${activity?.aga_id}/${activity.centro_custo_id_out}`)
  }
  const selectProduct = async (product) => {
    goTo(`offers/store/${product?.aga_id}/${product.centro_custo_id_out}`)
  }

  const actionStream = async (card) => {
    await dispatch(subscriptionRedux.actions.setTransmissionActivity(card))
    goTo('streaming')
  }

  const onClickMindsRadiology = () => {
    let detailing = tabs?.detailing?.[0]
    let mindsRadiologyUrl = detailing?.ins_metadata ? JSON.parse(detailing?.ins_metadata)?.mindsRadiologyUrl : ''
    window.open(mindsRadiologyUrl)
  }

  const updateTotalAnnuity = () => {
    let detailing = tabs?.detailing?.[0]
    if (!detailing) return 0
    let courseValue = parseFloat(detailing?.valor_pacote || detailing?.valor_socio_quite || activityAmount || 0)
    courseValue = courseValue * purchaseQuantity
    const total = [courseValue, parseFloat(detailing?.valor_anuidade_pendente)].reduce(function (total, number) {
      if (number) {
        return total + number
      }
      return total
    }, 0)

    return total
  }

  const onChangeComboAnnuity = (checked) => {
    if (!checked) {
      setTotalAnnuity(0)
      return
    }
    let total = updateTotalAnnuity()
    setTotalAnnuity(total)
  }

  const handlePurchaseQuantity = (quantity) => {
    setPurchaseQuantity(quantity)
  }

  useEffect(() => {
    if (!totalAnnuity) {
      setTotal(activityAmount * purchaseQuantity)
      return
    }
    let total = updateTotalAnnuity()
    setTotalAnnuity(total)
  }, [activityAmount, purchaseQuantity, updateTotalAnnuity])

  useEffect(() => {
    if (!reRegister?.data?.[0]) return
    const timeout = setTimeout(() => goTo('me'), 2000)
    return () => clearTimeout(timeout)
  }, [reRegister])

  useEffect(() => {
    if (isAuthenticated) {
      fetchReRegister()
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (questions && questions?.data?.length > 0 && !questions?.data[0]?.respondido) {
      setShowQuiz(true)
    }
  }, [questions])

  useEffect(() => {
    if ([undefined, null].includes(dataPersonCenterCost?.data) || dataPersonCenterCost?.data?.length < 1) {
      fetchListCategory()
    }
  }, [dataPersonCenterCost?.data, fetchListCategory])

  const onChangeCategory = async (e) => {
    await fetchPersonCenterCost(
      JSON.stringify({
        pessoa_id: user.data.id,
        categoria_centro_custo_id: e.value,
        categoria_centro_custo_princ: 'S',
        nome_cracha: user.data.nome,
      })
    )
    if (e.value) {
      let data = user.data
      dispatch(
        auth.actions.updateUser({
          ...user,
          data: {
            ...data,
            categoria_centro_custo_descricao: category.data.filter((res) => res.id === e.value)[0].descricao,
            categoria_centro_custo_id: category.data.filter((res) => res.id === e.value)[0].id,
          },
        })
      )
      fetchDetailRequest()
    }
  }

  return (
    <DetailOfferView
      centerCost={centerCost}
      isLoadingScreen={isLoadingDetail || isLoadingComboSubscription}
      status_comprovante_categoria={dataPersonCenterCostApi?.status_comprovante_categoria}
      comp_recusa={dataPersonCenterCostApi?.comp_recusa}
      payment={payment}
      openModalReceipt={openModalReceipt}
      setOpenModalReceipt={setOpenModalReceipt}
      center_cost_id={center_cost_id}
      cc={center_cost_id}
      id={id}
      paymentRedux={paymentRedux}
      toast={toast}
      user={user}
      total={total}
      openLogin={openLogin}
      offer={!erroDetailRequest && offer}
      tabs={!erroDetailRequest && tabs}
      value={value}
      showWrongCard={showWrongCard}
      annuities={filteredAnnuities}
      handleCloseLogin={handleCloseLogin}
      handleChange={handleChange}
      addShoppingCart={addShoppingCart}
      setShowWrongCard={setShowWrongCard}
      changePayment={changePayment}
      fetchInscricoes={fetchInscricoes}
      redirectSubscribe={redirectSubscribe}
      addWaitingList={addWaitingList}
      selectOffer={selectOffer}
      selectProduct={selectProduct}
      goTo={goTo}
      activityAmount={activityAmount}
      detailErrorMessage={detailErrorMessage && detailErrorMessage}
      necessaryErrorMessage={necessaryErrorMessage && necessaryErrorMessage}
      setNecessaryErrorMessage={setNecessaryErrorMessage}
      errorSubscriptionData={errorSubscriptionData && errorSubscriptionData}
      isLoading={isLoading}
      isLoadingSubscribe={isLoadingSubscribe}
      showQuiz={showQuiz}
      setShowQuiz={setShowQuiz}
      fetchQuestions={fetchQuestions}
      questions={questions}
      category={category}
      onChangeCategory={onChangeCategory}
      totalAnnuity={totalAnnuity}
      actionStream={actionStream}
      onClickMindsRadiology={onClickMindsRadiology}
      onChangeComboAnnuity={onChangeComboAnnuity}
      handlePurchaseQuantity={handlePurchaseQuantity}
    />
  )
}

export default DetailOfferContainer
