import { BtnAssistir, BtnPendente, BtnAssistirAoVivo, BtnAttendLecture, BtnAttendSeeClasses, BtnSeeMore, BtnMindsRadiology } from "../BtnsActions/BtnsActions"
import { useRedirect } from "@itarget/shared"
import { useDispatch } from "react-redux"
import { subscription } from "../../redux"
import { Grid } from "@material-ui/core"

const ActionsPlay = (props) => {
  const { card } = props
  const dispatch = useDispatch()
  const { goTo } = useRedirect()

  const mindsRadiologyUrl = card?.ins_metadata && JSON.parse(card?.ins_metadata)?.mindsRadiologyUrl || '';

  const onClickOnDemand = () => {
    goTo(`my-course/${card.aga_id}`)
  }

  const onClickSeeMore = () => {
    goTo(`offers/${card.aga_id}/${card.centro_custo_id_out}`)
  }
  const onClickMindsRadiology = () => {
    window.open(mindsRadiologyUrl);
  }

  const onClickAttendLecture = () => {
    card.commerce_formato_exibicao == 2 && goTo(`my-lecture/${card.centro_custo_id_out}/${card.aga_id}`)
    card.commerce_formato_exibicao == 3 && goTo(`detail-ead/${card.centro_custo_id_out}/${card.aga_id}`)
  }

  const onClickSeeClasses = () => {
    card.commerce_formato_exibicao == 3 && goTo(`detail-ead/${card.centro_custo_id_out}/${card.aga_id}`)
  }

  const onClickAoVivo = async () => {
    await dispatch(subscription.actions.setTransmissionActivity(card))
    goTo(`streaming`)
  }

  const onClickPending = () => {
    if (card.controle_boleto_cartao_id) {
      goTo(`my-activity`)
      return
    }
    goTo(`offers/${card.aga_id}`)
  }
  return (
    <Grid container className='grid-card-actions-play'>
      {card?.transmissao_gratuito === "S" && (
        <>
          {card?.status_inscricao !== 2 && <BtnAssistir onClick={() => onClickOnDemand()} />}
        </>
      )}
      {card && card.status_inscricao !== undefined && card.status_inscricao !== null && card.status_inscricao !== "" && (
        <>
          {card.status_inscricao === 1 && <BtnPendente onClick={() => onClickPending()} />}
          {card.status_inscricao === 2 && (
            <>
              {card.qtde_aulas_video > 0 && <BtnAssistir onClick={() => onClickOnDemand()} />}
              {card.qtde_aulas_video <= 0 && card.disponivel_transmissao !== "S" && <BtnSeeMore onClick={onClickSeeMore} />}
              {card.disponivel_transmissao === "S" && <BtnAssistirAoVivo onClick={() => onClickAoVivo()} />}
            </>
          )}
        </>
      )}
      {card && (
        <>
          {[3].includes(card.commerce_formato_exibicao) && <BtnAttendSeeClasses onClick={() => onClickSeeClasses()} />}
        </>
      )}
      {!card?.status_inscricao && <BtnSeeMore onClick={onClickSeeMore} />}
      {mindsRadiologyUrl && <BtnMindsRadiology onClick={onClickMindsRadiology} />}
    </Grid>
  )
}

export default ActionsPlay
