import { Config, enumsPayment, formatDate, formatString, useFetchApi, useQueryParams } from '@itarget/shared'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

function groupEventsByPeriod(programming) {
  const morningStartHour = 8
  const afternoonStartHour = 12

  let startAt = programming[0]?.startAt
  let dateStartAt = startAt ? formatDate.dateFormatToWeekday(startAt.split(' ')[0]) : null
  let weekday = dateStartAt ? formatString.toLowerCase(dateStartAt) : null

  const undefinedEvents = {
    id: 'morningEventsArray',
    startAt: `Sem horário agendado`,
    items: [],
  }
  const morningEvents = {
    id: 'morningEventsArray',
    startAt: `${weekday ? `${weekday} ` : ''}08:00 às 12:00`,
    items: [],
  }
  const afternoonEvents = {
    id: 'afternoonEventsArray',
    startAt: `${weekday ? `${weekday} ` : ''}12:00 às 18:00`,
    items: [],
  }

  programming.forEach((lecture) => {
    const eventStartHour = parseInt(lecture.startAt.split(' ')[1].split(':')[0], 10)
    if (eventStartHour == 0) {
      undefinedEvents.items.push(lecture)
    } else if (eventStartHour < afternoonStartHour) {
      morningEvents.items.push(lecture)
    } else {
      afternoonEvents.items.push(lecture)
    }
  })

  let response = {
    undefined: undefinedEvents,
    morning: morningEvents,
    afternoon: afternoonEvents,
  }

  if (response.morning.items.length == 0) {
    delete response.morning
  }

  if (response.afternoon.items.length == 0) {
    delete response.afternoon
  }

  return Object.values(response)
}

const useAction = ({ groupBy, detailing }) => {
  const { get } = useQueryParams()
  const centerCostId = useSelector((state) => state.auth.cc)
  const selectedLanguage = useSelector((state) => state.language.lang)
  const { data, fetchData, isLoading, error } = useFetchApi(
    Config.baseURL + `api/${selectedLanguage}/program?centro_custo_id=${detailing?.centro_custo_id_out || centerCostId}`
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if ((!data && isLoading) || (!data && !isLoading)) return { data, isLoading }

  let isRegistered = detailing?.status_inscricao == enumsPayment.STATUS_REGISTRATION.REGISTERED

  const selected = {
    date: get('date'),
    search: get('search'),
    startAt: get('startAt'),
    endAt: get('endAt'),
    tagId: get('tagId'),
    roomId: get('roomId'),
  }

  const groupProgramming = {
    period: groupEventsByPeriod,
  }

  let filteredData = data?.data || []

  filteredData = filteredData?.map((row) => {
    let newSpeakers = row.speakers?.map((speaker) => {
      speaker['isRegistered'] = isRegistered
      return speaker
    })
    row['speakers'] = newSpeakers
    return row
  })

  if (selected.search) {
    filteredData = filteredData.filter(
      (lecture) =>
        formatString.includes(lecture.title, selected.search) ||
        lecture.presentations.some((presentation) =>
          presentation.speakers.some((speaker) => formatString.includes(speaker.name, selected.search))
        )
    )
  }
  if (selected.startAt) {
    filteredData = filteredData.filter((lecture) => {
      const [hourStartAt, minuteStartAt] = lecture.startAt.split(' ')[1].split(':').map(Number)
      const [selectedHour, selectedMinute] = selected.startAt.split(':').map(Number)
      let rule = {
        earlierThanStartAt: hourStartAt < selectedHour || (hourStartAt === selectedHour && minuteStartAt < selectedMinute),
      }
      if (rule.earlierThanStartAt) return false
      return true
    })
  }
  if (selected.endAt) {
    filteredData = filteredData.filter((lecture) => {
      const [hourStartAt, minuteStartAt] = lecture.startAt.split(' ')[1].split(':').map(Number)
      const [selectedHour, selectedMinute] = selected.endAt.split(':').map(Number)
      let rule = {
        laterThanStartAt: hourStartAt > selectedHour || (hourStartAt === selectedHour && minuteStartAt > selectedMinute),
      }
      if (rule.laterThanStartAt) return false
      return true
    })
  }
  if (selected.tagId) {
    filteredData = filteredData.filter((lecture) => lecture.tagId == selected.tagId)
  }
  if (selected.roomId) {
    filteredData = filteredData.filter((lecture) => lecture.roomId == selected.roomId)
  }
  filteredData.map((lecture) => {
    lecture.presentations = lecture.presentations
      ? lecture.presentations.map((presentations) => {
          presentations['isRegistered'] = isRegistered
          return presentations
        })
      : []
    return lecture
  })

  const [roomsActivities, datesActivities] = [new Object(), new Object()]

  filteredData.map((lecture) => {
    let dateStartAt = lecture.startAt.split(' ')[0]
    let dateStartAtId = Number(dateStartAt.split('-').join(''))
    datesActivities[dateStartAtId] = { id: dateStartAtId, name: dateStartAt }
  })
  if (data?.meta?.dates) {
    data.meta.dates = data.meta.dates.map((date) => {
      let status = !!datesActivities[date.id]
      return { ...date, status }
    })
  }

  if (selected.date) {
    filteredData = filteredData.filter((lecture) => lecture.startAt.split(' ')[0] == selected.date)
  }

  filteredData.map((lecture) => {
    roomsActivities[lecture.roomId] = { id: lecture.roomId, name: lecture.room }
  })

  let rooms = Object.values(roomsActivities)
  let groupedFilteredData = groupProgramming[groupBy](filteredData)

  return {
    data: groupedFilteredData,
    ...data?.meta,
    rooms,
    isRegistered,
    fetchData,
    isLoading,
    error,
  }
}

export default useAction
