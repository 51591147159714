import { Divider, Grid, Dialog, Typography, Link, Button } from "@material-ui/core";
import { PaymentContainer, RTag, Translator } from "@itarget/shared";
import "./style.scss";

import Layout from "../../../components/Layout";
import CarouselCardOffers from "../../../components/CarouselCardOffers";
import CardAlertShoppingCart from "../../../components/CardAlertShoppingCart";
import LoginContainer from "../../login/LoginContainer";
import FinishPayment from "../../../components/FinishPayment";

import CardWrongCreditCard from "../../../components/CardWrongCreditCard";
import { useRef, useEffect, useState } from "react";
import CardShopping from "../../../components/CardShopping";
import TabsDetailStore from "../../../components/TabsDetailStore";
import CardOffersStore from "../../../components/CardOffers/CardOffersStore";
import CardPriceStore from "../../../components/CardPriceStore.jsx/CardPriceStore";
import ProductSubtitle from "../../../components/ProductSubtitle";
import ButtonArrowToTop from "../../../components/ButtonArrowToTop";

const DetailStoreView = ({
  status_comprovante_categoria,
  comp_recusa,
  paymentRedux,
  toast,
  subscriptionState,
  setSubscriptionState,
  paymentType,
  total,
  user,
  openShoppingCart,
  offer,
  state,
  setState,
  tabs,
  addShoppingCart,
  openLogin,
  handleCloseLogin,
  cc,
  center_cost_id,
  goFinish,
  vlTotal,
  incList,
  onInvalidCardPayment,
  showWrongCard,
  resetStatePayment,
  changePayment,
  setShowWrongCard,
  isLoadingInscricoes,
  redirectSubscribe,
  addWaitingList,
  openModalReceipt,
  setOpenModalReceipt,
  annuity,
  dispatch,
  id,
  subscription,
  selectOffer,
  selectProduct,
  amountProduct,
  amountProductChange,
}) => {
  const [totalAnnuity, setTotalAnnuity] = useState(null);
  const [openText, setOpenText] = useState(false);
  const informations = useRef(null);
  const textoDescritivoDoAgendamento = offer?.descritivo;
  const verificandoSeTemEmEstoqueParaVenda = offer?.vagas_limite >= offer?.vagas_ocupadas;

  const handleClick = () => {
    informations !== null &&
      informations.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  };

  useEffect(() => {
    if (tabs?.detailing?.[0]?.valor && tabs?.detailing?.[0]?.valor_anuidade_pendente) {
      const detailing = tabs?.detailing?.[0];
      const courseValue = detailing?.valor_socio_quite ? parseFloat(detailing?.valor_socio_quite) : parseFloat(detailing?.valor);
      const newTotal = [courseValue, parseFloat(detailing?.valor_anuidade_pendente)].reduce(function (total, numero) {
        if (numero) {
          return total + numero;
        }
        return total;
      }, 0);

      setTotalAnnuity(newTotal);
    }
  }, [tabs?.detailing?.[0]?.valor, tabs?.detailing?.[0]?.valor_anuidade_pendente]);

  return (
    <Layout>
      <Dialog maxWidth="lg" open={openLogin} onClose={handleCloseLogin} className="dialog-login">
        <LoginContainer />
      </Dialog>
      {showWrongCard && <CardWrongCreditCard changePayment={changePayment} setShow={setShowWrongCard} />}
      {openShoppingCart && <CardAlertShoppingCart card={offer} state={state} amountProduct={amountProduct} />}
      {!["voucher", "payment", "inPaymentWithAnnuity"].includes(state) ? (
        <Grid className="detailStore-container" container spacing={2} xs={12}>
          <Grid item xs={12} sm={12} md={7} lg={8} className="detailStore-cardOffers">
            <CardOffersStore card={offer} text={textoDescritivoDoAgendamento} handleClick={handleClick} />
          </Grid>
          <Grid item sx={12} sm={12} md={5} lg={4} style={{ height: "100%" }} className="payment-button">
            <CardPriceStore
              annuity={annuity}
              value={tabs?.amount?.value[0]?.valor}
              status_comprovante_categoria={status_comprovante_categoria}
              comp_recusa={comp_recusa}
              detailing={tabs.detailing && tabs.detailing[0]}
              state={state}
              payment={paymentRedux}
              toast={toast}
              addShoppingCart={addShoppingCart}
              redirectSubscribe={redirectSubscribe}
              addWaitingList={addWaitingList}
              cc={cc}
              verificandoSeTemEmEstoqueParaVenda={verificandoSeTemEmEstoqueParaVenda}
              openModalReceipt={openModalReceipt}
              setOpenModalReceipt={setOpenModalReceipt}
              totalAnnuity={totalAnnuity}
              amountProduct={amountProduct}
              amountProductChange={amountProductChange}
            />
          </Grid>
          <Grid style={{ margin: "1.5rem 0" }} item xs={12}>
            <Divider className="detailOffer-divider" />
          </Grid>
        </Grid>
      ) : (
        <Grid className="detailStore-container" container spacing={2} xs={12}>
          <Grid item xs={12} md={8} className="detailStore-payment">
            {state !== "finish" ? (
              <PaymentContainer
                subscription={subscription}
                goFinish={goFinish}
                centro_custo_id={center_cost_id}
                toast={toast}
                paymentType={paymentType}
                subscriptionState={subscriptionState}
                paymentRedux={paymentRedux}
                total={totalAnnuity || total}
                user={user}
                onInvalidCardPayment={onInvalidCardPayment}
                resetState={resetStatePayment}
              />
            ) : (
              <FinishPayment vlTotal={vlTotal} incList={incList} />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4} className="payment-button">
            <CardShopping
              isLoadingInscricoes={isLoadingInscricoes}
              total={totalAnnuity || total}
              state={state}
              setState={setState}
              selected={subscriptionState}
              setSelected={setSubscriptionState}
              user={user}
              dispatch={dispatch}
              amountProduct={amountProduct}
              amountProductChange={amountProductChange}
            />
          </Grid>
          <Grid style={{ margin: "1.5rem 0" }} item xs={12}>
            <Divider className="detailOffer-divider" />
          </Grid>
        </Grid>
      )}
      {!["voucher", "payment", "inPaymentWithAnnuity"].includes(state) && (
        <div className="detailOffer-moreOfProduct">
          <section className="moreOfProduct-subtitle">
            <ProductSubtitle text="Produtos relacionados:" />
            <Grid className="carouselCard">
              <CarouselCardOffers selectOffer={selectOffer} selectProduct={selectProduct} cc={offer?.centro_custo_id_out || center_cost_id} id={Number(id)} />
            </Grid>
          </section>
          <section ref={informations} className="moreOfProduct-informations">
            <ProductSubtitle text="Mais Informações:" />
            <Typography className="informations-text" variant="body1">
              <RTag show="desktop">
                <div
                  className="subtitle-text"
                  style={{ fontWeight: 300 }}
                  dangerouslySetInnerHTML={{
                    __html: offer?.descritivo !== null ? offer?.descritivo : "CADASTRAR DETALHES",
                  }}
                />
              </RTag>
              <RTag show="mobile">
                <div
                  className="subtitle-text"
                  style={{ fontWeight: 300 }}
                  dangerouslySetInnerHTML={{
                    __html: offer?.descritivo !== null ? `${offer?.descritivo.substr(0, 120)}...` : "CADASTRAR DETALHES",
                  }}
                />
                {offer?.descritivo !== null && offer?.descritivo.length > 150 && (
                  <Button className="cardOffersStore-button" onClick={() => setOpenText(true)} variant="contained">
                    <Translator transRef="translation.CARREGAR INFORMAÇÕES" />
                  </Button>
                )}
              </RTag>
            </Typography>
            <Dialog maxWidth="md" open={openText} onClose={() => setOpenText(false)}>
              <div className="mobile-subtitle">
                <ProductSubtitle text={offer?.desc_aga} width="60%" />
                <div
                  className="subtitle-text"
                  style={{ maxWidth: "100%", fontWeight: 300 }}
                  dangerouslySetInnerHTML={{
                    __html: offer?.descritivo !== null ? offer?.descritivo : "CADASTRAR DETALHES",
                  }}
                />
              </div>
            </Dialog>
          </section>
        </div>
      )}
      <ButtonArrowToTop />
    </Layout>
  );
};

export default DetailStoreView;
