import { Typography, Divider, Grid, Icon, Link, Tooltip, IconButton, Box } from '@material-ui/core'
import { Loading, Translator, Button, enumsSubscription } from '@itarget/shared'
import { useSelector } from 'react-redux'
import { dateFormatTranslation } from '@itarget/shared/src/utils/format/date'

const HeaderAccordion = ({ header, data, reprintBillet, openModal, checkPayment, onChangePayment, loadingPix }) => {
  const pendingApplication = data.find((r) => r.status_conta_receber === 1 || r.status_conta_receber === 2)
  const data_pagamento = data.find((r) => r.data_pagamento) && data.find((r) => r.data_pagamento).data_pagamento
  const tipo_origem_descricao =
    data.find((r) => r.tipo_origem_descricao !== null) && data.find((r) => r.tipo_origem_descricao !== null).tipo_origem_descricao
  const selectedLang = useSelector((state) => state.language.lang)
  const datePayment = Translator({ transRef: 'translation.Data pagamento' })
  const typePayment = Translator({ transRef: 'translation.Forma de pagamento' })

  if (data[0].tipo_origem == 13 && loadingPix) {
    return <Loading />
  }

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          md={10}>
          <Typography className='accordion-textHeader'>
            <Translator transRef='translation.Compra Nº' /> {header}
          </Typography>
        </Grid>

        <Grid
          item
          container
          alignItems='center'
          xs={12}
          md={2}
          spacing={1}
          className='leftSide'>
          <Box display='flex'>
            <Icon style={{ color: pendingApplication ? '#f8a84c' : '#32b126' }}>{pendingApplication ? 'schedule' : 'done'}</Icon>
          </Box>
          <Grid item>
            <Typography style={{ color: pendingApplication ? '#f8a84c' : '#32b126' }}>
              {pendingApplication ? <Translator transRef='translation.Pendente' /> : <Translator transRef='translation.Pago' />}
            </Typography>
          </Grid>
          {data[0].status_inscricao !== 2 && (
            <IconButton
              onClick={(event) => {
                event.stopPropagation()
                openModal(data, header)
              }}>
              <Icon>close</Icon>
            </IconButton>
          )}
        </Grid>

        <Grid
          xs={12}
          md={12}
          className='tab2'>
          <Grid
            xs={12}
            md={12}
            className='tab2-grid-container'>
            <Grid
              container
              className='informations'>
              <Grid
                container
                item
                xs={12}
                md={12}
                className='info-course'>
                <Grid container>
                  {data_pagamento && (
                    <Grid
                      item
                      container
                      alignItems='center'
                      spacing={1}>
                      <Box display='flex'>
                        <Icon>event</Icon>
                      </Box>
                      <Grid item>
                        <Typography variant='caption'>{dateFormatTranslation(selectedLang, data_pagamento)}</Typography>
                      </Grid>
                    </Grid>
                  )}

                  {tipo_origem_descricao && (
                    <Grid
                      item
                      container
                      alignItems='center'
                      spacing={1}>
                      <Box display='flex'>
                        <Icon>local_atm</Icon>
                      </Box>
                      <Grid item>
                        <Typography variant='caption'>{tipo_origem_descricao}</Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                <Grid
                  container
                  spacing={2}
                  className='grid-left-infos-container'>
                  
                  {data[0].status_inscricao === enumsSubscription.SUBSCRIPTION_STATUS.TRANSFERRED.CODE && (
                      <Grid item>
                        <Box
                          display='flex'
                          alignItems='center'
                        >
                          <Typography variant='caption'>
                            <Translator transRef='translation.Inscrição Transferida' />
                          </Typography>
                            &nbsp;
                            <Icon>move_down_sharp</Icon>
                        </Box>
                      </Grid>
                    )
                  }

                  {data[0].link_recibo && data[0].status_inscricao !== enumsSubscription.SUBSCRIPTION_STATUS.TRANSFERRED.CODE && (
                    <Grid item>
                      <Button
                        color='twilight'
                        icon='receipt_long'
                        variant='midnight'
                        onClick={(event) => {
                          window.open(data[0].link_recibo)
                          event.stopPropagation()
                        }}>
                        <Translator transRef='translation.Recibo' />
                      </Button>
                    </Grid>
                  )}
                  {data[0].tipo_origem == 13 && data[0].status_inscricao !== 2 && (
                    <Grid item>
                      <Button
                        color='twilight'
                        variant='midnight'
                        onClick={(event) => {
                          checkPayment(data[0])
                          event.stopPropagation()
                        }}>
                        <Translator transRef='translation.Consultar pagamento' />
                      </Button>
                    </Grid>
                  )}
                  {data[0].status_inscricao !== 2 && data[0].hash_transacao && (
                    <Grid item>
                      <Button
                        color='twilight'
                        variant='midnight'
                        onClick={(event) => {
                          event.stopPropagation()
                          reprintBillet(data[0])
                        }}>
                        <Translator transRef='translation.Reimprimir Boleto' />
                      </Button>
                    </Grid>
                  )}
                  {data[0].esta_vencido === 'S' && (
                    <Grid item>
                      <Button
                        color='twilight'
                        variant='midnight'>
                        <Translator transRef='translation.Vencido' />
                      </Button>
                    </Grid>
                  )}
                  {data[0].status_inscricao !== 2 && data[0].esta_vencido !== 'S' && (
                    <>
                      {data[0].administradora_cartao !== null && (
                        <Grid item>
                          <Button
                            color='twilight'
                            variant='midnight'>
                            <Translator transRef='translation.Aguardando processamento' />
                          </Button>
                        </Grid>
                      )}
                      <Grid item>
                        <Button
                          color='twilight'
                          variant='midnight'
                          onClick={(event) => {
                            event.stopPropagation()
                            onChangePayment(data)
                          }}>
                          <Translator transRef='translation.Forma de pagamento' />
                        </Button>
                      </Grid>
                    </>
                  )}
                  <Grid item>
                    <Button
                      icon='keyboard_arrow_down'
                      variant='midnight'>
                      <Translator transRef='translation.Ver Detalhes' />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default HeaderAccordion
