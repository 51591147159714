import { makeStyles } from '@material-ui/core'
import MenuC from '../../../MenuC'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Trailing } from './Trailing'
import { useLocation, useParams } from 'react-router-dom'
import { BannerCarrousel } from '../../../../BannerCarrousel'

const useStyles = makeStyles((theme) => ({
  light: {},
  dark: {},
  logged: {
    '& #menu-desktop': {
      top: '178px !important',
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& .shoppingCartIcon, & .userProfileInformationName': {
      color: '#ffffff',
    },
    '& .MuiTypography-colorTextSecondary': {
      color: '#fffffff3',
    },
  },
  iconcart: {
    width: '32px',
    height: '32px',
  },
  container: {
    backgroundImage: 'linear-gradient(220deg, #006F62 0%, #001613 150%)',
    borderBottom: '1px solid #006f62',
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (min-width: 501px)': {
      minHeight: '130px',
    },
    '@media (max-width: 500px)': {
      position: 'unset',
    },
  },
  header: {
    height: 'fit-content',
    marginRight: '1%',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '@media (min-width: 1240px)': {
      width: '1240px',
    },
    '@media (max-width: 740px)': {
      marginTop: '1%',
    },
    '@media (max-width: 500px)': {
      flexDirection: 'column',
    },
  },
  logo: {
    zIndex: 1,
    maxHeight: '130px',
    '@media (max-width: 1440px)': {
      marginLeft: theme.spacing(2),
    },
    '@media (max-width: 500px)': {
      height: '80px',
    },
  },
  trailingLogged: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: theme.spacing(1.5, 0),
    '& .user-information-block': {
      color: 'inherit',
    },
  },
  trailing: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  trailingMobile: {
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 740px)': {
      padding: '0',
      display: 'flex',
    },
  },
  leading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '@media (max-width: 740px)': {
      padding: '0',
    },
  },
  menuItem: {
    textTransform: 'uppercase',
  },
  menuButton: {
    zIndex: 4,
    '& .material-icons': {
      color: '#fff',
    },
    '@media (min-width: 1140px)': {
      display: 'none',
    },
  },
}))

const bannersMock = [
  {
    type: null,
    title: 'Educação médica continuada',
    subtitle: 'Excelência desde o conteúdo até os laboratórios, para você estar sempre entre os melhores',
    action: null,
    video: { url: 'https://icongresso-x.s3-sa-east-1.amazonaws.com/demo/courses-commerce/170972958289989.mp4', type: 'video/mp4', controls: false, autoPlay: true, muted: true, loop: true },
  },
]

const Header = ({
  logo,
  client,
  shoppingCarts,
  menuItemsC = [],
  isLoadingMenuData,
  user,
  goTo,
  onLogout,
  statusAssociated,
  category,
}) => {
  const MAX_MENU_SIZE_DESKTOP = 1140
  const [showMenu, setShowMenu] = useState('show')

  useEffect(() => {
    window.addEventListener('resize', () => (window.innerWidth <= MAX_MENU_SIZE_DESKTOP ? setShowMenu('hide') : setShowMenu('show')))
    window.removeEventListener('resize', () => (window.innerWidth <= MAX_MENU_SIZE_DESKTOP ? setShowMenu('hide') : setShowMenu('show')))
  }, [window.innerWidth])

  const [imageBackground, setImageBackground] = useState(null)
  const systemTheme = useSelector((state) => state.theme.systemTheme)
  const settingCourse = useSelector((state) => state.setting.settingCommerce)
  const location = useLocation()
  const { cc: centerCostId } = useParams()
  const shouldRenderBanner = location.pathname === '/offers' || location.pathname === `/offers-unique/${centerCostId}`

  let backgroundImageColors = {
    top: systemTheme == 'light' ? '#fafafa00' : '#1C1C1C00',
    bottom: systemTheme == 'light' ? '#fafafafd' : '#1C1C1Cff',
  }

  useEffect(() => {
    if (settingCourse.url_image_topo_background) setImageBackground(settingCourse.url_image_topo_background)
  }, [settingCourse])

  const styles = useStyles({
    backgroundImageColors,
    imageBackground,
    shouldRenderBanner,
    showMenu: showMenu != 'hide',
    theme: systemTheme,
  })

  function toggleMenu() {
    if (showMenu === 'hide') {
      setShowMenu('show')
    } else {
      setShowMenu('hide')
    }
  }

  return (
    <>
      <header className={`${styles.root} ${styles[systemTheme]} ${user?.data?.id ? styles.logged : ''}`}>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.leading}>
              <img
                className={`${styles.logo}`}
                alt={client}
                src={logo}
              />
            </div>
            <MenuC
              items={menuItemsC}
              isLoadingMenuData={isLoadingMenuData}
              classes={{ root: styles.menu, itemRoot: styles.menuItemRoot }}
              showMenu={showMenu}
            />
            <div className={styles.trailing}>
              <Trailing
                shoppingCarts={shoppingCarts}
                showMenu={showMenu}
                category={category}
                goTo={goTo}
                onLogout={onLogout}
                statusAssociated={statusAssociated}
                styles={styles}
                toggleMenu={toggleMenu}
                user={user}
              />
            </div>
          </div>
        </div>
      </header>
      {shouldRenderBanner && <BannerCarrousel bannersData={bannersMock} />}
    </>
  )
}

export default Header
