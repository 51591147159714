import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { useFetchApi, Config, useRedirect, useQueryParams, enumsPayment } from '@itarget/shared'
import { subscription } from '../../redux'
import queryString from 'query-string'
import EadView from './views/EadView'
import { debounce } from 'lodash'

const EadContainer = () => {
  const { goTo } = useRedirect()
  const dispatch = useDispatch()
  const routeParams = useParams()
  const [axis, setAxis] = useState([])
  const [tags, setTags] = useState([])
  const [offers, setOffers] = useState([])
  const [activityType, setActivityType] = useState([])
  const user = useSelector((state) => state.auth.user)
  const isAuthenticated = useSelector((state) => state.auth.isAutenticated)
  const centerCostId = useSelector((state) => state.auth.cc)
  const [groupByOffers, setGroupByOffers] = useState('')
  const [activityTypes, setActivityTypes] = useState([])
  const [selectedFilter, setSelectedFilter] = useState({})
  const [showQuiz, setShowQuiz] = useState()
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({})
  const isCheckboxMarked = Object.values(selectedCheckboxes).some((checkbox) => checkbox == true)
  const [selectedActivities, setSelectedActivities] = useState([])
  const selectedLang = useSelector((state) => state.language.lang)

  const { get, setMultiples } = useQueryParams()
  let selectedTag = get('tag_id')
  let fetchSelectedTag = selectedFilter?.hasOwnProperty('tag') ? selectedFilter.tag : selectedTag && selectedTag.split(',')

  let selectedTypeActivity = get('type_id')
  let fetchSelectedTypeActivity = selectedFilter?.hasOwnProperty('activity_type')
    ? selectedFilter.activity_type
    : selectedTypeActivity && selectedTypeActivity.split(',')

  const {
    data: listOffers,
    isLoading: isLoadingListOffers,
    fetchData: fetchListOffers,
  } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/activity-schedule/offer?nome_interface=ead` +
      (user?.data?.id ? `&pessoa_id=${user.data.id}` : '') +
      (routeParams.activity_id ? `&atividade_id=${routeParams.activity_id}` : '') +
      (selectedFilter?.grouping_field ? `&grouping_field=${selectedFilter.grouping_field}` : '') +
      (selectedFilter?.desc_atv ? `&pesquisa_completa_atv=${selectedFilter.desc_atv}` : '') +
      (selectedFilter?.data_inicio_aga ? `&data_inicio_aga=${selectedFilter.data_inicio_aga}` : '') +
      (fetchSelectedTag ? `&${queryString.stringify({ tag_id: fetchSelectedTag }, { arrayFormat: 'bracket' })}` : '') +
      (selectedFilter?.eixo_id ? `&eixo_id=${selectedFilter.eixo_id}` : '') +
      (selectedFilter?.data_fim_aga ? `&data_fim_aga=${selectedFilter.data_fim_aga}` : '') +
      (fetchSelectedTypeActivity
        ? `&${queryString.stringify({ tipo_atividade_id: fetchSelectedTypeActivity }, { arrayFormat: 'bracket' })}`
        : '')
  )

  const { data: reRegister, fetchData: fetchReRegister } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/person/re/register?pessoa_id=${user?.data?.id}&centro_custo_id=${centerCostId}&aplicacoes=4`
  )

  const { data: questions, fetchData: fetchQuestions } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/question?centro_custo_id=${centerCostId}&pessoa_id=${user?.data?.id}&destino_pergunta=17`
  )

  useEffect(() => {
    if (!reRegister?.data?.[0]) return
    const timeout = setTimeout(() => goTo('me'), 2000)
    return () => clearTimeout(timeout)
  }, [reRegister])

  useEffect(() => {
    if (isAuthenticated) {
      fetchReRegister()
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (user?.data) {
      fetchQuestions()
    }
  }, [user?.data])

  useEffect(() => {
    fetchListOffers()
  }, [user?.data, routeParams.activity_id, fetchListOffers])

  useEffect(() => {
    if (listOffers) {
      setOffers(listOffers.data)
      setAxis(listOffers.axis)
      setTags(listOffers.tag)
      if (!activityType.length > 0) setActivityType(listOffers.activityType)
      setGroupByOffers(listOffers.groupBy)
    }
  }, [listOffers])

  const verifyRegisteredRedirect = (activity) => {
    let statusRegistration = activity?.statusRegistration
    let isRegistered = statusRegistration === enumsPayment.STATUS_REGISTRATION.REGISTERED
    let hasTransmission = activity.disponivel_transmissao === 'S'
    let hasVideo = activity.qtde_aulas_video > 0
    let permission = {
      live: isRegistered && hasTransmission,
      video: isRegistered && hasVideo,
    }

    if (permission.live) {
      dispatch(subscription.actions.setTransmissionActivity(activity))
      goTo(`streaming`)
      return
    }
    if (permission.video) {
      goTo(`my-course/${activity.aga_id}`)
      return
    }
  }

  const selectOffer = async (activity) => {
    verifyRegisteredRedirect(activity)
    goTo(`offers/${activity.aga_id}/${activity.centro_custo_id_out}`)
  }

  const selectProduct = async (product) => {
    goTo(`offers/store/${product.aga_id}/${product.centro_custo_id_out}`)
  }

  const onClickRegistered = async (activity) => {
    verifyRegisteredRedirect(activity)
    goTo(`detail-ead/${activity.aga_id}/${activity.centro_custo_id_out}`)
  }

  useEffect(() => {
    if (questions && questions?.data?.length > 0 && !questions?.data[0]?.respondido) {
      setShowQuiz(true)
    }
  }, [questions])

  const onSubmit = (values) => {
    setSelectedFilter({ ...selectedFilter, ...values })
  }

  const formik = useFormik({
    initialValues: selectedFilter,
    enableReinitialize: true,
    onSubmit,
  })

  const handleInputChangeDebounced = debounce((value) => {
    setSelectedFilter({
      ...selectedFilter,
      desc_atv: value,
      palestrante: value,
    })
  }, 500)

  useEffect(() => {
    setActivityTypes(activityType)
    setSelectedCheckboxes(
      activityType.reduce((acc, curr) => {
        acc[curr.id] = false
        return acc
      }, {})
    )
  }, [activityType])

  useEffect(() => {
    formik.setFieldValue('activity_type', selectedActivities)
  }, [selectedActivities])

  const filterHandler = () => {
    if (!isCheckboxMarked) return
    const newSelectedActivities = []
    for (const [key, value] of Object.entries(selectedCheckboxes)) {
      if (value) {
        newSelectedActivities.push(key)
      }
    }
    setSelectedActivities(newSelectedActivities)
  }

  const handleChange = (event) => {
    let value = event.target.value
    setSelectedFilter({
      ...selectedFilter,
      grouping_field: value,
    })
  }

  useEffect(() => {
    if (Object.keys(selectedFilter).length == 0) return
    let series = [
      selectedFilter.hasOwnProperty('tag')
        ? { param: 'tag_id', value: selectedFilter.tag.length == 0 ? '' : selectedFilter.tag }
        : null,
      selectedFilter.hasOwnProperty('activity_type')
        ? {
            param: 'type_id',
            value: selectedFilter.activity_type.length == 0 ? '' : selectedFilter.activity_type,
          }
        : null,
    ]
    series = series.filter((set) => set)

    setMultiples({ series })
  }, [selectedFilter])

  return (
    <EadView
      axis={axis}
      tags={tags}
      formik={formik}
      centerCostId={centerCostId}
      offers={offers}
      showQuiz={showQuiz}
      groupByOffers={groupByOffers}
      activityType={activityType}
      activityTypes={activityTypes}
      loading={isLoadingListOffers}
      selectedFilter={selectedFilter}
      handleInputChangeDebounced={handleInputChangeDebounced}
      filterHandler={filterHandler}
      handleChange={handleChange}
      selectOffer={selectOffer}
      selectProduct={selectProduct}
      onClickRegistered={onClickRegistered}
      setShowQuiz={setShowQuiz}
    />
  )
}

export default EadContainer
