import React from "react";
import { Menu, MenuItem, Grid, Typography, Icon } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useRedirect } from "@itarget/shared";

import "./style.scss";

export default function LinkStore({ menu, selectOffer }) {
  const [idHover, setIdHover] = useState(0)

  const settingCommerce = useSelector((state) => state.setting.settingCommerce);
  const { centro_custo_id } = settingCommerce;
  const [anchorEl, setAnchorEl] = useState(null);
  const { goTo } = useRedirect()

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }
  useEffect(() => { }, [anchorEl]);

  return (
    <>
      <Link
        aria-owns={anchorEl ? "menuBox" : undefined}
        aria-haspopup="true"
        onMouseOver={handleClick}
        className='link-description'
      >
        <Icon className="link-icon">{menu.icone || 'category'}</Icon>
        <Typography>{menu?.description}</Typography>
      </Link>

      <Menu
        id="menuBox"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          onMouseLeave: handleClose,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem className="menuBox-item">
          <Link
            className='link-description-hover'
          >
            <Icon className="link-icon">{menu.icone || 'category'}</Icon>
            <Typography>{menu?.description}</Typography>
          </Link>
        </MenuItem>
        <div onMouseOver={handleClose} className="menuBox-container">
          {menu?.description.toUpperCase()}
        </div>
        <Grid className="submenuBox-container" spacing={0.5}>
          <Grid className="submenuBox-items" xs={3} style={{ overflowY: "scroll", maxHeight: "300px" }}>
            {menu?.subMenu?.map((subMenu, index) => {
              if (subMenu.product == null) return
              return (
                <Grid onMouseOver={() => setIdHover(index)} onClick={() => goTo(`offers-unique/${centro_custo_id}/${subMenu.id}`)} className="submenuBox-links" item xs={20}>
                  <Typography className="submenuBox-product" variant="body2">
                    {subMenu?.description}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
          <Grid onClick={() => goTo(`offers-unique/${centro_custo_id}/${menu?.subMenu?.[idHover]?.id}`)} className="submenuBox-image">
            <img className="image" src={menu?.subMenu?.[idHover]?.product?.image} alt={menu?.subMenu?.[idHover]?.product?.description} />
          </Grid>
        </Grid>
      </Menu>
    </>
  );
}
