import React, { useContext } from 'react'
import { Grid } from '@material-ui/core'
import { Loading } from '@itarget/shared'
import Layout from '../../../components/Layout'
import DetailSidebar from '../../../components/DetailSidebar/DetailSidebar'
import { SidebarContext } from '../../../context/SidebarContext'
import TabNav from '../../../components/TabNav/TabNav'
import ModalVideoCourse from '../../../components/ModalVideoCourse'
import './style.scss'

const DetailMyLectureView = ({
  tabs,
  date,
  scheduling,
  refPlayer,
  onOpenSidebar,
  cleanDateFilter,
  onOpenAccordion,
  onCloseAccordion,
  onCloseSidebar,
  lesson,
  showVideo,
  speakers,
  roomTabs,
  dateActive,
  roomActive,
  checked,
  selectStep,
  subscriptions,
  onPause,
  onPlay,
  user,
  onProgress,
  onEnded,
  setChecked,
  handleChange,
  participant,
  loadingProgram,
  scheduleType,
  activity_schedule_id,
  roomTabHandleChange,
  dateTabHandleChange,
  filterOnlyVideoActivities,
  handleShowActivitiesWithVideo,
  showActivitiesWithVideo,
  closeShowVideo,
  programing,
  datePrograming,
  selectedFilter,
  setSelectedFilter,
}) => {
  const { open } = useContext(SidebarContext)

  return (
    <>
      <Layout>
        {showVideo && (
          <ModalVideoCourse
            refPlayer={refPlayer}
            onProgress={onProgress}
            onPause={onPause}
            participant={participant}
            lesson={lesson}
            speakers={speakers}
            closeShowVideo={closeShowVideo}
            onPlay={onPlay}
            onEnded={onEnded}
          />
        )}
        <Grid
          container
          spacing={4}>
          <Grid
            item
            container
            xs={open ? 12 : 12}
            sm={open ? 5 : 2}
            md={open ? 4 : 1}
            lg={open ? 3 : 1}
            xl={open ? 3 : 1}>
            <DetailSidebar
              onOpenSidebar={onOpenSidebar}
              onCloseSidebar={onCloseSidebar}
              cleanDateFilter={cleanDateFilter}
              onOpenAccordion={onOpenAccordion}
              onCloseAccordion={onCloseAccordion}
              checked={checked}
              setChecked={setChecked}
              loadingProgram={loadingProgram}
              handleChange={handleChange}
              activity_schedule_id={activity_schedule_id}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              programing={programing}
              datePrograming={datePrograming}
              showActivitiesWithVideo={showActivitiesWithVideo}
              handleShowActivitiesWithVideo={handleShowActivitiesWithVideo}
            />
          </Grid>
          <Grid
            item
            container
            justifyContent='center'
            xs={open ? 12 : 12}
            sm={open ? 7 : 10}
            md={open ? 8 : 11}
            lg={open ? 9 : 11}
            xl={open ? 9 : 11}>
            {loadingProgram ? (
              <Loading />
            ) : (
              <TabNav
                user={user}
                tabs={tabs}
                date={date}
                roomTabs={roomTabs}
                dateActive={dateActive}
                roomActive={roomActive}
                scheduling={scheduling}
                selectStep={selectStep}
                participant={participant}
                scheduleType={scheduleType}
                subscriptions={subscriptions}
                datePrograming={datePrograming}
                roomTabHandleChange={roomTabHandleChange}
                dateTabHandleChange={dateTabHandleChange}
                showActivitiesWithVideo={showActivitiesWithVideo}
                filterOnlyVideoActivities={filterOnlyVideoActivities}
              />
            )}
          </Grid>
        </Grid>
      </Layout>
    </>
  )
}

export default DetailMyLectureView
