import { Icon, IconButton } from '@material-ui/core'
import CartButton from '../../../CartButton'
import UserMenu from '../../../UserMenu'
import LoginButton from '../../../LoginButton'

export const Trailing = ({ showMenu, user, styles, goTo, shoppingCarts, onLogout, statusAssociated, category, toggleMenu }) => {
  let menuIcon = showMenu == 'hide' ? 'view_list' : 'close'
  return (
    <>
      {user?.data ? (
        <>
          <div className={styles.trailingLogged}>
            <CartButton goTo={goTo} shoppingCarts={shoppingCarts} />
            <UserMenu user={user} onLogout={onLogout} goTo={goTo} statusAssociated={statusAssociated} category={category} />
            <IconButton onClick={toggleMenu} className={`view-list-red ${styles.menuButton} menu-icon-button`}>
              <Icon>{menuIcon}</Icon>
            </IconButton>
          </div>
        </>
      ) : (
        <>
          <LoginButton backgroundColor='#2a51c7' />
          <div className={styles.menuButton}>
            <IconButton onClick={toggleMenu} className='menu-icon-button'>
              <Icon>{menuIcon}</Icon>
            </IconButton>
          </div>
        </>
      )}
    </>
  )
}
