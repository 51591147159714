import { Grid, Typography } from '@material-ui/core'
import { Translator } from '@itarget/shared'
import './style.scss'
import { ButtonFooter } from '../ButtonFooter'

const Footer = ({}) => {
  return (
    <Grid className='footer'>
      <Typography className='footer-text'>
        © {new Date().getFullYear()} - Itarget Tecnologia - <Translator transRef='translation.Todos os direitos reservados' />
      </Typography>
      <ButtonFooter />
    </Grid>
  )
}

export default Footer
