import MODULE_NAME from "./constants";

export const SETSETTINGCOMMERCE = `${MODULE_NAME}/SETSETTINGCOMMERCE`;
export const SETENTITY = `${MODULE_NAME}/SETENTITY`;
export const SETCENTERCOST = `${MODULE_NAME}/SETCENTERCOST`;
export const SETSETTINGLOGIN = `${MODULE_NAME}/SETSETTINGLOGIN`;
export const SETISLOADINGSETTINGLOGIN = `${MODULE_NAME}/SETISLOADINGSETTINGLOGIN`;

export const setSettingCommerce = (setting) => ({
  type: SETSETTINGCOMMERCE,
  payload: { setting },
});
export const setEntity = (entity) => ({
  type: SETENTITY,
  payload: { entity },
});
export const setCenterCost = (centerCost) => ({
  type: SETCENTERCOST,
  payload: { centerCost },
});
export const setSettingLogin = (settingLogin) => ({
  type: SETSETTINGLOGIN,
  payload: { settingLogin },
});
export const setIsLoadingSettingLogin = (isLoadingSettingLogin) => ({
  type: SETISLOADINGSETTINGLOGIN,
  payload: { isLoadingSettingLogin },
});
