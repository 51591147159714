import { Grid, Typography, Icon } from "@material-ui/core";
import "./style.scss";
import { useSelector } from "react-redux";
import { formatMoney, Translator } from "@itarget/shared";
import { dateFormatTranslation } from "@itarget/shared/src/utils/format/date";

const CardAlertShoppingCart = ({ card, state, amountProduct }) => {
  const selectedLang = useSelector((state) => state.language.lang)
  return (
    <Grid container className="cardShopping">
      <Grid item xs={2} className="grid-img">
        <img alt="img" src={card?.thumb} />
      </Grid>
      <Grid item xs={10} className="grid-infos">
        <Typography className="title">{card && card.desc_atv}</Typography>
        <Typography className="price">
          {state === "toMyactives" ? <Translator transRef="translation.INSCRIÇÃO GRATUITA PARA ASSOCIADOS" /> : `R$${formatMoney.toReal(amountProduct ? card.valor * amountProduct : card.valor)}`}
        </Typography>
        <Grid className="more-infos">
          <Grid className="infos-item">
            <Icon className="infos-icon">event</Icon>
            <Typography className="infos-text">{dateFormatTranslation(selectedLang, card.data_fim_aga)}</Typography>
          </Grid>
          <Grid className="infos-item">
            <Icon className="infos-icon">room</Icon>
            <Typography className="infos-text">{card && card.desc_tatv}</Typography>
          </Grid>
        </Grid>
        <Typography className="message"> {state === "toMyactives" ? <Translator transRef="translation.INSCRIÇÃO REALIZADA COM SUCESSO" /> : <Translator transRef="translation.Adicionado com sucesso!" /> }</Typography>
      </Grid>
    </Grid>
  );
};

export default CardAlertShoppingCart;
