import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const RouteContainer = ({ isProtected = false, roles = [], ...props }) => {
  const isAutenticated = useSelector((state) => state.auth.isAutenticated);
  const isAutenticating = useSelector((state) => state.auth.isAutenticating);

  if (isProtected && isAutenticating) {
    return <div>loading</div>;
  }

  if (isProtected && !isAutenticating && !isAutenticated) {
    let to = '/';
    if (['ead', 'detail-ead'].includes(window.location.pathname.split('/')[1])) {
      to = '/ead';
    }
    return <Redirect to={to} />
  }

  return <Route {...props} />;
};

export default RouteContainer;
