import { Box, Icon, Avatar, IconButton, Typography } from '@material-ui/core'
import { useTranslation } from './translation'
import { Button } from '@itarget/shared'
import { useStyles } from './styles'
import useAction from './action'
import Menu from './Menu'

const MobileHeader = ({ statusAssociated }) => {
  const translation = useTranslation()
  const DEFAULT_AVATAR = '/assets/images/avatar.jpg'
  const { ref, user, name, menu, images, goTo, displayMenu, handleMenu, handleLogin, handleLogged, handleRegister } = useAction()
  const styles = useStyles({
    logo: !!images.logo,
    backgroundImage: images.background,
    isAssociated: statusAssociated,
    isLogged: user?.id,
  })

  return (
    <header
      ref={ref}
      className={`${styles.container} ${displayMenu ? styles.fullHeight : ''}`}>
      <Box className={styles.leftContainer}>
        <Box
          data-user={user?.uuid}
          onClick={handleLogged}
          className={styles.profileContainer}>
          <Avatar
            alt={user?.nome}
            className={styles.profileImage}
            src={user?.foto || DEFAULT_AVATAR}
          />
          {user?.id && (
            <Box className={styles.profileInfo}>
              <Typography className={styles.profileName}>{name}</Typography>
              <Box className={styles.profileSubInfo}>
                <Typography className={styles.profileStatus}>
                  {user?.categoria_centro_custo_descricao || translation.noCategory}
                </Typography>
                <Typography className={`${styles.profileStatus} ${styles.profileStatusAssociation}`}>
                  {statusAssociated?.status}
                </Typography>
              </Box>
            </Box>
          )}
          {!user?.id && (
            <Box className={styles.profileInfo}>
              <Button
                color='primary'
                onClick={handleLogin}
                className={styles.profileButton}>
                {translation.login}
              </Button>
              <Typography
                onClick={handleRegister}
                className={styles.profileRegister}>
                {translation.register}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box className={styles.rightContainer}>
        {images.logo && (
          <img
            src={images.logo}
            className={styles.logo}
            onClick={() => goTo('')}
            alt={translation.logoAlt}
          />
        )}
        <IconButton
          onClick={handleMenu}
          data-enabled={displayMenu}
          className={styles.menuButton}>
          <Icon className={styles.menuIconButton}>{displayMenu ? 'close' : 'menu'}</Icon>
        </IconButton>
      </Box>
      <Menu
        data={menu}
        enabled={displayMenu}
        handleClose={handleMenu}
      />
    </header>
  )
}

export default MobileHeader
