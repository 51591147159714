import { Box, Typography, Icon } from '@material-ui/core'
import { Translator } from '@itarget/shared'
import { useStyles } from './styles'
import { useState } from 'react'
import Menu from './Menu'
import { Home } from '@material-ui/icons'

const Item = ({ url, title, submenu, menu_order }) => {
  const [displayMenu, setDisplayMenu] = useState(false)
  let father = submenu && submenu.length > 0
  const styles = useStyles({ displayMenu })

  const handleDisplayMenu = (payload) => {
    setDisplayMenu((previous) => (payload && payload?.hasOwnProperty('value') ? payload.value : !previous))
  }

  const handleOnClick = () => {
    if (father) {
      handleDisplayMenu()
      return
    }

    let isInternal = url.startsWith('/')
    if (isInternal) {
      window.location.assign(url)
    } else {
      window.open(url)
    }
  }

  return (
    <Box
      className={styles.itemContainer}
      onClick={displayMenu ? undefined : handleOnClick}>
      {title.includes('fa-home') ? (
       <Typography
       data-father={father}
       className={styles.itemTitle}>
       <Translator transRef={'Home'} />
     </Typography>
      ) : (
        <Typography
          data-father={father}
          className={styles.itemTitle}>
          {title}
        </Typography>
      )}
      {father && <Icon className={styles.itemIcon}>expand_more</Icon>}
      {father && (
        <Menu
          title={title}
          data={submenu}
          enabled={displayMenu}
          handleClose={handleDisplayMenu}
        />
      )}
    </Box>
  )
}

export default Item
