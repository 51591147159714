import React, { useEffect } from "react";

const MuxPlayer = ({ id }) => {
  useEffect(() => {
    if (!customElements.get("mux-player")) {
      const script = document.createElement("script");
      script.src = "https://cdn.jsdelivr.net/npm/@mux/mux-player";
      script.onload = () => {
        console.log("Mux Player script loaded.");
      };
      document.head.appendChild(script);
    }
  }, []);

  return (
    <mux-player
      stream-type="live"
      playback-id={id}
      controls
      autoplay
    ></mux-player>
  );
};

export default MuxPlayer;
