import { Link, useParams } from "react-router-dom";
import { Translator, RTag } from "@itarget/shared";
import { useSelector } from "react-redux";
import "./style.scss";
import { Typography } from "@material-ui/core";

const LinkComponentStore = ({ offers }) => {
  const settingCommerce = useSelector((state) => state.setting.settingCommerce);
  const { centro_custo_id } = settingCommerce;
  const routeParams = useParams();
  const { activity_id } = routeParams;
  return (
    <div>
      {offers && activity_id ? (
        Object.entries(offers).map((atividade, id) => {
          const { centro_custo_id_out, atv_id } = atividade[1][0];
          return (
            <div key={id} className="breadScrumb">
              <RTag show="desktop">
                <Typography className="breadScrumb-link">&gt; {atividade[0]}</Typography>
              </RTag>
              <Link to={`/offers-unique/${centro_custo_id_out}/${atv_id}`} className="breadScrumb-link last">
                &gt; {atividade[1][0]?.desc_atv}
              </Link>
            </div>
          );
        })
      ) : (
        <div className="breadScrumb">
          <Link to={`/offers-unique/${centro_custo_id}`} className="breadScrumb-link">
            &gt; <Translator transRef={`translation.TODOS OS PRODUTOS`} />
          </Link>
        </div>
      )}
    </div>
  );
};

export default LinkComponentStore;
