import React from "react";
import Toast from "../Toast";
import "./style.scss";

const Toaster = ({ toasts = [], onClose }) => {
  return (
    <div className={"container-toast"}>
      {toasts?.slice(-3).map((toast) => (
        <>
          {toast?.content && Object.values(toast?.content).length > 0 && (
            <Toast
              key={toast?.id}
              id={toast?.id}
              content={toast?.content}
              severity={toast?.severity}
              onClose={onClose}
            />
          )}
        </>
      ))}
    </div>
  );
};

export default Toaster;