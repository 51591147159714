import {format as formatFns} from 'date-fns'

export const ptBr = (value) => {
  if (!value) {
    return "";
  }

  let part = value.split("-");
  const dt = new Date(part[0], part[1] - 1, part[2]);
  return (
    (dt.getDate() < 10 ? "0" : "") +
    dt.getDate() +
    "/" +
    (dt.getMonth() + 1 < 10 ? "0" : "") +
    (dt.getMonth() + 1) +
    "/" +
    dt.getFullYear()
  );
};
export const hour = (hour) => {
  var hour_ = (hour && hour.split(":")) || "";
  return hour ? `${hour_[0]}:${hour_[1]}` : null;
};

export const stringToDate = (dateString) => {
  let dateParam = dateString.split(/[\s-/:]/)
  dateParam[1] = (parseInt(dateParam[1], 10) - 1).toString()
  return new Date(...dateParam)
}

export const format = (dateToBeFormatted, dateFormattedFor) => {
  return formatFns(stringToDate(dateToBeFormatted), dateFormattedFor)
}

export const getDay = (value) => {
  if (!value) {
    return "";
  }

  let part = value.split("-");
  return part[2];
};

export const monthInFull = (month) => {
  if (!month) {
    return "";
  }

  month = month.split("-")[1];

  const months = new Array(
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
  );
  return months[month - 1];
};

export const dateFormatTranslation = (locales, date) => {
  if(!date) return '';
  const dt = date.split('-');
  return Intl.DateTimeFormat(locales == 'en' ? 'en-US' : 'pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(dt[0], (dt[1] - 1), dt[2], 0, 0, 0, 0))
}

export const dateFormatToWeekday = (date) => {
  if(!date) return
  var part = date.split('-');
  var data = new Date(part[0], (part[1] - 1), part[2], 0, 0, 0, 0);
  return data.toLocaleString([], { weekday: 'long'})
}

export default {
  ptBr,
  hour,
  format,
  stringToDate,
  monthInFull,
  dateFormatToWeekday,
  dateFormatTranslation,
  getDay,
};
