import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    gap: '3%',
    width: '100%',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    height: 'fit-content',
    justifyContent: 'center',
    padding: theme.spacing(1),
    boxShadow: theme.shadows[1],
    backgroundImage: ({ background }) => `url(${background})`,
  },
  fullHeight: {
    height: '100vh !important',
  },
  menuReturnButton: {
    display: 'flex',
    cursor: 'pointer',
    gap: theme.spacing(2),
    alignContent: 'center',
    padding: theme.spacing(1, 2.5),
  },
  leftContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  profileContainer: {
    display: 'flex',
    borderRadius: 12,
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    cursor: ({ isLogged }) => (isLogged ? 'pointer' : 'auto'),
  },
  profileImage: {
    width: theme.typography.pxToRem(45),
    height: theme.typography.pxToRem(45),
  },
  profileInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    flexDirection: 'column',
    padding: theme.spacing(0.5, 0),
  },
  profileButton: {
    width: '100%',
  },
  profileName: {
    width: 100,
    ...theme.typography.limitNumberOfLines(),
    ...theme.typography.caption,
    textWrap: 'nowrap',
    fontWeight: 700,
    lineHeight: 1,
  },
  profileStatus: {
    width: 100,
    ...theme.typography.limitNumberOfLines(),
    ...theme.typography.caption,
    textWrap: 'nowrap',
    lineHeight: 1.1,
  },
  profileStatusAssociation: {},
  profileRegister: {
    ...theme.typography.limitNumberOfLines(),
    ...theme.typography.caption,
    textWrap: 'nowrap',
    lineHeight: 1.1,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  rightContainer: {
    flex: 2,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    justifyContent: ({ logo }) => (logo ? 'space-between' : 'flex-end'),
  },
  logo: {
    maxHeight: 80,
    aspectRatio: 2,
    cursor: 'pointer',
    objectFit: 'contain',
    objectPosition: 'left',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      maxHeight: '10vw',
    },
  },
  menuButton: {
    padding: 0,
    // '&[data-enabled="true"]': {
    //   position: 'absolute',
    //   top: '20%',
    //   right: '4%',
    //   zIndex: '1200',
    // },
  },
  menuIconButton: {
    color: 'inherit',
    textAlign: 'center',
    fontSize: 'x-large !important',
  },
  menuContainer: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    position: 'fixed',
    overflowY: 'auto',
    pointerEvents: 'none',
    gap: theme.spacing(1),
    flexDirection: 'column',
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2, 0),
    transition: 'opacity 0.2s ease-in-out',
    opacity: ({ enabled }) => (enabled ? 1 : 0),
    backgroundColor: theme.palette.background.default,
    zIndex: ({ level }) => theme.zIndex.appBar + level,
    '&[data-enabled="true"]': {
      pointerEvents: 'auto',
    },
  },
  itemContainer: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    padding: theme.spacing(1, 2.5),
    justifyContent: 'space-between',
    cursor: ({ displayMenu }) => (displayMenu ? 'auto' : 'pointer'),
  },
  itemTitle: {
    color: 'inherit',
    textDecoration: 'none',
    ...theme.typography.button,
  },
  itemIcon: {
    color: 'inherit',
    textAlign: 'center',
    fontSize: 'x-large !important',
  },
}))
