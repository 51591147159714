const mobile = {
    android: function () {
        return navigator.userAgent.match(/Android/i);
    },
    blackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    windows: function () {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function () {
        return (mobile.android() || mobile.blackBerry() || mobile.iOS() || mobile.opera() || mobile.windows());
    }
};

export default mobile;