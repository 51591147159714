import React from 'react'
import { Grid, TextField, Typography, makeStyles, MenuItem, Box, Select, Chip } from '@material-ui/core'
import { Translator, Button, useQueryParams } from '@itarget/shared'
import { useSelector } from 'react-redux'
import queryString from 'query-string'
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  datePicker: {
    width: '100%',
  },
  filterButton: {
    marginBottom: theme.spacing(0.5),
  },
  searchBox: {
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.mainShowcase.items.backgroundColor,
    padding: theme.spacing(3, 2.5),
    borderRadius: theme.shape.borderRadius,
  },
  SearchTopBarContainer: {
    marginBottom: theme.spacing(3),
    '& .MuiSelect-select': {
      ...theme.typography.caption,
      paddingTop: '10.5px',
      paddingBottom: '10.5px',
    },
    '& input': {
      colorScheme: (props) => props.theme,
    },
  },
  searchField: {
    flex: 1,
  },
  select: {
    height: '40px',
  },
  selectLabel: {
    ...theme.typography.caption,
    ...theme.typography.limitNumberOfLines(1),
  },
}))

const SearchTopBar = ({
  formik,
  handleInputChangeDebounced,
  selectedFilter,
  activityTypes,
  axis,
  tags,
  filterByRelevantAxis = true,
  filterByPeriod = true,
}) => {
  const settingCommerce = useSelector((state) => state.setting.settingCommerce)
  const { get } = useQueryParams()
  let tagSelected = get('tag_id')
  tagSelected = tagSelected ? tagSelected.split(',') : []
  tagSelected = tagSelected.map((value) => Number(value))

  let activityTypeSelected = get('type_id')
  activityTypeSelected = activityTypeSelected ? activityTypeSelected.split(',') : []
  activityTypeSelected = activityTypeSelected.map((value) => Number(value))

  let filterTypeSetup = settingCommerce?.json_filtros
  let hasFilters = false
  let filterSetup = null
  if (filterTypeSetup) {
    filterByPeriod = JSON.parse(filterTypeSetup)
    Object.keys(filterByPeriod).length > 0 && (hasFilters = true)
    filterSetup = filterByPeriod.filters
  }
  let filtersSetupRules = {
    subjectSpeakers: filterSetup?.hasOwnProperty('subjectSpeakers') ? filterSetup.subjectSpeakers : false,
    relevantAxis: filterSetup?.hasOwnProperty('relevantAxis') ? filterSetup.relevantAxis : false,
    activityType: filterSetup?.hasOwnProperty('activityType') ? filterSetup.activityType : false,
    datePeriod: filterSetup?.hasOwnProperty('datePeriod') ? filterSetup.datePeriod : false,
    tag: filterSetup?.hasOwnProperty('tag') ? filterSetup.tag : false,
  }
  let emptyFilter = Object.values(filtersSetupRules).every((value) => !value)

  const systemTheme = useSelector((state) => state.theme.systemTheme)
  const translation = {
    search: Translator({ transRef: 'translation.Pesquisar...' }),
    all: Translator({ transRef: 'translation.Todos' }),
  }
  const classes = useStyles({ theme: systemTheme })

  if (!filterTypeSetup || !hasFilters || emptyFilter) return <></>

  const RenderSelectMultiple = (select, selectedValues) => {
    let values = select
      .filter(({ id }) => selectedValues.includes(id))
      .map(({ description, descricao }) => description || descricao)
      .join(', ')
    return <Typography className={classes.selectLabel}>{values}</Typography>
  }

  const RenderTagsSelectMultiple = (selectedValues) => {
    return RenderSelectMultiple(tags, selectedValues)
  }

  const RenderActivityTypesSelectMultiple = (selectedValues) => {
    return RenderSelectMultiple(activityTypes, selectedValues)
  }

  return (
    <Grid
      item
      className={classes.SearchTopBarContainer}
      container
      spacing={1}>
      <Box
        className={classes.searchBox}
        width='100%'>
        <form
          onSubmit={formik.handleSubmit}
          className={classes.root}>
          <Grid
            alignItems='flex-end'
            spacing={3}
            container>
            {filtersSetupRules.subjectSpeakers && (
              <Grid
                item
                className={classes.searchField}>
                <Typography variant='caption'>
                  <Translator transRef='translation.Tema/Palestrante' />
                </Typography>
                <TextField
                  id='desc_atv'
                  variant='outlined'
                  fullWidth
                  placeholder={translation.search.props.children}
                  size='small'
                  defaultValue={selectedFilter?.desc_atv || ''}
                  name='desc_atv'
                  onKeyUp={(event) => handleInputChangeDebounced(event.target.value)}
                />
              </Grid>
            )}
            {filtersSetupRules.activityType && (
              <Grid
                item
                xs={12}
                md={2}>
                <Typography variant='caption'>
                  <Translator transRef='translation.Tipo de atividade' />
                </Typography>
                <Select
                  name='activity_type'
                  variant='outlined'
                  fullWidth
                  multiple
                  className={classes.select}
                  onChange={formik.handleChange}
                  renderValue={RenderActivityTypesSelectMultiple}
                  defaultValue={activityTypeSelected || []}>
                  {activityTypes?.map((option, index) => (
                    <MenuItem
                      key={option.id + index}
                      value={option.id}>
                      <span>{option.description}</span>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            )}
            {filterByRelevantAxis && filtersSetupRules.relevantAxis && (
              <Grid
                item
                xs={12}
                md={2}>
                <Typography variant='caption'>
                  <Translator transRef='translation.Eixo' />
                </Typography>
                <TextField
                  select
                  name='eixo_id'
                  variant='outlined'
                  fullWidth
                  size='small'
                  defaultValue={''}
                  onChange={formik.handleChange}>
                  <MenuItem
                    key={0}
                    value=''>
                    <span>{translation.all}</span>
                  </MenuItem>
                  {axis?.map((option, index) => (
                    <MenuItem
                      key={option.id + index}
                      value={option.id}>
                      <span>{option.descricao}</span>
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            {filtersSetupRules.tag && (
              <Grid
                item
                xs={12}
                md={2}
                container
                direction='column'>
                <Typography variant='caption'>
                  <Translator transRef='translation.Classificação' />
                </Typography>
                <Select
                  name='tag'
                  variant='outlined'
                  fullWidth
                  multiple
                  className={classes.select}
                  onChange={formik.handleChange}
                  renderValue={RenderTagsSelectMultiple}
                  defaultValue={tagSelected || []}>
                  {tags?.map((option, index) => (
                    <MenuItem
                      key={option.id + index}
                      value={option.id}>
                      <span>{option.description}</span>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            )}
            {filterByPeriod && filtersSetupRules.datePeriod && (
              <Grid
                item
                xs={12}
                md={4}
                container
                direction='column'>
                <Typography variant='caption'>
                  <Translator transRef='translation.Período' />
                </Typography>
                <Grid
                  item
                  container
                  spacing={3}>
                  <Grid
                    xs={6}
                    item>
                    <TextField
                      className={classes.datePicker}
                      name='data_inicio_aga'
                      type='date'
                      onChange={formik.handleChange}
                      variant='outlined'
                      size='small'
                      id='data_inicio_aga'
                    />
                  </Grid>
                  <Grid
                    xs={6}
                    item>
                    <TextField
                      className={classes.datePicker}
                      name='data_fim_aga'
                      type='date'
                      onChange={formik.handleChange}
                      variant='outlined'
                      size='small'
                      id='data_fim_aga'
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid
              item
              className={classes.filterButton}
              xs={12}
              md={1}
              style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Button
                type='submit'
                color='primary'
                variant='contained'>
                <Translator transRef='translation.Filtrar' />
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Grid>
  )
}

export default SearchTopBar
