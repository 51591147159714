import { Card, CardContent, Typography, Grid, Icon, Button, TextField } from "@material-ui/core"
import ShoppingBasket from "@material-ui/icons/ShoppingBasket"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import "./style.scss"
import { useRedirect, formatMoney, ModalCategoryReceipt, Translator } from "@itarget/shared"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { formatString } from "@itarget/shared"
import ModalAnnuityDescribe from "../ModalAnnuityDescribe"
import TypographyValue from "../Layout/Typography/TypographyValue"
import { subscription } from "../../redux"
import ReactInputMask from "react-input-mask"
import TextFieldNumber from "../TextFieldNumber/TextFieldNumber"

const CardPriceStore = ({
  redirectSubscribe,
  addShoppingCart,
  state,
  detailing,
  openModalReceipt,
  setOpenModalReceipt,
  cc,
  verificandoSeTemEmEstoqueParaVenda,
  payment,
  toast,
  status_comprovante_categoria,
  comp_recusa,
  annuity,
  totalAnnuity,
  value,
  amountProduct,
  amountProductChange,
}) => {
  const { goTo } = useRedirect()
  const [open, setOpen] = useState(false)
  const user = useSelector((state) => state.auth.user)

  const ConditionalButtons = () => {
    const dispatch = useDispatch()

    const actionStream = async (card) => {
      await dispatch(subscription.actions.setTransmissionActivity(card))
      goTo("streaming")
    }
    return (
      <div style={{ height: "100%" }}>
        {state === "toShoppingCart" && (
          <div className="information-detail">
            <Button startIcon={<Icon>shopping_cart</Icon>} className="btn-goToShopping" onClick={() => goTo("shopping-cart")}>
              <Translator transRef="translation.ir para o carrinho" />
            </Button>
          </div>
        )}
        {state === "pending" && (
          <>
            <Button
              endIcon={<Icon>{detailing.gerou_cobranca !== null ? "format_list_bulleted" : "shopping_cart"}</Icon>}
              className="btn-goToShopping"
              onClick={() => {
                goTo(detailing.gerou_cobranca !== null ? "my-activity" : "shopping-cart")
              }}
            >
              <Translator transRef="translation.ir para o carrinho" />
            </Button>
          </>
        )}
        {state === "toMyactives" && (
          <>
            <Grid className="grid-flex">
              <div className="statusBlock">
                <Icon className="icon-green">done_outline</Icon>
                <Typography className="assistant-text text-green">
                  <Translator transRef="translation.Inscrito" />
                </Typography>
              </div>
            </Grid>
            <Button endIcon={<Icon className="icon-green">list</Icon>} className="btn-goMyactives" onClick={() => goTo("my-activity")}>
              <Translator transRef="translation.minhas atividades" />
            </Button>
          </>
        )}
        {state === "toWatch" && (
          <>
            {detailing.status_inscricao === 2 && detailing.qtde_aulas_video > 0 && (
              <Button
                startIcon={<Icon className="icon-green">playlist_play</Icon>}
                className="btn-goMyactives"
                onClick={() => goTo(`my-course/${detailing.aga_id}`)}
              >
                <strong>
                  <Translator transRef="translation.On Demand" />
                </strong>
              </Button>
            )}

            {detailing.status_inscricao === 2 && detailing.disponivel_transmissao === "S" && (
              <Button
                startIcon={<Icon className="icon-green">cast_for_education</Icon>}
                className="btn-goMyactives"
                onClick={() => actionStream(detailing)}
              >
                <strong>
                  <Translator transRef="translation.Ao vivo" />
                </strong>
              </Button>
            )}
          </>
        )}
        {state === "detail" && detailing && detailing.cortesia === "S" ? (
          <>
            <Button
              startIcon={<Icon>create</Icon>}
              className="btn-subscribe"
              onClick={() =>
                redirectSubscribe(
                  detailing.qtde_aulas_video > 0 || detailing.disponivel_transmissao === "S" ? "toWatch" : "toMyactives"
                )
              }
            >
              <Translator transRef="translation.inscreva-se" />
            </Button>
          </>
        ) : (
          (state === "detail" && detailing && detailing.status_inscricao === 2 && detailing.qtde_aulas_video < 1 && (
            <>
              <Grid className="grid-info" style={{ color: "#008A05", fontWeight: "bold" }}>
                <Icon className="info-icon" style={{ color: "#008A05", fontWeight: "bold" }}>
                  done
                </Icon>
                <Typography className="info-text">
                  <Translator transRef="translation.INSCRITO" />
                </Typography>
              </Grid>
              <Button
                endIcon={<Icon>format_list_bulleted</Icon>}
                className="btn-my-activity"
                onClick={() => {
                  goTo("my-activity")
                }}
              >
                <Translator transRef="translation.MINHAS ATIVIDADES" />
              </Button>
            </>
          ),
            state === "detail" && detailing && detailing.status_inscricao === 2 && detailing.qtde_aulas_video > 0 && (
              <>
                <Button
                  startIcon={<Icon>playlist_play</Icon>}
                  className="btn-my-activity"
                  onClick={() => {
                    goTo("my-course/" + detailing.aga_id)
                  }}
                >
                  <strong>
                    <Translator transRef="translation.On Demand" />
                  </strong>
                </Button>
              </>
            ),
            state === "detail" && detailing && detailing.status_inscricao === 1 && (
              <>
                <Grid className="grid-info" style={{ color: "#F29100", fontWeight: "bold" }}>
                  <Icon className="info-icon" style={{ color: "#F29100", fontWeight: "bold" }}>
                    watch_later
                  </Icon>
                  <Typography className="info-text">
                    <Translator transRef="translation.PENDENTE" />
                  </Typography>
                </Grid>
                <Button
                  endIcon={<Icon>{detailing.gerou_cobranca !== null ? "format_list_bulleted" : "shopping_cart"}</Icon>}
                  className="btn-shopping-card"
                  onClick={() => {
                    goTo(detailing.gerou_cobranca !== null ? "my-activity" : "shopping-cart")
                  }}
                >
                  {detailing.gerou_cobranca !== null ? (
                    <Translator transRef="translation.MINHAS ATIVIDADES" />
                  ) : (
                    <Translator transRef="translation.IR PARA O CARRINHO" />
                  )}
                </Button>
              </>
            ),
            state === "detail" && detailing && detailing.status_inscricao !== 2 && detailing.status_inscricao !== 1 && (
              <div className="information-detail">
                {!detailing?.cortesia && (
                  <>
                    <Typography className="detail-associated" variant="h4">
                      {value ? (
                        "R$" + formatMoney.toReal(value * amountProduct)
                      ) : (
                        <Translator transRef="translation.Valor não cadastrado." />
                      )}
                    </Typography>
                  </>
                )}
                <section className="detail-delivery">
                  <TextFieldNumber
                    amount={amountProduct}
                    amountChange={amountProductChange}
                    max={detailing?.vagas_limite_insc_mesma_pessoa}
                  />
                  <Typography className="delivery-inventory">
                    <ShoppingBasket />
                    <span
                      style={{
                        color: "#0086ff",
                        fontWeight: "900",
                        lineHeight: "2.6",
                      }}
                    >
                      {verificandoSeTemEmEstoqueParaVenda ? (
                        <Translator transRef="translation.Em estoque" />
                      ) : (
                        <Translator transRef="translation.Falta de produtos" />
                      )}
                    </span>
                  </Typography>
                  {/* <Typography className="delivery-cep">
                    <LocationOnIcon />
                    <ReactInputMask mask="99999-999" maskChar="#">
                      {() => (
                        <TextField
                          size="small"
                          placeholder="Insira o seu CEP"
                        />
                      )}
                    </ReactInputMask>
                  </Typography> */}
                  {/* <Typography className="delivery-promotion">
                    <LocalShippingIcon />
                    <div>
                      <span>
                        Receba <span>grátis</span> em
                      </span>{" "}
                      07 de Agosto
                    </div>
                  </Typography> */}
                </section>
                {state !== "pending" && (
                  <Button
                    className="btn-subscribe"
                    style={{
                      background: "#0086ff",
                      fontSize: "0.8rem",
                    }}
                    onClick={() => redirectSubscribe("toPayment")}
                  >
                    <Translator transRef="translation.COMPRAR AGORA" />
                  </Button>
                )}

                {!(detailing?.qtde_anuidade_pendente > 0) && (
                  <Button
                    className="btn-shopping"
                    style={{
                      background: "black",
                      fontSize: "0.8rem",
                    }}
                    onClick={addShoppingCart}
                  >
                    <Translator transRef="translation.ADICIONAR AO CARRINHO" />
                  </Button>
                )}
              </div>
            ))
        )}
      </div>
    )
  }

  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {openModalReceipt && (
        <ModalCategoryReceipt
          centerCostId={cc}
          status_comprovante_categoria={status_comprovante_categoria}
          comp_recusa={comp_recusa}
          open={openModalReceipt}
          setOpen={setOpenModalReceipt}
          toast={toast}
          payment={payment}
        />
      )}
      <ModalAnnuityDescribe annuity={annuity} handleClose={handleClose} open={open} />
      <Card className="cardPrice">
        <CardContent className="cardPrice-content" style={{ height: "100%", padding: "0" }}>
          {state !== "inPaymentWithAnnuity" && (
            <div className="cardPrice-information">
              {detailing?.cortesia === "S" ? (
                <>
                  {/* <Typography className="title-cortesia">
                    {" "}
                    INSCRIÇÃO GRATUITA
                  </Typography>
                  <Typography className="subtitle-cortesia">
                    CLIQUE EM INSCREVA-SE PARA CONFIRMAR
                  </Typography> */}
                </>
              ) : (
                <div>
                  {state !== "toShoppingCart" && state !== "pending" && detailing?.valor && (
                    <TypographyValue
                      align="center"
                      size="lg"
                      style={{ color: "#0086ff", fontWeight: "900" }}
                      value={formatMoney.toReal(detailing?.valor * amountProduct)}
                    />
                  )}
                </div>
              )}
              <ConditionalButtons />
            </div>
          )}

          {detailing?.qtde_anuidade_pendente > 0 && state !== "inPayment" && (
            <Grid className="grid-annuity">
              {state !== "inPaymentWithAnnuity" && (
                <Typography className="title-divider">
                  <Translator transRef="translation.OU" />
                </Typography>
              )}
              <Typography
                className="title"
                style={{
                  fontSize: "3rem",
                  color: "#0086ff",
                  fontWeight: "900",
                }}
              >
                {detailing?.valor &&
                  "R$" +
                  formatMoney.toReal(
                    detailing.valor_socio_quite ? detailing.valor_socio_quite * amountProduct : detailing.valor * amountProduct
                  )}
              </Typography>
              <Typography variant="body2">
                {user?.data?.nome && formatString.toLowerCase(user.data.nome)},{" "}
                <Translator
                  transRef="translation.aproveite para colocar a anuidade em dia e ganhe um
                desconto exclusivo."
                />
              </Typography>
              <Grid className="resume-price">
                <Typography onClick={handleOpen} className="text-annuity text-left tiny-size">
                  {detailing?.qtde_anuidade_pendente > 1 ? (
                    <Translator transRef="translation.Anuidades pendentes" />
                  ) : (
                    <Translator transRef="translation.Anuidade pendente" />
                  )}
                </Typography>
                <Typography className="tiny-size">
                  {detailing?.valor_anuidade_pendente && "R$" + formatMoney.toReal(detailing.valor_anuidade_pendente)}
                </Typography>
              </Grid>
              <Grid className="resume-price">
                <Typography className="text-left tiny-size">
                  <Translator transRef="translation.Curso" />
                </Typography>
                <Typography className="tiny-size">
                  {detailing?.valor &&
                    "R$" +
                    formatMoney.toReal(
                      detailing.valor_socio_quite ? detailing.valor_socio_quite * amountProduct : detailing.valor * amountProduct
                    )}
                </Typography>
              </Grid>
              <Grid className="resume-price">
                <Typography className="text-left text-total">
                  {detailing?.qtde_anuidade_pendente > 1 ? "Anuidades" : "Anuidade"} + <Translator transRef="translation.Curso" />
                </Typography>
                <Typography className="text-total">{"R$" + formatMoney.toReal(totalAnnuity * amountProduct)}</Typography>
              </Grid>
              {state !== "inPaymentWithAnnuity" && (
                <Button
                  startIcon={<Icon>create</Icon>}
                  className="btn-goToShopping"
                  onClick={() => redirectSubscribe("inPaymentWithAnnuity")}
                >
                  <Translator transRef="translation.pague agora" />
                </Button>
              )}
            </Grid>
          )}
        </CardContent>
      </Card>
    </div>
  )
}

export default CardPriceStore
