import { Grid, Icon, Typography, makeStyles } from "@material-ui/core";
import { Translator, formatTime } from '@itarget/shared'
import { useEffect, useState } from "react";

const TimerShoppingCart = ({ standardTime }) => {
    const [timer, setTimer] = useState(standardTime * 60);
    const styles = useStyles();
    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimer(prevTimer => prevTimer - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [standardTime]);

    useEffect(() => {
        if (timer === 0) {
            window.location.reload();
        }
    }, [timer]);
    return (
        <Grid container
            alignItems='center'
            justifyContent='flex-end'
            spacing={1}>
            <Grid item>
                <Icon className={styles.shoppingCartIcon}>timer</Icon>
            </Grid>
            <Grid item>
                <Typography variant='h5'>
                    <Translator transRef={`translation.Tempo para atualização`} /> <span>{formatTime.secondsToHour(timer)}</span>
                </Typography>
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles(() => ({
    shoppingCartIcon: {
        fontSize: '1.6rem !important',
    },
}))

export default TimerShoppingCart;