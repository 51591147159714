import { useState } from 'react'
import { Translator, Button } from '@itarget/shared'
import { Grid } from '@material-ui/core'
import DownloadComplementary from '../DowloadComplementary/DownloadComplementary'
import './style.scss'

const ComplementaryFile = ({ files }) => {
  const [openStatus, setOpenStatus] = useState(false)

  const changeStatus = () => setOpenStatus((prev) => !prev)

  return (
    <Grid item container direction='column'>
      <DownloadComplementary
        handleClose={changeStatus}
        openStatus={openStatus}
        files={files}
      />
      <Button
        variant='contained'
        color='twilight'
        onClick={() => changeStatus()}
        icon={openStatus ? 'folder_open' : 'folder'}>
        <Translator transRef={'translation.ARQUIVOS'} />
      </Button>
    </Grid>
  )
}

export default ComplementaryFile
