import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    overflowX: 'auto',
    position: 'relative',
    scrollbarWidth: 'none',
    scrollSnapType: 'both mandatory',
    '&::-webkit-scrollbar': {
      height: 0,
    },
    '& > div[data-last="true"]': {
      scrollSnapAlign: 'start',
    },
  },
  arrowContainer: {
    top: 0,
    zIndex: 10,
    height: '100%',
    display: 'flex',
    cursor: 'pointer',
    position: 'sticky',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: theme.typography.pxToRem(166),
    visibility: ({ enableScroll }) => (enableScroll ? 'visible' : 'hidden'),
  },
  arrowLeft: {
    left: 0,
    background: ({ backgroundColor }) =>
      `linear-gradient(to left, transparent, ${theme.palette.getColorBySystemTheme(
        backgroundColor.light,
        backgroundColor.dark
      )} 100%)`,
  },
  arrowRight: {
    right: 0,
    background: ({ backgroundColor }) =>
      `linear-gradient(to right, transparent, ${theme.palette.getColorBySystemTheme(
        backgroundColor.light,
        backgroundColor.dark
      )} 100%)`,
  },
  arrow: {
    paddingLeft: theme.typography.pxToRem(5),
    fontSize: `${theme.typography.pxToRem(48)} !important`,
  },
}))
