import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ListItem } from "@material-ui/core";

export default function MenuSingleMobile() {
  const [menu, setMenu] = useState([
    {
      description: "Home",
      url: "/",
    },
  ]);

  const settingCourse = useSelector((state) => state.setting.settingCommerce);

  useEffect(() => {
    try {
      if (settingCourse?.json_menu) {
        setMenu(JSON.parse(settingCourse.json_menu));
      }
    } catch (e) { }
  }, [settingCourse]);

  return (
    <>
      {menu.map((m) => (
        <ListItem>
          <Link to={m.url}>{m.description}</Link>
        </ListItem>
      ))}
    </>
  );
}
