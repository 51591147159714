import { Button } from '@itarget/shared'
import { Box, CircularProgress, Icon, Typography } from '@material-ui/core'
import { useSoldOutStyles } from './styles'
import { useSoldOutTranslation } from './translation'

const SoldOut = ({ onWaitingList = () => {}, enableWaitingList = false, isLoading = false }) => {
  const styles = useSoldOutStyles()
  const translation = useSoldOutTranslation()
  return (
    <Box className={styles.container}>
      <Box className={styles.messageContainer}>
        <Box className={styles.soldOutAlert}>
          <Box className={styles.alertIconContainer}>
            <Icon className={styles.alertIcon}>block</Icon>
          </Box>
          <Typography className={styles.alertMessage}>{translation.soldOut}</Typography>
        </Box>
        <Typography className={styles.message}>{translation.messageSoldOut}</Typography>
        {enableWaitingList && <Typography className={styles.message}>{translation.messageWaitingList}</Typography>}
      </Box>
      {enableWaitingList && (
        <Button
          onClick={onWaitingList}
          disabled={isLoading}
          icon={isLoading ? '' : 'done'}
          className={`${styles.button} ${styles.buttonOnWaitingList}`}>
          {isLoading ? (
            <CircularProgress
              size={20}
              color='inherit'
            />
          ) : (
            translation.waitingList
          )}
        </Button>
      )}
    </Box>
  )
}

export default SoldOut
