import { Grid, CardMedia, Typography, Tooltip } from "@material-ui/core"
import Icon from "@material-ui/core/Icon"
import { dateFormatTranslation } from "@itarget/shared/src/utils/format/date"
import { useSelector } from "react-redux"
import { BtnAssistir, BtnInscrito, BtnPendente } from "../BtnsActions/BtnsActions"

import "./style.scss"
const CardOffers = ({ card, onClick, width }) => {
  const selectedLang = useSelector((state) => state.language.lang)
  return (
    <Grid style={width && { width: width }}>
      <CardMedia
        style={!onClick ? { cursor: "default" } : {}}
        className="cardOffers-container"
        image={card && card.imagem_lista ? card.imagem_lista : ""}
        onClick={() => onClick && onClick(card)}
        title={card && card.desc_atv}
      >
        <div>
          <Tooltip title={card?.desc_atv} aria-label={card?.desc_atv}>
            <Grid className="cardOffers-title">
              <Typography>{card && card.desc_atv}</Typography>
            </Grid>
          </Tooltip>
          <Grid className="cardOffers-infos">
            {card?.exibir_programacao_horario === "S" && card?.data_fim_aga && (
              <div className="infos-item">
                <Icon className="infos-icon">event</Icon>
                <Typography className="infos-text">
                  {dateFormatTranslation(selectedLang, card?.data_inicio_aga) !==
                    dateFormatTranslation(selectedLang, card?.data_fim_aga) &&
                    `${dateFormatTranslation(selectedLang, card?.data_inicio_aga)} - `}
                  {dateFormatTranslation(selectedLang, card?.data_fim_aga)}
                </Typography>
              </div>
            )}
            {/* <div className="infos-item">
              <Icon className="infos-icon">room</Icon>
              <Typography className="infos-text">
                {card && card.desc_tatv}
              </Typography>
            </div> */}
            {card && card.status_inscricao !== undefined && card.status_inscricao !== null && card.status_inscricao !== "" && (
              <div className="float-right">
                {card.status_inscricao === 1 && <BtnPendente />}
                {card.status_inscricao === 2 && <>{card.qtde_aulas_video > 0 ? <BtnAssistir /> : <BtnInscrito />}</>}
              </div>
            )}
          </Grid>
        </div>
      </CardMedia>
    </Grid>
  )
}

export default CardOffers
