import { Icon, IconButton, makeStyles } from '@material-ui/core'
import { useWindowDimensions } from '@itarget/shared'
import LoginButton from '../../LoginButton'
import MenuC from '../../MenuC'
import SocialIcons from '../../SocialIcons'
import CartButton from '../../CartButton'
import UserMenu from '../../UserMenu'
import MobileHeader from '../../../MobileHeader'
import { useEffect, useState } from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    height: '100px',
  },
  iconcart: {
    width: '32px',
    height: '32px',
  },
  container: {
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'space-between',
    height: '80px',
  },
  logo: {
    maxWidth: '100%',
    maxHeight: '100%',
    display: 'flex',
    '@media (max-width:800px)': {
      width: '80%',
    },
  },
  trailing: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(4),
  },
  leading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
  },
  menu: {
    height: '30px',
  },
  menuItem: {
    textTransform: 'uppercase',
  },
  menuButton: {
    '@media (min-width: 950px)': {
      display: 'none',
    },
  },
}))

const Header = ({
  showMenu,
  toggleMenu,
  logo,
  backgroundHeader,
  client,
  menuItemsC = [],
  shoppingCarts,
  isLoadingMenuData,
  user,
  goTo,
  onLogout,
  statusAssociated,
  category,
}) => {
  const MAX_MENU_SIZE_DESKTOP = 950
  const { width: windowDimensions } = useWindowDimensions()
  const [mobile, setMobile] = useState(false)
  const styles = useStyles()
  const dataSocialMedias = {
    amib: [
      { id: 1, icon: 'facebook', url: 'https://www.facebook.com/amiboficial' },
      { id: 2, color: '#33A0E9', icon: 'twitter', url: 'https://twitter.com/AMIB_oficial' },
      { id: 3, color: '#2578B5', icon: 'linkedin', url: 'https://br.linkedin.com/company/amib-oficial' },
      { id: 4, color: '#FA2200', icon: 'youtube', url: 'https://www.youtube.com/channel/UCu17CupKThcViiKWra-Zigg' },
    ],
  }
  useEffect(() => {
    let mobile = windowDimensions <= MAX_MENU_SIZE_DESKTOP
    mobile ? setMobile(true) : setMobile(false)
  }, [windowDimensions])

  return (
    <>
      {!mobile && (
        <header className={styles.root}>
          <div
            className={styles.container}
            style={{ backgroundImage: `url(${backgroundHeader})` }}>
            <div className={styles.leading}>
              <img
                className={styles.logo}
                alt={client}
                src={logo}
              />
            </div>
            <div className={styles.trailing}>
              {user?.data ? (
                <>
                  <CartButton
                    goTo={goTo}
                    shoppingCarts={shoppingCarts}
                  />
                  <div>
                    <IconButton
                      onClick={toggleMenu}
                      className={`view-list-red ${styles.menuButton}`}>
                      <Icon>view_list</Icon>
                    </IconButton>
                  </div>

                  <UserMenu
                    user={user}
                    onLogout={onLogout}
                    goTo={goTo}
                    statusAssociated={statusAssociated}
                    category={category}
                  />
                </>
              ) : (
                <>
                  <LoginButton />
                  <div className={styles.menuButton}>
                    <IconButton onClick={toggleMenu}>
                      <Icon>view_list</Icon>
                    </IconButton>
                  </div>
                  <div>
                    <SocialIcons socialMedias={dataSocialMedias} />
                  </div>
                </>
              )}
            </div>
          </div>

          <MenuC
            items={menuItemsC}
            isLoadingMenuData={isLoadingMenuData}
            classes={{ root: styles.menu, itemRoot: styles.menuItemRoot }}
            showMenu={showMenu}
          />
        </header>
      )}
      {mobile && <MobileHeader statusAssociated={statusAssociated} />}
    </>
  )
}

export default Header
