import { Button, makeStyles, Icon, IconButton } from '@material-ui/core'
import MenuC from '../../MenuC'
import { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Trailing } from './2/Trailing'
import { Translator } from '@itarget/shared'
import MapIcon from '@material-ui/icons/Map'
import MailIcon from '@material-ui/icons/Mail'
import PhoneIcon from '@material-ui/icons/Phone'
import EditIcon from '@material-ui/icons/Edit'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { useLocation, useParams } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  light: {
    '& #menu-desktop a.li-link': {
      color: '#444 !important',
    },
    '& #menu-desktop li:last-child a.li-link': {
      color: '#444',
    },
    '& #menu-desktop li ul': {
      backgroundColor: '#ffffff',
      borderTop: '3px solid #13AFF0',
    },
    '@media (min-width: 1161px)': {
      '& #menu-desktop .submenu-1 li:hover > a': {
        backgroundColor: '#fff',
      },
    },
    '@media (max-width: 1160px)': {
      '& #menu-desktop ul': {
        backgroundColor: '#ffffff',
      },
      '& #menu-desktop ul.menu li:last-child': {
        '-webkit-box-shadow': '0px 34px 54px 0px #00000042',
        '-moz-box-shadow': '0px 34px 54px 0px #00000042',
        boxShadow: '0px 34px 54px 0px #00000042',
      },
    },
  },
  dark: {
    '& #menu-desktop a': {
      color: '#f3f3f3 !important',
    },
    '& #menu-desktop li ul': {
      backgroundColor: '#0f0f0f',
    },
    '@media (min-width: 1161px)': {
      '& #menu-desktop .submenu-1 li:hover > a': {
        backgroundColor: '#000',
      },
    },
    '@media (max-width: 1160px)': {
      '& #menu-desktop ul': {
        backgroundColor: '#0f0f0f',
      },

      '& #menu-desktop ul.menu li:last-child': {
        '-webkit-box-shadow': '0px 34px 54px 0px #ffffff40',
        '-moz-box-shadow': '0px 34px 54px 0px #ffffff40',
        boxShadow: '0px 34px 54px 0px #ffffff40',
      },
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '@media (max-width: 500px)': {
      height: '270px',

      '& #menu-desktop.show': {
        top: '180px !important',
      },
    },
    '@media (max-width: 359px)': {
      height: '300px',
    },
  },
  iconcart: {
    width: '32px',
    height: '32px',
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    marginTop: '20px',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80%',
    '@media (max-width: 740px)': {
      marginTop: '1%',
    },
    '@media (max-width: 500px)': {
      flexDirection: 'column',
      height: '150px',
    },
    '@media (max-width: 359px)': {
      height: '160px',
    },
  },
  logo: {
    zIndex: 1,
    maxHeight: '65px',
    '@media (max-width: 500px)': {
      maxHeight: '50px',
    },
  },
  trailingLogged: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: theme.spacing(1.5, 0),
    '& .user-information-block': {
      color: 'inherit',
      '& .MuiListItemAvatar-root': {},
    },
    '& .shoppingCartIcon': {
      color: (props) => (props.theme == 'light' ? '#000' : '#fff'),
    },
  },
  trailing: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  leading: {
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 740px)': {
      padding: '0',
    },
  },
  menuItem: {
    textTransform: 'uppercase',
  },
  menuButton: {
    zIndex: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .material-icons': {
      color: (props) => (props.theme == 'light' ? '#000' : '#fff'),
    },
    '@media (min-width: 1160px)': {
      display: 'none',
    },
    '& .MuiIconButton-root': {
      fontSize: '28px',
    },
  },
  headerSection: {
    backgroundColor: '#0072BC',
    width: '100%',
    position: 'relative',
  },
  headerElementsContainer: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '1200px',
    minHeight: '120px',
    position: 'relative',
    '@media (max-width: 1026px)': {
      justifyContent: 'space-between',
    },
    '@media (max-width: 359px)': {
      flexDirection: 'column',
    },
  },
  entityData: {
    width: '58%',
    display: 'flex',
    alignItems: 'center',
    minHeight: '1px',
    '@media (max-width: 1026px)': {
      display: 'none',
    },
  },
  entityDataContainer: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px',
    position: 'relative',
    flexWrap: 'wrap',
    width: '100%',
  },
  entityDataItem: {
    width: 'auto',
  },
  entityDataLabelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  entityDataLabelTitle: {
    fontSize: '15px',
    fontWeight: '700',
    lineHeight: '1px',
    color: '#FFFFFF',
  },
  entityDataLabelIcon: {
    marginRight: '5px',
    marginTop: '10px',
    fontSize: '18px',
    color: '#FFFFFF',
  },
  entityDataValue: {
    color: '#FFFFFF',
    fontSize: '15px',
  },
  buttonsContainer: {
    width: '43%',
    position: 'relative',
    minHeight: '1px',
    justifyContent: 'flex-end',
    display: 'flex',
    '@media (max-width: 1026px)': {
      width: '100%',
      justifyContent: 'center',
    },
  },
  buttons: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    paddingRight: '10px',
    position: 'relative',
    width: 'auto',
    flexWrap: 'wrap',
    '@media (max-width: 359px)': {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  buttonItem: {
    fontSize: '16px',
    marginLeft: '10px',
    color: '#0072BC',
    backgroundColor: '#FCC010',
    '&:hover': {
      backgroundColor: '#FFD256',
    },
    '&.MuiButton-root': {
      padding: '0 20px 0 20px',
    },
  },
  buttonText: {
    marginTop: '12px',
  },
}))

const Header = ({
  logo,
  client,
  shoppingCarts,
  menuItemsC = [],
  isLoadingMenuData,
  user,
  goTo,
  onLogout,
  statusAssociated,
  category,
}) => {
  const MAX_MENU_SIZE_DESKTOP = 1160
  const windowSize = useRef(window.innerWidth)
  const [showMenu, setShowMenu] = useState(window.innerWidth <= MAX_MENU_SIZE_DESKTOP ? 'hide' : 'show')
  let menuIcon = showMenu == 'hide' ? 'view_list' : 'close'

  useEffect(() => {
    const defineMenuStatus = () => {
      setShowMenu((prevStatus) => {
        if (windowSize.current == window.innerWidth) return prevStatus
        return window.innerWidth <= MAX_MENU_SIZE_DESKTOP ? 'hide' : 'show'
      })
    }
    window.addEventListener('resize', defineMenuStatus)
  }, [window.innerWidth, setShowMenu, MAX_MENU_SIZE_DESKTOP, windowSize.current])

  const [imageBackground, setImageBackground] = useState(null)
  const systemTheme = useSelector((state) => state.theme.systemTheme)
  const settingCourse = useSelector((state) => state.setting.settingCommerce)
  const location = useLocation()
  const { cc } = useParams()
  const shouldRenderBanner = location.pathname === '/offers' || location.pathname === `/offers-unique/${cc}`

  let backgroundImageColors = {
    top: systemTheme == 'light' ? '#fafafa00' : '#1C1C1C00',
    bottom: systemTheme == 'light' ? '#fafafafd' : '#1C1C1Cff',
  }

  useEffect(() => {
    try {
      if (settingCourse.url_image_topo_background) setImageBackground(settingCourse.url_image_topo_background)
    } catch (e) {}
  }, [settingCourse])
  const styles = useStyles({
    backgroundImageColors,
    imageBackground,
    shouldRenderBanner,
    showMenu: showMenu != 'hide',
    theme: systemTheme,
  })

  function toggleMenu() {
    if (showMenu === 'hide') {
      setShowMenu('show')
    } else {
      setShowMenu('hide')
    }
  }

  return (
    <>
      <header className={`${styles.root} ${styles[systemTheme]}`}>
        <div className={styles.container}>
          <section className={styles.headerSection}>
            <div className={styles.headerElementsContainer}>
              <div className={styles.entityData}>
                <div className={styles.entityDataContainer}>
                  <div className={styles.entityDataItem}>
                    <div className={styles.entityDataLabelContainer}>
                      <MapIcon className={styles.entityDataLabelIcon} />
                      <h3 className={styles.entityDataLabelTitle}>{'ENDEREÇO'}</h3>
                    </div>
                    <p className={styles.entityDataValue}>{'R. Gonçalves Ledo, 1630 Centro - Fortaleza/CE'}</p>
                  </div>
                  <div className={styles.entityDataItem}>
                    <div className={styles.entityDataLabelContainer}>
                      <MailIcon className={styles.entityDataLabelIcon} />
                      <h3 className={styles.entityDataLabelTitle}>{'E-MAIL'}</h3>
                    </div>
                    <p className={styles.entityDataValue}>{'abo@abo-ce.org.br'}</p>
                  </div>
                  <div className={styles.entityDataItem}>
                    <div className={styles.entityDataLabelContainer}>
                      <PhoneIcon className={styles.entityDataLabelIcon} />
                      <h3 className={styles.entityDataLabelTitle}>{'CONTATO'}</h3>
                    </div>
                    <p className={styles.entityDataValue}>{'+55 85 9 9146.0398'}</p>
                  </div>
                </div>
              </div>
              <div className={styles.buttonsContainer}>
                <div className={styles.buttons}>
                  <Button
                    className={styles.buttonItem}
                    variant='contained'
                    href='https://icase.aboce.itarget.com.br/estacao/cadastro/index/evento/1'
                    startIcon={<EditIcon />}>
                    <p className={styles.buttonText}>
                      <Translator transRef='translation.Clique aqui' />
                    </p>
                  </Button>
                  <Button
                    className={styles.buttonItem}
                    variant='contained'
                    href='https://abo-ce.org.br/area-do-associado/'
                    startIcon={<AccountCircleIcon />}>
                    <p className={styles.buttonText}>
                      <Translator transRef='translation.Área do associado' />
                    </p>
                  </Button>
                </div>
              </div>
              <div className={styles.menuButton}>
                <IconButton onClick={toggleMenu} className='menu-icon-button'>
                  <Icon>{menuIcon}</Icon>
                </IconButton>
              </div>
            </div>
          </section>
          <div className={styles.header}>
            <div className={styles.leading}>
              {logo && <img className={`${styles.logo}`} alt={client} src={logo} />}
              <MenuC
                items={menuItemsC}
                isLoadingMenuData={isLoadingMenuData}
                classes={{ root: styles.menu, itemRoot: styles.menuItemRoot }}
                showMenu={showMenu}
              />
            </div>
            <div className={styles.trailing}>
              <Trailing
                showMenu={showMenu}
                category={category}
                goTo={goTo}
                onLogout={onLogout}
                shoppingCarts={shoppingCarts}
                statusAssociated={statusAssociated}
                styles={styles}
                toggleMenu={toggleMenu}
                user={user}
              />
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
