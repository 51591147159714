import { formatTime, Translator, Button } from '@itarget/shared'
import { Avatar, Box, Typography } from '@material-ui/core'
import TypographyTooltip from '../TypographyTooltip'
import { useLectureItemStyles } from './styles'
import { AvatarGroup } from '@material-ui/lab'
import SpeakerItem from './SpeakerItem'
import { useContext, useState } from 'react'
import AllSpeakers from './AllSpeakers'
import { toast as toastRedux } from '../../redux'
import { useDispatch } from 'react-redux'
import { useTranslation } from './translation'
import { ProgrammingContext } from '../../context/ProgrammingContext'

const LectureItem = ({ data, lastItem, defaultOpened = false }) => {
  const dispatch = useDispatch()
  const translation = useTranslation()
  const { handleDisplayVideo } = useContext(ProgrammingContext)
  const [displayAllSpeakers, setDisplayAllSpeakers] = useState(defaultOpened)
  let maxSpeakers = 4
  const styles = useLectureItemStyles({ enableWatchButton: data.speakerVideoLink })
  let startAt = data.startAt ? formatTime.format(data.startAt) : ''
  let endAt = data.endAt ? formatTime.format(data.endAt) : undefined

  const handleDisplayAllSpeakers = () => {
    setDisplayAllSpeakers((prevStatus) => !prevStatus)
  }

  const handleWatchLecture = () => {
    if (!data.isRegistered) {
      return dispatch(
        toastRedux.actions.enqueue({
          content: translation.videoPresentationExclusive,
          severity: 'warning',
        })
      )
    }
    handleDisplayVideo(data)
  }

  return (
    <Box
      data-last={lastItem || undefined}
      className={`lecture ${styles.container}`}>
      <Box className={styles.information}>
        {startAt && (
          <Box className={styles.date}>
            <Typography className={styles.dateText}>{`${startAt}${endAt && endAt != startAt ? ` - ${endAt}` : ''}`}</Typography>
          </Box>
        )}
        {data.title && (
          <TypographyTooltip
            numberOfLines={data.speakers.length == 0 ? 0 : 2}
            className={styles.title}>
            {data.title}
          </TypographyTooltip>
        )}
      </Box>
      {data.speakers.length == 1 && (
        <Box className={styles.uniqueSpeaker}>
          <SpeakerItem
            data={data.speakers[0]}
            displayBackgroundColor={false}
          />
        </Box>
      )}
      {data.speakers.length > 1 && !displayAllSpeakers && (
        <Box
          className={styles.speakers}
          onClick={handleDisplayAllSpeakers}>
          <AvatarGroup
            className={styles.avatar}
            max={maxSpeakers}>
            {data.speakers.map(({ id, name, image }, index) => {
              return (
                <Avatar
                  alt={name}
                  src={image}
                  key={`${index}_${id}_${name}`}
                />
              )
            })}
          </AvatarGroup>
          <Typography className={styles.speakersText}>
            <Translator transRef='Ver palestrantes' />
          </Typography>
        </Box>
      )}
      {data.speakers.length > 1 && displayAllSpeakers && (
        <AllSpeakers
          data={data.speakers}
          handleClickSpeaker={handleDisplayAllSpeakers}
        />
      )}
      <Button
        color='primary'
        icon='play_circle'
        className={styles.button}
        onClick={handleWatchLecture}
        disabled={!data.speakerVideoLink}>
        <Translator transRef={data.speakerVideoLink ? 'Assistir palestra' : 'Gravação indisponível'} />
      </Button>
    </Box>
  )
}

export default LectureItem
