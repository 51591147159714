import React, { useContext } from 'react'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import MenuRounded from '@material-ui/icons/MenuRounded'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import { Translator, Button } from '@itarget/shared'
import { Grid, TextField, Typography, makeStyles } from '@material-ui/core'
import { useFormik } from 'formik'
import { SidebarContext } from '../../context/SidebarContext'
import DetailAccordion from '../DetailAccordion/DetailAccordion'
import './style.scss'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  sidebarContainerStyle: {
    backgroundColor: (props) => (props.theme == 'light' ? '#fff' : '#121212'),
    boxShadow: theme.shadows[1],
    height: 'fit-content',
    width: '100%',
    '& p, & span, & label, & input[value], & .MuiSelect-select': {
      ...theme.typography.caption,
      textTransform: 'lowercase',
    },
    '& p::first-letter, & span::first-letter, & label::first-letter, & input[value]::first-letter, & .MuiSelect-select::first-letter':
      {
        textTransform: 'uppercase',
      },
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  formStyle: {
    width: '100%',
  },
  formContainerStyle: {
    padding: theme.spacing(2),
  },
  textFieldStyle: {
    width: '100%',
  },
}))

const DetailSidebar = ({
  checked,
  programing,
  cleanDateFilter,
  onOpenAccordion,
  onCloseAccordion,
  selectedFilter,
  onOpenSidebar,
  onCloseSidebar,
  handleChange,
  loadingProgram,
  datePrograming,
  setSelectedFilter,
  handleChangeFilter,
  showActivitiesWithVideo,
  handleShowActivitiesWithVideo,
}) => {
  const systemTheme = useSelector((state) => state.theme.systemTheme)
  const onSubmit = async (values) => {
    setSelectedFilter({ ...selectedFilter, ...values })
  }
  const { open } = useContext(SidebarContext)
  const styles = useStyles({ theme: systemTheme, sidebarOpen: open })

  const formik = useFormik({
    initialValues: selectedFilter,
    enableReinitialize: true,
    onSubmit,
  })

  const translation = {
    search: Translator({ transRef: 'translation.Pesquisar...' }),
  }

  return (
    <Grid
      container
      alignItems='center'
      justifyContent='center'
      className={styles.sidebarContainerStyle}
      item>
      <form
        className={styles.formStyle}
        onSubmit={formik.handleSubmit}>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems={open ? 'flex-end' : 'center'}
          spacing={2}
          className={styles.formContainerStyle}>
          <Grid item>
            {!open ? (
              <IconButton onClick={() => onOpenSidebar()}>
                <MenuRounded />
              </IconButton>
            ) : (
              <IconButton onClick={() => onCloseSidebar()}>
                <ChevronLeftIcon />
              </IconButton>
            )}
          </Grid>
          {open ? (
            <>
              <Grid
                item
                direction='column'
                container>
                <Grid item>
                  <Typography>
                    <Translator transRef='translation.Buscar por' />
                  </Typography>
                </Grid>

                <Grid
                  item
                  container>
                  <TextField
                    id='desc_atv'
                    variant='outlined'
                    className={styles.textFieldStyle}
                    placeholder={translation.search.props.children}
                    size='small'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton type='submit'>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    defaultValue={selectedFilter?.atividade?.titulo || ''}
                    name='descricao'
                    onChange={formik.handleChange}
                    autoFocus
                  />
                </Grid>
              </Grid>
              <Grid
                spacing={1}
                container
                item>
                <Grid item>
                  <Typography>
                    <Translator transRef='translation.Busca avançada' />
                  </Typography>
                </Grid>
                <DetailAccordion
                  formik={formik}
                  checked={checked}
                  programing={programing}
                  handleChange={handleChange}
                  datePrograming={datePrograming}
                  selectedFilter={selectedFilter}
                  loadingProgram={loadingProgram}
                  onOpenAccordion={onOpenAccordion}
                  cleanDateFilter={cleanDateFilter}
                  onCloseAccordion={onCloseAccordion}
                  handleChangeFilter={handleChangeFilter}
                  showActivitiesWithVideo={showActivitiesWithVideo}
                  handleShowActivitiesWithVideo={handleShowActivitiesWithVideo}
                />
              </Grid>
            </>
          ) : (
            <Grid item>
              <IconButton
                size='medium'
                onClick={() => onOpenSidebar()}>
                <SearchIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </form>
    </Grid>
  )
}

export default DetailSidebar
