import Layout from "../../../components/Layout";
import { Grid, Breadcrumbs, Button } from "@material-ui/core";
import { Loading, Translator } from "@itarget/shared";
import { Alert } from "@material-ui/lab";
import "./style.scss";
import { useSelector } from "react-redux";
import MuxPlayer from "../../../components/MuxPlayer";

const StreamingView = ({
  urlChat,
  url,
  detail,
  text,
  dateHour,
  handleClickGoRoom,
  zoom,
}) => {
  const translation = {
    waitTransmission: Translator({
      transRef: "translation.Aguarde enquanto carregamos a transmissão!",
    }),
    accessTheRoom: Translator({ transRef: "translation.Acessar a sala!" }),
  };
  const user = useSelector((state) => state.auth.user);
  const dataNow = new Date();
  const ano = dataNow.getFullYear();
  const mes = String(dataNow.getMonth() + 1).padStart(2, "0");
  const dia = String(dataNow.getDate()).padStart(2, "0");
  const horas = String(dataNow.getHours()).padStart(2, "0");
  const minutos = String(dataNow.getMinutes()).padStart(2, "0");
  const segundos = String(dataNow.getSeconds()).padStart(2, "0");
  const diffDate =
    new Date(`${ano}-${mes}-${dia} ${horas}:${minutos}:${segundos}`) -
    new Date(dateHour);

  if (zoom) {
    return <></>;
  }
  return (
    <Layout>
      <Grid container>
        {detail && (
          <Breadcrumbs style={{ marginTop: 15, padding: 8 }} separator="›">
            {[
              <div
                onClick={() => window.history.back()}
                style={{ cursor: "pointer", fontWeight: "bold" }}
              >
                <Translator transRef="translation.Ofertas" />
              </div>,
              <div style={{ fontSize: 12 }}>
                {detail?.desc_atv || detail?.desc_tatv}
              </div>,
            ]}
          </Breadcrumbs>
        )}

        {[2, 8, 9].includes(detail?.tipo_transmissao) &&
          (diffDate > 360000 || diffDate < 0) && (
            <Alert severity="error">
              <div>
                Caso o zoom apareça a mensagem token expirado verifique sua
                data/hora e configure para definir horario automatico
              </div>
            </Alert>
          )}

        <Grid container className="grid-containerDetail content-transmissao">
          {![9].includes(detail?.tipo_transmissao) && (
            <Grid
              item
              md={[2, 8].includes(detail?.tipo_transmissao) ? 12 : 9}
              sm={12}
              xs={12}
              className="player-wrapper"
            >
              {url !== "" &&
                (![11].includes(detail?.tipo_transmissao) ? (
                  <>
                    {detail.tipo_transmissao == 12 ? (
                      <div
                        style={{ position: "relative", paddingTop: "56.25%" }}
                      >
                        <iframe
                          id="panda-6c697665-92bb-47ef-8ea3-e3f0091e1c90"
                          className="react-player"
                          src={url}
                          style={{
                            border: "none",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100vh",
                            zIndex: "1 ",
                          }}
                          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture"
                          allowFullScreen
                          fetchpriority="high"
                        ></iframe>
                      </div>
                    ) : (
                      <iframe
                        className="react-player"
                        src={url}
                        frameBorder="0"
                        allowFullScreen="yes"
                        sandbox="allow-forms allow-scripts allow-same-origin"
                        allow="autoplay; fullscreen;camera *;microphone *"
                        title="video"
                        style={{ height: "100vh", zIndex: "1" }}
                        width={"100%"}
                        height={"100%"}
                      ></iframe>
                    )}
                  </>
                ) : (
                  <Loading text={translation.waitTransmission} />
                ))}
              {[13].includes(detail?.tipo_transmissao) && (
                <MuxPlayer
                  id={detail?.token_transmissao?.split("|")[0] || null}
                />
              )}
            </Grid>
          )}
          {[2, 8].includes(detail?.tipo_transmissao) && (
            <Grid md={12} sm={12} xs={12} className="p">
              <p>
                Olá {user.data.nome.split(" ")[0]},
                <br />
                <br />
                É com imenso prazer que te recebemos na sala Zoom. Agradecemos
                por você estar aqui conosco para assistir à transmissão.
                <br />
                <br />
                Para entrar na transmissão e aproveitar ao máximo a experiência:
                <br />
                <br />
                Clique no botão azul "Acessar a Sala". Você será direcionado
                para a plataforma Zoom, onde poderá entrar na sala e assistir à
                palestra. Verifique seu áudio e vídeo. Certifique-se de que seus
                dispositivos de áudio e vídeo estão funcionando corretamente
                para que você possa aproveitar ao máximo a palestra. Estamos
                ansiosos para te ver e compartilhar este momento especial com
                você!
                <br />
                <br />
                Dúvidas?
                <br />
                <br />
                Em caso de dúvidas ou dificuldades para acessar a sala, nossa
                equipe de suporte está à disposição para te ajudar. Entre em
                contato conosco através do canal de suporte.
                <br />
                <br />
                Obrigado por participar!
              </p>
              <Button
                onClick={handleClickGoRoom}
                variant="contained"
                color="primary"
              >
                {translation.accessTheRoom}
              </Button>
            </Grid>
          )}
          {text && (
            <Grid item md={9} sm={12} xs={12}>
              {text && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                ></div>
              )}
            </Grid>
          )}
          {![2, 8].includes(detail?.tipo_transmissao) && (
            <Grid item md={3} sm={12} xs={12} className="status-chat">
              <Grid className="grid-course">
                <Grid className="chat">
                  {urlChat !== "" && (
                    <iframe
                      src={urlChat}
                      title="chat"
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                      allowFullScreen="yes"
                      height={"100%"}
                    ></iframe>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default StreamingView;
