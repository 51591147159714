import ReactDOM from 'react-dom'
import { StrictMode } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { StylesProvider } from '@material-ui/core/styles'
import AppContainer from './containers/AppContainer'
import { createReduxStore } from './redux'
import './index.scss'
import SidebarContextProvider from './context/SidebarContext'
import MenuStoreContextProvider from './context/MenuStoreContext'

const { store, persistor } = createReduxStore()
ReactDOM.render(
  <StrictMode>
    <MenuStoreContextProvider>
      <SidebarContextProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <StylesProvider injectFirst>
              <AppContainer />
            </StylesProvider>
          </PersistGate>
        </Provider>
      </SidebarContextProvider>
    </MenuStoreContextProvider>
  </StrictMode>,
  document.getElementById('root')
)
