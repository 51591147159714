import { useEffect, useState } from "react";
import { useFetchApi, Config } from "@itarget/shared";
import StreamingView from "./views/StreamingView";
import { useDispatch, useSelector } from "react-redux";
import { subscription, toast } from "../../redux";
import { KJUR, KEYUTIL } from "jsrsasign";
import mobile from "../../utils/mobile";
import { ZoomMtg } from "@zoom/meetingsdk";

const StreamingContainer = () => {
  const dispatch = useDispatch();
  const [url, setUrl] = useState("");
  const [urlChat, setUrlChat] = useState("");
  const [activityScheduleId, setActivityScheduleId] = useState("");
  const [configuration, setConfiguration] = useState({});
  const [text, setText] = useState("");
  const [zoom, setZoom] = useState();
  const [websocketReload, setWebsocketReload] = useState(false);
  const transmissionActivity = useSelector(
    (state) => state.subscription.transmissionActivity
  );
  const user = useSelector((state) => state.auth.user);
  const selectedLang = useSelector((state) => state.language.lang);
  const settingCommerce = useSelector((state) => state.setting.settingCommerce);
  const centerCostId = useSelector((state) => state.auth.cc);

  const { fetchData: fecthPostTracking } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/icongressolive/tracking`,
    "POST"
  );
  const { data: dataDateHour, fetchData: fetchDateHour } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/utility/date-hour`,
    "GET"
  );

  const { data: showRoomRequest, fetchData: fetchListShowRoom } = useFetchApi(
    `${Config.baseURL}api/${selectedLang}/webinar/show-room`,
    "POST"
  );

  const { data: setting, fetchData: fetchSetting } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/setting?centro_custo_id=${centerCostId}&codigo[]=932`
  );

  const {
    data: listOffers,
    fetchData: fetchListOffers,
  } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/activity-schedule/offer?agenda_atividade_id=${activityScheduleId}&pessoa_id=${user.data.id}`
  );

  const getSignature = (meetingNumber, key, secret) => {
    const iat = Math.round(new Date().getTime() / 1000) - 30;
    const exp = iat + 60 * 60 * 2;
    const oHeader = { alg: "HS256", typ: "JWT" };

    const oPayload = {
      sdkKey: key,
      appKey: key,
      mn: meetingNumber,
      role: 0,
      iat: iat,
      exp: exp,
      tokenExp: exp,
    };

    const sHeader = JSON.stringify(oHeader);
    const sPayload = JSON.stringify(oPayload);
    const sdkJWT = KJUR.jws.JWS.sign("HS256", sHeader, sPayload, secret);
    return sdkJWT;
  };

  function handleClickGoRoom(e) {
    e.preventDefault();
    const signature = getSignature(
      configuration.keyRoom,
      configuration.key,
      configuration.secret
    );
    createZoom(
      configuration.keyRoom,
      configuration.secretRoom,
      configuration.key,
      configuration.secret,
      signature
    );
    setZoom(true);
  }

  function websocket() {
    const ws = new WebSocket(
      "wss://fbg.websocket.itarget.com.br/app/fbg?protocol=7&client=js&version=8.0.2&flash=false"
    );
    const subscriptionMessage = {
      event: "pusher:subscribe",
      data: {
        channel: "live",
      },
    };

    ws.addEventListener("open", () => {
      ws.send(JSON.stringify(subscriptionMessage));
    });

    ws.addEventListener("message", function (event) {
      const data = JSON.parse(event.data);

      if (data.event === "Live") {
        setWebsocketReload(true);
        fetchListOffers();
      }
    });
  }

  const createZoom = (meetingNumber, password, key, secret, signature) => {
    ZoomMtg.init({
      leaveUrl: "https://" + window.location.hostname,
      patchJsMedia: true,
      disableInvite: true,
      meetingInfo: [],
      success: () => {
        ZoomMtg.join({
          signature: signature,
          sdkKey: key,
          meetingNumber: meetingNumber,
          passWord: password,
          userName: user.data.nome,
          userEmail: user.data.email,
          tk: "",
          zak: "",
        });
      },
    });
  };

  useEffect(() => {
    if (activityScheduleId) {
      websocket();
    }
  }, [activityScheduleId]);

  useEffect(() => {
    if (mobile.iOS() && settingCommerce?.app_redirect_ios == "S") {
      window.location = settingCommerce?.app_link_redirect_ios;
    }
    if (mobile.android() && settingCommerce?.app_redirect_android == "S") {
      window.location = settingCommerce?.app_link_redirect_android;
    }
    if (activityScheduleId) {
      fetchListOffers();
    }
    fetchDateHour();
    fetchSetting();
  }, [selectedLang]);

  useEffect(() => {
    if (listOffers?.data?.length) {
      dispatch(
        subscription.actions.setTransmissionActivity(listOffers.data[0])
      );
    }
  }, [listOffers]);

  useEffect(() => {
    if (transmissionActivity && setting) {
      setActivityScheduleId(transmissionActivity.aga_id);
      var token = transmissionActivity?.token_transmissao?.split("|");
      switch (transmissionActivity?.tipo_transmissao) {
        case 2:
        case 8:
          ZoomMtg.preLoadWasm();
          ZoomMtg.prepareWebSDK();
          const zoomDiv = document.getElementById("zmmtg-root");
          if (zoomDiv) {
            zoomDiv.parentNode.removeChild(zoomDiv);
          }
          let configZoom = setting?.data[0]?.valor_referencia?.split("|");
          if (configZoom.length < 2) {
            dispatch(
              toast.actions.enqueue({
                content: "Configurador de integração do zoom não configurado!",
                severity: "error",
              })
            );
            break;
          }
          if (token.length < 2) {
            dispatch(
              toast.actions.enqueue({
                content: "Sala não configurada para transmissão",
                severity: "error",
              })
            );
            break;
          }
          setConfiguration({
            keyRoom: token[0],
            secretRoom: token[1],
            key: configZoom[0],
            secret: configZoom[1],
          });
          break;
        case 4:
          setUrl(`https://vimeo.com/event/${token[0]}/embed/${token[1]}`);
          if (!websocketReload) {
            setUrlChat(`https://vimeo.com/event/${token[0]}/chat/${token[1]}`);
          }
          break;
        case 6:
          setUrl(`https://www.youtube.com/embed/${token[0]}`);
          setUrlChat(
            `https://www.youtube.com/live_chat?v=${token[0]}&embed_domain=${window.location.hostname}`
          );
          break;
        case 9:
          setText(transmissionActivity?.texto_sala_extena);
          break;
        case 10:
          setUrl(
            `https://embed-videos.netshow.me/t/${token[0]}?jwt=${token[1]}`
          );
          break;
        case 11:
          fetchListShowRoom(
            JSON.stringify({
              token: token[1],
              webnar_id: token[0],
              iid: transmissionActivity.sala_local_id,
              nome: user.data.nome,
              email: user.data.email,
            })
          );
          break;
        case 12:
          setUrl(
            `https://player-vz-ea49e2c4-a90.tv.pandavideo.com.br/embed/?v=${token[0]}&isLive=true`
          );
          if (!websocketReload) {
            setUrlChat(`https://vimeo.com/event/${token[1]}/chat/${token[2]}`);
          }
          break;
        case 13:
          setUrl("");
          if (!websocketReload) {
            setUrlChat(`https://vimeo.com/event/${token[1]}/chat/${token[2]}`);
          }
          break;
        default:
          break;
      }
    }

    fecthPostTracking(
      JSON.stringify({
        pessoa_id: user.data.id,
        local_acesso_ctc_id: 10,
        centro_custo_id: transmissionActivity?.centro_custo_id_out
          ? transmissionActivity.centro_custo_id_out
          : transmissionActivity.centro_custo_id,
        agenda_atividade_id: transmissionActivity?.aga_id
          ? transmissionActivity.aga_id
          : transmissionActivity.agenda_atividade_id,
        sala_local_id: transmissionActivity.sala_local_idioma_id,
        meta_data: { user_agent: window.navigator.userAgent },
      })
    );

    if (settingCommerce?.tempo_ativo_sala) {
      setInterval(() => {
        fecthPostTracking(
          JSON.stringify({
            pessoa_id: user.data.id,
            local_acesso_ctc_id: 13,
            centro_custo_id: transmissionActivity?.centro_custo_id_out
              ? transmissionActivity.centro_custo_id_out
              : transmissionActivity.centro_custo_id,
            agenda_atividade_id: transmissionActivity?.aga_id
              ? transmissionActivity.aga_id
              : transmissionActivity.agenda_atividade_id,
            sala_local_id: transmissionActivity.sala_local_idioma_id,
          })
        );
      }, settingCommerce?.tempo_ativo_sala * 1000);
    }
  }, [
    transmissionActivity,
    fecthPostTracking,
    fetchListShowRoom,
    settingCommerce?.tempo_ativo_sala,
    setting?.data,
    user.data.email,
    user.data.id,
    user.data.nome,
  ]);

  useEffect(() => {
    window.open(showRoomRequest?.data, "_self");
  }, [showRoomRequest]);

  const onStart = () => {};

  const onDuration = (e) => {};

  const onProgress = (e) => {};

  const onEnded = () => {};

  return (
    <StreamingView
      url={url}
      dateHour={dataDateHour?.data}
      urlChat={urlChat}
      text={text}
      onStart={onStart}
      onDuration={onDuration}
      onProgress={onProgress}
      onEnded={onEnded}
      detail={transmissionActivity}
      handleClickGoRoom={handleClickGoRoom}
      zoom={zoom}
    />
  );
};

export default StreamingContainer;
