import {
  Container,
  Grid,
  Typography,
  Icon,
  TextField,
  Link,
  CircularProgress,
  LinearProgress,
} from "@material-ui/core";
import { Translator, Button } from "@itarget/shared";
import "./style.scss";
import Footer from "../../../components/Footer/Footer";

const RecoveryLoginView = ({
  formik,
  state,
  isLoading,
  isLoadingService,
  errorLogin,
  setState,
  settingCommerce,
}) => {
  const SendSucess = () => {
    return (
      <Grid item xs={12} className="sendSucess">
        <Typography className="text">
          <Translator transRef="translation.Recuperação de senha enviada para o email com sucesso." />
        </Typography>
        <Typography className="text">
          <Translator transRef="translation.Em instantes, confira sua caixa de entrada para prosseguir com a
          atualização da senha." />
        </Typography>
        <Typography style={{ marginTop: 30 }}>
          <Translator transRef="translation.Redirecionando" />
        </Typography>
        <LinearProgress style={{ width: 150 }} />
        <Link className="link" onClick={() => setState("send")}>
          <Translator transRef="translation.Reenviar mensagem de recuperação" />
        </Link>
      </Grid>
    );
  };

  const translation = {
    typeEmail: Translator({ transRef: "translation.Digite seu email aqui" }),
  }

  return (
    <Container maxWidth="md" className="recoveryLogin-container">
      <Grid>
        {!isLoadingService && <img
          className="img-logo"
          src={settingCommerce?.url_image_login}
          alt="logo"
        />}
      </Grid>
      <Grid>
        <Grid item xs={12} className="grid-item">
          <Grid className="grid-info">
            <Icon className="icon">lock</Icon>
            <Typography className="text"><Translator transRef="translation.Esqueci minha senha" /></Typography>
          </Grid>
          <Grid className="grid-info">
            <Translator transRef="translation.Insira o e-mail cadastrado para recuperação da senha" />
          </Grid>
        </Grid>
        {state === "sucess" ? (
          <SendSucess />
        ) : (
          <form onSubmit={formik.handleSubmit} className="form">
            <Grid item xs={12} className="grid-form">
              <TextField
                type={"text"}
                name="login"
                onChange={formik.handleChange}
                className="text-field"
                label={translation?.typeEmail}
                helperText={errorLogin || ""}
                error={!!errorLogin}
              />
            </Grid>
            <br />
            <Grid container xs={12}>
              <Button variant='contained' type="submit" color='primary'>
                {isLoading ? <CircularProgress color='inherit' /> : <Translator transRef="translation.Enviar" />}
              </Button>
            </Grid>
          </form>
        )}
      </Grid>
      <Footer />
    </Container>
  );
};

export default RecoveryLoginView;
