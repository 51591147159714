import { Button } from '@itarget/shared'
import { Box, CircularProgress, Icon, Typography } from '@material-ui/core'
import { useCourtesyStyles } from './styles'
import { useCourtesyTranslation } from './translation'

const Courtesy = ({ onConfirm = () => {}, isLoading = false, enableOnConfirm = false }) => {
  const styles = useCourtesyStyles()
  const translation = useCourtesyTranslation()
  return (
    <Box className={styles.container}>
      <Box className={styles.messageContainer}>
        <Box className={styles.courtesyAlert}>
          <Box className={styles.alertIconContainer}>
            <Icon className={styles.alertIcon}>done</Icon>
          </Box>
          <Typography className={styles.alertMessage}>{translation.courtesy}</Typography>
        </Box>
        <Box>
          <Typography className={styles.message}>{translation.messageCourtesy}</Typography>
          {enableOnConfirm && <Typography className={styles.message}>{translation.messageConfirm}</Typography>}
        </Box>
      </Box>
      {enableOnConfirm && (
        <Button
          onClick={onConfirm}
          disabled={isLoading}
          icon={isLoading ? '' : 'done'}
          className={`${styles.button} ${styles.buttonOnConfirm}`}>
          {isLoading ? (
            <CircularProgress
              size={20}
              color='inherit'
            />
          ) : (
            translation.confirm
          )}
        </Button>
      )}
    </Box>
  )
}

export default Courtesy
