import style from './login.module.scss'

import { useState, useEffect } from 'react'
import {
  Grid,
  TextField,
  Button,
  Icon,
  Typography,
  Divider,
  MenuItem,
  Badge,
  Menu,
  ListItemIcon,
  ListItemText,
  Box,
} from '@material-ui/core'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { debounce, Config, Translator } from '@itarget/shared'
import { auth } from '../../redux'
import { useParams } from 'react-router-dom'

const LoginForm = ({ goTo, shoppingCarts, user, anchorEl, handleClick, handleClose, logout, setting, category }) => {
  const { cc } = useParams()
  const centerCostId = useSelector((state) => state.auth.cc)
  const dispatch = useDispatch()
  const selectedLang = useSelector((state) => state.language.lang)
  const settingLogin = useSelector((state) => state.setting.settingLogin)
  const [typeLoginDesc, setTypeLoginDesc] = useState()
  const settingCourse = useSelector((state) => state.setting.settingCommerce)
  const registerReferenceValue =
    settingLogin && settingLogin.filter((res) => res.codigo === 316).length > 0 && settingLogin.filter((res) => res.codigo === 316)[0]

  useEffect(() => {
    if (setting?.data) {
      let tld = setting.data?.[1]?.valor_referencia

      if (tld === 'email_cpf') {
        tld = 'Email/CPF'
      } else {
        tld = 'Login'
      }

      setTypeLoginDesc(tld)
    }
  }, [setting])

  const onSubmit = async (values) => {
    dispatch({
      type: 'FETCH_STARTED',
      meta: {
        actions: auth.actions.LOGIN,
        body: JSON.stringify(values),
        url: Config.baseURL + `api/${selectedLang}/login`,
        method: 'POST',
      },
    })
  }

  const formik = useFormik({
    initialValues: { login: '', senha: '', centro_custo_id: centerCostId, acao_id: 28 },
    enableReinitialize: true,
    onSubmit,
  })

  const onChange = debounce((target) => {
    formik.setFieldValue(target.name, target.value)
  })

  const translation = {
    password: Translator({ transRef: 'translation.Senha' }),
    register: Translator({ transRef: 'translation.Cadastre-se' }),
  }

  return (
    <Grid className={style.LeftSidemodel02}>
      {user?.data ? (
        <>
          <Divider orientation='vertical' />
          <MenuItem
            onClick={() => goTo('shopping-cart')}
            className='menu-item'>
            <Badge badgeContent={shoppingCarts && shoppingCarts.length}>
              <Icon
                className={shoppingCarts.length > 0 ? 'icon-shoppingCarts' : 'item-icon'}
                color='action'>
                shopping_cart
              </Icon>
            </Badge>
          </MenuItem>
          <Divider orientation='vertical' />
          <Grid className={`user-info`}>
            <MenuItem
              onClick={handleClick}
              className='menu-item'>
              <Grid className={`user-name ${style.userName}`}>
                <Typography>{user.data.nome_cracha ? user.data.nome_cracha : user.data.nome}</Typography>
                <Typography>{category}</Typography>
              </Grid>
              <img
                width={40}
                src={user.data.foto ? user.data.foto : '/assets/images/avatar.png'}
                alt='Avatar'
              />
            </MenuItem>
            <Menu
              id='customized-menu'
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}>
              <MenuItem onClick={() => goTo('me')}>
                <ListItemIcon>
                  <Icon color='action'>person</Icon>
                </ListItemIcon>
                <ListItemText primary='Meus dados' />
              </MenuItem>
              <MenuItem onClick={() => goTo('my-course')}>
                <ListItemIcon>
                  <Icon color='action'>person</Icon>
                </ListItemIcon>
                <ListItemText primary='Meus cursos' />
              </MenuItem>
              <MenuItem onClick={() => goTo('my-activity')}>
                <ListItemIcon>
                  <Icon color='action'>menu_book</Icon>
                </ListItemIcon>
                <ListItemText primary='Minhas atividades' />
              </MenuItem>
              <MenuItem onClick={() => logout()}>
                <ListItemIcon>
                  <Icon color='action'>exit_to_app</Icon>
                </ListItemIcon>
                <ListItemText primary='Sair' />
              </MenuItem>
            </Menu>
          </Grid>
        </>
      ) : (
        <Grid
          item={true}
          className={style.Model02}>
          <Grid
            container
            alignItems='center'
            spacing={1}>
            <Box
              display='flex'
              alignItems='center'>
              <Icon>person</Icon>
            </Box>
            <Grid item>
              <Typography>
                <Translator transRef='translation.Área do Participante' />
              </Typography>
            </Grid>
          </Grid>

          <Grid
            className='frm-login'
            item={true}
            lg={12}>
            <form onSubmit={formik.handleSubmit}>
              <Grid className={`${style.ContainerFields} container-grid`}>
                <Grid
                  className='grid-field'
                  item={true}
                  xs={6}>
                  <TextField
                    type={'text'}
                    variant='outlined'
                    label={typeLoginDesc}
                    name='login'
                    onChange={({ target }) => onChange(target)}
                    required={true}
                  />
                </Grid>
                <Grid
                  className='grid-field'
                  item={true}
                  xs={6}>
                  <TextField
                    type='password'
                    variant='outlined'
                    label={translation?.password}
                    name='senha'
                    onChange={formik.handleChange}
                    required={true}
                  />
                </Grid>
                <Grid
                  className='gridBtn'
                  item={true}
                  xs={12}>
                  <Button
                    className='model02-btnSubmit'
                    type='submit'
                    variant='contained'>
                    <Translator transRef='translation.ok' />
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>

          <Grid container>
            <Grid item>
              <Button
                variant='text'
                color='midnight'
                onClick={() => goTo(`recovery-login${settingCourse?.centro_custo_padrao ? `/${cc || centerCostId}` : ''}`)}>
                <Translator transRef='translation.Esqueci minha senha' />
              </Button>
            </Grid>
            {(['N', undefined, false].includes(registerReferenceValue?.ativo) ||
              ['S', undefined, false].includes(registerReferenceValue?.valor_referencia)) && (
              <Grid item>
                <Button
                  variant='text'
                  color='midnight'
                  onClick={() => goTo(`login${settingCourse?.centro_custo_padrao ? `/${cc || centerCostId}` : '/'}?state=register`)}>
                  {translation.register}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default LoginForm
