import { useEffect } from "react";
import { Form, useFetchApi, Config } from "@itarget/shared";
import { useSelector } from "react-redux";
import { toast } from "../../redux";
import MeView from "./views/MeView";

const MeContainer = () => {
  const centerCostId = useSelector((state) => state.auth.cc);
  const user = useSelector((state) => state.auth.user);
  const selectedLang = useSelector((state) => state.language.lang)

  const { data: reRegister, fetchData: fetchReRegister } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/person/re/register?pessoa_id=${user?.data?.id}&centro_custo_id=${centerCostId}&aplicacoes=4`
  )

  useEffect(() => {
    fetchReRegister()
  }, [user?.data?.id]);
  
  
  return (
    <MeView
      reRegister= {reRegister?.data?.[0]}
      Form={Form}
      toast={toast}
      user={user}
      centerCostId={centerCostId}
    />
  );
};

export default MeContainer;
