import { useCallback, useState, useMemo } from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { auth, language, setting } from '../redux'
import { Config, useFetchApi } from '@itarget/shared'
import RouteContainer from './RouteContainer'
import ToasterContainer from './ToasterContainer'
import DasboardContainer from './dashboard/DasboardContainer'
import RouterOffers from './offers/RouterOffers'
import OffersUniqueContainer from './offersUnique/OffersUniqueContainer'
import ShoppingCartContainer from './shoppingCart/ShoppingCartContainer'
import LoginContainer from './login/LoginContainer'
import RecoveryLoginContainer from './recoveryLogin/RecoveryLoginContainer'
import ChangePasswordContainer from './changePassword/ChangePasswordContainer'
import MyActivityContainer from './myActivity/MyActivityContainer'
import MeContainer from './me/MeContainer'
import RouterCourse from './myCourse/RouterCourse'
import StreamingContainer from './streaming/StreamingContainer'
import PanelContainer from './panel/PanelContainer'
import NewPanelContainer from './newPanel/NewPanelContainer'
import PageContainer from './page/PageContainer'
import RedirectContainer from './redirect/RedirectContainer'
import PaymentContainer from './payment/PaymentContainer'
import StoreContainer from './Store/StoreContainer'
import DetailMyLectureContainer from './myLecture/DetailMyLectureContainer'
import ScientificWorkContainer from './scientificWork/ScientificWorkContainer'
import EadContainer from './ead/EadContainer'
import DetailEadContainer from './detailEad/DetailEadContainer'
import { LinearProgress } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

function AppContainer() {
  const dispatch = useDispatch()
  const [status, setStatus] = useState()
  const systemTheme = useSelector((state) => state.theme.systemTheme)
  const SelectedLang = useSelector((state) => state.language.lang)
  const token = useSelector((state) => state.fetch.token)
  const centerCostId = useSelector((state) => state.auth.cc)

  const getColorBySystemTheme = (light, dark, important = true) => {
    if (!dark) return light
    return `${systemTheme == 'light' ? light : dark}${important ? ' !important' : ''}`
  }

  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          allVariants: {
            color: getColorBySystemTheme('#000000de', '#fff'),
          },
          button: {
            color: 'inherit !important',
          },
          offersTitle: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
            fontSize: '0.875rem',
            lineHeight: 1.3,
            letterSpacing: '0.01071em',
          },
          limitNumberOfLines: (numberOfLines = 1) => {
            return {
              display: '-webkit-box',
              '-webkit-line-clamp': numberOfLines,
              '-webkit-box-orient': 'vertical',
              wordBreak: 'none',
              overflow: 'hidden',
              hyphens: 'auto',
              '--is-single-line': `1 - Clamp(0, Calc(${numberOfLines} - 1), ${numberOfLines})`,
              '--delay': 'Calc(-1s * (var(--is-single-line, 1) - 1))',
              animation: 'states 1s var(--delay) paused',
            }
          },
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1200,
            xl: 1500,
          },
        },
        palette: {
          getColorBySystemTheme,
          transparent: {
            light: getColorBySystemTheme('#ffffff50'),
            main: getColorBySystemTheme('#ffffff50', '#00000050'),
            dark: getColorBySystemTheme('#00000050'),
          },
          primary: {
            main: getColorBySystemTheme('#1976d2', '#1976d2', false),
            contrastText: getColorBySystemTheme('#fff'),
          },
          secondary: {
            main: getColorBySystemTheme('#ffffff00', '#404943', false),
            contrastText: getColorBySystemTheme('#fff', '#000000ee'),
          },
          error: {
            main: getColorBySystemTheme('#E32f2f', '#f44336'),
            contrastText: getColorBySystemTheme('#fff', '#fff'),
          },
          warning: {
            main: getColorBySystemTheme('#E65100', '#EF6C00'),
            contrastText: getColorBySystemTheme('#000000ee', '#000000ee'),
          },
          info: {
            main: getColorBySystemTheme('#0D47A1', '#039BE5'),
            contrastText: getColorBySystemTheme('#fff', '#000000ee'),
          },
          success: {
            main: getColorBySystemTheme('#1B5E20', '#2E7D32'),
            contrastText: getColorBySystemTheme('#fff'),
          },
          midnight: {
            main: getColorBySystemTheme('#E0E2EC', '#404943'),
            dark: getColorBySystemTheme('#F1F1F1', '#ffffff14'),
            contrastText: getColorBySystemTheme('#43474E', '#FFFFFF'),
          },
          twilight: {
            main: getColorBySystemTheme('#EEEEEE', '#434343'),
            dark: getColorBySystemTheme('#E0E0E0', '#616161'),
            contrastText: getColorBySystemTheme('#424242', '#FFFFFF'),
          },
          loading: {
            color: getColorBySystemTheme('#424242', '#FFFFFF'),
            iconColor: getColorBySystemTheme('#424242', '#FFFFFF'),
            backgroundColor: getColorBySystemTheme('#EEEEEE', '#212121'),
          },
          header: {
            menu: {
              backgroundColor: getColorBySystemTheme('#fff', '#232323'),
              backgroundColorShadow: getColorBySystemTheme('#fff', '#232323', false),
            },
            menuItem: {
              color: getColorBySystemTheme('#000', '#fff'),
              backgroundHover: getColorBySystemTheme('#c2c2bd3b', '#433f43'),
              backgroundActive: getColorBySystemTheme('#c2c2c23b', '#434343'),
            },
            userContainer: {
              backgroundColor: getColorBySystemTheme('#ffffff66', '#21212166'),
            },
            shoppingCart: {
              color: getColorBySystemTheme('#FF5252', '#FF5252'),
              badgeColor: getColorBySystemTheme('#E65100', '#EF6C00'),
            },
            avatar: {
              color: getColorBySystemTheme('#fff', '#fff'),
              backgroundColor: getColorBySystemTheme('#1976D2', '#1976D2'),
            },
            participantAccess: {
              color: getColorBySystemTheme('#424242', '#fdfdfd'),
              buttonColor: getColorBySystemTheme('#fff ', '#fff'),
              backgroundColor: getColorBySystemTheme('transparent', '#131313'),
              buttonBackgroundColor: getColorBySystemTheme('#1976D2', '#1976D2'),
              buttonBackgroundColorHover: getColorBySystemTheme('#1979D2', '#1973D2'),
            },
            sub: {
              color: getColorBySystemTheme('#000', '#fff'),
              border: getColorBySystemTheme('#e0e0e0', '#424242'),
            },
          },
          dateFilterPicker: {
            container: {
              border: getColorBySystemTheme('#3F80D6', '#2E3035'),
              backgroundColor: getColorBySystemTheme('transparent', '#434343', false),
            },
            text: {
              color: getColorBySystemTheme('#3F80D6', '#fff'),
              colorSelected: getColorBySystemTheme('#fff'),
            },
          },
          mainShowcase: {
            items: {
              backgroundColor: getColorBySystemTheme('#F9F9FF', '#232323'),
            },
          },
          displayValuesOffers: {
            root: {
              backgroundColor: getColorBySystemTheme('#FAFAFA', '#212121'),
            },
            header: {
              axiosColor: getColorBySystemTheme('#424242', '#FAFAFA'),
              titleColor: getColorBySystemTheme('#424242', '#FAFAFA'),
            },
            registered: {
              backgroundColor: getColorBySystemTheme('#F5F5F5', '#212121'),
              color: getColorBySystemTheme('#424242', '#A9A9A9'),
              successColor: getColorBySystemTheme('#4CAF50', '#4CAF50'),
            },
            counter: {
              color: getColorBySystemTheme('#424242', '#FAFAFA'),
              limitColor: getColorBySystemTheme('#424242', '#FAFAFA'),
              iconColor: getColorBySystemTheme('#616161', '#BDBDBD'),
              borderColor: getColorBySystemTheme('#61616160', '#BDBDBD60'),
              iconDisabledColor: getColorBySystemTheme('#61616130', '#BDBDBD30'),
            },
            courtesy: {
              backgroundColor: getColorBySystemTheme('#F5F5F5', '#303030'),
              color: getColorBySystemTheme('#424242', '#FAFAFA'),
              successColor: getColorBySystemTheme('#4CAF50', '#4CAF50'),
            },
            soldOut: {
              backgroundColor: getColorBySystemTheme('#F5F5F5', '#303030'),
              messageColor: getColorBySystemTheme('#EF6C00', '#FFB74D'),
              color: getColorBySystemTheme('#424242', '#FAFAFA'),
            },
            comboAnnuity: {
              color: getColorBySystemTheme('#424242', '#FAFAFA'),
              backgroundColor: getColorBySystemTheme('#F5F5F5', '#252525'),
              checkboxColor: getColorBySystemTheme('#388E3C', '#66BB6A'),
              checkboxBackgroundColor: getColorBySystemTheme('#FFFFFF', '#424242'),
              messageColor: getColorBySystemTheme('#616161', '#BDBDBD'),
              messageBackgroundColor: getColorBySystemTheme('#E0E0E0', '#393939 '),
              dashedBorderColor: getColorBySystemTheme('#BDBDBD', '#616161'),
            },
            pending: {
              color: getColorBySystemTheme('#FFFFFF', '#121212'),
              redirectColor: getColorBySystemTheme('#01579B', '#81D4FA'),
              backgroundColor: getColorBySystemTheme('#F57C00', '#F57C00'),
            },
            shoppingCart: {
              color: getColorBySystemTheme('#FFFFFF', '#121212'),
              redirectColor: getColorBySystemTheme('#01579B', '#81D4FA'),
              backgroundColor: getColorBySystemTheme('#FFAB00', '#FFAB00'),
            },
            amount: {
              color: getColorBySystemTheme('#424242', '#FFFFFF'),
              unavailableColor: getColorBySystemTheme('#424242', '#BDBDBD'),
              discountedAmount: getColorBySystemTheme('#757575', '#757575'),
            },
            discount: {
              color: getColorBySystemTheme('#FFFFFF', '#FFFFFF'),
              backgroundColor: getColorBySystemTheme('#f70e0e', '#f70e0e'),
            },
            unavailable: {
              color: getColorBySystemTheme('#424242', '#FFFFFF'),
              iconColor: getColorBySystemTheme('#FFB74D', '#FFB74D'),
              backgroundColor: getColorBySystemTheme('#EEEEEE', '#212121'),
            },
            loading: {
              color: getColorBySystemTheme('#424242', '#FFFFFF'),
              iconColor: getColorBySystemTheme('#424242', '#FFFFFF'),
              backgroundColor: getColorBySystemTheme('#EEEEEE', '#212121'),
            },
            category: {
              color: getColorBySystemTheme('#424242', '#FFFFFF'),
              taskColor: getColorBySystemTheme('#111C2B', '#EEEEEE'),
              borderColor: getColorBySystemTheme('#EEEEEE', '#424242'),
              warningColor: getColorBySystemTheme('#FFB74D', '#FFB74D'),
              backgroundColor: getColorBySystemTheme('#EEEEEE', '#303030'),
            },
            detail: {
              color: getColorBySystemTheme('#616161', '#FAFAFA'),
              backgroundColor: getColorBySystemTheme('#F3F3F3', '#212121'),
            },
            actionsButtons: {
              onSubscribe: {
                color: getColorBySystemTheme('#FFFFFF', '#FFFFFF'),
                backgroundColor: getColorBySystemTheme('#394354', '#394354'),
                backgroundColorHover: getColorBySystemTheme('#394554', '#394554'),
              },
              onAddCart: {
                color: getColorBySystemTheme('#FFFFFF', '#FFFFFF'),
                backgroundColor: getColorBySystemTheme('#545F71', '#545F71'),
                backgroundColorHover: getColorBySystemTheme('#546271', '#546271'),
              },
              onReturn: {
                color: getColorBySystemTheme('#424242', '#BDBDBD'),
                backgroundColor: getColorBySystemTheme('transparent', 'transparent'),
                backgroundColorHover: getColorBySystemTheme('transparent', 'transparent'),
              },
              onWatchStream: {
                color: getColorBySystemTheme('#FFFFFF', '#FFFFFF'),
                backgroundColor: getColorBySystemTheme('#FF5252', '#FF5252'),
                backgroundColorHover: getColorBySystemTheme('#FF5452', '#FF5452'),
              },
              onWatchRecorded: {
                color: getColorBySystemTheme('#FFFFFF', '#FFFFFF'),
                backgroundColor: getColorBySystemTheme('#424242', '#424242'),
                backgroundColorHover: getColorBySystemTheme('#424442', '#424442'),
              },
              onConfirm: {
                color: getColorBySystemTheme('#FFFFFF', '#212121'),
                backgroundColor: getColorBySystemTheme('#4CAF50', '#73D076'),
                backgroundColorHover: getColorBySystemTheme('#4CB250', '#73D276'),
              },
              onWaitingList: {
                color: getColorBySystemTheme('#FFFFFF', '#212121'),
                backgroundColor: getColorBySystemTheme('#ffa726', '#ffb74d'),
                backgroundColorHover: getColorBySystemTheme('#ffaa26', '#ffba4d'),
              },
            },
          },
          programmingSection: {
            item: {
              container: {
                backgroundColor: getColorBySystemTheme('#F9F9FF', '#232323'),
              },
              button: {
                backgroundColorHover: getColorBySystemTheme('#6f93b5', '#545F61'),
                backgroundColor: getColorBySystemTheme('#6f93c5', '#545F71'),
                color: getColorBySystemTheme('#fff'),
              },
              time: {
                color: getColorBySystemTheme('#000', '#fff'),
              },
              information: {
                color: getColorBySystemTheme('#111C2B', '#fff'),
                colorBold: getColorBySystemTheme('#001C3A', '#fff'),
                typeActivityColor: getColorBySystemTheme('#545F71', '#999999'),
                defaultAxiosColor: getColorBySystemTheme('#0c0c0ccc', '#f5f5f5cc'),
              },
              lecture: {
                backgroundColor: getColorBySystemTheme('#ffffff', '#232323'),
                dateTimeColor: getColorBySystemTheme('#545F71', '#999999'),
                buttonColor: getColorBySystemTheme('#3F80D6'),
              },
              speaker: {
                backgroundColor: getColorBySystemTheme('#FFFFFF', '#52525284'),
                nameColor: getColorBySystemTheme('#111C2B', '#FFFFFF'),
                paperColor: getColorBySystemTheme('#545F71', '#999999'),
                dot: getColorBySystemTheme('#E0E2EC', '#6D6D6D'),
                dotContent: getColorBySystemTheme('#000', '#fff'),
                dotSelected: getColorBySystemTheme('#6D6D6D', '#E3E3E3'),
                dotContentSelected: getColorBySystemTheme('#fff', '#000'),
              },
            },
            title: {
              color: getColorBySystemTheme('#000', '#fff'),
            },
          },
          default: {
            light: getColorBySystemTheme('#1f1f1f'),
            main: getColorBySystemTheme('#212121'),
            dark: getColorBySystemTheme('#232323'),
            contrastText: getColorBySystemTheme('#ffffff'),
          },
          chip: {
            default: {
              color: getColorBySystemTheme('#424242'),
              text: getColorBySystemTheme('#ffffff'),
            },
            success: {
              color: getColorBySystemTheme('#4CAF50'),
              text: getColorBySystemTheme('#ffffff'),
            },
            warning: {
              color: getColorBySystemTheme('#EF6C00'),
              text: getColorBySystemTheme('#ffffff'),
            },
            danger: {
              color: getColorBySystemTheme('#f44336'),
              text: getColorBySystemTheme('#ffffff'),
            },
          },
          type: systemTheme,
          background: {
            default: getColorBySystemTheme('#fff', '#1C1C1C'),
          },
          invert: {
            filter: `invert(${systemTheme == 'light' ? 0 : 1})`,
          },
        },
        overrides: {
          MuiRadio: {
            root: {
              color: getColorBySystemTheme('#0000008a', '#ffffffb3'),
            },
          },
          MuiButton: {
            root: {
              '&.Mui-disabled': {
                opacity: 0.5,
              },
            },
          },
          MuiInputBase: {
            root: {
              '& .MuiOutlinedInput-input:-webkit-autofill': {
                '-webkit-box-shadow': `0 0 0 100px ${getColorBySystemTheme('#f3f3f3', '#1C1C1C')} inset`,
                '-webkit-text-fill-color': getColorBySystemTheme('#000000', '#ffffff'),
              },
            },
          },
          MuiPaper: {
            root: {
              backgroundColor: getColorBySystemTheme('#fff', '#232323'),
            },
          },
          MuiInputLabel: {
            root: {
              display: '-webkit-box',
              '-webkit-line-clamp': 1,
              '-webkit-box-orient': 'vertical',
              wordBreak: 'none',
              overflow: 'hidden',
              hyphens: 'auto',
              '--is-single-line': '1 - Clamp(0, Calc(1 - 1), 1)',
              '--delay': 'Calc(-1s * (var(--is-single-line, 1) - 1))',
              animation: 'states 1s var(--delay) paused',
            },
          },
          MuiTabs: {
            root: {
              minWidth: 'auto !important',
              '& .MuiTab-wrapper': {
                padding: '8px',
                '& .MuiIcon-root': {
                  fontSize: '20px !important',
                },
              },
              '& .Mui-selected': {
                fontWeight: 'bold',
                '& .MuiTab-wrapper': {
                  color: getColorBySystemTheme('#1976d2', '#fff'),
                  borderBottom: '1px solid',
                  borderBottomColor: getColorBySystemTheme('#434343', '#EEEEEE'),
                },
              },
            },
          },
        },
      }),
    [systemTheme]
  )

  const { data: centerCost, fetchData: fetchCenterCost } = useFetchApi(Config.baseURL + `api/${SelectedLang}/entity/current/commerce`)

  const { data: translation, fetchData: fetchTranslation } = useFetchApi(
    Config.baseURL + `api/${SelectedLang}/translation?centro_custo_id=${centerCostId}`
  )

  const getToken = useCallback(async () => {
    const data = {
      login: 'icongressolive',
      senha: 'FRB:y}|Y',
    }
    const body = JSON.stringify(data)
    await dispatch({
      type: 'FETCH_STARTED',
      meta: {
        actions: auth.actions.AUTHENTICATE_REQUEST,
        body: body,
        url: Config.baseURL + `api/${SelectedLang}/authenticate`,
        method: 'POST',
      },
    })
  }, [])

  useEffect(() => {
    if (centerCost?.data[0] && !centerCostId) {
      dispatch(auth.actions.setCenterCost(centerCost.data[0].centro_custo_padrao_id))
      dispatch(setting.actions.setEntity(centerCost.data[0]))
    }
  }, [centerCost])

  useEffect(() => {
    if (centerCostId) {
      fetchTranslation()
    }
  }, [SelectedLang, centerCostId, fetchTranslation])

  useEffect(() => {
    if (translation?.data) {
      dispatch(language.actions.setTranslation(translation.data))
    }
  }, [translation, dispatch])

  useEffect(() => {
    const list = async () => {
      await getToken()
    }
    list()
  }, [getToken])

  useEffect(() => {
    if (token && token != '') {
      const list = async () => {
        await fetchCenterCost(null, null, { Authorization: 'Bearer ' + token })
        setStatus('released')
      }
      list()
    }
  }, [token, fetchCenterCost])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <ToasterContainer />
        {status !== 'released' && <LinearProgress />}
        {status === 'released' && (
          <Switch>
            <RouteContainer path='/redirect/:cc'>
              <RedirectContainer />
            </RouteContainer>
            <RouteContainer path='/login/:cc?'>
              <LoginContainer />
            </RouteContainer>
            <RouteContainer path='/recovery-login/:cc?'>
              <RecoveryLoginContainer />
            </RouteContainer>
            <RouteContainer path='/change-password/:cc?'>
              <ChangePasswordContainer />
            </RouteContainer>
            <RouteContainer path='/offers'>
              <RouterOffers />
            </RouteContainer>
            <RouteContainer path='/offers-unique/:cc/:activity_id?'>
              <OffersUniqueContainer />
            </RouteContainer>
            <RouteContainer path='/ead'>
              <EadContainer />
            </RouteContainer>
            <RouteContainer path='/detail-ead/:cc/:activity_schedule_id'>
              <DetailEadContainer />
            </RouteContainer>
            <RouteContainer path='/store/:cc/'>
              <StoreContainer />
            </RouteContainer>
            <RouteContainer
              isProtected
              path='/shopping-cart'>
              <ShoppingCartContainer />
            </RouteContainer>
            <RouteContainer
              isProtected
              path='/my-activity'>
              <MyActivityContainer />
            </RouteContainer>
            <RouteContainer path='/scientific-work/:cc'>
              <ScientificWorkContainer />
            </RouteContainer>
            <RouteContainer
              isProtected
              path='/my-course'>
              <RouterCourse />
            </RouteContainer>
            <RouteContainer path='/my-lecture/:cc/:activity_schedule_id'>
              <DetailMyLectureContainer />
            </RouteContainer>
            <RouteContainer
              isProtected
              path='/streaming'>
              <StreamingContainer />
            </RouteContainer>
            <RouteContainer
              isProtected
              path='/me'>
              <MeContainer />
            </RouteContainer>
            <RouteContainer
              isProtected
              path='/panel'>
              <PanelContainer />
            </RouteContainer>
            <RouteContainer
              isProtected
              path='/new-panel'>
              <NewPanelContainer />
            </RouteContainer>
            <RouteContainer path='/payment/:id'>
              <PaymentContainer />
            </RouteContainer>
            <RouteContainer path='/page/:id'>
              <PageContainer />
            </RouteContainer>
            <RouteContainer path='/dasboard/:jwt/:page?'>
              <DasboardContainer />
            </RouteContainer>
            <RouteContainer path='/lang/:lang/:url?'>
              <DasboardContainer />
            </RouteContainer>
            <RouteContainer path='/:jwt?'>
              <DasboardContainer />
            </RouteContainer>
          </Switch>
        )}
      </Router>
    </ThemeProvider>
  )
}

export default AppContainer
