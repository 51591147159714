import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2.5),
    justifyContent: 'flex-end',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      gap: theme.spacing(1),
      flexDirection: 'column',
    },
  },
  advancedButton: {
    ...theme.typography.caption,
    textDecoration: 'underline',
    cursor: 'pointer',
    display: 'none',
    lineHeight: 1,
    textAlign: 'end',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      display: 'initial',
    },
  },
  advancedContainer: {
    width: 'auto',
    display: ({ displayAdvancedFilters }) => (displayAdvancedFilters ? 'flex' : 'none'),
    alignItems: 'flex-end',
    gap: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      width: '100%',
    },
  },
  timeContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    '& *::-webkit-calendar-picker-indicator': {
      ...theme.palette.invert,
    },
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    '& .MuiInputBase-root': {
      width: 'auto',
    },
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      flex: 1,
    },
  },
  searchField: {
    flex: 2,
    width: 'auto',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      width: '100%',
    },
  },
  fieldName: {
    ...theme.typography.caption,
    lineHeight: 1,
  },
  select: {
    width: theme.typography.pxToRem(135),
    paddingTop: theme.typography.pxToRem(10.5),
    paddingBottom: theme.typography.pxToRem(10.5),
    '& .MuiSelect-root': {
      fontSize: theme.typography.pxToRem(12),
      paddingTop: theme.typography.pxToRem(0),
      ...theme.typography.limitNumberOfLines(),
      paddingBottom: theme.typography.pxToRem(0),
    },
  },
  cleanButton: {
    display: 'inherit',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      display: 'none',
    },
  },
  button: {
    height: theme.typography.pxToRem(40),
    paddingTop: theme.typography.pxToRem(10.5),
    paddingBottom: theme.typography.pxToRem(10.5),
  },
  mobileCleanButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      display: 'none',
    },
  },
  mobileCleanButton: {
    flex: 0,
  },
}))
