import { Grid } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { useFetchApi, Config } from '@itarget/shared'
import { useDispatch, useSelector } from 'react-redux'
import { language as languageRedux } from '../../redux'
import './style.scss'

const BtnLanguages = () => {
  const centro_custo_id = useSelector((state) => state.auth.cc)
  const selectedLang = useSelector((state) => state.language.lang)
  const dispatch = useDispatch()
  const [listLang, setListLang] = useState([])

  const setLanguage = (number) => {
    let lang = number === 2 ? 'en' : number === 3 ? 'es' : 'pt-br'
    dispatch(languageRedux.actions.setLang(lang))
  }

  const url = useMemo(() => {
    return Config.baseURL + `api/${selectedLang}/language?centro_custo_id=${centro_custo_id}`
  })

  const { data: language, fetchData: fetchLanguage } = useFetchApi(url)

  useEffect(() => {
    if (language) {
      setListLang(language.data)
    }
  }, [language])

  useEffect(() => {
    fetchLanguage()
  }, [url])

  return (
    <Grid alignItems='center' justify='flex-end' container className='btnsContainerGeneral'>
      {listLang.length > 1 &&
        listLang?.map((row) => (
          <img
            key={row.value}
            className='flagsGeneral'
            src={`/assets/images/${row.value}.png`}
            alt={row.label}
            onClick={() => setLanguage(row.value)}
          />
        ))}
    </Grid>
  )
}

export default BtnLanguages
