import { Box, Icon, Typography } from '@material-ui/core'
import { useLoadingStyles } from './styles'
import { useLoadingTranslation } from './translation'

const Loading = () => {
  const styles = useLoadingStyles()
  const translation = useLoadingTranslation()

  return (
    <Box className={styles.container}>
      <Icon className={styles.loadingIcon}>sync</Icon>
      <Typography className={styles.loadingMessage}>{translation.loading}</Typography>
    </Box>
  )
}

export default Loading
