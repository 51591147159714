import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFetchApi, Config, useRedirect, Loading } from '@itarget/shared'
import { subscription, auth, setting } from '../../redux'

import OffersView from './views/OffersView'

const OffersContainer = () => {
  const dispatch = useDispatch()
  const { goTo } = useRedirect()
  const [offers, setOffers] = useState([])
  const [showQuiz, setShowQuiz] = useState()
  const [categorySelected, setCategorySelected] = useState(false)
  const [groupByOffers, setGroupByOffers] = useState('')

  const user = useSelector((state) => state.auth.user)
  const isAuthenticated = useSelector((state) => state.auth.isAutenticated)
  const selectedLang = useSelector((state) => state.language.lang)
  const center_cost_id = useSelector((state) => state.auth.cc)
  const centerCost = useSelector((state) => state.setting.centerCost)
  let userId = user?.data ? user?.data?.id : undefined
  let userCategoryDescription = user?.data ? user?.data?.categoria_centro_custo_descricao : undefined

  const { data: settingCourse, fetchData: fetchSettingCourse } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/setting-course-commerce`
  )

  const {
    data: listOffers,
    isLoading: isLoadingListOffers,
    fetchData: fetchListOffers,
  } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/activity-schedule/offer?nome_interface=offers&` +
      (user?.data?.id ? `pessoa_id=${user.data.id}` : '')
  )

  const { data: reRegister, fetchData: fetchReRegister } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/person/re/register?pessoa_id=${user?.data?.id}&centro_custo_id=${center_cost_id}&aplicacoes=4`
  )

  const { data: category, fetchData: fetchListCategory } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/center-cost/${center_cost_id}/category?pessoa_id=${user?.data?.id}&visivel_mod_congresso=S`
  )

  const { data: putCategory, fetchData: fetchPersonCenterCost } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/center-cost-person?pessoa_id=${user?.data?.id}&centro_custo_id=${center_cost_id}`,
    'POST'
  )

  const { data: questions, fetchData: fetchQuestions } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/question?centro_custo_id=${center_cost_id}&pessoa_id=${user?.data?.id}&destino_pergunta=17`
  )

  useEffect(() => {
    if (!reRegister?.data?.[0]) return
    const timeout = setTimeout(() => goTo('me'), 2000)
    return () => clearTimeout(timeout)
  }, [reRegister])

  useEffect(() => {
    if (isAuthenticated) {
      fetchReRegister()
    }
  }, [isAuthenticated])

  useEffect(() => {
    fetchSettingCourse()
  }, [fetchSettingCourse])

  useEffect(() => {
    if (centerCost?.tipo_centro_custo === 7) {
      goTo(`ead/`)
    }
  }, [centerCost])

  useEffect(() => {
    if (settingCourse) {
      dispatch(setting.actions.setSettingCommerce(settingCourse.data[0]))
    }
  }, [settingCourse, dispatch])

  useEffect(() => {
    fetchListOffers()
      if (userId) {
        fetchQuestions()
      }
  }, [userId, fetchListOffers])

  useEffect(() => {
    if (questions && questions?.data?.length > 0 && !questions?.data[0]?.respondido) {
      setShowQuiz(true)
    }
  }, [questions])

  useEffect(() => {
    if (listOffers) {
      setOffers(listOffers.data)
      setGroupByOffers(listOffers.groupBy)
    }
  }, [listOffers])

  useEffect(() => {
    if (user?.data && !user?.data?.categoria_centro_custo_descricao) {
      fetchListCategory()
    }
  }, [user?.data, fetchListCategory])

  useEffect(() => {
    if (!userCategoryDescription && category) {
      if (category.data.length == 1) {
        const selectedOneCategory = async () => {
          await fetchPersonCenterCost(
            JSON.stringify({
              pessoa_id: user?.data?.id,
              categoria_centro_custo_id: category.data[0].id,
              categoria_centro_custo_princ: 'S',
              nome_cracha: user?.data?.nome,
            })
          )
        }
        selectedOneCategory()
        setCategorySelected(false)
        let data = user?.data
        dispatch(
          auth.actions.updateUser({
            ...user,
            data: {
              ...data,
              categoria_centro_custo_descricao: category.data[0].descricao,
              categoria_centro_custo_id: category.data[0].id,
            },
          })
        )
        return
      }
      setCategorySelected(true)
    }
  }, [userCategoryDescription, category])

  const selectOffer = async (activity) => {
    if (activity && activity.status_inscricao === 2) {
      if (activity.disponivel_transmissao === 'S') {
        await dispatch(subscription.actions.setTransmissionActivity(activity))
        goTo(`streaming`)
        return
      }
      if (activity && activity.qtde_aulas_video > 0) {
        goTo(`my-course/${activity.aga_id}`)
        return
      }
    }
    goTo(`offers/${activity.aga_id}/${activity.centro_custo_id_out}`)
  }

  return (
    <>
      {isLoadingListOffers ? (
        <Loading text='...' />
      ) : (
        <OffersView
          groupByOffers={groupByOffers}
          category={category}
          categorySelected={categorySelected}
          offers={offers}
          selectOffer={selectOffer}
          showQuiz={showQuiz}
          questions={questions}
          setShowQuiz={setShowQuiz}
          center_cost_id={center_cost_id}
        />
      )}
    </>
  )
}

export default OffersContainer
