import { Divider, Grid, Icon, makeStyles } from '@material-ui/core'
import MenuC from '../../../MenuC'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import SocialIcons from '../../../SocialIcons'
import { Config, Translator } from '@itarget/shared'
import { Trailing } from './Trailing'

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
  },
  iconcart: {
    width: '32px',
    height: '32px',
  },
  container: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '5px solid #1c6ab9',
  },
  contactsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: '#1c6ab9',
    padding: '0 2rem',
  },
  phoneContact: {
    color: '#f8f9fa',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  phoneIcon: {
    marginRight: '0.3rem',
    fontSize: '16px !important',
  },
  contactsDivider: {
    width: '1px',
    height: '1rem',
    margin: '0 1.5rem',
    backgroundColor: '#f8f9fa',
  },
  socialMedias: {
    '& .redes-sociais': {
      display: 'flex',
      gap: '0.5rem',
    },
  },
  includesContent: {
    width: '100%',
    padding: '0.5rem 1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media (max-width: 950px)': {
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '@media (max-width: 425px)': {
      flexDirection: 'column',
    },
  },
  contentBackgroundImage: {
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    maxWidth: '120px',
    maxHeight: '100%',
    display: 'flex',
  },
  glass: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    gap: '5px',
    padding: '5px',
    /* From https://css.glass */
    background: '#606060cc',
    borderRadius: '16px',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    backdropFilter: 'blur(3.9px)',
    webkitBackdropFilter: 'blur(3.9px)',
    border: '1px solid rgba(255, 255, 255, 0.26)',
    color: '#ffffff',
    marginLeft: theme.spacing(1.5),

    '& .MuiIconButton-root, & .MuiListItemText-secondary': {
      color: '#ffffff',
    },
  },
  trailingLogged: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: theme.spacing(1.5, 0),
    '& .user-information-block': {
      color: 'inherit',
    },
  },
  trailing: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: '1rem',
    '& .MuiTypography-colorTextSecondary, & .shoppingCartIcon': {
      color: '#ffffff',
    },
  },
  leading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
  },
  menu: {
    height: '30px',
  },
  menuItem: {
    textTransform: 'uppercase',
  },
  icon: {
    color: '#000',
  },
  menuButton: {
    '& > .MuiIconButton-root': {
      padding: '0',
    },
    '@media (min-width: 950px)': {
      display: 'none',
    },
  },
  associateButton: {
    backgroundColor: '#70ad2f',
    margin: theme.spacing(2),
    borderRadius: 'unset',
    textTransform: 'uppercase',
    fontSize: '0.7rem',
    fontWeight: 'bold',
    borderRadius: '3px',
    '&:hover': {
      backgroundColor: '#70ad2f',
    },
  },
  menuItemsContainer: {
    width: 'fit-content',
    display: 'flex',
    '@media (max-width: 950px)': {
      display: 'none',
    },
  },
  menuItemsContainerMobile: {
    display: 'none',
    '@media (max-width: 950px)': {
      display: 'flex',
    },
  },
}))

const dataSocialMedias = {
  iapo: [
    { id: 1, size: '16px', color: '#f8f9fa', icon: 'facebook', url: 'https://facebook.com/iapobrasil' },
    { id: 2, size: '16px', color: '#f8f9fa', icon: 'linkedin', url: 'https://www.linkedin.com/in/iapo-brasil' },
    {
      id: 3,
      size: '16px',
      color: '#f8f9fa',
      icon: 'instagram',
      url: 'https://www.instagram.com/iapobrasil/',
    },
  ],
}

const associateUrl = 'https://iapo.org.br/Associe-se'

const clientContact = {
  phoneUrl: 'tel:+551139833396',
  phone: '+55 (11) 3983.3396',
}

const Header = ({
  showMenu,
  toggleMenu,
  logo,
  client,
  shoppingCarts,
  menuItemsC = [],
  isLoadingMenuData,
  user,
  goTo,
  onLogout,
  statusAssociated,
  category,
}) => {
  const [menu, setMenu] = useState([{ description: 'Home', url: '/' }])
  const [imageBackground, setImageBackground] = useState(null)
  const styles = useStyles()
  const settingCourse = useSelector((state) => state.setting.settingCommerce)

  const translation = {
    associateButtonText: <Translator transRef='translation.Associe-se' />,
  }

  useEffect(() => {
    try {
      if (settingCourse?.json_menu) setMenu(JSON.parse(settingCourse.json_menu))
      if (settingCourse.url_image_topo_background) setImageBackground(settingCourse.url_image_topo_background)
    } catch (e) {}
  }, [settingCourse])

  return (
    <header className={styles.header}>
      <div className={styles.contactsContainer}>
        <a
          className={styles.phoneContact}
          href={clientContact.phoneUrl}>
          <Icon className={styles.phoneIcon}>phone_iphone</Icon>
          <small>{clientContact.phone}</small>
        </a>
        <Divider className={styles.contactsDivider} />
        <div className={styles.socialMedias}>
          <SocialIcons
            client={Config.client}
            socialMedias={dataSocialMedias}
          />
        </div>
      </div>
      <Grid
        className={styles.container}
        container
        style={{ backgroundImage: `url(${imageBackground})` }}>
        <Grid className={styles.contentBackgroundImage}>
          <Grid
            item
            className={styles.includesContent}>
            <div className={styles.leading}>
              <img
                className={styles.logo}
                alt={client}
                src={logo}
              />
            </div>
            <div className={styles.trailing}>
              <div className={styles.menuItemsContainer}>
                <MenuC
                  items={menuItemsC}
                  isLoadingMenuData={isLoadingMenuData}
                  classes={{ root: styles.menu, itemRoot: styles.menuItemRoot }}
                  showMenu={showMenu}
                />
              </div>
              <Trailing
                shoppingCarts={shoppingCarts}
                showMenu={showMenu}
                category={category}
                goTo={goTo}
                onLogout={onLogout}
                statusAssociated={statusAssociated}
                styles={styles}
                toggleMenu={toggleMenu}
                user={user}
              />
            </div>
          </Grid>
        </Grid>
        <div className={styles.menuItemsContainerMobile}>
          <MenuC
            items={menuItemsC}
            isLoadingMenuData={isLoadingMenuData}
            classes={{ root: styles.menu, itemRoot: styles.menuItemRoot }}
            showMenu={showMenu}
          />
        </div>
      </Grid>
    </header>
  )
}

export default Header
