import { useEffect, useState } from "react"

import { Container, Grid, Typography, Icon, IconButton, FormControlLabel, Radio, Button, TextField, Checkbox } from "@material-ui/core"
import "./style.scss"
import { useFormik } from "formik"
import { Config, useFetchApi, Translator } from "@itarget/shared"
import { useSelector } from "react-redux"
import { Alert } from "@material-ui/lab"
import { ModalFullScreen } from '../ModalFullScreen'

const ModalQuestionsCourse = ({
  questions,
  closeShow,
  secondsLeftQuiz,
  limitTimeQuiz,
  fetchQuestions = () => { }
}) => {
  const [value, setValue] = useState();
  const [valueOther, setValueOther] = useState();
  const [checkRadio, setCheckRadio] = useState();
  const [others, setOthers] = useState([]);
  const [index, setIndex] = useState(0);
  const selectedLang = useSelector((state) => state.language.lang);
  const user = useSelector((state) => state.auth.user);

  const { data: dataSendAnswers, fetchData: fetchSendAnswers } = useFetchApi(Config.baseURL + `api/${selectedLang}/question`, "POST")

  const secondsToHour = (seconds) => {
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds % 3600) / 60);
    var secondsRemaining = seconds % 60;

    var hoursFormatted = hours.toString().padStart(2, '0');
    var minutesFormatted = minutes.toString().padStart(2, '0');
    var secondsFormatted = secondsRemaining.toString().padStart(2, '0');

    return hoursFormatted + ':' + minutesFormatted + ':' + secondsFormatted;
  }

  useEffect(() => {
    if (dataSendAnswers) {
      closeShow()
      fetchQuestions();
    }
  }, [closeShow, dataSendAnswers])

  useEffect(() => {
    limitTimeQuiz();
  }, [])

  const handleChange = (event) => {
    formik.setFieldValue(event.target.name, event.target.value)
    setValue(event.target.value)
  }

  const handleChangeOther = (event) => {
    formik.setFieldValue(event.target.name, event.target.value)
    setValueOther(event.target.value)
  }

  const handleChangeRadio = (event) => {
    if (checkRadio) {
      formik.setFieldValue(checkRadio, "")
    }
    formik.setFieldValue(event.target.name, event.target.value)
    setValue(event.target.value)
    setCheckRadio(event.target.value)
  }

  const handleChangeSelect = (event) => {
    setValue(event.target.value)
    formik.setFieldValue(event.target.value, event.target.value)
  }

  const onSubmit = () => {
    if (value) {
      nextQuestion()
    }
  }

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    onSubmit,
  })

  const nextQuestion = () => {
    if (questions.pergunta.length > index + 1) {
      setIndex((i) => i + 1)
      setValue()
      setCheckRadio()
    } else {
      confirmQuiz()
    }
  }

  const confirmQuiz = () => {
    var respostas = []
    const values = Object.entries(formik.values)
    for (let i = 0; i < values.length; i++) {
      if (values[i][1] !== "" && parseInt(values[i][0]) === parseInt(values[i][1])) {
        respostas.push({
          pessoa_id: user.data.id,
          resposta_id: values[i][0],
        })
      } else if (values[i][1] !== "") {
        respostas.push({
          pessoa_id: user.data.id,
          resposta_id: values[i][0],
          outros: values[i][1]?.outros ? values[i][1]?.outros : values[i][1],
        })
      }
    }

    fetchSendAnswers(
      JSON.stringify({
        respostas,
      })
    )
  }

  const handleChangeCheckbox = (e) => {
    if (e.target.checked) {
      formik.setFieldValue(e.target.name, e.target.name)
      setValue(e.target.name)
    } else {
      formik.setFieldValue(e.target.name, "")
    }
  }

  const translation = {
    typeHere: Translator({ transRef: "translation.digite aqui" }),
    others: Translator({ transRef: "translation.Outros" }),
  }

  useEffect(() => {
    if (!questions?.pergunta || questions?.pergunta?.length < 1) {
      return;
    }
    let answersId = questions?.pergunta[index]?.
      resposta.filter((r) => r.resposta_outros == 'S').map(r => r.resposta_id);
    let allAnswers = questions?.pergunta[index]?.resposta.map(r => r.resposta_id);
    setOthers((others) => {
      return others.filter(id => !allAnswers.filter(id => id != value).includes(id));
    })
    if (answersId == value && !others.includes(value)) {
      setOthers((others) => {
        return [...others, parseInt(value)];
      })
    }

  }, [value])
  return (
    <ModalFullScreen>
      <Container>
        <Grid container className="grid-header">
          <Grid item xs={10} md={10}>
            <Typography className="text-header">
              <Translator transRef={`translation.${questions.descricao}`} />
            </Typography>
          </Grid>
          <Grid item xs={2} md={2}>
            <IconButton onClick={() => closeShow()} className="icon-header">
              <Icon>highlight_off</Icon>
            </IconButton>
          </Grid>
        </Grid>
        {questions?.disponivel_por != null && secondsLeftQuiz > 0 &&
          <Grid>
            <Alert severity="info">
              Você possui {secondsToHour(secondsLeftQuiz)} para visualizar e responder as perguntas do quiz, podendo responder apenas até 1 vez cada pergunta
            </Alert>
          </Grid>
        }
        <Container maxWidth="md">
          <Grid className="grid-complet">
            <Grid className='grid-description'>
              <Typography className='question-title'>
                {index + 1} de {questions.pergunta && questions.pergunta.length}
              </Typography>
              {questions.pergunta?.[index]?.descricao && (
                <div dangerouslySetInnerHTML={{ __html: questions.pergunta[index]?.descricao }} />
              )}
            </Grid>
            <form onSubmit={formik.handleSubmit}>
              {questions?.pergunta?.[index]?.resposta.map((row, i) => (
                <div key={i} className="camps">
                  {questions?.pergunta?.[index]?.tipo_resposta === 1 && (
                    <TextField
                      id="multiline-flexible"
                      multiline
                      name={row.resposta_id}
                      onChange={handleChange}
                      variant="outlined"
                      label={row.descricao ? row.descricao : translation?.typeHere}
                      className="field-textarea"
                    />
                  )}
                  {questions?.pergunta?.[index]?.tipo_resposta === 2 && (
                    <FormControlLabel
                      name={row.resposta_id}
                      control={<Radio />}
                      checked={parseInt(checkRadio) === row.resposta_id}
                      label={row.descricao}
                      value={row.resposta_id}
                      onChange={handleChangeRadio}
                    />
                  )}
                  {questions?.pergunta?.[index]?.tipo_resposta === 3 && (
                    <FormControlLabel
                      name={row.resposta_id}
                      control={<Checkbox />}
                      label={row.descricao}
                      onChange={handleChangeCheckbox}
                    />
                  )}
                  {row.resposta_outros == 'S' && others?.includes(row.resposta_id) && (
                    <TextField
                      label={translation.others}
                      name={`${row.resposta_id}[outros]`}
                      onChange={handleChangeOther}
                    />
                  )}
                </div>
              ))}
              {questions?.pergunta?.[index]?.tipo_resposta === 4 && (
                <TextField select onChange={handleChangeSelect}>
                  {questions.pergunta &&
                    questions.pergunta[index].resposta.map((row, i) => (
                      <option key={i} value={row.resposta_id}>
                        {row.descricao}
                      </option>
                    ))}
                </TextField>
              )}
              <Grid className="grid-btn">
                <Button className="btn-confirm" type="submit">
                  <Translator transRef="translation.Confirmar" />
                </Button>
              </Grid>
            </form>
          </Grid>
        </Container>
      </Container>
    </ModalFullScreen>
  )
}

export default ModalQuestionsCourse
