import { Grid, CardMedia, Typography, Box, Hidden } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import { formatDate } from '@itarget/shared'
import ActionsPlay from './ActionsPlay'

import './style.scss'
import ChipStatus from './ChipStatus'

const Model7 = ({ card, onClick }) => {
  return (
    <Grid
      item
      container
      spacing={5}
      className='model7'>
      <Grid item>
        <Hidden smUp>
          <CardMedia
            className='cardOffers-container'
            image={card?.thumb || ''}
            src='img'
            onClick={() => onClick && onClick(card)}
            title={card && card.desc_atv}
          />
        </Hidden>
        <Hidden xsDown>
          <CardMedia
            className='cardOffers-container'
            image={card?.imagem_lista || ''}
            src='img'
            onClick={() => onClick && onClick(card)}
            title={card && card.desc_atv}
          />
        </Hidden>
      </Grid>
      <Box
        className='cardOffers-info'
        gridGap={5}
        flexGrow='1'
        display='flex'
        justifyContent='center'
        flexDirection='column'>
        <Grid
          item
          container
          className='cardOffers-infoLocal'
          xs={12}>
          <Typography variant='caption'>{card?.local_atividade}</Typography>
        </Grid>
        <Grid
          item
          alignItems='center'>
          <Typography variant='body1'>{card && card.desc_atv}</Typography>
        </Grid>
        <Grid
          item
          container
          spacing={1}
          alignItems='center'>
          {card?.exibir_programacao_horario === 'S' && card?.data_fim_aga && (
            <Grid
              item
              xs={12}
              className='cardOffers-infos'>
              <div className='infos-item'>
                <Icon className='infos-icon'>event</Icon>
                <Typography variant='caption'>
                  {card?.data_inicio_aga !== card?.data_fim_aga && `${formatDate.ptBr(card?.data_inicio_aga)} - `}
                  {formatDate.ptBr(card?.data_fim_aga)}
                </Typography>
              </div>
            </Grid>
          )}
          <ChipStatus
            vagas_limite={card.vagas_limite}
            vagas_ocupadas={card.vagas_ocupadas}
            status_inscricao={card.status_inscricao}
            transmissao_gratuito={card.transmissao_gratuito}
            disponivel_transmissao={card.disponivel_transmissao}
          />
          <Grid item>
            <ActionsPlay card={card} />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}

export default Model7
