import { useRedirect, Translator, Button } from '@itarget/shared'
import { Grid } from '@material-ui/core'

const AboutCourse = (props) => {
  const { agenda_atividade_id } = props
  const { goTo } = useRedirect()

  return (
    <Grid
      item
      container
      direction='column'>
      <Button
        variant='contained'
        color='twilight'
        onClick={() => goTo('offers/' + agenda_atividade_id)}
        icon='report'>
        <Translator transRef={'translation.SOBRE O CURSO'} />
      </Button>
    </Grid>
  )
}

export default AboutCourse
