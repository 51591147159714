import { IconButton, Badge, makeStyles } from '@material-ui/core'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'

const useStyle = makeStyles((theme) => ({
  iconButtonShoppingCart: {
    width: '52px',
    width: '52px',
  },
  badge: {
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      backgroundColor: '#ff5252',
      color: '#fff',
      fontFamily: 'arial',
      top: '0',
      right: theme.spacing(0.5),
    },
    '& .MuiSvgIcon-root': {
      width: '28px',
      height: '28px',
    },
  },
}))

const CartButton = ({ goTo, shoppingCarts }) => {
  const style = useStyle()

  return (
    <IconButton className={style.iconButtonShoppingCart} onClick={() => (goTo && goTo('shopping-cart')) || null}>
      <Badge
        className={style.badge}
        badgeContent={shoppingCarts?.length}
        color='error'>
        <ShoppingCartIcon className='shoppingCartIcon' />
      </Badge>
    </IconButton>
  )
}

export default CartButton
