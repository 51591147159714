import React from 'react'
import { Typography, Grid, Divider } from '@material-ui/core'
import { RTag, Translator, formatDate } from '@itarget/shared'
import './style.scss'
import SchedulingTimelineEad from '../SchedulingTimelineEad/SchedulingTimelineEad'
import TimelineAccordionEad from '../TimelineAccordionEad/TimelineAccordionEad'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  tabPanelStyle: {
    width: '100%',
  },
}))

const TabNavEad = ({ date, user, scheduling, subscriptions, selectStep, selectedFilter, participant }) => {
  const styles = useStyles()
  return (
    <Grid
      item
      container>
      <Grid
        item
        container
        spacing={2}
        justifyContent='center'
        sx={12}>
        {scheduling.length > 0 ? (
          scheduling.map((activities, index) => {
            return (
              <Grid
                key={index}
                item
                container>
                <RTag show='desktop'>
                  <Grid
                    container
                    direction='column'
                    justifyContent='space-between'>
                    <Typography variant='h5'>
                      {selectedFilter?.grouping_field === 'data_inicio_aga'
                        ? formatDate.ptBr(activities.titulo)
                        : activities.length > 60
                        ? `${activities.titulo.slice(0, 45)}...`
                        : activities.titulo}
                    </Typography>
                    <Divider />
                  </Grid>
                </RTag>
                <Grid
                  container
                  spacing={2}>
                  <Grid
                    className={styles.tabPanelStyle}
                    key={index}
                    item>
                    <RTag show='desktop'>
                      <SchedulingTimelineEad
                        user={user}
                        date={date}
                        selectStep={selectStep}
                        activities={activities}
                        participant={participant}
                        subscriptions={subscriptions}
                      />
                    </RTag>
                    <RTag show='mobile'>
                      <TimelineAccordionEad
                        user={user}
                        selectStep={selectStep}
                        activities={activities}
                        participant={participant}
                        subscriptions={subscriptions}
                      />
                    </RTag>
                  </Grid>
                </Grid>
              </Grid>
            )
          })
        ) : (
          <Typography variant='h6'>
            <Translator transRef='translation.Não há atividades agendadas' />
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

export default TabNavEad
