import { Config } from '@itarget/shared'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import HeaderDefault from './Default'
import HeaderStore from './HeaderStore'
import MenuSingleStore from './MenuSingleStore'
import { useParams } from 'react-router-dom'

const Header = ({
  logo,
  backgroundHeader,
  client,
  shoppingCarts,
  menuItems,
  menuItemsC,
  user,
  goTo,
  onLogout,
  center_cost_id,
  anchorEl,
  setting,
  handleClick,
  handleClose,
  statusAssociated,
  category,
}) => {
  const { cc: centerCostId } = useParams()
  const settingCourse = useSelector((state) => state.setting.settingCommerce)
  const conditionModel = settingCourse?.tipo_layout
  let selectedCenterCostId = centerCostId || center_cost_id
  const [size, setSize] = useState(window.innerWidth)
  const [showMenu, setShowMenu] = useState(size <= 950 ? 'hide' : 'show')

  useEffect(() => {
    window.addEventListener('resize', () => setSize(window.innerWidth))
    window.removeEventListener('resize', () => setSize(window.innerWidth))
  }, [])

  useEffect(() => {
    if (size <= 950) {
      setShowMenu('hide')
    } else {
      document.body.style.overflow = 'auto'
      setShowMenu('show')
    }
  }, [size])

  function toggleMenu() {
    if (showMenu === 'hide') {
      setShowMenu('show')
    } else {
      setShowMenu('hide')
    }
  }
  const tryRequire = (path) => {
    try {
      return require(`${path}`)
    } catch (err) {
      return null
    }
  }

  const onClickLogin = () => {
    goTo(`login${settingCourse?.centro_custo_padrao ? `/${selectedCenterCostId}` : '/'}`)
  }

  const onClickRegister = () => {
    goTo(`login${settingCourse?.centro_custo_padrao ? `/${selectedCenterCostId}` : '/'}?state=register`)
  }

  const onClickShoppingCart = () => {
    goTo('shopping-cart')
  }

  const header = () => {
    if (conditionModel === 6 && !tryRequire(`./Clients/${Config.client}/Header`)) {
      return HeaderStore
    }
    if (tryRequire(`./Clients/${Config.client}/${center_cost_id}/Header`)) {
      return tryRequire(`./Clients/${Config.client}/${center_cost_id}/Header`).default
    }
    if (tryRequire(`./Clients/${Config.client}/Header`)) {
      return tryRequire(`./Clients/${Config.client}/Header`).default
    }
    return HeaderDefault
  }

  const HeaderReturn = header()

  return (
    <>
      <HeaderReturn
        showMenu={showMenu}
        toggleMenu={toggleMenu}
        statusAssociated={statusAssociated}
        category={category}
        logo={logo}
        backgroundHeader={backgroundHeader}
        client={client}
        shoppingCarts={shoppingCarts}
        menuItems={menuItems}
        menuItemsC={menuItemsC}
        user={user}
        goTo={goTo}
        onLogout={onLogout}
        center_cost_id={selectedCenterCostId}
        anchorEl={anchorEl}
        setting={setting}
        handleClick={handleClick}
        handleClose={handleClose}
        onClickLogin={onClickLogin}
        onClickRegister={onClickRegister}
        onClickShoppingCart={onClickShoppingCart}
      />
      {conditionModel === 6 && tryRequire(`./Clients/${Config.client}/Header`) && <MenuSingleStore cc={selectedCenterCostId} />}
    </>
  )
}

export default Header
