import { checkJwt, useRedirect } from "@itarget/shared";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { auth, language as languageRedux } from "../../redux";
import DasboardView from "./views/DasboardView";
import { useEffect, useCallback } from "react";

const DasboardContainer = () => {
  const routeParams = useParams();
  const { goTo } = useRedirect();
  const dispatch = useDispatch();
  const centerCostId = useSelector((state) => state.auth.cc);

  const onValidJwt = (validateJwt) => {
    dispatch(auth.actions.setUser(validateJwt));
    dispatch(auth.actions.setIsAutenticated(true));
    if (routeParams?.page) {
      const decodedPage = decodeURIComponent(atob(routeParams.page));
      goTo(decodedPage);
      return
    }
    goTo('new-panel');
  };

  const onInvalidJwt = useCallback(() => {
    dispatch(auth.actions.logout());
    return goTo(`offers-unique/${centerCostId}`);
  }, [centerCostId, goTo, dispatch]);

  const { isLoadingValidateJwt } = checkJwt({
    jwt: routeParams.jwt,
    centro_custo_id: centerCostId,
    onValid: onValidJwt,
    onInvalid: onInvalidJwt,
  });

  useEffect(() => {
    if (!routeParams.jwt && centerCostId) {
      let url = routeParams.url ? atob(routeParams.url) : `offers-unique/${centerCostId}`;
      if (routeParams.lang) {
        let lang = routeParams.lang === '2' ? "en" : routeParams.lang === '3' ? "es" : "pt-br"
        dispatch(languageRedux.actions.setLang(lang));
        goTo(`${url}`);
      } else {
        goTo(`${url}`);
      }
    }
  }, [routeParams, onInvalidJwt, centerCostId, goTo]);

  return <DasboardView isLoadingValidateJwt={isLoadingValidateJwt} />;
};

export default DasboardContainer;
