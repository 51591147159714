import { memo, useEffect, useState } from "react"

import { Container, Grid, Typography, Icon, IconButton, FormControlLabel, Radio, Button, TextField, Checkbox, RadioGroup } from "@material-ui/core"
import "./style.scss"
import { Field, Form, Formik, ErrorMessage } from "formik"
import { Config, useFetchApi, Translator } from "@itarget/shared"
import { useSelector } from "react-redux"
import { ModalFullScreen } from '../ModalFullScreen'

function QuestionOther({ question, answer, translation, value, others, handleChange }) {
  if (!(answer.resposta_outros == 'S' && (others?.includes(answer.resposta_id) || value))) {
    return <></>
  }
  return <TextField
    key={answer.resposta_id}
    defaultValue={value}
    label={translation.others}
    name={`others[${question.pergunta_id}]`}
    variant="outlined"
    onChange={handleChange}
  />
}

const ModalQuestions = ({
  setShowQuiz,
  centerCostId,
  questionDestination,
}) => {
  const [initialValues, setInitialValues] = useState();
  const [others, setOthers] = useState([]);
  const selectedLang = useSelector((state) => state.language.lang);
  const user = useSelector((state) => state.auth.user);

  const {
    data: questions,
    fetchData: fetchQuestions,
  } = useFetchApi(Config.baseURL + `api/${selectedLang}/question?centro_custo_id=${centerCostId}&pessoa_id=${user.data.id}&destino_pergunta=${questionDestination}`)

  const { data: dataSendAnswers, fetchData: fetchSendAnswers } = useFetchApi(Config.baseURL + `api/${selectedLang}/question`, "POST")

  useEffect(() => {
    if (dataSendAnswers) {
      setShowQuiz(false);
      fetchQuestions();
    }
  }, [setShowQuiz, dataSendAnswers])

  const translation = {
    typeHere: Translator({ transRef: "translation.digite aqui" }),
    others: Translator({ transRef: "translation.Outros" }),
  }

  useEffect(() => {
    fetchQuestions();
  }, [])

  useEffect(() => {
    if (questions) {
      setInitialValues(createResponseObject(questions));
    }
  }, [questions])

  const createResponseObject = (jsonData) => {
    const answers = { 'answers': {}, 'others': {}, 'checkbox': {} };
    if (jsonData?.data?.length > 0) {
      jsonData.data[0].pergunta.forEach((pergunta) => {
        if (pergunta.tipo_resposta == 1) {
          answers['answers'][pergunta.pergunta_id] = `${pergunta.resposta[0].resposta_id}`;
        }
        if (pergunta.pessoa_resposta?.length > 0) {
          const answer = pergunta.pessoa_resposta[0];
          answers['answers'][pergunta.pergunta_id] = `${answer.resposta_id}`;
          if (!others.includes(parseInt(answer.resposta_id))) {
            answers['others'][pergunta.pergunta_id] = `${answer.outros}`;
            setOthers((others) => {
              return [...others, parseInt(answer.resposta_id)];
            })
          }
        }
      });
    }

    return answers;
  };

  const handleChangeOther = (event) => {
    const questionId = event.target.name.match(/\[(\d+)\]/);
    if (questionId && questionId[1]) {
      setOthers((others) => {
        return others.filter(id => !questions?.data[0]?.pergunta
          .filter(p => p.pergunta_id == questionId[1])[0]
          .resposta.map(r => r.resposta_id).includes(id));
      })
    }
    if (!others.includes(parseInt(event.target.value))) {
      setOthers((others) => {
        return [...others, parseInt(event.target.value)];
      })
    }
  }

  const personAnswer = (question, type = '') => {
    let maxAttempts = Math.max(...question?.pessoa_resposta.map(item => item.tentativas));
    maxAttempts = maxAttempts - 1;
    if (type == 'other') {
      return question?.pessoa_resposta[maxAttempts]?.outros
    }
    return `${question?.pessoa_resposta[maxAttempts]?.resposta_id}` || '';
  }

  const validateRequired = (values, questions) => {
    const errors = {};
    questions.forEach((question) => {
      errors.answers = errors.answers || {};
      if (question.requerido === 'S') {
        if (![1, 3].includes(question.tipo_resposta) && !values.answers[question.pergunta_id]) {
          errors.answers[question.pergunta_id] = 'Campo obrigatório';
        }
        if (question.tipo_resposta == 1 && !values.others[question.pergunta_id]) {
          errors.answers[question.pergunta_id] = 'Campo obrigatório';
        }
        if (question.tipo_resposta == 3) {
          const checkTrue = Object.entries(values.checkbox)
            .filter(([key, value]) => value === true)
            .map(([key, value]) => parseInt(key, 10));
          if (!question.resposta.map(r => r.resposta_id).some(item => checkTrue.includes(item))) {
            errors.answers[question.pergunta_id] = 'Campo obrigatório';
          }
        }
        if (question.resposta.some(resposta => resposta.resposta_outros === 'S'
          && values.answers[question.pergunta_id] === resposta.resposta_id.toString())
          && (values.others[question.pergunta_id] === undefined || values.others[question.pergunta_id] == '')
        ) {
          errors.answers[question.pergunta_id] = 'Campo outros obrigatório';
        }
      }
    });
    return errors.answers;
  };

  if (!questions || !initialValues) {
    return <></>
  }

  return (
    <ModalFullScreen>
      <Container>
        <Grid container className="grid-header">
          <Grid item xs={10} md={10}>
            <Typography className="text-header">
              <Translator transRef={`translation.${questions?.data[0]?.descricao}`} />
            </Typography>
          </Grid>
          <Grid item xs={2} md={2}>
            <IconButton onClick={() => setShowQuiz(false)} className="icon-header">
              <Icon>highlight_off</Icon>
            </IconButton>
          </Grid>
        </Grid>
        <Container maxWidth="md">
          <Grid className="grid-complet">
            <Formik
              initialValues={initialValues}
              validate={(values) => validateRequired(values, questions?.data[0]?.pergunta)}
              onSubmit={async (values) => {
                const answers = Object.values(values.answers);
                const keysAnswers = Object.keys(values.answers);
                const checkbox = Object.values(values.checkbox);
                const keysCheckbox = Object.keys(values.checkbox);
                let sendAnswers = [];
                for (let i = 0; i < answers.length; i++) {
                  sendAnswers.push({
                    pessoa_id: user.data.id,
                    resposta_id: parseInt(answers[i]),
                    outros: values.others[keysAnswers[i]] || '',
                  })
                }
                for (let i = 0; i < checkbox.length; i++) {
                  sendAnswers = sendAnswers.filter(f => f.resposta_id != keysCheckbox[i]);
                  if (checkbox[i]) {
                    sendAnswers.push({
                      pessoa_id: user.data.id,
                      resposta_id: parseInt(keysCheckbox[i]),
                      outros: '',
                    })
                  }
                }
                fetchSendAnswers(JSON.stringify({
                  respostas: sendAnswers
                }));
              }}
            >
              {({ values, errors, handleChange }) => (
                <Form>
                  {questions?.data[0]?.pergunta?.map((question, i) => (
                    <>
                      <Typography className="question-title">
                        {i + 1} - {question.descricao}
                      </Typography>
                      <Grid key={i} className="camps">
                        {question.tipo_resposta === 1 && (
                          <>
                            <Field
                              type="hidden"
                              name={`answers[${question.pergunta_id}]`}
                              defaultValue={`${question.resposta[0].resposta_id}`}
                              onChange={handleChange}
                            />
                            <TextField
                              multiline
                              name={`others[${question.pergunta_id}]`}
                              onChange={handleChange}
                              variant="outlined"
                              defaultValue={personAnswer(question, 'other')}
                              label={question.descricao ? question.descricao : translation?.typeHere}
                              className="field-textarea"
                            />
                          </>
                        )}
                        {question.tipo_resposta === 2 && (
                          <>
                            <RadioGroup
                              name={`answers[${question.pergunta_id}]`}
                              onChange={(e) => { handleChange(e); handleChangeOther(e) }}
                              defaultValue={personAnswer(question)}
                            >
                              {question.resposta.map((answer) => (
                                <FormControlLabel variant="outlined" key={answer.resposta_id} value={`${answer.resposta_id}`} control={<Radio />} label={answer.descricao} />
                              ))}
                            </RadioGroup>
                            {
                              question.resposta.map((answer) => {
                                return <QuestionOther
                                  key={answer.resposta_id}
                                  question={question}
                                  value={personAnswer(question, 'other')}
                                  answer={answer}
                                  translation={translation}
                                  others={others}
                                  handleChange={handleChange} />
                              })
                            }
                          </>
                        )}
                        {question.tipo_resposta === 3 && (
                          <div role="group" aria-labelledby="checkbox-group">
                            {question.resposta.map((answer, i) => (
                              <label>
                                <Field
                                  name={`checkbox[${answer.resposta_id}]`}
                                  handleChange={handleChange}
                                  render={({ field }) => {
                                    let checked = null;
                                    if (field.value == undefined) {
                                      checked = question?.pessoa_resposta?.filter(p => p.resposta_id == answer.resposta_id)?.length > 0;
                                    } else {
                                      checked = field.value;
                                    }
                                    return (
                                      <input
                                        type="checkbox"
                                        checked={checked}
                                        {...field} />
                                    );
                                  }}
                                />
                                {answer.descricao}
                              </label>
                            ))}
                          </div>
                        )}
                        {question.tipo_resposta === 4 && (
                          <TextField select
                            onChange={handleChange}
                            variant="outlined"
                            name={`answers[${question.pergunta_id}]`}
                            defaultValue={personAnswer(question)}
                          >
                            {question.resposta.map((answer) => (
                              <option key={answer.resposta_id} value={answer.resposta_id}>
                                {answer.descricao}
                              </option>
                            ))}
                          </TextField>
                        )}
                        {errors && errors[question.pergunta_id] && (
                          <div style={{ color: 'red' }}>{errors[question.pergunta_id]}</div>
                        )}

                      </Grid>
                    </>
                  ))}
                  <Grid className="grid-btn">
                    <Button className="btn-confirm" type="submit">
                      <Translator transRef="translation.Confirmar" />
                    </Button>
                  </Grid>
                </Form >
              )}
            </Formik>
          </Grid>
        </Container>
      </Container>
    </ModalFullScreen>
  )
}

export default memo(ModalQuestions)
