import {
  Container,
  AppBar,
  Tabs,
  Tab,
  Icon,
  Grid,
  Hidden,
} from "@material-ui/core";
import "./style.scss";

import {
  FormLoginContainer,
  Form,
  Translator
} from "@itarget/shared";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Footer from "../../../components/Footer/Footer";
import { useCallback } from 'react'


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="tabPanel-div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const LoginView = ({
  value,
  handleChange,
  setting,
  bottomRegister,
  auth,
  toast,
  settingCommerce,
  isLoadingService,
  isLoadingSettingLogin
}) => {
  const { cc } = useParams()
  const centerCostId = useSelector((state) => state.auth.cc);
  const settingCourse = useSelector((state) => state.setting.settingCommerce)
  let selectedCenterCostId = cc || centerCostId
  
  const label = useCallback({ 
    login: Translator({
      transRef: "translation.Tenho Login"
    }),
    register: Translator({
      transRef: "translation.Novo Cadastro"
    })
  }, [bottomRegister])
 
  return (
    <Container maxWidth="lg" className="login-container">
      <Grid style={{ padding: '15px' }}>
        {!isLoadingService && <img
          className="img-logo"
          src={settingCommerce?.url_image_login}
          width="100%"
          alt="logo"
        />}
      </Grid>
      <Grid>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="primary"
            variant="fullWidth"
            className="tabs-login"
          >
            <Tab
              className="tab-item tab-login"
              icon={<Icon>how_to_reg</Icon>}
              label={label.login}
            />
            {!isLoadingSettingLogin && ['S', undefined, false].includes(bottomRegister) &&
              <Tab
                className="tab-item tab-register"
                icon={<Icon>person_add</Icon>}
                label={label.register}
              />
            }
          </Tabs>
        </AppBar>

        <TabPanel className="tab-loginForm" value={value} index={0}>
          <Grid>
            <FormLoginContainer
              tipo_interface_id={109}
              auth={auth}
              centro_custo_id={selectedCenterCostId}
              setting={setting}
              redirect={`recovery-login${settingCourse?.centro_custo_padrao ? `/${selectedCenterCostId}` : ''}`}
              backGroundColorLogin='#35446B'
              colorLogin='#fff'
            />
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Form
            auth={auth}
            toast={toast}
            centro_custo_id={selectedCenterCostId}
            tipo_interface_id={109}
            description={false}
            rules={true}
          />
        </TabPanel >
      </Grid >
      <Hidden mdDown>
        <Footer />
      </Hidden>
    </Container >
  );
};

export default LoginView;