import { Translator } from '@itarget/shared'

export const useTranslation = () => ({
  register: Translator({ transRef: 'translation.Cadastre-se' }).props.children,
  login: Translator({ transRef: 'translation.Login' }).props.children,
  noCategory: Translator({ transRef: 'translation.Sem categoria' }).props.children,
  noAssociation: Translator({ transRef: 'translation.Não Sócio' }).props.children,
  return: Translator({ transRef: 'translation.Fechar menu' }).props.children,
  logoAlt: Translator({ transRef: 'translation.Logo da entidade' }).props.children,
})
