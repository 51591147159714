import { Avatar, Box, Typography } from '@material-ui/core'
import { useSpeakerItemStyles } from './styles'

const SpeakerItem = ({ data, displayBackgroundColor = true, displayPaper = true, enableRounded = true, onClick = undefined }) => {
  const styles = useSpeakerItemStyles({ displayPaper, displayBackgroundColor, enableRounded, hasOnClick: !!onClick })
  const { name, image, paper } = data

  const handleOnClick = () => {
    onClick && onClick(data)
  }

  return (
    <Box
      onClick={handleOnClick}
      className={styles.container}>
      <Avatar
        alt={name}
        src={image}
        className={styles.avatar}
      />
      <Box className={styles.information}>
        <Typography className={styles.name}>{name}</Typography>
        <Typography className={styles.paper}>{paper}</Typography>
      </Box>
    </Box>
  )
}

export default SpeakerItem
