import { Box, Icon, IconButton, Typography } from '@material-ui/core'
import { useStyles } from './styles'
import Item from './item'
import { useTranslation } from './translation'

const Menu = ({ data, title, enabled = false, handleClose, level = 0 }) => {
  const styles = useStyles({ enabled, level })
  const translation = useTranslation()
  return (
    <Box
      data-enabled={enabled}
      className={styles.menuContainer}>
      <Box
        onClick={() => handleClose({ value: false })}
        className={styles.menuReturnButton}>
        <Icon className={styles.menuIconButton}>arrow_back_ios_new</Icon>
        <Typography className={styles.itemTitle}>{title || translation.return}</Typography>
      </Box>
      {data && data.length > 0 && data.map((row, index) => {
        return (
          <Item
            {...row}
            key={index}
          />
        )
      })}
    </Box>
  )
}

export default Menu
