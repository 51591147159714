import { Icon, IconButton } from '@material-ui/core'
import CartButton from '../../../CartButton'
import UserMenu from '../../../UserMenu'
import LoginButton from '../../../LoginButton'

export const Trailing = ({ user, styles, goTo, shoppingCarts, onLogout, statusAssociated, category, toggleMenu }) => {
  return (
    <>
      {user?.data ? (
        <>
          <div className={styles.glass}>
            <CartButton goTo={goTo} shoppingCarts={shoppingCarts} />
            <UserMenu user={user} onLogout={onLogout} goTo={goTo} statusAssociated={statusAssociated} category={category} />
            <IconButton onClick={toggleMenu} className={`view-list-red ${styles.menuButton}`}>
              <Icon>view_list</Icon>
            </IconButton>
          </div>
        </>
      ) : (
        <>
          <LoginButton backgroundColor='#2a51c7' />
          <div className={styles.menuButton}>
            <IconButton onClick={toggleMenu}>
              <Icon>view_list</Icon>
            </IconButton>
          </div>
        </>
      )}
    </>
  )
}
