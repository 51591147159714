import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { payment, toast } from "../../redux";
import { Config, useFetchApi, useRedirect } from "@itarget/shared";
import queryString from "query-string";
import PaymentView from "./view/PaymentView";

const PaymentContainer = () => {
  const settingCommerce = useSelector((state) => state.setting.settingCommerce);
  const selectedLang = useSelector((state) => state.language.lang)
  const paymentType = useSelector((state) => state.payment.type);
  const centerCostId = useSelector((state) => state.auth.cc);
  const [personId, setPersonId] = useState();
  const routeParams = useParams();
  const { goTo } = useRedirect();

  const { data: subscription, fetchData: fecthSubscription } = useFetchApi(
    Config.baseURL +
    `api/${selectedLang}/subscription/pending?` +
    queryString.stringify(
      {
        conta_receber_id: Buffer.from(routeParams?.id, "base64")
          .toString("ascii")
          .replace(/\r/g, "")
          .split(","),
      },
      { arrayFormat: "bracket" }
    )
  );

  const { data: subscriptionPaid, fetchData: fecthSubscriptionPaid } =
    useFetchApi(
      Config.baseURL +
      `api/${selectedLang}/subscription/complete-list?status_inscricao=2&per_page=1&` +
      queryString.stringify(
        {
          conta_receber_id: Buffer.from(routeParams?.id, "base64")
            .toString("ascii")
            .replace(/\r/g, "")
            .split(","),
        },
        { arrayFormat: "bracket" }
      )
    );

  const { data: person, fetchData: fecthPerson } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/person/${personId}`
  );

  const { data: addressCorresp, fetchData: fecthAddressCorresp } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/address/correspondence?pessoa_id=${personId}`
  );

  const reprintBillet = async (row) => {
    if (row?.hash_transacao.indexOf("http") !== -1) {
      var my_form = document.createElement("FORM");
      my_form.name = "myForm";
      my_form.method = "GET";
      my_form.action = row?.hash_transacao;
      my_form.target = "_blank";
      document.body.appendChild(my_form);
      my_form.submit();
      return
    }
    var my_form = document.createElement("FORM");
    my_form.name = "myForm";
    my_form.method = "POST";
    my_form.action = "https://shopline.itau.com.br/shopline/shopline.aspx";
    my_form.target = "_blank";

    var my_tb = document.createElement("INPUT");
    my_tb.type = "HIDDEN";
    my_tb.name = "dc";
    my_tb.value = row?.hash_transacao;
    my_form.appendChild(my_tb);

    document.body.appendChild(my_form);
    my_form.submit();
  };

  useEffect(() => {
    if (routeParams?.id) {
      fecthSubscription();
      fecthSubscriptionPaid();
    }
  }, [routeParams?.id, fecthSubscription, fecthSubscriptionPaid]);

  useEffect(() => {
    if (subscription?.data[0]?.pessoa_id) {
      setPersonId(subscription?.data[0]?.pessoa_id);
    }
  }, [subscription]);

  useEffect(() => {
    if (personId) {
      fecthPerson();
      fecthAddressCorresp();
    }
  }, [personId, fecthPerson, fecthAddressCorresp]);

  useEffect(() => {
    if (subscriptionPaid?.data[0]?.pessoa_id) {
      setPersonId(subscriptionPaid?.data[0]?.pessoa_id);
    }
  }, [subscriptionPaid]);

  return (
    <PaymentView
      settingCommerce={settingCommerce}
      onInvalidCardPayment={() => { }}
      toast={toast}
      total={subscription?.data
        ?.map((row) => parseFloat(row.cr_valor))
        .reduce(function (total, numero) {
          return total + numero;
        }, 0)}
      centerCostId={centerCostId}
      payment={payment}
      person={{ data: person }}
      paymentType={paymentType}
      subscription={subscription?.data}
      addressCorresp={addressCorresp?.data}
      id={routeParams?.id}
      reprintBillet={reprintBillet}
      subscriptionPaid={subscriptionPaid?.data}
      goTo={goTo}
    />
  );
};

export default PaymentContainer;
