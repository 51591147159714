import { Box } from '@material-ui/core'
import { Button } from '@itarget/shared'
import { useWatchStyles } from './styles'
import { useWatchTranslation } from './translation'

const Watch = ({ onWatchStream = () => {}, onWatchRecorded = () => {}, enableStream = false, enableRecorded = false }) => {
  const styles = useWatchStyles()
  const translation = useWatchTranslation()
  if (!enableStream && !enableRecorded) return <></>
  return (
    <Box className={styles.container}>
      {enableStream && (
        <Button
          icon={'live_tv'}
          onClick={onWatchStream}
          className={`${styles.button} ${styles.buttonOnWatchStream}`}>
          {translation.watchStream}
        </Button>
      )}
      {enableRecorded && (
        <Button
          icon={'video_library'}
          onClick={onWatchRecorded}
          className={`${styles.button} ${styles.buttonOnWatchRecorded}`}>
          {translation.watchRecorded}
        </Button>
      )}
    </Box>
  )
}

export default Watch
