import { makeStyles } from '@material-ui/styles'

export const useSpeakerItemStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    cursor: ({ hasOnClick }) => (hasOnClick ? 'pointer' : 'initial'),
    borderRadius: ({ enableRounded }) => theme.spacing(enableRounded ? 1 : 0),
    backgroundColor: ({ displayBackgroundColor }) =>
      displayBackgroundColor ? theme.palette.programmingSection.item.speaker.backgroundColor : '',
  },
  avatar: {
    width: theme.typography.pxToRem(34),
    height: theme.typography.pxToRem(34),
    fontSize: theme.typography.pxToRem(12),
  },
  information: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  name: {
    ...theme.typography.caption,
    lineHeight: 1,
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(10),
    color: theme.palette.programmingSection.item.speaker.nameColor,
  },
  paper: {
    display: ({ displayPaper }) => (displayPaper ? 'initial' : 'none'),
    ...theme.typography.caption,
    lineHeight: 1,
    fontSize: theme.typography.pxToRem(10),
    color: theme.palette.programmingSection.item.speaker.paperColor,
  },
}))

export const useAllSpeakersStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    gap: theme.spacing(0.25),
    borderRadius: theme.spacing(1),
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    justifyContent: 'center',
    marginTop: theme.spacing(0.5),
    height: theme.typography.pxToRem(20),
    backgroundColor: () => theme.palette.programmingSection.item.speaker.backgroundColor,
    transition: 'all 400ms ease-in-out 1s',
    '&:hover': {
      gap: theme.spacing(3),
      transitionDelay: 0,
    },
    '&:hover $dot': {
      transitionDelay: 0,
      position: 'relative',
      transform: 'scale(2.5)',
      padding: theme.spacing(0.5),
    },
    '& $dot::after': {
      top: '50%',
      left: '50%',
      position: 'absolute',
      content: 'attr(data-page)',
      transform: 'translate(-50%, -50%)',
      fontSize: theme.typography.pxToRem(5),
      color: theme.palette.programmingSection.item.speaker.dotContent,
      transition: 'all 400ms ease-in-out 1s',
      opacity: 0,
    },
    '&:hover $dot::after': {
      transitionDelay: 0,
      opacity: 1,
    },
    '&:hover $dot[data-selected="true"]::after': {
      color: theme.palette.programmingSection.item.speaker.dotContentSelected,
    },
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      '&': {
        gap: theme.spacing(3),
        height: theme.typography.pxToRem(35),
      },
      '& $dot': {
        position: 'relative',
        transform: 'scale(2.5)',
        padding: theme.spacing(0.5),
      },
      '& $dot::after': {
        top: '50%',
        left: '50%',
        opacity: 1,
        position: 'absolute',
        content: 'attr(data-page)',
        transform: 'translate(-50%, -50%)',
        fontSize: theme.typography.pxToRem(5),
        color: theme.palette.programmingSection.item.speaker.dotContent,
      },
      '& $dot[data-selected="true"]::after': {
        fontWeight: 'bold',
        color: theme.palette.programmingSection.item.speaker.dotContentSelected,
      },
    },
  },
  dot: {
    cursor: 'pointer',
    width: theme.typography.pxToRem(5),
    height: theme.typography.pxToRem(5),
    transition: 'all 400ms ease-in-out 1s',
    backgroundColor: theme.palette.programmingSection.item.speaker.dot,
    '&:hover': {
      transitionDelay: 0,
    },
    '&[data-selected="true"]': {
      backgroundColor: theme.palette.programmingSection.item.speaker.dotSelected,
    },
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      width: theme.typography.pxToRem(10),
      height: theme.typography.pxToRem(10),
    },
  },
}))

export const useLectureItemStyles = makeStyles((theme) => ({
  container: {
    flex: 'none',
    width: 'auto',
    display: 'flex',
    userSelect: 'none',
    gap: theme.spacing(1),
    flexDirection: 'column',
    scrollSnapAlign: 'end',
    padding: theme.spacing(1, 1.5),
    borderRadius: theme.spacing(1.5),
    width: theme.typography.pxToRem(271),
    minHeight: theme.typography.pxToRem(164),
    backgroundColor: theme.palette.programmingSection.item.lecture.backgroundColor,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      minHeight: 0,
      width: '100%',
    },
  },
  information: {
    minHeight: 54,
    display: 'flex',
    gap: theme.spacing(1.5),
    flexDirection: 'column',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      minHeight: 0,
    },
  },
  title: {
    ...theme.typography.body2,
    lineHeight: 1,
  },
  date: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dateText: {
    ...theme.typography.body2,
    lineHeight: 1,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.programmingSection.item.lecture.dateTimeColor,
  },
  uniqueSpeaker: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    justifyContent: 'center',
  },
  speakers: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    position: 'relative',
    gap: theme.spacing(1),
    justifyContent: 'center',
    height: theme.typography.pxToRem(51),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      justifyContent: 'flex-start',
    },
  },
  speakersText: {
    ...theme.typography.caption,
    fontWeight: 'bold',
    textWrap: 'nowrap',
    lineHeight: 1,
  },
  avatar: {
    '& .MuiAvatar-root': {
      width: theme.typography.pxToRem(30),
      height: theme.typography.pxToRem(30),
      fontSize: theme.typography.pxToRem(12),
    },
  },
  button: {
    flex: 0,
  },
}))

export const useLectureStyles = makeStyles((theme) => ({
  container: {
    minWidth: '100%',
    alignItems: 'flex-start',
    gap: theme.spacing(1.25),
    display: ({ display }) => (display ? 'flex' : 'none'),
    transition: 'transform 400ms ease-in-out',
    '&:hover': {
      transform: 'scale(1.02)',
      transitionDelay: 0,
    },
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      display: 'none !important',
    },
  },
  containerMobile: {
    display: 'none',
    minWidth: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(1.25),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      display: ({ display }) => `${display ? 'flex' : 'none'} !important`,
      paddingLeft: 70,
    },
  },
}))

export const useItemContainerStyles = makeStyles((theme) => ({
  lectureContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}))

export const useItemStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    gap: theme.spacing(3),
    flexDirection: 'column',
    padding: theme.spacing(2, 2.5),
    borderRadius: `${theme.spacing(1.5)}px`,
    backgroundColor: theme.palette.programmingSection.item.container.backgroundColor,
  },
  itemContainer: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    gap: theme.spacing(2.5),
  },
  time: {
    ...theme.typography.h6,
    color: theme.palette.programmingSection.item.time.color,
    fontWeight: 400,
    lineHeight: 1,
  },
  informationButtonContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2.5),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      gap: theme.spacing(1.5),
      flexDirection: 'column',
    },
  },
  informationContainer: {
    flex: 1,
    display: 'flex',
    gap: theme.spacing(1),
    flexDirection: 'column',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      gap: theme.spacing(1.5),
    },
  },
  topInformationContainer: {
    display: 'flex',
    gap: theme.spacing(0.5),
    flexDirection: 'column',
  },
  type: {
    ...theme.typography.caption,
    color: theme.palette.programmingSection.item.information.typeActivityColor,
    fontSize: theme.typography.pxToRem(10),
    lineHeight: 1,
  },
  theme: {
    ...theme.typography.body2,
    color: theme.palette.programmingSection.item.information.colorBold,
    lineHeight: 1,
  },
  bottomInformationContainer: {
    display: 'flex',
    overflow: 'hidden',
    flexWrap: 'nowrap',
    alignItems: 'center',
    flexDirection: 'row',
    gap: theme.spacing(1.25),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      gap: theme.spacing(1),
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  tag: {
    ...theme.typography.caption,
    paddingLeft: theme.spacing(0.75),
    borderLeft: ({ tagColor }) => `3px solid ${tagColor || theme.palette.programmingSection.item.information.defaultAxiosColor}`,
    color: theme.palette.programmingSection.item.information.color,
    lineHeight: 1,
  },
  roomGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  room: {
    ...theme.typography.caption,
    color: theme.palette.programmingSection.item.information.color,
    textAlign: 'center',
    lineHeight: 1,
  },
  button: {
    flex: '0',
    width: 'fit-content',
    height: 'min-content',
    color: theme.palette.programmingSection.item.button.color,
    backgroundColor: theme.palette.programmingSection.item.button.backgroundColor,
    '&:hover': {
      backgroundColor: theme.palette.programmingSection.item.button.backgroundColorHover,
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      width: '100%',
    },
  },
}))

export const useSectionStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    gap: theme.spacing(1.5),
    flexDirection: 'column',
    justifyContent: 'center',
  },
}))

export const useSectionTitleStyles = makeStyles((theme) => ({
  title: {
    ...theme.typography.caption,
    lineHeight: 1,
  },
}))

export const useRootStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    maxWidth: '90%',
    alignItems: 'center',
    gap: theme.spacing(3.75),
    flexDirection: 'column',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      maxWidth: '100%',
    },
  },
}))
