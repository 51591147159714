import React, { createContext, useState } from "react"

export const MenuStoreContext = createContext([])

const MenuStoreContextProvider = ({ children }) => {
  const [products, setProducts] = useState([])

  const value = {products, setProducts}

  return <MenuStoreContext.Provider value={value}>{children}</MenuStoreContext.Provider>
}

export default MenuStoreContextProvider
