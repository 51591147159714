import { Box, Chip, Icon, Typography } from '@material-ui/core'
import { usePendingStyles } from './styles'
import { usePendingTranslation } from './translation'

const Pending = ({ onRedirect = () => {} }) => {
  const styles = usePendingStyles()
  const translation = usePendingTranslation()

  return (
    <Box className={styles.container}>
      <Box className={styles.actionsContainer}>
        <Chip
          className={styles.chip}
          label={translation.pending}
        />
        <Box
          onClick={onRedirect}
          className={styles.linkContainer}>
          <Icon className={styles.linkIcon}>confirmation_number</Icon>
          <Typography className={styles.link}>{translation.redirectToRegistered}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default Pending
