import {Grid, Icon, Modal, Typography} from '@material-ui/core'
import {Translator} from '@itarget/shared'

const File = ({title, description, document}) => {
  return (
    <Grid aria-label='Arquivo para dowload.' item xs={12} xl={2} lg={3} md={4} sm={6} className='file'>
      <Typography aria-label='Título do arquivo.' variant='subtitle1' className='title'>
        {title}
      </Typography>
      <Grid onClick={() => window.open(document, '_blank', 'fullscreen=yes')} aria-label='Icone.' className='file-icon'>
        <Icon className='icon'>description</Icon>
        <Icon className='icon-helper'>file_download</Icon>
      </Grid>
      {description ? (
        <Typography aria-label='Descrição do arquivo.' variant='caption' className='title'>
          {description}
        </Typography>
      ) : (
        <></>
      )}
    </Grid>
  )
}

export const DownloadComplementary = ({openStatus, handleClose, files}) => {
  return (
    <Modal open={openStatus} onClose={handleClose} aria-labelledby='simple-modal-title' aria-describedby='simple-modal-description'>
      <Grid container xs={8} md={8} sm={10} className='complementary'>
        <Grid item className='complementary-header'>
          <Typography className='title'>
            <Translator transRef={'translation.ARQUIVOS'} />
          </Typography>
          <Icon className='icon' onClick={() => handleClose()}>
            close
          </Icon>
        </Grid>
        <Grid container xs={12} className='complementary-file'>
          {files?.map((file) => {
            if (!file.nome_armazenado) return
            return <File title={file.nome_original} description={file?.observacao} document={file.nome_armazenado} />
          })}
        </Grid>
      </Grid>
    </Modal>
  )
}

export default DownloadComplementary
