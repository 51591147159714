import React, { createContext, useState } from "react"

export const SidebarContext = createContext(null)

const SidebarContextProvider = ({ children }) => {
  const [displayDate, setDisplayDate] = useState(null)
  const [expand, setExpand] = useState(true)
  const [open, setOpen] = useState(true)

  const value = { open, setOpen, displayDate, setDisplayDate, expand, setExpand}

  return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
}

export default SidebarContextProvider
