import React from "react";
import { Switch, Route } from "react-router-dom";

import MyCourseContainer from "./MyCourseContainer";
import DetailMyCourseGroupContainer from "./DetailMyCourseGroupContainer";

const RouterCourse = () => {
  return (
    <Switch>
      <Route path="/my-course/:id" isProtected>
        <DetailMyCourseGroupContainer />
      </Route>
      <Route path="/my-course" isProtected>
        <MyCourseContainer />
      </Route>
    </Switch>
  );
};

export default RouterCourse;
