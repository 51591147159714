import { useRedirect, useFetchApi, Config } from "@itarget/shared";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import MyCourseView from "./views/MyCourseView";
import { subscription } from "../../redux";

const MyCourseContainer = () => {
  const { goTo } = useRedirect();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const selectedLang = useSelector((state) => state.language.lang)
  const { data: showRoomRequest, fetchData: fetchListShowRoom } = useFetchApi(
    `${Config.baseURL}api/${selectedLang}/webinar/show-room`,
    "POST"
  );

  const selectCourse = async (course, toGo) => {
    if (toGo === "aovivo" && course?.disponivel_transmissao === "S") {
      if (course?.tipo_transmissao > 0) {
        if (course?.tipo_transmissao === 11) {
          var token = course.token_transmissao.split("|");
          fetchListShowRoom(
            JSON.stringify({
              token: token[1],
              webnar_id: token[0],
              iid: course.sala_local_id,
              nome: user.data.nome,
              email: user.data.email,
            })
          );
          return;
        }

        await dispatch(subscription.actions.setTransmissionActivity(course));
        goTo(`streaming`);
        return;
      }
    }

    if (toGo === "ondemand" && course.qtde_aulas_video > 0)
      goTo("my-course/" + course.agenda_atividade_id);
  };

  const { data: dataCourse, fetchData: fetchDataCourse } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/person/${user.data.id}/course`
  );

  useEffect(() => {
    if (showRoomRequest?.data) window.open(showRoomRequest?.data, "blank");
  }, [showRoomRequest]);

  useEffect(() => {
    fetchDataCourse();
  }, [fetchDataCourse]);

  return (
    <MyCourseView
      course={dataCourse && dataCourse.data}
      selectCourse={selectCourse}
    />
  );
};

export default MyCourseContainer;
