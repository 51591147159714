import { useState } from 'react'
import PersonIcon from '@material-ui/icons/Person'
import { Translator } from '@itarget/shared'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { ListItem, ListItemAvatar, ListItemText, ListItemIcon, Menu, MenuItem, Typography, Avatar } from '@material-ui/core'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  userContainer: {
    cursor: 'pointer',
    padding: theme.spacing(1, 1.5),
    borderRadius: theme.shape.borderRadius * 2,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1.5),
    maxWidth: 'max-content',
    '& .userProfileAvatarContainer': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '100%',
      border: '1px solid #D9D9D960',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: (props) => (props.hasPhoto ? 0 : theme.spacing(1)),
      width: theme.spacing(6.5),
      height: theme.spacing(6.5),
      '& .MuiAvatar-colorDefault': {
        backgroundColor: theme.palette.primary.main,
      },
      '& .userProfileAvatarNoImage': {
        width: theme.spacing(4),
        height: theme.spacing(4),
        color: theme.palette.header.avatar.color,
      },
      '& .userProfileAvatarImage': {
        width: theme.spacing(6.5),
        height: theme.spacing(6.5),
      },
    },
    '& .MuiListItemAvatar-root': {
      minWidth: 'auto',
    },
    '& .userProfileInformationContainer': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: theme.spacing(1),
      maxWidth: '135px',
      '& .userProfileInformationName': {
        ...theme.typography.caption,
        fontWeight: theme.typography.fontWeightBold,
        display: '-webkit-box',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        lineHeight: '1em',
        color: 'inherit',
      },
      '& .userProfileAssociationInformationContainer': {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(0.5),
        '& .userProfileAssociationInformationCategory, & .userProfileAssociationInformationStatus': {
          ...theme.typography.caption,
          display: '-webkit-box',
          '-webkit-line-clamp': 2,
          '-webkit-box-orient': 'vertical',
          overflow: 'hidden',
          lineHeight: '1em',
          color: 'inherit',
        },
        '& .userProfileAssociationInformationCategory': {
          fontWeight: theme.typography.fontWeightBold,
        },
      },
    },
  },
}))

const UserMenu = ({ goTo, user, onLogout, statusAssociated, category }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const systemTheme = useSelector((state) => state.theme.systemTheme)
  let userName = user?.data?.nome.split(' ').shift()
  let name = user?.data?.nome_cracha || userName

  let description = category || ''
  let photo = user?.data?.foto
  let status = statusAssociated?.data?.status || statusAssociated?.data?.financialStatus
  const styles = useStyles({ hasPhoto: !!photo, theme: systemTheme })

  const setLabelSecondary = () => {
    return (
      <div className='userProfileAssociationInformationContainer'>
        <Typography variant='inherit' className='userProfileAssociationInformationCategory'>{description}</Typography>
        <Typography variant='inherit' className='userProfileAssociationInformationStatus'>{status}</Typography>
      </div>
    )
  }

  const onClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const onClose = () => {
    setAnchorEl(null)
  }

  const StyledMenu = withStyles({
    paper: {
      width: '255px',
      marginLeft: '10px',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ))

  const translation = {
    myDashboard: Translator({ transRef: 'translation.Meu painel' }),
    exit: Translator({ transRef: 'translation.Sair' }),
  }

  return (
    <>
      <ListItem
        className={styles.userContainer}
        onClick={onClick}>
        <ListItemAvatar className='userProfileAvatarContainer'>
          {!photo ? (
            <PersonIcon className='userProfileAvatarNoImage' />
          ) : (
            <Avatar
              src={photo}
              className='userProfileAvatarImage'
            />
          )}
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{ className: 'userProfileInformationName' }}
          className='userProfileInformationContainer'
          primary={name}
          secondary={setLabelSecondary()}
        />
      </ListItem>
      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}>
        <MenuItem onClick={() => (goTo && goTo('new-panel')) || null}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary={translation?.myDashboard} />
        </MenuItem>
        <MenuItem onClick={onLogout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={translation?.exit} />
        </MenuItem>
      </StyledMenu>
    </>
  )
}

export default UserMenu
