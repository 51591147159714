import { useEffect, useState } from 'react'
import {
  Container,
  Typography,
  Divider,
  Tabs,
  Tab,
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Icon,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Checkbox,
  IconButton,
  makeStyles,
} from '@material-ui/core'
import './style.scss'
import { formatMoney, PaymentContainer, Translator, Button } from '@itarget/shared'
import BodyAccordion from './BodyAccordion'
import HeaderAccordion from './HeaderAccordion'
import CardShopping from '../../../components/CardShopping'
import Layout from '../../../components/Layout'
import { useSelector } from 'react-redux'
import { dateFormatTranslation } from '@itarget/shared/src/utils/format/date'
import { enumsSubscription } from '@itarget/shared/src/enums/'

const useStyles = makeStyles((theme) => ({
  paymentCardContainer: {
    boxShadow: theme.shadows[1],
    backgroundColor: (props) => (props.theme == 'dark' ? '#121212' : '#fff'),
  },
}))

function TabPanel(props) {
  let { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box pr={3}>{children}</Box>}
    </div>
  )
}

const MyActivityView = ({
  allSubscriptions,
  tabs,
  reprintBillet,
  onClickUnsubscribe,
  openModal,
  openConfirmUnsubscribe,
  handleClose,
  onChangePayment,
  openChangePayment,
  center_cost_id,
  toast,
  paymentType,
  isLoadingInscricoes,
  subscriptionState,
  paymentRedux,
  totalState,
  user,
  onInvalidCardPayment,
  onChangeSelected,
  selected,
  setSelected,
  total,
  state,
  setState,
  goFinish,
  resetStatePayment,
  settingCommerce,
  dispatch,
  checkPayment,
  loadingPix,
}) => {
  const [value, setValue] = useState(0)
  const [arrayAll, setArrayAll] = useState([])
  const [dataTabs, setDataTabs] = useState({})
  const [expanded, setExpanded] = useState([])
  const selectedLang = useSelector((state) => state.language.lang)
  const systemTheme = useSelector((state) => state.theme.systemTheme)
  const styles = useStyles({ theme: systemTheme })

  const translation = {
    datePayment: Translator({ transRef: 'translation.Data pagamento' }),
    activity: Translator({ transRef: 'translation.Atividade' }),
    valueActivity: Translator({ transRef: 'translation.Valor da atividade' }),
    myPurchase: Translator({ transRef: 'translation.Minhas compras' }),
    transferredSubscription: Translator({ transRef: 'translation.Inscrição Transferida' }),
  }
  useEffect(() => {
    if (window.location.hash) {
      let _index = window.location.hash.split('#')[1]
      setValue(_index)
    }

    var clean_uri = window.location.toString().substring(0, window.location.toString().indexOf('#'))
    window.history.replaceState({}, document.title, clean_uri)
  }, [value])

  function Activity(props) {
    const { activity, onChangeSelected, reprintBillet, selected } = props
    if (!activity) {
      return <></>
    }

    return activity?.map((card, ii) => (
      <Grid
        key={card.conta_receber_id}
        className={`tab2-grid-container ${styles.paymentCardContainer}`}>
        <Grid
          container
          className='informations'>
          <Grid
            item
            xs={12}
            md={2}>
            {card.categoria_tipo_atividade !== 1 && (
              <img
                className='tab2-activityThumb'
                alt={card.descricao}
                src={card.thumb || `assets/images/png-placeholder.jpg`}
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={10}>
            <Box paddingLeft={2}>
              <Grid
                item
                xs={12}
                md={12}>
                <Grid container>
                  <Grid
                    item
                    md={11}>
                    <Typography gutterBottom>{card.descricao}</Typography>
                    <Grid
                      container
                      alignItems='center'
                      spacing={1}>
                      <Box display='flex'>
                        <Icon
                          style={
                            card.status_conta_receber === 3 || (card.status_conta_receber === null && card.status_inscricao === 2)
                              ? { color: '#32b126' }
                              : { color: '#f8a84c' }
                          }>
                          {card.status_conta_receber === 3 || (card.status_conta_receber === null && card.status_inscricao === 2)
                            ? 'done'
                            : 'schedule'}
                        </Icon>
                      </Box>
                      <Grid item>
                        <Typography
                          style={
                            card.status_conta_receber === 3 || (card.status_conta_receber === null && card.status_inscricao === 2)
                              ? { color: '#32b126' }
                              : { color: '#f8a84c' }
                          }>
                          {card.status_inscricao_descricao}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={1}>
                    <div style={{ float: 'right', marginRight: '-30px' }}>
                      {!(card.status_conta_receber === 3 || [null, 2].includes(card.status_conta_receber)) &&
                        !card.controle_boleto_cartao_id && (
                          <>
                            <Checkbox
                              onChange={(e) => onChangeSelected(e, card)}
                              defaultChecked={selected.map((m) => m.conta_receber_id).includes(card?.conta_receber_id)}
                              className='checkbox'
                            />
                            <br />
                          </>
                        )}
                      {card.status_inscricao !== 2 &&
                        card.num_nosso_numero === null &&
                        (([2, 3].includes(settingCommerce.cancelamento_anuidade) && card.categoria_tipo_atividade === 1) ||
                          card.categoria_tipo_atividade !== 1) && (
                          <IconButton
                            onClick={(event) => {
                              event.stopPropagation()
                              openModal([card])
                            }}>
                            <Icon>close</Icon>
                          </IconButton>
                        )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          className='informations'>
          <Grid
            item
            xs={12}
            md={12}
            >
            <Divider />
          </Grid>

          <Grid
            item
            spacing={2}
            container
            xs={12}>
            <Grid
              item
              xs={12}
              spacing={2}
              container>
              {card?.data_pagamento && (
                <Grid item>
                  <Box
                    display='flex'
                    alignItems='center'
                    gridGap={3}
                    spacing={1}>
                    <Box display='flex'>
                      <Icon>event</Icon>
                    </Box>
                    <Grid item>
                      <Typography variant='caption'>{dateFormatTranslation(selectedLang, card.data_pagamento)}</Typography>
                    </Grid>
                  </Box>
                </Grid>
              )}

              {card?.tipo_atividade_descricao && (
                <Grid item>
                  <Box
                    display='flex'
                    alignItems='center'
                    gridGap={3}
                    spacing={1}>
                    <Box display='flex'>
                      <Icon>local_atm</Icon>
                    </Box>
                    <Grid item>
                      <Typography variant='caption'>{card.tipo_atividade_descricao}</Typography>
                    </Grid>
                  </Box>
                </Grid>
              )}

              {card?.cr_valor && (
                <Grid item>
                  <Box
                    display='flex'
                    alignItems='center'
                    gridGap={3}
                    spacing={1}>
                    <Box display='flex'>
                      <Icon>local_atm</Icon>
                    </Box>
                    <Grid item>
                      <Typography variant='caption'>{`R$ ${formatMoney.toReal(card.cr_valor)}`}</Typography>
                    </Grid>
                  </Box>
                </Grid>
              )}
            </Grid>

            <Grid item container>
  
              {card.status_inscricao === enumsSubscription.SUBSCRIPTION_STATUS.TRANSFERRED.CODE && (
                <Grid item>
                  <Box
                    display='flex'
                    alignItems='center'
                  >
                    <Typography variant='caption'>
                      <Translator transRef='translation.Inscrição Transferida' />
                    </Typography>
                      &nbsp;
                      <Icon>move_down_sharp</Icon>
                  </Box>
                </Grid>
              )}

              {card.link_recibo && card.status_inscricao !== enumsSubscription.SUBSCRIPTION_STATUS.TRANSFERRED.CODE && (
                <Grid item>
                  <Button
                    color='info'
                    icon='receipt_long'
                    variant='twilight'
                    onClick={(event) => {
                      window.open(card.link_recibo)
                      event.stopPropagation()
                    }}>
                    <Translator transRef='translation.Recibo' />
                  </Button>
                </Grid>
              )}

              {card?.status_inscricao !== 2 && card?.hash_transacao && (
                <Grid item>
                  <Button
                    color='info'
                    icon='receipt_long'
                    variant='twilight'
                    onClick={(event) => {
                      event.preventDefault()
                      reprintBillet(card)
                    }}>
                    <Translator transRef={`translation.Reimprimir boleto`} />
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    ))
  }

  useEffect(() => {
    for (let i = 0; i < arrayAll.length; i++) {
      setExpanded((expanded) => [...expanded, false])
    }
  }, [arrayAll])

  useEffect(() => {
    tabs && setDataTabs(tabs)
  }, [tabs])

  useEffect(() => {
    setArrayAll(Object.entries(allSubscriptions))
  }, [allSubscriptions])

  const handleClick = (newValue) => {
    setValue(newValue)
  }

  const onChangeAccordion = (i) => {
    setExpanded(
      expanded.map((r, idx) => {
        if (i === idx) {
          return !r
        } else {
          return r
        }
      })
    )
  }

  return (
    <Layout>
      <Dialog
        maxWidth='lg'
        open={openChangePayment}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          <Translator transRef={`translation.Mudar forma de pagamento`} />
        </DialogTitle>
        <DialogContent>
          <PaymentContainer
            goFinish={goFinish}
            centro_custo_id={center_cost_id}
            handleClose={handleClose}
            toast={toast}
            openChangePayment={openChangePayment}
            paymentType={paymentType}
            subscriptionState={subscriptionState}
            paymentRedux={paymentRedux}
            total={totalState}
            user={user}
            onInvalidCardPayment={onInvalidCardPayment}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            <Translator transRef={`translation.Voltar`} />
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmUnsubscribe}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          <Translator transRef={`translation.Deseja cancelar a sua inscrição?`} />
        </DialogTitle>
        <DialogActions>
          <Grid
            justify='flex-end'
            spacing={2}
            container>
            <Grid item>
              <Button
                variant='midnight'
                color='primary'
                onClick={handleClose}>
                <Translator transRef={`translation.Voltar`} />
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='outlined'
                color='error'
                onClick={onClickUnsubscribe}
                autoFocus>
                <Translator transRef={`translation.Cancelar inscrição`} />
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <Container maxWidth='xl' className='myActivity-header'>
        <Typography variant='h4'>
          {state === 'payment' ? (
            <Translator transRef={`translation.Carrinho`} />
          ) : (
            <Translator transRef={`translation.Minhas atividades`} />
          )}
        </Typography>
      </Container>
      <Divider />

      <Container maxWidth='xl' className='myActivity-body'>
        <Grid
          container
          spacing={2}
          className={'grid'}>
          {state !== 'payment' && (
            <>
              <Grid
                item
                xs={12}
                md={10}>
                <Tabs
                  value={parseInt(value)}
                  aria-label='simple tabs example'
                  variant='scrollable'
                  scrollButtons='auto'>
                  <Tab
                    label={translation?.myPurchase}
                    onClick={() => handleClick(0)}
                    tabIndex={0}
                  />
                  {tabs?.descriptions &&
                    Object.keys(tabs.descriptions).map((key, i) => {
                      let desc = tabs.descriptions[key].description
                      return (
                        <Tab
                          value={parseInt(key)}
                          onClick={() => handleClick(key)}
                          label={desc}
                          key={key}
                          tabIndex={parseInt(key)}
                          index={key}
                        />
                      )
                    })}
                </Tabs>
              </Grid>

              <Grid
                item
                xs={12}
                md={8}>
                <TabPanel
                  value={value}
                  index={0}
                  className='tab1'>
                  {arrayAll.map((row, i) => (
                    <Grid
                      xs={12}
                      key={i}
                      className={`tab1-gridContainer ${styles.paymentCardContainer}`}>
                      <Accordion
                        onChange={() => onChangeAccordion(i)}
                        className='tab1-accordion'>
                        <AccordionSummary
                          aria-controls='panel1a-content'
                          id='panel1a-header'>
                          <HeaderAccordion
                            onChangePayment={onChangePayment}
                            reprintBillet={reprintBillet}
                            openModal={openModal}
                            header={row[0]}
                            data={row[1]}
                            loadingPix={loadingPix}
                            checkPayment={checkPayment}
                          />
                        </AccordionSummary>
                        <AccordionDetails className='accordionDetails'>
                          <BodyAccordion
                            header={row[0]}
                            data={row[1]}
                          />
                        </AccordionDetails>
                      </Accordion>
                      <Grid
                        xs={12}
                        className='grid-total text-center'>
                        <Grid
                          xs={12}
                          md={12}>
                          <Typography className='text-total'>
                            <span>
                              <Translator transRef={`translation.Total`} />:{' '}
                            </span>
                            <span className='cifrao'> R$</span>{' '}
                            {formatMoney.toReal(row[1].map((res) => parseFloat(res.cr_valor)).reduce((acc, curr) => acc + curr))}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </TabPanel>
                {tabs?.descriptions &&
                  Object.keys(tabs.descriptions).map((key, index) => {
                    let tabDesc = tabs.descriptions[key].description
                    if (value !== key) {
                      return <></>
                    }
                    return (
                      <TabPanel
                        value={tabDesc}
                        index={tabDesc}
                        key={key}
                        className='tab2'>
                        {dataTabs?.data && dataTabs?.data?.[key] !== undefined && (
                          <Activity
                            activity={dataTabs?.data?.[key]}
                            reprintBillet={reprintBillet}
                            onChangeSelected={onChangeSelected}
                            selected={selected}
                          />
                        )}
                      </TabPanel>
                    )
                  })}
              </Grid>
            </>
          )}

          {state === 'payment' && (
            <>
              <Grid
                item
                xs={12}
                md={8}>
                <Grid
                  xs={12}
                  md={11}>
                  <PaymentContainer
                    goFinish={goFinish}
                    centro_custo_id={center_cost_id}
                    toast={toast}
                    paymentType={paymentType}
                    subscriptionState={selected}
                    paymentRedux={paymentRedux}
                    total={totalState}
                    user={user}
                    onInvalidCardPayment={onInvalidCardPayment}
                    resetState={resetStatePayment}
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Grid
            item
            xs={12}
            md={4}>
            <CardShopping
              isLoadingInscricoes={isLoadingInscricoes}
              total={total}
              state={state}
              setState={setState}
              selected={selected}
              setSelected={setSelected}
              user={user}
              dispatch={dispatch}
            />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default MyActivityView
