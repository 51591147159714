import { useState } from "react";
import HomeIcon from "@material-ui/icons/Home";

import clsx from "clsx";

import {
  ListItem,
  Button,
  IconButton,
  Popover,
  makeStyles,
} from "@material-ui/core";
import Menu from "./Menu";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "stretch",
    fontSize: "0.7em",
    padding: 0,
    backgroundColor: (props) =>
      (props.level === 0 && "#2a7499") || "transparent",
    color: (props) => (props.level === 0 && "#fff") || "#2a7499",
    width: "auto",
    borderRight: "1px solid #fff",
  },
  button: {
    color: "inherit",
    padding: `${theme.spacing()}px !important`,
    fontSize: "inherit",
    "& *": {
      fontFamily: "Gotham !important",
    },
    flex: "1",
    justifyContent: "center",
    borderRadius: "none",
  },
  popover: {
    maxWidth: "300px",
    minWidth: "300px",
    display: "flex",
    backgroundColor: "#fff",
  },
  menu: {
    flexDirection: "column",
    flex: "1",
    backgroundColor: "transparent",
  },
}));

const MenuItem = ({
  post_title,
  subItems = [],
  level = 0,
  title,
  url,
  classes = {},
  ...rest
}) => {
  const styles = useStyles({ level });
  const [anchorEl, setAnchorEl] = useState(null);
  const onClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };

  if (/^i/gi.test(post_title) || /^i/gi.test(title)) {
    return (
      <ListItem className={clsx(styles.root, classes.root)}>
        <IconButton
          component="a"
          href={url}
          onClick={onClick}
          className={clsx(styles.button, classes.itemButtonRoot)}
        >
          <HomeIcon />
        </IconButton>
      </ListItem>
    );
  }
  return (
    <ListItem className={clsx(styles.root, classes.root)}>
      <Button
        component="a"
        href={url}
        onClick={onClick}
        className={styles.button}
      >
        {title}
      </Button>
      {subItems?.length > 0 && (
        <Popover
          id={Boolean(anchorEl) ? "simple-popover" : undefined}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: (level === 0 && "bottom") || "top",
            horizontal: (level === 0 && "left") || "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{
            paper: styles.popover,
          }}
        >
          <Menu
            items={subItems}
            classes={{ root: styles.menu }}
            level={level + 1}
          />
        </Popover>
      )}
    </ListItem>
  );
};
export default MenuItem;
