import { Translator } from '@itarget/shared'

export const useTranslation = () => ({
  advancedButton: Translator({ transRef: 'translation.Filtros avançados' }).props.children,
  clean: Translator({ transRef: 'translation.Limpar' }).props.children,
  default: Translator({ transRef: 'translation.Selecione' }).props.children,
  search: {
    name: Translator({ transRef: 'translation.Tema / Palestrante' }).props.children,
    label: Translator({ transRef: 'translation.Pesquisar' }).props.children,
  },
  period: {
    name: Translator({ transRef: 'translation.Iniciadas entre' }).props.children,
  },
  room: {
    name: Translator({ transRef: 'translation.Sala da atividade' }).props.children,
  },
  tag: {
    name: Translator({ transRef: 'translation.Eixo da atividade' }).props.children,
  },
})
