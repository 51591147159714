import { Grid, Typography, Icon, Button } from "@material-ui/core";
import "./style.scss";
import { Translator } from "@itarget/shared";
import { useSelector } from "react-redux";
import { dateFormatTranslation } from "@itarget/shared/src/utils/format/date";

const CardCourseList = ({ selectCourse, course }) => {
  const selectedLang = useSelector((state) => state.language.lang)
  return (
    <Grid className="grid-container" container>
      <Grid item xs={12} md={1} className="grid-img">
        <img className="card-img" alt={course && course.descricao} src={course && course.thumb} />
      </Grid>

      <Grid item xs={12} md={11} className="grid-detail">
        <Grid container>
          <Grid item md={6} xs={12} className="grid-title">
            <Typography className="text-description">
              {course && course.descricao}
            </Typography>
          </Grid>

          <Grid item md={6} xs={12} className="grid-infos">
            <Grid container>
              <Grid item md={3} xs={6}>
                {course?.exibir_programacao_horario === "S" &&
                  course?.data_inicio && (
                    <div className="grid-date">
                      <Typography className='card-course-list-tag-description'>
                        {dateFormatTranslation(selectedLang, course && course.data_inicio)}
                      </Typography>
                    </div>
                  )}
              </Grid>
              <Grid item md={3} xs={6}>
                <div className="grid-description">
                  <Icon>room</Icon>
                  <Typography className='card-course-list-tag-description'>
                    {course && course.tipo_atividade_descricao}
                  </Typography>
                </div>
              </Grid>

              <Grid item md={4} xs={6} style={{ textAlign: "center" }}>
                {course?.qtde_aulas_video > 0 && (
                  <Button
                    className="btn-watch"
                    onClick={() => {
                      selectCourse(course, "ondemand");
                    }}
                  >
                    <Icon className="icon-watch">playlist_play</Icon>
                    <Typography className="text">
                      <Translator transRef="translation.On Demand" />
                    </Typography>
                  </Button>
                )}

                {course?.disponivel_transmissao === "S" && (
                  <Button
                    className="btn-watch"
                    onClick={() => {
                      selectCourse(course, "aovivo");
                    }}
                  >
                    <Icon className="icon-watch">cast_for_education</Icon>
                    <Typography className="text">
                      <Translator transRef="translation.Ao Vivo" />
                    </Typography>
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CardCourseList;
