import { makeStyles } from '@material-ui/core'

import MenuC from '../../../MenuC'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Trailing } from './Trailing'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '@media (max-width: 425px)': {
      position: 'relative',
      borderBottom: '2px solid #000000',
    },
  },
  rootJumper: {
    marginBottom: '17rem',
    '@media (min-width: 950px)': {
      marginBottom: '0',
    },
  },
  iconcart: {
    width: '32px',
    height: '32px',
  },
  container: {
    height: '160px',
    borderBottom: '2px solid #000000',
    position: 'relative',
    backgroundSize: 'auto',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    '@media (max-width: 425px)': {
      borderBottom: '0',
      position: 'unset',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media (max-width: 425px)': {
      flexDirection: 'column',
    },
  },
  logo: {
    marginTop: '0.5rem',
    maxWidth: '100%',
    maxHeight: '100%',
    width: '190px',
  },
  glass: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    /* From https://css.glass */
    background: '#606060cc',
    borderRadius: '16px',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    backdropFilter: 'blur(3.9px)',
    webkitBackdropFilter: 'blur(3.9px)',
    border: '1px solid rgba(255, 255, 255, 0.26)',

    '& .MuiIconButton-root, & .MuiListItemText-secondary, & .userProfileInformationName': {
      color: '#ffffff',
    },
  },
  trailing: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(4),
    '@media (max-width: 425px)': {
      padding: '0',
      display: 'none',
    },
  },
  trailingMobile: {
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: theme.spacing(4),
    '@media (max-width: 425px)': {
      padding: '0',
      display: 'flex',
    },
  },
  leading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
    '@media (max-width: 425px)': {
      padding: '0',
    },
  },
  menu: {
    height: '30px',
  },
  menuItem: {
    textTransform: 'uppercase',
  },
  menuButton: {
    '& .material-icons': {
      color: 'white',
      '@media (max-width: 425px)': {
        color: 'black',
      },
    },
    '@media (min-width: 950px)': {
      display: 'none',
    },
  },
}))

const Header = ({
  showMenu,
  toggleMenu,
  logo,
  client,
  shoppingCarts,
  menuItemsC = [],
  isLoadingMenuData,
  user,
  goTo,
  onLogout,
  statusAssociated,
  category,
}) => {
  const [menu, setMenu] = useState([{ description: 'Home', url: '/' }])
  const [imageBackground, setImageBackground] = useState(null)
  const styles = useStyles()
  const settingCourse = useSelector((state) => state.setting.settingCommerce)

  useEffect(() => {
    try {
      if (settingCourse?.json_menu) setMenu(JSON.parse(settingCourse.json_menu))
      if (settingCourse.url_image_topo_background) setImageBackground(settingCourse.url_image_topo_background)
    } catch (e) {}
  }, [settingCourse])

  return (
    <header className={`${styles.root} ${showMenu != 'hide' ? styles.rootJumper : ''}`}>
      <div
        className={styles.container}
        style={{ backgroundImage: `url(${imageBackground})` }}>
        <div className={styles.header}>
          <div className={styles.leading}>
            <img
              className={styles.logo}
              alt={client}
              src={logo}
            />
          </div>
          <div className={styles.trailing}>
            <Trailing
              category={category}
              goTo={goTo}
              onLogout={onLogout}
              shoppingCarts={shoppingCarts}
              statusAssociated={statusAssociated}
              styles={styles}
              toggleMenu={toggleMenu}
              user={user}
            />
          </div>
        </div>
        <MenuC
          items={menuItemsC}
          isLoadingMenuData={isLoadingMenuData}
          classes={{ root: styles.menu, itemRoot: styles.menuItemRoot }}
          showMenu={showMenu}
        />
      </div>
      <div className={styles.trailingMobile}>
        <Trailing
          category={category}
          goTo={goTo}
          onLogout={onLogout}
          shoppingCarts={shoppingCarts}
          statusAssociated={statusAssociated}
          styles={styles}
          toggleMenu={toggleMenu}
          user={user}
        />
      </div>
    </header>
  )
}

export default Header
