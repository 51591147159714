import { useHeaderStyles } from './styles'
import { Box, Typography } from '@material-ui/core'

const Header = ({ title, activityType }) => {
  const styles = useHeaderStyles()

  return (
    <Box className={styles.container}>
      <Typography className={styles.title}>{title}</Typography>
      <Typography className={styles.activityType}>{activityType}</Typography>
    </Box>
  )
}

export default Header
