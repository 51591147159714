import * as React from "react";
import { Button, Dialog, List, Slide } from "@material-ui/core";
import { Translator } from "@itarget/shared";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const contentValue = props.children;
  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        <Translator transRef="translation.menu" />
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Button autoFocus color="inherit" onClick={handleClose}>
          <Translator transRef="translation.Voltar" />
        </Button>
        <List>{contentValue}</List>
      </Dialog>
    </div>
  );
}
