import { RTag, Translator } from "@itarget/shared"
import { Link } from "react-router-dom"
import { Dialog, Grid, makeStyles, Typography } from "@material-ui/core"
import PersonIcon from "@material-ui/icons/Person"

import CartButton from "./CartButton"
import UserMenu from "./UserMenu"
import MenuSingleStore from "./MenuSingleStore"
import LoginForm from "../Layout/LoginForm"
import { useState } from "react"
import LoginContainer from "../../containers/login/LoginContainer"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    // height: "100px",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    //height: "120px"
  },
  logo: {
    maxWidth: "100%",
    maxHeight: "100%",
    display: "flex",
  },
  trailing: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: theme.spacing(4),
  },
  leading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    // paddingLeft: theme.spacing(4),
    paddingLeft: "16px",
  },
  menu: {
    height: "30px",
  },
  menuItem: {
    textTransform: "uppercase",
  },
  navMenu: {
    display: "flex",
    flexDirection: "row",
    "@media (max-width: 600px)": {
      transform: "scale(1.5)",
    },
  },
  loginMobile: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  loginMobileTitle: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  mobileTitleIcon: {
    color: "#ffffff",
    background: "#0086ff",
    padding: "3px",
    borderRadius: "20px",
  },
  "@media (max-width:800px)": {
    container: {
      flexFlow: "nowrap !important",
      minHeight: "auto !important",
    },
    logo: {
      maxWidth: "80%",
    },
    trailing: {
      justifyContent: "center",
    },
    leading: {
      justifyContent: "center",
    },
  },
  "@media (max-width: 600px)": {
    navMenu: {
      transform: "scale(1.5)",
    },
  },
}))

const HeaderStore = ({
  logo,
  shoppingCarts,
  user,
  goTo,
  onLogout,
  center_cost_id,
  anchorEl,
  setting,
  handleClick,
  handleClose,
  statusAssociated,
  category,
}) => {
  const styles = useStyles()
  const [openLogin, setOpenLogin] = useState(false)

  return (
    <header className={styles.root}>
      <Dialog maxWidth="lg" open={openLogin} onClose={() => setOpenLogin(false)} className="dialog-login">
        <LoginContainer />
      </Dialog>
      <div className={`${styles.container} makeStyles-container-2`}>
        <Grid className="logo-cliente" lg={6} xs={12}>
          <div className={styles.leading}>
            <img className={styles.logo} src={logo} />
          </div>
        </Grid>

        <Grid className="area-login" lg={6} xs={12}>
          <div className={`${styles.trailing} makeStyles-trailing-4`}>
            {user?.data ? (
              <div className={styles.navMenu}>
                <CartButton goTo={goTo} shoppingCarts={shoppingCarts} />
                <UserMenu user={user} onLogout={onLogout} goTo={goTo} statusAssociated={statusAssociated} category={category} />
              </div>
            ) : (
              <>
                <RTag show="desktop">
                  <LoginForm
                    shoppingCarts={shoppingCarts}
                    user={user}
                    category={category}
                    goTo={goTo}
                    center_cost_id={center_cost_id}
                    anchorEl={anchorEl}
                    setting={setting}
                    handleClick={handleClick}
                    handleClose={handleClose}
                  />
                </RTag>
                <RTag show="mobile">
                  <div onClick={() => setOpenLogin(true)} className={styles.loginMobile}>
                    <Typography className={styles.loginMobileTitle}>
                      <PersonIcon className={styles.mobileTitleIcon} />
                      <Translator transRef="translation.Login" />
                    </Typography>
                    <Typography variant="caption">
                      <Translator transRef="translation.& cadastro" />
                    </Typography>
                  </div>
                </RTag>
              </>
            )}
          </div>
        </Grid>
      </div>

      <MenuSingleStore cc={center_cost_id} />
    </header>
  )
}

export default HeaderStore
