import { Box, Icon, Typography } from '@material-ui/core'
import { useDetailTranslation } from './translation'
import { useDetailStyles } from './styles'

const Field = ({ icon = 'info', label = '', value = '' }) => {
  const styles = useDetailStyles()
  const translation = useDetailTranslation()
  if (!label || !value || value == '0') return <></>
  return (
    <Box className={styles.fieldContainer}>
      <Typography className={styles.label}>{label}</Typography>
      <Box className={styles.fieldValueContainer}>
        <Icon className={styles.icon}>{icon}</Icon>
        <Typography className={styles.value}>{value || translation.defaultValue}</Typography>
      </Box>
    </Box>
  )
}

const Detail = (props) => {
  const { startDate = undefined, endDate = undefined, location = undefined, room = undefined, hourlyLoad = undefined } = props
  const styles = useDetailStyles()
  const translation = useDetailTranslation()
  if (Object.values(props).every((element) => !element)) return <></>
  return (
    <Box className={styles.container}>
      {startDate && (
        <Box className={styles.horizontalContainer}>
          <Field
            icon='calendar_month'
            value={startDate}
            label={translation.dateStart}
          />
          {endDate && (
            <Field
              icon='calendar_month'
              value={endDate}
              label={translation.dateEnd}
            />
          )}
        </Box>
      )}
      <Box className={styles.horizontalContainer}>
        <Field
          value={location}
          icon='location_on'
          label={translation.location}
        />
        <Field
          value={room}
          icon='meeting_room'
          label={translation.room}
        />
      </Box>
      <Field
        icon='schedule'
        value={hourlyLoad}
        label={translation.hourlyLoad}
      />
    </Box>
  )
}

export default Detail
