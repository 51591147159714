import React, { useState } from 'react'
import DownloadComplementary from '../DowloadComplementary/DownloadComplementary'
import { makeStyles } from '@material-ui/core/styles'
import { Translator, ReplaceAllInstances } from '@itarget/shared'
import { Typography, Icon, Button, Avatar, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import { enumsSchedule } from "@itarget/shared/src/enums";
import './style.scss'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': { margin: theme.spacing(0) },
  },
  red: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
  },
  green: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
  },
}))

const StepperDetailCourse = ({ steps = [], selectStep }) => {
  const classes = useStyles()
  const stepIcon = (course) => {
    return (
      <div className={classes.root}>
        <Avatar className={course.total_assistido > 99 ? classes.green : classes.red}>
          <Icon className='icon-step'>{course.total_assistido > 99 ? 'verified' : 'ondemand_video'}</Icon>
        </Avatar>
      </div>
    )
  }

  const ConditionalButton = ({ course, speakers }) => {
    const [openStatus, setOpenStatus] = useState(false)
    const [openQuiz, setOpenQuiz] = useState(false)
    const changeStatus = () => setOpenStatus((prev) => !prev)
    let quiz = course.formulario?.[0]
    const canYouAnswer = !quiz?.respondido || quiz?.libera_tentativa
    const itsInTheMedia = !course.meta_nota_certificado || parseFloat(course.meta_nota_certificado) <= parseFloat(course.nota)
    const alreadyWatched = course.total_assistido >= 99
    let attemptsLeft = quiz?.tentativas > quiz?.meta_num_tentativas ? 0 : quiz?.meta_num_tentativas - quiz?.tentativas

    const translation = {
      watchAgain: Translator({ transRef: 'translation.rever' }),
      watch: Translator({ transRef: 'translation.assistir' }),
      unavailable: Translator({ transRef: "translation.indisponível" }),
      yes: Translator({ transRef: 'translation.sim' }).props.children,
      no: Translator({ transRef: 'translation.não' }).props.children,
      attemptsProceed: Translator({ transRef: 'translation.Atenção: Você possui (%s) tentativas restantes. Deseja prosseguir?' }).props
        .children,
      attemptProceed: Translator({ transRef: 'translation.Atenção: Você possui (%s) tentativa restante. Deseja prosseguir?' }).props
        .children,
      successPercentage: Translator({ transRef: 'translation.Você acertou %s% do questionário.' }).props.children,
      attempts: Translator({ transRef: 'translation.Atenção: Você possui %s tentativas restantes.' }).props.children,
      attempt: Translator({ transRef: 'translation.Atenção: Você possui %s tentativa restante.' }).props.children,
    }

    const handleClickOpenQuiz = () => {
      if (!attemptsLeft || !quiz?.meta_num_tentativas) return confirmTakeQuiz()
      setOpenQuiz(true)
    }

    const handleCloseQuiz = () => {
      setOpenQuiz(false)
    }

    const confirmTakeQuiz = () => {
      handleCloseQuiz()
      selectStep(course, speakers, 'question')
    }

    const denyTakeQuiz = () => {
      handleCloseQuiz()
    }

    if (!course.link_video_palestrante) return <></>
   
    const showWatchButton = () => {
      const scheduleValidityMode = steps[0]['modo_vigencia']
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      if(scheduleValidityMode === enumsSchedule.SCHEDULE_VALIDITY_MODE.NOT_APPLICABLE || scheduleValidityMode === undefined){
        return true
      }

      if(scheduleValidityMode === enumsSchedule.SCHEDULE_VALIDITY_MODE.FIXED_DATE && new Date(`${steps[0]['data_vigencia_fixa']}T00:00:00`) >= today){
        return true
      }

      if(scheduleValidityMode === enumsSchedule.SCHEDULE_VALIDITY_MODE.ACCORDING_TO_THE_INSCRIPTION 
          && new Date(`${steps[0]['data_vigencia_associacao']}T00:00:00`) >= today){
          return true
      }
      
      return false
    }
    

    let messageAttemptsLeft = ReplaceAllInstances(translation[Number(attemptsLeft) == 1 ? 'attempt' : 'attempts'], [attemptsLeft])
    let messageAttemptsLeftProceed = ReplaceAllInstances(
      translation[Number(attemptsLeft) == 1 ? 'attemptProceed' : 'attemptsProceed'],
      [attemptsLeft]
    )

    return (
      <>
        <Dialog
          open={openQuiz}
          onClose={handleCloseQuiz}>
          <DialogContent>{messageAttemptsLeftProceed}</DialogContent>
          <DialogActions>
            <Button
              onClick={confirmTakeQuiz}
              color='primary'>
              {translation.yes}
            </Button>
            <Button
              onClick={denyTakeQuiz}
              color='inherit'>
              {translation.no}
            </Button>
          </DialogActions>
        </Dialog>
        {course?.arquivo?.length > 0 && (
          <>
            <DownloadComplementary
              handleClose={() => changeStatus()}
              openStatus={openStatus}
              files={course?.arquivo}
            />
            <Button
              onClick={() => changeStatus()}
              className='btn-complementary'>
              <Icon className='btn-icon'>{openStatus ? 'folder_open' : 'folder'}</Icon>
              <Typography className='btn-text'>
                <Translator transRef='translation.arquivos' />
              </Typography>
            </Button>
          </>
        )}
        {course.exibir_questionario && (
          <div className='quiz-buttons-container'>
            {course.exibir_desempenho && quiz?.respondido && (
              <Tooltip title={ReplaceAllInstances(translation.successPercentage, [course.nota])}>
                <Button className='btn-watch'>
                  <Icon
                    className='btn-icon'
                    style={itsInTheMedia ? { color: '#32b126' } : { color: '#ccac00' }}>
                    assessment
                  </Icon>
                  <Typography className='btn-text'>{`${course.nota}%`}</Typography>
                </Button>
              </Tooltip>
            )}
            <Tooltip title={quiz?.meta_num_tentativas ? messageAttemptsLeft : ''}>
              <Button
                className='btn-watch'
                data-disabled={!canYouAnswer}
                onClick={attemptsLeft ? handleClickOpenQuiz : confirmTakeQuiz}>
                <Icon
                  className='btn-icon'
                  style={quiz?.respondido ? { color: '#32b126' } : { color: '#f44336' }}>
                  local_library
                </Icon>
                <Typography className='btn-text'>
                  <Translator transRef='translation.questionário' />
                </Typography>
              </Button>
            </Tooltip>
          </div>
        )}
        

        {showWatchButton()  && (
          <Button className="btn-watch" onClick={() => selectStep(course, speakers, "video")}>
            <Icon className="btn-icon" 
              style={course.total_assistido === 0 ? { color: "#f44336" } : { color: "#32b126" }}>
              {alreadyWatched ? "replay" : "live_tv"}
            </Icon>
            <Typography className="btn-text">{alreadyWatched ? translation?.watchAgain : translation?.watch}</Typography>
          </Button>
        )}

        {!showWatchButton()  && (
          <Button className="btn-watch">
            <Icon className="btn-icon" style={{ color: "gray" }}>  {"block"} </Icon>
            <Typography className="btn-text">{translation?.unavailable}</Typography>
          </Button>
        )}

      </>
    )
  }

  const getSpeakers = (steps) => {
    if (!steps) return ''
    return [...new Set(steps.map((x) => x.palestrantes_nome))].reduce((current, next) => current + ', ' + next)
  }

  return (
    <div class='list-ondemand'>
      <ul>
        <li>
          {stepIcon(steps[0])}
          <em>{getSpeakers(steps)}</em>
          <div className='list-ondemand-condition-button-container'>
            <ConditionalButton
              course={steps[0]}
              speakers={getSpeakers(steps)}
            />
          </div>
        </li>
      </ul>
    </div>
  )
}

export default StepperDetailCourse
