import { Grid } from '@material-ui/core'
import BtnLanguages from '../BtnLanguages'
import { ButtonTheme } from '../ButtonTheme'
import { useSelector } from 'react-redux'
import './styles.scss'

export const ButtonFooter = () => {
  const settings = useSelector((state) => state.setting.settingCommerce)
  const hasPreferenceTheme = useSelector((state) => state.theme.hasPreferenceTheme)
  return (
    <Grid className='footer-buttons-group'>
      {hasPreferenceTheme && <ButtonTheme />}
      <BtnLanguages />
    </Grid>
  )
}
