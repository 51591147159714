import MODULE_NAME from './constants'

export const SETSYSTEMTHEME = `${MODULE_NAME}/SETSYSTEMTHEME`
export const SETHASPREFERENCETHEME = `${MODULE_NAME}/SETHASPREFERENCETHEME`
export const SETHASUSERPREFERENCETHEME = `${MODULE_NAME}/SETHASUSERPREFERENCETHEME`

export const setSystemTheme = (systemTheme) => ({
  type: SETSYSTEMTHEME,
  payload: { systemTheme },
})
export const setHasPreferenceTheme = (hasPreferenceTheme) => ({
  type: SETHASPREFERENCETHEME,
  payload: { hasPreferenceTheme },
})
export const setHasUserPreferenceTheme = (hasUserPreferenceTheme) => ({
  type: SETHASUSERPREFERENCETHEME,
  payload: { hasUserPreferenceTheme },
})
