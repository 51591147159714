import * as actions from './actions'
import produce from 'immer'

const initialState = {
  systemTheme: 'light',
  hasPreferenceTheme: false,
  hasUserPreferenceTheme: false,
}

export const reducer = produce((state, action) => {
  switch (action.type) {
    case actions.SETSYSTEMTHEME:
      state.systemTheme = action.payload.systemTheme
      break
    case actions.SETHASPREFERENCETHEME:
      state.hasPreferenceTheme = action.payload.hasPreferenceTheme
      break
    case actions.SETHASUSERPREFERENCETHEME:
      state.hasUserPreferenceTheme = action.payload.hasUserPreferenceTheme
      break
    default:
      break
  }
}, initialState)
