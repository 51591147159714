import { Grid, Chip, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Translator, enumsPayment } from '@itarget/shared'

const useStyles = makeStyles((theme) => ({
  gridStyle: {
    gap: theme.spacing(1),
    paddingBottom: theme.spacing(2.5),
  },
  ChipStyle: {
    padding: theme.spacing(0.5, 1.5),
    lineHeight: 'normal',
    height: 'fit-content',
    '& .MuiChip-label': {
      padding: 0,
      fontSize: 10,
    },
  },
  ChipDefaultStyle: {
    backgroundColor: theme.palette.chip.default.color,
    color: theme.palette.chip.default.text,
  },
  ChipSuccessStyle: {
    backgroundColor: theme.palette.chip.success.color,
    color: theme.palette.chip.success.text,
  },
  ChipWarningStyle: {
    backgroundColor: theme.palette.chip.warning.color,
    color: theme.palette.chip.warning.text,
  },
  ChipDangerStyle: {
    backgroundColor: theme.palette.chip.danger.color,
    color: theme.palette.chip.danger.text,
  },
}))

const ChipStatus = (props) => {
  const {
    status_inscricao,
    transmissao_gratuito,
    vagas_ocupadas,
    vagas_limite,
    disponivel_transmissao,
    gerou_cobranca,
    plp_id,
    cortesia,
  } = props
  const { STATUS_REGISTRATION } = enumsPayment
  const styles = useStyles()

  const chipStatus = {
    subscribe: {
      translation: Translator({ transRef: 'Inscrito' }),
      rule: status_inscricao === STATUS_REGISTRATION.REGISTERED && !!plp_id && cortesia !== 'S',
      className: styles.ChipSuccessStyle,
    },
    courtesy: {
      translation: Translator({ transRef: 'Inscrito' }),
      rule: status_inscricao === STATUS_REGISTRATION.REGISTERED && !!plp_id && cortesia === 'S',
      className: styles.ChipSuccessStyle,
    },
    freeAccess: {
      translation: Translator({ transRef: 'Livre acesso' }),
      rule: status_inscricao === STATUS_REGISTRATION.REGISTERED && !plp_id,
      className: styles.ChipSuccessStyle,
    },
    free: {
      translation: Translator({ transRef: 'Aula gratuita' }),
      rule: transmissao_gratuito === 'S',
      className: styles.ChipSuccessStyle,
    },
    closed: {
      translation: Translator({ transRef: 'Vagas encerradas' }),
      rule: vagas_ocupadas >= vagas_limite && !status_inscricao,
      className: styles.ChipDefaultStyle,
    },
    pending: {
      translation: Translator({ transRef: 'Pendente' }),
      rule: status_inscricao === 1 && !!gerou_cobranca,
      className: styles.ChipWarningStyle,
    },
    shoppingCart: {
      translation: Translator({ transRef: 'Adicionado ao carrinho' }),
      rule: status_inscricao === 1 && !gerou_cobranca,
      className: styles.ChipWarningStyle,
    },
    live: {
      translation: Translator({ transRef: 'Ao vivo' }),
      rule: disponivel_transmissao === 'S' && (status_inscricao == 2 || transmissao_gratuito == 'S'),
      className: styles.ChipDangerStyle,
    },
  }

  let hasChipToShow = Object.values(chipStatus).some((value) => value.rule == true)

  if (!hasChipToShow) return null

  return (
    <Box
      className={`${styles.gridStyle} gridChipStatusStyle`}
      display='flex'
      flexWrap='wrap'
      flex={1}
      padding={1}
      alignItems='flex-end'
      justifyContent='flex-end'
      container>
      {Object.entries(chipStatus).map(([key, { className, rule, translation }]) => {
        if (!rule) return null
        return (
          <Grid
            item
            key={key}>
            <Chip
              size='small'
              label={translation}
              className={`${styles.ChipStyle} ${className}`}
            />
          </Grid>
        )
      })}
    </Box>
  )
}

export default ChipStatus
