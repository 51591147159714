import React, { useEffect, useState, useContext, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { useFetchApi, Config, useRedirect } from '@itarget/shared'
import { subscription, auth, payment, toast } from '../../redux'
import OffersUniqueView from './views/OffersUniqueView'
import { SidebarContext } from '../../context/SidebarContext'
import { debounce, isNumber } from 'lodash'

const OffersUniqueContainer = () => {
  const getStateFromLocalStorage = () => {
    const value = window.localStorage.getItem('isOpen')
    return value ? value === 'true' : false
  }

  const { setOpen } = useContext(SidebarContext)

  const setStateToLocalStorage = (value) => {
    return window.localStorage.setItem('isOpen', value)
  }

  const { cc } = useParams()
  const { goTo } = useRedirect()
  const dispatch = useDispatch()
  const routeParams = useParams()
  const [axis, setAxis] = useState([])
  const [offers, setOffers] = useState([])
  const [activityType, setActivityType] = useState([])
  const user = useSelector((state) => state.auth.user)
  const isAuthenticated = useSelector((state) => state.auth.isAutenticated)
  const center_cost_id = useSelector((state) => state.auth.cc)
  const [groupByOffers, setGroupByOffers] = useState('')
  const [activityTypes, setActivityTypes] = useState([])
  const [selectedFilter, setSelectedFilter] = useState({})
  const [showQuiz, setShowQuiz] = useState()
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({})
  const isCheckboxMarked = Object.values(selectedCheckboxes).some((checkbox) => checkbox == true)
  const [selectedActivities, setSelectedActivities] = useState([])
  const [categorySelected, setCategorySelected] = useState(false)
  const [openModalReceipt, setOpenModalReceipt] = useState(false)
  const selectedLang = useSelector((state) => state.language.lang)
  const settingCourse = useSelector((state) => state.setting.settingCommerce)
  const [dataPersonCenterCostApi, setDataPersonCenterCostApi] = useState({})
  const centerCost = useSelector((state) => state.setting.centerCost)

  const {
    data: listOffers,
    isLoading: isLoadingListOffers,
    fetchData: fetchListOffers,
  } = useFetchApi(
    Config.baseURL +
    `api/${selectedLang}/activity-schedule/offer?centro_custo_id=${cc}&nome_interface=offers` +
    (user?.data?.id ? `&pessoa_id=${user.data.id}` : '') +
    (routeParams.activity_id ? `&atividade_id=${routeParams.activity_id}` : '') +
    (selectedFilter?.grouping_field ? `&grouping_field=${selectedFilter.grouping_field}` : '') +
    (selectedFilter?.desc_atv ? `&pesquisa_completa_atv=${selectedFilter.desc_atv}` : '') +
    (selectedFilter?.data_inicio_aga ? `&data_inicio_aga=${selectedFilter.data_inicio_aga}` : '') +
    (selectedFilter?.eixo_id ? `&eixo_id=${selectedFilter.eixo_id}` : '') +
    (selectedFilter?.data_fim_aga ? `&data_fim_aga=${selectedFilter.data_fim_aga}` : '') +
    (isNumber(selectedFilter?.activity_type) ? `&tipo_atividade_id[]=${selectedFilter.activity_type}` : '')
  )

  const { data: reRegister, fetchData: fetchReRegister } = useFetchApi(
    Config.baseURL +
    `api/${selectedLang}/person/re/register?pessoa_id=${user?.data?.id}&centro_custo_id=${center_cost_id}&aplicacoes=4`
  )

  const { data: category, fetchData: fetchListCategory } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/center-cost/${cc}/category?pessoa_id=${user?.data?.id}&visivel_mod_congresso=S`
  )

  const { data: personCenterCost, fetchData: fetchPersonCenterCost } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/center-cost-person?pessoa_id=${user?.data?.id}&centro_custo_id=${cc}`,
    'POST'
  )
  //comprovante categoria
  const { data: dataPersonCenterCost, fetchData: fetchDataPersonCenterCost } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/center-cost-person/${user?.data?.id}/category-rule?centro_custo_id=${cc}`
  )

  const { data: idReceipt, fetchData: fetchIdReceipt } = useFetchApi(
    Config.baseURL +
    `api/${selectedLang}/attachments?table=pessoas_centros_custos&tabela_id=${dataPersonCenterCostApi?.id}&tipo_documento_id[]=200`
  )

  const { data: questions, fetchData: fetchQuestions } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/question?centro_custo_id=${cc}&pessoa_id=${user?.data?.id}&destino_pergunta=17`
  )

  useEffect(() => {
    if (!reRegister?.data?.[0]) return
    const timeout = setTimeout(() => goTo('me'), 2000)
    return () => clearTimeout(timeout)
  }, [reRegister])

  useEffect(() => {
    if (isAuthenticated) {
      fetchReRegister()
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (centerCost?.tipo_centro_custo === 7) {
      goTo(`ead/`)
    }
  }, [centerCost])
  //fim comprovante categoria

  useEffect(() => {
    if (dataPersonCenterCost?.data !== undefined && dataPersonCenterCost?.data === null) {
      fetchListCategory()
      setCategorySelected(true)
    }
  }, [dataPersonCenterCost?.data, fetchListCategory])

  useEffect(() => {
    if (user?.data) {
      fetchDataPersonCenterCost()
      fetchQuestions()
    }
  }, [user?.data, fetchDataPersonCenterCost])

  //comprovante
  useEffect(() => {
    if (personCenterCost) {
      setDataPersonCenterCostApi(personCenterCost.data)
      //fetchParceiros();#comentado pois na minha analise de código não está sendo feito nada com o data dessa requisição.
    }
  }, [personCenterCost])

  useEffect(() => {
    if (dataPersonCenterCost) {
      setDataPersonCenterCostApi(dataPersonCenterCost.data)
    }
  }, [dataPersonCenterCost])

  useEffect(() => {
    if (dataPersonCenterCostApi?.id) {
      fetchIdReceipt()
    }
  }, [dataPersonCenterCostApi, fetchIdReceipt])

  //fim comprovante
  useEffect(() => {
    fetchListOffers()
  }, [user?.data?.id, routeParams.activity_id, ...Object.values(selectedFilter), personCenterCost, selectedLang])

  useEffect(() => {
    if (listOffers) {
      setAxis(listOffers.axis)
      setOffers(listOffers.data)
      setGroupByOffers(listOffers.groupBy)
      if (!isCheckboxMarked) setActivityType(listOffers.activityType)
    }
  }, [listOffers])

  const selectOffer = async (activity) => {
    goTo(`offers/${activity.aga_id}/${activity.centro_custo_id_out}`)
  }
  const selectProduct = async (product) => {
    goTo(`offers/store/${product.aga_id}/${product.centro_custo_id_out}`)
  }

  const onClickRegistered = async (activity) => {
    if (activity?.transmissao_gratuito === 'S') {
      await dispatch(subscription.actions.setTransmissionActivity(activity))
      goTo(`streaming`)
      return
    }

    if (activity.disponivel_transmissao === 'S') {
      await dispatch(subscription.actions.setTransmissionActivity(activity))
      goTo(`streaming`)
      return
    }

    if (activity.qtde_aulas_video > 0) {
      goTo(`my-course/${activity.aga_id}`)
      return
    }

    goTo(`offers/${activity.aga_id}/${activity.centro_custo_id_out}`)
  }

  useEffect(() => {
    if (questions && questions?.data?.length > 0 && !questions?.data[0]?.respondido) {
      setShowQuiz(true)
    }
  }, [questions])

  useEffect(() => {
    if (user.data && !user.data?.categoria_centro_custo_descricao && category) {
      if (category.data.length == 1) {
        const selectedOneCategory = async () => {
          await fetchPersonCenterCost(
            JSON.stringify({
              pessoa_id: user.data.id,
              categoria_centro_custo_id: category.data[0].id,
              categoria_centro_custo_princ: 'S',
              nome_cracha: user.data.nome,
            })
          )
        }
        selectedOneCategory()
        setCategorySelected(false)
        let data = user.data
        dispatch(
          auth.actions.updateUser({
            ...user,
            data: {
              ...data,
              categoria_centro_custo_descricao: category.data[0].descricao,
              categoria_centro_custo_id: category.data[0].id,
            },
          })
        )
        return
      }
      setCategorySelected(true)
    }
  }, [category])

  useLayoutEffect(() => {
    if (localStorage.length < 2) {
      return setStateToLocalStorage(true)
    }
  }, [])

  const onSubmit = (values) => {
    setSelectedFilter({ ...selectedFilter, ...values })
  }

  const formik = useFormik({
    initialValues: selectedFilter,
    enableReinitialize: true,
    onSubmit,
  })

  const handleInputChangeDebounced = debounce((value) => {
    setSelectedFilter({
      ...selectedFilter,
      desc_atv: value,
      palestrante: value,
    })
  }, 500)

  useEffect(() => {
    setActivityTypes(activityType)
    setSelectedCheckboxes(
      activityType.reduce((acc, curr) => {
        acc[curr.id] = false
        return acc
      }, {})
    )
  }, [activityType])

  useEffect(() => {
    formik.setFieldValue('activity_type', selectedActivities)
  }, [selectedActivities])

  const handleCheckboxChanged = (event, id) => {
    setSelectedCheckboxes({
      ...selectedCheckboxes,
      [id]: event.target.checked,
    })
  }

  const filterHandler = () => {
    if (!isCheckboxMarked) return
    const newSelectedActivities = []
    for (const [key, value] of Object.entries(selectedCheckboxes)) {
      if (value) {
        newSelectedActivities.push(key)
      }
    }
    setSelectedActivities(newSelectedActivities)
  }

  const handleChange = (e) => {
    setSelectedFilter({
      ...selectedFilter,
      grouping_field: e.target.value,
    })
  }

  const onOpenSidebar = () => {
    setOpen(true)
    setStateToLocalStorage(true)
  }

  const onCloseSidebar = () => {
    setOpen(false)
    setStateToLocalStorage(false)
  }

  useEffect(() => {
    const stateFromLocalStorage = getStateFromLocalStorage()
    if (stateFromLocalStorage !== null) {
      setOpen(stateFromLocalStorage)
    } else {
      setOpen(true)
      setStateToLocalStorage(true)
    }
  }, [])

  const cleanAllFilters = () => {
    setSelectedFilter({})
    setSelectedActivities([])
    setSelectedCheckboxes({})
  }

  return (
    <OffersUniqueView
      axis={axis}
      toast={toast}
      formik={formik}
      centerCostId={cc}
      payment={payment}
      idReceipt={idReceipt}
      category={category}
      offers={offers}
      groupByOffers={groupByOffers}
      settingCourse={settingCourse}
      activityType={activityType}
      categorySelected={categorySelected}
      activityTypes={activityTypes}
      loading={isLoadingListOffers}
      selectedFilter={selectedFilter}
      openModalReceipt={openModalReceipt}
      isCheckboxMarked={isCheckboxMarked}
      selectedCheckboxes={selectedCheckboxes}
      dataPersonCenterCostApi={dataPersonCenterCostApi}
      onOpenSidebar={onOpenSidebar}
      onCloseSidebar={onCloseSidebar}
      setOpenModalReceipt={setOpenModalReceipt}
      cleanAllFilters={cleanAllFilters}
      handleInputChangeDebounced={handleInputChangeDebounced}
      handleCheckboxChanged={handleCheckboxChanged}
      filterHandler={filterHandler}
      handleChange={handleChange}
      setSelectedFilter={setSelectedFilter}
      selectOffer={selectOffer}
      selectProduct={selectProduct}
      onClickRegistered={onClickRegistered}
      showQuiz={showQuiz}
      setShowQuiz={setShowQuiz}
    />
  )
}

export default OffersUniqueContainer
