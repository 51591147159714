import {
  Container,
  Grid,
  Typography,
  TextField,
  Icon,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Translator } from "@itarget/shared";
import "./style.scss";

const ChangePasswordView = ({ formik, isLoading, state, settingCommerce, isLoadingService, }) => {
  const Sucess = () => {
    return (
      <div className="grid-sucess">
        <Typography className="text"><Translator transRef="translation.Senha atualizada com sucesso!" /></Typography>
        <Typography className="text"><Translator transRef="translation.Você será redirecionado para a tela e login" /></Typography>
      </div>
    );
  };
  return (
    <Container maxWidth="md" className="recoveryLogin-container">
      <Grid>
      {!isLoadingService && <img
          className="img-logo"
          src={settingCommerce?.url_image_login}
          alt="logo"
      />}
      </Grid>
      <Grid>
        {state === "sucess" ? (
          <Sucess />
        ) : (
          <>
            <Grid item xs={12} className="grid-item">
              <Grid className="grid-info">
                <Icon className="icon">lock</Icon>
                <Typography className="text"><Translator transRef="translation.Esqueci minha senha!" /></Typography>
              </Grid>
              <Grid className="grid-info">
                <Translator transRef="translation.Informe o codigo recebido por email e cadastre a nova senha." />
              </Grid>
            </Grid>
            <form onSubmit={formik.handleSubmit} className="form">
              <Grid item xs={12} className="grid-form">
                <TextField
                  type={"text"}
                  name="codigo"
                  onChange={formik.handleChange}
                  required={true}
                  className="text-field"
                  label={
                    Translator({
                      transRef: "translation.Código"
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} className="grid-form">
                <TextField
                  type={"password"}
                  name="senha"
                  onChange={formik.handleChange}
                  required={true}
                  className="text-field"
                  label={
                    Translator({
                      transRef: "translation.Nova senha"
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} className="grid-form">
                <TextField
                  type={"password"}
                  name="confirma_senha"
                  onChange={formik.handleChange}
                  required={true}
                  className="text-field"
                  label={
                    Translator({
                      transRef: "translation.Confirme a senha"
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} className="grid-item">
                <Button className="btn-submit" type="submit">
                  {isLoading ? <CircularProgress /> : <Translator transRef="translation.Enviar" />}
                </Button>
              </Grid>
            </form>
          </>
        )}
      </Grid>
    </Container>
  );
};

export default ChangePasswordView;
