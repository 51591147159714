import { Box, CircularProgress, Typography } from '@material-ui/core'
import DateFilterPicker from '../DateFilterPicker'
import ProgrammingSection from '../ProgrammingSection'
import { useProgramStyles } from './styles'
import useAction from './action'
import { useTranslation } from './translation'
import ProgrammingFilter from '../ProgrammingFIlter'
import { ProgrammingContextProvider } from '../../context/ProgrammingContext'
import { ProgrammingVideo } from '../ProgrammingVideo'
import { Fragment } from 'react'

const Programming = ({ detailing }) => {
  const styles = useProgramStyles()
  const translation = useTranslation()
  const { isLoading, dates, data, rooms, tags } = useAction({ groupBy: 'period', detailing })

  if (isLoading) {
    return (
      <Box className={styles.loading}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <ProgrammingContextProvider>
      <Box className={styles.container}>
        <ProgrammingFilter tags={tags} rooms={rooms} />
        <Box className={styles.programmingContainer}>
          {dates && dates.length > 0 && (
            <DateFilterPicker.Root>
              {dates.map(({ id, name, status }) => {
                return <DateFilterPicker.Item key={`${id}_${name}`} enabled={status} date={name} />
              })}
            </DateFilterPicker.Root>
          )}

          {data && data.length > 0 && (
            <ProgrammingSection.Root>
              {data.map(({ id, startAt, items }) => {
                if (items.length == 0) return <Fragment key={`${id}_${startAt}`}></Fragment>
                return (
                  <Fragment key={`${id}_${startAt}`}>
                    <ProgrammingSection.Section>
                      <ProgrammingSection.SectionTitle title={startAt} />
                      <Box className={styles.lectureContainer}>
                        {items &&
                          items.map((lecture) => {
                            return <ProgrammingSection.Item data={lecture} key={`${lecture.id}_${lecture.startAt}`} />
                          })}
                      </Box>
                    </ProgrammingSection.Section>
                  </Fragment>
                )
              })}
            </ProgrammingSection.Root>
          )}
          {data && data.length == 0 && (
            <Box className={styles.emptyContainer}>
              <Typography className={styles.emptyText}>{translation.empty}</Typography>
            </Box>
          )}
        </Box>
      </Box>
      <ProgrammingVideo />
    </ProgrammingContextProvider>
  )
}

export default Programming
