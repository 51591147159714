import { Box, Icon, IconButton, Typography } from '@material-ui/core'
import { useCounterStyles } from './styles'
import { useCounterTranslation } from './translation'
import { useCallback, useEffect, useState } from 'react'

const Counter = ({ minLimit = 1, maxLimit = 0, title = '', onChange = (counter) => {} }) => {
  const [counter, setCounter] = useState(minLimit)
  const styles = useCounterStyles()
  const translation = useCounterTranslation()
  const handleRemoveCounter = useCallback(() => {
    setCounter((previous) => --previous)
  }, [])
  const handleAddCounter = useCallback(() => {
    setCounter((previous) => ++previous)
  }, [])
  let inMinLimit = minLimit === counter
  let inMaxLimit = maxLimit === counter

  useEffect(() => {
    onChange && onChange(counter)
  }, [counter])


  return (
    <Box className={styles.container}>
      <Box className={styles.textContainer}>
        <Typography className={styles.title}>{title || translation.quantity}</Typography>
      </Box>
      <Box className={styles.counterContainer}>
        <IconButton
          className={styles.iconButton}
          onClick={handleRemoveCounter}
          disabled={inMinLimit}>
          <Icon className={styles.icon}>remove</Icon>
        </IconButton>
        <Typography className={styles.counter}>{counter}</Typography>
        <IconButton
          className={styles.iconButton}
          onClick={handleAddCounter}
          disabled={inMaxLimit}>
          <Icon className={styles.icon}>add</Icon>
        </IconButton>
      </Box>
    </Box>
  )
}

export default Counter
