import MODULE_NAME from "./constants";
import { utils } from "../fetch";

export const LOGIN = utils.buildActions(MODULE_NAME, "LOGIN");

export const REGISTER = utils.buildActions(MODULE_NAME, "REGISTER");

export const SET_USER = `${MODULE_NAME}/SET_USER`;

export const SET_IS_AUTENTICATED = `${MODULE_NAME}/SET_IS_AUTENTICATED`;

export const LOGOUT = `${MODULE_NAME}/LOGOUT`;

export const UPDATE_USER = `${MODULE_NAME}/UPDATE_USER`;

export const AUTHORIZED_ACCESS = `${MODULE_NAME}/AUTHORIZED_ACCESS`;

export const SET_CENTER_COST = `${MODULE_NAME}/SET_CENTER_COST`;

export const FETCH_ACTION = `${MODULE_NAME}/FETCH_ACTION`

export const SET_IS_LOADING_FETCH = `${MODULE_NAME}/SET_IS_LOADING_FETCH`

export const AUTHENTICATE_REQUEST = utils.buildActions(
  MODULE_NAME,
  "AUTHENTICATE_REQUEST"
);

export const fetchAction = (meta) => ({
  type: FETCH_ACTION,
  payload: { isLoadingFetch: true, meta },
})

export const setIsLoadingFetch = (status) => ({
  type: SET_IS_LOADING_FETCH,
  payload: { status },
})

export const setUser = (user) => ({
  type: SET_USER,
  payload: { user, token: user.token },
});

export const updateUser = (user) => ({
  type: UPDATE_USER,
  payload: { user },
});

export const setCenterCost = (cc) => ({
  type: SET_CENTER_COST,
  payload: { cc },
});

export const setIsAutenticated = (status) => ({
  type: SET_IS_AUTENTICATED,
  payload: { isAutenticated: status },
});

export const authorizedAccess = (authorizedAccess) => ({
  type: AUTHORIZED_ACCESS,
  payload: { authorizedAccess },
});

export const logout = () => ({
  type: LOGOUT,
});
