import { Typography, Grid, makeStyles, Card, CardHeader, CardContent } from "@material-ui/core"
import { formatDate, Translator } from "@itarget/shared"
import StepperDetailCourse from "../StepperDetailCourse"
import "./style.scss"
import React from "react"

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      display: "flex",
    },
    marginTop: 5,
  },
}))

const ProgramingOnDemand = ({ programingGrouped, steps = [], selectStep }) => {
  const classes = useStyles()
  const mountSubHeader = (title) => {
    return Object.keys(programingGrouped.data.pelestrante_header[title]).map((func, counter) => {
      return (
        <Typography className="programing-title" style={{ fontSize: "18px" }}>
          <Typography className="programing-details">
            <Grid className="occupations">
              <Typography key={counter} className="profiles" style={{ fontSize: "13px" }}>
                <strong className="funcao">
                  {`${func}:`}
                </strong>
                {programingGrouped.data.pelestrante_header[title][func].join(", ")}.
              </Typography>
            </Grid>
          </Typography>
        </Typography>
      )
    })
  }
  const getHour = (steps) => {
    const hour = steps[Object.keys(steps).filter((step) => step != "")[0]][0]
    if (!hour["hora_inicio"]) {
      return <></>
    }
    return <> das {formatDate.hour(hour["hora_inicio"]) + " - " + formatDate.hour(hour["hora_fim"])}</>
  }

  const translation = {
    onDemand: Translator({ transRef: "translation.On Demand" }),
    recordedClass: Translator({ transRef: "translation.Este é seu acervo de aulas gravadas!" }),
  }

  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          title={translation?.onDemand}
          subheader={translation?.recordedClass}
        />
      </Card>
      <div>
        {steps != null &&
          Object.keys(steps).map((title, i) => {
            return (
              <Card className={classes.root}>
                <Grid container>
                  <Grid item xs={12}>
                    <CardHeader title={`${i + 1} - ${title}`} subheader={mountSubHeader(title)} />
                    <CardContent style={{ padding: "0 10px" }}>
                      {Object.keys(steps[title]).map((date) => {
                        return (
                          <>
                            {Object.keys(steps[title][date]).filter((step) => step != "").length > 0 && date && (
                              <Grid>
                                {formatDate.getDay(date)} de {formatDate.monthInFull(date)}
                                {getHour(steps[title][date])}
                                <hr />
                              </Grid>
                            )}
                            {Object.keys(steps[title][date]).map((video, i) => {
                              return <>{video && <StepperDetailCourse selectStep={selectStep} steps={steps[title][date][video]} />}</>
                            })}
                          </>
                        )
                      })}
                    </CardContent>
                  </Grid>
                </Grid>
              </Card>
            )
          })}
      </div>
    </>
  )
}

export default ProgramingOnDemand
