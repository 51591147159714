import { makeStyles } from '@material-ui/core'

import MenuC from '../../MenuC'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Trailing } from './Trailing'
import { useLocation, useParams } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  logged: {
    height: '100px !important',
    '& #menu-desktop.show': {
      top: '100px !important',
    },
  },
  root: {
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#01172C',
    borderBottom: '1px solid #000',
  },
  iconcart: {
    width: '32px',
    height: '32px',
  },
  container: {
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 425px)': {
      borderBottom: '0',
      position: 'unset',
    },
  },
  header: {
    marginRight: '1%',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '@media (min-width: 1240px)': {
      width: '1240px',
    },
    '@media (max-width: 740px)': {
      marginTop: '1%',
    },
  },
  logo: {
    zIndex: 1,
    maxHeight: '100px',
    aspectRatio: 2,
    objectFit: 'contain',
    '@media (max-width: 500px)': {
      maxHeight: '80px',
    },
  },
  trailingLogged: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& .user-information-block': {
      color: 'inherit',
      '& .MuiListItemAvatar-root': {},
    },
    '& .shoppingCartIcon, & .userProfileInformationName': {
      color: '#fff !important',
    },
    '& .MuiTypography-colorTextSecondary': {
      color: '#fffffff5 !important',
    },
    '& > .MuiIconButton-root': {
      padding: theme.spacing(0.5),
      width: 'auto',
    },
    '@media screen and (max-width: 1220px)': {
      '& .MuiListItem-gutters': {
        gap: `${theme.spacing(0.5)}px !important`,
        padding: `${theme.spacing(0.5)}px !important`,
      },
    },
  },
  trailing: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& .userProfileInformationContainer': {
      width: 'auto !important',
      maxWidth: '80px',
    },
  },
  trailingMobile: {
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 740px)': {
      padding: '0',
      display: 'flex',
    },
  },
  leading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '@media (max-width: 740px)': {
      padding: '0',
    },
  },
  menuItem: {
    textTransform: 'uppercase',
  },
  menuButton: {
    zIndex: 4,
    '& .material-icons': {
      color: '#fff',
    },
    '@media (min-width: 1190px)': {
      display: 'none',
    },
  },
}))

const Header = ({
  logo,
  client,
  shoppingCarts,
  menuItemsC = [],
  isLoadingMenuData,
  user,
  goTo,
  onLogout,
  statusAssociated,
  category,
}) => {
  const MAX_MENU_SIZE_DESKTOP = 1190
  const windowSize = useRef(window.innerWidth)
  const [showMenu, setShowMenu] = useState(window.innerWidth <= MAX_MENU_SIZE_DESKTOP ? 'hide' : 'show')

  useEffect(() => {
    const defineMenuStatus = () => {
      setShowMenu((prevStatus) => {
        if (windowSize.current == window.innerWidth) return prevStatus
        return window.innerWidth <= MAX_MENU_SIZE_DESKTOP ? 'hide' : 'show'
      })
    }
    window.addEventListener('resize', defineMenuStatus)
  }, [window.innerWidth, setShowMenu, MAX_MENU_SIZE_DESKTOP, windowSize.current])
  const [imageBackground, setImageBackground] = useState(null)
  const systemTheme = useSelector((state) => state.theme.systemTheme)
  const settingCourse = useSelector((state) => state.setting.settingCommerce)
  const location = useLocation()
  const { cc: centerCostId } = useParams()
  const shouldRenderBanner = location.pathname === '/offers' || location.pathname === `/offers-unique/${centerCostId}`

  let backgroundImageColors = {
    top: systemTheme == 'light' ? '#fafafa00' : '#1C1C1C00',
    bottom: systemTheme == 'light' ? '#fafafafd' : '#1C1C1Cff',
  }

  useEffect(() => {
    try {
      if (settingCourse.url_image_topo_background) setImageBackground(settingCourse.url_image_topo_background)
    } catch (e) {}
  }, [settingCourse])
  const styles = useStyles({
    backgroundImageColors,
    imageBackground,
    shouldRenderBanner,
    showMenu: showMenu != 'hide',
    theme: systemTheme,
  })

  function toggleMenu() {
    if (showMenu === 'hide') {
      setShowMenu('show')
    } else {
      setShowMenu('hide')
    }
  }

  return (
    <>
      <header className={`${styles.root} ${user?.data?.id ? styles.logged : ''}`}>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.leading}>
              {logo && (
                <img
                  className={`${styles.logo}`}
                  alt={client}
                  src={logo}
                />
              )}
            </div>
            <MenuC
              items={menuItemsC}
              isLoadingMenuData={isLoadingMenuData}
              classes={{ root: styles.menu, itemRoot: styles.menuItemRoot }}
              showMenu={showMenu}
            />
            <div className={styles.trailing}>
              <Trailing
                showMenu={showMenu}
                category={category}
                goTo={goTo}
                onLogout={onLogout}
                shoppingCarts={shoppingCarts}
                statusAssociated={statusAssociated}
                styles={styles}
                toggleMenu={toggleMenu}
                user={user}
              />
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
