import RecoveryLoginView from "./views/RecoveryLoginView";
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { Config, useFetchApi } from "@itarget/shared";
import { recoveryPassword, useRedirect } from "@itarget/shared";

const RecoveryLoginContainer = () => {
  const { cc } = useParams()
  const { goTo } = useRedirect();
  const [state, setState] = useState();
  const lang = useSelector((state) => state.language.lang);
  const centerCostId = useSelector((state) => state.auth.cc);
  const settingCommerce = useSelector((state) => state.setting.settingCommerce);

  const { data: settingCourse, fetchData: fetchSettingCourse, isLoading } = useFetchApi(
    Config.baseURL +
    `api/${lang}/setting-course-commerce/setting?centro_custo_id=${cc || centerCostId}`
  );

  
  const onValid = () => {
    setState("sucess");

    setTimeout(() => {
      goTo(`change-password${settingCourse?.data?.centro_custo_padrao ? `/${cc || centerCostId}` : ''}`);
    }, 8000);
  };

  const { submit, isLoadingRecoveryPassword, errorRecoveryPassword } =
    recoveryPassword({
      onValid,
    });

  const onSubmit = (value) => {
    value.centro_custo_id = cc || centerCostId;
    submit(value);
  };

  const formik = useFormik({
    initialValues: { login: "" },
    enableReinitialize: true,
    onSubmit: onSubmit,
  });

  useEffect(() => {
    fetchSettingCourse()
  }, [cc])

  useEffect(() => {
    if (settingCourse?.data?.texto_aba_navegador) {
      document.title = settingCourse?.data?.texto_aba_navegador;
    }

    if (settingCourse?.data?.icone_aba_navegador) {
      document.getElementById("favicon").href = settingCourse?.data?.icone_aba_navegador;
    }
  }, [settingCourse]);

  return (
    <RecoveryLoginView
      settingCommerce={settingCourse?.data || settingCommerce}
      errorLogin={errorRecoveryPassword}
      formik={formik}
      state={state}
      setState={setState}
      isLoadingService={isLoading}
      isLoading={isLoadingRecoveryPassword}
    />
  );
};

export default RecoveryLoginContainer;