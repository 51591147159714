import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { toast, auth } from '../../redux'
import { Config, useFetchApi, useRedirect, convertBase64, Translator, convertInputFileToFileType } from '@itarget/shared'
import NewPanelView from './views/NewPanelView'

const NewPanelContainer = () => {
  const { goTo } = useRedirect()
  const dispatch = useDispatch()
  const [openMyData, setOpenMyData] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [passwords, setPasswords] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  })
  const [showQuiz, setShowQuiz] = useState()
  const selectedLang = useSelector((state) => state.language.lang)
  const settingCommerce = useSelector((state) => state.setting.settingCommerce)
  const [listService, setListService] = useState([
    {
      label: 'Minhas compras',
      key: 'my-purchases',
      icon: 'shopping_bag',
      onClick: () => goTo('my-activity'),
    },
    {
      label: 'Certificados',
      key: 'certificates',
      icon: 'insert_drive_file',
      onClick: () =>
        window.open(
          `${Config.urlIcase}/estacao/index/autenticar-hash/lang/${selectedLang}/evento/${centro_custo_id}/hash/${user.data.hash_link_senha}/link/L2VzdGFjYW8vaW5kZXgvbGlzdGEtY2VydGlmaWNhZG9zL2xheW91dC9tb2RhbA==`,
          '_blank'
        ),
    },
    {
      label: 'Recibos',
      key: 'receipts',
      icon: 'insert_drive_file',
      onClick: () => goTo(`my-activity`),
    },
    {
      label: 'Meus cursos',
      key: 'my-courses',
      icon: 'cast_for_education',
      onClick: () => goTo('my-activity#3'),
    },
    {
      label: 'Minhas anuidades',
      key: 'my-annuities',
      icon: 'local_atm',
      onClick: () => goTo('my-activity#1'),
    },
    {
      label: 'Minhas respostas',
      key: 'my-answers',
      icon: 'list_alt',
      onClick: () => setShowQuiz(true),
    },
    {
      label: 'Minhas aulas e transmissões',
      key: 'streaming',
      icon: 'ondemand_video',
      onClick: () => goTo('my-course'),
    },
  ])

  const profilePanelButtons = [
    {
      label: 'MEU PERFIL',
      icon: 'person',
      onClick: () => setStateProfile('profile'),
    },
    {
      label: 'MEUS DADOS',
      icon: 'info',
      onClick: () => goTo('me'),
    },
    {
      label: 'ALTERAR SENHA',
      icon: 'password',
      onClick: () => setStateProfile('changePassword'),
    },
  ]

  const translation = {
    passwordError: Translator({ transRef: 'translation.As senhas não são compatíveis' }),
    modalPasswordError: Translator({ transRef: 'translation.Preencha os campos antes de redefinir sua senha' }),
    success: Translator({ transRef: 'translation.Ação efetuada com sucesso.' }),
    failure: Translator({ transRef: 'translation.Ação não efetuada. Por gentileza, tente novamente mais tarde.' }),
  }

  const [stateProfile, setStateProfile] = useState('profile')

  const user = useSelector((state) => state.auth.user)

  const centro_custo_id = useSelector((state) => state.auth.cc)

  const isAuthenticated = useSelector((state) => state.auth.isAutenticated)

  const handleOpenMyData = () => {
    setOpenMyData(true)
  }

  const handleCloseMyData = () => {
    setOpenMyData(false)
  }

  const { data: statusAssociated, fetchData: fetchStatusAssociated } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/association/status-associated?associationId=${user?.data && user?.data?.associacao_id}&entityId=1`
  )

  const { data: reRegister, fetchData: fetchReRegister } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/person/re/register?pessoa_id=${user?.data?.id}&centro_custo_id=${centro_custo_id}&aplicacoes=4`
  )

  const {
    data: dataPersonCourse,
    fetchData: fetchPersonCourse,
    isLoading: isLoadingPersonCourse,
  } = useFetchApi(Config.baseURL + `api/${selectedLang}/person/${user?.data?.id}/course`)

  const {
    data: passwordReset,
    error: errorPasswordReset,
    fetchData: fetchPasswordReset,
  } = useFetchApi(Config.baseURL + `api/${selectedLang}/change-password/change`, 'PUT')

  const {
    data: personPut,
    error: erroPersonPut,
    fetchData: fetchPersonPut,
    isLoading: isLoadingPersonPut
  } = useFetchApi(Config.baseURL + `api/${selectedLang}/person/${user?.data?.id}`, 'PUT')

  const { data: gamefication, fetchData: fetchGamefication } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/person/${user?.data?.id}/gamification?centro_custo_id=${centro_custo_id}`
  )

  const {
    data: activeStreaming,
    isLoading: isLoadingActiveStreaming,
    fetchData: fetchActiveStraming,
  } = useFetchApi(Config.baseURL + `api/${selectedLang}/activity-schedule/active-streaming`)

  useEffect(() => {
    if (!reRegister?.data?.[0]) return
    const timeout = setTimeout(() => goTo('me'), 2000)
    return () => clearTimeout(timeout)
  }, [reRegister])

  useEffect(() => {
    if (isAuthenticated) {
      fetchReRegister()
      fetchStatusAssociated()
    }
  }, [isAuthenticated])

  useEffect(() => {
    fetchGamefication()
    fetchActiveStraming()

    if (settingCommerce?.json_tela_perfil) {
      setListService((service) => {
        let newList = service.filter(
          (serv) =>
            !Object.keys(settingCommerce.json_tela_perfil)
              .filter((key) => settingCommerce.json_tela_perfil[key] === false)
              .includes(serv.key)
        )
        return newList
      })
    }
  }, [fetchActiveStraming, fetchGamefication, settingCommerce.json_tela_perfil])

  useEffect(() => {
    if (activeStreaming !== null && !activeStreaming?.data?.active) {
      setListService((service) => {
        return service.filter((serv) => serv.key !== 'streaming')
      })
    }
  }, [activeStreaming, activeStreaming?.data?.active])

  useEffect(() => {
    if (erroPersonPut !== null) {
      dispatch(
        toast.actions.enqueue({
          content: erroPersonPut,
          severity: 'error',
        })
      )
    }
    if (personPut !== null) {
      let data = user.data
      dispatch(
        auth.actions.updateUser({
          ...user,
          data: {
            ...data,
            foto: personPut?.data?.foto,
          },
        })
      )

      dispatch(
        toast.actions.enqueue({
          content: personPut.message,
          severity: 'success',
        })
      )
    }
  }, [personPut, erroPersonPut])

  const onSubmit = (values) => {
    if (values.confirma_senha !== values.senha_atual) {
      dispatch(
        toast.actions.enqueue({
          content: Translator({ transRef: 'translation.As senhas não são compatíveis' }),
          severity: 'error',
        })
      )
      return
    }
    fetchPersonPut(JSON.stringify(values))
  }
  const formik = useFormik({
    initialValues: {
      id: user?.data?.id,
      senha_atual: '',
      nova_senha: '',
      confirma_senha: '',
    },
    enableReinitialize: true,
    onSubmit,
  })

  const sendPhoto = async (e) => {
    try {
      let file = await convertInputFileToFileType({ inputEventTarget: e.target })
      const base64 = await convertBase64(file)
      let fetchPersonPutPayload = JSON.stringify({ id: user.data.id, foto: base64 })
      fetchPersonPut(fetchPersonPutPayload)
    } catch (error) {
      dispatch(
        toast.actions.enqueue({
          content: translation.failure,
          severity: 'error',
        })
      )
    }
  }

  useEffect(() => {
    if (errorPasswordReset !== null) {
      dispatch(
        toast.actions.enqueue({
          content: errorPasswordReset,
          severity: 'error',
        })
      )
    }
    if (passwordReset !== null) {
      dispatch(
        toast.actions.enqueue({
          content: passwordReset.message,
          severity: 'success',
        })
      )
      goTo(`offers-unique/${centro_custo_id}`)
    }
  }, [passwordReset, errorPasswordReset, dispatch])

  const onOpenModal = () => {
    if (passwords.currentPassword && passwords.newPassword && passwords.confirmPassword !== '') {
      setOpenModal(true)
    } else {
      dispatch(
        toast.actions.enqueue({
          content: translation.modalPasswordError,
          severity: 'warning',
        })
      )
    }
  }

  const onCloseModal = () => {
    setOpenModal(false)
  }

  const handleSubmit = (eventSubmit) => {
    eventSubmit.preventDefault()
    fetchPasswordReset(
      JSON.stringify({
        id: user?.data?.id,
        senha_atual: passwords.currentPassword,
        senha: passwords.newPassword,
        confirmar_senha: passwords.confirmPassword,
      })
    )
    setOpenModal(false)
  }

  const handlePasswordChange = (event) => {
    setPasswords({
      ...passwords,
      [event.target.name]: event.target.value,
    })
  }

  const handleFormInformation = () => {
    setStateProfile('profile')
  }

  const handleFormChangePassword = () => {
    setStateProfile('changePassword')
  }

  useEffect(() => {
    fetchPersonCourse()
  }, [user.data?.id])

  const handleLogout = () => {
    dispatch(auth.actions.logout())
  }

  const getInitializedCourses = (course) => {
    let minToEnableCertificate = 80
    let certificateRule = settingCommerce?.meta_certificado ? Number(settingCommerce?.meta_certificado) : minToEnableCertificate
    let maxToBeInitialized = certificateRule ? certificateRule : minToEnableCertificate
    return course.total_assistido > 0 && course.total_assistido < maxToBeInitialized
  }

  let initializedPersonCourses = dataPersonCourse?.data ? dataPersonCourse?.data.filter(getInitializedCourses) : []

  let displayInitializedCourses = listService.find((row) => row.label == 'Minhas aulas e transmissões') ? true : false

  return (
    <NewPanelView
      user={user}
      goTo={goTo}
      auth={auth}
      formik={formik}
      openModal={openModal}
      passwords={passwords}
      reRegister={reRegister}
      openMyData={openMyData}
      listService={listService}
      gamefication={gamefication}
      stateProfile={stateProfile}
      financialStatus={statusAssociated?.data?.financialStatus || statusAssociated?.data?.status}
      centro_custo_id={centro_custo_id}
      isLoadingPersonPut={isLoadingPersonPut}
      dataPersonCourse={initializedPersonCourses}
      isLoadingPersonCourse={isLoadingPersonCourse}
      profilePanelButtons={profilePanelButtons}
      isLoadingActiveStreaming={isLoadingActiveStreaming}
      displayInitializedCourses={displayInitializedCourses}
      sendPhoto={sendPhoto}
      onOpenModal={onOpenModal}
      handleSubmit={handleSubmit}
      setPasswords={setPasswords}
      onCloseModal={onCloseModal}
      handleLogout={handleLogout}
      handleFormInformation={handleFormInformation}
      handleFormChangePassword={handleFormChangePassword}
      handleOpenMyData={handleOpenMyData}
      handleCloseMyData={handleCloseMyData}
      handlePasswordChange={handlePasswordChange}
      showQuiz={showQuiz}
      setShowQuiz={setShowQuiz}
    />
  )
}

export default NewPanelContainer
