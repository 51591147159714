import { CardMedia, Grid, Icon } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFetchApi, Config, useRedirect, useWindowDimensions } from '@itarget/shared'
import './styles.scss'
import { CircularProgress } from '@material-ui/core'
import CardDetailStore from '../CardDetailStore'
const CarouselCardOffers = (props) => {
  const { goTo } = useRedirect()
  const { width: windowWidth } = useWindowDimensions()
  const [marginLeft, setMarginLeft] = useState(0)
  const [widthTotal, setWidthTotal] = useState(0)
  const [hasNext, setHasNext] = useState(true)
  const [hasPrevious, setHasPrevious] = useState(false)
  const selectedLang = useSelector((state) => state.language.lang)
  const settingCourse = useSelector((state) => state.setting.settingCommerce)
  const user = useSelector((state) => state.auth.user)
  const cc = props?.cc
  const store = props?.store
  const conditionModel = settingCourse?.tipo_layout
  const {
    data: listOffersAll,
    fetchData: fetchListOffers,
    isLoading: isLoadingListOffers,
  } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/activity-schedule/activity-carrusel?centro_custo_id=${cc}${
        user?.data?.id ? `&pessoa_id=${user?.data?.id}` : ''
      }&agenda_atividade_id=${props?.id}`
  )
  let listOffers = new Array()
  listOffers.data = store ? listOffersAll?.data.filter((category) => category.tatv_id == store) : listOffersAll?.data

  function getCardWidth() {
    if (windowWidth > 800) {
      return 346
    } else if (windowWidth > 575 && windowWidth <= 800) {
      return 300
    } else if (windowWidth > 320 && windowWidth <= 575) {
      return windowWidth * 0.47
    } else {
      return windowWidth * 0.4
    }
  }

  useEffect(() => {
    fetchListOffers()
  }, [user, fetchListOffers])

  useEffect(() => {
    if (listOffers) {
      setWidthTotal(listOffers?.data?.length * getCardWidth())
    }
  }, [listOffers, getCardWidth])

  const goPrev = () => {
    if (marginLeft < 0) {
      setMarginLeft((marginLeft) => marginLeft + getCardWidth())
      setHasPrevious(true)
      setHasNext(true)
    } else {
      setHasPrevious(false)
    }
  }

  const goNext = () => {
    const initialWidth = document.getElementsByClassName('carouselCards-inner')[0].offsetWidth

    if (marginLeft > widthTotal * -1 + initialWidth) {
      setMarginLeft((marginLeft) => marginLeft - getCardWidth())
      setHasNext(true)
      setHasPrevious(true)
    } else {
      setHasNext(false)
    }
  }

  let carrouselListOffers = listOffers?.data || []

  carrouselListOffers = carrouselListOffers.map((item) => {
    let local_publicacao = item?.local_publicacao ? JSON.parse(item.local_publicacao) : {}

    return { ...item, local_publicacao}
  }).filter((item) => {
     return item?.local_publicacao?.offers && (item.imagem_lista  || item.imagem_capa_portal )
  })

  return (
    <Grid className='carouselCards-container carrossel-mini-banners'>
      <Grid onClick={goPrev}>
        <Icon
          className='arrow'
          style={{
            opacity: !hasPrevious ? '0.2' : '1',
            cursor: !hasPrevious ? 'no-drop' : 'pointer',
          }}>
          keyboard_arrow_left
        </Icon>
      </Grid>
      <div className='carouselCards-inner'>
        <Grid
          style={{ marginLeft: marginLeft }}
          className='carouselCards-items'>
          {isLoadingListOffers && <CircularProgress />}
          {carrouselListOffers?.map((row, i) => (
            <Grid
              key={i}
              className='card-grid'>
              <Grid
                className='card-grid-fluid'
                style={{
                  width: getCardWidth(),
                  position: 'relative',
                }}>
                {conditionModel !== 6 ? (
                  <>
                    <CardMedia
                      className={'cardOffers-container'}
                      image={row?.imagem_lista || row?.imagem_capa_portal}
                      onClick={() => props.selectOffer(row && row)}
                    />
                    {row?.desc_atv && <div className='tooltip'>{row?.desc_atv}</div>}
                  </>
                ) : (
                  <CardDetailStore
                    carousel
                    direction='row'
                    selectOffer={props.selectProduct}
                    row={row && row}
                  />
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </div>
      <Grid onClick={goNext}>
        <Icon
          className='arrow'
          style={{
            opacity: !hasNext || carrouselListOffers.length == 1 ? '0.2' : '1',
            cursor: !hasNext || carrouselListOffers.length == 1 ? 'no-drop' : 'pointer',
          }}>
          keyboard_arrow_right
        </Icon>
      </Grid>
    </Grid>
  )
}

export default CarouselCardOffers
