import { Button } from '@itarget/shared'
import { useReturnStyles } from './styles'
import { useReturnTranslation } from './translation'
import { Box } from '@material-ui/core'

const Return = ({ onReturn = () => {} }) => {
  const styles = useReturnStyles()
  const translation = useReturnTranslation()

  return (
    <Box
      onClick={onReturn}
      className={styles.container}>
      <Button
        icon='undo'
        className={styles.button}>
        {translation.offers}
      </Button>
    </Box>
  )
}

export default Return
