import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Config, useFetchApi, changePassword, useRedirect } from "@itarget/shared";
import { toast } from "../../redux";
import ChangePasswordView from "./views/ChangePasswordView";

const ChangePasswordContainer = () => {
  const { cc } = useParams();
  const { goTo } = useRedirect();
  const [state, setState] = useState("change");
  const lang = useSelector((state) => state.language.lang);
  const centerCostId = useSelector((state) => state.auth.cc);
  const settingCommerce = useSelector((state) => state.setting.settingCommerce);
  let selectedCenterCostId = cc || centerCostId;
  

  const { data: settingCourse, fetchData: fetchSettingCourse, isLoading } = useFetchApi(
    Config.baseURL +
      `api/${lang}/setting-course-commerce/setting?centro_custo_id=${selectedCenterCostId}`
  );

  const onValid = () => {
    setState("success");
    setTimeout(() => {
      goTo(`login${settingCourse?.centro_custo_padrao ? `/${selectedCenterCostId}` : ''}`);
    }, 4000);
  };

  const { submit } = changePassword({ onValid, toast });

  const onSubmit = (values) => {
    values.centro_custo_id = selectedCenterCostId;
    submit(values);
  };

  const formik = useFormik({
    initialValues: { codigo: "", senha: "", confirma_senha: "" },
    enableReinitialize: true,
    onSubmit,
  });

  useEffect(() => {
    fetchSettingCourse();
  }, [cc]);

  useEffect(() => {
    if (settingCourse?.data?.texto_aba_navegador) {
      document.title = settingCourse.data.texto_aba_navegador;
    }

    if (settingCourse?.data?.icone_aba_navegador) {
      document.getElementById("favicon").href = settingCourse.data.icone_aba_navegador;
    }
  }, [settingCourse]);

  return (
    <ChangePasswordView
      settingCommerce={settingCourse?.data || settingCommerce}
      state={state}
      formik={formik}
      isLoadingService={isLoading}
    />
  );
};

export default ChangePasswordContainer;