import React from "react"
import ViewListIcon from "@material-ui/icons/ViewList"
import ViewModuleIcon from "@material-ui/icons/ViewModule"
import ToggleButton from "@material-ui/lab/ToggleButton"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import { useState } from "react"
import "./style.scss"
import { Grid } from "@material-ui/core"

const SortButton = ({ gridLayoutHandler, listLayoutHandler }) => {
  return (
      <ToggleButtonGroup className="sort-buttons-group" orientation="horizontal" exclusive>
        <ToggleButton onClick={listLayoutHandler} className="list-btn" value="list" aria-label="list">
          <ViewListIcon />
        </ToggleButton>
        <ToggleButton onClick={gridLayoutHandler} className="grid-btn" value="module" aria-label="module">
          <ViewModuleIcon />
        </ToggleButton>
      </ToggleButtonGroup>
  )
}

export default SortButton
