import { Icon, TextField, Grid, IconButton, Typography } from '@material-ui/core'
import './style.scss'

const TextFieldNumber = ({ card, onClickAddAmount, onClickRemoveAmount, amountSubscribe, amount, amountChange, max }) => {
  const addAmount = () => {
    ;(amountSubscribe < max || amount < max) && (amountSubscribe ? onClickAddAmount(card) : amountChange(amount + 1))
  }
  const removeAmout = () => {
    ;(amountSubscribe > 1 || amount > 1) && (amountSubscribe ? onClickRemoveAmount(card) : amountChange(amount - 1))
  }
  return (
    <Grid className='amount'>
      <Icon color='primary'>swap_vert</Icon>
      <Typography color='textPrimary'>Quantidade:</Typography>
      <IconButton className='icon-remove' onMouseDown={() => removeAmout()}>
        <Icon>remove</Icon>
      </IconButton>
      <TextField
        className='field'
        type='number'
        value={amountSubscribe ? amountSubscribe : amount}
        InputProps={{ inputProps: { max: max, min: 1 }, readOnly: true }}
      />
      <IconButton className='icon-add' onMouseDown={() => addAmount()}>
        <Icon>add</Icon>
      </IconButton>
    </Grid>
  )
}

export default TextFieldNumber
