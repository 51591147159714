import { formatMoney, Config, useFetchApi, useRedirect, Loading, Translator, ReplaceAllInstances, Button } from '@itarget/shared'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListItensCart from './ListItensCart'
import { Typography, Divider, Grid, Paper, CardContent, TextField, CircularProgress } from '@material-ui/core'
import { toast, payment as paymentRedux } from '../../redux'
import './index.scss'
import { makeStyles } from '@material-ui/styles'
import { enumsSetting } from "@itarget/shared";


const useStyles = makeStyles((theme) => ({
  paperContainer: {
    backgroundColor: (props) => (props.theme == 'light' ? '#fff' : '#232323'),
  },
  cardShopping: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    '& .shopping-card-title': {
      paddingBottom: theme.spacing(1),
      borderBottom: `${theme.spacing(0.125)}px solid ${theme.palette.grey['400']}`,
    },
  },
  textResume: {
    fontWeight: 'bold',
  },
}))

const CardShopping = ({ fetchInscricoes, state, setState, selected, setSelected, user, courses, setting }) => {
  const systemTheme = useSelector((state) => state.theme.systemTheme)
  const styles = useStyles({ theme: systemTheme })
  const { goTo } = useRedirect()
  const cloneDeep = require('clone-deep')
  const dispatch = useDispatch()
  const center_cost_id = useSelector((state) => state.auth.cc)
  const [total, setTotal] = useState(0)
  const selectedLang = useSelector((state) => state.language.lang)
  const [valueVoucher, setValueVoucher] = useState('')
  const [subscriptionIds, setSubscriptionIds] = useState('')
  const [finalAmount, setFinalAmount] = useState([])
  const [descriptionActivityPending, setDescriptionActivityPending] = useState('')
  const activeVoucher = setting?.data.find(item => item.codigo == enumsSetting.ACTIVE_VOUCHER)
  
  const {
    data: voucherPut,
    error: erroVoucherPut,
    fetchData: fetchVoucherPut,
    isLoading: isLoadingApllyVoucher,
  } = useFetchApi(Config.baseURL + `api/${selectedLang}/finance/voucher/apply`, 'POST')

  const {
    data: subscriptionComboApi,
    fetchData: fetchComboSubscription,
    isLoading: isLoadingComboSubscription,
  } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/subscription/combo?pessoa_id=${user.data && user.data.id}&inscricao_id[]=${subscriptionIds}`
  )

  const onChangeVoucher = (event) => {
    setValueVoucher(event.target.value)
    if (event.target.value != '') {
      setState('voucher')
    } else {
      setState('payment')
    }
  }

  const goFinishVoucher = async () => {
    goTo(`my-activity`)
  }

  const translation = {
    total: Translator({ transRef: 'translation.Seu carrinho de compra está vazio!' }),
    voucherTextLabel: Translator({ transRef: 'translation.Cód. do Voucher' }),
    emptyVoucher: Translator({ transRef: 'translation.Preencha o campo com o código do voucher.' }),
    descriptionActivity: Translator({
      transRef: `translation.A atividade %s ainda está pendente. Por favor, realize o pagamento dessa atividade antes de prosseguir.`,
    }),
  }

  const confirmVoucher = async (eventSubmit) => {
    eventSubmit.preventDefault()
    if (!valueVoucher) {
      dispatch(
        toast.actions.enqueue({
          content: translation.emptyVoucher.props.children,
          severity: 'error',
        })
      )
      return
    }
    await fetchVoucherPut(
      JSON.stringify({
        origem_pessoa_id: user.data.id,
        centro_custo_id: center_cost_id,
        amount: total,
        subscription: selected,
        voucher: valueVoucher,
      })
    )
    if (!erroVoucherPut) {
      await fetchInscricoes()
      setValueVoucher('')
      window.scrollTo(0, document.body.scrollHeight)
    }
  }

  useEffect(() => {
    if (descriptionActivityPending) {
      dispatch(
        toast.actions.enqueue({
          content: ReplaceAllInstances(translation.descriptionActivity.props.children, [descriptionActivityPending]),
          severity: 'error',
        })
      )
    }
    setDescriptionActivityPending('')
  }, [descriptionActivityPending])

  const goPayment = () => {
    const pendingActivity = selected.find((select) =>
      select.pre_agenda_atividades?.some(
        (pre_agenda) =>
          pre_agenda.status_inscricao !== 2 &&
          !selected.some((atividade) => atividade.agenda_atividade_id === pre_agenda.agenda_atividade_pre_id)
      )
    )

    if (!(total > 0)) {
      dispatch(
        toast.actions.enqueue({
          content: translation.total,
          severity: 'error',
        })
      )
      return
    } else if (pendingActivity) {
      setDescriptionActivityPending(
        pendingActivity.pre_agenda_atividades.find(
          (pre_agenda) =>
            pre_agenda.status_inscricao !== 2 &&
            !selected.some((atividade) => atividade.agenda_atividade_id === pre_agenda.agenda_atividade_pre_id)
        ).descricao
      )

      return
    }
    document.documentElement.scroll({
      top: 0,
      left: 0,
    })
    setState('payment')
  }

  useEffect(() => {
    setSubscriptionIds(selected?.map((res) => res.inscricao_uuid).join(','))
    setTotal(0)

    function valueVoucher(value, descountType, descount) {
      if (descountType === 'P') {
        value = value * (parseFloat(descount) / 100)
      } else if (descountType === 'V') {
        value = parseFloat(descount)
      }
      return value
    }
    setFinalAmount([])

    const getMoney = (item, inscricao) => {
      if (item.valor_desconto !== null && inscricao) {
        return parseFloat(inscricao.cr_valor || 0) - parseFloat(item.valor_desconto || 0)
      }
      if (inscricao) {
        return parseFloat(inscricao.cr_valor || 0)
      }
      return item.valor
    }

    if (subscriptionComboApi?.data?.length) {
      subscriptionComboApi.data.map((item) => {
        let account = 0
        let inscricao = selected?.find((i) => i.inscricao_uuid === item.inscricao_id) || null
        account = getMoney(item, inscricao)
        if (subscriptionComboApi.data.length > 1 && item.tipo_desconto_voucher && item.valor_voucher) {
          account = account - valueVoucher(account, item.tipo_desconto_voucher, item.valor_voucher)
        }
        finalAmount.push(parseFloat(account))
      })
    }

    selected?.map((row) => {
      if (!subscriptionComboApi?.data?.length) {
        finalAmount.push(parseFloat(row.cr_valor))
      }
    })
    setTotal(selected?.length > 0 && finalAmount?.length > 0 ? finalAmount.reduce((amount1, amount2) => amount1 + amount2) : 0)
  }, [selected, subscriptionComboApi])

  useEffect(() => {
    if (subscriptionIds && subscriptionIds?.split(',').length > 0) {
      fetchComboSubscription()
    }
  }, [subscriptionIds])

  useEffect(() => {
    dispatch(paymentRedux.actions.setTotal(total))
  }, [total])

  useEffect(() => {
    if (!isLoadingApllyVoucher) {
      if (erroVoucherPut) {
        dispatch(
          toast.actions.enqueue({
            content: erroVoucherPut,
            severity: 'error',
          })
        )
      }
      if (voucherPut?.message) {
        dispatch(
          toast.actions.enqueue({
            content: voucherPut.message,
            severity: 'success',
          })
        )
      }
    }
  }, [isLoadingApllyVoucher])

  useEffect(() => {
    if (voucherPut?.data?.id && selected.length > 0) {
      const descount = voucherPut.data.voucher.desconto
      const descountType = voucherPut.data.voucher.tipo_desconto
      let selected2 = cloneDeep(selected)
      setSelected(
        selected2.map((row) => {
          if (voucherPut.data.id === row.inscricao_uuid) {
            if (descountType === 'P') {
              row.valor_desconto = row.valor * (parseFloat(descount) / 100)
            } else if (descountType === 'V') {
              row.valor_desconto = parseFloat(descount)
            }
            setValueVoucher('')
          }
          return row
        })
      )

      setState('payment')
      dispatch(paymentRedux.actions.setCodVoucher(valueVoucher))
      fetchComboSubscription()
    }
  }, [voucherPut])
  // if (state === 'payment') return <></>
  return (
    <Paper className={`resumoCompra ${styles.paperContainer}`}>
      <CardContent>
        <Grid className={`shoppingCard ${styles.cardShopping}`}>
          <Typography className={styles.textResume}>
            <Translator transRef={`translation.Resumo da compra`} />
          </Typography>
          <Grid className='grid-selected'>
            {isLoadingComboSubscription && <Loading text='...' />}
            {!isLoadingComboSubscription && (
              <ListItensCart
                selected={selected}
                subscriptionComboApi={subscriptionComboApi}
              />
            )}
          </Grid>

          {isLoadingComboSubscription && (
            <Typography className='text-sum'>
              <Translator transRef='translation.Calculando...' />
            </Typography>
          )}
          {!isLoadingComboSubscription && (
            <Grid>
              <Typography className='text-sum'>
                <Translator transRef={`translation.Total`} />:
              </Typography>
              <Typography className='value-sum'>
                <span className='value'> R$ {formatMoney.toReal(total)}</span>
              </Typography>
            </Grid>
          )}

          {(state === 'payment' || state === 'voucher') && total !== 0 && activeVoucher?.ativo === 'S' && (
            <Grid item>
              <form
                autoComplete='off'
                onSubmit={confirmVoucher}>
                <Grid
                  container
                  direction='column'
                  spacing={1}>
                  <Grid
                    container
                    item>
                    <TextField
                      fullWidth
                      size='small'
                      id='outlined-voucher-textfield'
                      label={translation.voucherTextLabel}
                      autoComplete='off'
                      variant='outlined'
                      name='voucher'
                      value={valueVoucher}
                      onChange={(e) => onChangeVoucher(e)}
                    />
                  </Grid>
                  <Grid
                    container
                    item>
                    <Button
                      id='submit-voucher-button'
                      variant='midnight'
                      color='midnight'
                      type='submit'>
                      {isLoadingApllyVoucher ? (
                        <CircularProgress
                          size={24}
                          color='inherit'
                        />
                      ) : (
                        <Translator transRef={`translation.Aplicar voucher`} />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          )}


          {state === 'payment' && total === 0 && (
            <Button
              variant='midnight'
              color='midnight'
              onClick={() => goFinishVoucher()}>
              <Translator transRef={`translation.Finalizar`} />
            </Button>
          )}

          {state === 'cards' && (
            <Button
              variant='contained'
              color='error'
              onClick={() => goPayment()}>
              <Translator transRef={`translation.Ir para pagamento`} />
            </Button>
          )}
        </Grid>
      </CardContent>
    </Paper>
  )
}

export default CardShopping
