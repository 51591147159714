import * as actions from "./actions";
import produce from "immer";

const initialState = {
  shoppingCarts: [],
};

export const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case actions.SET_SHOPPINGCARTS:
      state.shoppingCarts = action.payload.data;
      break;
    case actions.RESET_SHOPPINGCARTS:
      state.shoppingCarts = [];
      break;
    case actions.RESET:
      state.shoppingCarts = [];
      break;
    default:
      break;
  }
}, initialState);
