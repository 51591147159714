import { MutableRefObject, ComponentType, WheelEvent, useEffect, useRef, useMemo, useState } from 'react'
import { useWindowDimensions } from '@itarget/shared'
import { Box, Icon } from '@material-ui/core'
import { useStyles } from './styles'

const Scrollable = ({ itemWidth = 0, className, backgroundColor = { light: '#fff', dark: '#1C1C1C' }, children }) => {
  const [enableScroll, setEnableScroll] = useState(true)
  const { width: windowWidth } = useWindowDimensions()
  const containerRef = useRef(null)
  const ARROW_WIDTH = 48
  let itemsOffsetWidth = itemWidth * children.length
  const styles = useStyles({ backgroundColor, enableScroll })

  const handleWheel = (event) => {
    event.preventDefault()
    let container = containerRef.current
    if (!container) return
    event.deltaY < 0 ? handleArrowLeftClick() : handleArrowRightClick()
  }

  const handleWindowResize = () => {
    let container = containerRef.current
    if (!container) return
    let containerBiggerOrEqualContentWidth = container.offsetWidth >= itemsOffsetWidth + ARROW_WIDTH
    setEnableScroll(containerBiggerOrEqualContentWidth)
  }

  const handleArrowClick = (direction) => {
    let container = containerRef.current
    if (!container) return
    const scrollTo = { left: -itemWidth, right: itemWidth }
    container.scrollBy({ left: scrollTo[direction], behavior: 'smooth' })
  }

  const handleArrowLeftClick = () => handleArrowClick('left')
  const handleArrowRightClick = () => handleArrowClick('right')

  const handleStartDragging = (event) => {
    event.preventDefault()
    const container = containerRef.current
    if (!container) return
    container.style['scroll-snap-type'] = 'none'
    container.style.cursor = 'grabbing'
    container.lastMouseDownX = event.pageX
    container.mouseDown = true
  }

  const handleMove = (event) => {
    event.preventDefault()
    const container = containerRef.current
    if (!container.mouseDown) return
    const movementX = event.pageX - container.lastMouseDownX
    if (movementX > 0) {
      container.scrollLeft -= movementX
    } else {
      container.scrollLeft += Math.abs(movementX)
    }
    container.lastMouseDownX = event.pageX
  }

  const handleStopDragging = () => {
    const container = containerRef.current
    if (!container.mouseDown || !container) return
    container.style['scroll-snap-type'] = 'both mandatory'
    container.style.cursor = 'auto'
    container.mouseDown = false
  }

  useEffect(() => {
    let container = containerRef.current
    if (container) {
      container.addEventListener('wheel', handleWheel, { passive: false })
      container.addEventListener('mousedown', handleStartDragging, { passive: false })
      container.addEventListener('mousemove', handleMove, { passive: false })
      container.addEventListener('mouseup', handleStopDragging, { passive: false })
      container.addEventListener('mouseleave', handleStopDragging, { passive: false })
    }

    return () => {
      if (container) {
        container.removeEventListener('wheel', handleWheel)
        container.removeEventListener('mousedown', handleStartDragging)
        container.removeEventListener('mousemove', handleMove)
        container.removeEventListener('mouseup', handleStopDragging)
        container.removeEventListener('mouseleave', handleStopDragging)
      }
    }
  }, [handleWheel, containerRef.current])

  useEffect(handleWindowResize, [windowWidth])

  return (
    <Box
      ref={containerRef}
      className={`${className} ${styles.container}`}>
      <Box
        onClick={handleArrowLeftClick}
        className={`${styles.arrowContainer} ${styles.arrowLeft}`}>
        <Icon className={styles.arrow}>arrow_back_ios</Icon>
      </Box>
      {children}
      <Box
        onClick={handleArrowRightClick}
        className={`${styles.arrowContainer} ${styles.arrowRight}`}>
        <Icon className={styles.arrow}>arrow_forward_ios</Icon>
      </Box>
    </Box>
  )
}

export default Scrollable
