import CartButton from '../../../CartButton'
import UserMenu from '../../../UserMenu'
import LoginButton from '../../../LoginButton'

export const Trailing = ({ showMenu, user, styles, goTo, shoppingCarts, onLogout, statusAssociated, category, toggleMenu }) => {
  return (
    <>
      {user?.data ? (
        <>
          <div className={styles.trailingLogged}>
            <CartButton goTo={goTo} shoppingCarts={shoppingCarts} />
            <UserMenu user={user} onLogout={onLogout} goTo={goTo} statusAssociated={statusAssociated} category={category} />
          </div>
        </>
      ) : (
        <>
          <LoginButton backgroundColor='#2a51c7' />
        </>
      )}
    </>
  )
}
