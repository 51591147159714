import { Translator } from "@itarget/shared";
import { Grid, Typography, Icon, Button } from "@material-ui/core";
import "./style.scss";

const Quiz = (props) => {
    const { setOpenQuestionnaire } = props;
    return (
        <>
            <Grid className="grid-course">
                <Button
                    onClick={() => setOpenQuestionnaire()}
                    className="btn-quest"
                    endIcon={<Icon className="icon-list-alt">list_alt</Icon>}
                >
                    <Typography>
                        <Translator transRef={'translation.Questionario'} />
                    </Typography>
                </Button>
            </Grid>
        </>
    )
}

export default Quiz