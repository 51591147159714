import { Box, Typography, Icon } from '@material-ui/core'
import { Button, Translator, formatDate } from '@itarget/shared'
import { useItemStyles } from './styles'
import TypographyTooltip from '../TypographyTooltip'
import { useState } from 'react'
import Lecture from './Lecture'
import LectureItem from './LectureItem'

function groupSpeakersByVideoLink(speakersArray) {
  const groupedSpeakers = []
  const videoLinkMap = new Map()
  let noVideoLinkGroup = {
    speakerVideoLink: null,
    idaad: null,
    speakers: [],
  }

  groupedSpeakers.push(noVideoLinkGroup)

  for (const speaker of speakersArray) {
    const videoLink = speaker.speakerVideoLink

    if (videoLink) {
      if (!videoLinkMap.has(videoLink)) {
        const newGroup = {
          speakerVideoLink: videoLink,
          isRegistered: speaker.isRegistered,
          idaad: speaker.speakerPresentationKey,
          speakers: [],
        }
        groupedSpeakers.push(newGroup)
        videoLinkMap.set(videoLink, newGroup)
      }
      videoLinkMap.get(videoLink).speakers.push(speaker)
    } else {
      noVideoLinkGroup.speakers.push(speaker)
    }
  }

  if (noVideoLinkGroup.speakers.length === 0) {
    groupedSpeakers.shift()
  }

  return groupedSpeakers
}

const Item = ({ data }) => {
  const [displayLecture, setDisplayLecture] = useState(false)
  const styles = useItemStyles({ tagColor: data.tagColor })

  const onClickDisplayLecture = () => {
    setDisplayLecture((previousState) => !previousState)
  }

  let startAtTime = data.startAt.split(' ')[1]
  let time = formatDate.hour(startAtTime)
  let grouped = data.speakers && data.speakers.length > 0 ? groupSpeakersByVideoLink(data.speakers) : []
  let displayLectureButton = data.presentations.length > 0 || grouped.length > 0

  return (
    <Box className={styles.container}>
      <Box className={styles.itemContainer}>
        {time != '00:00' && (
          <Typography className={styles.time}>
            <Translator transRef={time} />
          </Typography>
        )}
        <Box className={styles.informationButtonContainer}>
          <Box className={styles.informationContainer}>
            <Box className={styles.topInformationContainer}>
              {data.type && (
                <TypographyTooltip
                  numberOfLines={1}
                  className={styles.type}>
                  {data.type}
                </TypographyTooltip>
              )}
              <TypographyTooltip
                numberOfLines={2}
                className={styles.theme}>
                {data.title}
              </TypographyTooltip>
            </Box>
            <Box className={styles.bottomInformationContainer}>
              {data.tag && (
                <TypographyTooltip
                  numberOfLines={2}
                  className={styles.tag}>
                  {data.tag}
                </TypographyTooltip>
              )}
              {data.room && (
                <Box className={styles.roomGroup}>
                  <Icon>location_on</Icon>
                  <TypographyTooltip
                    numberOfLines={1}
                    className={styles.room}>
                    {data.room}
                  </TypographyTooltip>
                </Box>
              )}
            </Box>
          </Box>
          {displayLectureButton && (
            <Button
              icon={'video_library'}
              onClick={onClickDisplayLecture}
              className={styles.button}>
              <Translator transRef={displayLecture ? 'Ocultar palestras' : 'Exibir palestras'} />
            </Button>
          )}
        </Box>
      </Box>
      {displayLecture && (
        <Lecture display={displayLecture}>
          {grouped.length > 0 &&
            grouped.map((data, index) => {
              return (
                <LectureItem
                  key={`${index}_${data.startAt}_${data.endAt}`}
                  defaultOpened={true}
                  data={data}
                />
              )
            })}
          {data.presentations.map((data, index, array) => {
            let positionInArray = index + 1
            let lastItem = array.length == positionInArray

            return (
              <LectureItem
                key={`${index}_${data.startAt}_${data.endAt}`}
                lastItem={lastItem}
                data={data}
              />
            )
          })}
        </Lecture>
      )}
    </Box>
  )
}

export default Item
