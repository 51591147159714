// import Header from "@itarget/react-page";

const DasboardView = ({ isLoadingValidateJwt }) => {
  return (
    <div>
      {isLoadingValidateJwt ? "-" : ""}
    </div>
  );
};

export default DasboardView;
