import { Grid, Icon, IconButton, makeStyles } from '@material-ui/core'

import LoginButton from '../../LoginButton'
import CartButton from '../../CartButton'
import UserMenu from '../../UserMenu'
import MenuC from '../../MenuC'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
  },
  iconcart: {
    width: '32px',
    height: '32px',
  },
  container: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
  },
  includesContent: {
    flexBasis: '83.333333%',
    maxWidth: '83.333333%',
    padding: '0.5rem 1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media (max-width: 950px)': {
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
  contentBackgroundImage: {
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#2193b1',
    backgroundImage:
      'url(http://iweb04.itarget.com.br/itarget.com.br/newclients/fbg-portal-2023/wp-content/uploads/2023/03/Grupo-125.png), url(http://iweb04.itarget.com.br/itarget.com.br/newclients/fbg-portal-2023/wp-content/uploads/2023/03/Grupo-132.png), url(http://iweb04.itarget.com.br/itarget.com.br/newclients/fbg-portal-2023/wp-content/uploads/2023/03/Grupo-133-1.png), linear-gradient(238deg, rgba(33,147,177,1) 0%, rgba(67,201,167,1) 100%)',
    backgroundRepeat: 'no-repeat, no-repeat, no-repeat, no-repeat',
    backgroundPosition: 'center left, bottom right, top right, center center',
  },
  logo: {
    maxWidth: '120px',
    maxHeight: '100%',
    display: 'flex',
  },
  glass: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    gap: '5px',
    padding: '5px',
    /* From https://css.glass */
    background: '#606060cc',
    borderRadius: '16px',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    backdropFilter: 'blur(3.9px)',
    webkitBackdropFilter: 'blur(3.9px)',
    border: '1px solid rgba(255, 255, 255, 0.26)',
    color: '#ffffff',

    '& .MuiIconButton-root, & .MuiListItemText-secondary': {
      color: '#ffffff',
    },
  },
  trailing: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(4),
  },
  leading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
  },
  menu: {
    height: '30px',
  },
  menuItem: {
    textTransform: 'uppercase',
  },
  icon: {
    color: 'invert(100%)',
  },
  menuButton: {
    '@media (min-width: 950px)': {
      display: 'none',
    },
  },
}))

const Header = ({
  showMenu,
  toggleMenu,
  logo,
  client,
  shoppingCarts,
  menuItemsC = [],
  isLoadingMenuData,
  user,
  goTo,
  onLogout,
  statusAssociated,
  category,
}) => {
  const [menu, setMenu] = useState([{ description: 'Home', url: '/' }])
  const [imageBackground, setImageBackground] = useState(null)
  const styles = useStyles()
  const settingCourse = useSelector((state) => state.setting.settingCommerce)

  useEffect(() => {
    try {
      if (settingCourse?.json_menu) setMenu(JSON.parse(settingCourse.json_menu))
      if (settingCourse.url_image_topo_background) setImageBackground(settingCourse.url_image_topo_background)
    } catch (e) {
    }
  }, [settingCourse])

  return (
    <header className={styles.header}>
      <Grid
        className={styles.container}
        container
        style={{ backgroundImage: `url(${imageBackground})` }}>
        <Grid className={styles.contentBackgroundImage}>
          <Grid
            item
            className={styles.includesContent}>
            <div className={styles.leading}>
              <img
                className={styles.logo}
                alt={client}
                src={logo}
              />
            </div>
            <div className={styles.trailing}>
              {user?.data ? (
                <>
                  <div className={styles.glass}>
                    <CartButton
                      goTo={goTo}
                      shoppingCarts={shoppingCarts}
                    />
                    <div>
                      <IconButton
                        onClick={toggleMenu}
                        className={`view-list-red ${styles.menuButton}`}>
                        <Icon className={styles.icon}>{showMenu == 'show' ? 'close' : 'view_list'}</Icon>
                      </IconButton>
                    </div>
                    <UserMenu
                      user={user}
                      onLogout={onLogout}
                      goTo={goTo}
                      statusAssociated={statusAssociated}
                      category={category}
                    />
                  </div>
                </>
              ) : (
                <>
                  <LoginButton backgroundColor='#2a51c7' />
                  <div className={styles.menuButton}>
                    <IconButton onClick={toggleMenu}>
                      <Icon className={styles.icon}>{showMenu == 'show' ? 'close' : 'view_list'}</Icon>
                    </IconButton>
                  </div>
                </>
              )}
            </div>
          </Grid>
        </Grid>
        <MenuC
          items={menuItemsC}
          isLoadingMenuData={isLoadingMenuData}
          classes={{ root: styles.menu, itemRoot: styles.menuItemRoot }}
          showMenu={showMenu}
        />
      </Grid>
    </header>
  )
}

export default Header
