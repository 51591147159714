import * as actions from "./actions";
import produce from "immer";

const initialState = {
  lang: "pt-br",
  translation: {},
};

export const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case actions.SET_LANG:
      state.lang = action.payload.lang;
      break;
    case actions.SET_TRANSLATION:
      state.translation = action.payload.translation;
      break;
    default:
      break;
  }
}, initialState);
