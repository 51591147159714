import { Translator } from '@itarget/shared'

export const useCounterTranslation = () => ({
  quantity: Translator({ transRef: 'translation.Quantidade:' }).props.children,
})

export const useRegisteredTranslation = () => ({
  registered: Translator({ transRef: 'translation.Inscrito!' }).props.children,
  redirectToRegistered: Translator({ transRef: 'translation.Ver minhas inscrições' }).props.children,
  messageRegistered: Translator({ transRef: 'translation.Você está inscrito nesta atividade.' }).props.children,
})

export const useCourtesyTranslation = () => ({
  confirm: Translator({ transRef: 'translation.Confirmar participação' }).props.children,
  courtesy: Translator({ transRef: 'translation.Cortesia' }).props.children,
  messageCourtesy: Translator({
    transRef: 'translation.Esta atividade é gratuita para você.',
  }).props.children,
  messageConfirm: Translator({ transRef: 'translation.Para acessar basta confirmar sua participação.' }).props.children,
})

export const useSoldOutTranslation = () => ({
  waitingList: Translator({ transRef: 'translation.Entrar na lista de espera' }).props.children,
  soldOut: Translator({ transRef: 'translation.Vagas encerradas' }).props.children,
  messageSoldOut: Translator({
    transRef: 'translation.Não há mais vagas para esta atividade.',
  }).props.children,
  messageWaitingList: Translator({
    transRef: 'translation.Clique abaixo caso desejar entrar para a lista de espera.',
  }).props.children,
})

export const usePendingTranslation = () => ({
  pending: Translator({ transRef: 'translation.Pendente de pagamento' }).props.children,
  redirectToRegistered: Translator({ transRef: 'translation.Ver minhas inscrições' }).props.children,
})

export const useShoppingCartTranslation = () => ({
  added: Translator({ transRef: 'translation.Adicionado ao carrinho' }).props.children,
  redirectToShoppingCart: Translator({ transRef: 'translation.Ir para carrinho' }).props.children,
})

export const useAvailableTranslation = () => ({
  subscribe: Translator({ transRef: 'translation.Inscreva-se agora' }).props.children,
  addCart: Translator({ transRef: 'translation.Adicionar ao carrinho' }).props.children,
})

export const useWatchTranslation = () => ({
  watchStream: Translator({ transRef: 'translation.Assistir ao vivo' }).props.children,
  watchRecorded: Translator({ transRef: 'translation.Assistir aulas' }).props.children,
})

export const useAmountTranslation = () => ({
  discount: Translator({ transRef: 'translation.Desconto' }).props.children,
  unavailable: Translator({ transRef: 'translation.Valor não disponível' }).props.children,
})

export const useReturnTranslation = () => ({
  offers: Translator({ transRef: 'translation.Voltar para lista de ofertas' }).props.children,
})

export const useUnavailableTranslation = () => ({
  unavailable: Translator({ transRef: 'translation.Este recurso não está disponível para inscrição neste momento.' }).props.children,
})

export const useLoadingTranslation = () => ({
  loading: Translator({ transRef: 'translation.Carregando os recursos.' }).props.children,
})

export const useCategoryTranslation = () => ({
  confirmCategory: Translator({ transRef: 'translation.Confirmar categoria' }).props.children,
  selectLabel: Translator({ transRef: 'translation.Selecione sua categoria' }).props.children,
  defaultValue: Translator({ transRef: 'translation.Selecione' }).props.children,
  noCategoryMessage: Translator({
    transRef: 'translation.Você ainda não tem uma categoria para o contexto desta atividade. Selecione uma na lista.',
  }).props.children,
  organizingCommitteeMessage: Translator({
    transRef: 'translation.A categoria selecionada estará sujeita à aprovação do comitê organizador.',
  }).props.children,
})

export const useDetailTranslation = () => ({
  defaultValue: Translator({ transRef: 'translation.Não definido' }).props.children,
  hourlyLoad: Translator({ transRef: 'translation.Carga horária' }).props.children,
  dateStart: Translator({ transRef: 'translation.Data início' }).props.children,
  dateEnd: Translator({ transRef: 'translation.Data fim' }).props.children,
  location: Translator({ transRef: 'translation.Local' }).props.children,
  room: Translator({ transRef: 'translation.Sala' }).props.children,
})

export const useComboAnnuityTranslation = () => ({
  discount: Translator({ transRef: 'translation.Pagar anuidades pendentes e receber desconto nesta atividade.' }).props.children,
  discountTitle: Translator({ transRef: 'translation.Lista de anuidades em aberto' }).props.children,
})

export const useTotalTranslation = () => ({
  label: Translator({ transRef: 'translation.Total da compra' }).props.children,
})

export const useReferenceTranslation = () => ({
  category: Translator({ transRef: 'translation.Valor referente a tabela:' }).props.children,
  situation: Translator({ transRef: 'translation.na situação de:' }).props.children,
})
