import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useFetchApi,
  Config,
  subscriptionRequest,
  useRedirect,
} from "@itarget/shared";

import {
  offers,
  toast,
  payment as paymentRedux,
  subscription as subscriptionRedux,
} from "../../redux";
import { payment } from "../../redux";

import { CircularProgress } from "@material-ui/core";
import DetailStoreView from "./views/DetailStoreView";

const DetailStoreContainer = () => {
  const dispatch = useDispatch();
  const { id, cc } = useParams();
  const { goTo } = useRedirect();
  const [value, setValue] = useState(0);
  const [tabs, setTabs] = useState([]);
  const [openShoppingCart, setOpenShoppingCart] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [state, setState] = useState("detail");
  const [offer, setOffer] = useState();
  const [incList, setIncList] = useState();
  const [incIds, setIncIds] = useState("");
  const [vlTotal, setVlTotal] = useState();
  const [showWrongCard, setShowWrongCard] = useState(false);
  const [resetStatePayment, setResetStatePayment] = useState(false);
  const [subscriptionState, setSubscriptionState] = useState();
  const [openModalReceipt, setOpenModalReceipt] = useState(false);
  const [dataPersonCenterCostApi, setDataPersonCenterCostApi] = useState({});
  const [amountProduct, setAmountProduct] = useState(1);

  const user = useSelector((state) => state.auth.user);
  const center_cost_id = useSelector((state) => state.auth.cc);
  const isAutenticated = useSelector((state) => state.auth.isAutenticated);
  const selectedLang = useSelector((state) => state.language.lang)
  const shoppingCarts = useSelector((state) => state.offers.shoppingCarts);
  const total = useSelector((state) => state.payment.total);
  const paymentType = useSelector((state) => state.payment.type);

  const { data: annuities, fetchData: fetchAnnuities } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/subscription/${user?.data?.id}/unified-registration`
  );

  const {
    data: detailRequest,
    fetchData: fetchDetailRequest,
    isLoading: isLoadingDetail,
  } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/activity-payment/${id}/detailing?centro_custo_id=${center_cost_id}${
        user && user.data ? "&pessoa_id=" + user.data.id : ""
      }`
  );

  const { data: accountReceive, fetchData: fetchAccountReceive } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/finance/account-receive/` + incIds + "/receipt"
  );

  const { data: inscricoesApi, fetchData: fetchInscricoes, isLoading: isLoadingInscricoes } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/subscription/pending?pessoa_id=${
        user.data && user.data.id
      }&origem_inscricao=5`
  );

  //comprovante categoria

  const {
    data: categoryReceiptRequest,
    fetchData: fetchCategoryReceiptRequest,
  } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/center-cost-category/${user?.data?.categoria_centro_custo_id}`
  );

  const {
    data: dataPersonCenterCost,
    isLoading: isLoadingDataPersonCenterCost,
    error: erroDataPersonCenterCost,
    fetchData: fetchDataPersonCenterCost,
  } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/center-cost-person?pessoa_id=${user?.data?.id}&centro_custo_id=${center_cost_id}`
  );
  const { data: idReceipt, fetchData: fetchIdReceipt } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/attachments?table=pessoas_centros_custos&tabela_id=${dataPersonCenterCostApi?.id}&tipo_documento_id[]=200`
  );

  //fim comprovante categoria

  //comprovante
  useEffect(() => {
    if (user?.data) {
      fetchDataPersonCenterCost();
      fetchCategoryReceiptRequest();
    }
  }, []);
  useEffect(() => {
    if (dataPersonCenterCost) {
      setDataPersonCenterCostApi(dataPersonCenterCost.data[0]);
    }
  }, [dataPersonCenterCost]);

  useEffect(() => {
    if (dataPersonCenterCostApi?.id) {
      fetchIdReceipt();
    }
  }, [dataPersonCenterCostApi, openModalReceipt]);
  //fim comprovante

  const onValidSubscription = () => {
    dispatch(payment.actions.setTotal(parseFloat(offer.valor)));
  };
  const { onSubscribe, subscription } = subscriptionRequest({
    user,
    selectedCourse: detailRequest?.data.detailing[0],
    onValid: onValidSubscription,
    subscriptionRedux: subscriptionRedux,
    amount: amountProduct,
    toast: toast,
  });

  useEffect(() => {
    if (isAutenticated) {
      handleCloseLogin();
    }
  }, [isAutenticated]);

  useEffect(() => {
    if (detailRequest && detailRequest.data.detailing.length === 0) {
      goTo("offers");
    }

    if (offer) {
      switch (offer.status_inscricao) {
        case 2:
          if (
            offer.qtde_aulas_video === 0 &&
            offer?.disponivel_transmissao === "N"
          ) {
            setState("toMyactives");
          } else {
            setState("toWatch");
          }
          break;

        case 1:
          setState("pending");
          break;

        default:
          break;
      }
    }
  }, [offer]);

  useEffect(() => {
    resetStates();
    fetchDetailRequest();
  }, [id, fetchDetailRequest]);

  useEffect(() => {
    if (detailRequest) {
      setTabs(detailRequest.data);
      setOffer(detailRequest.data.detailing[0]);
    }
  }, [detailRequest]);

  const resetStates = () => {
    setState("detail");
    setOpenShoppingCart(false);
    setValue(0);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (user?.data?.id) {
      fetchAnnuities();
    }
  }, [user?.data?.id]);

  useEffect(() => {
    if (resetStatePayment) {
      setResetStatePayment(false);
    }
  }, [resetStatePayment]);

  useEffect(() => {
    if (subscription && state === "toShoppingCart") {
      dispatch(offers.actions.setShoppingCarts([...shoppingCarts, offer]));
      setOpenShoppingCart(true);
      setTimeout(() => {
        setOpenShoppingCart(false);
      }, 4000);
    }
    if (subscription && state === "toPayment") {
      setState("payment");
    }
    if (subscription && (state === "toMyactives" || state === "toWatch")) {
      setOpenShoppingCart(true);
      setTimeout(() => {
        setOpenShoppingCart(false);
      }, 4000);
    }
  }, [state]);

  useEffect(() => {
    if (subscription) {
      fetchInscricoes();
    }
  }, [subscription]);

  useEffect(() => {
    if (inscricoesApi) {
      const sub = inscricoesApi.data.find(
        (r) => r.agenda_atividade_id === offer.aga_id
      );
      const subWithAnnuity = inscricoesApi.data.filter(
        (r) =>
          r.agenda_atividade_id === offer.aga_id ||
          r.categoria_tipo_atividade === 1
      );

      const newSubscriptionState =
        state === "inPaymentWithAnnuity" ? subWithAnnuity : [sub];

      setSubscriptionState(newSubscriptionState);
      let ids = sub?.conta_receber_id;
      setIncIds(ids?.toString());
    }
  }, [inscricoesApi]);

  useEffect(() => {
    if (accountReceive) {
      setIncList(accountReceive.data);
    }
  }, [accountReceive]);

  useEffect(() => {
    if (incList && incList.length > 0) {
      let vl = 0;
      incList.map((e) => {
        if (e.r_a_importancia_de) {
          vl = vl + parseFloat(e.r_a_importancia_de);
        }
      });
      setVlTotal(vl);
    }
  }, [incList]);

  const goFinish = () => {
    setState("finish");
    if (incIds && incIds.length > 0) {
      fetchAccountReceive();
    }
  };

  const addShoppingCart = async () => {
    if (!user.data) {
      setOpenLogin(true);
      return;
    }

    if (!(await validCategoryReceipt())) {
      return;
    }

    await onSubscribe();

    setState("toShoppingCart");
  };

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  const onInvalidCardPayment = () => {
    setShowWrongCard(true);
  };

  const changePayment = () => {
    setResetStatePayment(true);
    setShowWrongCard(false);
  };

  const validCategoryReceipt = async () => {
    if (categoryReceiptRequest?.exige_comp_cat_evento === "S") {
      if (dataPersonCenterCostApi?.id && idReceipt?.data?.length === 0) {
        setOpenModalReceipt(true);
        return false;
      }
    }
    if (categoryReceiptRequest?.exige_comp_cat_evento_insc === "S") {
      if (
        dataPersonCenterCostApi?.id &&
        (idReceipt?.data?.length === 0 ||
          [0, 2].includes(
            dataPersonCenterCostApi?.status_comprovante_categoria
          ))
      ) {
        setOpenModalReceipt(true);
        return false;
      }
    }
    return true;
  };

  const redirectSubscribe = async (state) => {
    if (!user.data) {
      setOpenLogin(true);
      return;
    }

    if (!(await validCategoryReceipt())) {
      return;
    }

    await onSubscribe();

    setState(state);
  };

  const selectOffer = async (activity) => {
    goTo(`offers/${activity.aga_id}`);
  };
  const selectProduct = async (productId) => {
    goTo(`offers/store/${productId.aga_id}`);
  };

  const amountProductChange = (value) => {
    setAmountProduct(value);
  };

  return (
    <>
      {isLoadingDetail ? (
        <CircularProgress />
      ) : (
        <DetailStoreView
          status_comprovante_categoria={
            dataPersonCenterCostApi?.status_comprovante_categoria
          }
          comp_recusa={dataPersonCenterCostApi?.comp_recusa}
          payment={payment}
          openModalReceipt={openModalReceipt}
          setOpenModalReceipt={setOpenModalReceipt}
          vlTotal={vlTotal}
          incList={incList}
          center_cost_id={center_cost_id}
          cc={center_cost_id}
          id={id}
          paymentRedux={paymentRedux}
          toast={toast}
          total={total}
          paymentType={paymentType}
          user={user}
          openLogin={openLogin}
          openShoppingCart={openShoppingCart}
          offer={offer}
          state={state}
          setState={setState}
          tabs={tabs}
          value={value}
          subscriptionState={subscriptionState}
          setSubscriptionState={setSubscriptionState}
          resetStatePayment={resetStatePayment}
          showWrongCard={showWrongCard}
          annuity={annuities}
          goFinish={goFinish}
          handleCloseLogin={handleCloseLogin}
          handleChange={handleChange}
          addShoppingCart={addShoppingCart}
          onInvalidCardPayment={onInvalidCardPayment}
          setShowWrongCard={setShowWrongCard}
          changePayment={changePayment}
          isLoadingInscricoes={isLoadingInscricoes}
          redirectSubscribe={redirectSubscribe}
          subscription={subscriptionRedux}
          selectOffer={selectOffer}
          selectProduct={selectProduct}
          amountProduct={amountProduct}
          amountProductChange={amountProductChange}
        />
      )}
    </>
  );
};

export default DetailStoreContainer;
