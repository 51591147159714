import { Grid, Typography } from "@material-ui/core";
import { Translator } from "@itarget/shared";
import "./style.scss";

const ProductSubtitle = ({ text, width = "15%" }) => {
  return (
    <Grid item xs={12}>
      <Typography className="subtitle-text" variant="h6" style={{wordWrap: 'anywhere'}}>
        <Translator transRef={`translation.${text}`} />
      </Typography>
      <div className="subtitle-divider" style={{ width: width }} />
    </Grid>
  );
};

export default ProductSubtitle;
