import React from 'react'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import Typography from '@material-ui/core/Typography'
import { useParams } from 'react-router-dom'
import { Avatar, Grid, IconButton, Icon, Tooltip } from '@material-ui/core'
import { useRedirect } from '@itarget/shared'
import { Translator } from '@itarget/shared'
import './style.scss'

const SchedulingTimelineEad = ({ activities, isMainActivity, user, participant, selectStep, subscriptions }) => {
  const { goTo } = useRedirect()
  const { activity_schedule_id } = useParams()
  let mainActivityID = Number(activity_schedule_id)
  const translation = {
    subscribedTooltip: Translator({ transRef: 'translation.Inscrito' }),
    pendingSubscribe: Translator({ transRef: 'translation.Pendente' }),
    onDemand: Translator({ transRef: 'translation.on Demand' }),
    buyActivity: Translator({ transRef: 'translation.Inscreva-se!' }),
  }
  const subscription = subscriptions.find((sub) => sub.agenda_atividade_id === activities.agenda_atividade_id)?.status_inscricao
  const mainSubscription = subscriptions.find((sub) => sub.agenda_atividade_id === mainActivityID)?.status_inscricao
  const rules = {
    onlyProgram: activities.gera_inscricao_trabalho == 2,
    hasMainSubscription: mainSubscription == 2,
    isSubscribed: subscription == 2,
    isPending: subscription == 1,
  }
  return (
    <Grid
      className='timeline-grid-container'
      container
      direction='column'>
      <Timeline
        key={activities.uuid}
        className='timeline'
        align='left'>
        <TimelineItem className='timeline-item'>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineOppositeContent>
            <Typography
              component={'h1'}
              className='scheduling-title'
              style={{ textAlign: 'left' }}>
              {activities.titulo}
            </Typography>
            <Grid
              className='lecturer-container'
              alignItems='center'>
              {activities.sub_tema.map((subtopic) => {
                return subtopic.participante.map((lecturer) => {
                  const hasVideo = subtopic.exibe_video_palestra !== 'N'
                  const lecturerInfo = participant[lecturer.participante]
                  let lecturerName = lecturerInfo?.nome_cracha ? lecturerInfo?.nome_cracha : lecturerInfo?.nome
                  return (
                    <>
                      <Grid
                        className='lecturer-details'
                        container>
                        <Grid>
                          <Grid container>
                            {subtopic.titulo && (
                              <Grid
                                container
                                className='schedule-subtheme'>
                                <Typography>
                                  {subtopic && subtopic.titulo.length > 100 ? `${subtopic.titulo.slice(0, 100)}...` : subtopic.titulo}
                                </Typography>
                              </Grid>
                            )}
                            <Avatar
                              className='lecturer-avatar'
                              alt={lecturerName}
                              src={lecturerInfo?.foto}
                              style={{ background: '#c0c0c0' }}
                            />
                            <Grid>
                              <Typography
                                className='lecturer'
                                style={{ textAlign: 'left' }}>
                                {lecturerName}
                                {lecturerInfo?.estado && ` - (${lecturerInfo?.estado})`}
                              </Typography>
                              {lecturer?.funcao_descricao && (
                                <Typography
                                  className='lecturer-description'
                                  style={{ textAlign: 'left' }}>
                                  {lecturer.funcao_descricao}
                                </Typography>
                              )}
                            </Grid>
                            <Grid></Grid>
                            {user.data ? (
                              <Grid>
                                {activities && (
                                  <>
                                    {(rules.isSubscribed || (rules.onlyProgram && rules.hasMainSubscription)) && (
                                      <>
                                        {hasVideo && (
                                          <Tooltip title={translation.subscribedTooltip}>
                                            <IconButton
                                              edge='end'
                                              size='large'
                                              className='btn-play'>
                                              <Icon
                                                className='play-icon'
                                                fontSize='large'>
                                                verified
                                              </Icon>
                                            </IconButton>
                                          </Tooltip>
                                        )}

                                        {hasVideo && (
                                          <Tooltip title={translation.onDemand}>
                                            <IconButton
                                              onClick={() => selectStep(subtopic, lecturerInfo.nome)}
                                              edge='end'
                                              size='large'
                                              className='btn-play'>
                                              <Icon
                                                className='play-icon'
                                                fontSize='large'>
                                                ondemand_video
                                              </Icon>
                                            </IconButton>
                                          </Tooltip>
                                        )}
                                      </>
                                    )}
                                    {rules.isPending && (
                                      <Tooltip title={translation.pendingSubscribe}>
                                        <IconButton
                                          onClick={() => goTo(`shopping-cart`)}
                                          edge='end'
                                          size='large'
                                          className='btn-play'>
                                          <Icon
                                            className='play-icon'
                                            fontSize='large'>
                                            pending
                                          </Icon>
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                    {!rules.onlyProgram && !rules.isSubscribed && hasVideo && !isMainActivity && (
                                      <Tooltip title={translation.buyActivity}>
                                        <IconButton
                                          onClick={() => goTo(`offers/${activities.agenda_atividade_id}`)}
                                          edge='end'
                                          size='large'
                                          className='btn-play'>
                                          <Icon
                                            className='play-icon'
                                            fontSize='large'>
                                            add_shopping_cart
                                          </Icon>
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </>
                                )}
                              </Grid>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )
                })
              })}
            </Grid>
          </TimelineOppositeContent>
        </TimelineItem>
      </Timeline>
    </Grid>
  )
}

export default SchedulingTimelineEad
