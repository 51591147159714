import styled from "styled-components";

export const StylesAmib = styled.div`
  @font-face {
    font-family: "Gotham";
    src: url("fonts/Gotham-Bold.woff2") format("woff2"), url("fonts/Gotham-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  ul.menu,
  .menu li,
  .menu a {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    text-transform: uppercase;
  }
  .toggle,
  [id^="drop"] {
    display: none;
  }

  .current-menu-item .nav-link {
    background-color: #f06363;
    font-weight: bold;
  }
  ul.menu ul {
    position: absolute;
    display: none;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    border-bottom: 3px solid rgba(0, 0, 0, 0.15);
  }

  span.set:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
  }

  /* Configurações Nivel 1 */
  ul.menu {
    height: 28px;
    width: 100%;
    max-width: 99.74%;
    display: inline-flex;
    justify-content: center;
    font-size: 12px;
    padding: 0 2px;
  }

  .menu li {
    float: left;
    width: auto;
    position: relative;
  }

  .menu li a {
    display: block;
    padding: 5px 10px;
    line-height: 1.6;
    white-space: normal;
    font-weight: 750;
    float: left;
    clear: both;
    color: #fff;
    font-family: "Gotham", Arial !important;
    transition: all 0.1s linear;
  }
  .menu li a:hover {
  }

  .nav-menu {
    width: 100%;
  }

  .nav-social {
    display: none;
  }

  .nav-search {
    display: none;
  }

  /* Configurações Nivel 2*/
  .menu li.show > ul.submenu-1 {
    display: block;
    top: 28px;
    left: 0;
    padding: 5px;
    min-width: 8.5rem;
  }
  // .menu li:hover>ul.submenu-1 {
  //   display: block;
  //   top: 28px;
  //   left: 0;
  //   padding: 5px;
  //   min-width: 8.5rem;
  // }

  .menu ul.submenu-1 a {
    min-width: 8.5rem;
    padding: 0.25rem 0.9rem;
    border: 0;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    font-size: 1.2em;
    text-transform: none;
  }
  .menu ul.submenu-1 a:hover {
    color: #444;
    background: #f7f7f7;
  }

  /* Configurações nivel 2*/
  .menu ul.submenu-1 li.showSub > ul.submenu-2 {
    display: block;
    top: 0;
    left: 170px;
    padding: 5px;
    border-radius: 0 5px 5px 5px;
    min-width: 9.5rem;
  }

  .menu ul.submenu-2 a {
    min-width: 9.5rem;
    padding: 0.25rem 0.9rem;
    border: 0;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    font-size: 1.2em;
    text-transform: none;
  }
  .menu ul.submenu-2 a:hover {
    color: #444;
    background: #f7f7f7;
  }

  /* Configurações nivel 3*/
  .menu li a:hover > ul.submenu-3 {
    display: block;
    top: 0;
    left: 100px;
    padding: 5px;
    border-radius: 0 5px 5px 5px;
  }

  .menu ul.submenu-3 a {
    padding: 0 20px;
    border-radius: 5px;
  }

  /*Configurações de cores*/
  /*Nivel 1*/
  .menu {
    background: rgb(42, 116, 153);
  }
  .menu li:first-child {
    border-left: 1px solid #fff;
  }

  .menu a {
    color: #fff;
    border-right: 1px solid #fff;
  }

  .menu li:hover > a {
    background: rgb(42, 116, 153);
    color: #fff;
  }

  /*nivel 2*/
  .submenu-1 {
    background: rgb(42, 116, 153);
  }
  .submenu-1 a {
    color: #fff;
  }
  .submenu-1 li:hover > a {
    background: #fff;
    color: rgb(42, 116, 153);
  }

  /*nivel 3*/
  .menu ul.submenu-1 li.showSub > ul.submenu-2 {
    background: #f6f6f6;
  }
  .submenu-2 a {
    color: #fff;
  }
  .submenu-2 li:hover > a {
    background: #fff;
  }

  /*nivel 3*/
  .submenu-3 {
    background: rgb(42, 116, 153);
  }
  .submenu-3 a {
    color: #fff;
  }
  .submenu-3 li:hover > a {
    background: #000;
  }

  .MuiSvgIcon-root {
    font-size: 17px !important;
  }

  @media all and (max-width: 800px) {
    nav {
      display: none;
    }

    #menu-desktop.show {
      top: 20px;
    }

    ul.menu {
      height: auto;
    }

    ul.menu li:first-child a.li-link {
      background-color: rgb(240, 99, 99);
    }

    .show {
      position: relative;
      display: block;
    }

    .show .menu {
      display: flex;
      text-align: center;
      flex-direction: column;
      padding: 0 !important;
      width: 101% !important;
    }

    .show ul.menu ul {
      padding: 0 !important;
    }

    .show .menu li {
      display: block;
      width: 100%;
      background: #2a7499;
      border-bottom: 1px solid #226789;
      position: relative;
    }

    .show .menu a {
      border-right: none;
    }

    .show .menu li.show a.li-link::after {
      content: "-";
      font-size: 16px;
      float: right;
      padding-right: 20px;
    }

    .show .menu a.parent.li-link::after {
      content: "+";
      font-size: 16px;
      float: right;
      padding-right: 20px;
    }

    .show .menu li.show ul li a::after {
      content: inherit;
    }

    .show .menu li.show > ul.submenu-1 {
      position: absolute;
      z-index: 30;
      top: 40px !important;
      left: 0 !important;
      padding: 0 !important;
      border-radius: inherit;
      border: none;
      width: 100%;
    }

    .show .menu li.show > ul.submenu-1 > li > ul.submenu-2 {
      position: absolute;
      z-index: 30;
      top: 40px !important;
      left: 0;
      padding: 0 !important;
      border-radius: inherit;
      border: none;
      width: 100%;
    }

    .show .menu li.show > ul.submenu-1 > li {
      background: #fff;
      display: inline-block !important;
      width: 100%;
    }

    .show .menu li.show > ul.submenu-1 > li > ul.submenu-2 > li {
      background: #fff;
      display: inline-block !important;
      width: 100%;
    }

    ul.menu li.show > ul.submenu-1 > li > a > span.set::after {
      content: "+";
      color: var(--e-global-text-color);
      width: 20px;
      height: auto;
      margin: 0;
      border: 0;
    }

    ul.menu li.show > ul.submenu-1 > li.showSub > a > span.set::after {
      content: "-";
      color: var(--e-global-text-color);
      margin: 0;
      border: 0;
    }

    .show .menu li a {
      display: inline-block;
      overflow: hidden;
      float: none !important;
      padding: 7px 5px !important;
      width: inherit;
    }

    .show .menu li a:hover {
      color: #2b2b2b !important;
    }

    .show .menu li:first-child {
      border-left: none !important;
    }

    /* FORM NOVO CADASTRO */

    .login-container .MuiTabs-flexContainer {
      display: inherit;
      text-align: center;
    }

    .login-container .MuiTabs-scroller {
      white-space: normal !important;
    }

    /* CARROSSEL CART */

    .carouselCards-container .arrow {
      font-size: 2.4rem;
    }

    .carouselCards-container .carouselCards-inner .card-grid {
      margin: 0;
    }

    .carouselCards-inner .MuiIcon-root {
      flex-shrink: inherit;
    }

    /* MUITABS - NOVO CADASTRO */

    .login-container .PrivateTabIndicator-colorSecondary-3 {
      background-color: inherit;
    }

    .login-container .MuiTab-textColorInherit.Mui-selected {
      opacity: 1;
      border-bottom: 2px solid #f50057 !important;
    }

    .login-container .MuiTabs-indicator {
      display: none;
    }

    .MuiTabs-scroller {
      position: inherit !important;
    }
  }
`;
