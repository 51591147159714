import { Grid } from "@material-ui/core"
import Layout from "../../../components/Layout";
import { Container, Typography, Divider } from "@material-ui/core";
import { Translator } from "@itarget/shared";
const MeView = ({
    Form,
    toast,
    user,
    centerCostId, 
    reRegister
}) => {
    return (
        <Layout>
            <Container className="me-header title-page">
                <Typography variant="h4">
                    <Translator transRef="translation.MEUS DADOS" />
                </Typography>
            </Container>
            <Divider />
            <Container className="me-body">
                {reRegister?.texto_explicativo && [(
                    <Grid className="explication-text">
                        <Typography variant="p">
                            {reRegister?.texto_explicativo}
                        </Typography>
                    </Grid>
                    )]
                }
                <Form
                    toast={toast}
                    user={user}
                    centro_custo_id={centerCostId}
                    reRegister={reRegister}
                    tipo_interface_id={124}
                    description={true}
                />
            </Container>
        </Layout>
    );
};

export default MeView;
