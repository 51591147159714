import { Grid, CardMedia } from "@material-ui/core"
import { useState, useEffect, useCallback } from "react"
import "./style.scss"

const CardOffers = ({ card, onClick, width }) => {
  const [dimensions, setDimensions] = useState(document.body.clientWidth)

  useEffect(() => {
    window.addEventListener("resize", () => {
      setDimensions(document.body.clientWidth)
    })
  }, [])

  const setHeaderImagem = useCallback(() => {
    if ((parseInt(dimensions) > 0) & (parseInt(dimensions) < 600)) {
      return card?.imagem_lista
    }

    return card?.imagem_detalhe
  }, [dimensions, card?.imagem_lista, card?.imagem_detalhe])

  useEffect(() => {
    setHeaderImagem()
  }, [dimensions, setHeaderImagem])

  if (!setHeaderImagem()) return null

  return (
    <Grid style={width && { width: width }}>
      <CardMedia
        className={"cardOffers-container"}
        image={setHeaderImagem()}
        onClick={() => onClick && onClick(card.aga_id && card.aga_id)}
        title={card && card.desc_atv}
      >
        <div></div>
      </CardMedia>
    </Grid>
  )
}

export default CardOffers
