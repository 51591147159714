import { Link } from "react-router-dom";
import { Translator } from "@itarget/shared";
import "./style.scss";

const LinkComponent = ({ url, description, color }) => {
  let type = url[0] === "/" ? "internal" : "external";
  return (
    <>
      {type === "internal" ? (
        <Link to={url} style={{ color, textDecoration: "none", fontWeight: 400 }}>
          <Translator transRef={`translation.${description}`} />
        </Link>
      ) : (
        <a style={{ color, textDecoration: "none", fontWeight: 400 }} href={url}>
          {description}
        </a>
      )}
    </>
  );
};

export default LinkComponent;
