import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import SocialIcons from './SocialIcons'
import HomeIcon from '@material-ui/icons/Home'
import { Config } from '@itarget/shared'

export const StylesMobile = styled.div`
  @import url(https://fonts.googleapis.com/css?family=Open+Sans);
  @import url(https://fonts.googleapis.com/css?family=Bree+Serif);

  nav {
    display: none;
    overflow: overlay;
    max-height: 400px;
  }

  h3 a {
    color: #fff;
  }

  a {
    color: #fff;
    text-transform: uppercase;
  }
  .toggle,
  [id^='drop'] {
    display: none;
  }

  /* Giving a background-color to the nav container. */
  nav {
    margin: 0;
    padding: 0;
    background-color: #21759b;
  }
  nav:after {
    content: '';
    display: table;
    clear: both;
  }
  /* Removing padding, margin and "list-style" from the "ul",
  * and adding "position:reltive" */
  nav ul {
    float: right;
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
  }

  /* Positioning the navigation items inline */
  nav ul li {
    margin: 0px;
    display: inline-block;
    float: left;
    background-color: #21759b;
  }

  /* Styling the links */
  nav a {
    display: block;
    padding: 14px 20px;
    color: #fff;
    font-size: 17px;
    text-decoration: none;
  }

  nav ul li ul li:hover {
    background: #21759b;
  }

  /* Background color change on Hover */
  nav a:hover {
    background-color: #21759b;
  }
  nav ul ul {
    display: none;
    position: absolute;
    top: 60px;
  }

  /* Display Dropdowns on Hover */
  nav ul li:hover > ul {
    display: inherit;
  }

  /* Fisrt Tier Dropdown */
  nav ul ul li {
    width: 170px;
    float: none;
    display: list-item;
    position: relative;
  }

  nav ul ul ul li {
    position: relative;
    top: -60px;
    left: 170px;
  }

  li > a:after {
    content: ' +';
  }
  li > a:only-child:after {
    content: '';
  }

  .nav-social {
    display: none;
  }

  .nav-search {
    display: none;
  }

  /* Media Queries
  --------------------------------------------- */

  @media all and (max-width: 950px) {
    nav {
      display: none;
      margin: 0;
    }
    /* Hide the navigation menu by default */
    /* Also hide the  */
    .toggle + a,
    .menu {
      display: none;
    }

    /* Stylinf the toggle lable */
    .toggle {
      display: block;
      background-color: #21759b;
      padding: 14px 20px;
      color: #fff;
      font-size: 17px;
      text-decoration: none;
      border: none;
    }

    .toggle:hover {
      background-color: #21759b;
    }

    /* Display Dropdown when clicked on Parent Lable */
    [id^='drop']:checked + ul {
      display: block;
    }

    /* Change menu item's width to 100% */
    nav ul li {
      display: block;
      width: 100%;
    }

    nav ul ul .toggle,
    nav ul ul a {
      padding: 0 40px;
    }

    nav ul ul ul a {
      padding: 0 80px;
    }

    nav a:hover,
    nav ul ul ul a {
      background-color: #044286;
    }

    nav ul li ul li .toggle,
    nav ul ul a,
    nav ul ul ul a {
      padding: 8px 20px;
      color: #fff;
      font-size: 17px;
    }

    nav ul li ul li .toggle,
    nav ul ul a {
      background-color: #fff;
      color: #333;
    }

    /* Hide Dropdowns by Default */
    nav ul ul {
      float: none;
      position: static;
      color: #ffffff;
      /* has to be the same number as the "line-height" of "nav a" */
    }

    /* Hide menus on hover */
    nav ul ul li:hover > ul,
    nav ul li:hover > ul {
      display: none;
    }

    /* Fisrt Tier Dropdown */
    nav ul ul li {
      display: block;
      width: 100%;
    }

    nav ul ul ul li {
      position: static;
      /* has to be the same number as the "width" of "nav ul ul li" */
    }
  }

  @media all and (max-width: 800px) {
    nav {
      /*display: block;*/
      display: none;
      margin: 0;
    }
    nav ul li {
      display: block;
      width: 100%;
    }
    .MuiSvgIcon-root {
      margin-left: 5px;
      font-size: 24px !important;
    }
  }
`

export const StylesAssobrafir = styled.div`
  ul.menu,
  .menu li,
  .menu a {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    text-transform: uppercase;
  }

  ul.menu ul {
    position: absolute;
    display: none;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  }

  /* Configurações Nivel 1 */
  ul.menu {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    font-family: Gotham !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.42;
    padding: 0 2px;
  }

  .menu li {
    float: left;
    width: auto;
    position: relative;
  }

  .menu li a {
    display: block;
    padding: 10px 10px 9px;
    line-height: 1.75;
    height: 30px;
    float: left;
    transition: all 0.1s linear;
  }

  .menu li ul a {
    height: 27px;
  }

  .nav-social {
    display: none;
  }

  .nav-search {
    display: none;
  }

  /* Configurações Nivel 2*/
  .menu li:hover > ul {
    background: #fff;
  }

  .menu li:hover > ul.submenu-1 {
    display: block;
    top: 50px;
    left: 0;
    padding: 5px;
    width: 400px;
  }

  .menu ul.submenu-1 a {
    width: 100%;
    padding: 0 20px;
    border: 0;
  }

  /* Configurações nivel 2*/
  .menu li:hover > ul.submenu-2 {
    display: block;
    top: 0;
    left: 405px;
    padding: 5px;
    width: 100%;
    border-radius: 0 5px 5px 5px;
  }

  .menu ul.submenu-2 a {
    padding: 0 20px;
    border-radius: 5px;
  }

  /* Configurações nivel 3*/
  .menu li:hover > ul.submenu-3 {
    display: block;
    top: 0;
    left: 295px;
    padding: 5px;
    border-radius: 0 5px 5px 5px;
    width: 400px;
  }

  .menu ul.submenu-3 a {
    padding: 0 20px;
    border-radius: 5px;
  }

  /*Configurações de cores*/
  /*Nivel 1*/
  .menu {
    background: #044286;
  }
  .menu a {
    color: #d0d3dd;
  }
  .menu li {
    background: url('https://assobrafir.com.br/wp-content/themes/portal-assobrafir/images/menu-spacer.png') no-repeat
      center right;
  }
  .menu li ul li {
    background: none;
    width: 100%;
  }
  .menu a:hover {
    color: #fff;
  }
  .menu li:hover > a {
    color: #fff;
  }

  /*nivel 2*/
  .submenu-1 {
    background: rgb(42, 116, 153);
  }
  .submenu-1 a {
    color: #333;
  }
  .submenu-1 li:hover > a {
    background: #fff;
    color: rgb(42, 116, 153);
  }

  /*nivel 3*/
  .submenu-2 {
    background: rgb(42, 116, 153);
  }
  .submenu-2 a {
    color: #333;
  }
  .submenu-2 li:hover > a {
    background: #fff;
  }

  /*nivel 3*/
  .submenu-3 {
    background: rgb(42, 116, 153);
  }
  .submenu-3 a {
    color: #333;
  }
  .submenu-3 li:hover > a {
    background: #000;
  }

  @media all and (max-width: 800px) {
    nav {
      display: none;
      margin: 0;
    }
  }
`

const StylesAborl = styled.div`
  background-color: #fff;

  @font-face {
    font-family: "Gotham";
    src: url("fonts/Gotham-Bold.woff2") format("woff2"), url("fonts/Gotham-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  ul.menu,
  .menu li,
  .menu a {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    font-size: 0.9em;
    font-weight: 750;
    text-transform: uppercase;
    word-spacing: 0px;
  }
  .toggle,
  [id^="drop"] {
    display: none;
  }

  .current-menu-item .nav-link {
    background-color: #f06363;
    font-weight: bold;
  }

  ul.menu ul {
    position: absolute;
    display: none;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    border-bottom: 3px solid rgba(0, 0, 0, 0.15);
  }

  ul.menu li.show ul.submenu-1 li.showSub ul.submenu-2 {
    display: block;
    position: absolute;
    z-index: 30;
    top: 0px !important;
    left: 175px;
    padding: 0 !important;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    border-bottom: 3px solid rgba(0,0,0,0.15);
  }

  ul.menu li.show > ul.submenu-1 > li > a > span {
    width: 100%
  }

  ul.menu li.show > ul.submenu-1 > li > a > span.set::after {
    content: "+";
    color: var(--e-global-text-color);
    width: 20px;
    height: auto;
    margin: 0;
    border: 0;
    margin-left: 10px;
  }

  ul.menu li.show > ul.submenu-1 > li.showSub > a > span.set::after {
    content: "-";
    color: var(--e-global-text-color);
    margin: 0;
    border: 0;
    margin-left: 10px;
  }


  .nav-social {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 10.445%;
  }

  .nav-search {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 3.445%;
  }

  .nav-search > span {
    fill: var(--e-global-text-color);
    color: var(--e-global-text-color);
  }

  /* Configurações Nivel 1 */
  .nav-menu-desktop {
    display: flex !important;
    flex-direction: row;
    max-width: 100%;
  }

  .nav-menu {
    max-width: 86.9%;
    flex-wrap: wrap;
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ul.menu {
    max-width: 100%;
    flex-wrap: wrap;
    flex: 1 0 auto;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12.8px;
  }

  .menu li {
    float: left;
    width: auto;
    position: relative;
  }

  .menu li a {
    display: block;
    padding: 5px 10px;
    line-height: 1.6;
    white-space: normal;
    float: left;
    clear: both;
    color: #fff;
    font-family: "Gotham", Arial !important;
    transition: all 0.1s linear;
  }

  .menu li:hover > .li-link {
    transition: border 0.2s linear;
  }

  /* Configurações Nivel 2*/
  .menu li.show > ul.submenu-1 {
    width: 100%;
    background-color: #f3f3f3;
    z-index: 2;
    display: block;
    top: 28px;
    left: 0;
    padding: 0;
    min-width: 8.5rem;
  }
  // .menu li:hover>ul.submenu-1 {
  //   display: block;
  //   top: 28px;
  //   left: 0;
  //   padding: 5px;
  //   min-width: 8.5rem;
  // }

  .menu ul.submenu-1 a {
    width: 100%;
    min-width: 8.5rem;
    border: 0;
    color: rgba(0, 0, 0, 0.5);
    background-color: #f3f3f3;
    font-weight: 400;
    font-size: 1.2em;
    text-transform: none;
  }
  .menu ul.submenu-1 a:hover {
    color: #444;
    background: #f7f7f7;
  }

  /* Configurações nivel 2*/

  .menu ul.submenu-2 a {
    width: 100%;
    min-width: 9.5rem;
    background-color: #e9e9e9;
    border: 0;
    font-weight: 750;
    font-size: 1.2em;
    text-transform: none;
  }
  .menu ul.submenu-2 a:hover {
    color: #444;
    background: #f7f7f7;
  }

  /* Configurações nivel 3*/

  .menu ul.submenu-3 a {
    padding: 0 20px;
    border-radius: 5px;
  }

  /*Configurações de cores*/
  /*Nivel 1*/
  .nav-menu-desktop {
    border-top: 1px solid #006ea2;
  }

  .nav-menu-desktop > div + div {
    margin-left: 5px;
    padding-left: 5px;
    border-left: 1px solid #006ea2;
  }

  .menu {
    background: #fff;
  }

  .menu li a {
    color: var(--e-global-text-color);
  }

  .menu li:first-child {
    border-left: 1px solid #fff;
  }

  .menu a {
    color: #fff;
    border-right: 1px solid #fff;
  }

  .menu li:hover > a.li-link {
    background: #fff;
    border-bottom: 4px solid var(--e-global-color-primary);
    color: #000;
  }

  /*nivel 2*/
  .submenu-1 {
    background: rgb(42, 116, 153);
  }
  .submenu-1 a {
    color: #fff;
  }
  .submenu-1 li:hover > a {
    background: #fff;
    color: rgb(42, 116, 153);
  }

  /*nivel 3*/
  .submenu-2 {
    background: rgb(42, 116, 153);
  }
  .submenu-2 a {
    color: #fff;
  }
  .submenu-2 li:hover > a {
    background: #fff;
  }

  /*nivel 3*/
  .submenu-3 {
    background: rgb(42, 116, 153);
  }
  .submenu-3 a {
    color: #fff;
  }
  .submenu-3 li:hover > a {
    background: #000;
  }

  .MuiSvgIcon-root {
    font-size: 17px !important;
  }

  @media screen and (max-width: 950px) {
    .nav-menu-desktop.hide {
      display: none !important;
    }

    .nav-menu {
      max-width: 100%;
    }
    .nav-search {
      display: none;
    }
    .nav-social {
      display: none;
    }

    ul.menu li {
      width: 100%;
      background-color: white;
      border-bottom: 1px solid #529dd7;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ul.menu li.show ul.submenu-1 li.showSub ul.submenu-2 {
      display: block;
      position: absolute;
      z-index: 30;
      top: 35px !important;
      left: 0 !important;
      padding: 0 !important;
      border-radius: inherit;
      border: none;
      width: 100%;
    }

    ul.menu li a {
      z-index: 1;
      width: 100%;
      min-height: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .show ul.menu li.show a.li-link::after {
      flex: 0 0 auto;
      content: "-";
      font-size: 16px;
      float: right;
    }

    ul.menu a.parent.li-link::after {
      flex: 0 0 auto;
      content: "+";
      font-size: 16px;
      float: right;
    }

    ul.menu li.show ul li a::after {
      content: inherit;
    }

    .menu li.show > ul.submenu-1 {
      width: 100%;
      z-index: 2;
      display: block;
      top: 35px;
      left: -0;
      min-width: 8.5rem;

    .show .menu li.show > ul.submenu-1 {
      width: 100%;
      position: absolute;
      z-index: 30;
      top: 40px !important;
      left: 0;
      padding: 0 !important;
      border-radius: inherit;
      border: none;
      width: 100%;
    }

    .show .menu li.show > ul.submenu-1 > li > ul.submenu-2 {
      display: none;
      position: absolute;
      z-index: 30;
      top: 40px !important;
      left: 0;
      padding: 0 !important;
      border-radius: inherit;
      border: none;
      width: 100%;
    }

    .show .menu li.show > ul.submenu-1 > li.showSub > ul.submenu-2 {
      display: block;
    }

    .show .menu li.show > ul.submenu-1 > li {
      background: #fff;
      display: inline-block !important;
      width: 100%;
    }

    .show .menu li.show > ul.submenu-1 > li > ul.submenu-2 > li {
      background: #dfdfdf;
      display: inline-block !important;
      width: 100%;
    }

    ul.menu li.show > ul.submenu-1 > li > a > span.set::after {
      content: "+";
      color: var(--e-global-text-color);
      width: 20px;
      height: auto;
      margin: 0;
      border: 0;
      float: right;
    }

    ul.menu li.show > ul.submenu-1 > li.showSub > a > span.set::after {
      content: "-";
      color: var(--e-global-text-color);
      margin: 0;
      border: 0;
      float: right;
    }

    .show .menu li a {
      display: inline-block;
      overflow: hidden;
      float: none !important;
      padding: 7px 5px !important;
      width: inherit;
    }

    .show .menu li a:hover {
      color: #2b2b2b !important;
    }

    .show .menu li:first-child {
      border-left: none !important;
    }
  }
`

export const StylesAmib = styled.div`
  @font-face {
    font-family: 'Gotham';
    src: url('fonts/Gotham-Bold.woff2') format('woff2'), url('fonts/Gotham-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  ul.menu,
  .menu li,
  .menu a {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    text-transform: uppercase;
  }
  .toggle,
  [id^='drop'] {
    display: none;
  }

  .current-menu-item .nav-link {
    background-color: #f06363;
    font-weight: bold;
  }
  ul.menu ul {
    position: absolute;
    display: none;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    border-bottom: 3px solid rgba(0, 0, 0, 0.15);
  }

  span.set:after {
    display: block;
    content: ' ';
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
  }

  /* Configurações Nivel 1 */
  ul.menu {
    height: 28px;
    width: 100%;
    max-width: 99.74%;
    display: inline-flex;
    justify-content: center;
    font-size: 12px;
    padding: 0 2px;
  }

  .menu li {
    float: left;
    width: auto;
    position: relative;
  }

  .menu li a {
    display: block;
    padding: 5px 10px;
    line-height: 1.6;
    white-space: normal;
    font-weight: 750;
    float: left;
    clear: both;
    color: #fff;
    font-family: 'Gotham', Arial !important;
    transition: all 0.1s linear;
  }
  .menu li a:hover {
  }

  .nav-menu {
    width: 100%;
  }

  .nav-social {
    display: none;
  }

  .nav-search {
    display: none;
  }

  /* Configurações Nivel 2*/
  .menu li.show > ul.submenu-1 {
    display: block;
    top: 28px;
    left: 0;
    padding: 5px;
    min-width: 8.5rem;
  }

  .menu ul.submenu-1 li {
    width: 100%;
  }

  .menu ul.submenu-1 a {
    min-width: 8.5rem;
    padding: 0.25rem 0.9rem;
    border: 0;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    font-size: 1.2em;
    text-transform: none;
    width: 100%;
  }
  .menu ul.submenu-1 a:hover {
    color: #444;
    background: #f7f7f7;
  }

  /* Configurações nivel 2*/
  .menu ul.submenu-1 li.showSub > ul.submenu-2 {
    display: block;
    top: 0;
    left: 0;
    transform: translateX(100%);
    padding: 5px;
    border-radius: 0 5px 5px 5px;
    min-width: 9.5rem;
  }

  .menu ul.submenu-2 a {
    min-width: 9.5rem;
    padding: 0.25rem 0.9rem;
    border: 0;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    font-size: 1.2em;
    text-transform: none;
  }
  .menu ul.submenu-2 a:hover {
    color: #444;
    background: #f7f7f7;
  }

  /* Configurações nivel 3*/
  .menu ul.submenu-2 li.showSub > ul.submenu-3 {
    display: block;
    top: 0;
    left: 0;
    transform: translateX(94%);
    padding: 5px;
    border-radius: 0 5px 5px 5px;
  }

  .menu ul.submenu-3 a {
    padding: 0 20px;
    border-radius: 5px;
  }

  /*Configurações de cores*/
  /*Nivel 1*/
  .menu {
    background: rgb(42, 116, 153);
  }
  .menu li:first-child {
    border-left: 1px solid #fff;
  }

  .menu a {
    color: #fff;
    border-right: 1px solid #fff;
  }

  .menu li:hover > a {
    background: rgb(42, 116, 153);
    color: #fff;
  }

  /*nivel 2*/
  .submenu-1 {
    background: rgb(42, 116, 153);
  }
  .submenu-1 a {
    color: #fff;
  }
  .submenu-1 li:hover > a {
    background: #fff;
    color: rgb(42, 116, 153);
  }

  /*nivel 3*/
  .menu ul.submenu-1 li.showSub > ul.submenu-2 {
    background: #f6f6f6;
  }
  .submenu-2 a {
    color: #fff;
  }
  .submenu-2 li:hover > a {
    background: #fff;
  }

  /*nivel 3*/
  .submenu-3 {
    background: rgb(42, 116, 153);
  }
  .submenu-3 a {
    color: #fff;
  }
  .submenu-3 li:hover > a {
    background: #000;
  }

  .MuiSvgIcon-root {
    font-size: 17px !important;
  }

  @media all and (max-width: 800px) {
    nav {
      display: none;
    }

    #menu-desktop.show {
      top: 20px;
    }

    ul.menu {
      height: auto;
    }

    ul.menu li:first-child a.li-link {
      background-color: rgb(240, 99, 99);
    }

    .show {
      position: relative;
      display: block;
    }

    .show .menu {
      display: flex;
      text-align: center;
      flex-direction: column;
      padding: 0 !important;
      width: 101% !important;
    }

    .show ul.menu ul {
      padding: 0 !important;
    }

    .show .menu li {
      display: block;
      width: 100%;
      background: #2a7499;
      border-bottom: 1px solid #226789;
      position: relative;
    }

    .show .menu a {
      border-right: none;
    }

    .show .menu li.show a.li-link::after {
      content: '-';
      font-size: 16px;
      float: right;
      padding-right: 20px;
    }

    .show .menu a.parent.li-link::after {
      content: '+';
      font-size: 16px;
      float: right;
      padding-right: 20px;
    }

    .show .menu li.show ul li a::after {
      content: inherit;
    }

    .show .menu li.show > ul.submenu-1 {
      position: absolute;
      z-index: 30;
      top: 40px !important;
      left: 0 !important;
      padding: 0 !important;
      border-radius: inherit;
      border: none;
      width: 100%;
    }

    .show .menu li.show > ul.submenu-1 > li > ul.submenu-2 {
      position: absolute;
      z-index: 30;
      top: 40px !important;
      left: 0;
      padding: 0 !important;
      border-radius: inherit;
      border: none;
      width: 100%;
    }

    .show .menu li.show > ul.submenu-1 > li {
      background: #fff;
      display: inline-block !important;
      width: 100%;
    }

    .show .menu li.show > ul.submenu-1 > li > ul.submenu-2 > li {
      background: #fff;
      display: inline-block !important;
      width: 100%;
    }

    ul.menu li.show > ul.submenu-1 > li > a > span.set::after {
      content: '+';
      color: var(--e-global-text-color);
      width: 20px;
      height: auto;
      margin: 0;
      border: 0;
    }

    ul.menu li.show > ul.submenu-1 > li.showSub > a > span.set::after {
      content: '-';
      color: var(--e-global-text-color);
      margin: 0;
      border: 0;
    }

    .show .menu li a {
      display: inline-block;
      overflow: hidden;
      float: none !important;
      padding: 7px 5px !important;
      width: inherit;
    }

    .show .menu li a:hover {
      color: #2b2b2b !important;
    }

    .show .menu li:first-child {
      border-left: none !important;
    }

    /* FORM NOVO CADASTRO */

    .login-container .MuiTabs-flexContainer {
      display: inherit;
      text-align: center;
    }

    .login-container .MuiTabs-scroller {
      white-space: normal !important;
    }

    /* CARROSSEL CART */

    .carouselCards-container .arrow {
      font-size: 2.4rem;
    }

    .carouselCards-container .carouselCards-inner .card-grid {
      margin: 0;
    }

    .carouselCards-inner .MuiIcon-root {
      flex-shrink: inherit;
    }

    /* MUITABS - NOVO CADASTRO */

    .login-container .PrivateTabIndicator-colorSecondary-3 {
      background-color: inherit;
    }

    .login-container .MuiTab-textColorInherit.Mui-selected {
      opacity: 1;
      border-bottom: 2px solid #f50057 !important;
    }

    .login-container .MuiTabs-indicator {
      display: none;
    }

    .MuiTabs-scroller {
      position: inherit !important;
    }
  }
`

const StylesNewcbr = styled.div`
  @font-face {
    font-family: "Gotham";
    src: url("fonts/Gotham-Bold.woff2") format("woff2"), url("fonts/Gotham-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  ul.menu,
  .menu li,
  .menu a {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    font-size: 0.9em;
    font-weight: 750;
    text-transform: uppercase;
    word-spacing: 0px;
  }
  .toggle,
  [id^="drop"] {
    display: none;
  }

  .current-menu-item .nav-link {
    background-color: #f06363;
    font-weight: bold;
  }
  ul.menu ul {
    position: absolute;
    display: none;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    border-bottom: 3px solid rgba(0, 0, 0, 0.15);
  }

  ul.menu li:hover ul.submenu-1 li:hover ul.submenu-2 {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 30;
    top: 0px !important;
    left: 100%;
    padding: 0 !important;
  }

  ul.menu li:hover> ul.submenu-1 > li > a > span {
    width: 100%
  }

  /* Configurações Nivel 1 */
  .nav-menu-desktop {
    display: flex !important;
    flex-direction: row;
    max-width: 100%;
  }

  .nav-menu {
    max-width: 86.9%;
    flex-wrap: wrap;
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ul.menu {
    max-width: 100%;
    flex-wrap: wrap;
    flex: 1 0 auto;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 14px;
    font-size: 12.8px;
    margin: 0 20px;
  }

  .menu li {
    float: left;
    width: auto;
    position: relative;
  }

  .menu li a {
    border-radius: 20px;
    border: 0;
    background-color: #ffffffbd;
    display: block;
    padding: 5px 15px;
    line-height: 1.6;
    white-space: normal;
    float: left;
    clear: both;
    transition: all 0.2s linear;
  }

  .menu li:hover > .li-link {
    transition: border 0.2s linear;
  }

  /* Configurações Nivel 2*/
  .menu li:hover> ul.submenu-1 {
    background-color: #f3f3f3;
    z-index: 2;
    display: flex;
    flex-direction: column;
    top: 26px;
    left: 0;
    padding: 0;
    min-width: 8.5rem;
  }

  .menu li:hover> ul.submenu-1 > li {
  }

  .menu ul.submenu-1 a {
    border-radius: 0;
    min-width: 8.5rem;
    border: 0;
    color: rgba(0, 0, 0, 0.5);
    background-color: #f3f3f3;
    font-weight: 400;
    font-size: 1.2em;
    text-transform: none;
  }
  .menu ul.submenu-1 a:hover {
    color: #222;
    background: #f7f7f7;
  }

  /* Configurações nivel 2*/

  .menu ul.submenu-2 a {
    overflow-x: hidden;
    max-width: 200px;
    width: 100%;
    border: 0;
    font-weight: 750;
    font-size: 1.2em;
    text-transform: none;
  }
  .menu ul.submenu-2 a:hover {
    color: #222;
    background: #f7f7f7;
  }

  /* Configurações nivel 3*/

  .menu ul.submenu-3 a {
    padding: 0 20px;
    border-radius: 5px;
  }

  /*Configurações de cores*/
  /*Nivel 1*/
  .nav-menu-desktop > div + div {
    margin-left: 5px;
    padding-left: 5px;
    border-left: 1px solid #006ea2;
  }

  .menu {
  }

  .menu li a {
    color: #000000;
  }

  .menu li:first-child {
  }

  .menu a {
    color: #fff;
    border-right: 1px solid #fff;
  }

  .menu li:hover > a.li-link {
    background-color: #000000;
    color: #FF7337;
  }

  /*nivel 2*/
  .submenu-1 {
    background: rgb(42, 116, 153);
  }
  .submenu-1 a {
    color: #fff;
  }
  .submenu-1 li:hover > a {
    background: #fff;
    color: rgb(42, 116, 153);
  }

  /*nivel 3*/
  .submenu-2 {
    background: rgb(42, 116, 153);
  }
  .submenu-2 a {
    color: #fff;
  }
  .submenu-2 li:hover > a {
    background: #fff;
  }

  /*nivel 3*/
  .submenu-3 {
    background: rgb(42, 116, 153);
  }
  .submenu-3 a {
    color: #fff;
  }
  .submenu-3 li:hover > a {
    background: #000;
  }

  .MuiSvgIcon-root {
    font-size: 17px !important;
  }

  @media screen and (max-width: 950px) {
    .nav-menu-desktop.hide {
      display: none !important;
    }

    #menu-desktop {
      position: absolute;
      bottom: -2.7rem;    
      transition: transform .5s;
      transform: scaleY(0);
    }

    #menu-desktop.show {
      transform: scaleY(1);
    }

    .nav-menu-desktop {
      border-bottom: 3px solid #000000;
    }

    .menu li:first-child {
      border-top: 1px solid #000000;
    }

    .menu li:last-child {
      box-shadow: 0 8px 12px rgba(0, 0, 0, 0.45);
    }

    .show .menu li.show > ul.submenu-1 {
      display: block;
      position: absolute;
      z-index: 30;
      top: 35px !important;
      left: 0 !important;
      border-radius: inherit;
      border: none;
      width: 100vw;
      flex: 0;
      padding: 0;
    }

    .nav-menu {
      z-index: 1;
      max-width: 100vw;
      width: 100vw;
      padding: 0;
      margin: 0;
      flexDirection: column-reverse;
    }
    .nav-search {
      display: none;
    }

    .nav-menu .menu {
      flex: 0;
      padding: 0;
      width: 100vw;
      gap: 0;
    }

    .nav-menu .menu ul {
      width: 100vw;
    }

    ul.menu li {
      width: 100vw;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid #D0D0D0;
    }

    ul.menu li:hover ul.submenu-1 li:hover ul.submenu-2 {
      display: block;
      position: absolute;
      z-index: 30;
      top: 35px !important;
      left: 0 !important;
      border-radius: inherit;
      border: none;
      width: 100%;
    }

    ul.menu li {
      width: 100vw;
      min-height: 30px;
    }

    ul.menu li a {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      color: #000000;
      font-size: 1rem;
      font-weight: 400;
      font-family: "Montserrat", Sans-serif;
      line-height: 20px;
      padding: 1rem 0.5rem;
      width: 100%;
      border-radius: 0;
      text-align: center;
    }

    ul.menu li:hover ul li a::after {
      content: inherit;
    }

    .menu li:hover> ul.submenu-1 {
      width: 100%;
      z-index: 2;
      display: block;
      top: 35px;
      left: -0;
      min-width: 8.5rem;

    .menu li:hover> ul.submenu-1 {
      width: 100%;
      position: absolute;
      z-index: 30;
      left: 0;
      border-radius: inherit;
      border: none;
      width: 100%;
    }

    .menu li:hover> ul.submenu-1 > li > ul.submenu-2 {
      display: none;
      position: absolute;
      z-index: 30;
      left: 0;
      padding: 0 !important;
      border-radius: inherit;
      border: none;
      width: 100%;
    }

    .menu li:hover> ul.submenu-1 > li:hover > ul.submenu-2 {
      display: block;
    }

    .menu li:hover> ul.submenu-1 > li {
      background: #fff;
      display: inline-block !important;
      width: 100%;
    }

    .menu li:hover> ul.submenu-1 > li > ul.submenu-2 > li {
      background: #dfdfdf;
      display: inline-block !important;
      width: 100%;
    }

    .menu li a {
      display: inline-block;
      overflow: hidden;
      float: none !important;
      padding: 7px 5px !important;
      width: inherit;
    }
  }
`

const StylesFbg = styled.div`
  @font-face {
    font-family: "Gotham";
    src: url("fonts/Gotham-Bold.woff2") format("woff2"), url("fonts/Gotham-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  #menu-desktop {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-social {
    padding: 0 2rem;
  }

  ul.menu,
  .menu li,
  .menu a {
    margin: 0;
    list-style: none;
    text-decoration: none;
    font-size: 0.9em;
    font-weight: 750;
    text-transform: uppercase;
    word-spacing: 0px;
  }
  .toggle,
  [id^="drop"] {
    display: none;
  }

  .current-menu-item .nav-link {
    background-color: #f06363;
    font-weight: bold;
  }
  ul.menu ul {
    position: absolute;
    display: none;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    border-bottom: 3px solid rgba(0, 0, 0, 0.15);
  }

  ul.menu li:hover ul.submenu-1 li:hover ul.submenu-2 {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 30;
    top: 0px !important;
    left: 100%;
    padding: 0 !important;
  }

  /* Configurações Nivel 1 */
  .nav-menu-desktop {
    display: flex;
    flex-direction: row;
    max-width: 100%;
  }

  .nav-menu {
    max-width: 86.9%;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 11rem;
  }

  ul.menu {
    flex-wrap: wrap;
    flex: 1 0 auto;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    flexBasis: '83.333333%',
    maxWidth: '83.333333%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }

  .menu li {
    float: left;
    width: auto;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu li a {
    border: 0;
    padding: 2.5px 10px;
    display: block;
    line-height: 17px;
    white-space: normal;
    font-variant: all-small-caps;
    font-size: 1.2em;
    float: left;
    clear: both;
    gap: 1rem;
    font-weight: 500;
    font-family: "Gotham", Arial !important;
    transition: all 0.2s linear;
  }

  .show .menu a.parent.li-link {
    transition: border 0.2s linear;
  }

  .show .menu a.parent.li-link::after {
    content: '▼';
    font-size: 16px;
    float: right;
    margin-left: 2px;
  }

  .show .menu li:hover a.parent.li-link::after {
    content: '▲';
    font-size: 16px;
    float: right;
    margin-left: 2px;
  }

  .show .menu .show a.parent.li-link::after {
    content: '▲';
    font-size: 16px;
    float: right;
    margin-left: 2px;
  }

  .menu li:hover > .li-link {
    transition: border 0.2s linear;
  }

  /* Configurações Nivel 2*/
  .menu li:hover> ul.submenu-1 {
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    top: 2.3rem;
    left: 0;
    padding: 0.2rem 0;
  }

  .menu ul.submenu-1 a {
    border-radius: 0;
    min-width: 8.5rem;
    border: 0;
    color: #333;
    background-color: #fff;
    font-weight: 400;
    font-size: 1.2em;
    text-transform: none;
    padding: 0.25rem 1rem;
  }
  .menu ul.submenu-1 a:hover {
    background: #e9ecef;
    color: #1e2125;
  }

  /* Configurações nivel 2*/

  .menu ul.submenu-2 a {
    overflow-x: hidden;
    max-width: 200px;
    width: 100%;
    border: 0;
    font-weight: 750;
    font-size: 1.2em;
    text-transform: none;
  }
  .menu ul.submenu-2 a:hover {
    color: #222;
    background: #f7f7f7;
  }

  /* Configurações nivel 3*/

  .menu ul.submenu-3 a {
    padding: 0 20px;
    border-radius: 5px;
  }

  /*Configurações de cores*/
  /*Nivel 1*/
  .nav-menu-desktop {
    border-bottom: 5px solid #1B60AE;
  }

  .nav-menu-desktop > div + div {
    border-left: 1px solid #006ea2;
  }

  .menu li a {
    color: #000;
  }

  .menu li:last-child a {
    color: #fff;
  }

  .menu li:last-child a: hover {
    color: #f5f5f5;
  }

  .menu > li:last-child{
    background-color: #6EAEBF;
  } 

  .menu li a: hover, .menu li a: hover::after {
    color: #444;
  }

  .menu li a: hover, .menu li a: hover::after {
    color: #444;
  }

  .menu a {
    color: #fff;
    border-right: 1px solid #fff;
  }

  /*nivel 2*/
  .submenu-1 li a {
    color: #333 !important;
  }
  .submenu-1 li:hover > a {
    background: #fff;
    color: #444 !important;
  }

  /*nivel 3*/
  .submenu-2 {
    background: rgb(42, 116, 153);
  }
  .submenu-2 a {
    color: #fff;
  }
  .submenu-2 li:hover > a {
    background: #fff;
  }

  /*nivel 3*/
  .submenu-3 {
    background: rgb(42, 116, 153);
  }
  .submenu-3 a {
    color: #fff;
  }
  .submenu-3 li:hover > a {
    background: #000;
  }

  .MuiSvgIcon-root {
    font-size: 17px !important;
  }

  @media screen and (max-width: 1510px) {
    .menu li a {
      padding: 2.5px 5px;
    }
  } 

  @media screen and (max-width: 1410px) {
    .menu li a {
      padding: 2.5px 2.5px;
    }
  } 

  @media screen and (max-width: 1360px) {
    .menu li a {
      font-size: 1rem;
    }
  } 

  @media screen and (max-width: 1045px) {
    .menu li a {
      font-size: 1rem;
    }
  } 

  @media screen and (max-width: 950px) {
    .nav-menu-desktop.hide {
      display: none !important;
    }

    #menu-desktop {
      flex: 1;
    }

    .menu li:first-child {
      border-top: 1px solid #529dd7;
    }

    .menu li:last-child {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    }

    .show .menu li.show > ul.submenu-1 {
      display: block;
      position: absolute;
      z-index: 30;
      top: 35px !important;
      left: 0 !important;
      border-radius: inherit;
      border: none;
      width: 100vw;
      flex: 0;
      padding: 0;
    }

    .nav-menu {
      z-index: 1;
      max-width: 100vw;
      width: 100vw;
      padding: 0;
      margin: 0;
      flexDirection: column-reverse;
    }
    .nav-search {
      display: none;
    }

    .nav-menu .menu {
      flex: 0;
      padding: 0;
      width: 100vw;
      gap: 0;
    }

    .nav-menu .menu ul {
      width: 100vw;
    }

    ul.menu li {
      width: 100vw;
      background-color: white;
      border-bottom: 1px solid #529dd7;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ul.menu li:hover ul.submenu-1 li:hover ul.submenu-2 {
      display: block;
      position: absolute;
      z-index: 30;
      top: 35px !important;
      left: 0 !important;
      border-radius: inherit;
      border: none;
      width: 100%;
    }

    ul.menu li {
      width: 100vw;
      min-height: 30px;
    }

    ul.menu li a {
      width: 100vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    ul.menu li:hover ul li a::after {
      content: inherit;
    }

    .menu li:hover> ul.submenu-1 {
      width: 100%;
      z-index: 2;
      display: block;
      top: 35px;
      left: -0;
      min-width: 8.5rem;

    .menu li:hover> ul.submenu-1 {
      width: 100%;
      position: absolute;
      z-index: 30;
      left: 0;
      border-radius: inherit;
      border: none;
      width: 100%;
    }

    .menu li:hover> ul.submenu-1 > li > ul.submenu-2 {
      display: none;
      position: absolute;
      z-index: 30;
      left: 0;
      padding: 0 !important;
      border-radius: inherit;
      border: none;
      width: 100%;
    }

    .menu li:hover> ul.submenu-1 > li:hover > ul.submenu-2 {
      display: block;
    }

    .menu li:hover> ul.submenu-1 > li {
      background: #fff;
      display: inline-block !important;
      width: 100%;
    }

    .menu li:hover> ul.submenu-1 > li > ul.submenu-2 > li {
      background: #dfdfdf;
      display: inline-block !important;
      width: 100%;
    }

    .menu li a {
      display: inline-block;
      overflow: hidden;
      float: none !important;
      padding: 7px 5px !important;
      width: inherit;
    }
  }
`

const StylesSbd = styled.div`
  flex: 1;

  @font-face {
    font-family: 'Gotham';
    src: url('fonts/Gotham-Bold.woff2') format('woff2'), url('fonts/Gotham-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  ul.menu,
  .menu li,
  .menu a {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    font-size: 0.9em;
    font-weight: 750;
    text-transform: uppercase;
    word-spacing: 0px;
  }
  .toggle,
  [id^='drop'] {
    display: none;
  }

  .current-menu-item .nav-link {
    background-color: #f06363;
    font-weight: bold;
  }
  ul.menu ul {
    width: 240px;
    position: absolute;
    display: none;
    border-radius: 5px;
    border-top: 0.2rem solid #00b3cc;
  }

  /* Configurações Nivel 1 */
  .nav-menu-desktop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .nav-menu {
    z-index: 1;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  ul.menu {
    flex-wrap: wrap;
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    font-size: 12.8px;
  }

  .menu li {
    float: left;
    width: auto;
    position: relative;
  }

  .menu li:first {
    margin-left: 0 !important;
  }

  .menu > li:last-child {
    background-color: #006d95;
  }

  .menu > li:last-child a {
    transition: all 0.2s linear;
    color: #ffffff;
  }

  .menu li a {
    font-size: 0.8rem;
    padding: 10px;
    transition: all 0.2s linear;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
    white-space: normal;
    font-family: 'Merriweather';
  }

  .dangerouslySetInnerHTML img {
    width: 3rem;
  }

  .menu li ul.menu a {
    margin-left: 30px;
  }

  /* Configurações Nivel 2*/

  .menu ul.submenu-1 a:before {
    content: '▸';
    margin-right: 5px;
  }

  .menu ul.submenu-1 a {
    border-radius: 0;
    min-width: 8.5rem;
    border: 0;
    font-weight: 400;
    font-size: 1.2em;
    text-transform: none;
  }

  /* Configurações nivel 2*/

  .menu li:hover > ul.submenu-1 {
    z-index: 3;
  }

  .menu ul.submenu-2 a {
    overflow-x: hidden;
    width: 100%;
    border: 0;
    font-weight: 750;
    font-size: 1.2em;
    text-transform: none;
  }

  /* Configurações nivel 3*/

  /*Configurações de cores*/
  /*Nivel 1*/
  .nav-menu-desktop > div + div {
    margin-left: 5px;
    padding-left: 5px;
    border-left: 1px solid #006ea2;
  }

  .menu li a {
    color: #444;
  }

  /*nivel 2*/
  .submenu-1 a {
    color: #fff;
  }

  /*nivel 3*/
  .submenu-2 a {
    color: #fff;
  }

  /*nivel 3*/
  .submenu-3 a {
    color: #fff;
  }

  .MuiSvgIcon-root {
    font-size: 17px !important;
  }

  @media screen and (min-width: 1161px) {
    ul.menu li:hover ul.submenu-1 li:hover ul.submenu-2 {
      display: flex;
      flex-direction: column;
      position: absolute;
      z-index: 30;
      top: 0px;
      left: 100%;
      padding: 0 !important;
    }

    ul.menu li:hover > ul.submenu-1 > li > a > span {
      width: 100%;
    }
    .menu li:hover > ul.submenu-1 {
      z-index: 2;
      display: flex;
      flex-direction: column;
      min-width: 8.5rem;
      position: absolute;
      width: 240px;
      left: 0;
      top: auto;
      margin: -3px 0 0;
      padding: 0;
    }

    .menu li:hover > ul.submenu-1::after {
      content: '';
      position: absolute;
      top: -0.4rem;
      left: 30px;
      width: 5px;
      height: 5px;
      background-color: #00b3cc;
      transform: rotate(45deg);
    }
    .menu ul.submenu-1 a:hover {
      color: #222;
      background: #f7f7f7;
    }
    .menu ul.submenu-2 a:hover {
      color: #222;
      background: #f7f7f7;
    }
    .submenu-1 li:hover > a {
      background: #fff;
      color: rgb(42, 116, 153);
    }
    .submenu-2 li:hover > a {
      background: #fff;
    }
    .submenu-3 li:hover > a {
      background: #000;
    }
  }

  @media screen and (max-width: 1160px) {
    .nav-menu-desktop.hide {
      display: none !important;
    }

    // header element
    #menu-desktop {
      display: none;
    }

    #menu-desktop.show {
      width: 100%;
      position: absolute;
      top: 130px;
      left: 0;
      z-index: 10;
    }

    // nav element
    .nav-menu {
      width: 100%;
    }

    // ul element
    ul {
      border-radius: 0 !important;
    }

    ul.menu {
      width: 100%;
      border-top: 0.2rem solid #00b3cc;
    }

    // li element
    ul.menu li {
      width: 100%;
      border-bottom: 1px solid #529dd7;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    // SHADOW
    ul.menu li:last-child {
      -webkit-box-shadow: 0px 34px 54px 0px #00000042;
      -moz-box-shadow: 0px 34px 54px 0px #00000042;
      box-shadow: 0px 34px 54px 0px #00000042;
    }

    // a element
    ul.menu li a {
      padding: 0;
      width: 100%;
      max-width: 100% !important;
      display: flex;
      align-items: center;
      padding: 12px 4px;
    }

    ul.menu li a.parent,
    ul.menu li a:has(.set) {
      justify-content: space-between;
    }

    // last-child a element
    ul.menu > li:last-child a {
      background-color: #006d95;
    }

    // selected a
    ul li.show a.parent,
    ul li.showSub a:has(.set) {
      color: #00b3cc;
    }

    // diamond
    ul.submenu-1::after,
    ul.submenu-2::after {
      content: '';
      position: absolute;
      top: -0.4rem;
      left: 30px;
      width: 5px;
      height: 5px;
      background-color: #00b3cc;
      transform: rotate(45deg);
    }

    // remove before & after
    ul.menu li.show ul li a::after,
    ul.menu li.show ul li a::before {
      display: none;
    }

    // submenu
    .menu li.show > ul.submenu-1,
    .menu li.showSub > ul.submenu-2 {
      width: 100%;
      display: block;
      top: 2.7rem;
      left: -0;
      padding: 0;
    }

    .menu li.show > ul.submenu-1 {
      z-index: 2;
    }

    .menu li.showSub > ul.submenu-2 li a {
      max-width: 100%;
    }

    .menu li.showSub > ul.submenu-2 {
      z-index: 3 !important;
      top: 2.7rem !important;
      left: 0 !important;
    }

    .show .menu a.parent.li-link::after,
    ul.menu li.showSub a:has(.set)::after {
      content: '▼';
      display: block !important;
      font-size: 10px;
      float: right;
      margin-left: 2px;
    }

    .show .menu .show a.parent.li-link::after,
    ul.menu li a:has(.set)::after {
      content: '▲';
      display: block !important;
      font-size: 10px;
      float: right;
      margin-left: 2px;
    }
  }
`

export const StylesIapo = styled.div`
@font-face {
  font-family: "Gotham";
  src: url("fonts/Gotham-Bold.woff2") format("woff2"), url("fonts/Gotham-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

#menu-desktop {
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.menu,
.menu li,
.menu a {
  margin: 0;
  list-style: none;
  text-decoration: none;
  font-size: 0.9em;
  font-weight: 750;
  text-transform: uppercase;
  word-spacing: 0px;
}
.toggle,
[id^="drop"] {
  display: none;
}

ul.menu ul {
  position: absolute;
  display: none;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  border-bottom: 3px solid rgba(0, 0, 0, 0.15);
}

ul.menu li:hover ul.submenu-1 li:hover ul.submenu-2 {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 30;
  top: 0px !important;
  left: 100%;
  padding: 0 !important;
}

/* Configurações Nivel 1 */
.nav-menu-desktop {
  display: flex;
  flex-direction: row;
}

.nav-menu {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.menu {
  flex-wrap: wrap;
  flex: 1 0 auto;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}

.menu li {
  float: left;
  width: auto;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu li a {
  border: 0;
  padding: 2.5px 10px;
  display: block;
  line-height: 17px;
  white-space: normal;
  font-variant: all-small-caps;
  float: left;
  clear: both;
  gap: 1rem;
  transition: all 0.2s linear;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 1.15rem;
}

.show .menu a.parent.li-link {
  transition: border 0.2s linear;
}

.show .menu a.parent.li-link::after {
  content: '▼';
  font-size: 0.75rem;
  float: right;
  margin-left: 4px;
}

.menu li:hover > .li-link {
  transition: border 0.2s linear;
}

/* Configurações Nivel 2*/
.menu li:hover> ul.submenu-1 {
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  top: 2.3rem;
  left: 0;
  padding: 0.2rem 0;
}

.menu ul.submenu-1 a {
  min-width: 9.5rem;
  border: 0;
  background-color: #fff;
  padding: 0.25rem 1rem;
}
.menu ul.submenu-1 a:hover {
  background: #e9ecef;
}

/* Configurações nivel 2*/

.menu ul.submenu-2 a {
  overflow-x: hidden;
  max-width: 9.5rem;
  width: 100%;
  border: 0;
  font-weight: 750;
  font-size: 1.2em;
  text-transform: none;
}
.menu ul.submenu-2 a:hover {
  color: #222;
  background: #f7f7f7;
}

/* Configurações nivel 3*/

.menu ul.submenu-3 a {
  padding: 0 20px;
  border-radius: 5px;
  max-width: 9.5rem;
}

/*Configurações de cores*/
/*Nivel 1*/
.nav-menu-desktop > div + div {
  border-left: 1px solid #006ea2;
}

.menu li a {
  color: #1c6ab9;
}

.menu li a: hover, .menu li a: hover::after {
  color: #1c7ab9;
}

.menu li a: hover, .menu li a: hover::after {
  color: #1c7ab9;
}

.menu a {
  color: #fff;
  border-right: 1px solid #fff;
}

/*nivel 2*/
.submenu-1 li:hover > a {
  background: #fff;
}

/*nivel 3*/
.submenu-2 {
  background: rgb(42, 116, 153);
}
.submenu-2 a {
  color: #fff;
}
.submenu-2 li:hover > a {
  background: #fff;
}

/*nivel 3*/
.submenu-3 {
  background: rgb(42, 116, 153);
}
.submenu-3 a {
  color: #fff;
}
.submenu-3 li:hover > a {
  background: #000;
}

.MuiSvgIcon-root {
  font-size: 17px !important;
}

@media screen and (max-width: 1510px) {
  .menu li a {
    padding: 2.5px 5px;
  }
} 

@media screen and (max-width: 1410px) {
  .menu li a {
    padding: 2.5px 2.5px;
  }
} 

@media screen and (max-width: 1360px) {
  .menu li a {
    font-size: 1rem;
  }
} 

@media screen and (max-width: 1045px) {
  .menu li a {
    font-size: 1rem;
  }
} 

@media screen and (max-width: 950px) {
  .nav-menu-desktop.hide {
    display: none !important;
  }

  #menu-desktop {
    flex: 1;
  }

  .menu li:first-child {
    border-top: 1px solid #1c6ab9;
  }

  .menu li:last-child {
    box-shadow: 0 10px 12px rgba(0, 0, 0, 0.457);
  }

  .show .menu li.show > ul.submenu-1 {
    display: block;
    position: absolute;
    z-index: 30;
    top: 35px !important;
    left: 0 !important;
    border-radius: inherit;
    border: none;
    width: 100vw;
    flex: 0;
    padding: 0;
  }

  .nav-menu {
    z-index: 1;
    max-width: 100vw;
    width: 100vw;
    padding: 0;
    margin: 0;
    flexDirection: column-reverse;
  }
  .nav-search {
    display: none;
  }

  .nav-menu .menu {
    flex: 0;
    padding: 0;
    width: 100vw;
    gap: 0;
  }

  .nav-menu .menu ul {
    width: 100vw;
  }

  ul.menu li {
    width: 100vw;
    background-color: white;
    border-bottom: 1px solid #1c6ab9;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ul.menu li:hover ul.submenu-1 li:hover ul.submenu-2 {
    display: block;
    position: absolute;
    z-index: 30;
    top: 35px !important;
    left: 0 !important;
    border-radius: inherit;
    border: none;
    width: 100%;
  }

  ul.menu li {
    width: 100vw;
    min-height: 30px;
  }

  ul.menu li a {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ul.menu li:hover ul li a::after {
    content: inherit;
  }

  .menu li:hover> ul.submenu-1 {
    width: 100%;
    z-index: 2;
    display: block;
    top: 35px;
    left: -0;
    min-width: 8.5rem;

  .menu li:hover> ul.submenu-1 {
    width: 100%;
    position: absolute;
    z-index: 30;
    left: 0;
    border-radius: inherit;
    border: none;
    width: 100%;
  }

  .menu li:hover> ul.submenu-1 > li > ul.submenu-2 {
    display: none;
    position: absolute;
    z-index: 30;
    left: 0;
    padding: 0 !important;
    border-radius: inherit;
    border: none;
    width: 100%;
  }

  .menu li:hover> ul.submenu-1 > li:hover > ul.submenu-2 {
    display: block;
  }

  .menu li:hover> ul.submenu-1 > li {
    background: #fff;
    display: inline-block !important;
    width: 100%;
  }

  .menu li:hover> ul.submenu-1 > li > ul.submenu-2 > li {
    background: #dfdfdf;
    display: inline-block !important;
    width: 100%;
  }

  .menu li a {
    display: inline-block;
    overflow: hidden;
    float: none !important;
    padding: 7px 5px !important;
    width: inherit;
  }
}`

const StylesForl = styled.div`
  flex: 1;

  ul.menu,
  .menu li,
  .menu a {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: 750;
    word-spacing: 0px;
  }
  .toggle,
  [id^='drop'] {
    display: none;
  }

  .current-menu-item .nav-link {
    background-color: #f06363;
    font-weight: bold;
  }
  ul.menu ul {
    position: absolute;
    display: none;
  }

  ul.menu li:hover ul.submenu-1 li:hover ul.submenu-2 {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 30;
    top: 0px !important;
    left: 100%;
    padding: 0 !important;
  }

  ul.menu li:hover > ul.submenu-1 > li > a > span {
    width: 100%;
  }

  a[data-content='Cursos'] {
    color: #ffff00 !important;
    border-bottom: 5px solid #ffff00 !important;
    border-radius: 0;
  }

  /* Configurações Nivel 1 */
  .nav-menu-desktop {
    display: flex !important;
    flex-direction: row;
    max-width: 100%;
  }

  .nav-menu {
    width: 100%;
    flex-wrap: wrap;
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ul.menu {
    width: 100%;
    flex-wrap: wrap;
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu li {
    float: left;
    width: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }

  .menu li a {
    border: 0;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    line-height: 1.6;
    white-space: normal;
    float: left;
    clear: both;
    font-family: 'Gotham', Arial !important;
    transition: all 0.2s linear;
    font-weight: bold;
    font-size: 0.7rem;
  }

  .menu li:hover > .li-link {
    transition: border 0.2s linear;
  }

  .menu a.parent.li-link::after {
    content: '▾';
    padding-left: 0.2rem;
    font-size: 0.5rem;
    float: right;
  }

  /* Configurações Nivel 2*/
  .menu li:hover > ul.submenu-1 {
    background-color: #f3f3f3;
    z-index: 2;
    display: flex;
    flex-direction: column;
    top: 25px;
    left: 8px;
    padding: 0;
    min-width: 8.5rem;
  }

  .menu ul.submenu-1 a {
    border-radius: 0;
    min-width: 8.5rem;
    border: 0;
    color: #0000007f;
    background-color: #f3f3f3;
    font-weight: 400;
    text-transform: none;
  }
  .menu ul.submenu-1 a {
    border-radius: 0;
    min-width: 8.5rem;
    border: 0;
    color: #ffffff;
    background-color: #000000;
    font-weight: 400;
    text-transform: none;
  }

  .menu ul.submenu-1 {
    border: 2px solid #ffff00;
  }

  ul.menu li > ul.submenu-1 > li > a > span.set {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ul.menu li > ul.submenu-1 > li > a > span.set::after {
    content: '▸';
    padding-left: 0.5rem;
    font-size: 0.5rem;
    float: right;
  }

  .menu ul.submenu-1 li {
    border-bottom: 1px solid #dbdbdb31;
  }

  .menu ul.submenu-1 li:last-child() {
    border-bottom: 0;
  }

  /* Configurações nivel 2*/

  .menu ul.submenu-2 {
    border: 2px solid #ffff00;
  }

  .menu ul.submenu-2 a {
    overflow-x: hidden;
    max-width: 200px;
    width: 100%;
    border: 0;
    font-weight: 750;
    text-transform: none;
  }

  .menu ul.submenu-2 li {
    border-bottom: 1px solid #fcfcfc;
  }

  .menu ul.submenu-2 li:last-child() {
    border-bottom: 0;
  }

  .menu ul.submenu-2 a:hover {
    color: #ffff00;
  }

  /* Configurações nivel 3*/

  .menu ul.submenu-3 a {
    padding: 0 20px;
    border-radius: 5px;
  }

  /*Configurações de cores*/
  /*Nivel 1*/
  .nav-menu-desktop > div + div {
    margin-left: 5px;
    padding-left: 5px;
    border-left: 1px solid #006ea2;
  }

  .menu li a {
    color: #f4f4f4;
    width: 100%;
  }

  .menu li:first-child {
  }

  .menu a {
    color: #fff;
    border-right: 1px solid #fff;
  }

  /*nivel 2*/
  .menu ul.submenu-1 a:hover {
    color: #ffff00;
  }

  .menu li:hover > a {
    color: #ffff00;
  }

  .submenu-1 {
    background: #2a7499;
  }
  .submenu-1 a {
    color: #fff;
  }

  /*nivel 3*/
  .submenu-2 {
    background: #2a7499;
  }
  .submenu-2 a {
    color: #fff;
  }

  /*nivel 3*/
  .submenu-3 {
    background: #2a7499;
  }
  .submenu-3 a {
    color: #fff;
  }
  .submenu-3 li:hover > a {
    background: #000;
  }

  .MuiSvgIcon-root {
    font-size: 17px !important;
  }

  @media screen and (max-width: 1190px) {
    .nav-menu-desktop.hide {
      display: none !important;
    }

    #menu-desktop.show {
      display: flex !important;
      position: absolute;
      top: 100px;
      left: 0;
    }

    .nav-menu-desktop {
      width: 100vw;
    }

    .nav-menu {
      max-width: 100%;
    }
    .nav-search {
      display: none;
    }
    .nav-social {
      display: none;
    }

    ul.menu {
      width: 100%;
      height: fit-content;
      background-color: #000000;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
      border: 0;
      border-bottom: 2px solid #ffff00;
      z-index: 20;
    }

    ul.menu li {
      width: 100%;
      border-bottom: 1px solid #ffffff;
      display: flex;
    }

    ul.menu li:hover ul.submenu-1 li:hover ul.submenu-2 {
      display: none !important;
    }

    ul.menu li a {
      color: #ffffff !important;
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .menu li:hover > ul.submenu-1 {
      display: none;
    }

    a[data-content='Cursos'] {
      color: #ffffff !important;
      border-width: 0 !important;
    }

    ul.menu li.show ul.submenu-1 {
      display: flex;
      z-index: 30;
      flex-direction: column;
      width: 100%;
      max-width: 100%;
      height: fit-content;
      padding: 0;
      border: 0;
      top: 45px;
      left: 0;
      background-color: #1f1f1f;
      border-bottom: 2px solid #ffff00;
    }

    ul.menu,
    ul.menu ul.submenu-1,
    ul.menu ul.submenu-2,
    ul.menu ul.submenu-3 {
      -webkit-box-shadow: 0px 27px 29px 21px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 27px 29px 21px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 27px 29px 21px rgba(0, 0, 0, 0.75);
    }

    ul.menu li > ul.submenu-1 > li > a > span.set {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    ul.menu li > ul.submenu-1 > li > a > span {
      width: 100%;
    }

    ul.menu li > ul.submenu-1 > li > a > span.set::after {
      content: '▾';
      padding-left: 0.5rem;
      font-size: 0.5rem;
      float: right;
    }

    ul.menu li.show ul.submenu-1 a {
      height: fit-content;
      background-color: #1a1a1a;
    }

    ul.menu li.show ul.submenu-1 li.showSub ul.submenu-2 {
      display: flex;
      z-index: 2;
      flex-direction: column;
      width: 100%;
      height: fit-content;
      padding: 0;
      top: 45px !important;
      border: 0;
      border-bottom: 2px solid #ffff50;
      border-radius: 0;
    }

    ul.menu li.show ul.submenu-1 li.showSub ul.submenu-2 a {
      width: 100%;
      max-width: 100%;
      height: fit-content;
    }

    .menu li:hover > ul.submenu-1 > li > ul.submenu-2 {
      display: none !important;
      position: absolute;
      z-index: 30;
      left: 0;
      border-radius: inherit;
      border: none;
      width: 100%;
    }

    .menu li:hover > ul.submenu-1 > li:hover > ul.submenu-2 {
      display: none !important;
    }
  }
`

const StylesSba = styled.div`
  #menu-desktop {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  ul.menu {
    flex: 1;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }

  .menu li {
    list-style: none;
    border-radius: 20px;
    position: relative;
    transition: all 0.3s;
  }

  .menu a {
    color: inherit;
    font-size: 1.2em;
    padding: 4px 8px;
    text-decoration: none;
  }

  .menu > li:hover > a {
    font-weight: 600;
    border-radius: 4px;
    background-color: #dedede30;
  }

  ul.menu ul {
    position: absolute;
    display: none;
    background: #fff;
    border-radius: 4px;
    border-bottom: 3px solid #00000015;
  }

  .menu li:hover > ul.submenu-1::after {
    content: '';
    width: 8px;
    height: 8px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: #191919df;
    position: absolute;
    z-index: -1;
    top: -4px;
    left: 28px;
  }

  .menu li:hover > ul.submenu-1 {
    backdrop-filter: blur(50px);
    background-color: #19191924;
    z-index: 3;
    display: flex;
    flex-direction: column;
    top: 29px;
    left: 0;
    padding: 0;
    min-width: max-content;
  }

  ul.menu li:hover ul.submenu-1 li:hover ul.submenu-2 {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 30;
    top: 0px !important;
    left: 100%;
    padding: 0 !important;
  }

  .menu ul.submenu-1 li {
    width: 100%;
    display: flex;
  }

  .menu ul.submenu-1 a {
    width: 100%;
    border-radius: 0;
    min-width: 8.5rem;
    border: 0;
    color: #fff;
    font-weight: 400;
    font-size: 1em;
    text-transform: none;
    transition: all 0.4s;
    padding: 1rem 0.8rem !important;
  }

  .menu ul.submenu-1 a:hover {
    background: #f7f7f720;
  }

  .menu ul.submenu-2 a {
    overflow-x: hidden;
    max-width: 200px;
    width: 100%;
    border: 0;
    font-weight: 750;
    font-size: 1.2em;
    text-transform: none;
  }

  .menu ul.submenu-2 a:hover {
    color: #222;
    background: #f7f7f7;
  }

  .menu ul.submenu-3 a {
    padding: 0 20px;
    border-radius: 5px;
  }

  @media screen and (max-width: 1140px) {
    .nav-menu-desktop.hide {
      display: none !important;
    }

    #menu-desktop {
      z-index: 3;
      top: 0;
      left: 0;
      height: 100vh;
      position: fixed;
      background-color: #00000020;
      backdrop-filter: blur(4rem);
      -webkit-box-shadow: 200px 0px 300px 69px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 200px 0px 300px 69px rgba(0, 0, 0, 0.75);
      box-shadow: 200px 0px 300px 69px rgba(0, 0, 0, 0.75);
    }

    ul.menu {
      gap: 5px;
      width: 80vw;
      margin-top: 70px;
      flex-direction: column;
    }

    ul.menu li {
      flex: 1;
      width: 100%;
      display: flex;
    }

    .menu a {
      width: 100%;
      height: 100%;
      padding: 20px 15px;
      font-size: 0.9rem;
    }

    .show .menu a.parent.li-link::after {
      content: '▼';
      font-size: 0.5rem;
      float: right;
      margin-left: 2px;
    }

    .show .menu li:hover a.parent.li-link::after {
      content: '▲';
      font-size: 0.5rem;
      float: right;
      margin-left: 2px;
    }

    .show .menu .show a.parent.li-link::after {
      content: '▲';
      font-size: 0.5rem;
      float: right;
      margin-left: 2px;
    }

    .menu li:hover > ul.submenu-1 {
      display: none;
    }

    .show .menu li.show {
      flex-direction: column;
    }

    .show .menu li.show > ul.submenu-1 {
      width: 80vw;
      display: block;
      position: relative;
      z-index: 30;
      border-radius: 2px;
      border: none;
      flex: 0;
      padding: 0;
      background-color: #f7f7f720;
      backdrop-filter: blur(50rem);
    }

    .show .menu li.show > a {
      font-weight: 600;
      border-radius: 2px;
      background-color: #dedede80;
    }

    .show .menu li.show > ul.submenu-1 a {
      color: #fff;
    }
  }
`

const StylesCapacitacao = styled.div`
  #menu-desktop {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  ul.menu {
    flex: 1;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }

  .menu li {
    list-style: none;
    border-radius: 20px;
    position: relative;
    transition: all 0.3s;
  }

  .menu a {
    color: inherit;
    font-size: 1.2em;
    padding: 4px 8px;
    text-decoration: none;
  }

  .menu > li:hover > a {
    font-weight: 600;
    border-radius: 4px;
    background-color: #dedede30;
  }

  ul.menu ul {
    position: absolute;
    display: none;
    background: #fff;
    border-radius: 4px;
    border-bottom: 3px solid #00000015;
  }

  .menu li:hover > ul.submenu-1::after {
    content: '';
    width: 8px;
    height: 8px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: #191919df;
    position: absolute;
    z-index: -1;
    top: -4px;
    left: 28px;
  }

  .menu li:hover > ul.submenu-1 {
    backdrop-filter: blur(50px);
    background-color: #19191924;
    z-index: 3;
    display: flex;
    flex-direction: column;
    top: 29px;
    left: 0;
    padding: 0;
    min-width: max-content;
  }

  ul.menu li:hover ul.submenu-1 li:hover ul.submenu-2 {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 30;
    top: 0px !important;
    left: 100%;
    padding: 0 !important;
  }

  .menu ul.submenu-1 li {
    width: 100%;
    display: flex;
  }

  .menu ul.submenu-1 a {
    width: 100%;
    border-radius: 0;
    min-width: 8.5rem;
    border: 0;
    color: #fff;
    font-weight: 400;
    font-size: 1em;
    text-transform: none;
    transition: all 0.4s;
    padding: 1rem 0.8rem !important;
  }

  .menu ul.submenu-1 a:hover {
    background: #f7f7f720;
  }

  .menu ul.submenu-2 a {
    overflow-x: hidden;
    max-width: 200px;
    width: 100%;
    border: 0;
    font-weight: 750;
    font-size: 1.2em;
    text-transform: none;
  }

  .menu ul.submenu-2 a:hover {
    color: #222;
    background: #f7f7f7;
  }

  .menu ul.submenu-3 a {
    padding: 0 20px;
    border-radius: 5px;
  }

  @media screen and (max-width: 1140px) {
    .nav-menu-desktop.hide {
      display: none !important;
    }

    #menu-desktop {
      z-index: 3;
      top: 0;
      left: 0;
      height: 100vh;
      position: fixed;
      background-color: #00000070;
      backdrop-filter: blur(4rem);
      -webkit-box-shadow: 200px 0px 300px 69px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 200px 0px 300px 69px rgba(0, 0, 0, 0.75);
      box-shadow: 200px 0px 300px 69px rgba(0, 0, 0, 0.75);
    }

    ul.menu {
      gap: 5px;
      width: 80vw;
      margin-top: 70px;
      flex-direction: column;
    }

    ul.menu li {
      flex: 1;
      width: 100%;
      display: flex;
    }

    .menu a {
      width: 100%;
      height: 100%;
      padding: 20px 15px;
      font-size: 0.9rem;
    }

    .show .menu a.parent.li-link::after {
      content: '▼';
      font-size: 0.5rem;
      float: right;
      margin-left: 2px;
    }

    .show .menu li:hover a.parent.li-link::after {
      content: '▲';
      font-size: 0.5rem;
      float: right;
      margin-left: 2px;
    }

    .show .menu .show a.parent.li-link::after {
      content: '▲';
      font-size: 0.5rem;
      float: right;
      margin-left: 2px;
    }

    .menu li:hover > ul.submenu-1 {
      display: none;
    }

    .show .menu li.show {
      flex-direction: column;
    }

    .show .menu li.show > ul.submenu-1 {
      width: 80vw;
      display: block;
      position: relative;
      z-index: 30;
      border-radius: 2px;
      border: none;
      flex: 0;
      padding: 0;
      background-color: #f7f7f720;
      backdrop-filter: blur(50rem);
    }

    .show .menu li.show > a {
      font-weight: 600;
      border-radius: 2px;
      background-color: #dedede80;
    }

    .show .menu li.show > ul.submenu-1 a {
      color: #fff;
    }
  }
`

const StylesSbot = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  #menu-desktop {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 0.5rem;
  }

  .nav-menu {
    height: 100%;
    flex: 1;
    display: flex;
  }

  ul.menu {
    flex: 1;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .menu li {
    flex: 1;
    height: 100%;
    list-style: none;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    width: min-content;
    padding: 0 0.5px;
  }

  .menu a {
    width: 100%;
    height: 100%;
    color: inherit;
    font-size: 0.8em;
    text-decoration: none;
    display: grid;
    place-items: center;
    text-align: center;
    overflow-wrap: break-word;
    line-height: normal;
  }

  ul.menu ul {
    background-color: #ffffff;
    position: absolute;
    display: none;
  }

  @media screen and (min-width: 1141px) {
    height: 130px;

    .menu li:not(:has(ul.submenu-2)) {
      position: relative;
    }

    ul.menu > li:not(:last-child):hover {
      background-color: #006f62;
    }

    .menu li:last-child {
      align-items: center;
      justify-content: center;
    }

    .menu li:last-child a {
      width: min-content;
      height: auto;
      padding: 13px 20px 10px 20px;
      background-color: #ffffff20;
      transition: background-color 300ms linear;
    }

    .menu li:last-child a:hover {
      background-color: #ffffff30;
    }

    ul.menu > li:not(:last-child) > a {
      cursor: default;
    }

    ul.menu > li > a {
      color: #ffffff;
      font-family: 'Ubuntu', 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0px;
      font-style: normal;
    }

    .menu li:hover > ul.submenu-1 ul.submenu-2 {
      width: 100%;
      display: flex;
      position: relative;
      flex-direction: column;
      padding: 0;
      align-items: flex-start;
    }

    .menu li:hover > ul.submenu-1 {
      padding: 20px 0 29px 0 !important;
    }

    .menu li:hover > ul.submenu-1:has(ul.submenu-2) {
      width: 100vw;
      z-index: 3;
      display: flex;
      flex-direction: row;
      top: 130px;
      left: 0;
      align-items: flex-start;
    }

    .menu li:hover > ul.submenu-1:not(:has(ul.submenu-2)) {
      min-width: 8.5rem;
      z-index: 3;
      display: flex;
      flex-direction: row;
      top: 130px;
      left: 0;
      padding: 0;
      flex-direction: column;
      align-items: flex-start;
    }

    .menu li:hover > ul.submenu-1 li {
      flex-direction: column;
      width: 100%;
      height: 100%;
    }

    .menu li:hover > ul.submenu-1 a {
      display: block;
      text-align: start;
      padding: 8px 22px;
      color: #000000;
      width: 100%;
      height: 100%;
    }

    .menu li:hover > ul.submenu-1 a:hover {
      color: #333f48;
    }

    .menu li:hover > ul.submenu-1 a:not(:has(.set)) {
      font-weight: 300;
      font-size: 15px;
    }

    .menu li:hover > ul.submenu-1 a:has(.set) {
      cursor: default;
      color: #1f4934;
      font-size: 0.9rem;
      font-weight: bold;
      width: 100%;
      height: 100%;
    }

    .menu li:hover > ul.submenu-1 li > a .set {
      cursor: default;
    }
  }

  @media screen and (max-width: 1140px) {
    .nav-menu-desktop.hide {
      display: none !important;
    }

    #menu-desktop {
      padding: 0;
      z-index: 3;
      width: 100vw;
      top: 129px;
      left: 0;
      position: absolute;
      height: fit-content;
      background-color: #006f62;
    }

    ul.menu {
      gap: 5px;
      width: 100vw;
      flex-direction: column;
    }

    ul.menu li {
      flex: 1;
      width: 100%;
      display: flex;
      border-bottom: 1px solid #ffffff;
    }

    .menu a {
      width: 100%;
      height: 100%;
      padding: 20px 20px 20px 5px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .show .menu a.parent.li-link::after,
    a:has(.set)::after {
      content: '▼';
      font-size: 0.5rem;
      float: right;
    }

    .show .menu a.parent.li-link::after,
    a:has(.set)::after {
      content: '▼';
      font-size: 0.5rem;
      float: right;
    }

    .show .menu li.show {
      flex-direction: column;
    }

    ul.submenu-1 a {
      color: #030303;
    }

    .show .menu li.show > ul.submenu-1 {
      width: 100vw;
      display: block;
      position: relative;
      z-index: 30;
      padding: 0;
      background-color: #ffffff;
    }

    .show .menu li.show > ul.submenu-1 li {
      border-bottom: 1px solid #006f62;
    }

    .show .menu li.show > ul.submenu-1 li.showSub {
      display: flex;
      flex-direction: column;
    }

    .show .menu li.show > ul.submenu-1 li.showSub > ul.submenu-2 {
      width: 100vw;
      display: block;
      position: relative;
      z-index: 60;
      padding: 0;
      background-color: #0f0f0f10;
    }

    .show .menu li.show > a {
      background-color: #0f0f0f20;
    }
  }
`

const StylesSbpt = styled.div`
  .hide {
    display: none;
  }

  #menu-desktop {
    flex: 1;
    display: flex;
    justify-content: center;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
  }

  .nav-menu {
    width: 100%;
    padding: 0 15px;
    position: relative;
  }

  ul.menu {
    flex: 1;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }

  .menu li {
    list-style: none;
    border-radius: 20px;
    transition: all 0.3s;
  }

  .menu a {
    display: flex;
    color: inherit;
    font-weight: bold;
    padding: 10px 8px;
    align-items: center;
    font-size: 0.875rem;
    text-decoration: none;
  }

  .menu a svg {
    width: 0.875rem;
    height: 0.875rem;
  }

  .menu > li:hover > a {
    color: #149eda;
  }

  ul.menu ul {
    position: absolute;
    display: none;
    background: #fff;
    border-radius: 4px;
  }

  .menu li:hover > ul.submenu-1 {
    background-color: #ededed;
    width: 99.22vw;
    z-index: 3;
    display: initial;
    top: 40;
    left: 0;
    padding: 0;
    background: #ededed;
    border-radius: 0;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    column-count: 3;
    float: none;
    padding: 0.5rem 0;
  }

  ul.menu li:hover ul.submenu-1 li:hover ul.submenu-2 {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 30;
    top: 0px !important;
    left: 100%;
    padding: 0 !important;
  }

  .menu ul.submenu-1 li {
    width: 100%;
    display: flex;
  }

  .menu ul.submenu-1 a {
    width: 100%;
    border-radius: 0;
    min-width: 8.5rem;
    border: 0;
    color: #1872a7;
    font-weight: bold;
    font-size: 1em;
    text-transform: none;
    transition: all 0.4s;
    padding: 0.25rem 1.5rem !important;
    clear: both;
    text-shadow: 1px 1px 1px #ffffff;
  }

  .menu ul.submenu-1 a:hover {
    color: #0b4566;
    background: #f7f7f720;
  }

  .menu ul.submenu-2 a {
    overflow-x: hidden;
    max-width: 200px;
    width: 100%;
    border: 0;
    font-weight: 750;
    font-size: 1.2em;
    text-transform: none;
  }

  .menu ul.submenu-2 a:hover {
    color: #222;
    background: #f7f7f7;
  }

  .menu ul.submenu-3 a {
    padding: 0 20px;
    border-radius: 5px;
  }

  @media screen and (max-width: 960px) {
    .hide {
      display: none !important;
    }

    #menu-desktop {
      z-index: 3;
      top: 0;
      left: 0;
      height: 100vh;
      position: fixed;
      background-color: #00000070;
      backdrop-filter: blur(4rem);
      -webkit-box-shadow: 200px 0px 300px 69px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 200px 0px 300px 69px rgba(0, 0, 0, 0.75);
      box-shadow: 200px 0px 300px 69px rgba(0, 0, 0, 0.75);
    }

    ul.menu {
      gap: 5px;
      width: 80vw;
      margin-top: 70px;
      flex-direction: column;
    }

    ul.menu li {
      flex: 1;
      width: 100%;
      display: flex;
    }

    .menu a {
      width: 100%;
      height: 100%;
      display: flex;
      font-size: 0.9rem;
      padding: 20px 15px;
    }

    .show .menu a.parent.li-link::after {
      content: '▼';
      font-size: 0.5rem;
      float: right;
      margin-left: 2px;
    }

    .show .menu li:hover a.parent.li-link::after {
      content: '▲';
      font-size: 0.5rem;
      float: right;
      margin-left: 2px;
    }

    .show .menu .show a.parent.li-link::after {
      content: '▲';
      font-size: 0.5rem;
      float: right;
      margin-left: 2px;
    }

    .menu li:hover > ul.submenu-1 {
      display: none;
    }

    .show .menu li.show {
      flex-direction: column;
    }

    .show .menu li.show > ul.submenu-1 {
      width: 80vw;
      display: block;
      position: relative;
      z-index: 30;
      border-radius: 2px;
      border: none;
      flex: 0;
      padding: 0;
      background-color: #f7f7f720;
      backdrop-filter: blur(50rem);
    }

    .show .menu li.show > a {
      font-weight: 600;
      border-radius: 2px;
      background-color: #dedede80;
    }

    .show .menu li.show > ul.submenu-1 a {
      color: #fff;
    }
  }
`

const StylesAboce = styled.div`
  flex: 1;

  ul.menu,
  .menu li,
  .menu a {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: 750;
    word-spacing: 0px;
  }
  .toggle,
  [id^='drop'] {
    display: none;
  }

  .current-menu-item .nav-link {
    background-color: #f06363;
    font-weight: bold;
  }
  ul.menu ul {
    position: absolute;
    display: none;
  }

  ul.menu li:hover ul.submenu-1 li:hover ul.submenu-2 {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 30;
    top: 0px !important;
    left: 100%;
    padding: 0 !important;
  }

  ul.menu li:hover > ul.submenu-1 > li > a > span {
    width: 100%;
  }

  a[data-content='Cursos'] {
    color: #ffff00 !important;
    border-radius: 0;
  }

  /* Configurações Nivel 1 */
  .nav-menu-desktop {
    display: flex !important;
    flex-direction: row;
    max-width: 100%;
  }

  .nav-menu {
    width: 100%;
    flex-wrap: wrap;
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ul.menu {
    width: 100%;
    flex-wrap: wrap;
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu li {
    float: left;
    width: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }

  .menu li a {
    border: 0;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    line-height: 1.6;
    white-space: normal;
    float: left;
    clear: both;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    transition: all 0.2s linear;
    font-weight: bold;
    font-size: 0.8rem;
  }

  .menu li:hover > .li-link {
    transition: border 0.2s linear;
  }

  .menu a.parent.li-link::after {
    content: '▾';
    padding-left: 0.2rem;
    font-size: 0.5rem;
    float: right;
  }

  /* Configurações Nivel 2*/
  .menu li:hover > ul.submenu-1 {
    background-color: #f3f3f3;
    z-index: 2;
    display: flex;
    flex-direction: column;
    top: 25px;
    left: 8px;
    padding: 0;
    min-width: 10.5rem;
  }

  .menu ul.submenu-1 a {
    border-radius: 0;
    min-width: 10.5rem;
    border: 0;
    color: #0000007f;
    background-color: #f3f3f3;
    font-weight: 400;
    text-transform: none;
  }
  .menu ul.submenu-1 a {
    border-radius: 0;
    min-width: 8.5rem;
    border: 0;
    color: #000000;
    background-color: #ffffff;
    font-weight: 400;
    text-transform: none;
  }

  .menu ul.submenu-1 {
    border-top: 3px solid #13aff0;
  }

  ul.menu li > ul.submenu-1 > li > a > span.set {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ul.menu li > ul.submenu-1 > li > a > span.set::after {
    content: '▸';
    padding-left: 0.5rem;
    font-size: 0.5rem;
    float: right;
  }

  .menu ul.submenu-1 li {
    border-bottom: 1px solid #e9e9e9;
  }

  .menu ul.submenu-1 li:last-child() {
    border-bottom: 0;
  }

  /* Configurações nivel 2*/

  .menu ul.submenu-2 {
    border: 2px solid #ffff00;
  }

  .menu ul.submenu-2 a {
    overflow-x: hidden;
    max-width: 200px;
    width: 100%;
    border: 0;
    font-weight: 750;
    text-transform: none;
  }

  .menu ul.submenu-2 li {
    border-bottom: 1px solid #fcfcfc;
  }

  .menu ul.submenu-2 li:last-child() {
    border-bottom: 0;
  }

  .menu ul.submenu-2 a:hover {
    background-color: #fafafa;
  }

  /* Configurações nivel 3*/

  .menu ul.submenu-3 a {
    padding: 0 20px;
    border-radius: 5px;
  }

  /*Configurações de cores*/
  /*Nivel 1*/
  .nav-menu-desktop > div + div {
    margin-left: 5px;
    padding-left: 5px;
    border-left: 1px solid #006ea2;
  }

  .menu li a {
    color: #f4f4f4;
    width: 100%;
  }

  .menu li:first-child {
  }

  .menu a {
    color: #fff;
    border-right: 1px solid #fff;
  }

  /*nivel 2*/
  .menu ul.submenu-1 a:hover {
    background-color: #fafafa;
  }

  .menu li:hover > a {
    background-color: #fafafa;
  }

  .submenu-1 {
    background: #2a7499;
  }
  .submenu-1 a {
    color: #fff;
  }

  /*nivel 3*/
  .submenu-2 {
    background: #2a7499;
  }
  .submenu-2 a {
    color: #fff;
  }

  /*nivel 3*/
  .submenu-3 {
    background: #2a7499;
  }
  .submenu-3 a {
    color: #fff;
  }
  .submenu-3 li:hover > a {
    background: #000;
  }

  .MuiSvgIcon-root {
    font-size: 17px !important;
  }

  @media screen and (max-width: 1160px) {
    .nav-menu-desktop.hide {
      display: none !important;
    }

    #menu-desktop.show {
      display: flex !important;
      position: absolute;
      top: 215px;
      left: 0;
      border-top: 3px solid #13aff0;
    }

    .nav-menu-desktop {
      width: 100vw;
    }

    .nav-menu {
      max-width: 100%;
    }
    .nav-search {
      display: none;
    }
    .nav-social {
      display: none;
    }

    ul.menu {
      width: 100%;
      height: fit-content;
      background-color: #000000;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
      border: 0;
      z-index: 20;
    }

    ul.menu li {
      width: 100%;
      border-bottom: 1px solid #f2f2f2;
      display: flex;
    }

    ul.menu li:hover ul.submenu-1 li:hover ul.submenu-2 {
      display: none !important;
    }

    ul.menu li a {
      color: #444 !important;
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .menu li:hover > ul.submenu-1 {
      display: none;
    }

    a[data-content='Cursos'] {
      color: #ffffff !important;
      border-width: 0 !important;
    }

    ul.menu li.show ul.submenu-1 {
      display: flex;
      z-index: 30;
      border-top: 3px solid #13aff0;
      flex-direction: column;
      width: 100%;
      max-width: 100%;
      height: fit-content;
      padding: 0;
      border: 0;
      top: 45px;
      left: 0;
    }

    ul.menu li > ul.submenu-1 > li > a > span.set {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    ul.menu li > ul.submenu-1 > li > a > span {
      width: 100%;
    }

    ul.menu li > ul.submenu-1 > li > a > span.set::after {
      content: '▾';
      padding-left: 0.5rem;
      font-size: 0.5rem;
      float: right;
    }

    ul.menu li.show ul.submenu-1 a {
      height: fit-content;
      background-color: #ffffff;
    }

    ul.menu li.show ul.submenu-1 li.showSub ul.submenu-2 {
      display: flex;
      z-index: 2;
      flex-direction: column;
      width: 100%;
      height: fit-content;
      padding: 0;
      top: 45px !important;
      border: 0;
      border-radius: 0;
    }

    ul.menu li.show ul.submenu-1 li.showSub ul.submenu-2 a {
      width: 100%;
      max-width: 100%;
      height: fit-content;
    }

    .menu li:hover > ul.submenu-1 > li > ul.submenu-2 {
      display: none !important;
      position: absolute;
      z-index: 30;
      left: 0;
      border-radius: inherit;
      border: none;
      width: 100%;
    }

    .menu li:hover > ul.submenu-1 > li:hover > ul.submenu-2 {
      display: none !important;
    }
  }
`

export function SubMenu({ activeSubMenu, handleClick, items = [], nivel }) {
  if (items.length == 0) return null
  return (
    <ul className={`submenu-${nivel}`} style={{ zIndex: nivel * 10 }}>
      {items.map((item, index) => {
        return (
          <li className={index === activeSubMenu[nivel] ? 'showSub' : ''}>
            <a onClick={() => item.submenu && item.submenu.length > 0 && handleClick(index, nivel)} href={item.url}>
              <span className={item.submenu && item.submenu.length > 0 ? 'set' : ''}>{item.title}</span>
            </a>
            {item.submenu && (
              <SubMenu activeSubMenu={activeSubMenu} handleClick={handleClick} items={item.submenu} nivel={nivel + 1} />
            )}
          </li>
        )
      })}
    </ul>
  )
}

export function SubMenuMobile({ title = '', items = [], nivel }) {
  if (items.length == 0) return null
  return (
    <>
      <label for={`drop-${nivel}`} className='toggle'>
        {title} +
      </label>
      <a href={`#${title}`}>{title}</a>
      <input type='checkbox' id={`drop-${nivel}`} />
      <ul>
        {items.map((item, index) => {
          return (
            <li key={index}>
              {item.submenu && !item.submenu.length && (
                <a href={item.url}>
                  <span className='MuiButton-label'>{item.title}</span>
                </a>
              )}
              {item.submenu && <SubMenuMobile title={item.title} items={item.submenu} nivel={nivel + index} />}
            </li>
          )
        })}
      </ul>
    </>
  )
}

const MenuC = ({ items = [], level = 0, isLoadingMenuData = false, classes = {}, showMenu }) => {
  const [activeMenu, setActiveMenu] = useState('')
  const [activeSubMenu, setActiveSubMenu] = useState({})
  const dataSocialMedias = {
    aborl: [
      { id: 1, size: '16px', color: '#6EC1E4', icon: 'facebook', url: 'https://www.facebook.com/aborlccf' },
      { id: 2, size: '16px', color: '#6EC1E4', icon: 'instagram', url: 'https://www.instagram.com/aborlccf/' },
      { id: 3, size: '16px', color: '#6EC1E4', icon: 'youtube', url: 'https://www.youtube.com/user/AborlCCF' },
      { id: 4, size: '16px', color: '#6EC1E4', icon: 'twitter', url: 'https://twitter.com/aborlccfoficial' },
    ],
    fbg: [
      {
        id: 1,
        size: '16px',
        color: '#4464B0',
        icon: 'facebook',
        url: 'https://web.facebook.com/GastroOficial/?_rdc=1&_rdr',
      },
      { id: 2, size: '16px', color: '#b300ff', icon: 'instagram', url: 'https://www.instagram.com/fbg_gastro/' },
      {
        id: 3,
        size: '16px',
        color: '#EF1D13',
        icon: 'youtube',
        url: 'https://www.youtube.com/c/Federa%C3%A7%C3%A3oBrasileiradeGastroenterologia',
      },
    ],
  }

  const handleClick = (index, level) => {
    setActiveSubMenu((previous) => {
      const newState = { ...previous }

      if (newState.hasOwnProperty(level) && newState[level] === index) {
        delete newState[level]
      } else {
        newState[level] = index
      }

      return newState
    })
  }

  function toggleMenu(index) {
    if (activeMenu === index) {
      setActiveMenu('')
    } else {
      setActiveMenu(index)
    }
  }

  function MenuHtml({ items = [] }) {
    return (
      <>
        <nav id='menu-desktop' className={`${showMenu} nav-menu-desktop`}>
          <div className='nav-menu'>
            <ul className='menu'>
              {items.length == 0 && <span style={{ alignSelf: 'center' }}>...</span>}
              {items.map((item, index) => {
                return (
                  <li className={index === activeMenu ? 'show' : ''}>
                    {item.submenu && item.submenu.length > 0 ? (
                      <>
                        <a
                          href={item.url}
                          onClick={() => toggleMenu(index)}
                          className={item.submenu ? 'parent li-link' : ''}>
                          {item.title}
                        </a>
                      </>
                    ) : (
                      <a
                        className='li-link'
                        style={
                          item?.menu_order === 1
                            ? {
                                padding: '2.5px 10px',
                              }
                            : {}
                        }
                        href={item.url}>
                        {!item.title.includes('fa-home') ? (
                          <div
                            className='dangerouslySetInnerHTML'
                            style={{
                              all: 'inherit',
                              backgroundColor: 'initial',
                              padding: 'initial',
                              border: 'initial',
                            }}
                            dangerouslySetInnerHTML={{ __html: item.title || item.description }}
                          />
                        ) : (
                          <HomeIcon />
                        )}
                      </a>
                    )}
                    {/* SUB1 */}
                    {item.submenu && (
                      <SubMenu activeSubMenu={activeSubMenu} handleClick={handleClick} items={item.submenu} nivel={1} />
                    )}
                  </li>
                )
              })}
            </ul>
            {Object.keys(dataSocialMedias).includes(Config.client) && (
              <div className='nav-social'>
                <SocialIcons client={Config.client} socialMedias={dataSocialMedias} />
              </div>
            )}
          </div>
        </nav>
      </>
    )
  }

  return (
    <>
      {Config.client === 'amib' && (
        <StylesAmib style={{ zIndex: 1 }} client={Config.client}>
          <MenuHtml items={items} level={level} isLoadingMenuData={isLoadingMenuData} classes={classes} />
        </StylesAmib>
      )}

      {Config.client === 'aborl' && (
        <StylesAborl style={{ zIndex: 1 }} client={Config.client}>
          <MenuHtml items={items} level={level} isLoadingMenuData={isLoadingMenuData} classes={classes} />
        </StylesAborl>
      )}

      {Config.client === 'assobrafir' && (
        <StylesAssobrafir client={Config.client}>
          <MenuHtml items={items} level={level} isLoadingMenuData={isLoadingMenuData} classes={classes} />
        </StylesAssobrafir>
      )}

      {(Config.client === 'newcbr' || Config.client === 'cbr') && (
        <StylesNewcbr client={Config.client}>
          <MenuHtml items={items} level={level} isLoadingMenuData={isLoadingMenuData} classes={classes} />
        </StylesNewcbr>
      )}

      {Config.client === 'fbg' && (
        <StylesFbg client={Config.client}>
          <MenuHtml items={items} level={level} isLoadingMenuData={isLoadingMenuData} classes={classes} />
        </StylesFbg>
      )}

      {Config.client === 'iapo' && (
        <StylesIapo client={Config.client}>
          <MenuHtml items={items} level={level} isLoadingMenuData={isLoadingMenuData} classes={classes} />
        </StylesIapo>
      )}

      {Config.client === 'sba' && (
        <StylesSba style={{ flex: 1 }} client={Config.client}>
          <MenuHtml items={items} level={level} isLoadingMenuData={isLoadingMenuData} classes={classes} />
        </StylesSba>
      )}

      {Config.client === 'capacitacao' && (
        <StylesCapacitacao style={{ flex: 1 }} client={Config.client}>
          <MenuHtml items={items} level={level} isLoadingMenuData={isLoadingMenuData} classes={classes} />
        </StylesCapacitacao>
      )}

      {Config.client === 'forl' && (
        <StylesForl client={Config.client}>
          <MenuHtml items={items} level={level} isLoadingMenuData={isLoadingMenuData} classes={classes} />
        </StylesForl>
      )}

      {Config.client === 'sbot' && (
        <StylesSbot style={{ flex: 1 }} client={Config.client}>
          <MenuHtml items={items} level={level} isLoadingMenuData={isLoadingMenuData} classes={classes} />
        </StylesSbot>
      )}

      {Config.client === 'sbpt' && (
        <StylesSbpt client={Config.client}>
          <MenuHtml items={items} level={level} isLoadingMenuData={isLoadingMenuData} classes={classes} />
        </StylesSbpt>
      )}

      {Config.client === 'aboce' && (
        <StylesAboce client={Config.client}>
          <MenuHtml items={items} level={level} isLoadingMenuData={isLoadingMenuData} classes={classes} />
        </StylesAboce>
      )}
    </>
  )
}
export default React.memo(MenuC)
