import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  hidden: {
    display: 'none !important',
  },
  buttonArrowToTop: {
    display: 'flex',
    position: 'fixed',
    zIndex: 100,
    width: 32,
    height: 32,
    background: '#ffffff90',
    borderRadius: '50%',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: 60,
    right: 40,
    cursor: 'pointer',

    '& .MuiSvgIcon-root': {
      color: '#000',
    },

    '&:before, &:after': {
      content: '""',
      width: 28,
      height: 28,
      position: 'absolute',
      border: '2px solid #dfdfdf',
      borderRadius: '50%',
      animation: '$pulse 2s linear infinite',
      transformOrigin: 'center',
    },

    '&:before': {
      transform: 'scale(0.8)',
    },

    '&:after': {
      animationDelay: '1s',
      transform: 'scale(0.5)',
    },
  },

  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.8)',
      opacity: 0.6,
    },
    '50%': {
      transform: 'scale(1.5)',
      opacity: 0.3,
    },
    '100%': {
      transform: 'scale(0.8)',
      opacity: 0.6,
    },
  },
}))

export default useStyles
