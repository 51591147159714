import MODULE_NAME from "./constants";
import { utils } from "../fetch";

export const SUBSCRIPTION = utils.buildActions(MODULE_NAME, "SUBSCRIPTION");

export const SET_SUBSCRIPTION = `${MODULE_NAME}/SET_SUBSCRIPTION`;

export const SET_TRANSMISSIONACTIVITY = `${MODULE_NAME}/SET_TRANSMISSIONACTIVITY`;

export const RESET_INITIALSTATE = `${MODULE_NAME}/RESET_INITIALSTATE`;

export const setSubscription = (data) => ({
  type: SET_SUBSCRIPTION,
  payload: { subscription: data, id: data.id },
});

export const setTransmissionActivity = (data) => ({
  type: SET_TRANSMISSIONACTIVITY,
  payload: { data },
});

export const resetInitialstate = () => ({
  type: RESET_INITIALSTATE,
});
