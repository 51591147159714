import { Button, Grid } from "@material-ui/core";
import ImageMagnifier from "../ImageMagnifier/ImageMagnifier";
import { Translator } from "@itarget/shared";
import ProductSubtitle from "../ProductSubtitle";

const CardOffersStore = ({ card, text, handleClick }) => {
  const textoReduzidoParaExibicao = text && `${text.substr(0, 120)}...`;
  return (
    <Grid container spacing={2} className="cardOffersStore-container">
      <Grid item xs={12} sm={5} md={6} className="cardOffersStore-image">
        <ImageMagnifier
          src={card?.imagem_detalhe}
          alt={card?.desc_aga}
          width="auto"
          height="auto"
          magnifierHeight="165"
          magnifieWidth="165"
          zoomLevel="1.8"
        />
      </Grid>
      <Grid item xs={12} sm={7} md={6} className="cardOffersStore-description">
        <ProductSubtitle text={card?.desc_aga} width="30%" />
        <div className="cardOffersStore-information">
          <div
            className="cardOffersStore-text"
            dangerouslySetInnerHTML={{
              __html: textoReduzidoParaExibicao,
            }}
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              fontWeight: "800",
              fontSize: "0.95rem",
            }}
          />
          <Button
            onClick={() => handleClick()}
            variant="contained"
            className="cardOffersStore-button"
          >
            <Translator transRef="translation.MAIS INFORMAÇÕES" />
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default CardOffersStore;
