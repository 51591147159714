import { Typography, makeStyles } from '@material-ui/core'
import MenuC from '../../MenuC'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Trailing } from './Trailing'
import MenuSingle from '../../MenuSingle'
import { useWindowDimensions } from '@itarget/shared'
import MobileHeader from '../../../MobileHeader'

const useStyles = makeStyles((theme) => ({
  light: {
    '& $trailing': {
      '& .shoppingCartIcon': {
        color: '#000000d3 !important',
      },
      '& .userProfileInformationContainer .userProfileInformationName, & .userProfileInformationContainer .MuiTypography-colorTextSecondary':
        {
          color: '#000000d3 !important',
        },
    },
    '& .li-link': {
      color: '#888888 !important',
    },
    '& .li-link:hover': {
      color: '#1D85C6 !important',
    },
  },
  dark: {
    '& .li-link': {
      color: '#FFFFFF',
    },
  },
  logged: {
    '& #menu-desktop': {
      top: '185px !important',
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& .shoppingCartIcon, & .userProfileInformationName': {
      color: '#ffffff',
    },
    '& .MuiTypography-colorTextSecondary': {
      color: '#fffffff3',
    },
  },
  iconcart: {
    width: '32px',
    height: '32px',
  },
  container: {
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 100,
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 500px)': {
      position: 'unset',
    },
  },
  header: {
    height: 'fit-content',
    display: 'flex',
    padding: '0 15px',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '@media (max-width: 740px)': {
      marginTop: '1%',
    },
    '@media (max-width: 500px)': {
      flexDirection: 'column',
    },
  },
  logo: {
    zIndex: 1,
    cursor: 'pointer',
    maxHeight: '80px',
  },
  trailingLogged: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: theme.spacing(1.5, 0),
    '& .user-information-block': {
      color: 'inherit',
    },
  },
  trailing: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  trailingMobile: {
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 740px)': {
      padding: '0',
      display: 'flex',
    },
  },
  leading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '@media (max-width: 740px)': {
      padding: '0',
    },
  },
  menuItem: {
    textTransform: 'uppercase',
  },
  menuButton: {
    zIndex: 4,
    '& .material-icons': {
      color: '#fff',
    },
    '@media (min-width: 960px)': {
      display: 'none',
    },
  },
  logoContainer: {
    gap: theme.spacing(1.25),
    alignItems: 'center',
    display: 'flex',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: theme.typography.pxToRem(26),
    fontWeight: 'bold',
    color: '#149EDA',
    lineHeight: 1,
    maxWidth: 180,
  },
  titleDescription: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 'bold',
    color: '#149EDA',
    lineHeight: 1,
    maxWidth: 180,
  },
  covenantContainer: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  covenant: {
    fontSize: theme.typography.pxToRem(10),
    color: '#149EDA',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

const STATIC = {
  sbpt: {
    image: 'https://sbpt.org.br/portal/wp-content/themes/portal_icase_novo/images/logo-sbpt.png',
    alt: 'Sociedade Brasileira de Pneumologia e Tisiologia',
    link: 'https://sbpt.org.br/portal/',
  },
  jbp: {
    image: 'https://sbpt.org.br/portal/wp-content/themes/portal_icase_novo/images/logo-jbp.png',
    alt: 'Jornal Brasileiro de Pneumologia',
    link: 'http://www.jornaldepneumologia.com.br/',
  },
  ers: {
    image: 'https://sbpt.org.br/portal/wp-content/themes/portal_icase_novo/images/logo-ers.png',
    alt: 'EUROPEAN RESPIRATORY SOCIETY',
    link: 'https://www.ersnet.org/',
  },
}

const Header = ({ shoppingCarts, menuItemsC = [], isLoadingMenuData, user, goTo, onLogout, statusAssociated, category }) => {
  const MAX_MENU_SIZE_DESKTOP = 960
  const { width: windowDimensions } = useWindowDimensions()
  const [mobile, setMobile] = useState(false)

  useEffect(() => {
    let mobile = windowDimensions <= MAX_MENU_SIZE_DESKTOP
    mobile ? setMobile(true) : setMobile(false)
  }, [windowDimensions])

  const [imageBackground, setImageBackground] = useState(null)
  const systemTheme = useSelector((state) => state.theme.systemTheme)
  const settingCourse = useSelector((state) => state.setting.settingCommerce)

  let backgroundImageColors = {
    top: systemTheme == 'light' ? '#fafafa00' : '#1C1C1C00',
    bottom: systemTheme == 'light' ? '#fafafafd' : '#1C1C1Cff',
  }

  useEffect(() => {
    if (settingCourse.url_image_topo_background) setImageBackground(settingCourse.url_image_topo_background)
  }, [settingCourse])

  const styles = useStyles({
    backgroundImageColors,
    imageBackground,
    mobile: mobile,
    theme: systemTheme,
  })

  return (
    <>
      <header className={`${styles.root} ${styles[systemTheme]} ${user?.data?.id ? styles.logged : ''}`}>
        {!mobile && (
          <>
            <div className={styles.container}>
              <div className={styles.header}>
                <div className={styles.leading}>
                  <div className={styles.logoContainer}>
                    <img
                      alt={STATIC.sbpt.alt}
                      src={STATIC.sbpt.image}
                      className={`${styles.logo}`}
                      onClick={() => (window.location = STATIC.sbpt.link)}
                    />
                    <div className={styles.titleContainer}>
                      <Typography className={styles.title}>SBPT</Typography>
                      <Typography className={styles.titleDescription}>Sociedade Brasileira de Pneumologia e Tisiologia</Typography>
                    </div>
                  </div>
                  <img
                    alt={STATIC.jbp.alt}
                    src={STATIC.jbp.image}
                    className={`${styles.logo}`}
                    onClick={() => (window.location = STATIC.jbp.link)}
                  />
                  <div
                    className={styles.covenantContainer}
                    onClick={() => (window.location = STATIC.ers.link)}>
                    <Typography className={styles.covenant}>Convênio:</Typography>
                    <img
                      alt={STATIC.ers.alt}
                      src={STATIC.ers.image}
                      className={`${styles.logo}`}
                    />
                  </div>
                </div>
                <img
                  onClick={() => (window.location = 'https://sbpt.org.br/portal/coronavirus/')}
                  className={`${styles.logo}`}
                  alt={'EUROPEAN RESPIRATORY SOCIETY'}
                  src={'https://d1xe7tfg0uwul9.cloudfront.net/sbpt-portal/wp-content/uploads/2024/07/10172743/sbpt-covid-19-3-2.gif'}
                />
                <div className={styles.trailing}>
                  <Trailing
                    shoppingCarts={shoppingCarts}
                    category={category}
                    goTo={goTo}
                    onLogout={onLogout}
                    statusAssociated={statusAssociated}
                    styles={styles}
                    user={user}
                  />
                </div>
              </div>
            </div>
            <MenuC
              items={menuItemsC}
              isLoadingMenuData={isLoadingMenuData}
              classes={{ root: styles.menu, itemRoot: styles.menuItemRoot }}
            />
            <MenuSingle disableCustomMenu={true} />
          </>
        )}
      </header>
      {mobile && <MobileHeader statusAssociated={statusAssociated} />}
    </>
  )
}

export default Header
