import { CardMedia, Typography } from "@material-ui/core";
import {Translator} from '@itarget/shared'
import "./style.scss";

const CardDetailStore = ({ row, carousel, selectOffer, direction = "row" }) => {
  return (
    <div className="cardDetailStore-container" onClick={() => (!carousel ? selectOffer && selectOffer(row && row) : selectOffer(row && row))}>
      <CardMedia className="cardDetailStore-image" image={carousel ? row.thumb : row.imagem_lista || row.imagem_capa_portal} title={row?.desc_atv}>
        <div></div>
      </CardMedia>
      <div
        className="cardDetailStore-detail"
        style={{
          flexDirection: direction,
        }}
      >
        <Typography
          className="cardDetailStore-title"
          style={{
            maxWidth: direction === "row" ? "fit-content" : "100%",
          }}
          variant="body1"
        >
          {row?.desc_atv}
        </Typography>
        <div
          className="cardDetailStore-prices"
          style={{
            alignItems: direction === "row" ? "flex-start" : "center",
          }}
        >
          <div
            className="cardDetailStore-pricesAssociated"
            style={{
              alignItems: direction === "row" ? "flex-start" : "center",
            }}
          >
            <Typography className="cardDetailStore-associatedPrice" variant="body4">
              <Translator transRef="translation.Saiba mais" />
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDetailStore;
