import { ChangeEventHandler, MutableRefObject, ChangeEvent, useRef, useState } from 'react'
import { useQueryParams, useWindowDimensions } from '@itarget/shared'

const useAction = () => {
  const BREAKPOINT_MD = 960
  const [displayAdvanced, setDisplayAdvanced] = useState(false)
  const { width } = useWindowDimensions()
  const { get, set, setMultiples } = useQueryParams()
  const selected = {
    search: get('search'),
    startAt: get('startAt'),
    endAt: get('endAt'),
    tagId: get('tagId'),
    roomId: get('roomId'),
  }
  let DEFAULT_SERIES = [
    { param: 'startAt', value: '' },
    { param: 'endAt', value: '' },
    { param: 'tagId', value: 0 },
    { param: 'roomId', value: 0 },
  ]

  const searchValueTimeout = useRef(null)

  const handleSearchField = (event) => {
    let searchValue = event.target.value
    let lastTimeout = searchValueTimeout.current
    let searchTimeout = setTimeout(() => set({ param: 'search', value: searchValue }), 700)
    if (lastTimeout) clearTimeout(lastTimeout)
    searchValueTimeout.current = searchTimeout
  }

  const handleStartAt = (event) => {
    let startAtValue = event.target.value
    set({ param: 'startAt', value: startAtValue })
  }

  const handleEndAt = (event) => {
    let endAtValue = event.target.value
    set({ param: 'endAt', value: endAtValue })
  }

  const handleTagField = (event) => {
    let tagId = event.target.value
    set({ param: 'tagId', value: tagId })
  }

  const handleRoomField = (event) => {
    let roomId = event.target.value
    set({ param: 'roomId', value: roomId })
  }

  const handleCleanFields = () => {
    setMultiples({ series: DEFAULT_SERIES })
  }

  const handleAdvancedFilters = () => {
    setDisplayAdvanced((previous) => !previous)
  }

  let displayCleanButton = selected.startAt || selected.endAt || selected.tagId || selected.roomId

  let displayAdvancedFilters = width >= BREAKPOINT_MD || displayAdvanced

  return {
    selected,
    handleSearchField,
    handleStartAt,
    handleEndAt,
    handleTagField,
    handleRoomField,
    handleCleanFields,
    handleAdvancedFilters,
    displayCleanButton,
    displayAdvancedFilters,
  }
}

export default useAction
