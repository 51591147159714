import './style.scss'
import { Grid, Typography, Icon, Checkbox, IconButton, Box } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { formatMoney, Translator } from '@itarget/shared'
import { dateFormatTranslation } from '@itarget/shared/src/utils/format/date'
import TypographyValue from '../../components/Layout/Typography/TypographyValue'
import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    backgroundColor: (props) => (props.theme == 'light' ? '#fff' : '#232323'),
    boxShadow: theme.shadows[1],
  },
  valueTitle: {
    fontSize: '20px !important',
  },
  image: {
    aspectRatio: 1,
    width: '100%',
  },
}))

const CardToPayment = ({ card, onChange, openModal, setting, activityChecked, courses }) => {
  const systemTheme = useSelector((state) => state.theme.systemTheme)
  const styles = useStyles({ theme: systemTheme })
  const selectedLang = useSelector((state) => state.language.lang)
  if (!card) return <></>
  const cardAnyDesc = card.descricao.split(',')
  const wrappedCardText = cardAnyDesc.map((part, index) => (
    <React.Fragment key={index}>
      {part.trim()}
      {index !== cardAnyDesc.length - 1 && <br />}
    </React.Fragment>
  ))
  return (
    <Grid
      container
      className={`cardToPayment-container ${styles.cardContainer}`}
      key={card.conta_receber_id}>
      <Grid
        item
        alignItems='center'
        xs={2}>
        {!card.pacote_centro_custo_id && card.thumb && (
          <img
            className={styles.image}
            alt={`thumb do ${card.descricao}`}
            src={card.thumb}
          />
        )}
      </Grid>
      <Grid
        item
        xs={10}
        className='grid-infos'>
        {card.esta_vencido === 'S' ? (
          <Grid
            className={'ribbon'}
            style={{ float: 'right' }}>
            <Translator transRef='translation.Vencido' />
          </Grid>
        ) : (
          <div style={{ float: 'right' }}>
            <Checkbox
              onChange={(e) => onChange(e, card)}
              checked={activityChecked.includes(card.conta_receber_id) && validatorChecked()}
              className='checkbox'
            />
          </div>
        )}
        <Box
          display='flex'
          flexDirection='column'>
          <Typography variant='caption'>{wrappedCardText}</Typography>
        </Box>
        <TypographyValue className={styles.valueTitle} value={formatMoney.toReal(card.valor)} />
        <Box
          marginTop={1}
          className='more-infos'>
          {card?.exibir_programacao_horario === 'S' && (
            <Grid item>
              <Typography variant='subtitle2'>
                <Translator transRef='translation.Vencimento:' />{' '}
                {dateFormatTranslation(selectedLang, card.data_vencimento ? card.data_vencimento : card.data_fim_aga)}
              </Typography>
            </Grid>
          )}
          <Grid
            container
            alignItems='center'
            justify='flex-end'>
            {!(
              setting?.data?.filter((s) => s.codigo == 404).filter((f) => f.ativo == 'S').length > 0 &&
              card.categoria_tipo_atividade === 1
            ) && (
              <IconButton onClick={() => openModal(card)}>
                <Icon>close</Icon>
              </IconButton>
            )}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )

  function validatorChecked() {
    return (
      card.pre_agenda_atividades?.length < 0 ||
      card.pre_agenda_atividades.every((pre_agenda) =>
        activityChecked.includes(
          courses.find((course) => course.agenda_atividade_id === pre_agenda.agenda_atividade_pre_id)?.conta_receber_id
        )
      ) ||
      !card.pre_agenda_atividades.every((pre_atividade) =>
        courses.some((course) => course.agenda_atividade_id === pre_atividade.agenda_atividade_pre_id)
      )
    )
  }
}
export default CardToPayment
