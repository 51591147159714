import { Box, Typography } from '@material-ui/core'
import { useReferenceTranslation } from './translation'
import { useReferenceStyles } from './styles'

const Reference = ({ category = '', situation = '' }) => {
  const styles = useReferenceStyles()
  const translation = useReferenceTranslation()
  if (!category) return <></>
  return (
    <Box className={styles.container}>
      <Box className={styles.referenceContainer}>
        <Typography className={styles.referenceLabel}>
          {translation.category}{' '}
          <strong>
            {category}
            {situation ? ',' : '.'}
          </strong>
        </Typography>
      </Box>
      {situation && (
        <Box className={styles.referenceContainer}>
          <Typography className={styles.referenceLabel}>
            {translation.situation} <strong>{situation}.</strong>
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default Reference
