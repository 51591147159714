import { Grid } from '@material-ui/core'
import { useSelector } from 'react-redux'
import './style.scss'
import { useEffect, useRef } from 'react'

export const ModalFullScreen = ({ children }) => {
  const modalRef = useRef(null)
  const systemTheme = useSelector((state) => state.theme.systemTheme)

  useEffect(() => {
    let modalCurrent = modalRef.current

    const handleMouseMove = (event) => {
      event.stopPropagation() // Impede a propagação do evento para os elementos abaixo
    }

    if (modalCurrent) {
      modalCurrent.addEventListener('mousemove', handleMouseMove)
    }

    return () => {
      if (modalCurrent) {
        modalCurrent.removeEventListener('mousemove', handleMouseMove)
      }
    }
  }, [modalRef])

  return (
    <Grid
      ref={modalRef}
      container
      className={`grid-modalFullScreen grid-modalFullScreen-${systemTheme}`}>
      {children}
    </Grid>
  )
}
