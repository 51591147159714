import { useState, useEffect, useMemo, useRef } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import "./style.scss"
import { Box, Container, Grid } from "@material-ui/core"
import { useFetchApi, Config, useRedirect, configurator } from "@itarget/shared"
import { auth, offers, auth as authRedux } from "../../redux"
import Header from "../Header"
import Footer from "../Footer/Footer"

const ListLayout = ({ children }) => {
  const { current: windowWidth } = useRef(window.innerWidth)
  const { goTo } = useRedirect()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const user = useSelector((state) => state.auth.user)
  const shoppingCarts = useSelector((state) => state.offers.shoppingCarts)
  const center_cost_id = useSelector((state) => state.auth.cc)
  const selectedLang = useSelector((state) => state.language.lang)
  const settingCommerce = useSelector((state) => state.setting.settingCommerce)
  const [menuItemsC, setMenuItemsC] = useState([])

  const backgroundHeader = useMemo(
    () =>
      windowWidth <= 425 && settingCommerce?.url_image_topo_background_mobile
        ? settingCommerce?.url_image_topo_background_mobile
        : settingCommerce?.url_image_topo_background,
    [windowWidth, settingCommerce?.url_image_topo_background_mobile, settingCommerce?.url_image_topo_background]
  )

  const {
    data: menuDataF,
    fetchData: fetchMenuDataF,
    isLoading: isLoadingMenuDataF,
  } = useFetchApi(Config.baseURL + `api/${selectedLang}/template/header/all/menu?centro_custo_id=${center_cost_id}`)

  const { data: setting, fetchData: fetchSetting } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/setting?centro_custo_id=${center_cost_id}&${configurator.LOGIN}`
  )

  const onLogout = () => {
    dispatch(auth.actions.logout())
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { data: inscricoesApi, fetchData: fetchInscricoes } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/subscription/pending?pessoa_id=${user.data && user.data.id}&origem_inscricao=5`
  )

  const { data: statusAssociated, fetchData: fecthStatusAssociated } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/association/status-associated?associationId=${user.data && user.data.associacao_id}&entityId=1`
  )

  const { data: category, fetchData: fecthCategory } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/center-cost-person/category-person?person_id=${user.data && user.data.id}&center_cost_id=${
        routeParams?.cc ? routeParams.cc : center_cost_id
      }`
  )

  useEffect(() => {
    fetchSetting()
    fetchMenuDataF()
  }, [fetchMenuDataF, fetchSetting])

  useEffect(() => {
    if (user.data) {
      fetchInscricoes()
      fecthStatusAssociated()
      fecthCategory()
    }
  }, [user, fecthCategory, fecthStatusAssociated, fetchInscricoes])

  useEffect(() => {
    if (inscricoesApi?.data) {
      dispatch(offers.actions.setShoppingCarts(inscricoesApi.data.filter((res) => res.controle_boleto_cartao_id === null)))
    }
  }, [dispatch, inscricoesApi])

  useEffect(() => {
    if (routeParams?.cc) {
      dispatch(auth.actions.setCenterCost(routeParams.cc))
    }
  }, [routeParams?.cc])

  useEffect(() => {
    if (menuDataF) {
      if (!menuDataF.menu_principal?.length) {
        return
      }
      let lis = []
      menuDataF.menu_principal.map((item, curr) => {
        return (lis[item.menu_order] = item)
      })
      setMenuItemsC(lis)
    }
  }, [menuDataF])

  return (
    <div className="layout-container">
      <Grid className="topo-full" item={true} md={12}>
        <div className="layout-fixed">
          <Header
            statusAssociated={statusAssociated}
            category={category?.data?.descricao}
            user={user}
            client={settingCommerce?.texto_aba_navegador}
            logo={settingCommerce?.url_image_topo}
            backgroundHeader={backgroundHeader}
            shoppingCarts={shoppingCarts}
            menuItemsC={menuItemsC}
            goTo={goTo}
            onLogout={onLogout}
            isLoadingMenuData={isLoadingMenuDataF}
            center_cost_id={routeParams?.cc ? routeParams.cc : center_cost_id}
            anchorEl={anchorEl}
            setting={setting}
            handleClick={handleClick}
            handleClose={handleClose}
          />
        </div>
      </Grid>
      <Box
        className='list-container body-layout-container'
        p={3}>
        <Container
          maxWidth='xl'
          className='body-layout'
          spacing={2}>
          {children}
        </Container>
      </Box>
      <Footer />
    </div>
  )
}

export default ListLayout
