import { useSelector } from "react-redux";
import { formatString, formatMoney } from "@itarget/shared";
import { Typography, Grid, Icon, Modal, Divider, IconButton } from "@material-ui/core";
import { useEffect, useState } from "react";

const ModalAnnuityDescribe = ({ open, handleClose, annuity }) => {
  const user = useSelector((state) => state.auth.user);
  const systemTheme = useSelector((state) => state.theme.systemTheme)
  const [annuities, setAnnuities] = useState([]);
  const [total, setTotal] = useState(0)

  useEffect(()=>{
    if(annuity?.data) setAnnuities(annuity.data);
  },[annuity])

  useEffect(() => {
    const newTotal = annuities.map(r=> parseFloat(r.conta_receber_valor)).reduce(function (total, numero) {
      if (numero) {
        return total + numero;
      }
      return total;
    }, 0);
    setTotal(newTotal);
  }, [annuities]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={`modal-detail-container modal-detail-container-${systemTheme}`}
    >
      <Grid className="modal-detail-annuity">
        <Grid className="left-side">
          <Grid className="modal-detail-header">
            <Grid className="modal-detail-header-left">
              <Typography variant="h6" className="text-gray">
                {user?.data?.nome && formatString.toLowerCase(user.data.nome)}
              </Typography>
              <Typography variant="caption" className="text-gray">
                {user?.data?.categoria_centro_custo_descricao && formatString.toLowerCase(user.data.categoria_centro_custo_descricao)}
              </Typography>
            </Grid>
            <Grid className="modal-detail-header-right">
              <IconButton onClick={handleClose} color="default">
                <Icon> cancel </Icon>
              </IconButton>
            </Grid>
          </Grid>
          <Divider />
          <Grid className="modal-detail-body">
            {annuities.map((row, index) => (
              <Grid key={index} className="modal-detail-body-item">
                <Typography className="modal-detail-body-item-text-left">{formatString.toLowerCase(row.agenda_atividades_descricao)}</Typography>
                <Typography>{row?.conta_receber_valor && "R$ " + formatMoney.toReal(row.conta_receber_valor)}</Typography>
              </Grid>
            ))}
            <Grid className='modal-detail-body-total'>
              {"Total: R$" + formatMoney.toReal(total)}
            </Grid>
          </Grid>
        </Grid>
        <Grid className="border-red" />
      </Grid>
    </Modal>
  );
};

export default ModalAnnuityDescribe;
