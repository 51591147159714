import { TextField, Link, Typography, makeStyles, Grid } from '@material-ui/core'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useFetchApi, Config, configurator, Translator, Button } from '@itarget/shared'
import { auth } from '../../redux'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  textFieldRoot: {
    textTransform: 'capitalize',
    flex: 1,
    '& input': {
      padding: theme.spacing(1),
    },
    '& label': {
      transform: `translate(${theme.spacing(1.5)}px, ${theme.spacing(1.5)}px) scale(1)`,
    },
  },
  title: {
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase',
    fontSize: '0.9em',
  },
  link: {
    color: 'inherit',
    textDecoration: 'underline',
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
}))

const LoginForm = () => {
  const [typeLoginDesc, setTypeLoginDesc] = useState('Login')
  const { cc } = useParams()
  const dispatch = useDispatch()
  const selectedLang = useSelector((state) => state.language.lang)
  const styles = useStyles()
  const settingCourse = useSelector((state) => state.setting.settingCommerce)
  const centerCostId = useSelector((state) => state.auth.cc)
  let selectedCenterCostId = cc || centerCostId

  const { data: setting, fetchData: fetchSetting } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/setting?centro_custo_id=${Number(selectedCenterCostId)}&${configurator.LOGIN}`
  )

  const onSubmit = async (values) => {
    dispatch({
      type: 'FETCH_STARTED',
      meta: {
        actions: auth.actions.LOGIN,
        body: JSON.stringify(values),
        url: Config.baseURL + `api/${selectedLang}/login`,
        method: 'POST',
      },
    })
  }

  const { handleChange, handleSubmit } = useFormik({
    initialValues: {
      login: '',
      senha: '',
      acao_id: 28,
      centro_custo_id: Number(selectedCenterCostId),
    },
    enableReinitialize: true,
    onSubmit,
  })

  const translation = {
    email: Translator({ transRef: 'translation.Email' }),
    password: Translator({ transRef: 'translation.Senha' }),
  }

  useEffect(() => {
    fetchSetting()
  }, [fetchSetting])

  useEffect(() => {
    if (setting?.data) {
      let loginDesc = setting.data.find((setting) => setting?.codigo == 223)?.valor_referencia
      if (loginDesc === 'email_cpf') {
        loginDesc = 'Email/CPF'
      }
      loginDesc && setTypeLoginDesc(loginDesc)
    }
  }, [setting])

  let textFieldClasses = {
    root: styles.textFieldRoot,
  }

  return (
    <div>
      <Typography className={styles.title}>
        <Translator transRef='translation.Login do participante' />
      </Typography>
      <form
        className={styles.root}
        onSubmit={handleSubmit}>
        <Grid
          direction='column'
          container
          spacing={1}>
          <Grid item>
            <TextField
              variant='outlined'
              label={typeLoginDesc}
              name='login'
              onChange={handleChange}
              required={true}
              classes={textFieldClasses}
            />
          </Grid>
          <Grid item>
            <TextField
              type='password'
              variant='outlined'
              label={translation?.password}
              name='senha'
              onChange={handleChange}
              classes={textFieldClasses}
              required={true}
            />
          </Grid>
          <Grid item container>
            <Button
              variant='contained'
              type='submit'
              color='primary'>
              <Translator transRef='translation.Entrar' />
            </Button>
          </Grid>
        </Grid>
        <div className={styles.actions}>
          <Link
            className={styles.link}
            href={`/recovery-login${settingCourse?.centro_custo_padrao ? `/${selectedCenterCostId}` : ''}`}>
            <Translator transRef='translation.Esqueci minha senha' />
          </Link>
          <Link
            className={styles.link}
            href={`/login${settingCourse?.centro_custo_padrao ? `/${selectedCenterCostId}` : '/'}?state=register`}>
            <Translator transRef='translation.Cadastre-se' />
          </Link>
        </div>
      </form>
    </div>
  )
}

export default LoginForm
