import React from 'react'
import './style.scss'
import {
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormGroup,
  FormControl,
  FormControlLabel,
  DialogActions,
  DialogContentText,
} from '@material-ui/core'

import { Translator, Button } from '@itarget/shared'

import './style.scss'

const RecoveryPassword = ({ passwords, handleSubmit, handlePasswordChange, onCloseModal, onOpenModal, openModal }) => {
  const translation = {
    currentPassword: Translator({ transRef: 'translation.Senha atual' }),
    newPassword: Translator({ transRef: 'translation.Nova senha' }),
    confirmNewPassword: Translator({ transRef: 'translation.Confirmar nova senha' }),
  }

  return (
    <Grid
      item
      container
      className='grid-changePassword'>
      <form
        onSubmit={handleSubmit}
        className='form'>
        <Grid
          container
          item
          direction='column'
          spacing={2}>
          <Grid
            item
            container>
            <TextField
              type='password'
              name='currentPassword'
              autoComplete='current-password'
              value={passwords.currentPassword}
              onChange={handlePasswordChange}
              className='text-field'
              label={translation?.currentPassword}
              required
            />
          </Grid>
          <Grid
            item
            container>
            <TextField
              type='password'
              name='newPassword'
              autoComplete='new-password'
              value={passwords.newPassword}
              onChange={handlePasswordChange}
              className='text-field'
              label={translation?.newPassword}
              required
            />
          </Grid>
          <Grid
            item
            container>
            <TextField
              type='password'
              name='confirmPassword'
              autoComplete='confirm-password'
              value={passwords.confirmPassword}
              onChange={handlePasswordChange}
              className='text-field'
              label={translation?.confirmNewPassword}
              required
            />
          </Grid>
          <Grid
            item
            container
            xs={12}>
            <Button
              variant='contained'
              color="secondary"
              onClick={onOpenModal}
              className='resetPassword-btn'>
              <Typography>
                <Translator transRef='translation.Redefinir senha' />
              </Typography>
            </Button>
          </Grid>
        </Grid>

        <Grid>
          <Dialog
            open={openModal}
            onClose={onCloseModal}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'>
            <DialogTitle id='alert-dialog-title'>
              <Translator transRef='translation.Alteração de senha.' />
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                <Translator transRef='translation.Deseja realmente alterar sua senha?' />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={onCloseModal}
                color='default'>
                <Translator transRef='translation.Cancelar' />
              </Button>
              <Button
                type='submit'
                onClick={handleSubmit}
                color='secondary'>
                <Translator transRef='translation.Redefinir senha' />
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </form>
    </Grid>
  )
}

export default RecoveryPassword
