import { Grid, Typography, Container, Paper, IconButton, Icon } from "@material-ui/core"
import "./style.scss"

import { formatMoney, Translator } from "@itarget/shared"

const FinishPayment = ({ incList, vlTotal }) => {
  return (
    <Container className={"containerFinished"} maxWidth="xl">
      <Grid container className={"grid"} spacing={4}>
        <Grid item xs={12} className={"gridButtons"}>
          <Grid container>
            <Grid item xs={9}>
              <Typography className={"textHeader"} gutterBottom>
                <Translator transRef="translation.Compra realizada com sucesso!" />
              </Typography>
            </Grid>
            <Grid item xs={3} className={"actions"}>
              <IconButton
                className={"btnSaveFinished"}
               
                aria-label="add to shopping cart"
                onClick={() => window.print()}
              >
                <Icon className={"btnSaveIcon"}>print</Icon>
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className={"gridRecibo"}>
          <Paper className={"headerRecibo"}>
            <Grid container>
              <Grid item xs={9}>
                <Grid container xs>
                  <Grid item className={"infoHeader"}>
                    {incList && (
                      <>
                        <Typography gutterBottom>
                          {`${incList[0].e_endereco}, ${incList[0].e_numero}, ${incList[0].e_complemento}, ${incList[0].e_cep}, `}
                          {`${incList[0].e_municipio} - ${incList[0].e_uf}`}
                        </Typography>
                        <Typography gutterBottom>{incList[0].e_fone}</Typography>
                        <Typography gutterBottom>{incList[0].e_cnpj}</Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Typography className={"textFatura"} gutterBottom>
                  <Translator transRef="translation.FATURA" />
                </Typography>
                {incList && (
                  <Grid container xs>
                    <Grid item className={"infoHeader"}>
                      <Typography gutterBottom>{incList[0].num_nosso_numero}</Typography>
                      <Typography gutterBottom>{incList[0].f_cr_data_pagamento}</Typography>
                      <Typography gutterBottom>{incList[0].recibo_numero}</Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>
          <Paper className={"dadosRecibo"}>
            {incList && (
              <Grid item className={"infoDados"}>
                <Typography className={"infoNome"} gutterBottom>
                  {incList[0].r_recebemos_de}
                </Typography>
                <Typography gutterBottom>{incList[0].pessoa_cpf}</Typography>
              </Grid>
            )}
          </Paper>
          <Paper className={"descRecibo"}>
            <Grid container>
              <Grid container className={"descReciboHeader"}>
                <Grid item xs={9}>
                  <Typography gutterBottom>
                    <Translator transRef="translation.Descrição" />
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={"vlDesc"} gutterBottom>
                    <Translator transRef="translation.Valor" />
                  </Typography>
                </Grid>
              </Grid>
              {incList &&
                incList.map((row, i) => (
                  <Grid container className={"descReciboBody"} key={i}>
                    <Grid item xs={9}>
                      {row.agenda_atividade_idioma_descricao}
                    </Grid>
                    <Grid item xs={3} className={"vlDesc"}>
                      {formatMoney.toReal(row.r_a_importancia_de)}
                    </Grid>
                  </Grid>
                ))}
              {incList ? (
                <Grid container>
                  <Grid item xs={9}>
                    <></>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className={"txtVlTotal"} gutterBottom>
                      <Translator transRef="translation.TOTAL" />
                    </Typography>
                    <Typography className={"vlTotal"} gutterBottom>
                      R$ {formatMoney.toReal(vlTotal)}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
            <Typography className={"txtFooter"} gutterBottom>
              <Translator transRef="translation.Agradecemos por sua aquisição" />
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

export default FinishPayment
