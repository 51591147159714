import * as actions from "./actions";
import produce from "immer";

const initialState = {
  token: "",
};
export const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case actions.SET_TOKEN:
      state.token = action.payload.token;
      break;
    default:
      break;
  }
}, initialState);
