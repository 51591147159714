import { useEffect } from "react";
import RedirectView from "./views/RedirectView";
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import mobile from "../../utils/mobile";
import { Config } from "@itarget/shared";
const RedirectContainer = () => {
  const routeParams = useParams();
  const settingCommerce = useSelector((state) => state.setting.settingCommerce);

  useEffect(() => {
    if (Config.client == 'fbg') {
      // if (mobile.iOS()) {
      //   window.location = 'https://fbgcursos.icongressolive.itarget.com.br/mobile';
      //   return;
      // }
      // if (mobile.android()) {
      //   window.location = 'https://fbgcursos.icongressolive.itarget.com.br/mobile';
      //   return;
      // }
      window.location = '/offers-unique/' + routeParams.cc
    }
  }, [])

  return <RedirectView />;
};

export default RedirectContainer;
