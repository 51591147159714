import { Box, Grid, Typography } from '@material-ui/core'
import { formatMoney, Translator } from '@itarget/shared'
import { makeStyles } from '@material-ui/styles'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  selectedItemsContainerStyle: {
    backgroundColor: (props) => (props.theme == 'light' ? '#f4f4f4' : '#2c2c2c'),
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1, 1.5),
    [theme.breakpoints.up(theme.breakpoints.values.xs)]: {
      flexWrap: 'wrap',
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      flexWrap: 'nowrap',
    },
  },
  selectedItemStyle: {
    [theme.breakpoints.up(theme.breakpoints.values.xs)]: {
      width: '100%',
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      width: 'auto',
    },
  },
  taxedValue: {
    textDecoration: 'line-through',
    color: (props) => `${theme.palette.warning[props.theme == 'light' ? 'dark' : 'light']} !important`,
    fontSize: '14px',
    lineHeight: 'initial',
  },
  value: {
    lineHeight: 'initial',
    fontSize: '20px',
  },
}))

const ListItensCart = ({ selected, subscriptionComboApi }) => {
  const systemTheme = useSelector((state) => state.theme.systemTheme)
  const styles = useStyles({ theme: systemTheme })
  const valueVoucher = (value, discountType, discount) => {
    if (discountType === 'P') {
      return value * (discount / 100)
    }
    if (discountType === 'V') {
      return parseFloat(discount)
    }
    return 0
  }
  const changeValues = (item) => {
    let value = 'R$' + formatMoney.toReal(item.cr_valor)

    let inscricao = undefined
    if (subscriptionComboApi?.data.length > 0) {
      inscricao = subscriptionComboApi?.data.find((i) => item.inscricao_uuid === i.inscricao_id) || null

      if (inscricao) {
        if (inscricao.valor_desconto !== null) {
          let newValue = Number(item.cr_valor) - Number(inscricao.valor_desconto)

          if (inscricao.tipo_desconto_voucher && inscricao.valor_voucher) {
            const voucherDiscount = valueVoucher(newValue, inscricao.tipo_desconto_voucher, inscricao.valor_voucher)
            newValue -= voucherDiscount
          }

          return (
            <Box
              display='flex'
              flexDirection='column'
              alignItems='flex-end'>
              <Typography
                variant='caption'
                className={styles.taxedValue}>
                R${formatMoney.toReal(inscricao.valor)}
              </Typography>
              <Typography
                variant='caption'
                className={styles.value}>
                R${formatMoney.toReal(newValue)}
              </Typography>
            </Box>
          )
        }
      }
    }

    if (item?.valor_desconto) {
      return (
        <Box
          display='flex'
          flexDirection='column'
          alignItems='flex-end'>
          <Typography
            variant='caption'
            className={styles.taxedValue}>
            R${formatMoney.toReal(item?.valor)}
          </Typography>
          <Typography
            variant='caption'
            className={styles.value}>
            R${formatMoney.toReal(item?.cr_valor)}
          </Typography>
        </Box>
      )
    }

    return (
      <Box
        display='flex'
        flexDirection='column'
        alignItems='flex-end'>
        <Typography
          variant='caption'
          className={styles.value}>
          {value}
        </Typography>
      </Box>
    )
  }

  const listItens = (row) => {
    let gridArray = []
    let descricaoAtvds = row.titulo.split(', ')
    for (let index = 0; index < row.inscricao_uuid.length; index++) {
      let inscricao = subscriptionComboApi?.data.find((i) => row.inscricao_uuid[index] === i.inscricao_id) || null
      if (inscricao) {
        gridArray.push(
          <Grid
            key={index}
            container>
            <Grid
              className='title'
              item
              xs={8}>
              <Grid>
                <b>{descricaoAtvds[index]}</b>
              </Grid>
            </Grid>
            <Grid
              className='price'
              item
              xs={4}>
              {row.inscricao_valor[index] > 0 ? (
                `R$${formatMoney.toReal(row.inscricao_valor[index])}`
              ) : (
                <b className='green'>CORTESIA</b>
              )}
            </Grid>
          </Grid>
        )
      }
    }
    return gridArray
  }

  return (
    <>
      <div>
        {selected && selected?.length <= 0 && (
          <span>
            <Translator transRef='translation.Não há itens no carrinho.' />
          </span>
        )}
        {selected && selected?.length <= 0 && selected?.length > 0 && (
          <span>
            <Translator transRef='translation.Não há itens selecionados.' />
          </span>
        )}
      </div>
      {selected &&
        selected.length > 0 &&
        selected.map((row, i) =>
          parseInt(Object.keys(row?.conta_receber_id).length) < row?.inscricao_uuid?.length ? (
            listItens(row)
          ) : (
            <Grid
              key={i}
              container
              className={styles.selectedItemsContainerStyle}
              spacing='1'
              justifyContent='space-between'
              xs={12}>
              <Grid
                item
                className={styles.selectedItemStyle}>
                <Typography>{row.descricao}</Typography>
              </Grid>
              <Grid
                item
                className={styles.selectedItemStyle}>
                {changeValues(row)}
              </Grid>
            </Grid>
          )
        )}
    </>
  )
}

export default ListItensCart
