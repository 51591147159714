import * as actions from "./actions";
import produce from "immer";

const initialState = {
  subscription: [],
  transmissionActivity: {},
};

export const reducer = produce((state, action) => {
  switch (action.type) {
    case actions.SET_SUBSCRIPTION:
      state.subscription.push(action.payload.subscription);
      break;
    case actions.SET_TRANSMISSIONACTIVITY:
      state.transmissionActivity = action.payload.data;
      break;
    case actions.RESET_INITIALSTATE:
      state.subscription = [];
      state.transmissionActivity = {};
      break;
    default:
      break;
  }
}, initialState);
