import PersonIcon from "@material-ui/icons/Person"
import { Translator } from "@itarget/shared"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"

import { ListItem, ListItemAvatar, ListItemText, ListItemIcon, Avatar } from "@material-ui/core"

const UserMenuMobile = ({ goTo, user, onLogout, statusAssociated, category }) => {
  const name = user?.data?.nome_cracha || user?.data?.nome || ""
  const description = category || ""

  const setLabelSecondary = () => {
    return (
      <>
        {description}
        <br />
        <strong>{statusAssociated?.data?.status || statusAssociated?.data?.financialStatus}</strong>
      </>
    )
  }

  const translation = {
    myDashboard: Translator({ transRef: "translation.Meu Painel"}),
    exit: Translator({ transRef: "translation.Sair"}),
  }

  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <PersonIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={name} secondary={setLabelSecondary()} />
      </ListItem>
      <ListItem onClick={() => (goTo && goTo("new-panel")) || null}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary={translation?.myDashboard} />
      </ListItem>
      <ListItem onClick={onLogout}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary={translation?.exit} />
      </ListItem>
    </>
  )
}

export default UserMenuMobile
