import { Translator, Button } from '@itarget/shared'
import { Grid } from '@material-ui/core'
import './style.scss'

const ViewCertificate = (props) => {
  const { url_certificado, setOpenCertificate } = props

  return (
    <Grid
      item
      container
      direction='column'>
      <Button
        variant='contained'
        color='twilight'
        onClick={() => setOpenCertificate(url_certificado)}
        icon='approval'>
        <Translator transRef={'translation.CERTIFICADO'} />
      </Button>
    </Grid>
  )
}

export default ViewCertificate
