import React, { useState } from "react"
import styled from "styled-components"
import HomeIcon from "@material-ui/icons/Home"
import { Config, Translator } from "@itarget/shared"

export const StylesStore = styled.div`
  @font-face {
    font-family: "Gotham";
    src: url("fonts/Gotham-Bold.woff2") format("woff2"), url("fonts/Gotham-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  ul.menu,
  .menu li,
  .menu a {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    text-transform: uppercase;
  }
  .toggle,
  [id^="drop"] {
    display: none;
  }

  .current-menu-item .nav-link {
    background-color: #f06363;
    font-weight: bold;
  }
  ul.menu ul {
    position: absolute;
    display: none;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    border-bottom: 3px solid rgba(0, 0, 0, 0.15);
  }

  span.set:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
  }

  /* Configurações Nivel 1 */
  ul.menu {
    height: 28px;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    font-size: 12px;
    padding: 0 2px;
  }

  .menu li {
    float: left;
    width: auto;
    position: relative;
  }

  .menu li a {
    display: block;
    padding: 5px 10px;
    line-height: 1.6;
    white-space: normal;
    font-weight: bold;
    float: left;
    clear: both;
    color: #fff;
    font-family: "Gotham", Arial !important;
    transition: all 0.1s linear;
  }
  .menu li a:hover {
  }

  /* Configurações Nivel 2*/
  .menu li.show > ul.submenu-1 {
    display: block;
    top: 28px;
    left: 0;
    padding: 5px;
    min-width: 8.5rem;
  }
  // .menu li:hover>ul.submenu-1 {
  //   display: block;
  //   top: 28px;
  //   left: 0;
  //   padding: 5px;
  //   min-width: 8.5rem;
  // }

  .menu ul.submenu-1 a {
    min-width: 8.5rem;
    padding: 0.25rem 0.9rem;
    border: 0;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    font-size: 1.2em;
    text-transform: none;
  }
  .menu ul.submenu-1 a:hover {
    color: #444;
    background: #f7f7f7;
  }

  /* Configurações nivel 2*/
  .menu li:hover > ul.submenu-2 {
    display: block;
    top: 0;
    left: 170px;
    padding: 5px;
    border-radius: 0 5px 5px 5px;
    min-width: 9.5rem;
  }

  .menu ul.submenu-2 a {
    min-width: 9.5rem;
    padding: 0.25rem 0.9rem;
    border: 0;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    font-size: 1.2em;
    text-transform: none;
  }
  .menu ul.submenu-2 a:hover {
    color: #444;
    background: #f7f7f7;
  }

  /* Configurações nivel 3*/
  .menu li a:hover > ul.submenu-3 {
    display: block;
    top: 0;
    left: 100px;
    padding: 5px;
    border-radius: 0 5px 5px 5px;
  }

  .menu ul.submenu-3 a {
    padding: 0 20px;
    border-radius: 5px;
  }

  /*Configurações de cores*/
  /*Nivel 1*/
  .menu {
    background: rgb(42, 116, 153);
  }
  .menu li:first-child {
    border-left: 1px solid #fff;
  }

  .menu a {
    color: #fff;
    border-right: 1px solid #fff;
  }

  .menu li:hover > a {
    background: rgb(42, 116, 153);
    color: #fff;
  }

  /*nivel 2*/
  .submenu-1 {
    background: rgb(42, 116, 153);
  }
  .submenu-1 a {
    color: #fff;
  }
  .submenu-1 li:hover > a {
    background: #fff;
    color: rgb(42, 116, 153);
  }

  /*nivel 3*/
  .submenu-2 {
    background: rgb(42, 116, 153);
  }
  .submenu-2 a {
    color: #fff;
  }
  .submenu-2 li:hover > a {
    background: #fff;
  }

  /*nivel 3*/
  .submenu-3 {
    background: rgb(42, 116, 153);
  }
  .submenu-3 a {
    color: #fff;
  }
  .submenu-3 li:hover > a {
    background: #000;
  }

  .MuiSvgIcon-root {
    font-size: 17px !important;
  }

  @media all and (max-width: 800px) {
    nav {
      display: none;
      margin: 0;
    }
  }
`

export function SubMenu({ items = [], nivel }) {
  if (!items.length) return null
  return (
    <ul className={`submenu-${nivel}`}>
      {items.map((item) => (
        <li>
          <a href={item.url}>
            <span className={item.submenu && item.submenu.length ? "set" : ""}>
              {item.title}
            </span>
          </a>
          {item.submenu && <SubMenu items={item.submenu} nivel={nivel + 1} />}
        </li>
      ))}
    </ul>
  )
}

export function SubMenuMobile({ title = "", items = [], nivel }) {
  if (!items.length) return null
  return (
    <>
      <label for={`drop-${nivel}`} className="toggle">
        {title} +
      </label>
      <a href="#">
        {title}
      </a>
      <input type="checkbox" id={`drop-${nivel}`} />
      <ul>
        {items.map((item, index) => {
          return (
            <li>
              {item.submenu && !item.submenu.length && (
                <a href={item.url}>
                  <span className="MuiButton-label">
                    {item.title}
                  </span>
                </a>
              )}
              {item.submenu && <SubMenuMobile title={item.title} items={item.submenu} nivel={nivel + index} />}
            </li>
          )
        })}
      </ul>
    </>
  )
}

const MenuC = ({ items = [], level = 0, isLoadingMenuData = false, classes = {}, showMenu }) => {
  const [activeMenu, setActiveMenu] = useState("")
  let menuShow = showMenu

  function toggleMenu(index) {
    if (activeMenu === index) {
      setActiveMenu("")
    } else {
      setActiveMenu(index)
    }
  }

  function MenuHtml({ items = [], level = 0, isLoadingMenuData = false, classes = {} }) {
    return (
      <nav id="menu-desktop" className={`${menuShow}`}>
        <ul className="menu">
          {!items.length && <span style={{ color: "white" }}>
            ...
          </span>}
          {items.map((item, index) => {
            if (/^\<i/gi.test(item.title) || /^\<i/gi.test(item.title)) {
              return (
                <li className={classes}>
                  <a href={item.url} style={{ padding: "1px 10px", backgroundColor: "#f06363" }}>
                    <HomeIcon style={{ marginTop: "4px" }} />
                  </a>
                  {/* SUB1 */}
                  {item.submenu && <SubMenu items={item.submenu} nivel={1} />}
                </li>
              )
            }
            return (
              <li className={index === activeMenu ? "show" : ""}>
                {item.submenu && item.submenu.length ? (
                  <>
                    <a href={item.url} onClick={() => toggleMenu(index)} className={item.submenu ? "parent" : ""}>
                      {item.title !== "Home" && item.title}
                    </a>
                  </>
                ) : (
                  <a href={item.url}>
                    {item.title === "Home" && <HomeIcon style={{ marginTop: "4px" }} />}
                    {item.title !== "Home" && item.title}
                  </a>
                )}
                {/* SUB1 */}
                {item.submenu && <SubMenu items={item.submenu} nivel={1} />}
              </li>
            )
          })}
        </ul>
      </nav>
    )
  }

  return (
    <>
      {Config.client === "sbcbm" && (
        <StylesStore style={{ zIndex: 1 }} client={Config.client}>
          <MenuHtml items={items} level={level} isLoadingMenuData={isLoadingMenuData} classes={classes} />
        </StylesStore>
      )}
    </>
  )
}
export default React.memo(MenuC)
