import { auth as authRedux } from '../../redux'
import { Config, useRedirect } from '@itarget/shared'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useRef } from 'react'

const DEFAULT_MENU = {
  title: 'Home',
  url: Config.baseURL,
}

const useAction = () => {
  const { goTo } = useRedirect()
  const dispatch = useDispatch()
  const centerCostId = useSelector((state) => state.auth.cc)
  const user = useSelector((state) => state.auth.user)
  const settingCommerceCenterCostId = useSelector((state) => state.setting.settingCommerce)
  const [displayMenu, setDisplayMenu] = useState(false)
  const ref = useRef(null)

  let systemMenu = settingCommerceCenterCostId?.json_menu
    ? JSON.parse(settingCommerceCenterCostId?.json_menu).map((row) => ({ url: row.url, title: row.description }))
    : DEFAULT_MENU
  let dynamicMenu = settingCommerceCenterCostId?.customMenu
  let menu = dynamicMenu || systemMenu

  let images = {
    logo: settingCommerceCenterCostId?.url_image_topo_mobile,
    background: settingCommerceCenterCostId?.url_image_topo_background_mobile,
  }

  const handleLogout = () => {
    dispatch(authRedux.actions.logout())
  }

  const cleanDataEnabled = () => {
    const children = ref.current.querySelectorAll('[data-enabled]')

    children.forEach((child) => {
      child.setAttribute('data-enabled', false)
    })
  }

  const handleMenu = () => {
    setDisplayMenu((previous) => {
      let newValue = !previous
      if (newValue) {
        document.body.style.overflow = 'hidden'
      } else {
        ref.current && cleanDataEnabled()
        document.body.style.overflow = 'auto'
      }
      return newValue
    })
  }

  const handleLogged = () => {
    if (!user?.data?.id) return
    goTo('new-panel')
  }

  const handleLogin = () => {
    goTo(`login${centerCostId ? `/${centerCostId}` : ''}`)
  }

  const handleRegister = () => {
    goTo(`login${centerCostId ? `/${centerCostId}` : ''}?state=register`)
  }

  let name = user?.data?.nome_cracha || user?.data?.nome.split(' ')[0]

  return {
    ref,
    menu,
    goTo,
    name,
    images,
    handleMenu,
    handleLogin,
    displayMenu,
    handleLogout,
    handleLogged,
    handleRegister,
    user: user?.data,
  }
}

export default useAction
