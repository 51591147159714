import {
  Container,
  Typography,
  Divider,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Link,
  Icon,
  CircularProgress,
} from '@material-ui/core'
import { PaymentContainer, Translator } from '@itarget/shared'
import Layout from '../../../components/Layout'
import CardToPayment from '../../../components/CardToPayment'
import FinishPayment from '../../../components/FinishPayment'
import CardWrongCreditCard from '../../../components/CardWrongCreditCard'
import CardShopping from '../../../components/CardShopping'
import './style.scss'
import { ModalQuestions } from '../../../components/ModalQuestionsCourse'
import { makeStyles } from '@material-ui/styles'
import TimerShoppingCart from '../TimerShoppingCart'

const useStyles = makeStyles(() => ({
  shoppingCartIcon: {
    fontSize: '1.6rem !important',
  },
}))

const ShoppingCartView = ({
  isLoading,
  activityChecked,
  vlTotal,
  incList,
  goFinish,
  courses = [],
  selected,
  setSelected = {},
  total,
  onChangeSelected,
  state,
  setState,
  toast,
  paymentRedux,
  paymentType,
  fetchInscricoes,
  subscriptionState,
  totalState,
  subscription,
  user,
  center_cost_id,
  openConfirmUnsubscribe,
  handleCloseUnsubscribe,
  onClickUnsubscribe,
  openModal,
  onInvalidCardPayment,
  showWrongCard,
  resetStatePayment,
  setShowWrongCard,
  changePayment,
  settingCommerce,
  dispatch,
  amountProduct,
  goTo,
  setShowQuiz,
  showQuiz,
  setting,
  authRedux,
}) => {
  const styles = useStyles()
  return (
    <Layout>
      <Dialog
        className={'dialog-unsubscribe'}
        open={openConfirmUnsubscribe}
        onClose={handleCloseUnsubscribe}>
        <DialogTitle id='alert-dialog-title'>
          <Translator transRef={`translation.Deseja cancelar a sua inscrição?`} />
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseUnsubscribe}>
            <Translator transRef={`translation.Voltar`} />
          </Button>
          <Button
            onClick={onClickUnsubscribe}
            autoFocus>
            <Translator transRef={`translation.Cancelar inscrição`} />
          </Button>
        </DialogActions>
      </Dialog>
      <Container maxWidth='xl' className='shoppingCart-header'>
        {showWrongCard && (
          <CardWrongCreditCard
            changePayment={changePayment}
            setShow={setShowWrongCard}
          />
        )}
        <Grid
          container
          alignItems='left'
          spacing={1}>
          <Grid item>
            <Icon className={styles.shoppingCartIcon}>shopping_cart</Icon>
          </Grid>
          <Grid item>
            <Typography variant='h5'>
              <Translator transRef={`translation.Carrinho`} />
            </Typography>
          </Grid>
        </Grid>
        <TimerShoppingCart standardTime={60} />
      </Container>
      <Divider />
      {showQuiz && (
        <ModalQuestions
          setShowQuiz={() => {
            setShowQuiz()
          }}
          questionDestination={17}
          centerCostId={center_cost_id}
        />
      )}
      <Container maxWidth='xl' className='shoppingCart-body'>
        <Grid
          spacing={5}
          container>
          <Grid
            item
            container
            spacing={1}
            xs={12}
            md={7}>
            {isLoading && (
              <Grid
                item
                container>
                <CircularProgress />
              </Grid>
            )}
            {!isLoading && courses?.length < 1 && (
              <Grid
                item
                container>
                <Typography
                  variant='h5'
                  className='title'>
                  <Translator transRef={`translation.Seu carrinho está vazio.`} />
                </Typography>
              </Grid>
            )}
            {['payment', 'voucher'].includes(state) && (
              <Grid
                container
                item
                className='grid-cardPayment'>
                <PaymentContainer
                  settingCommerce={settingCommerce}
                  subscription={subscription}
                  goFinish={goFinish}
                  centro_custo_id={center_cost_id}
                  toast={toast}
                  paymentType={paymentType}
                  subscriptionState={subscriptionState}
                  paymentRedux={paymentRedux}
                  total={totalState}
                  user={user}
                  onInvalidCardPayment={onInvalidCardPayment}
                  resetState={resetStatePayment}
                  authRedux={authRedux}
                />
              </Grid>
            )}
            {state === 'finish' && (
              <FinishPayment
                vlTotal={vlTotal}
                incList={incList}
              />
            )}
            {state === 'cards' &&
              courses.map((row, i) => (
                <Grid
                  key={i}
                  item
                  container
                  className='grid-cardPayment'>
                  <CardToPayment
                    activityChecked={activityChecked}
                    openModal={openModal}
                    openConfirmUnsubscribe={openConfirmUnsubscribe}
                    handleCloseUnsubscribe={handleCloseUnsubscribe}
                    onClickUnsubscribe={onClickUnsubscribe}
                    onChange={onChangeSelected}
                    card={row}
                    courses={courses}
                    settingCommerce={settingCommerce}
                  />
                </Grid>
              ))}
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            className='gridItem-detailPurchase'>
            <Grid className='grid-detailPurchase'>
              {state === 'finish' ? (
                <>
                  <Typography className='header'>
                    <Translator transRef={`translation.Ações`} />
                  </Typography>
                  <Typography className='link-finish'>
                    <Link onClick={() => goTo('my-activity#3')}>
                      <Translator transRef={`translation.Acessar meus cursos`} />
                    </Link>
                  </Typography>
                  <Typography className='link-finish'>
                    <Link href='/shopping-cart'>
                      <Translator transRef={`translation.Ir para Carrinho`} />
                    </Link>
                  </Typography>
                  <Typography className='link-finish'>
                    <Link href='/'>
                      <Translator transRef={`translation.Continuar comprando`} />
                    </Link>
                  </Typography>
                </>
              ) : (
                <>
                  <CardShopping
                    fetchInscricoes={fetchInscricoes}
                    total={total}
                    state={state}
                    setState={setState}
                    selected={selected}
                    setSelected={setSelected}
                    user={user}
                    dispatch={dispatch}
                    amountProduct={amountProduct}
                    courses={courses}
                    setting={setting}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default ShoppingCartView
