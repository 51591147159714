import { Divider, Container, Typography } from "@material-ui/core";
import Layout from "../../../components/Layout";
import CardCourseList from "../../../components/CardCourseList";
import { Translator } from "@itarget/shared";

const MyCourseView = ({ selectCourse, course }) => {
  return (
    <Layout>
      <Container className="myActivities-header title-page">
        <Typography variant="h4"><Translator transRef={`translation.MEUS CURSOS`} /></Typography>
      </Container>
      <Divider />
      <Container maxWidth='xl'>
        {(!course || course.length === 0) && (
          <Typography variant="h6"><Translator transRef={`translation.Nenhum registro encontrado`} /></Typography>
        )}
        {course?.map((row) => (
          <CardCourseList selectCourse={selectCourse} course={row} />
        ))}
      </Container>
    </Layout>
  );
};

export default MyCourseView;
