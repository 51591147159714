import { useState, useEffect, useRef, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import './style.scss'
import { Box, Container, Grid, LinearProgress } from '@material-ui/core'
import { useFetchApi, Config, useRedirect, configurator } from '@itarget/shared'
import { auth, offers, setting as settingRedux, theme as themeRedux } from '../../redux'

import Header from '../Header'
import Footer from '../Footer/Footer'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  LinearProgress: {
    borderRadius: '0 0 4px 4px',
    height: theme.spacing(0.5),
  },
}))

const Layout = ({ children, isLoading = false }) => {
  const styles = useStyles()
  const { current: windowWidth } = useRef(window.innerWidth)
  const { goTo } = useRedirect()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const user = useSelector((state) => state.auth.user)
  const shoppingCarts = useSelector((state) => state.offers.shoppingCarts)
  const center_cost_id = useSelector((state) => state.auth.cc)
  const selectedLang = useSelector((state) => state.language.lang)
  const settingCommerce = useSelector((state) => state.setting.settingCommerce)
  const hasUserPreferenceTheme = useSelector((state) => state.theme.hasUserPreferenceTheme)
  const token = useSelector((state) => state.fetch.token)
  const [menuItemsC, setMenuItemsC] = useState([])
  let minWidthToEnableMobileImage = 425

  const logo = useMemo(
    () =>
      windowWidth <= minWidthToEnableMobileImage && settingCommerce?.url_image_topo_mobile
        ? settingCommerce?.url_image_topo_mobile
        : settingCommerce?.url_image_topo,
    [windowWidth, settingCommerce?.url_image_topo_mobile, settingCommerce?.url_image_topo]
  )

  const backgroundHeader = useMemo(
    () =>
      windowWidth <= minWidthToEnableMobileImage && settingCommerce?.url_image_topo_background_mobile
        ? settingCommerce?.url_image_topo_background_mobile
        : settingCommerce?.url_image_topo_background,
    [windowWidth, settingCommerce?.url_image_topo_background_mobile, settingCommerce?.url_image_topo_background]
  )

  const {
    data: menuDataF,
    fetchData: fetchMenuDataF,
    isLoading: isLoadingMenuDataF,
  } = useFetchApi(Config.baseURL + `api/${selectedLang}/template/header/all/menu?centro_custo_id=${center_cost_id}`)

  const {
    data: settingLogin,
    fetchData: fetchSetting,
    isLoading: isLoadingSettingLogin,
  } = useFetchApi(Config.baseURL + `api/${selectedLang}/setting?centro_custo_id=${center_cost_id}&${configurator.LOGIN}`)

  const { data: settingCourse, fetchData: fetchSettingCourse } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/setting-course-commerce/setting?centro_custo_id=${center_cost_id}`
  )

  const { data: dataCenterCost, fetchData: fetchcenterCost } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/center-cost/${routeParams?.cc ? routeParams.cc : center_cost_id}`
  )

  const onLogout = () => {
    dispatch(auth.actions.logout())
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const getFaviconEl = () => {
    return document.getElementById('favicon')
  }

  const { data: inscricoesApi, fetchData: fetchInscricoes } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/subscription/pending?pessoa_id=${user.data && user.data.id}&origem_inscricao=5`
  )

  const { data: statusAssociated, fetchData: fecthStatusAssociated } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/association/status-associated?associationId=${
        user.data && user.data.associacao_id
      }&entityId=1&center_cost_id=${routeParams?.cc ? routeParams.cc : center_cost_id}`
  )

  const { data: category, fetchData: fecthCategory } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/center-cost-person/category-person?person_id=${user.data && user.data.id}&center_cost_id=${
        routeParams?.cc ? routeParams.cc : center_cost_id
      }`
  )

  useEffect(() => {
    fetchSetting()
    fetchMenuDataF()
    fetchcenterCost()
    if (token) {
      fetchSettingCourse(null, null, {
        Authorization: 'Bearer ' + token,
      })
    }
    if (user.data) {
      fetchInscricoes()
      fecthStatusAssociated()
      fecthCategory()
    }
  }, [user, fecthCategory, fecthStatusAssociated, fetchInscricoes, fetchSettingCourse, token, fetchMenuDataF, fetchSetting])

  useEffect(() => {
    if (inscricoesApi?.data) {
      dispatch(offers.actions.setShoppingCarts(inscricoesApi.data.filter((res) => res.controle_boleto_cartao_id === null)))
    }
  }, [dispatch, inscricoesApi])

  useEffect(() => {
    if (routeParams?.cc) {
      dispatch(auth.actions.setCenterCost(routeParams.cc))
    }
  }, [routeParams?.cc])

  useEffect(() => {
    if (menuDataF) {
      if (!menuDataF.menu_principal?.length) {
        dispatch(settingRedux.actions.setSettingCommerce({ customMenu: undefined }))
        return
      }
      let customMenu = []
      menuDataF.menu_principal.map((item) => {
        return (customMenu[item.menu_order] = item)
      })
      dispatch(settingRedux.actions.setSettingCommerce({ customMenu: customMenu.filter((row) => row != null) }))
      setMenuItemsC(customMenu)
    }
  }, [menuDataF])

  useEffect(() => {
    if (settingCourse) {
      dispatch(settingRedux.actions.setSettingCommerce(settingCourse.data))
    }

    if (settingCourse?.data?.texto_aba_navegador) {
      document.title = settingCourse?.data?.texto_aba_navegador
    }

    if (settingCourse?.data?.icone_aba_navegador) {
      getFaviconEl().href = settingCourse?.data?.icone_aba_navegador
    }
    if (settingCourse?.data?.json_cores_barra_menu) {
      let jsonColorsMenuBar = JSON.parse(settingCourse?.data?.json_cores_barra_menu)
      if (jsonColorsMenuBar.hasOwnProperty('defaultThemeMode') && !hasUserPreferenceTheme) {
        dispatch(themeRedux.actions.setHasPreferenceTheme(true))
        dispatch(themeRedux.actions.setSystemTheme(jsonColorsMenuBar.defaultThemeMode))
      }
    }
  }, [settingCourse, dispatch])

  useEffect(() => {
    if (dataCenterCost) {
      dispatch(settingRedux.actions.setCenterCost(dataCenterCost))
    }
  }, [dataCenterCost])

  useEffect(() => {
    dispatch(settingRedux.actions.setIsLoadingSettingLogin(isLoadingSettingLogin))
    if (settingLogin?.data) dispatch(settingRedux.actions.setSettingLogin(settingLogin.data))
  }, [settingLogin, isLoadingSettingLogin])

  useEffect(() => {
    if (statusAssociated?.data) {
      dispatch(auth.actions.updateUser({ ...user, data: { ...user?.data, ...statusAssociated?.data } }))
    }
  }, [statusAssociated?.data?.financialStatus, dispatch])

  return (
    <div className='layout-container'>
      <Grid
        className='topo-full'
        item={true}
        md={12}>
        <div className='layout-fixed'>
          <Header
            statusAssociated={statusAssociated}
            category={category?.data?.descricao}
            user={user}
            client={settingCommerce?.texto_aba_navegador}
            logo={logo}
            backgroundHeader={backgroundHeader}
            shoppingCarts={shoppingCarts}
            menuItemsC={menuItemsC}
            goTo={goTo}
            onLogout={onLogout}
            isLoadingMenuData={isLoadingMenuDataF}
            center_cost_id={routeParams?.cc ? routeParams.cc : center_cost_id}
            anchorEl={anchorEl}
            setting={settingLogin}
            handleClick={handleClick}
            handleClose={handleClose}
          />
        </div>
      </Grid>
      {isLoading && <LinearProgress className={styles.LinearProgress} />}
      <Box
        className='body-layout-container'
        p={3}>
        <Container
          maxWidth='xl'
          className='body-layout'
          spacing={2}>
          {children}
        </Container>
      </Box>
      <Footer />
    </div>
  )
}

export default Layout
