import styled from 'styled-components'

export const StylesNewcbr = styled.div`
  @font-face {
    font-family: "Gotham";
    src: url("fonts/Gotham-Bold.woff2") format("woff2"), url("fonts/Gotham-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  ul.menu,
  .menu li,
  .menu a {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    font-size: 0.9em;
    font-weight: 750;
    text-transform: uppercase;
    word-spacing: 0px;
  }
  .toggle,
  [id^="drop"] {
    display: none;
  }

  .current-menu-item .nav-link {
    background-color: #f06363;
    font-weight: bold;
  }
  ul.menu ul {
    position: absolute;
    display: none;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    border-bottom: 3px solid rgba(0, 0, 0, 0.15);
  }

  ul.menu li:hover ul.submenu-1 li:hover ul.submenu-2 {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 30;
    top: 0px !important;
    left: 100%;
    padding: 0 !important;
  }

  ul.menu li:hover> ul.submenu-1 > li > a > span {
    width: 100%
  }

  /* Configurações Nivel 1 */
  .nav-menu-desktop {
    display: flex !important;
    flex-direction: row;
    max-width: 100%;
  }

  .nav-menu {
    max-width: 86.9%;
    flex-wrap: wrap;
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ul.menu {
    max-width: 100%;
    flex-wrap: wrap;
    flex: 1 0 auto;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 14px;
    font-size: 12.8px;
    margin: 0 20px;
  }

  .menu li {
    float: left;
    width: auto;
    position: relative;
  }

  .menu li a {
    border-radius: 20px;
    border: 0;
    background-color: #0d0d0d;
    display: block;
    padding: 5px 15px;
    line-height: 1.6;
    white-space: normal;
    float: left;
    clear: both;
    font-weight: 200;
    font-family: "Gotham", Arial !important;
    transition: all 0.2s linear;
  }

  .menu li:hover > .li-link {
    transition: border 0.2s linear;
  }

  /* Configurações Nivel 2*/
  .menu li:hover> ul.submenu-1 {
    background-color: #f3f3f3;
    z-index: 2;
    display: flex;
    flex-direction: column;
    top: 26px;
    left: 0;
    padding: 0;
    min-width: 8.5rem;
  }

  .menu li:hover> ul.submenu-1 > li {
  }

  .menu ul.submenu-1 a {
    border-radius: 0;
    min-width: 8.5rem;
    border: 0;
    color: rgba(0, 0, 0, 0.5);
    background-color: #f3f3f3;
    font-weight: 400;
    font-size: 1.2em;
    text-transform: none;
  }
  .menu ul.submenu-1 a:hover {
    color: #222;
    background: #f7f7f7;
  }

  /* Configurações nivel 2*/

  .menu ul.submenu-2 a {
    overflow-x: hidden;
    max-width: 200px;
    width: 100%;
    border: 0;
    font-weight: 750;
    font-size: 1.2em;
    text-transform: none;
  }
  .menu ul.submenu-2 a:hover {
    color: #222;
    background: #f7f7f7;
  }

  /* Configurações nivel 3*/

  .menu ul.submenu-3 a {
    padding: 0 20px;
    border-radius: 5px;
  }

  /*Configurações de cores*/
  /*Nivel 1*/
  .nav-menu-desktop {
    border-bottom: 3px solid #000000;
  }

  .nav-menu-desktop > div + div {
    margin-left: 5px;
    padding-left: 5px;
    border-left: 1px solid #006ea2;
  }

  .menu {
  }

  .menu li a {
    color: #f4f4f4;
  }

  .menu li:first-child {
  }

  .menu a {
    color: #fff;
    border-right: 1px solid #fff;
  }

  .menu li:hover > a.li-link {
    background-color: #ff7b08;
  }

  /*nivel 2*/
  .submenu-1 {
    background: rgb(42, 116, 153);
  }
  .submenu-1 a {
    color: #fff;
  }
  .submenu-1 li:hover > a {
    background: #fff;
    color: rgb(42, 116, 153);
  }

  /*nivel 3*/
  .submenu-2 {
    background: rgb(42, 116, 153);
  }
  .submenu-2 a {
    color: #fff;
  }
  .submenu-2 li:hover > a {
    background: #fff;
  }

  /*nivel 3*/
  .submenu-3 {
    background: rgb(42, 116, 153);
  }
  .submenu-3 a {
    color: #fff;
  }
  .submenu-3 li:hover > a {
    background: #000;
  }

  .MuiSvgIcon-root {
    font-size: 17px !important;
  }

  @media screen and (max-width: 950px) {
    .nav-menu {
      max-width: 100%;
    }
    .nav-search {
      display: none;
    }
    .nav-social {
      display: none;
    }

    ul.menu li {
      width: 100%;
      background-color: white;
      border-bottom: 1px solid #529dd7;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ul.menu li:hover ul.submenu-1 li:hover ul.submenu-2 {
      display: block;
      position: absolute;
      z-index: 30;
      top: 35px !important;
      left: 0 !important;
      padding: 0 !important;
      border-radius: inherit;
      border: none;
      width: 100%;
    }

    ul.menu li a {
      z-index: 1;
      width: 100%;
      min-height: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    ul.menu li:hover ul li a::after {
      content: inherit;
    }

    .menu li:hover> ul.submenu-1 {
      width: 100%;
      z-index: 2;
      display: block;
      top: 35px;
      left: -0;
      min-width: 8.5rem;

    .menu li:hover> ul.submenu-1 {
      width: 100%;
      position: absolute;
      z-index: 30;
      top: 40px !important;
      left: 0;
      padding: 0 !important;
      border-radius: inherit;
      border: none;
      width: 100%;
    }

    .menu li:hover> ul.submenu-1 > li > ul.submenu-2 {
      display: none;
      position: absolute;
      z-index: 30;
      top: 40px !important;
      left: 0;
      padding: 0 !important;
      border-radius: inherit;
      border: none;
      width: 100%;
    }

    .menu li:hover> ul.submenu-1 > li:hover > ul.submenu-2 {
      display: block;
    }

    .menu li:hover> ul.submenu-1 > li {
      background: #fff;
      display: inline-block !important;
      width: 100%;
    }

    .menu li:hover> ul.submenu-1 > li > ul.submenu-2 > li {
      background: #dfdfdf;
      display: inline-block !important;
      width: 100%;
    }

    .menu li a {
      display: inline-block;
      overflow: hidden;
      float: none !important;
      padding: 7px 5px !important;
      width: inherit;
    }
  }
`
