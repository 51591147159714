import { useState, useEffect } from 'react'
import { Translator } from '@itarget/shared'
import { Popover, makeStyles } from '@material-ui/core'
import { Button } from '@itarget/shared'
import LoginForm from './LoginForm'

const useStyles = makeStyles((theme) => ({
  popover: {
    padding: theme.spacing(1.5),
  },
}))

const LoginButton = ({ showForm = false }) => {
  const [open, setOpen] = useState(showForm)
  const [anchorEl, setAnchorEl] = useState(null)

  const onClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const onClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    setOpen(!!anchorEl)
  }, [anchorEl])

  const styles = useStyles()

  return (
    <>
      <Button variant='contained' color='primary' className={styles.button} onClick={onClick}>
        <Translator transRef='translation.Login' />
      </Button>
      <Popover
        id='login-button'
        open={open}
        anchorEl={anchorEl}
        keepMounted
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{
          paper: styles.popover,
        }}
      >
        <LoginForm />
      </Popover>
    </>
  )
}

export default LoginButton
