import Amount from './Amount'
import Available from './Available'
import Category from './Category'
import ComboAnnuity from './ComboAnnuity'
import Counter from './Counter'
import Courtesy from './Courtesy'
import Detail from './Detail'
import Header from './Header'
import Loading from './Loading'
import Pending from './Pending'
import Reference from './Reference'
import Registered from './Registered'
import Return from './Return'
import Root from './Root'
import SoldOut from './SoldOut'
import Total from './Total'
import Unavailable from './Unavailable'
import Watch from './Watch'
import ShoppingCart from './shoppingCart'

export default {
  Root,
  Watch,
  Total,
  Header,
  Detail,
  Amount,
  Return,
  Counter,
  SoldOut,
  Pending,
  Loading,
  Category,
  Courtesy,
  Available,
  Reference,
  Registered,
  Unavailable,
  ComboAnnuity,
  ShoppingCart,
}
