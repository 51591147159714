import React, { useEffect, useRef, useState, useContext, useLayoutEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { toast } from '../../redux'
import { useFetchApi, Config } from '@itarget/shared'
import DetailEadView from './view/DetailEadView'
import { SidebarContext } from '../../context/SidebarContext'
import { debounce } from 'lodash'
import { useFormik } from 'formik'
const DetailEadContainer = () => {
  const getStateFromLocalStorage = () => {
    const value = window.localStorage.getItem('isOpen')
    return value ? value === 'true' : false
  }

  const { setOpen, setDisplayDate, setExpand } = useContext(SidebarContext)

  const setStateToLocalStorage = (value) => {
    return window.localStorage.setItem('isOpen', value)
  }

  const dispatch = useDispatch()
  const refPlayer = useRef()
  const user = useSelector((state) => state.auth.user)
  const { id } = useParams()
  const { cc, activity_schedule_id } = useParams()
  const [showVideo, setShowVideo] = useState(false)
  const [endVideo, setEndVideo] = useState(false)
  const selectedLang = useSelector((state) => state.language.lang)
  const [progress, setProgress] = useState({
    playedSeconds: 0,
    played: 0,
    loadedSeconds: 0,
    loaded: 0,
  })
  const [totalWatch, setTotalWatch] = useState(0)
  const [percentNextVideo, setPercentNextVideo] = useState(1)
  const [lesson, setLesson] = useState('')
  const [speakers, setSpeakers] = useState('')
  const [programing, setPrograming] = useState([])
  const [subscriptions, setSubscriptions] = useState([])
  const [datePrograming, setDatePrograming] = useState([])
  const [tabs, setTabs] = useState([])
  const [scheduling, setScheduling] = useState([])
  const [participant, setParticipant] = useState({})
  const [scheduleType, setScheduleType] = useState({})
  const [selectedFilter, setSelectedFilter] = useState({})
  const [checked, setChecked] = useState(false)
  const firstTabWithContent = tabs.findIndex((tab) => scheduling.some((activity) => activity.sala === tab.uuid))
  const [date, setDate] = useState(datePrograming[0])
  const [roomTabs, setRoomTabs] = useState([])
  const [roomActive, setRoomActive] = useState(firstTabWithContent >= 0 ? firstTabWithContent : 0)
  const [dateActive, setDateActive] = useState(0)
  const [showActivitiesWithVideo, setShowActivitiesWithVideo] = useState(false)

  let axis = programing?.area ? programing.area.map((row) => ({ id: row.uuid, descricao: row.nome })) : []
  let activityTypes = programing?.tipo_atividade
    ? programing.tipo_atividade.map((row) => ({ id: row.uuid, description: row.nome }))
    : []
  let filterByPeriod = selectedFilter?.grouping_field === 'data_inicio_aga'

  const handleChange = (event) => {
    setSelectedFilter({
      ...selectedFilter,
      agenda_atividades: { [event.target.name]: event.target.checked ? event.target.value : '' },
    })
    setDisplayDate(event.target.value)
    setChecked({ [event.target.value]: event.target.checked })
  }

  const {
    data: dataProgram,
    fetchData: fetchProgram,
    isLoading: loadingProgram,
  } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/programing?centro_custo_id=${cc}&exibir_programacao_horario=A&redis=N` +
      (user?.data?.id ? `&pessoa_id=${user.data.id}` : '') +
      (selectedFilter?.descricao ? `&descricao=${selectedFilter.descricao}` : '') +
      (selectedFilter?.data_inicio_aga ? `&agenda_atividades[data_inicio]=${selectedFilter.data_inicio_aga}` : '') +
      (selectedFilter?.data_fim_aga ? `&agenda_atividades[data_inicio]=${selectedFilter.data_fim_aga}` : '') +
      (selectedFilter?.activity_type ? `&tipos_atividades_idiomas[id]=${selectedFilter.activity_type}` : '') +
      (selectedFilter?.eixo_id ? `&eixos_idiomas[id]=${selectedFilter.eixo_id}` : '') +
      `&agenda_atividades[disponivel_conteudo_internet]=S`
  )

  const { fetchData: fetchSendVideo } = useFetchApi(Config.baseURL + `api/${selectedLang}/video-frequency`, 'POST')

  const {
    data: getVideo,
    error: errorGetVideo,
    fetchData: fetchGetVideo,
  } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/video-frequency/${lesson && lesson.agenda_atividade_detalhe_id}/resume?pessoa_id=${
        user.data && user.data.id
      }`
  )

  useEffect(() => {
    fetchProgram()
    return () => {
      resetState()
    }
  }, [selectedFilter])

  useEffect(() => {
    if (dataProgram) {
      setPrograming(dataProgram.data)
    }
  }, [dataProgram])

  useEffect(() => {
    if (errorGetVideo !== null) {
      dispatch(
        toast.actions.enqueue({
          content: errorGetVideo,
          severity: 'error',
        })
      )
    }
  }, [errorGetVideo, dispatch])

  useEffect(() => {
    if (showVideo && lesson) {
      fetchGetVideo()
    }
  }, [showVideo, lesson, fetchGetVideo])

  useEffect(() => {
    if (getVideo && getVideo.data?.posicao && refPlayer.current) {
      const posicao = getVideo.data.posicao
      const time = posicao.seconds ? posicao.seconds : posicao.playedSeconds
      setTotalWatch(posicao.playedSeconds)
      if (posicao.played !== 1 && getVideo.data.tipo !== 'F') {
        refPlayer.current.seekTo(time)
        setProgress(posicao)
      }
    }
  }, [getVideo])

  const onSubmit = (values) => {
    setSelectedFilter({ ...selectedFilter, ...values })
  }

  const formik = useFormik({
    initialValues: selectedFilter,
    enableReinitialize: true,
    onSubmit,
  })

  const handleInputChangeDebounced = debounce((value) => {
    setSelectedFilter({
      ...selectedFilter,
      descricao: value,
    })
  }, 500)

  const selectStep = (lesson, speakers) => {
    setLesson(lesson)
    setSpeakers(speakers)
    setShowVideo(true)
  }

  const sendVideo = (tipo, posicao) => {
    const seconds = posicao !== undefined ? posicao.playedSeconds : progress.playedSeconds
    const position = posicao !== undefined ? posicao : progress

    if (position.loaded >= percentNextVideo) {
      tipo = 'F'
    }

    fetchSendVideo(
      JSON.stringify({
        agenda_atividade_detalhe_id: lesson.agenda_atividade_detalhe_id,
        pessoa_id: user.data.id,
        tipo: tipo,
        posicao: posicao ? posicao : progress,
        replay: totalWatch > seconds || endVideo ? 'S' : 'N',
      })
    )
  }

  const onPause = (e) => {
    if (e?.percent < 1) {
      sendVideo('P')
    }
  }

  const onPlay = () => {
    sendVideo('I')
  }

  const onProgress = (e) => {
    if (totalWatch < e.playedSeconds) {
      setTotalWatch(e.playedSeconds)
    }
    if (e.played !== 1) {
      setProgress(e)
      sendVideo('P', e)
    }
  }

  const onEnded = () => {
    const position = {
      playedSeconds: progress.loadedSeconds,
      played: 1,
      loadedSeconds: progress.loadedSeconds,
      loaded: progress.loaded,
    }
    setTotalWatch(progress.loadedSeconds)
    sendVideo('F', position)
    setEndVideo(true)
    setProgress({ playedSeconds: 0, played: 0, loadedSeconds: 0, loaded: 0 })
  }

  const closeShowVideo = async () => {
    if (progress.loadedSeconds !== progress.playedSeconds) {
      await sendVideo('P')
    }
    resetState()
    setShowVideo(false)
  }

  const resetState = () => {
    setProgress({ playedSeconds: 0, played: 0, loadedSeconds: 0, loaded: 0 })
    setTotalWatch(0)
    setLesson('')
    setEndVideo(false)
  }

  useLayoutEffect(() => {
    if (localStorage.length < 2) {
      return setStateToLocalStorage(true)
    }
  }, [])

  const filterOnlyVideoActivities = () => {
    const activitiesWithVideo = scheduling.filter((activity) =>
      activity.sub_tema.some((subTema) => {
        return subTema.exibe_video_palestra && subTema.exibe_video_palestra !== 'N'
      })
    )
    return activitiesWithVideo
  }

  const handleShowActivitiesWithVideo = (event) => {
    setShowActivitiesWithVideo(event.target.checked)
    setDisplayDate('')
  }

  const onOpenSidebar = () => {
    setOpen(true)
    setStateToLocalStorage(true)
  }

  const onCloseSidebar = () => {
    setOpen(false)
    setStateToLocalStorage(false)
  }

  const roomTabHandleChange = (index) => {
    setRoomActive(index)
  }

  const dateTabHandleChange = (date, index) => {
    setDate(date)
    setDateActive(index)
  }

  const cleanDateFilter = () => {
    setSelectedFilter({})
    setChecked({})
    setDisplayDate('')
    setShowActivitiesWithVideo(true)
  }

  const onCloseAccordion = () => {
    setExpand(false)
  }

  const onOpenAccordion = () => {
    setExpand(true)
  }

  useEffect(() => {
    const stateFromLocalStorage = getStateFromLocalStorage()
    if (stateFromLocalStorage !== null) {
      setOpen(stateFromLocalStorage)
    } else {
      setOpen(true)
      setStateToLocalStorage(true)
    }
  }, [])

  useLayoutEffect(() => {
    setTabs([])
    setScheduling([])
    setDatePrograming([])
    setParticipant({})
    setSubscriptions([])

    programing.local?.map((room) => {
      setTabs((tabs) => [...tabs, room])
    })

    programing.atividade?.map((activity) => {
      setScheduling((scheduling) => [...scheduling, activity])
    })

    setDatePrograming([...new Set(programing.atividade?.map((activity) => activity.data_hora_inicio?.split(' ')[0]))])

    programing.participante?.map((participants) => {
      setParticipant((prev) => {
        return { ...prev, [participants.uuid]: participants }
      })
    })

    programing?.inscricoes && setSubscriptions(programing.inscricoes)

    programing.tipo_atividade?.map((activityType) => {
      setScheduleType((prev) => {
        return { ...prev, [activityType.uuid]: activityType }
      })
    })
  }, [programing])

  useEffect(() => {
    setDate(datePrograming[0])
  }, [datePrograming])

  useEffect(() => {
    const filteredTabs = tabs.filter((tab) => {
      const filteredScheduling = scheduling.filter((sched) => {
        const isTabMatch = sched.sala === tab.uuid
        const isDateMatch = sched.data_hora_inicio?.split(' ')[0] === date
        return isTabMatch && isDateMatch
      })
      return filteredScheduling.length > 0
    })
    setRoomTabs(filteredTabs)
  }, [tabs, scheduling, datePrograming, date])

  return (
    <DetailEadView
      id={id}
      user={user}
      tabs={tabs}
      date={date}
      axis={axis}
      formik={formik}
      lesson={lesson}
      checked={checked}
      speakers={speakers}
      roomTabs={roomTabs}
      refPlayer={refPlayer}
      showVideo={showVideo}
      programing={programing}
      dateActive={dateActive}
      roomActive={roomActive}
      scheduling={scheduling}
      participant={participant}
      scheduleType={scheduleType}
      activityTypes={activityTypes}
      subscriptions={subscriptions}
      loadingProgram={loadingProgram}
      filterByPeriod={filterByPeriod}
      datePrograming={datePrograming}
      selectedFilter={selectedFilter}
      activity_schedule_id={activity_schedule_id}
      showActivitiesWithVideo={showActivitiesWithVideo}
      handleInputChangeDebounced={handleInputChangeDebounced}
      onPlay={onPlay}
      onEnded={onEnded}
      onPause={onPause}
      setChecked={setChecked}
      onProgress={onProgress}
      selectStep={selectStep}
      handleChange={handleChange}
      onOpenSidebar={onOpenSidebar}
      closeShowVideo={closeShowVideo}
      onCloseSidebar={onCloseSidebar}
      cleanDateFilter={cleanDateFilter}
      onOpenAccordion={onOpenAccordion}
      onCloseAccordion={onCloseAccordion}
      setSelectedFilter={setSelectedFilter}
      roomTabHandleChange={roomTabHandleChange}
      dateTabHandleChange={dateTabHandleChange}
      filterOnlyVideoActivities={filterOnlyVideoActivities}
      handleShowActivitiesWithVideo={handleShowActivitiesWithVideo}
    />
  )
}

export default DetailEadContainer
