import { Translator } from "@itarget/shared";
import { Button, Divider, Grid, Icon, Typography } from "@material-ui/core";
import "./style.scss";
import { useSelector } from 'react-redux'

const CardWrongCreditCard = ({
  setShow = () => {},
  changePayment = () => {},
}) => {
  const settingTheme = useSelector(state => state.setting.theme)
  return (
    <Grid className={`cwcc-container cwcc-container-${settingTheme}`}>
      <Grid container>
        <Grid item xs={11}>
          <Grid className="cwcc-infos">
            <Typography className="infos-title" gutterBottom>
              <Translator transRef="translation.Pagamento não autorizado" />
            </Typography>
            <Typography className="infos-subtitle" gutterBottom>
              <Translator transRef="translation.Por favor, confirme os dados do cartão.Confira:" />
            </Typography>
            <Grid container>
              <Grid item xs={4} className="infos-gridimg">
                <img
                  src="/assets/images/info-card1.png"
                  className="infos-img"
                  alt="informativo"
                />
                <Typography className="infos-imgtitle">
                  <Translator transRef="translation.os dados estão corretos" />
                </Typography>
              </Grid>
              <Grid item xs={4} className="infos-gridimg">
                <img
                  className="infos-img"
                  src="/assets/images/info-card2.png"
                  alt="informativo"
                />

                <Typography className="infos-imgtitle">
                  <Translator transRef="translation.o código de segurança" />
                </Typography>
              </Grid>
              <Grid item xs={4} className="infos-gridimg">
                <img
                  className="infos-img"
                  src="/assets/images/info-card3.png"
                  alt="informativo"
                />

                <Typography className="infos-imgtitle">
                  <Translator transRef="translation.o limite disponível" />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <Grid className="cwcc-buttons">
            <Button className="buttons-confirm" onClick={() => setShow(false)}>
              <Translator transRef="translation.confirmar dados" />
            </Button>
            <Button className="buttons-change" onClick={() => changePayment()}>
              <Translator transRef="translation.mudar forma de pagamento" />
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={1} className="cwcc-icon">
          <Icon>credit_card</Icon>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CardWrongCreditCard;
