import { Grid, Typography, Divider, Button, Tooltip } from "@material-ui/core"
import { dateFormatTranslation } from "@itarget/shared/src/utils/format/date"
import { Translator } from "@itarget/shared"
import { useSelector } from "react-redux"
import Icon from "@material-ui/core/Icon"
import "./style.scss"
import { BtnInscrito, BtnAssistir, BtnPendente } from "../BtnsActions/BtnsActions"
const CardOffers = ({ card, onClick, width }) => {
  const selectedLang = useSelector((state) => state.language.lang)
  return (
    <Grid style={width && { width: width }}>
      <Grid container className={"grid-model2"}>
        <Grid item xs={4}>
          <img
            className={"img-model2"}
            style={!onClick ? { cursor: "default" } : {}}
            alt={card && card.desc_atv}
            title={card && card.desc_atv}
            src={card && card.imagem_lista ? card.imagem_lista : ""}
          />
        </Grid>
        <Grid className="gridItem-detail" item xs={8}>
          <Tooltip title={card?.desc_atv} aria-label={card?.desc_atv}>
            <Grid className="cardOffers-title">
              <Typography>{card && card.desc_atv}</Typography>
            </Grid>
          </Tooltip>
          <Grid className="cardOffers-infos">
            {card?.exibir_programacao_horario === "S" && (
              <div className="infos-item">
                <Icon className="infos-icon">event</Icon>
                <Typography className="infos-text">
                  {dateFormatTranslation(selectedLang, card?.data_inicio_aga) !==
                    dateFormatTranslation(selectedLang, card?.data_fim_aga) &&
                    `${dateFormatTranslation(selectedLang, card?.data_inicio_aga)} - `}
                  {dateFormatTranslation(selectedLang, card?.data_fim_aga)}
                </Typography>
              </div>
            )}
            {/* <div className="infos-item">
              <Icon className="infos-icon">room</Icon>
              <Typography className="infos-text">
                {card && card.desc_tatv}
              </Typography>
            </div> */}
            {card && card.status_inscricao !== undefined && card.status_inscricao !== null && card.status_inscricao !== "" && (
              <div className="float-right">
                {card.status_inscricao === 1 && <BtnPendente />}
                {card.status_inscricao === 2 && <>{card.qtde_aulas_video > 0 ? <BtnAssistir /> : <BtnInscrito />}</>}
              </div>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <div className="degrade"></div>
          <Grid className="description">
            {card && card.descritivo && <div className="div-description" dangerouslySetInnerHTML={{ __html: card.descritivo }} />}
          </Grid>
        </Grid>
        <Grid item xs={12} className={"grid-btnMore"}>
          <Button onClick={() => onClick && onClick(card)} className="btnMore">
            <Translator transRef="translation.mais" />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CardOffers
