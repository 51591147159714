import { Loading } from '@itarget/shared'
import { CardMedia } from '@material-ui/core'
import { useEffect, useState } from 'react'

import './style.scss'
const CardDetailCourse = ({ detail }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [image, setImage] = useState(null)

  useEffect(() => {
    setImage(
      (detail?.data?.imagem_detalhe && detail.data?.imagem_detalhe?.[0]?.imagem_detalhe) ||
        (detail?.data?.list && detail?.data?.list[0]?.imagem_detalhe) ||
        undefined
    )
    setIsLoaded(true)
  }, [detail])

  if (!isLoaded) {
    return <Loading />
  }

  if (!image) return null

  return <CardMedia className='cardDetailCourse-container' image={image || { uri: '' }}></CardMedia>
}

export default CardDetailCourse
