import React from "react";
import {
  Container,
  AppBar,
  TableCell,
  TableHead,
  TableBody,
  Table,
  TableRow,
  Link,
  Typography,
  Grid,
  Icon,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import { Alert } from "@material-ui/lab";
import { formatMoney, PaymentContainer, Translator } from "@itarget/shared";
import "./style.scss";

const PaymentView = ({
  settingCommerce,
  onInvalidCardPayment,
  toast,
  centerCostId,
  paymentType,
  payment,
  subscription,
  total,
  person,
  addressCorresp,
  id,
  reprintBillet,
  subscriptionPaid,
  goTo,
}) => {
  return (
    <Container maxWidth="lg" className="login-container">
      <Grid style={{ padding: "15px" }}>
        <img
          className="img-logo"
          src={settingCommerce?.url_image_login}
          width="100%"
          alt="logo"
        />
      </Grid>
      <Grid item xs={12} md={12} className={"grid"} spacing={4}>
        <Alert
          icon={false}
          variant="outlined"
          color="info"
          style={{ width: "auto" }}
        >
          <PersonIcon />
          {person?.data?.nome}
        </Alert>
      </Grid>
      <Grid item xs={12} md={12} className={"grid activity-row"} spacing={4}>
        {subscription?.length >= 2 && (
          <Alert variant="outlined" color="warning" style={{ width: "auto" }}>
            <Translator transRef="translation.Pagamento permitido somente para uma atividade" />
          </Alert>
        )}
        {subscription?.length < 2 && (
          <>
            {subscription &&
              subscription?.filter((subs) => subs.esta_vencido === "S").length >
              0 && (
                <Grid className={"ribbon"} style={{ float: "right" }}>
                  <Translator transRef="translation.Vencido" />
                </Grid>
              )}
            {subscription?.length > 0 && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><Translator transRef="translation.Atividade" /></TableCell>
                    <TableCell numeric><Translator transRef="translation.Valor" /></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subscription?.map(
                    ({ id, agenda_atividade_idioma_descricao, cr_valor }) => (
                      <TableRow key={id}>
                        <TableCell component="th" scope="row">
                          {agenda_atividade_idioma_descricao}
                        </TableCell>
                        <TableCell numeric>
                          R$ {formatMoney.toReal(cr_valor)}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                  <TableRow key={"total"}>
                    <TableCell component="th" scope="row">
                      <Translator transRef="translation.Total" />
                    </TableCell>
                    <TableCell numeric>
                      R$ {formatMoney.toReal(total)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}
            {subscriptionPaid?.length > 0 && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><Translator transRef="translation.Atividade" /></TableCell>
                    <TableCell><Translator transRef="translation.Recibo" /></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subscriptionPaid?.map(
                    ({
                      id,
                      agenda_atividade_idioma_descricao,
                      link_recibo,
                    }) => (
                      <TableRow key={id}>
                        <TableCell component="th" scope="row">
                          {agenda_atividade_idioma_descricao}
                        </TableCell>
                        <TableCell>
                          <Grid
                            onClick={(event) => {
                              window.open(link_recibo);
                              event.stopPropagation();
                            }}
                            style={{ cursor: "pointer" }}
                            className="grid-left-infos"
                          >
                            <Typography className="text-left">
                              <Translator transRef="translation.Comprovante" />
                            </Typography>
                            <Icon
                              style={{ color: "#008A05" }}
                              className="icon-left"
                            >
                              play_arrow
                            </Icon>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            )}
          </>
        )}
      </Grid>
      {subscription?.length < 2 && (
        <Grid container className={"grid"} spacing={4}>
          <AppBar position="static" color="default">
            {subscription &&
              subscription?.filter((subs) => subs.esta_vencido === "S").length <
              1 &&
              subscription[0]?.hash_transacao && (
                <Grid className="grid-left-infos">
                  <Link
                    target="_blank"
                    onClick={(event) => {
                      event.preventDefault();
                      reprintBillet(subscription[0]);
                    }}
                  >
                    <Typography className="text-left">
                      <Translator transRef="translation.Reimprimir boleto" />
                    </Typography>
                  </Link>
                </Grid>
              )}

            {addressCorresp &&
              subscription &&
              subscription?.filter((subs) => subs.esta_vencido === "S").length <
              1 && (
                <PaymentContainer
                  centro_custo_id={centerCostId}
                  toast={toast}
                  paymentType={paymentType}
                  subscriptionState={subscription}
                  paymentRedux={payment}
                  total={total}
                  user={person}
                  onInvalidCardPayment={onInvalidCardPayment}
                  showFormData={false}
                  routerFinishBank={`payment/${id}`}
                  routeFinishPayment={`payment/${id}`}
                  goFinish={() => {
                    goTo(`payment/${id}`);
                  }}
                />
              )}
          </AppBar>
        </Grid>
      )}
      {addressCorresp === null && subscription?.length < 2 && (
        <Grid item xs={12} md={12} className={"grid"} spacing={4}>
          <br />
          <Alert color="warning" style={{ width: "auto" }}>
            <Translator transRef="translation.Você não tem endereço de correspondencia complete na area do
            associado pelo site e depois retornar no link para pagamento." />
          </Alert>
        </Grid>
      )}
    </Container>
  );
};

export default PaymentView;
