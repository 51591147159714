import { Grid, Typography, Divider } from "@material-ui/core"
import { formatMoney, Translator } from "@itarget/shared"
import "./style.scss"

const BodyAccordion = ({ data }) => {
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          {data.map((row, i) => {
            return (
              <div key={i} style={{ padding: "20px" }}>
                <Grid container>
                  <Grid
                    xs={12}
                    md={2}
                    style={{
                      padding: 0,
                      display: "flex",
                      alignItems: "center",
                      minHeight: 40,
                    }}
                  >
                    <img className="activityThumb-img" width="100%" alt={row.descricao} src={row.thumb || `assets/images/png-placeholder.jpg`} />
                  </Grid>
                  <Grid xs={12} md={10}>
                    <div className="description-curses">{row.descricao}</div>
                  </Grid>
                </Grid>
                <Grid container style={{ marginTop: "12px" }}>
                  <Grid xs={12} md={4}>
                    <Typography>
                      <Translator transRef="translation.Tipo:" /> {row.tipo_atividade_descricao}
                    </Typography>
                  </Grid>
                  <Grid xs={12} md={4}>
                    <Typography>
                      <Translator transRef="translation.Qtd.:" /> {row.qtde_inscricao}
                    </Typography>
                  </Grid>
                  <Grid xs={12} md={4}>
                    <Typography>
                      <Translator transRef="translation.Valor:" /> R$ {formatMoney.toReal(row.cr_valor)}
                    </Typography>
                  </Grid>
                </Grid>
                <br />
                <Divider />
              </div>
            )
          })}
        </Grid>
      </Grid>
    </>
  )
}

export default BodyAccordion
