import React from "react";
import Layout from "../../../components/Layout";

const PageView = ({ page }) => {
    return (
        <Layout>
            <div dangerouslySetInnerHTML={{ __html: page?.corpo }} />
        </Layout>
    )
}

export default PageView;