import { Box, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { useTranslation } from './translation'
import { Button } from '@itarget/shared'
import { useStyles } from './styles'
import useAction from './action'

const ProgrammingFilter = ({ tags = [], rooms = [] }) => {
  const translation = useTranslation()
  const {
    selected,
    displayCleanButton,
    displayAdvancedFilters,
    handleSearchField,
    handleStartAt,
    handleEndAt,
    handleCleanFields,
    handleRoomField,
    handleAdvancedFilters,
  } = useAction()
  const styles = useStyles({ displayAdvancedFilters })
  return (
    <Box className={styles.container}>
      <Box className={`${styles.field} ${styles.searchField}`}>
        <Typography className={styles.fieldName}>{translation.search.name}</Typography>
        <TextField
          size='small'
          id='search-field'
          variant='outlined'
          onChange={handleSearchField}
          defaultValue={selected.search}
          placeholder={translation.search.label}
        />
      </Box>
      <Typography
        aria-roledescription='button'
        onClick={handleAdvancedFilters}
        className={styles.advancedButton}>
        {translation.advancedButton}
      </Typography>
      <Box className={styles.advancedContainer}>
        <Box className={styles.field}>
          <Typography className={styles.fieldName}>{translation.period.name}</Typography>
          <Box className={styles.timeContainer}>
            <TextField
              type='time'
              size='small'
              variant='outlined'
              onChange={handleStartAt}
              className={styles.field}
              value={selected.startAt || ''}
              defaultValue={selected.startAt}
            />
            <TextField
              type='time'
              size='small'
              variant='outlined'
              onChange={handleEndAt}
              className={styles.field}
              value={selected.endAt || ''}
              defaultValue={selected.endAt}
            />
          </Box>
        </Box>
        {rooms.length > 0 && (
          <Box className={styles.field}>
            <Typography className={styles.fieldName}>{translation.room.name}</Typography>

            <Select
              variant='outlined'
              className={styles.select}
              value={selected.roomId || 0}
              onChange={handleRoomField}>
              <MenuItem value={0}>{translation.default}</MenuItem>
              {rooms.map(({ id, name }) => {
                return (
                  <MenuItem
                    key={`${id}_${name}`}
                    value={id}>
                    {name}
                  </MenuItem>
                )
              })}
            </Select>
          </Box>
        )}
        {displayCleanButton && (
          <Button
            color='twilight'
            onClick={handleCleanFields}
            className={`${styles.button} ${styles.cleanButton}`}>
            {translation.clean}
          </Button>
        )}
      </Box>
      {displayCleanButton && displayAdvancedFilters && (
        <Box className={`${styles.button} ${styles.mobileCleanButtonContainer}`}>
          <Button
            color='twilight'
            onClick={handleCleanFields}
            className={`${styles.button} ${styles.mobileCleanButton}`}>
            {translation.clean}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default ProgrammingFilter
