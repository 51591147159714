import styled from "styled-components";

export const StylesAborl = styled.div`
  @font-face {
    font-family: "Gotham";
    src: url("fonts/Gotham-Bold.woff2") format("woff2"), url("fonts/Gotham-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  ul.menu,
  .menu li,
  .menu a {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    font-size: 0.9em;
    font-weight: 750;
    text-transform: uppercase;
    word-spacing: 0px;
  }
  .toggle,
  [id^="drop"] {
    display: none;
  }

  .current-menu-item .nav-link {
    background-color: #f06363;
    font-weight: bold;
  }
  ul.menu ul {
    position: absolute;
    display: none;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    border-bottom: 3px solid rgba(0, 0, 0, 0.15);
  }

  ul.menu li.show ul.submenu-1 li.showSub ul.submenu-2 {
    display: block;
    position: absolute;
    z-index: 30;
    top: 0px !important;
    left: 175px;
    padding: 0 !important;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    border-bottom: 3px solid rgba(0,0,0,0.15);
  }

  ul.menu li.show > ul.submenu-1 > li > a > span {
    width: 100%
  }

  ul.menu li.show > ul.submenu-1 > li > a > span.set::after {
    content: "+";
    color: var(--e-global-text-color);
    width: 20px;
    height: auto;
    margin: 0;
    border: 0;
    margin-left: 10px;
  }

  ul.menu li.show > ul.submenu-1 > li.showSub > a > span.set::after {
    content: "-";
    color: var(--e-global-text-color);
    margin: 0;
    border: 0;
    margin-left: 10px;
  }


  .nav-social {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 10.445%;
  }

  .nav-search {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 3.445%;
  }

  .nav-search > span {
    fill: var(--e-global-text-color);
    color: var(--e-global-text-color);
  }

  /* Configurações Nivel 1 */
  .nav-menu-desktop {
    display: flex !important;
    flex-direction: row;
    max-width: 100%;
  }

  .nav-menu {
    max-width: 86.9%;
    flex-wrap: wrap;
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ul.menu {
    max-width: 100%;
    flex-wrap: wrap;
    flex: 1 0 auto;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12.8px;
  }

  .menu li {
    float: left;
    width: auto;
    position: relative;
  }

  .menu li a {
    display: block;
    padding: 5px 10px;
    line-height: 1.6;
    white-space: normal;
    float: left;
    clear: both;
    color: #fff;
    font-family: "Gotham", Arial !important;
    transition: all 0.1s linear;
  }

  .menu li:hover > .li-link {
    transition: border 0.2s linear;
  }

  /* Configurações Nivel 2*/
  .menu li.show > ul.submenu-1 {
    width: 100%;
    background-color: #f3f3f3;
    z-index: 2;
    display: block;
    top: 28px;
    left: 0;
    padding: 0;
    min-width: 8.5rem;
  }
  // .menu li:hover>ul.submenu-1 {
  //   display: block;
  //   top: 28px;
  //   left: 0;
  //   padding: 5px;
  //   min-width: 8.5rem;
  // }

  .menu ul.submenu-1 a {
    width: 100%;
    min-width: 8.5rem;
    border: 0;
    color: rgba(0, 0, 0, 0.5);
    background-color: #f3f3f3;
    font-weight: 400;
    font-size: 1.2em;
    text-transform: none;
  }
  .menu ul.submenu-1 a:hover {
    color: #444;
    background: #f7f7f7;
  }

  /* Configurações nivel 2*/

  .menu ul.submenu-2 a {
    width: 100%;
    min-width: 9.5rem;
    background-color: #e9e9e9;
    border: 0;
    font-weight: 750;
    font-size: 1.2em;
    text-transform: none;
  }
  .menu ul.submenu-2 a:hover {
    color: #444;
    background: #f7f7f7;
  }

  /* Configurações nivel 3*/

  .menu ul.submenu-3 a {
    padding: 0 20px;
    border-radius: 5px;
  }

  /*Configurações de cores*/
  /*Nivel 1*/
  .nav-menu-desktop {
    border-top: 1px solid #006ea2;
  }

  .nav-menu-desktop > div + div {
    margin-left: 5px;
    padding-left: 5px;
    border-left: 1px solid #006ea2;
  }

  .menu {
    background: #fff;
  }

  .menu li a {
    color: var(--e-global-text-color);
  }

  .menu li:first-child {
    border-left: 1px solid #fff;
  }

  .menu a {
    color: #fff;
    border-right: 1px solid #fff;
  }

  .menu li:hover > a.li-link {
    background: #fff;
    border-bottom: 4px solid var(--e-global-color-primary);
    color: #000;
  }

  /*nivel 2*/
  .submenu-1 {
    background: rgb(42, 116, 153);
  }
  .submenu-1 a {
    color: #fff;
  }
  .submenu-1 li:hover > a {
    background: #fff;
    color: rgb(42, 116, 153);
  }

  /*nivel 3*/
  .submenu-2 {
    background: rgb(42, 116, 153);
  }
  .submenu-2 a {
    color: #fff;
  }
  .submenu-2 li:hover > a {
    background: #fff;
  }

  /*nivel 3*/
  .submenu-3 {
    background: rgb(42, 116, 153);
  }
  .submenu-3 a {
    color: #fff;
  }
  .submenu-3 li:hover > a {
    background: #000;
  }

  .MuiSvgIcon-root {
    font-size: 17px !important;
  }

  @media screen and (max-width: 950px) {
    .nav-menu {
      max-width: 100%;
    }
    .nav-search {
      display: none;
    }
    .nav-social {
      display: none;
    }

    ul.menu li {
      width: 100%;
      background-color: white;
      border-bottom: 1px solid #529dd7;
      display: flex;
      justify-content: center;
      aligin-items: center;
    }

    ul.menu li.show ul.submenu-1 li.showSub ul.submenu-2 {
      display: block;
      position: absolute;
      z-index: 30;
      top: 35px !important;
      left: 0 !important;
      padding: 0 !important;
      border-radius: inherit;
      border: none;
      width: 100%;
    }

    ul.menu li a {
      z-index: 1;
      width: 100%;
      min-height: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .show ul.menu li.show a.li-link::after {
      flex: 0 0 auto;
      content: "-";
      font-size: 16px;
      float: right;
    }

    ul.menu a.parent.li-link::after {
      flex: 0 0 auto;
      content: "+";
      font-size: 16px;
      float: right;
    }

    ul.menu li.show ul li a::after {
      content: inherit;
    }

    .menu li.show > ul.submenu-1 {
      width: 100%;
      z-index: 2;
      display: block;
      top: 35px;
      left: -0;
      min-width: 8.5rem;

    .show .menu li.show > ul.submenu-1 {
      width: 100%;
      position: absolute;
      z-index: 30;
      top: 40px !important;
      left: 0;
      padding: 0 !important;
      border-radius: inherit;
      border: none;
      width: 100%;
    }

    .show .menu li.show > ul.submenu-1 > li > ul.submenu-2 {
      display: none;
      position: absolute;
      z-index: 30;
      top: 40px !important;
      left: 0;
      padding: 0 !important;
      border-radius: inherit;
      border: none;
      width: 100%;
    }

    .show .menu li.show > ul.submenu-1 > li.showSub > ul.submenu-2 {
      display: block;
    }

    .show .menu li.show > ul.submenu-1 > li {
      background: #fff;
      display: inline-block !important;
      width: 100%;
    }

    .show .menu li.show > ul.submenu-1 > li > ul.submenu-2 > li {
      background: #dfdfdf;
      display: inline-block !important;
      width: 100%;
    }

    ul.menu li.show > ul.submenu-1 > li > a > span.set::after {
      content: "+";
      color: var(--e-global-text-color);
      width: 20px;
      height: auto;
      margin: 0;
      border: 0;
      float: right;
    }

    ul.menu li.show > ul.submenu-1 > li.showSub > a > span.set::after {
      content: "-";
      color: var(--e-global-text-color);
      margin: 0;
      border: 0;
      float: right;
    }

    .show .menu li a {
      display: inline-block;
      overflow: hidden;
      float: none !important;
      padding: 7px 5px !important;
      width: inherit;
    }

    .show .menu li a:hover {
      color: #2b2b2b !important;
    }

    .show .menu li:first-child {
      border-left: none !important;
    }
  }
`;
