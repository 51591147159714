import { ProgrammingContext } from '../../context/ProgrammingContext'
import { useVideo } from '@itarget/shared'
import { MutableRefObject, useContext, useEffect, useRef } from 'react'

const useAction = () => {
  const videoPlayerRef = useRef(null)
  const { displayVideo, currentPresentation, handleDisplayVideo } = useContext(ProgrammingContext)
  const {
    handlePlay,
    handlePause,
    handleEnded,
    errorGetVideo,
    actualPosition,
    handlePosition,
    getVideoFrequency,
    fetchGetVideoFrequency,
  } = useVideo({
    mainId: currentPresentation?.idaad,
  })
  let speakers = currentPresentation?.speakers ? currentPresentation.speakers.map((speaker) => speaker.name).join(', ') : ''
  let MAX_PERCENT = 100
  let percentWatched = ((actualPosition?.played || 0) * MAX_PERCENT).toFixed(2)
  let video = currentPresentation?.speakerVideoLink
  let title = currentPresentation?.title
  let enabled = displayVideo

  let handleClose = () => {
    handleEnded()
    handleDisplayVideo()
  }

  useEffect(() => {
    fetchGetVideoFrequency()
  }, [fetchGetVideoFrequency])

  useEffect(() => {
    if (!getVideoFrequency || !getVideoFrequency?.data?.posicao || !videoPlayerRef) return
    let position = getVideoFrequency.data.posicao
    let type = getVideoFrequency?.data?.tipo
    let time = position?.seconds ? position.seconds : position.playedSeconds
    if (position.played == 1 || type == 'F') return videoPlayerRef.current.seekTo(0)
    videoPlayerRef.current.seekTo(time)
  }, [getVideoFrequency, videoPlayerRef])

  return {
    title,
    video,
    enabled,
    speakers,
    handleClose,
    percentWatched,
    videoPlayerRef,

    handlePlay,
    handlePause,
    handleEnded,
    errorGetVideo,
    handlePosition,
  }
}

export default useAction
